import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import React, { useEffect, useRef, useState } from "react";
import draftIcon from "../../Asset/Images/GmailIcons/draftIcon.png";
import Card from "react-bootstrap/Card";
import inboxIcon from "../../Asset/Images/GmailIcons/inboxIcon.png";
import sentIcon from "../../Asset/Images/GmailIcons/sentIcon.png";
import trashIcon from "../../Asset/Images/GmailIcons/trashIcon.png";
import AllmailsIcon from "../../Asset/Images/GmailIcons/AllmailsIcon.png";
import spamIcon from "../../Asset/Images/GmailIcons/spamIcon.png";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../Microsoft/components/SignInButton";
import { SignOutButton } from "../Microsoft/components/SignOutButton";
// import { FaFilePdf, FaFileExcel, FaFile, FaGoogle } from "react-icons/fa";
import {
  FaFile,
  FaFileAlt,
  FaFileArchive,
  FaFileCode,
  FaFileExcel,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
  FaGoogle,
} from "react-icons/fa";
// import LogoIcon from "./Images/outlook.jpg";

import moment from "moment/moment";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { PageLayout } from "./components/PageLayout";
// import { UserData } from "./Redux/Action";
import { loginRequest, msalConfig } from "./components/authConfig";
import {
  EmailAttachments,
  EmailNextPrev,
  callMail,
  callMsGraph,
  getDeletedmaildata,
  getEmailInbox,
} from "./components/graph";
import Skeleton from "react-loading-skeleton";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { getDateFormatIn_MonthName_Date } from "../../Services/CommonServices";
import Inbox from "../Microsoft/mail_folders/Inbox";
import Sent from "./mail_folders/Sent";
import Drafts from "./mail_folders/Drafts";
import Trash from "./mail_folders/Trash";
import Spam from "./mail_folders/Spam";
import All from "./mail_folders/All";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

// import "./styles/App.css";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [MailList, setMailList] = useState({ IsData: false, data: [] });
  const [IsLastPage, setIsLastPage] = useState(false);
  const [_PageNo, set_PageNo] = useState(1);
  const [_TotalPageNo, set_TotalPageNo] = useState(1);
  const ForLoader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [Click, setClick] = useState([]);
  // let dispatch = new useDispatch();
  const nextPrevRef = useRef(0);

  const [EmailData, setEmailData] = useState([]);

  const contentTypeToIcon = {
    "application/pdf": <FaFilePdf />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <FaFileExcel />,
    "application/vnd.google-apps.spreadsheet": <FaGoogle />,
    "application/msword": <FaFileWord />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": <FaFileWord />,
    "application/vnd.ms-powerpoint": <FaFilePowerpoint />,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": (
      <FaFilePowerpoint />
    ),
    "application/zip": <FaFileArchive />,
    "application/x-rar-compressed": <FaFileArchive />,
    "application/x-tar": <FaFileArchive />,
    "application/x-7z-compressed": <FaFileArchive />,
    "application/x-gzip": <FaFileArchive />,
    "application/x-bzip2": <FaFileArchive />,
    "text/plain": <FaFileAlt />,
    "text/html": <FaFileAlt />,
    "text/css": <FaFileAlt />,
    "text/javascript": <FaFileCode />,
    "application/json": <FaFileCode />,
    "application/xml": <FaFileCode />,
  };

  function RequestProfileData() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken)
          .then((profileResponse) => {
            // if (
            //   profileResponse !== undefined &&
            //   profileResponse !== "" &&
            //   profileResponse !== null
            // ) {
            //   dispatch(UserData(profileResponse));
            // }
          })
          .catch((error) => {
            console.error("Error fetching profile data:", error);
          });
        callMail(response.accessToken)
          .then((emailResponse) => {
            setEmailData(emailResponse?.value);
            handleEmailClick(emailResponse.value[0]);
          })
          .catch((error) => {
            console.error("Error fetching email data:", error);
          });
        getDeletedmaildata(response.accessToken)
          .then((emailResponse) => {
            // console.log("Deleted mail data ::", emailResponse);
          })
          .catch((error) => {
            console.error("Error fetching email data:", error);
          });
      })
      .catch((error) => {
        console.error("Error acquiring access token:", error);
      });
  }

  // inbox email data
  function RequestInboxEmail() {
    setMailList({ IsData: false, data: [] });
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        getEmailInbox(response.accessToken)
          .then((Response) => {
            setMailList({ IsData: true, data: Response.value });
            // console.log("Response ::", Response);

            // if (
            //   profileResponse !== undefined &&
            //   profileResponse !== "" &&
            //   profileResponse !== null
            // ) {
            //   dispatch(UserData(profileResponse));
            // }
          })
          .catch((error) => {
            console.error("email inbox error ::", error);
            setMailList({ IsData: true, data: [] });
          });
      })
      .catch((error) => {
        console.error("Error acquiring access token:", error);
      });
  }

  useEffect(() => {
    RequestProfileData();
    RequestInboxEmail();
  }, []);

  // console.log("MailList ::", MailList);

  function handleEmailClick(email) {
    let lat = [];
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        EmailAttachments(response.accessToken, email.id)
          .then((emailResponse) => {
            email.attachment = emailResponse.value;
            lat.push(email);
            setClick(lat);
          })
          .catch((error) => {
            console.error("Error fetching attachments:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching attachments:", error);
      });
  }

  function fnNextPrev(type) {
    if (type === "next") {
      nextPrevRef.current = nextPrevRef.current + 10;
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          EmailNextPrev(response.accessToken, nextPrevRef.current)
            .then((emailResponse) => {
              setEmailData(emailResponse?.value);
              handleEmailClick(emailResponse.value[0]);
            })
            .catch((error) => {
              console.error("Error fetching email data:", error);
            });
        })
        .catch((error) => {
          console.error("Error acquiring access token:", error);
        });
    }
    if (type === "prev") {
      nextPrevRef.current = nextPrevRef.current - 10;
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          EmailNextPrev(response.accessToken, nextPrevRef.current)
            .then((emailResponse) => {
              setEmailData(emailResponse?.value);
              handleEmailClick(emailResponse.value[0]);
            })
            .catch((error) => {
              console.error("Error fetching email data:", error);
            });
        })
        .catch((error) => {
          console.error("Error acquiring access token:", error);
        });
    }
  }

  const [ketActive, setKetActive] = useState("inbox");

  return (
    <div className="inbox_main">
      <Row>
        <Tab.Container id="left-tabs-example" defaultActiveKey={ketActive}>
          <Col xxl={2} xl={2} lg={2} md={2}>
            <div className="nav-pills">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="inbox" onClick={() => setKetActive("inbox")}>
                    <img src={inboxIcon} alt="inbox icon" /> Inbox
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="sent" onClick={() => setKetActive("sent")}>
                    <img src={sentIcon} alt="sent icon" /> Sent
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="drafts" onClick={() => setKetActive("drafts")}>
                    <img src={draftIcon} alt="draft icon" /> Drafts
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="deleted-emails"
                    onClick={() => setKetActive("deleted-emails")}
                  >
                    <img src={trashIcon} alt="trash icon" /> Trash
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="spam" onClick={() => setKetActive("spam")}>
                    <img src={spamIcon} alt="spam icon" /> Spam
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="all" onClick={() => setKetActive("all")}>
                    <img src={AllmailsIcon} alt="Allmails icon" /> All
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <SignOutButton />
            </div>
          </Col>

          <Col xxl={10} xl={10} lg={10} md={10} className="mt-4 mt-lg-0">
            <Tab.Content>
              <Tab.Pane eventKey="inbox">{ketActive === "inbox" && <Inbox />}</Tab.Pane>
              <Tab.Pane eventKey="sent">{ketActive === "sent" && <Sent />}</Tab.Pane>
              <Tab.Pane eventKey="drafts">{ketActive === "drafts" && <Drafts />}</Tab.Pane>
              <Tab.Pane eventKey="deleted-emails">
                {ketActive === "deleted-emails" && <Trash />}
              </Tab.Pane>
              <Tab.Pane eventKey="spam">{ketActive === "spam" && <Spam />}</Tab.Pane>
              <Tab.Pane eventKey="all">{ketActive === "all" && <All />}</Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Row>
    </div>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  const isAuthenticated = useIsAuthenticated();
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        {/* <h5 className="card-title">nnnnnnnnnnnnnnnnnnnnnn</h5> */}
        <div className="logo-live">
          <div>
            {" "}
            <SignInButton />
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <>
      {/* <PageLayout> */}
      <MsalProvider instance={msalInstance}>
        <MainContent />
      </MsalProvider>

      {/* </PageLayout> */}
    </>
  );
}
