import moment from "moment";
import React from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import NoDataFoundImage from "../../Component/NoData/NoDataFoundImage";
import { AppConfigData, CommonService, ToasterError } from "../../Services/CommonServices";
import { MainMonthlyRollupReportListExport } from "../../Services/ReportServices";
import { useNavigate } from "react-router-dom";

export default function MonthlyPortfolioReportModal({
  activityData,
  oppoData,
  loading,
  inputs,
  showReport,
  setShowReport,
}) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const handleClosePopup = () => {
    setShowReport(false);
  };

  const [OnProcessHide, setOnProcessHide]: any = React.useState(false);

  async function exportExelData(e) {
    e.preventDefault();
    setOnProcessHide(true);
    const res: any = await MainMonthlyRollupReportListExport(inputs, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setOnProcessHide(false);
        } else {
          ToasterError("No Record Found");
          setOnProcessHide(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setOnProcessHide(false);
      }
    } else {
      ToasterError("Something Went Wrong.");
      setOnProcessHide(false);
    }
  }

  let page_rights_account = CommonService.getPageRight("Account");
  let navigate = useNavigate();

  return (
    <Modal
      show={showReport}
      onHide={() => {
        handleClosePopup();
      }}
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Monthly Rollup Report ({moment(inputs.startDate).format("MM-DD-YYYY")} To{" "}
          {moment(inputs.endDate).format("MM-DD-YYYY")}){" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <Row className="mt-2">
            <Col md={12} lg={12} xl={12} className="mb-3">
              {/* <div className="information-division"> */}
              <div className="information-division">
                <h3 className="inner-header">Total activity by type</h3>
                <div className="tab-inner-box position-relative">
                  <Table responsive bordered className="table-without-space mb-0">
                    <thead>
                      <tr>
                        <th>Activity Type</th>
                        <th>Total Activities</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityData.length ? (
                        activityData.map((ac_data: any, key) => {
                          return (
                            <tr key={key}>
                              <td>{ac_data.ActivityType}</td>
                              <td>{ac_data.TotalActivities}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={2}>
                            <NoDataFoundImage />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* </div> */}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={12} lg={12} xl={12} className="mb-3">
              <div className="information-division">
                {/* <div className="information-division"> */}
                <h3 className="inner-header">Total new opportunity</h3>
                <div className=" mt-3">
                  <Table responsive bordered className="table-without-space">
                    <thead>
                      <tr>
                        <th style={{ width: "10%" }}>Oppo Type</th>
                        <th>Property Code</th>
                        <th>Account Name</th>
                        <th>Oppo Name</th>
                        <th>Source</th>
                        <th>Status</th>
                        <th>Anticipated Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oppoData.length ? (
                        oppoData.map((op_data: any, key) => {
                          return (
                            <tr key={key}>
                              <td>{op_data.OppoType}</td>
                              <td>{op_data.PropertyCode}</td>
                              <td>
                                <div
                                  role="button"
                                  tabIndex={-1}
                                  onClick={() => {
                                    if (page_rights_account.is_view) {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: op_data.AccountID,
                                          AccountName: op_data.AccountName,
                                        },
                                      });
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                  onKeyDown={() => {
                                    if (page_rights_account.is_view) {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: op_data.AccountID,
                                          AccountName: op_data.AccountName,
                                        },
                                      });
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                >
                                  {op_data.AccountName ? op_data.AccountName.replace("#", " ") : ""}
                                </div>
                              </td>
                              <td>{op_data.OppoName}</td>
                              <td>{op_data.Source}</td>
                              <td>{op_data.Status}</td>
                              <td>{op_data.TotalAnticipatedRevenue}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7}>
                            <NoDataFoundImage />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              {/* </div> */}
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={handleClosePopup}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          onClick={exportExelData}
          disabled={OnProcessHide === true ? true : false}
        >
          <span className="me-2">
            <img src={ExportIcon} alt="" />
          </span>
          Export Excel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
