/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useNavigate } from "react-router-dom";

export default function Tutorials() {
  const navigate = useNavigate();
  return (
    <div className="nav-pills">
      <ul className="p-0 mb-0">
        <li
          className={`nav-link ${location.pathname === "/knowledge-tutorials" ? "active" : ""}`}
          onClick={() => navigate("/knowledge-tutorials")}
        >
          Inntellient Overview
        </li>
        <li
          className={`nav-link ${location.pathname === "/new-user-tour" ? "active" : ""}`}
          onClick={() => navigate("/new-user-tour")}
        >
          New User Tour
        </li>
        <li
          className={`nav-link ${location.pathname === "/adding-new-account-tour" ? "active" : ""}`}
          onClick={() => navigate("/adding-new-account-tour")}
        >
          Adding a New Account
        </li>
        <li
          className={`nav-link ${location.pathname === "/new-suspect-or-lead-tour" ? "active" : ""}`}
          onClick={() => navigate("/new-suspect-or-lead-tour")}
        >
          New Suspect or Lead
        </li>
        <li
          className={`nav-link ${location.pathname === "/adding-new-contacts-tour" ? "active" : ""}`}
          onClick={() => navigate("/adding-new-contacts-tour")}
        >
          Adding New Contacts
        </li>
        <li
          className={`nav-link ${location.pathname === "/opportunity-build-tour" ? "active" : ""}`}
          onClick={() => navigate("/opportunity-build-tour")}
        >
          Opportunity Build
        </li>
      </ul>
    </div>
  );
}
