import React, { useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import { MoreResultInActivity } from "../Services/LeftMenuServices";
import PageLoader from "../Component/Loader/PageLoader";
import { ToasterError } from "../Services/CommonServices";

function ActivityResultViewModel(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  let [modalResultView, setModalResultView]: any = React.useState("");
  let [loading, setLoading]: any = React.useState(false);

  async function getResults() {
    let input: any = {
      keyId: props.resultdata.ActivityID,
    };
    setLoading(true);
    // setOpenActivity({ IsData: true, data: [] });
    let res: any = await MoreResultInActivity(input, Token);

    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setLoading(false);
          setModalResultView(res.data.data);
        } else {
          ToasterError("Something Went Wrong");
          setLoading(false);
        }
      } else {
        ToasterError("Something Went Wrong");
        setLoading(false);
      }
    } else {
      ToasterError("Something Went Wrong");
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.resultdata && props.resultdata.ActivityID) {
      getResults();
    }
  }, []);

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Activity Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} xl={12} className="mb-3 mb-xl-0">
              <div className="information-division">
                <hr />
                <h3 className="inner-header m-0">
                  {modalResultView.activitySubject !== undefined &&
                  modalResultView.activitySubject !== null &&
                  modalResultView.activitySubject !== "" &&
                  modalResultView?.activitySubject
                    ? modalResultView.activitySubject
                    : "-"}
                </h3>
                <div className="tab-inner-box position-relative">
                  <p className="box-detials">
                    {modalResultView.activityDetails !== null &&
                    modalResultView.activityDetails !== undefined &&
                    modalResultView.activityDetails !== "" ? (
                      <div dangerouslySetInnerHTML={{ __html: modalResultView.activityDetails }} />
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          {/* <Button variant="primary" onClick={props.onHide}>
          <span className="me-2">
            <img src={WhiteSaveIcon} alt="" />
          </span>
          Save
        </Button> */}
        </Modal.Footer>
      </Modal>
      {loading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ActivityResultViewModel;
