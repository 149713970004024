import React from "react";
import { Button, Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ENUMS } from "../Constants";
import {
  getAccountList,
  getContactListing,
  getGroupList,
  getLNRList,
  getLeadListData,
  getMeetingCateringList,
  getRFPList,
  saveActivities,
} from "../Services/LeftMenuServices";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../Component/Loader/PageLoader";
import {
  ToasterError,
  crmExtensionGetSelectedHtml,
  fnCheckValidationOfObject,
} from "../Services/CommonServices";
import { useNavigate } from "react-router-dom";
import { GetHotelsListForReports } from "../Services/Setup&Config/HotelService";
import Swal from "sweetalert2";

const GmailInboxLinkWith = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const Token = dataSelector.access_Token;
  const navigate = useNavigate();

  const U_DisplayName = dataSelector.auth_UserDetail.U_DisplayName;
  const U_UserID = dataSelector.auth_UserDetail.U_UserID;

  const globalHotelData: any = useSelector((state: any) => state.dashboard);

  const [loadProcess, setloadProcess] = React.useState(false);
  const [GetDatalist, setGetDatalist] = React.useState([]);
  const [LinkWith, setLinkWith] = React.useState("");
  const [selectedHTML, setSelectedHTML] = React.useState("");

  let [PropertiesListData, setPropertiesListData]: any = React.useState({
    IsData: true,
    data: [],
  });
  const [selectedHotel, setSelectedHotel] = React.useState({
    label: "Select Hotel",
    value: null,
  });
  const [selectedOption, setSelectedOption] = React.useState({
    label: "Select Option",
    value: null,
  });
  const [Loading, setLoading] = React.useState(false);

  const [emailLinkInput, setEmailLinkInput] = React.useState({
    moduleName: "",
    hotelName: "",
    hotelID: "",
    linkWith: "",
    linkWithID: "",
    AccountID: "",
    AccountName: "",
    errors: {
      moduleName: "",
      hotelName: "",
      linkWith: "",
      ValidationRules: [
        {
          FieldName: "moduleName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "hotelName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "linkWith",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  });

  let MyOption: any = [];
  // globalHotelData.GlobalHotels !== undefined &&
  //   globalHotelData.GlobalHotels &&
  //   globalHotelData.GlobalHotels.length > 0 &&
  //   globalHotelData.GlobalHotels.map((item: any) => {
  //     if (item.portfolioName !== "All") {
  //       MyOption.push({
  //         label: item.HotelCode + " " + item.PropertyName,
  //         value: item.PropertyID,
  //       });
  //     }
  //   });

  //   let HotelsDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      MyOption.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  let optionsLinkWith: any = [];
  ENUMS.GmailLinkWith !== undefined &&
    ENUMS.GmailLinkWith &&
    ENUMS.GmailLinkWith.length > 0 &&
    ENUMS.GmailLinkWith.map((item: any) => {
      optionsLinkWith.push({
        label: item,
        value: item,
      });
    });

  let optionsToLinkWith: any = [];
  GetDatalist !== undefined &&
    GetDatalist &&
    GetDatalist.length > 0 &&
    GetDatalist.map((item: any) => {
      if (LinkWith === "Lead") {
        optionsToLinkWith.push({
          label: item.CompanyName,
          value: item.LeadID,
        });
      } else if (LinkWith === "Account") {
        optionsToLinkWith.push({
          label: item.AccountName,
          value: item.AccountID,
        });
      } else if (LinkWith === "Contact") {
        optionsToLinkWith.push({
          label: item.ContactName,
          value: item.ContactID,
          AccountID: item.AccountID,
          AccountName: item.Account,
        });
      } else if (LinkWith === "RFP") {
        optionsToLinkWith.push({
          label: item.OppoName,
          value: item.RFPOppoID,
          AccountID: item.AccountID,
          AccountName: item.AccountName,
        });
      } else if (LinkWith === "LNR") {
        optionsToLinkWith.push({
          label: item.OppoName,
          value: item.LNROppoID,
          AccountID: item.AccountID,
          AccountName: item.AccountName,
        });
      } else if (LinkWith === "Group") {
        optionsToLinkWith.push({
          label: item.OppoName,
          value: item.GroupOppoID,
          AccountID: item.AccountID,
          AccountName: item.AccountName,
        });
      } else if (LinkWith === "Meeting and Catering") {
        optionsToLinkWith.push({
          label: item.OppoName,
          value: item.MeetingAndCateringOppoID,
          AccountID: item.AccountID,
          AccountName: item.AccountName,
        });
      } else {
        optionsToLinkWith = [];
      }
    });

  async function getSuspectdata(propertyID) {
    let input = {
      leadID: "00000000-0000-0000-0000-000000000000",
      clientID: clientID,
      ownerID: "00000000-0000-0000-0000-000000000000",
      createdOn: "",
      companyName: "",
      firstName: "",
      lastName: "",
      telephoneNo: "",
      mobileNo: "",
      email: "",
      lead_Status_Term: null,
      lead_Source_Term: "",
      lastContactedOn: "",
      lastContactedBy: "00000000-0000-0000-0000-000000000000",
      leadRatings: null,
      propertyIDs: propertyID,
      dosid: "00000000-0000-0000-0000-000000000000",
      priority: "",
      isConvertAcc: false,
    };
    setLoading(true);
    const res: any = await getLeadListData(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          if (SelectedPropertyIDs.SelectedPropertyID) {
            setGetDatalist(res.data.data.leadList);
            setLoading(false);
          }
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function getAccountListData(propertyID) {
    let input = {
      clientID: clientID,
      accountStatus: null,
      accountType: null,
      propertyIDs: propertyID,
      stayPattern: null,
      userIDs: null,
      createdFrom: null,
      createdTo: null,
      modifiedFrom: null,
      modifiedTo: null,
      searchText: null,
    };
    setLoading(true);
    const res: any = await getAccountList(input, Token);

    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetDatalist(res.data.data.dataList1);
          setLoading(false);
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function contactdetails(propertyID) {
    let input: any = {
      clientID: clientID,
      propertyIDs: propertyID,
      accountID: null,
      searchText: "",
    };
    setLoading(true);
    let res: any = await getContactListing(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetDatalist(res.data.data.dataList1);
          setLoading(false);
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function getRFPListData(propertyID) {
    let input = {
      clientID: clientID,
      accountID: "00000000-0000-0000-0000-000000000000",
      hotelIDs: propertyID,
      year: 0,
    };
    setLoading(true);
    const res: any = await getRFPList(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetDatalist(res.data.data.dataList1);
          setLoading(false);
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function getLNRListData(propertyID) {
    let input = {
      clientID: clientID,
      accountID: "00000000-0000-0000-0000-000000000000",
      hotelIDs: propertyID,
      year: 0,
    };
    setLoading(true);
    const res: any = await getLNRList(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetDatalist(res.data.data.dataList1);
          setLoading(false);
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function getGroupListData(propertyID) {
    let input = {
      clientID: clientID,
      accountID: "00000000-0000-0000-0000-000000000000",
      hotelIDs: propertyID,
      year: 0,
    };
    setLoading(true);
    const res: any = await getGroupList(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== "" && res.data.data !== null) {
          if (res.data.data.dataList1 !== undefined) {
            setGetDatalist(res.data.data.dataList1);
            setLoading(false);
          } else {
            setGetDatalist([]);
            setLoading(false);
          }
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function getMeetingCateringListData(propertyID) {
    let input = {
      clientID: clientID,
      accountID: "00000000-0000-0000-0000-000000000000",
      hotelIDs: propertyID,
      year: 0,
    };
    setLoading(true);
    const res: any = await getMeetingCateringList(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== "" && res.data.data !== null) {
          if (res.data.data.dataList1 !== undefined) {
            setGetDatalist(res.data.data.dataList1);
            setLoading(false);
          } else {
            setGetDatalist([]);
            setLoading(false);
          }
        } else {
          setGetDatalist([]);
          setLoading(false);
        }
      } else {
        setGetDatalist([]);
        setLoading(false);
      }
    } else {
      setGetDatalist([]);
      setLoading(false);
    }
  }

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: U_UserID,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          // Pre Selected Hotel Fill Start:
          if (SelectedPropertyName) {
            if (!SelectedPropertyIDs.SelectedPropertyID.includes(",")) {
              let temp: any = res.data.data.dataList1.filter(
                (itm) => itm.PropertyID === SelectedPropertyIDs.SelectedPropertyID
              );

              setEmailLinkInput({
                ...emailLinkInput,
                hotelName: temp[0].HotelCode + " " + temp[0].PropertyName,
                hotelID: temp[0].PropertyID,
              });

              setSelectedHotel({
                label: temp[0].HotelCode + " " + temp[0].PropertyName,
                value: temp[0].PropertyID,
              });
            }
          }
          // Pre Selected Hotel Fill End :

          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  React.useEffect(() => {
    setSelectedHTML(crmExtensionGetSelectedHtml());
    getHotelsList();
  }, [SelectedPropertyName]);

  async function SaveEmailLinkForm() {
    let obj = fnCheckValidationOfObject(emailLinkInput);
    setEmailLinkInput({
      ...obj.obj,
    });
    if (obj.isValid) {
      // For Asking confirmation to user :

      Swal.fire({
        title: `Area you would like saved as your activity? `,
        text: `For ${emailLinkInput.moduleName} : ${emailLinkInput.linkWith} `,
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Save",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let input: any = {
            activityID: "00000000-0000-0000-0000-000000000000",
            ownerID: null,
            associationID: null,
            associationType_Term: "",
            accountName: "",
            assignTo: U_UserID,
            otherUsers: "",
            activityStatus_Term: "Complete",
            activityType_Term: "Email",
            priority_Term: "Low",
            startDate: new Date(),
            duration: 0,
            endDate: new Date(),
            emailReminders_BeforeHrs: 0,
            activitySubject:
              props.subject !== undefined && props.subject !== null && props.subject !== ""
                ? props.subject
                : "(no subject)",
            activityDetails:
              props.folder === "microsoft"
                ? selectedHTML
                  ? selectedHTML
                  : props.MailDetails
                : selectedHTML
                ? selectedHTML
                : props.MailDetails,

            accountID: "00000000-0000-0000-0000-000000000000",
            isFollowUp: false,
            followUpOnActivityID: null,
            isInComing: false,
            contactID: null,
            emailSendTo: "",
            hotelID: emailLinkInput.hotelID,
            strDuration: "",
            activityTime: "",
            reminderSendEmail: false,
            assigntoUserName: U_DisplayName,
          };

          if (emailLinkInput.moduleName === "Lead") {
            input.associationType_Term = "Lead";
            input.accountID = null;
            input.accountName = emailLinkInput.linkWith;
            input.associationID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/suspect/details", {
                  state: {
                    leadID: emailLinkInput.linkWithID,
                    leadName: emailLinkInput.linkWith,
                    activeKey: "Activities",
                  },
                });
                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else if (emailLinkInput.moduleName === "Account") {
            input.associationType_Term = "Accounts";
            input.accountID = emailLinkInput.linkWithID;
            input.accountName = emailLinkInput.linkWith;
            input.associationID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/accounts-view", {
                  state: {
                    AccountID: emailLinkInput.linkWithID,
                    AccountName: emailLinkInput.linkWith,
                    activeKey: "Activities",
                  },
                });
                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else if (emailLinkInput.moduleName === "Contact") {
            input.associationType_Term = "Contact";
            input.accountID = emailLinkInput.AccountID;
            input.accountName = emailLinkInput.AccountName;
            input.associationID = emailLinkInput.linkWithID;
            input.contactID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/contact/details", {
                  state: {
                    ContactID: emailLinkInput.linkWithID,
                    ContactName: emailLinkInput.linkWith,
                    activeKey: "Activities",
                  },
                });
                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else if (emailLinkInput.moduleName === "RFP") {
            input.associationType_Term = "RFP Oppo";
            input.accountID = emailLinkInput.AccountID;
            input.accountName = emailLinkInput.AccountName;
            input.associationID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/Opportunity/RFP/details", {
                  state: { OppoID: emailLinkInput.linkWithID, activeKey: "Activities" },
                });

                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else if (emailLinkInput.moduleName === "LNR") {
            input.associationType_Term = "LNR Oppo";
            input.accountID = emailLinkInput.AccountID;
            input.accountName = emailLinkInput.AccountName;
            input.associationID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/Opportunity/LNR/details", {
                  state: { LNROppoID: emailLinkInput.linkWithID, activeKey: "Activities" },
                });

                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else if (emailLinkInput.moduleName === "Group") {
            input.associationType_Term = "Group Oppo";
            input.accountID = emailLinkInput.AccountID;
            input.accountName = emailLinkInput.AccountName;
            input.associationID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/Opportunity/Group/details", {
                  state: { GroupOppoID: emailLinkInput.linkWithID, activeKey: "Activities" },
                });

                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          } else if (emailLinkInput.moduleName === "Meeting and Catering") {
            input.associationType_Term = "MAC Oppo";
            input.accountID = emailLinkInput.AccountID;
            input.accountName = emailLinkInput.AccountName;
            input.associationID = emailLinkInput.linkWithID;
            setloadProcess(true);
            let res: any = await saveActivities(input, Token);
            setloadProcess(false);
            if (res.data !== null && res.data !== undefined) {
              if (res.data.statusCode === 200) {
                navigate("/Opportunity/MeetingAndCatering/details", {
                  state: { MACOppoID: emailLinkInput.linkWithID, activeKey: "Activities" },
                });

                props.onHide;
                props.setModalShow(false);
              } else {
                ToasterError(res.data.message);
              }
            } else {
              ToasterError(res.message);
            }
          }
        }
      });
    }
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-30w"
      className="chart-popup gmail_inbox link_email_modal"
      //className="chart-popup gmail_inbox"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Link Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {Loading ? (
          <div className="calender-loader-main">
            <PageLoader />
          </div>
        ) : (
          ""
        )}
        <Row>
          <Col xs={12} className="mb-3">
            <div className={`${emailLinkInput.errors.hotelName && "red-border-masking"}`}>
              <FloatingLabel controlId="floatingSelectGrid" label="">
                <Select
                  options={MyOption}
                  value={selectedHotel}
                  // defaultValue={ MyOption.filter(
                  //   (itm) =>
                  //   emailLinkInput.hotelID && itm.value.includes(emailLinkInput.hotelID)
                  // )}
                  onChange={(e: any) => {
                    setEmailLinkInput({
                      ...emailLinkInput,
                      hotelName: e.label,
                      hotelID: e.value,
                      linkWith: "",
                    });
                    setSelectedHotel(e);
                    setSelectedOption({
                      label: "Select Option",
                      value: null,
                    });
                    optionsToLinkWith = [];
                    if (LinkWith === "Lead") {
                      getSuspectdata(e.value);
                    } else if (LinkWith === "Account") {
                      getAccountListData(e.value);
                    } else if (LinkWith === "Contact") {
                      contactdetails(e.value);
                    } else if (LinkWith === "RFP") {
                      getRFPListData(e.value);
                    } else if (LinkWith === "LNR") {
                      getLNRListData(e.value);
                    } else if (LinkWith === "Group") {
                      getGroupListData(e.value);
                    } else if (LinkWith === "Meeting and Catering") {
                      getMeetingCateringListData(e.value);
                    }
                  }}
                  name="colors"
                  placeholder="Select Hotel"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  isDisabled={false}
                />
              </FloatingLabel>
            </div>
            <small style={{ color: "#dc3545" }}>
              {emailLinkInput.errors.hotelName && emailLinkInput.errors.hotelName}
            </small>
          </Col>

          <Col xs={12} className="mb-3">
            <div className={`${emailLinkInput.errors.moduleName && "red-border-masking"}`}>
              <FloatingLabel controlId="floatingSelectGrid" label="">
                <Select
                  placeholder="Select Module"
                  options={optionsLinkWith}
                  onChange={(e: any) => {
                    optionsToLinkWith = [];
                    // setSelectedHotel({
                    //   label: "Select Hotel",
                    //   value: null,
                    // });
                    setSelectedOption({
                      label: "Select Option",
                      value: null,
                    });
                    setEmailLinkInput({
                      ...emailLinkInput,
                      moduleName: e.label,
                      // hotelName: "",
                      linkWith: "",
                    });
                    setLinkWith(e.label);
                    if (e.label === "Lead") {
                      getSuspectdata(emailLinkInput.hotelID);
                    } else if (e.label === "Account") {
                      getAccountListData(emailLinkInput.hotelID);
                    } else if (e.label === "Contact") {
                      contactdetails(emailLinkInput.hotelID);
                    } else if (e.label === "RFP") {
                      getRFPListData(emailLinkInput.hotelID);
                    } else if (e.label === "LNR") {
                      getLNRListData(emailLinkInput.hotelID);
                    } else if (e.label === "Group") {
                      getGroupListData(emailLinkInput.hotelID);
                    } else if (e.label === "Meeting and Catering") {
                      getMeetingCateringListData(emailLinkInput.hotelID);
                    }
                  }}
                />
              </FloatingLabel>
            </div>
            <small style={{ color: "#dc3545" }}>
              {emailLinkInput.errors.moduleName && emailLinkInput.errors.moduleName}
            </small>
          </Col>

          <Col xs={12} className="mb-3">
            <div className={`${emailLinkInput.errors.linkWith && "red-border-masking"}`}>
              <FloatingLabel controlId="floatingSelectGrid" label="">
                <Select
                  placeholder="Select"
                  value={selectedOption}
                  options={optionsToLinkWith}
                  onChange={(e: any) => {
                    setSelectedOption(e);
                    setEmailLinkInput({
                      ...emailLinkInput,
                      linkWith: e.label,
                      linkWithID: e.value,
                      AccountID: e.AccountID,
                      AccountName: e.AccountName,
                    });
                  }}
                />
              </FloatingLabel>
            </div>
            <small style={{ color: "#dc3545" }}>
              {emailLinkInput.errors.linkWith && emailLinkInput.errors.linkWith}
            </small>
          </Col>
          <Col xs={12} className="">
            <h6>Details</h6>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="mb-3">
            <div
              id="EmailHtmlData"
              style={{
                width: "100%", // Set your desired fixed width
                height: "300px", // Set your desired fixed height
                overflow: "auto", // Enable scrolling
                border: "1px solid #ccc", // Add a border for visibility
                borderRadius: "5px", // Optional: Add border radius for a rounded appearance
              }}
              dangerouslySetInnerHTML={{
                __html: selectedHTML ? selectedHTML : props.MailDetails,
              }}
            ></div>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button variant="primary" disabled={loadProcess ? true : false} onClick={SaveEmailLinkForm}>
          <span className="me-2">
            <img src={WhiteSaveIcon} alt="" />
          </span>
          Generate Activity
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GmailInboxLinkWith;
