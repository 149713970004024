import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { GetMailDetailsByFolder } from "../../Services/GmailServices/MailServices";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../../../Asset/Images/Icons/blackCancelIcon.svg";
// import GmailInboxLinkWith from "../GmailInboxLinkWith";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import { EmailAttachments } from "../../Microsoft/Microsoft/components/graph";
import { loginRequest } from "../../../Views/Microsoft/components/authConfig";

import {
  FaFile,
  FaFileAlt,
  FaFileArchive,
  FaFileCode,
  FaFileExcel,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileWord,
  FaGoogle,
} from "react-icons/fa";
import GmailInboxLinkWith from "../../GmailInboxLinkWith";
import { EmailAttachments } from "../../../Views/Microsoft/components/graph";

const MicrosoftViewCommonModal = (props) => {
  const { instance, accounts } = useMsal();

  const dataSelector: any = useSelector((state: any) => state.session);
  const userId = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;

  const [ModalShowLinkWith, setModalShowLinkWith] = React.useState(false);
  const [AttachmentData, setAttachmentData] = useState([]);

  // const [modalShowChartFirst, setModalShowChartFirst] = React.useState(false);
  const [MailDetails, setMailDetails]: any = React.useState({
    IsData: true,
    data: "",
  });

  const contentTypeToIcon = {
    "application/pdf": <FaFilePdf />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <FaFileExcel />,
    "application/vnd.google-apps.spreadsheet": <FaGoogle />,
    "application/msword": <FaFileWord />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": <FaFileWord />,
    "application/vnd.ms-powerpoint": <FaFilePowerpoint />,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": (
      <FaFilePowerpoint />
    ),
    "application/zip": <FaFileArchive />,
    "application/x-rar-compressed": <FaFileArchive />,
    "application/x-tar": <FaFileArchive />,
    "application/x-7z-compressed": <FaFileArchive />,
    "application/x-gzip": <FaFileArchive />,
    "application/x-bzip2": <FaFileArchive />,
    "text/plain": <FaFileAlt />,
    "text/html": <FaFileAlt />,
    "text/css": <FaFileAlt />,
    "text/javascript": <FaFileCode />,
    "application/json": <FaFileCode />,
    "application/xml": <FaFileCode />,
  };

  async function getMailAttachData(id) {
    setMailDetails({ IsData: false, data: [] });
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        EmailAttachments(response.accessToken, id)
          .then((Response) => {
            setMailDetails({ IsData: true, data: props.SelectedMailDetails });
            setAttachmentData(Response?.value);
          })
          .catch((error) => {
            console.error("email inbox error ::", error);
            setMailDetails({ IsData: true, data: [] });
          });
      })
      .catch((error) => {
        console.error("Error acquiring access token:", error);
      });
  }

  useEffect(() => {
    if (props.SelectedMailDetails && props.SelectedMailDetails.id && props.folderName) {
      getMailAttachData(props.SelectedMailDetails.id);
    }
  }, []);

  function fnImg(value) {
    let img = document.querySelectorAll('img[src="' + "cid:" + value.contentId + '"]');
    if (img !== null && img.length !== 0) {
      for (let i = 0; i < img.length; i++) {
        img[i].src = `data:${value.contentType};base64,${value.contentBytes}`; // `data:${value.mimeType};base64,${value.data.data
        // .replace(/-/g, `+`)
        // .replace(/_/g, `/`)}`;
      }
    }
  }

  return (
    <Modal
      {...props}
      className="GmailModalMain"
      size="lg"
      dialogClassName="modal-50w modal-dialog-scrollable"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="information-division">
            <h3 className="inner-header m-0">
              {props.SelectedMailDetails !== undefined &&
              props.SelectedMailDetails !== null &&
              props.SelectedMailDetails !== "" &&
              props.SelectedMailDetails
                ? props.SelectedMailDetails.subject !== undefined &&
                  props.SelectedMailDetails.subject !== null &&
                  props.SelectedMailDetails.subject !== ""
                  ? props.SelectedMailDetails.subject
                  : "(no subject)"
                : "-"}
            </h3>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} xl={12} className="mb-3 mb-xl-0">
            <div className="information-division">
              <div className="tab-inner-box position-relative">
                <p className="box-detials">
                  {MailDetails.IsData ? (
                    <>
                      <p
                        id="EmailHtmlData"
                        dangerouslySetInnerHTML={{
                          __html: MailDetails?.data?.body?.content,
                        }}
                      ></p>
                      <div>
                        {AttachmentData?.length > 0 &&
                          AttachmentData.map(
                            (atc_item, attachmentIndex) =>
                              atc_item !== undefined && (
                                <div key={attachmentIndex} className="attachment mt-3">
                                  {atc_item.contentType.startsWith("image/") ? (
                                    <>
                                      {fnImg(atc_item)}
                                      {/* <img
                                        src={`data:${atc_item.contentType};base64,${atc_item.contentBytes}`}
                                        alt={atc_item.name}
                                        className="image-attachment"
                                      /> */}
                                    </>
                                  ) : (
                                    <></>
                                    // <div className="non-image-attachment">
                                    //   <div className="attachment-icon">
                                    //     {contentTypeToIcon[atc_item.contentType] || <FaFile />}
                                    //   </div>
                                    //   <div className="attachment-details">
                                    //     <p className="attachment-name">{atc_item.name}</p>
                                    //     <p className="attachment-type">{atc_item.contentType}</p>
                                    //   </div>
                                    //   <a
                                    //     href={atc_item.contentLocation}
                                    //     target="_blank"
                                    //     rel="noopener noreferrer"
                                    //     className="attachment-download-link"
                                    //   >
                                    //     Download
                                    //   </a>
                                    // </div>
                                  )}
                                </div>
                              )
                          )}
                      </div>
                    </>
                  ) : (
                    <Skeleton count={20} height={20} />
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            {" "}
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          disabled={
            MailDetails.data !== undefined && MailDetails.data !== "" && MailDetails.data !== null
              ? false
              : true
          }
          onClick={() => setModalShowLinkWith(true)}
          variant="primary"
          // className="savebtnclient btn btn-primary float-start me-2 mt-3"
        >
          Link With &nbsp;<i className="fa-regular fa-arrow-right"></i>
        </Button>
      </Modal.Footer>
      {ModalShowLinkWith === true && (
        <GmailInboxLinkWith
          getRequestData={props.SelectedMailDetails && props.SelectedMailDetails}
          subject={
            props.SelectedMailDetails !== undefined &&
            props.SelectedMailDetails !== null &&
            props.SelectedMailDetails !== "" &&
            props.SelectedMailDetails
              ? props.SelectedMailDetails.subject !== undefined &&
                props.SelectedMailDetails.subject !== null &&
                props.SelectedMailDetails.subject !== ""
                ? props.SelectedMailDetails.subject
                : "(no subject)"
              : "-"
          }
          MailDetails={document.getElementById("EmailHtmlData")?.innerHTML}
          // MailDetails={MailDetails}
          setModalShow={props.onHide}
          folder={"microsoft"}
          show={ModalShowLinkWith}
          onHide={() => setModalShowLinkWith(false)}
        />
      )}
    </Modal>
  );
};

export default MicrosoftViewCommonModal;
