import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import HardRet from "../../Asset/Images/Other/hard-ret.svg";
import ReportAccounts from "../../Asset/Images/Other/report-accounts.svg";
import ReportCalendar from "../../Asset/Images/Other/report-calendar.svg";
import ReportEmail from "../../Asset/Images/Other/report-email.svg";
import ReportNormal from "../../Asset/Images/Other/report-normal.svg";
import ReportNots from "../../Asset/Images/Other/report-nots.svg";
import ReportUserCall from "../../Asset/Images/Other/report-user-call.svg";
import ReportUser from "../../Asset/Images/Other/report-user.svg";

import {
  getActivitiesReportList,
  getActivitiesReportListExportPDF,
  getWeeklyReportList,
  MeetingCateringByDateReportList,
  OpportunityBySourceReportList,
} from "../../Services/ReportServices";
import XLSX, { utils } from "sheetjs-style";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { Button, Col, Modal, Row } from "react-bootstrap";
import TableView from "../../Component/Table/Index";
import {
  AppConfigData,
  CommonService,
  getDateInFormat,
  ToasterError,
} from "../../Services/CommonServices";
import moment from "moment";
import PageLoader from "../../Component/Loader/PageLoader";
import { useNavigate } from "react-router-dom";

function ActivityReportModal(props: any) {
  const [loading, setLoading] = useState(false);
  const [LoadProcess, setLoadProcess] = useState(false);

  const [ActivityReport, setActivityReport]: any = useState([]);

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [dateTitle, setdateTitle]: any = useState({
    stDate: "",
    endDate: "",
  });

  React.useEffect(() => {
    // if (props.MeetingAndCateringTitle) {
    //   setdateTitle({
    //     ...dateTitle,
    //     stDate: getDateInFormat(props.MeetingAndCateringTitle.startDate),
    //     endDate: getDateInFormat(props.MeetingAndCateringTitle.endDate),
    //   });
    // }

    getActivityReportData();
  }, []);

  async function getActivityReportData() {
    let input: any = {
      // propertyIDs: props.ReportData.propertyIDs,
      propertyID: props.ReportData.propertyIDs,
      accountIDs: props.ReportData.accountID,
      startDate: props.ReportData.startDate,
      endDate: props.ReportData.endDate,
    };

    setLoading(true);
    let res: any = await getActivitiesReportList(input, Token);
    setLoading(false);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== null && res.data.data !== undefined) {
          setActivityReport(res.data.data);
          // window.open(AppConfigData.APIBasePath + res.data.data.lstReportData);
          // props.onHide(false);
        } else {
          ToasterError("Something Went Wrong.");
        }
      } else {
        ToasterError("Something Went Wrong.");
      }
    }
  }

  const exportActivitiesReportPDF = async () => {
    let input: any = {
      propertyID: props.ReportData.propertyIDs,
      accountIDs: props.ReportData.accountID,
      startDate: props.ReportData.startDate,
      endDate: props.ReportData.endDate,
    };

    setLoadProcess(true);
    let res: any = await getActivitiesReportListExportPDF(input, Token);
    setLoadProcess(false);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== "" && res.data.data !== null && res.data.data !== undefined) {
          window.open(AppConfigData.APIBasePath + res.data.data);
          // props.onHide(false);
        } else {
          ToasterError(res.data.message);
        }
      } else {
        ToasterError("Something Went Wrong.");
      }
    } else {
      ToasterError("Something Went Wrong.");
    }
  };

  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_LNR = CommonService.getPageRight("LNR");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_Group = CommonService.getPageRight("Group");
  let page_rights_MeetingAndCatering = CommonService.getPageRight("MeetingAndCatering");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Lead = CommonService.getPageRight("Lead");

  let navigate: any = useNavigate();

  function handleNavigate(itm) {
    if (itm.AssociationType_Term.toLowerCase() == "rfp oppo") {
      if (page_rights_RFP.is_view) {
        navigate("/Opportunity/RFP/details", { state: { OppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lnr oppo") {
      if (page_rights_LNR.is_view) {
        navigate("/Opportunity/LNR/details", { state: { LNROppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "group oppo") {
      if (page_rights_Group.is_view) {
        navigate("/Opportunity/Group/details", { state: { GroupOppoID: itm.AssociationID } });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "mac oppo") {
      if (page_rights_MeetingAndCatering.is_view) {
        navigate("/Opportunity/MeetingAndCatering/details", {
          state: { MACOppoID: itm.AssociationID },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lead") {
      if (page_rights_Lead.is_view) {
        navigate("/suspect/details", {
          state: { leadID: itm.AssociationID, leadName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "contact") {
      if (page_rights_Contacts.is_view) {
        navigate("/contact/details", {
          state: { ContactID: itm.AssociationID, ContactName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }

    if (itm.AssociationType_Term.toLowerCase() == "account") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "accounts") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
        // props.onHide(true);
      } else {
        CommonService.unauthorizedAction();
      }
    }
  }

  return (
    <Modal {...props} fullscreen>
      <Modal.Header>
        <Modal.Title>
          Activity Report
          {/* <h6>
            {props.ReportData.propertyName}
            <br />
            Date :{" "}
            {moment(props.ReportData.startDate).format("MM/DD/YYYY") +
              " - " +
              moment(props.ReportData.endDate).format("MM/DD/YYYY")}{" "}
            <br />
            Type : ALL
          </h6> */}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          {ActivityReport.datePropertyData &&
          ActivityReport.datePropertyData.PropertyLogo !== undefined &&
          ActivityReport.datePropertyData.PropertyLogo ? (
            <>
              <div className="text-center">
                <img
                  src={
                    ActivityReport.datePropertyData && ActivityReport.datePropertyData.PropertyLogo
                  }
                  style={{ width: "200px", maxHeight: "150px" }}
                  alt=""
                />
              </div>
            </>
          ) : (
            ""
          )}
          {ActivityReport && ActivityReport.datePropertyData && ActivityReport.datePropertyData && (
            <div className="report-col mt-3">
              <div className="report-title">
                <span>
                  {ActivityReport.datePropertyData && ActivityReport.datePropertyData.PropertyName}
                </span>
              </div>
            </div>
          )}
        </Row>
        <Row className="mt-3">
          <div className="information-division">
            <h3 className="inner-header m-0">
              Previous Activities
              {ActivityReport.datePropertyData &&
                " (" +
                  ActivityReport.datePropertyData.StartDate +
                  " - " +
                  ActivityReport.datePropertyData.EndDate +
                  ")"}
            </h3>
          </div>

          <Col md={12}>
            <Row>
              {/* {ActivityReport && ActivityReport.length  ? 
                ActivityReport.map((myCount) => ( */}
              <>
                {!loading ? (
                  ActivityReport.lstReportData && ActivityReport.lstReportData.length ? (
                    ActivityReport.lstReportData.map((myActivityData) => (
                      <>
                        <Col lg={6} xl={6} className="mb-3">
                          <Card className="border-0">
                            <Card.Body>
                              <Row className="marginbottom-10px">
                                <Col xs={9}>
                                  <div className="d-flex flex-direction-row align-items-start">
                                    <div className="reports-icons me-2">
                                      <img src={HardRet} alt="" />
                                    </div>
                                    <div className="report-card-title">
                                      {/* Call Mr Horner for details, then refer to groups on site */}
                                      {myActivityData.Objective}
                                    </div>
                                  </div>
                                </Col>
                                <Col
                                  xs={3}
                                  className="d-flex justify-content-end align-items-start"
                                >
                                  {myActivityData.Status === "Open" ? (
                                    <label className="danger-label">{myActivityData.Status}</label>
                                  ) : (
                                    <label className="success-label">{myActivityData.Status}</label>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12} className="marginbottom-10px">
                                  <div className="d-flex flex-direction-row align-items-center">
                                    <div className="reports-icons me-2">
                                      <img src={ReportUser} alt="" />
                                    </div>
                                    <div
                                      className="report-content-one report-user-content"
                                      role="button"
                                      tabIndex={-1}
                                      style={{ cursor: "pointer", color: "blue" }}
                                      onClick={() => {
                                        handleNavigate(myActivityData);
                                      }}
                                      onKeyDown={() => {
                                        handleNavigate(myActivityData);
                                      }}
                                    >
                                      {myActivityData.AccountName
                                        ? myActivityData.AccountName.replace("#", " ")
                                        : ""}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} className="marginbottom-10px">
                                  <div className="d-flex flex-direction-row align-items-center">
                                    <div className="reports-icons me-2">
                                      <img src={ReportUserCall} alt="" />
                                    </div>
                                    <div className="report-content-one">
                                      {myActivityData.ContactName}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6} className="marginbottom-10px">
                                  <div className="d-flex flex-direction-row align-items-center">
                                    <div className="reports-icons me-2">
                                      <img src={ReportNormal} alt="" />
                                    </div>
                                    <div className="report-content-one">
                                      {myActivityData.Priority}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} className="marginbottom-10px">
                                  <div className="d-flex flex-direction-row align-items-center">
                                    <div className="reports-icons me-2">
                                      <img src={ReportEmail} alt="" />
                                    </div>
                                    <div className="report-content-one">
                                      {/* {myActivityData.ActivityType_Term} */}
                                      {myActivityData.Type}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} className="marginbottom-10px">
                                  <div className="d-flex flex-direction-row align-items-center">
                                    <div className="reports-icons me-2">
                                      {/* <img src={ReportAccounts} alt="" /> */}
                                      <img src={ReportUser} alt="" />
                                    </div>
                                    <div className="report-content-one">
                                      {myActivityData.UserName}
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={6} className="marginbottom-10px">
                                  <div className="d-flex flex-direction-row align-items-center">
                                    <div className="reports-icons me-2">
                                      <img src={ReportCalendar} alt="" />
                                    </div>
                                    <div className="report-content-one">
                                      {myActivityData.StartDate} - {myActivityData.CompleteDate}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={12}>
                                  <div
                                    className="d-flex flex-direction-row align-
                                      s-start mt-2"
                                  >
                                    <div className="reports-icons me-2">
                                      <img src={ReportNots} alt="" />
                                    </div>
                                    <div className="report-content-one">
                                      <div
                                        className="activity_detail_div_html"
                                        dangerouslySetInnerHTML={{
                                          __html: myActivityData.Results
                                            ? myActivityData.Results
                                            : "-",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </>
                    ))
                  ) : (
                    <tr className="h-30" align="center">
                      <td colSpan={22}> No data found ...! </td>
                    </tr>
                  )
                ) : (
                  <PageLoader />
                )}
              </>
              {/* ))

                : "No record Found...!"
              
              } */}
            </Row>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          disabled={
            ActivityReport.lstReportData !== undefined &&
            ActivityReport.lstReportData &&
            ActivityReport.lstReportData.length &&
            LoadProcess === false
              ? false
              : true
          }
          onClick={(e) => {
            e.preventDefault();
            exportActivitiesReportPDF();
          }}
        >
          <span className="me-2">
            <img src={PdfIcon} alt="" />
          </span>
          Export PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivityReportModal;
