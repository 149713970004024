import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getWeeklyReportList,
  MeetingCateringByDateReportList,
  OpportunityBySourceReportList,
} from "../../Services/ReportServices";
import XLSX, { utils } from "sheetjs-style";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { Button, Modal } from "react-bootstrap";
import WeeklyReportUserData from "./WeeklyReportUserData";
import TableView from "../../Component/Table/Index";
import {
  CommonService,
  getDateInFormat,
  getDateTimeInDBFormat,
  getDateInDBFormatForExcell,
  getSortDataByObjKey,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
} from "../../Services/CommonServices";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function OpportunityBySourceReportModal(props: any) {
  const [loading, setLoading] = useState(false);
  const [SourceReportTotalRecord, setSourceReportTotalRecord]: any = useState([]);

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [dateTitle, setdateTitle]: any = useState({
    stDate: "",
    endDate: "",
  });
  // React.useEffect(() => {

  //   if (props.OppoBySourceReport) {
  //     getOppoBySourceReport();
  //   }
  // }, [SelectedPropertyID]);

  React.useEffect(() => {
    setdateTitle({
      ...dateTitle,
      stDate: getDateInFormat(props.reportTitle.startDate),
      endDate: getDateInFormat(props.reportTitle.endDate),
    });

    getOppoBySourceReport();
  }, []);
  const tableOppoSourceHeaders = useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "PropertyName",
      },
      {
        Header: "Account",
        accessor: "AccountName",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Opportunity",
        accessor: "OppoName",
      },
      {
        Header: "OppoOwner",
        accessor: "OppoOwner",
      },
      {
        Header: "OpportunityType",
        accessor: "OpportunityType",
      },

      {
        Header: "Rate StartDate",
        accessor: "RateStartDate",
      },
      {
        Header: "Rate EndDate",
        accessor: "RateEndDate",
      },
      {
        Header: "Anticipated Revenue",
        accessor: "AnticipatedRevenue",
      },
      {
        Header: "Lost Reason",
        accessor: "LostReason",
      },
    ],
    []
  );

  let page_rights_account = CommonService.getPageRight("Account");
  let navigate = useNavigate();
  var rightAlignClass = { textAlign: "right" };
  var centerAlignClass = { textAlign: "center" };

  let tableOppoSourceData: any = [];
  SourceReportTotalRecord.length > 0 &&
    SourceReportTotalRecord.map((item) => {
      tableOppoSourceData.push({
        className: {
          AnticipatedRevenue: "rightAlignClass text-end",
          RateStartDate: "rightAlignClass text-end",
          RateEndDate: "rightAlignClass text-end",
        },
        controlRender: {
          AccountName: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (page_rights_account.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              onKeyDown={() => {
                if (page_rights_account.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              {item.AccountName ? item.AccountName.replace("#", " ") : ""}
            </div>
          ),
          RateStartDate: getDateInFormat(item.RateStartDate),
          RateEndDate: getDateInFormat(item.RateEndDate),
        },
        PropertyName: item.PropertyName,
        AccountName: item.AccountName,
        Status: item.Status,
        OppoOwner: item.OppoOwner,
        OppoName: item.OppoName,
        OpportunityType: item.OpportunityType,
        AnticipatedRevenue: "$" + item.AnticipatedRevenue,
        RateStartDate: getDateTimeInDBFormat(item.RateStartDate),
        RateEndDate: getDateTimeInDBFormat(item.RateEndDate),
        LostReason: item.LostReason,
      });
    });

  async function getOppoBySourceReport() {
    setLoading(true);
    let res: any = await OpportunityBySourceReportList(props.OppoBySourceReport, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstReportData !== undefined &&
            res.data.data.lstReportData &&
            res.data.data.lstReportData.length
          ) {
            setSourceReportTotalRecord(res.data.data.lstReportData);
            setLoading(false);
          } else {
            setSourceReportTotalRecord([]);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      setLoading(false);
      ToasterError(res.data.message);
    }
  }

  async function getFilteredExportData(newArray) {
    let localFilters = JSON.parse(localStorage.getItem("oppoBySourceReportFilter"));
    let mainTableData: any = [];
    newArray !== undefined &&
      newArray !== null &&
      newArray.map((item: any) => {
        mainTableData.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
          Status: item.Status,
          OppoOwner: item.OppoOwner,
          OppoName: item.OppoName,
          OpportunityType: item.OpportunityType,
          RateStartDate: getDateInDBFormatForExcell(item.RateStartDate),
          RateEndDate: getDateInDBFormatForExcell(item.RateEndDate),
          AnticipatedRevenue: "$" + item.AnticipatedRevenue,
          LostReason: item.LostReason,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName,
            Status: item.Status,
            OppoOwner: item.OppoOwner,
            OppoName: item.OppoName,
            OpportunityType: item.OpportunityType,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            AnticipatedRevenue: item.AnticipatedRevenue,
            LostReason: item.LostReason,
          });
        });

        return ascDataBind;
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName,
            Status: item.Status,
            OppoOwner: item.OppoOwner,
            OppoName: item.OppoName,
            OpportunityType: item.OpportunityType,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            AnticipatedRevenue: item.AnticipatedRevenue,
            LostReason: item.LostReason,
          });
        });

        return descDataBind;
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName,
            Status: item.Status,
            OppoOwner: item.OppoOwner,
            OppoName: item.OppoName,
            OpportunityType: item.OpportunityType,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            AnticipatedRevenue: item.AnticipatedRevenue,
            LostReason: item.LostReason,
          });
        });

        return normalDataBind;
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName,
          Status: item.Status,
          OppoOwner: item.OppoOwner,
          OppoName: item.OppoName,
          OpportunityType: item.OpportunityType,
          RateStartDate: item.RateStartDate,
          RateEndDate: item.RateEndDate,
          AnticipatedRevenue: item.AnticipatedRevenue,
          LostReason: item.LostReason,
        });
      });
      return normalInitDataBind;
    }
  }

  const exportData = async () => {
    const DateValue = new Date();

    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    let ws = XLSX.utils.json_to_sheet([], {
      header: [
        `Opportunities by Source - Internet Search-` +
          "(" +
          dateTitle.stDate +
          "-" +
          dateTitle.endDate +
          ")",
      ],
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }];
    ws["!merges"] = merge;

    let tableOppoSourceDataExp: any = [];
    SourceReportTotalRecord.length > 0 &&
      SourceReportTotalRecord.map((item) => {
        tableOppoSourceDataExp.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName,
          OppoName: item.OppoName,
          OppoOwner: item.OppoOwner,
          OpportunityType: item.OpportunityType,
          Status: item.Status,
          RateStartDate: getDateInFormat(item.RateStartDate), //&& item.RateStartDate.split("T")[0],
          RateEndDate: getDateInFormat(item.RateEndDate), // && item.RateEndDate.split("T")[0],
          AnticipatedRevenue: item.AnticipatedRevenue,
          LostReason: item.LostReason,
        });
      });

    // ------------------- Sort Data By Filter :

    let newArraySortedData = await getFilteredExportData(tableOppoSourceDataExp);

    // ------------------- Sort Data By Filter :

    XLSX.utils.sheet_add_aoa(ws, [Object.keys(newArraySortedData[0])], { origin: "A3" });
    XLSX.utils.sheet_add_json(ws, newArraySortedData, {
      skipHeader: true,
      origin: "A4",
    });

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    for (var R = rangeTotalAccounts.s.r; R <= rangeTotalAccounts.e.r; ++R) {
      for (var C = rangeTotalAccounts.s.c; C <= rangeTotalAccounts.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else {
            // Comented Old one
            // ws[cell_ref].s = {
            //   ...cellStyle,
            //   alignment: {
            //     ...cellStyle.alignment,
            //     horizontal: C === 1 || C === 2 ? "right" : "left",
            //   },
            // };
            let checkForRightArray = [6, 7, 8];

            if (checkForRightArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "right",
                },
              };
            }
          }
        }
      }
    }

    let cols: any = [];

    Object.keys(newArraySortedData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    let filename =
      "Opportunity By Source" +
      DateValue.getFullYear() +
      "-" +
      (DateValue.getMonth() + 1) +
      "-" +
      DateValue.getDate();
    // let filename = 'Meeting-GRID' + selectedYear.label + '.xlsx';
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename + ".xlsx");

    // let binaryWS = XLSX.utils.json_to_sheet(tableOppoSourceData);
    // var wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
    // XLSX.writeFile(wb, FileName + ".xlsx");
  };

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="report-title">
            <span>
              Opportunity By Source - (
              {props.reportTitle !== undefined &&
                props.reportTitle &&
                moment(props.reportTitle.startDate).format("MM/DD/YYYY") +
                  " - " +
                  moment(props.reportTitle.endDate).format("MM/DD/YYYY")}
              )
            </span>
          </div>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <TableView
          isData={loading}
          columnsData={tableOppoSourceHeaders}
          tableData={tableOppoSourceData}
        />
      </Modal.Body>

      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          disabled={SourceReportTotalRecord.length ? false : true}
          onClick={(e) => {
            e.preventDefault();
            exportData();
          }}
        >
          <span className="me-2">
            <img src={ExportIcon} alt="" />
          </span>
          Export Excel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default OpportunityBySourceReportModal;
