import React, { useState } from "react";
import { Button, Col, FloatingLabel, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import PageLoader from "../../Component/Loader/PageLoader";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import ReportNavigations from "./ReportNavigations";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoalReport from "../../Asset/Images/Other/generate-report.png";
import RFPGridReportModal from "../../Model/Reports/RFPGridReportModal";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import LeadReportModal from "../../Model/Reports/LeadReportModal";
import AccountReportModal from "../../Model/Reports/AccountReportModal";

function AccountReport() {
  let AccountReport_page_rights = CommonService.getPageRight("Account Report");

  const [selectedUsers, setSelectedUsers] = useState([]);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const [UserDropdownData, setUserDropdownData]: any = useState([]);
  const [selHotels, setSelHotels]: any = useState([]);
  const [AllDropdown, setAllDropdown]: any = useState([]);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const [ReportData, setReportData]: any = useState({
    clientID: clientID,
    accountStatus: "",
    accountType: "",
    propertyIDs: "",
    stayPattern: "",
    userIDs: "",
    createdFrom: "",
    createdTo: "",
    modifiedFrom: "",
    modifiedTo: "",
    searchText: "",
    nameStartWith: "",
    source: "",
    errors: {
      propertyIDs: "",
      ValidationRules: [
        {
          FieldName: "propertyIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });
  let [PropertiesListData, setPropertiesListData]: any = React.useState({
    IsData: true,
    data: [],
  });
  const [showReport, setShowReport]: any = useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  let ArrayPropertyDropdown: any = [];

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("accountReportFilter"))) {
      HandleRemoveOtherFilters("accountReportFilter");
      HandleFilters("accountReportFilter", {
        pagePath: "/AccountReport",
        pageName: "accountReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  React.useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);
        setReportData({
          ...ReportData,
          ["propertyIDs"]: SelectedPropertyID,
          ["userIDs"]: "",
        });
        setSelectedUsers([]);
        userByProperty(SelectedPropertyID);
      }
    }
    getAllDropdown();
    getHotelsList();
    getPropertyDropdown();
  }, [SelectedPropertyName]);

  async function userByProperty(data) {
    let input = {
      clientID: clientID,
      propertyIDs: data.toString(),
    };

    sethotelChangeLoading(true);
    const res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          if (
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1 !== ""
          ) {
            setUserDropdownData(res.data.data.dataList1);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  }

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      }
    }
  }

  let HotelsDropdown: any = [{ label: "All Hotels", value: "" }];
  let UsersDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  UserDropdownData !== undefined &&
    UserDropdownData !== null &&
    UserDropdownData.map((item: any) => {
      UsersDropdown.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  let optionStatus: any = [];
  let optionSource: any = [];
  let optionAccountType: any = [];
  let optionStayPattern: any = [
    {
      label: "Mon",
      value: "Mon",
    },
    {
      label: "Tue",
      value: "Tue",
    },
    {
      label: "Wed",
      value: "Wed",
    },
    {
      label: "Thu",
      value: "Thu",
    },
    {
      label: "Fri",
      value: "Fri",
    },
    {
      label: "Sat",
      value: "Sat",
    },
    {
      label: "Sun",
      value: "Sun",
    },
  ];

  AllDropdown !== undefined &&
    AllDropdown !== null &&
    AllDropdown.map((item: any) => {
      if (item.Category === "Account Status") {
        optionStatus.push({
          label: item.TermCode,
          value: item.TermCode,
        });
      }
      if (item.Category === "Source") {
        optionSource.push({
          value: item.Term,
          label: item.Term,
        });
      }
      if (item.Category === "Account Type") {
        optionAccountType.push({
          value: item.Term,
          label: item.Term,
        });
      }
    });

  async function getAllDropdown() {
    let inputs = {
      category: "Source, Account Status, Account Type",
      clientID: clientID,
    };
    const res: any = await getProjectTermByCategory(inputs, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          setAllDropdown(res.data.data.dataList1);
        }
      }
    }
  }

  function getPropertyDropdown() {
    if (
      SelectedPropertyID !== "" &&
      SelectedPropertyID !== undefined &&
      SelectedPropertyID !== null
    ) {
      ArrayPropertyDropdown.push(SelectedPropertyID.length !== 0 && SelectedPropertyID.split(","));
    }
  }
  async function SelectedPropertyData(data: any) {
    let temp: any = [];
    if (data.length) {
      data.map((item: any) => {
        temp.push(item.value);
      });

      let input = {
        clientID: clientID,
        propertyIDs: temp.toString(),
      };
      sethotelChangeLoading(true);
      const res: any = await getEmailAssignToDropdownList(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            if (
              res.data.data.dataList1 !== undefined &&
              res.data.data.dataList1 !== null &&
              res.data.data.dataList1 !== ""
            ) {
              setUserDropdownData(res.data.data.dataList1);

              // Logic for selected user by property.
              if (selectedUsers.length) {
                let resultedUsers: any = [];
                selectedUsers.forEach((element: any) => {
                  if (res.data.data.dataList1.length) {
                    res.data.data.dataList1.forEach((data: any) => {
                      if (data.UserID === element.value) {
                        resultedUsers.push({ label: element.label, value: element.value });
                      }
                    });
                  }
                });
                setSelectedUsers(resultedUsers);
                let fiteredUsers: any = [];
                resultedUsers.forEach((obj) => {
                  fiteredUsers.push(obj.value);
                });

                setReportData({
                  ...ReportData,
                  propertyIDs: temp.toString(),
                  userIDs: fiteredUsers.toString(),
                });
              } else {
                setReportData({
                  ...ReportData,
                  propertyIDs: temp.toString(),
                });
              }
            }
          }
        }
      }
    } else {
      setReportData({
        ...ReportData,
        ["propertyIDs"]: "",
        ["userIDs"]: "",
      });
      setSelectedUsers([]);
      setUserDropdownData([]);
    }
    sethotelChangeLoading(false);
  }

  function selectedUser(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].value);
      }
      setReportData({
        ...ReportData,
        ["userIDs"]: abc.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["userIDs"]: "",
      });
    }
  }

  function selectStatus(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].value);
      }

      setReportData({
        ...ReportData,
        ["accountStatus"]: abc.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["accountStatus"]: "",
      });
    }
  }

  function selectSource(event: any) {
    let tempSource: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        tempSource.push(event[index].value);
      }

      setReportData({
        ...ReportData,
        ["source"]: tempSource.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["source"]: "",
      });
    }
  }

  function selectAccountType(event: any) {
    let tempAccountType: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        tempAccountType.push(event[index].value);
      }

      setReportData({
        ...ReportData,
        ["accountType"]: tempAccountType.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["accountType"]: "",
      });
    }
  }

  function selectStayPattern(event: any) {
    let tempStayPattern: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        tempStayPattern.push(event[index].value);
      }

      setReportData({
        ...ReportData,
        ["stayPattern"]: tempStayPattern.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["stayPattern"]: "",
      });
    }
  }

  function SaveGenerateReport() {
    let obj = fnCheckValidationOfObject(ReportData);
    setReportData({
      ...obj.obj,
    });
    if (obj.isValid) {
      setShowReport(true);
    }
  }

  const [dateCreatedRange, setDateCreatedRange] = useState([null, null]);
  const [startCreatedDate, endCreatedDate] = dateCreatedRange;

  function handleDateCreatedRange(e) {
    let [startCreatedDate, endCreatedDate] = e;
    if (startCreatedDate !== null && endCreatedDate !== null) {
      let x = moment(startCreatedDate).format("MM-DD-YYYY");
      let y = moment(endCreatedDate).format("MM-DD-YYYY");

      setReportData({
        ...ReportData,
        ["createdFrom"]: x,
        ["createdTo"]: y,
      });
    } else {
      setReportData({
        ...ReportData,
        ["createdFrom"]: "",
        ["createdTo"]: "",
      });
    }
  }

  const [dateModifiedRange, setDateModifiedRange] = useState([null, null]);
  const [startModifiedDate, endModifiedDate] = dateModifiedRange;

  function handleDateModifiedRange(e) {
    let [startModifiedDate, endModifiedDate] = e;
    if (startModifiedDate !== null && endModifiedDate !== null) {
      let x = moment(startModifiedDate).format("MM-DD-YYYY");
      let y = moment(endModifiedDate).format("MM-DD-YYYY");

      setReportData({
        ...ReportData,
        ["modifiedFrom"]: x,
        ["modifiedTo"]: y,
      });
    } else {
      setReportData({
        ...ReportData,
        ["modifiedFrom"]: "",
        ["modifiedTo"]: "",
      });
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>

          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {AccountReport_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col lg={12} className="mb-4">
                          <div
                            className={`${ReportData.errors.propertyIDs && "red-border-masking"}`}
                          >
                            <FloatingLabel label="">
                              <Select
                                placeholder="Select Property"
                                options={HotelsDropdown}
                                isMulti
                                value={selHotels}
                                defaultValue={HotelsDropdown.filter(
                                  (itm) =>
                                    ReportData.propertyIDs &&
                                    itm.value.includes(ReportData.propertyIDs)
                                )}
                                onChange={(event: any) => {
                                  if (event.length) {
                                    let data = event.filter((itm) => itm.label === "All Hotels");
                                    HotelsDropdown.splice(data, 1);
                                    if (data.length) {
                                      SelectedPropertyData(HotelsDropdown);
                                      setSelHotels(HotelsDropdown);
                                    } else {
                                      setSelHotels(event);
                                      SelectedPropertyData(event);
                                    }
                                  } else {
                                    setSelHotels([]);
                                    SelectedPropertyData([]);
                                  }
                                }}
                              ></Select>
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.propertyIDs && ReportData.errors.propertyIDs}{" "}
                          </small>
                        </Col>
                        <Col lg={12} className="mb-4">
                          <FloatingLabel controlId="selectUser" label="">
                            <Select
                              placeholder="Select User"
                              options={UsersDropdown}
                              isMulti
                              value={selectedUsers}
                              onChange={(e: any) => {
                                selectedUser(e);
                                setSelectedUsers(e);
                              }}
                            ></Select>
                          </FloatingLabel>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <FloatingLabel controlId="selectUser" label="">
                            <Select
                              placeholder="Select Status"
                              options={optionStatus}
                              onChange={(e: any) => selectStatus(e)}
                              isMulti
                            ></Select>
                          </FloatingLabel>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <FloatingLabel controlId="floatingSelectGrid" label="">
                            <Select
                              options={optionSource}
                              onChange={(e: any) => selectSource(e)}
                              isMulti
                              name="colors"
                              placeholder="Select Source"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isDisabled={false}
                            />
                          </FloatingLabel>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <FloatingLabel controlId="floatingSelectGrid" label="">
                            <Select
                              options={optionAccountType}
                              onChange={(e: any) => selectAccountType(e)}
                              isMulti
                              name="colors"
                              placeholder="Select Account Type"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isDisabled={false}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col lg={6} className="mb-4">
                          <FloatingLabel controlId="floatingSelectGrid" label="">
                            <Select
                              options={optionStayPattern}
                              onChange={(e: any) => selectStayPattern(e)}
                              isMulti
                              name="colors"
                              placeholder="Select Stay Pattern"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              isDisabled={false}
                            />
                          </FloatingLabel>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <div className="form-floating">
                            <DatePicker
                              className="form-control form-control-date datepicker-react"
                              placeholderText="Select Created From & Created To"
                              minDate={new Date("2010/01/01")}
                              maxDate={new Date("9999/12/31")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              selectsRange={true}
                              startDate={startCreatedDate}
                              endDate={endCreatedDate}
                              onChange={(update) => {
                                handleDateCreatedRange(update);
                                setDateCreatedRange(update);
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg={6} className="mb-4">
                          <div className="form-floating">
                            <DatePicker
                              className="form-control form-control-date datepicker-react"
                              placeholderText="Select Modified From & Modified To"
                              minDate={new Date("2010/01/01")}
                              maxDate={new Date("9999/12/31")}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              selectsRange={true}
                              startDate={startModifiedDate}
                              endDate={endModifiedDate}
                              onChange={(update) => {
                                handleDateModifiedRange(update);
                                setDateModifiedRange(update);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <Button
                        variant="primary ms-0"
                        onClick={() => {
                          if (AccountReport_page_rights.is_view) {
                            SaveGenerateReport();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        <span className="me-2">
                          <img src={GoalReport} alt="" />
                        </span>
                        Generate Report
                      </Button>
                    </Col>
                  </Row>

                  {showReport === true && (
                    <AccountReportModal
                      ReportInput={ReportData}
                      show={showReport}
                      onHide={() => setShowReport(false)}
                      showReport={showReport}
                      setShowReport={setShowReport}
                    />
                  )}
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default AccountReport;
