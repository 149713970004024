import errorimg from "../../Asset/Images/Other/eror.png";

function Unauthorized() {
  return (
    <>
      <div className="unauthorized-page vh-100 d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="content-unauthorized-page">
                <div className="image-unauthorized">
                  <img src={errorimg} alt="401 not found" />
                </div>
                <div className="data-unauthorized">
                  <h1>ERROR 401 Unauthorized</h1>
                  <p>
                    Lorem Ipsum Doler sit amet. Lorem Ipsum Doler sit amet. Lorem Ipsum Doler sit
                    amet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Unauthorized;
