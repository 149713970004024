import React, { useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import XLSX from "sheetjs-style";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { AppConfigData, CommonService, ToasterError } from "../../Services/CommonServices";
import {
  getMarketingPlanReportList,
  MainMarketingPlanReportListExport,
  MarketingPlanReportFileExportPDF,
} from "../../Services/ReportServices";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import PageLoader from "../../Component/Loader/PageLoader";

function MarketingPlanReportModal(props) {
  let page_rights = CommonService.getPageRight("Account");
  let navigate = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [loading, setloading] = useState(false);
  const [MarketingReportData, setMarketingReportData]: any = React.useState([]);
  const [Top, setTop]: any = React.useState([]);
  const [Target, setTarget]: any = React.useState([]);
  const [Growth, setGrowth]: any = React.useState([]);
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const [Downloading, setDownloading] = React.useState(false);

  React.useEffect(() => {
    FetchMarketingPlanReportData();
  }, []);

  async function FetchMarketingPlanReportData() {
    let input: any = {
      prevYear: props.marketingPlaneReportData.prevYear,
      prevYearID: props.marketingPlaneReportData.prevYearID,
      propertyID: props.marketingPlaneReportData.propertyID,
      year: props.marketingPlaneReportData.year,
      yearID: props.marketingPlaneReportData.yearID,
      isIncludeMarketSegment: props.marketingPlaneReportData.isIncludeMarketSegment,
    };

    setloading(true);
    let res: any = await getMarketingPlanReportList(input, Token);
    setloading(false);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          let TopAccount: any = [];
          let TargetAccount: any = [];
          let GrowthAccount: any = [];
          res.data.data.lstTopAccountsData.map((item) => {
            TopAccount = [
              {
                ...TopAccount,
                ["AccountName"]: item.AccountName,
              },
            ];
          });
          res.data.data.lstTopAccountsData.map((item) => {
            TargetAccount = [
              {
                ...TargetAccount,
                ["AccountName"]: item.AccountName,
              },
            ];
          });
          res.data.data.lstTopAccountsData.map((item) => {
            GrowthAccount = [
              {
                ...GrowthAccount,
                ["AccountName"]: item.AccountName,
              },
            ];
          });
          setTop(TopAccount);
          setTarget(TargetAccount);
          setGrowth(GrowthAccount);
          setMarketingReportData(res.data.data);
        }
      }
    }
  }

  async function GenerateMarketingPlaneExcel() {
    let input: any = {
      propertyID: props.marketingPlaneReportData.propertyID,
      year: props.marketingPlaneReportData.year,
      prevYear: props.marketingPlaneReportData.prevYear,
      yearID: props.marketingPlaneReportData.yearID,
      prevYearID: props.marketingPlaneReportData.prevYearID,
      isIncludeMarketSegment: props.marketingPlaneReportData.isIncludeMarketSegment,
    };
    setOnProcessHide(true);
    const res: any = await MainMarketingPlanReportListExport(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setOnProcessHide(false);
        } else {
          ToasterError("No Record Found");
          setOnProcessHide(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setOnProcessHide(false);
      }
    } else {
      ToasterError("Something Went Wrong.");
      setOnProcessHide(false);
    }
  }

  // Tables Start :
  //#region
  let CitySpendTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-40">
            <th>Account Name</th>
            <th>City</th>
            <th className="text-center">
              {" "}
              {props.marketingPlaneReportData &&
                props.marketingPlaneReportData.year + " " + "Nights"}
            </th>
            <th className="text-end">
              {props.marketingPlaneReportData &&
                props.marketingPlaneReportData.year + " " + "Revenue"}
            </th>
          </tr>
        </thead>
        <tbody>
          {MarketingReportData.lstCitySpendData !== undefined &&
            MarketingReportData.lstCitySpendData &&
            MarketingReportData.lstCitySpendData.map((table_key, index) => {
              return (
                <tr className="h-40" key={index}>
                  <td>{table_key.AccountName ? table_key.AccountName : "-"}</td>
                  <td>{table_key.City ? table_key.City : "-"}</td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.roomnights ? table_key.roomnights : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.revenue ? table_key.revenue : "-"}
                  </td>
                </tr>
              );
            })}
          {MarketingReportData.lstCitySpendData !== undefined &&
            MarketingReportData.lstCitySpendData &&
            !MarketingReportData.lstCitySpendData.length && (
              <tr className="h-30" align="center">
                <td colSpan={22}> No Record Found </td>
              </tr>
            )}
        </tbody>
      </>
    );
  };

  let HotelligenceTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-40">
            <th style={{ textAlign: "center" }} colSpan={7}>
              Hotel
            </th>
            <th style={{ textAlign: "center" }} colSpan={5}>
              Comp Set
            </th>
            <th colSpan={10}></th>
          </tr>
          <tr className="h-30">
            <th> Agency </th>
            <th> Company </th>
            <th> Revenue </th>
            <th> Variance </th>
            <th> Roomnights </th>
            <th> Variance1 </th>
            <th> ADR </th>
            <th> Compset Revenue </th>
            <th> Compset Variance1 </th>
            <th> Compset Roomnights </th>
            <th> Compset Variance2</th>
            <th> Compset ADR</th>
            <th> GDS Source </th>
            <th> IATA </th>
            <th> PCC </th>
            <th> Address </th>
            <th> Address2 </th>
            <th> City </th>
            <th> State </th>
            <th> Postal Code </th>
            <th> Phone </th>
            <th> Country </th>
          </tr>
        </thead>
        <tbody>
          {/* {Object.keys(groupTableData).map((table_key, index) => { */}
          {MarketingReportData.lstHotelligenceData !== undefined &&
            MarketingReportData.lstHotelligenceData &&
            MarketingReportData.lstHotelligenceData.map((table_key, index) => {
              return (
                <tr className="h-40" key={index}>
                  {/* <td className="bluetext w-25"> */}
                  {/* <td className="settitle text-center"> */}
                  <td>{table_key.Agency ? table_key.Agency : "-"}</td>
                  <td>{table_key.Company ? table_key.Company : "-"}</td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Sub_Revenue ? "$" + table_key.Sub_Revenue : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Sub_Variance1 ? "$" + table_key.Sub_Variance1 : "-"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.Sub_RoomNights ? table_key.Sub_RoomNights : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Sub_Variance2 ? "$" + table_key.Sub_Variance2 : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Sub_ADR ? "$" + table_key.Sub_ADR : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Comp_Set_Revenue ? "$" + table_key.Comp_Set_Revenue : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Comp_Set_Variance1 ? "$" + table_key.Comp_Set_Variance1 : "-"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.Comp_Set_RoomNights ? table_key.Comp_Set_RoomNights : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Comp_Set_Variance2 ? "$" + table_key.Comp_Set_Variance2 : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Comp_Set_ADR ? "$" + table_key.Comp_Set_ADR : "-"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.TACI_GDSSource ? table_key.TACI_GDSSource : "-"}
                  </td>
                  <td>{table_key.TACI_IATA ? table_key.TACI_IATA : "-"}</td>
                  <td>{table_key.TACI_PCC ? table_key.TACI_PCC : "-"}</td>
                  <td>{table_key.TACI_Address ? table_key.TACI_Address : "-"}</td>
                  <td>{table_key.TACI_Address2 ? table_key.TACI_Address2 : "-"}</td>
                  <td>{table_key.TACI_City ? table_key.TACI_City : "-"}</td>
                  <td>{table_key.TACI_State ? table_key.TACI_State : "-"}</td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.TACI_PostalCode ? table_key.TACI_PostalCode : "-"}
                  </td>
                  <td>{table_key.TACI_Phone ? table_key.TACI_Phone : "-"}</td>
                  <td>{table_key.TACI_Country ? table_key.TACI_Country : "-"}</td>
                </tr>
              );
            })}

          {MarketingReportData.lstHotelligenceData !== undefined &&
            MarketingReportData.lstHotelligenceData &&
            !MarketingReportData.lstHotelligenceData.length && (
              <tr className="h-30" align="center">
                <td colSpan={22}> No Record Found</td>
              </tr>
            )}
        </tbody>
      </>
    );
  };

  let RevenueBudgetTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-30">
            <th className=""> Type </th>
            <th className="text-end"> January </th>
            <th className="text-end"> Februry </th>
            <th className="text-end"> March </th>
            <th className="text-end"> April </th>
            <th className="text-end"> May </th>
            <th className="text-end"> June </th>
            <th className="text-end"> July </th>
            <th className="text-end"> August </th>
            <th className="text-end"> September </th>
            <th className="text-end"> October</th>
            <th className="text-end"> November</th>
            <th className="text-end"> December </th>
          </tr>
        </thead>
        <tbody>
          {/* {Object.keys(groupTableData).map((table_key, index) => { */}
          {MarketingReportData.lstRevenueAndBudgetData !== undefined &&
            MarketingReportData.lstRevenueAndBudgetData &&
            MarketingReportData.lstRevenueAndBudgetData.map((table_key, index) => {
              return (
                <tr className="h-40" key={index}>
                  {/* <td className="bluetext w-25"> */}
                  {/* <td className="settitle text-center"> */}
                  <td>{table_key.Type ? table_key.Type : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Jan ? table_key.Jan : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Feb ? table_key.Feb : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Mar ? table_key.Mar : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Apr ? table_key.Apr : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.May ? table_key.May : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Jun ? table_key.Jun : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Jul ? table_key.Jul : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Aug ? table_key.Aug : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Sep ? table_key.Sep : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Oct ? table_key.Oct : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Nov ? table_key.Nov : "-"}</td>
                  <td style={{ textAlign: "right" }}>{table_key.Dec ? table_key.Dec : "-"}</td>
                </tr>
              );
            })}

          {MarketingReportData.lstRevenueAndBudgetData !== undefined &&
            MarketingReportData.lstRevenueAndBudgetData &&
            !MarketingReportData.lstRevenueAndBudgetData.length && (
              <tr className="h-30" align="center">
                <td colSpan={13}> No Record Found </td>
              </tr>
            )}
        </tbody>
      </>
    );
  };

  let CompSetTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-30">
            <th> Competitor Hotel </th>
            <th> Strength </th>
            <th> Weakness </th>
            <th> Opportunity </th>
            <th> Threat </th>
            <th> BARWeekday </th>
            <th> BARWeekend </th>
            <th> Total Rooms </th>
            <th> Address </th>
            <th> City </th>
            <th> State</th>
            <th> Phone Number</th>
          </tr>
        </thead>
        <tbody>
          {/* {Object.keys(groupTableData).map((table_key, index) => { */}
          {MarketingReportData.lstCompSetData !== undefined &&
            MarketingReportData.lstCompSetData &&
            MarketingReportData.lstCompSetData.map((table_key, index) => {
              return (
                <tr className="h-40" key={index}>
                  {/* <td className="bluetext w-25"> */}
                  {/* <td className="settitle text-center"> */}
                  <td>{table_key.CompetitorHotel ? table_key.CompetitorHotel : "-"}</td>
                  <td>{table_key.Strength ? table_key.Strength : "-"}</td>
                  <td>{table_key.Weakness ? table_key.Weakness : "-"}</td>
                  <td>{table_key.Opportunity ? table_key.Opportunity : "-"}</td>
                  <td>{table_key.Threat ? table_key.Threat : "-"}</td>
                  <td>{table_key.BARWeekday ? table_key.BARWeekday : "-"}</td>
                  <td>{table_key.BARWeekend ? table_key.BARWeekend : "-"}</td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.TotalRooms ? table_key.TotalRooms : "-"}
                  </td>
                  <td>{table_key.Address ? table_key.Address : "-"}</td>
                  <td>{table_key.City ? table_key.City : "-"}</td>
                  <td>{table_key.State ? table_key.State : "-"}</td>
                  <td>{table_key.PhoneNumber ? table_key.PhoneNumber : "-"}</td>
                </tr>
              );
            })}
          {MarketingReportData.lstCompSetData !== undefined &&
            MarketingReportData.lstCompSetData &&
            !MarketingReportData.lstCompSetData.length && (
              <tr className="h-30" align="center">
                <td colSpan={12}> No Record Found </td>
              </tr>
            )}
        </tbody>
      </>
    );
  };

  let TargetCompSetHotelTable: any = () => {
    return (
      <>
        <thead>
          <tr>
            <th>Month</th>
            <th> Hotels</th>
          </tr>
        </thead>
        {MarketingReportData.lstTargetCompSetHotelsData !== undefined &&
        MarketingReportData.lstTargetCompSetHotelsData.length > 0
          ? MarketingReportData.lstTargetCompSetHotelsData.map((table_key, index) => {
              return (
                <tbody key={index}>
                  <tr className="h-30">
                    <td>{table_key.Month ? table_key.Month : "-"} </td>
                    <td>
                      {table_key.TargetComSetHotelsName ? table_key.TargetComSetHotelsName : "-"}
                    </td>
                  </tr>
                </tbody>
              );
            })
          : "No Record Found"}
      </>
    );
  };

  let TargetMarketSegmentsTable: any = () => {
    return (
      <>
        {/* <thead> */}
        <tr style={{ width: "10%", border: "1px solid " }}>
          <th style={{ width: "10%", border: "1px solid ", padding: "10px" }}> Month </th>
          <th style={{ width: "15%", border: "1px solid ", padding: "10px" }}>Market Segment</th>
          {props.marketingPlaneReportData && props.marketingPlaneReportData.isIncludeMarketSegment && (
            <>
              <th style={{ width: "20%", border: "1px solid ", padding: "10px" }}>
                Who to Contact
              </th>
              <th style={{ width: "20%", border: "1px solid ", padding: "10px" }}>
                Usual Type of Incoming Travel
              </th>
              <th style={{ width: "20%", border: "1px solid ", padding: "10px" }}> Questions </th>
            </>
          )}
          <th style={{ width: "15%", border: "1px solid ", padding: "10px" }}> Assigned To </th>
        </tr>
        {MarketingReportData.lstTargetMarketSegmentsData !== undefined &&
          MarketingReportData.lstTargetMarketSegmentsData &&
          MarketingReportData.lstTargetMarketSegmentsData.map((table_key, index) => {
            return (
              <tr className="h-40" key={index}>
                <td style={{ width: "10%", border: "1px solid ", padding: "10px" }}>
                  {table_key.Month ? table_key.Month : "-"}
                </td>
                <td
                  className="width-220px"
                  style={{ width: "10%", border: "1px solid ", padding: "10px" }}
                >
                  {table_key.MarketSegmentName ? table_key.MarketSegmentName : "-"}
                </td>
                {props.marketingPlaneReportData &&
                  props.marketingPlaneReportData.isIncludeMarketSegment && (
                    <>
                      <td style={{ width: "10%", border: "1px solid ", padding: "10px" }}>
                        {" "}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: table_key.WhoToContact ? table_key.WhoToContact : "-",
                          }}
                        />
                        {/* {table_key.WhoToContact ? table_key.WhoToContact : "-"} */}
                      </td>
                      <td style={{ width: "10%", border: "1px solid ", padding: "10px" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: table_key.UsualTypeofIncomingTravel
                              ? table_key.UsualTypeofIncomingTravel
                              : "-",
                          }}
                        />
                        {/* {table_key.UsualTypeofIncomingTravel
                      ? table_key.UsualTypeofIncomingTravel
                      : "-"} */}
                      </td>
                      <td style={{ width: "10%", border: "1px solid ", padding: "10px" }}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: table_key.Questions ? table_key.Questions : "-",
                          }}
                        />
                        {/* {table_key.Questions ? table_key.Questions : "-"} */}
                      </td>
                    </>
                  )}
                <td style={{ width: "10%", border: "1px solid ", padding: "10px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: table_key.AssingTo ? table_key.AssingTo : "-",
                    }}
                  />
                  {/* {table_key.AssingTo ? table_key.AssingTo : "-"} */}
                </td>
              </tr>
            );
          })}

        {MarketingReportData.lstTargetMarketSegmentsData !== undefined &&
          MarketingReportData.lstTargetMarketSegmentsData &&
          !MarketingReportData.lstTargetMarketSegmentsData.length && (
            <tr className="h-30 text-center">
              <td colSpan={6}> No Record Found </td>
            </tr>
          )}
      </>
    );
  };

  let TopGroupsTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-30">
            <th> Group Name </th>
            <th> CheckIn Date </th>
            <th> CheckOut Date </th>
            <th> Revenue </th>
          </tr>
        </thead>
        <tbody>
          {/* {Object.keys(groupTableData).map((table_key, index) => { */}
          {MarketingReportData.lstTopGroupsData !== undefined &&
            MarketingReportData.lstTopGroupsData &&
            MarketingReportData.lstTopGroupsData.map((table_key, index) => {
              return (
                <tr className="h-40" key={index}>
                  {/* <td className="bluetext w-25"> */}
                  {/* <td className="settitle text-center"> */}
                  <td>{table_key.GroupName ? table_key.GroupName : "-"}</td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.CheckInDate ? table_key.CheckInDate : "-"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.CheckOutDate ? table_key.CheckOutDate : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.Revenue ? "$" + table_key.Revenue : "-"}
                  </td>
                </tr>
              );
            })}
          {MarketingReportData.lstTopGroupsData !== undefined &&
            MarketingReportData.lstTopGroupsData &&
            !MarketingReportData.lstTopGroupsData.length && (
              <tr className="h-30">
                <td colSpan={4} className="text-center">
                  No Record Found
                </td>
              </tr>
            )}
        </tbody>
      </>
    );
  };

  let EventsTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-30">
            <th> Event </th>
            <th> Location </th>
            <th> Date </th>
            <th> Prior Year Revenue </th>
          </tr>
        </thead>
        <tbody>
          {/* {Object.keys(groupTableData).map((table_key, index) => { */}
          {MarketingReportData.lstEventsData !== undefined &&
            MarketingReportData.lstEventsData &&
            MarketingReportData.lstEventsData.map((table_key, index) => {
              return (
                <tr className="h-40" key={index}>
                  {/* <td className="bluetext w-25"> */}
                  {/* <td className="settitle text-center"> */}
                  <td>{table_key.Event ? table_key.Event : "-"}</td>
                  <td>{table_key.Location ? table_key.Location : "-"}</td>
                  <td style={{ textAlign: "center" }}>
                    {table_key.EventDate ? table_key.EventDate : "-"}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {table_key.PriorYearRevenue ? table_key.PriorYearRevenue : "-"}
                  </td>
                </tr>
              );
            })}
          {MarketingReportData.lstEventsData !== undefined &&
            MarketingReportData.lstEventsData &&
            !MarketingReportData.lstEventsData.length && (
              <tr className="h-30 text-center">
                <td colSpan={4}> No Record Found </td>
              </tr>
            )}
        </tbody>
      </>
    );
  };
  //#endregion
  // Tables End :

  async function exportPDF() {
    let inputData: any = {
      propertyID: props.marketingPlaneReportData.propertyID,
      year: props.marketingPlaneReportData.year,
      prevYear: props.marketingPlaneReportData.prevYear,
      yearID: props.marketingPlaneReportData.yearID,
      prevYearID: props.marketingPlaneReportData.prevYearID,
      isIncludeMarketSegment: props.marketingPlaneReportData.isIncludeMarketSegment,
    };

    setDownloading(true);
    // setOnProcessHide(true);
    let res: any = await MarketingPlanReportFileExportPDF(inputData, Token);
    // setOnProcessHide(false);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloading(false);
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    } else {
      ToasterError("Something Went Wrong.");
      setDownloading(false);
    }
  }

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          Marketing Plan (
          {MarketingReportData.propertyData && MarketingReportData.propertyData.Year})
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {!loading ? (
          <>
            <Row>
              <Col lg={12}>
                {MarketingReportData.propertyData &&
                MarketingReportData.propertyData.PropertyLogo !== undefined &&
                MarketingReportData.propertyData.PropertyLogo ? (
                  <>
                    <div className="text-center mt-3">
                      <img
                        src={MarketingReportData.propertyData.PropertyLogo}
                        style={{ width: "200px", maxHeight: "150px" }}
                        alt="Hotel Logo"
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}

                {MarketingReportData.propertyData &&
                MarketingReportData.propertyData.PropertyName !== undefined &&
                MarketingReportData.propertyData.PropertyName ? (
                  <>
                    <div className="report-col mt-2 mb-2">
                      <div className="report-title">
                        <span>
                          {MarketingReportData.propertyData.HotelCode + " "}
                          {MarketingReportData.propertyData.PropertyName}
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="information-division">
                  <div className="information-division">
                    <h3 className="inner-header">Positioning Statement</h3>
                    <div className="tab-inner-box position-relative">
                      {MarketingReportData.lstPositioningStatementData !== undefined &&
                      MarketingReportData.lstPositioningStatementData &&
                      MarketingReportData.lstPositioningStatementData.length ? (
                        MarketingReportData.lstPositioningStatementData.map((item, index) => (
                          <label key={index}>
                            <div dangerouslySetInnerHTML={{ __html: item.PositioningStatement }} />
                          </label>
                        ))
                      ) : (
                        <span className="text-center d-block">No data found..!</span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Market Volume Information</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <CitySpendTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">GDS Market Data</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <HotelligenceTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Revenue & Budget</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <RevenueBudgetTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Comp Set</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <CompSetTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={4} xl={4} lg={12} className="mb-3">
                <div className="information-division">
                  <h3 className="inner-header m-0">Top Accounts</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <div className="report-col">
                        <div className="col-box-bar mb-3">
                          {MarketingReportData.lstTopAccountsData !== undefined &&
                          MarketingReportData.lstTopAccountsData.length > 0
                            ? MarketingReportData.lstTopAccountsData.map((item_key, index) => (
                                <label
                                  className="report-label"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (page_rights.is_view) {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: item_key.AccountID,
                                          AccountName: item_key.AccountName,
                                        },
                                      });
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                  onKeyDown={() => {
                                    if (page_rights.is_view) {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: item_key.AccountID,
                                          AccountName: item_key.AccountName,
                                        },
                                      });
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                >
                                  {item_key.AccountName
                                    ? item_key.AccountName.replace("#", " ")
                                    : ""}
                                </label>
                              ))
                            : "No Record Found"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} xl={4} lg={12} className="mb-3">
                <div className="information-division">
                  <h3 className="inner-header m-0">Target Accounts</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <div className="report-col">
                        <div className="col-box-bar mb-3">
                          {MarketingReportData.lstTargetAccountsData !== undefined &&
                          MarketingReportData.lstTargetAccountsData.length > 0
                            ? MarketingReportData.lstTargetAccountsData.map((item_key, index) => (
                                <label
                                  className="report-label"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (page_rights.is_view) {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: item_key.AccountID,
                                          AccountName: item_key.AccountName,
                                        },
                                      });
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                  onKeyDown={() => {
                                    if (page_rights.is_view) {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: item_key.AccountID,
                                          AccountName: item_key.AccountName,
                                        },
                                      });
                                    } else {
                                      CommonService.unauthorizedAction();
                                    }
                                  }}
                                >
                                  {item_key.AccountName
                                    ? item_key.AccountName.replace("#", " ")
                                    : ""}
                                </label>
                              ))
                            : "No Record Found"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={4} xl={4} lg={12} className="mb-3">
                <div className="information-division">
                  <h3 className="inner-header m-0">Target Growth Accounts</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <div className="report-col">
                        <div className="col-box-bar mb-3">
                          {MarketingReportData.lstTargetGrowthAccountsData !== undefined &&
                          MarketingReportData.lstTargetGrowthAccountsData.length > 0
                            ? MarketingReportData.lstTargetGrowthAccountsData.map(
                                (item_key, index) => (
                                  <label
                                    className="report-label"
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      if (page_rights.is_view) {
                                        navigate("/accounts-view", {
                                          state: {
                                            AccountID: item_key.AccountID,
                                            AccountName: item_key.AccountName,
                                          },
                                        });
                                      } else {
                                        CommonService.unauthorizedAction();
                                      }
                                    }}
                                    onKeyDown={() => {
                                      if (page_rights.is_view) {
                                        navigate("/accounts-view", {
                                          state: {
                                            AccountID: item_key.AccountID,
                                            AccountName: item_key.AccountName,
                                          },
                                        });
                                      } else {
                                        CommonService.unauthorizedAction();
                                      }
                                    }}
                                  >
                                    {item_key.AccountName
                                      ? item_key.AccountName.replace("#", " ")
                                      : ""}
                                  </label>
                                )
                              )
                            : "No Record Found"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Target Comp Set Hotels</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <TargetCompSetHotelTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Target Market Segments</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <table style={{ width: "100%", border: "1px solid" }}>
                        <TargetMarketSegmentsTable />
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Top Groups</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <TopGroupsTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={12} xl={12} className="">
                <div className="information-division">
                  <h3 className="inner-header m-0">Events</h3>
                  <div className="tab-inner-box position-relative">
                    <div className="customtablemetting">
                      <Table responsive bordered className="table-without-space mb-0">
                        <EventsTable />
                      </Table>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {Downloading && (
              <div className="calender-loader-main">
                <PageLoader />
              </div>
            )}
          </>
        ) : (
          <>
            <PageLoader />
          </>
        )}
      </Modal.Body>

      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          onClick={GenerateMarketingPlaneExcel}
          disabled={OnProcessHide === true ? true : false}
        >
          <span className="me-2">
            <img src={ExportIcon} alt="" />
          </span>
          Export Excel
        </Button>
        <Button
          variant="primary ms-0"
          disabled={Downloading === true ? true : false}
          onClick={(e) => {
            e.preventDefault();
            exportPDF();
          }}
        >
          <span className="me-2">
            <img src={PdfIcon} alt="" />
          </span>
          Export PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MarketingPlanReportModal;
