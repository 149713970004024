import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import MeetingRoomBookedReportModal from "../../Model/Reports/MeetingRoomBookedReportModal";
import { CommonService, ToasterError } from "../../Services/CommonServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";

function MeetingRoomBookedReport() {
  let MeetingRoomBooked_page_rights = CommonService.getPageRight("MeetingRoomBooked");

  const navigate = useNavigate();
  const d = new Date();
  let currentMonth = d.getMonth();
  let currentYear = d.getFullYear().toString();
  let tempMonth: any = [
    { January: 0 },
    { Februry: 1 },
    { March: 2 },
    { April: 3 },
    { May: 4 },
    { June: 5 },
    { July: 6 },
    { August: 7 },
    { September: 8 },
    { October: 9 },
    { November: 10 },
    { December: 11 },
  ];

  let tempMonths: any = [
    "January",
    "Februry",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November:",
    "December:",
  ];

  let passMonth: any = "";
  tempMonths &&
    tempMonths.map((item, index) => {
      if (index === currentMonth) {
        passMonth = item;
      }
    });

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const userID: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const Token = dataSelector.access_Token;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);

  const [loading, setLoading]: any = useState(false);
  const [yearList, setYearList]: any = useState([]);
  const [monthList, setMonthList]: any = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [selectedMonthPrimary, setSelectedMonthPrimary] = useState<any>({});
  const [selectedYearPrimary, setSelectedYearPrimary] = useState<any>({});
  const [showModal, setshowModal] = useState(false);

  const [selectedYear, setSelectedYear] = useState<any>({});
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);

  const [pageLoading, setPageLoading] = useState(true);

  const [inputs, setInputs] = useState({
    propertyIDs: "",
    yearID: "",
    monthID: "",
    year: "",
    month: "",
  });

  const [ReportData, setReportData]: any = useState({
    propertyIDs: "",
    yearID: "",
    year: "",
    monthID: "",
    month: "",
  });

  const fetchPrimaryDetails = async () => {
    let monthInput = {
      clientID: clientID,
      category: "month, year",
    };
    setLoading(true);

    setReportData({
      ...ReportData,
      propertyIDs: SelectedPropertyIDs.SelectedPropertyID && SelectedPropertyIDs.SelectedPropertyID,
    });
    let resMonthYear = await getProjectTermByCategory(monthInput, Token);
    if (resMonthYear.status === 200) {
      if (resMonthYear.data.success) {
        if (
          resMonthYear.data.data.dataList1 !== undefined &&
          resMonthYear.data.data.dataList1 &&
          resMonthYear.data.data.dataList1.length
        ) {
          let monthArray: any = [];
          let yearArray: any = [];

          resMonthYear.data.data.dataList1.forEach((itm) => {
            if (itm.Category === "Month") {
              monthArray = [
                ...monthArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                  ordetNo: itm.OrdetNo,
                },
              ];
            }
          });

          resMonthYear.data.data.dataList1.forEach((itm) => {
            if (itm.Category === "Year") {
              yearArray = [
                ...yearArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];
            }
          });
          setYearList(yearArray);
          setMonthList(monthArray);
        }
        setLoading(false);
      }
      // }
    }
  };

  const fetchYearList = async () => {
    let yearInput = {
      clientID: clientID,
      category: "year",
    };
    let monthInput = {
      clientID: clientID,
      category: "month",
    };

    setPageLoading(true);
    setLoading(true);
    let resYear = await getProjectTermByCategory(yearInput, Token);
    let resMonth = await getProjectTermByCategory(monthInput, Token);
    setLoading(false);

    if (resYear && resMonth) {
      setPageLoading(false);
    }
    let iniYearId = "";
    let iniMonthID = "";
    let iniMonth = "";
    let iniYear = "";
    if (resYear.status === 200) {
      if (resYear.data.success) {
        if (resYear.data.data !== undefined && resYear.data.data) {
          if (
            resYear.data.data.dataList1 !== undefined &&
            resYear.data.data.dataList1 &&
            resYear.data.data.dataList1.length
          ) {
            let yrArray: any = [];
            resYear.data.data.dataList1.forEach((itm) => {
              yrArray = [
                ...yrArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];
            });
            let newSelection = {
              label: resYear.data.data.dataList1[0].TermCode,
              value: resYear.data.data.dataList1[0].TermID,
            };
            let currentYear = new Date().getFullYear();
            let crYrArray = yrArray.filter((obj) => parseInt(obj.label) === currentYear);

            iniYearId = crYrArray.length
              ? crYrArray[0].value
              : resYear.data.data.dataList1[0].TermID;
            iniYear = crYrArray.length
              ? crYrArray[0].label
              : resYear.data.data.dataList1[0].TermCode;
            setSelectedYear(crYrArray.length ? crYrArray[0] : newSelection);
            setYearList(yrArray);
          }
        }
      } else {
        ToasterError(resYear.message);
      }
    } else {
      ToasterError(resYear.message);
    }
    if (resMonth.status === 200) {
      if (resMonth.data.success) {
        if (resMonth.data.data !== undefined && resMonth.data.data) {
          if (
            resMonth.data.data.dataList1 !== undefined &&
            resMonth.data.data.dataList1 &&
            resMonth.data.data.dataList1.length
          ) {
            let MonthArray: any = [];
            resMonth.data.data.dataList1.forEach((itm) => {
              MonthArray = [
                ...MonthArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];
            });

            setSelectedMonth({
              label: resMonth.data.data.dataList1[currentMonth].TermCode,
              value: resMonth.data.data.dataList1[currentMonth].TermID,
            });

            iniMonthID = resMonth.data.data.dataList1[currentMonth].TermID;
            iniMonth = resMonth.data.data.dataList1[currentMonth].TermCode;
            setMonthList(MonthArray);
          }
        }
      } else {
        ToasterError(resYear.message);
      }
    } else {
      ToasterError(resYear.message);
    }

    setInputs({
      ...inputs,
      propertyIDs: SelectedPropertyIDs.SelectedPropertyID && SelectedPropertyIDs.SelectedPropertyID,
      monthID: iniMonthID,
      yearID: iniYearId,
      year: iniYear,
      month: iniMonth,
    });
  };

  useEffect(() => {
    fetchYearList();
  }, [SelectedPropertyIDs.SelectedPropertyID]);

  function handleGenerateReport() {
    setReportData(inputs);
    setshowModal(true);
  }

  return (
    <div className="report-page">
      <Row>
        <Col xxl={3} xl={3} lg={3} md={4}>
          <ReportNavigations />
        </Col>

        <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
          {MeetingRoomBooked_page_rights.is_view ? (
            !loading ? (
              <>
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={6} xl={3} className="mb-3">
                      <FloatingLabel controlId="floatingSelectGrid" label="">
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={selectedYear}
                          onChange={(itm: any) => {
                            setSelectedYear(itm);
                            setInputs({
                              ...inputs,
                              yearID: itm.value,
                              year: itm.label,
                            });
                          }}
                          options={yearList}
                          name="colors"
                          placeholder="Select Year"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col lg={6} xl={3} className="mb-3">
                      <FloatingLabel controlId="floatingSelectGrid" label="">
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={selectedMonth}
                          onChange={(itm: any) => {
                            setSelectedMonth(itm);
                            setInputs({
                              ...inputs,
                              monthID: itm.value,
                              month: itm.label,
                            });
                          }}
                          options={monthList}
                          name="colors"
                          placeholder="Select Month"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="custom-profile-page">
                        <Modal.Footer className="border-0 justify-content-start">
                          <Button
                            variant="primary ms-0"
                            onClick={() => {
                              if (MeetingRoomBooked_page_rights.is_view) {
                                handleGenerateReport();
                              } else {
                                CommonService.unauthorizedAction();
                              }
                            }}
                            disabled={
                              inputs.propertyIDs.length && inputs.monthID && inputs.yearID
                                ? false
                                : true
                            }
                          >
                            <span className="me-2">
                              <img src={GenerateReport} alt="" />
                            </span>
                            Generate Report
                          </Button>
                        </Modal.Footer>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <>
                <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              </>
            )
          ) : (
            <Unauthorized />
          )}
        </Col>
      </Row>

      {showModal && (
        <MeetingRoomBookedReportModal
          MeetingRoomBookedReportData={ReportData}
          show={showModal}
          onHide={() => setshowModal(false)}
        />
      )}
    </div>
  );
}

export default MeetingRoomBookedReport;
