import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import TableView from "../../../Component/Table/Index";
import { ENUMS } from "../../../Constants/Enums";
import {
  DeletePropertyAttachment,
  PropertyAttachmentList,
  PropertyAttachmentsSave,
} from "../../../Services/Setup&Config/HotelService";

import DocxIcon from "../../../Asset/Images/Icons/DocxIcon.svg";
import ImageIcon from "../../../Asset/Images/Icons/ImageIcon.svg";
import PdfIcon from "../../../Asset/Images/Icons/PdfIcon.svg";
import { CommonService, ToasterError, ToasterSuccess, getDateInFormat } from "../../../Services/CommonServices";

export default function MergeDocument({ property_id }) {
  let page_rights_Hotel = CommonService.getPageRight("Hotels");
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  const attachFileRef = useRef<any>(null);
  const [IsDataCheck, setIsDataCheck] = React.useState(false);

  const [formInputs, setFormInputs] = useState<any>({
    associationID: property_id,
    associationType_Term: "LNR",
    DocumentFrom: "Properties",
    FormFiles: [],
  });

  const [attachmentList, setAttachmentList] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleRemoveFile = (file_data, ind) => {
    let newFilesArray: any = [];
    formInputs.FormFiles.forEach((element_file, i) => {
      if (ind != i) {
        newFilesArray = [...newFilesArray, element_file];
      }
    });
    setFormInputs({
      ...formInputs,
      FormFiles: newFilesArray,
    });
  };

  const handleUploadAttachment = async () => {
    if (formInputs.FormFiles) {
      var formData = new FormData();

      formData.append("associationID", formInputs.associationID);
      formData.append("associationType_Term", formInputs.associationType_Term);
      formInputs.FormFiles.forEach((form_fl) => {
        formData.append("FormFiles", form_fl);
      });
      formData.append("DocumentFrom", formInputs.DocumentFrom);
      setUploadLoading(true);
      let res: any = await PropertyAttachmentsSave(formData, Token);
      if (res.status === 200) {
        if (res.data.statusCode === 200) {
          setFormInputs({
            associationID: property_id,
            associationType_Term: "LNR",
            DocumentFrom: "Properties",
            FormFiles: [],
          });
          setUploadLoading(false);

          fetchHotelAttachmentList();
          attachFileRef.current.value = null;
        } else {
          ToasterError("Something Went Wrong");
          setUploadLoading(false);
        }
      } else {
        setUploadLoading(false);
      }
    }
  };

  const handleAttachmentFile = (file_res) => {
    let isValidFile = true;
    let totalSize = 0;
    let isLenValid = true;
    file_res.forEach((file_el) => {
      totalSize = totalSize + parseFloat((file_el.size / 1000000).toFixed(2));
      if (!file_el.name.match(/\.(doc|docx)$/)) {
        isValidFile = false;
      }
    });

    if (file_res.length > 5) {
      isLenValid = false;
      ToasterError("Maximum 5 files are allowed.");
    } else {
      if (!isValidFile) {
        ToasterError("Only doc and docx files are supported.");
      } else {
        if (totalSize >= 25) {
          ToasterError("Total files size should be less than 25MB.");
        }
      }
    }

    if (isValidFile && totalSize < 25 && isLenValid) {
      setFormInputs({
        ...formInputs,
        FormFiles: [...formInputs.FormFiles, ...file_res],
      });
    }
  };

  const fetchHotelAttachmentList = async () => {
    let data = {
      clientID: clientID,
      associationIDs: property_id,
      associationType_Term: "LNR, Group, MAC, ComboGroupMeeting",
    };
    setIsDataCheck(true);
    let res: any = await PropertyAttachmentList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            setAttachmentList(res.data.data.dataList1);
            setIsDataCheck(false);
          } else {
            setIsDataCheck(false);
            setAttachmentList([]);
          }
        } else {
          setIsDataCheck(false);
          setAttachmentList([]);
        }
      } else {
        setIsDataCheck(false);
        setAttachmentList([]);
      }
    } else {
      setIsDataCheck(false);
      setAttachmentList([]);
    }
  };

  function DeleteAttachment(value: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          keyID: value,
        };
        let res: any = await DeletePropertyAttachment(input, Token);
        if (res.data.success) {
          if (res.data.statusCode === 200) {
          ToasterSuccess("Attachment Deleted Successfully");

            fetchHotelAttachmentList();
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }

  useEffect(() => {
    if (property_id) {
      fetchHotelAttachmentList();
    }
  }, []);

  const TableData: any = [];
  attachmentList.map((item_attach: any) => {
    TableData.push({
      controlRender: {
        name: (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={item_attach.DocumentPath} target="_blank">
            {item_attach.DocumentName}
          </a>
        ),
      },
      name: item_attach.DocumentName,
      uploadedDate: getDateInFormat(item_attach.CreatedOn.split("T")[0]),
      from: ENUMS.FromProperty[item_attach.AssociationType_Term],
      action: (
        <>
          <div className="table-action">
            <div className="btn-icon-grp-table">
              <Button
                className="btn-icon cancelIcon"
                onClick={() => {
                  if (page_rights_Hotel.is_delete) {
                    DeleteAttachment(item_attach.AttachmentID);
                  } else {
                    CommonService.unauthorizedAction();
                  }
                }}
              ></Button>
            </div>
          </div>
        </>
      ),
    });
  });
  // ];
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadedDate",
      },
      {
        Header: "From",
        accessor: "from",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "table-action",
      },
    ],
    []
  );

  return (
    <>
      <Form className="search-section-option text-center">
        <div className="option-field">
          {Object.keys(ENUMS.FromProperty).map((form_key, i) => {
            return (
              <Form.Check
                key={i}
                onChange={() => {
                  setFormInputs({
                    ...formInputs,
                    associationType_Term: form_key,
                  });
                }}
                inline
                checked={formInputs.associationType_Term === form_key ? true : false}
                label={ENUMS.FromProperty[form_key]}
                name="group1"
                type="radio"
                defaultChecked
                id={form_key}
              />
            );
          })}
        </div>
      </Form>
      <div className="mt-3">
        <Row>
          <Col xs={12} xl={12} lg={12} md={12} className="mb-3 col-12">
            {formInputs.associationType_Term === "LNR" && (
              <div className="information-division">
                <h3 className="inner-header m-0">LNR Tokens</h3>
                <div className="tab-inner-box position-relative">
                  <div className="token-header-label">Opportunity Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <div className="box-label">Opportunity Name</div>
                      <p className="box-detials">[$$opponame$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Account Name</span>
                      <p className="box-detials">[$$accountname$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Current Date</span>
                      <p className="box-detials">[$$currentdate$$]</p>
                    </Col>
                  </Row>
                  <div className="token-header-label">Contact Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Title</span>
                      <p className="box-detials">[$$contacttitle$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Name</span>
                      <p className="box-detials">[$$contactname$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label"> Phone</span>
                      <p className="box-detials">[$$contactphone$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Street</span>
                      <p className="box-detials">[$$contactaddress$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact City</span>
                      <p className="box-detials">[$$contactcity$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact State</span>
                      <p className="box-detials">[$$contactstate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Country</span>
                      <p className="box-detials">[$$contactcountry$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact ZipCode</span>
                      <p className="box-detials">[$$contactzipcode$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact E-mail</span>
                      <p className="box-detials">[$$contactemail$$]</p>
                    </Col>{" "}
                  </Row>
                  <div className="token-header-label">Rate Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Rate Start Date</span>
                      <p className="box-detials">[$$ratestartdate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Rate End Date</span>
                      <p className="box-detials">[$$rateenddate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Client Target Rate</span>
                      <p className="box-detials">[$$clienttargetrate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Room Night Goal</span>
                      <p className="box-detials">[$$roomnightgoal$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Target RateTier 1</span>
                      <p className="box-detials">[$$targetratetier1$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Target RateTier 2</span>
                      <p className="box-detials">[$$targetratetier2$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Target RateTier 3</span>
                      <p className="box-detials">[$$targetratetier3$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Target RateTier 4</span>
                      <p className="box-detials">[$$targetratetier4$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Rate Grid</span>
                      <p className="box-detials">[$$rategrid$$]</p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {formInputs.associationType_Term === "Group" && (
              <div className="information-division">
                <h3 className="inner-header m-0">Group Tokens</h3>
                <div className="tab-inner-box position-relative">
                  <div className="token-header-label">Opportunity Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Function Name</span>
                      <p className="box-detials">[$$functionname$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Account Name</span>
                      <p className="box-detials">[$$accountname$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Day / Date of Group</span>
                      <p className="box-detials">[$$dateofgroup$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Reservation Cut Off Date</span>
                      <p className="box-detials">[$$reservationcutoffdate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Reservation Method</span>
                      <p className="box-detials">[$$reservationmethod$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Payment Method</span>
                      <p className="box-detials">[$$paymentmethod$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Current Date</span>
                      <p className="box-detials">[$$currentdate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Group Agreements</span>
                      <p className="box-detials">[$$specialinstruction$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Commission</span>
                      <p className="box-detials">[$$commission$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Rebate</span>
                      <p className="box-detials">[$$rebate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">IATA</span>
                      <p className="box-detials">[$$iata$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Group Code</span>
                      <p className="box-detials">[$$groupcode$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Booking Link</span>
                      <p className="box-detials">[$$bookinglink$$]</p>
                    </Col>
                  </Row>
                  <div className="token-header-label">Contact Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Title</span>
                      <p className="box-detials">[$$contacttitle$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Name</span>
                      <p className="box-detials">[$$contactname$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Street</span>
                      <p className="box-detials">[$$address$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">City</span>
                      <p className="box-detials">[$$contactcity$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">State</span>
                      <p className="box-detials">[$$contactstate$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Country</span>
                      <p className="box-detials">[$$contactcountry$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">ZipCode</span>
                      <p className="box-detials">[$$contactzipcode$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Phone</span>
                      <p className="box-detials">[$$phone$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">E-mail</span>
                      <p className="box-detials">[$$email$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">On-Site Contact</span>
                      <p className="box-detials">[$$onsitecontact$$]</p>
                    </Col>{" "}
                  </Row>
                  <div className="token-header-label">Room Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Guest Room Requirements</span>
                      <p className="box-detials">[$$guestroomrequirements$$]</p>
                    </Col>{" "}
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Total Amount</span>
                      <p className="box-detials">[$$totalamount$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Amount</span>
                      <p className="box-detials">[$$taxamount$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Percentage</span>
                      <p className="box-detials">[$$taxpercentage$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Gross Total </span>
                      <p className="box-detials">[$$grosstotal$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Detail Grid </span>
                      <p className="box-detials"> [$$taxdetailgrid$$]</p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {formInputs.associationType_Term === "MAC" && (
              <div className="information-division">
                <h3 className="inner-header m-0">Meeting and Catering Tokens</h3>
                <div className="tab-inner-box position-relative">
                  <div className="token-header-label">Opportunity Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Opportunity Name</span>
                      <p className="box-detials">[$$opportunityname$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Account Name</span>
                      <p className="box-detials">[$$accountname$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Special Instruction</span>
                      <p className="box-detials">[$$specialinstruction$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Payment Method</span>
                      <p className="box-detials">[$$paymentmethod$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Current Date</span>
                      <p className="box-detials">[$$currentdate$$]</p>
                    </Col>
                  </Row>

                  <div className="token-header-label">Contact Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Phone</span>
                      <p className="box-detials">[$$contact$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Title</span>
                      <p className="box-detials">[$$contacttitle$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Name</span>
                      <p className="box-detials">[$$contactname$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Street</span>
                      <p className="box-detials">[$$address$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">City</span>
                      <p className="box-detials">[$$contactcity$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">State</span>
                      <p className="box-detials">[$$contactstate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Country</span>
                      <p className="box-detials">[$$contactcountry$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">ZipCode</span>
                      <p className="box-detials">[$$contactzipcode$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">E-mail</span>
                      <p className="box-detials">[$$email$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">On-Site Contact</span>
                      <p className="box-detials">[$$onsitecontact$$]</p>
                    </Col>
                  </Row>

                  <div className="token-header-label">Meeting Room Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Start Date</span>
                      <p className="box-detials">[$$startdate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">End Date</span>
                      <p className="box-detials">[$$enddate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Room Grid</span>
                      <p className="box-detials">[$$meetingroomgrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food</span>
                      <p className="box-detials">[$$foodgrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Beverage</span>
                      <p className="box-detials">[$$beveragegrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">AV Misc Grid</span>
                      <p className="box-detials">[$$avmiscgrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food & Beverage Grid</span>
                      <p className="box-detials">[$$foodandbeveragegrid$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Total Amount</span>
                      <p className="box-detials">[$$totalamount$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Amount</span>
                      <p className="box-detials">[$$taxamount$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Percentage</span>
                      <p className="box-detials">[$$taxpercentage$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Gross Total</span>
                      <p className="box-detials">[$$grosstotal$$]</p>
                    </Col>
                  </Row>

                  <div className="token-header-label">Tax Detail Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Tax Detail Grid</span>
                      <p className="box-detials">[$$meetingtaxdetailgrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label"> AV Tax Detail Grid</span>
                      <p className="box-detials">[$$avtaxdetailgrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food Tax Detail Grid</span>
                      <p className="box-detials">[$$foodtaxdetailgrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Beverage Tax Detail Grid</span>
                      <p className="box-detials">[$$beveragetaxdetailgrid$$]</p>
                    </Col>
                  </Row>

                  <div className="token-header-label">Tax Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting and AV Tax</span>
                      <p className="box-detials">[$$meetingandavtax$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food and Beverage Tax</span>
                      <p className="box-detials">[$$foodandbeveragetax$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Room Total</span>
                      <p className="box-detials">[$$meetingroomtotal$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">AV Total</span>
                      <p className="box-detials">[$$avtotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food Total</span>
                      <p className="box-detials">[$$foodtotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Beverage Total</span>
                      <p className="box-detials">[$$beveragetotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting and AV Total</span>
                      <p className="box-detials">[$$meetingandavtotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food and Beverage Total</span>
                      <p className="box-detials">[$$foodandbeveragetotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Room Tax</span>
                      <p className="box-detials">[$$meetingroomtax$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">AV Tax</span>
                      <p className="box-detials">[$$avtax$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food Tax</span>
                      <p className="box-detials">[$$foodtax$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Beverage Tax</span>
                      <p className="box-detials">[$$beveragetax$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food and Beverage tax</span>
                      <p className="box-detials">[$$foodandbeveragetax$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Room Gross Total</span>
                      <p className="box-detials">[$$meetingroomgrosstotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">AV Gross Total</span>
                      <p className="box-detials">[$$avgrosstotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food Gross Total</span>
                      <p className="box-detials">[$$foodgrosstotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Beverage Gross Total</span>
                      <p className="box-detials">[$$beveragegrosstotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting and AV Gross Total</span>
                      <p className="box-detials">[$$meetingandavgrosstotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food and Beverage Gross Total</span>
                      <p className="box-detials">[$$foodandbeveragegrosstotal$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Room Tax Percentage</span>
                      <p className="box-detials">[$$meetingroomtaxpercentage$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">AV Tax Percentage</span>
                      <p className="box-detials">[$$avtaxpercentage$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food Tax Percentage</span>
                      <p className="box-detials">[$$foodtaxpercentage$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Beverage Tax Percentage</span>
                      <p className="box-detials">[$$beveragetaxpercentage$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting and AV Tax Percentage</span>
                      <p className="box-detials">[$$meetingandavtaxpercentage$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Food and Beverage Tax Percentage</span>
                      <p className="box-detials">[$$foodandbeveragetaxpercentage$$]</p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            {formInputs.associationType_Term === "ComboGroupMeeting" && (
              <div className="information-division">
                <h3 className="inner-header m-0">Group Tokens</h3>
                <div className="tab-inner-box position-relative">
                  <div className="token-header-label">Opportunity Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Oppo Name</span>
                      <p className="box-detials">[$$groupopponame$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Account Name</span>
                      <p className="box-detials">[$$groupaccountname$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Day / Date of Group</span>
                      <p className="box-detials">[$$groupdateofgroup$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Reservation Cut Off Date</span>
                      <p className="box-detials">[$$groupreservationcutoffdate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Reservation Method</span>
                      <p className="box-detials">[$$groupreservationmethod$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Payment Method</span>
                      <p className="box-detials">[$$grouppaymentmethod$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Current Date</span>
                      <p className="box-detials">[$$groupcurrentdate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Agreements</span>
                      <p className="box-detials">[$$groupspecialinstruction$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Commission</span>
                      <p className="box-detials">[$$groupcommission$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Rebate</span>
                      <p className="box-detials">[$$grouprebate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">IATA</span>
                      <p className="box-detials">[$$groupiata$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Group Code</span>
                      <p className="box-detials">[$$groupcode$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Booking Link</span>
                      <p className="box-detials">[$$bookinglink$$]</p>
                    </Col>
                  </Row>

                  <div className="token-header-label">Contact Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Title</span>
                      <p className="box-detials">[$$groupcontacttitle$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Contact Name</span>
                      <p className="box-detials">[$$groupcontactname$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Street</span>
                      <p className="box-detials">[$$groupaddress$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">City</span>
                      <p className="box-detials">[$$groupcontactcity$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">State</span>
                      <p className="box-detials">[$$groupcontactstate$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Country</span>
                      <p className="box-detials">[$$groupcontactcountry$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">ZipCode</span>
                      <p className="box-detials">[$$groupcontactzipcode$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Phone</span>
                      <p className="box-detials">[$$groupphone$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">E-mail</span>
                      <p className="box-detials">[$$groupemail$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">On-Site Contact</span>
                      <p className="box-detials">[$$grouponsitecontact$$]</p>
                    </Col>
                  </Row>

                  <div className="token-header-label">Room Tokens</div>
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Guest Room Requirements</span>
                      <p className="box-detials">[$$groupguestroomrequirements$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Total Amount</span>
                      <p className="box-detials">[$$totalamount$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Amount</span>
                      <p className="box-detials">[$$taxamount$$]</p>
                    </Col>

                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Percentage</span>
                      <p className="box-detials">[$$taxpercentage$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Gross Total </span>
                      <p className="box-detials">[$$grosstotal$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Tax Detail Grid </span>
                      <p className="box-detials"> [$$taxdetailgrid$$]</p>
                    </Col>
                  </Row>
                </div>

                <h3 className="inner-header m-0">Meeting & Catering Tokens</h3>
                <div className="tab-inner-box position-relative">
                  <Row className="container">
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Meeting Room Grid</span>
                      <p className="box-detials">[$$meetinggrid$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Special Instruction</span>
                      <p className="box-detials">[$$meetingspecialinstruction$$]</p>
                    </Col>
                    <Col xs={12} md={3} lg={3} className="mb-2">
                      <span className="box-label">Payment Method</span>
                      <p className="box-detials">[$$meetingpaymentmethod$$]</p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Col>
          <Col
            style={{ minHeight: "200px" }}
            xs={12}
            xl={12}
            lg={12}
            md={12}
            className="mb-3 col-12"
          >
            {formInputs.FormFiles.length ? (
              <div className="h-100 hotel-attachment cursor-pointer">
                <div className="attach-parent-col">
                  {formInputs.FormFiles.map((file_itm, i) => {
                    return (
                      <>
                        <div className="attach-parent-one-col">
                          <div className="attach-parent-main">
                            <div className="attach-parent-child">
                              <div className="attach-parent-image">
                                {(file_itm.name && file_itm.name.split(".")[1] === "XML") ||
                                "docx" ||
                                "doc" ? (
                                  <img src={DocxIcon} alt="" />
                                ) : file_itm.name.split(".")[1] === "pdf" ? (
                                  <img src={PdfIcon} alt="" />
                                ) : file_itm.name.split(".")[1] === "jpg" ||
                                  "png" ||
                                  "svg" ||
                                  "gif" ||
                                  "jpeg" ? (
                                  <img src={ImageIcon} alt="" />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div
                              onClick={() => {
                                handleRemoveFile(file_itm, i);
                              }}
                              className="btn-icon-grp-table close-icon"
                              tabIndex={-1}
                              onKeyDown={() => {
                                handleRemoveFile(file_itm, i);
                              }}
                              role={"button"}
                            >
                              <Button className="btn-icon cancelIcon"></Button>
                            </div>
                          </div>
                          <div title={file_itm.name} className="attach-name">
                            {file_itm.name}
                          </div>
                          <div className="attach-size">
                            {parseFloat((file_itm.size / 1000000).toFixed(2))} MB
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="attach-parent-button mt-auto">
                  {!uploadLoading ? (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleUploadAttachment();
                      }}
                      className="btn btn-primary w-100 justify-content-center"
                      type="button"
                    >
                      Upload
                    </Button>
                  ) : (
                    <Button className="btn btn-primary w-100 justify-content-center" type="button">
                      <Spinner size="sm" animation="border" variant="light" />
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div
                onClick={() => {
                  attachFileRef.current.click();
                }}
                onDragOver={(e: any) => {
                  e.preventDefault();
                }}
                onDrop={(e: any) => {
                  e.preventDefault();
                  handleAttachmentFile(Array.from(e.dataTransfer.files));
                }}
                className="empty-files"
                role="button"
                tabIndex={-1}
                onKeyDown={(e: any) => {
                  e.preventDefault();
                  handleAttachmentFile(Array.from(e.dataTransfer.files));
                }}
              >
                Drop Files To Upload
                <span className="d-block">(or click)</span>
              </div>
            )}

            <input
              type="file"
              accept=".doc,.docx"
              onChange={(e: any) => {
                e.preventDefault();
                handleAttachmentFile(Array.from(e.target.files));
              }}
              ref={attachFileRef}
              multiple={true}
              className="d-none"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="information-division">
              <h3 className="inner-header m-0">Uploaded Document List</h3>
              <div className="tab-inner-box position-relative">
                <TableView columnsData={columns} tableData={TableData} isData={IsDataCheck} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
