import { Row, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ActivityReport from "./ActivityReport";
import DashboardWeeklyReport from "./DashboardWeeklyReport";
import GroupGridTable from "./GroupGridTable";
import GroupMonthViewReport from "./GroupMonthViewReport";
import LNRGridTable from "./LNRGridTable";
import MarketingPlanReport from "./MarketingPlanReport";
import MeetingAndCateringByDate from "./MeetingAndCateringByDate";
import MeetingCateringGridTable from "./MeetingCateringGridTable";
import OpportunityBySourceReport from "./OpportunityBySourceReport";
import OpportunitySalesFunnelReport from "./OpportunitySalesFunnelReport";
import RFPGridTable from "./RFPGridTable";
import WeeklyReport from "./WeeklyReport";

function ReportMain() {
  const navigate = useNavigate();

  return (
    <div className="report-page">
      <Tab.Container id="left-tabs-example" defaultActiveKey="dashboard-weekly-report">
        <Row>
          <Tab.Content>
            <Tab.Pane eventKey="dashboard-weekly-report">
              <DashboardWeeklyReport />
            </Tab.Pane>
            <Tab.Pane eventKey="weekly-report">
              <WeeklyReport />
            </Tab.Pane>
            <Tab.Pane eventKey="marketing-plan-report">
              {/* Marketing Plan Report */}
              <MarketingPlanReport />
            </Tab.Pane>

            <Tab.Pane eventKey="activities-report">
              <ActivityReport />
            </Tab.Pane>

            <Tab.Pane eventKey="rfp-grid">
              <RFPGridTable />
            </Tab.Pane>
            <Tab.Pane eventKey="lnr-grid">
              <LNRGridTable />
            </Tab.Pane>
            <Tab.Pane eventKey="group-grid">
              <GroupGridTable />
            </Tab.Pane>
            <Tab.Pane eventKey="meeting-catering-grid">
              <MeetingCateringGridTable />
            </Tab.Pane>
            <Tab.Pane eventKey="group-room-count">
              <GroupMonthViewReport />
            </Tab.Pane>

            <Tab.Pane eventKey="opportunity-eby-source">
              <OpportunityBySourceReport />
            </Tab.Pane>
            <Tab.Pane eventKey="meeting-catering-by-date">
              <MeetingAndCateringByDate />
            </Tab.Pane>
            <Tab.Pane eventKey="opportunity-sales-funnel">
              <OpportunitySalesFunnelReport />
            </Tab.Pane>
          </Tab.Content>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default ReportMain;
