import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import GreenLabel from "../../../Component/Labels/GreenLabel/Index";
import GreyLabel from "../../../Component/Labels/GreyLabel/index";
import RedLabel from "../../../Component/Labels/RedLabel/Index";
import GlobalSearchloading from "../../../Component/Loader/GlobalSearchloading";
import NoRecordGlobalSearch from "../../../Component/NoData/NoRecordGlobalSearch";
import { GetGlobalSearchResultList } from "../../../Services/HeaderServices";
import { ToasterError } from "../../../Services/CommonServices";

function HeaderCommonSearch({
  AllHotels,
  startWith,
  commonSearchModule,
  setCommonSearchModule,
  setSearchInput,
  searchInput,
}) {
  const navigate = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);

  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  const [accountList, setAccountList] = useState<any>([]);
  const [contactList, setContactList] = useState([]);
  const [leadList, setLeadList] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const [loading, setLoading] = useState(false);

  const fetchSearchResult = async () => {
    let data = {
      clientID: clientID,
      propertyIDs: SelectedPropertyIDs && SelectedPropertyIDs.SelectedPropertyID.length > 0 ?
      SelectedPropertyIDs.SelectedPropertyID : "",
      // propertyIDs: AllHotels.toString(), // Pass All property ID if selected or not .
      searchText: searchInput,
      isSearchFromStartWith: startWith,
    };

    setLoading(true);
    let res = await GetGlobalSearchResultList(data, Token);

    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data) {
          setLoading(false);
          setAccountList(res.data.data.dataList1);
          setContactList(res.data.data.dataList2);
          setLeadList(res.data.data.dataList3);
          setActivityList(res.data.data.dataList4);
          setLoading(false);
        } else {
          setLoading(false);
          setAccountList([]);
          setContactList([]);
          setLeadList([]);
          setActivityList([]);
        }
      } else {
        ToasterError("Something Went Wrong");
      }
    }
  };

  useEffect(() => {
    if (commonSearchModule) {
      fetchSearchResult();
    }
  }, [searchInput, startWith]);

  return (
    <>
      <div className="header-search-div">
        <button
          className="btn-close"
          onClick={() => {
            setCommonSearchModule(false);
            setSearchInput("");
          }}
          type="button"
        ></button>
      </div>
      <Container>
        <div className="bottom-line">
          <h2 className="mb-3">Account</h2>
          <Row>
            {!loading ? (
              accountList.length ? (
                accountList.map((account_list: any, i) => {
                  return (
                    <Col key={i} className="mb-3" lg={4} md={6} xs={12}>
                      <Card className="actitvity-cards">
                        <div className="card-header d-flex align-items-start  justify-content-between p-0">
                          <div
                            onClick={() => {
                              navigate("/accounts-view", {
                                state: {
                                  AccountID: account_list.AccountID,
                                  AccountName: account_list.AccountName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            className="activity-card-link cursor-pointer"
                          >
                            {account_list.AccountName}
                          </div>
                          {account_list.AccountType_Term === "Active" && (
                            <GreenLabel successLabel="Active" />
                          )}
                          {account_list.AccountType_Term === "Inactive" && (
                            <RedLabel dangerLabel="Inactive" />
                          )}
                        </div>
                        <div className="activity-code">
                          <h6 className="mt-2 mb-2">{account_list.AccountCode}</h6>
                        </div>
                        {/* <div className="grey-labels">
                          <GreyLabel
                            onClick={() => {
                              navigate("/accounts-view", {
                                state: {
                                  AccountID: account_list.AccountID,
                                  AccountName: account_list.AccountName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            greyLabel="Account"
                          />
                          <GreyLabel
                            onClick={() => {
                              navigate("/accounts-view", {
                                state: {
                                  AccountID: account_list.AccountID,
                                  AccountName: account_list.AccountName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            greyLabel="Activity"
                          />
                          <GreyLabel
                            onClick={() => {
                              navigate("/accounts-view", {
                                state: {
                                  AccountID: account_list.AccountID,
                                  AccountName: account_list.AccountName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            greyLabel="RFP"
                          />
                          <GreyLabel
                            onClick={() => {
                              navigate("/accounts-view", {
                                state: {
                                  AccountID: account_list.AccountID,
                                  AccountName: account_list.AccountName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            greyLabel="LNR"
                          />
                          <GreyLabel
                            onClick={() => {
                              navigate("/accounts-view", {
                                state: {
                                  AccountID: account_list.AccountID,
                                  AccountName: account_list.AccountName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            greyLabel="Files"
                          />
                        </div> */}
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <NoRecordGlobalSearch />
              )
            ) : (
              <GlobalSearchloading />
            )}
          </Row>
        </div>
        <div className="bottom-line">
          <h2 className="mb-3">Contacts</h2>
          <Row>
            {" "}
            {!loading ? (
              contactList.length ? (
                contactList.map((contact_list: any, i) => {
                  return (
                    <Col className="mb-3" lg={4} md={6} xs={12}>
                      <Card className="actitvity-cards">
                        <div className="card-header d-flex align-items-start justify-content-between p-0">
                          <div
                            onClick={() => {
                              navigate("/contact/details", {
                                state: {
                                  ContactID: contact_list.ContactID,
                                  ContactName: contact_list.ContactName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            className="activity-card-link cursor-pointer"
                          >
                            {contact_list.AccountName}
                          </div>
                        </div>
                        <div className="accountant-name">
                          <h6
                            className="mt-2 mb-2"
                            onClick={() => {
                              navigate("/contact/details", {
                                state: {
                                  ContactID: contact_list.ContactID,
                                  ContactName: contact_list.ContactName,
                                },
                              });
                            }}
                          >
                            {contact_list.ContactName}
                          </h6>
                        </div>
                        <div className="activity-code d-flex justify-content-between">
                          <h6 className="mt-2 mb-2">{contact_list.ContactMobNo}</h6>
                        </div>
                        <div className="accountant-email">
                          <h6 className="mt-2 mb-2">{contact_list.Email}</h6>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <NoRecordGlobalSearch />
              )
            ) : (
              <GlobalSearchloading />
            )}
          </Row>
        </div>
        <div className="bottom-line">
          <h2 className="mb-3">Lead</h2>
          <Row>
            {" "}
            {!loading ? (
              leadList.length ? (
                leadList.map((lead_list: any, i) => {
                  return (
                    <Col className="mb-3" lg={4} md={6} xs={12}>
                      <Card className="actitvity-cards">
                        <div className="card-header d-flex align-items-start  justify-content-between p-0">
                          <div
                            onClick={() => {
                              navigate("/suspect/details", {
                                state: {
                                  leadID: lead_list.LeadID,
                                  leadName: lead_list.CompanyName,
                                },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            className="activity-card-link cursor-pointer"
                          >
                            {lead_list.CompanyName ? lead_list.CompanyName : ""}
                          </div>
                        </div>
                        <div className="accountant-name">
                          <h6 className="mt-2 mb-2">{lead_list.Name ? lead_list.Name : ""}</h6>
                        </div>
                        <div className="activity-code d-flex justify-content-between">
                          <h6 className="mt-2 mb-2">
                            {lead_list.MobileNo ? lead_list.MobileNo : ""}
                          </h6>
                          <h6 className="mt-2 mb-2">
                            {lead_list.TelephoneNo ? lead_list.TelephoneNo : ""}
                          </h6>
                        </div>
                        <div className="accountant-email">
                          <h6 className="mt-2 mb-2">{lead_list.Email ? lead_list.Email : ""}</h6>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <NoRecordGlobalSearch />
              )
            ) : (
              <GlobalSearchloading />
            )}
          </Row>
        </div>
        <div className="bottom-line">
          <h2 className="mb-3">Activity</h2>
          <Row>
            {" "}
            {!loading ? (
              activityList.length ? (
                activityList.map((activity_list: any, i) => {
                  return (
                    <Col className="mb-3" lg={4} md={6} xs={12}>
                      <Card className="actitvity-cards">
                        <div className="card-header d-flex align-items-start  justify-content-between p-0">
                          <div
                            onClick={() => {
                              navigate("/activity/details", {
                                state: { activityID: activity_list.ActivityID },
                              });
                              setCommonSearchModule(false);
                              setSearchInput("");
                            }}
                            className="activity-card-link cursor-pointer"
                          >
                            {activity_list.AccountName ? activity_list.AccountName : ""}
                          </div>
                          {activity_list.ActivityStatus_Term === "Complete" && (
                            <GreenLabel successLabel="Complete" />
                          )}
                          {activity_list.ActivityStatus_Term === "Open" && (
                            <RedLabel dangerLabel="Open" />
                          )}
                        </div>

                        <div className="accountant-name">
                          <h6 className="mt-2 mb-2">
                            {activity_list.ContactName ? activity_list.ContactName : ""}
                          </h6>
                        </div>
                        <div className="activity-code d-flex justify-content-between">
                          <h6 className="mt-2 mb-2">
                            {activity_list.StartDate ? activity_list.StartDate.split("T")[0] : ""}
                          </h6>
                          <h6 className="mt-2 mb-2">
                            {activity_list.EndDate ? activity_list.EndDate.split("T")[0] : ""}
                          </h6>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <NoRecordGlobalSearch />
              )
            ) : (
              <GlobalSearchloading />
            )}
          </Row>
        </div>
      </Container>
    </>
  );
}

export default HeaderCommonSearch;
