import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import GroupRoomCountModal from "../../Model/Reports/GroupRoomCountModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
} from "../../Services/CommonServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";

function GroupMonthViewReport() {
  let GroupRoomCountGRC_page_rights = CommonService.getPageRight("GroupRoomCount(GRC)");

  const navigate = useNavigate();
  const dataSelector: any = useSelector((state: any) => state.session);
  const hotelsData = useSelector((item: any) => item.header.Hotels);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);
  const d = new Date();
  let currentMonth = d.getMonth();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState<any>({});
  const [monthList, setMonthList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState<any>(currentMonth);
  const [ShowModal, setShowModal] = useState<any>(false);

  const [inputs, setInputs] = useState({
    yearID: "",
    monthID: "",
    year: "",
    month: "",
  });

  let iniSTR = "";
  if (Array.isArray(hotelsData)) {
    let count = 0;
    hotelsData.forEach((itm) => {
      iniSTR = count ? iniSTR + "," + itm.PropertyID : itm.PropertyID;
      count = count + 1;
    });
  }

  const fetchGRPMonthList = async (prop_ids) => {
    let input = {
      ...inputs,
      propertyIDs: SelectedPropertyIDs.SelectedPropertyID && SelectedPropertyIDs.SelectedPropertyID,
    };
    setInputs(input);
    setShowModal(true);
  };

  const fetchYearList = async () => {
    let yearInput = {
      clientID: clientID,
      category: "year",
    };
    let monthInput = {
      clientID: clientID,
      category: "month",
    };

    setPageLoading(true);

    let resYear = await getProjectTermByCategory(yearInput, Token);
    let resMonth = await getProjectTermByCategory(monthInput, Token);

    if (resYear && resMonth) {
      setPageLoading(false);
    }
    let iniYearId = "";
    let iniMonthID = "";
    let iniMonth = "";
    let iniYear = "";
    if (resYear.status === 200) {
      if (resYear.data.success) {
        if (resYear.data.data !== undefined && resYear.data.data) {
          if (
            resYear.data.data.dataList1 !== undefined &&
            resYear.data.data.dataList1 &&
            resYear.data.data.dataList1.length
          ) {
            let yrArray: any = [];
            resYear.data.data.dataList1.forEach((itm) => {
              yrArray = [
                ...yrArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];
            });
            let newSelection = {
              label: resYear.data.data.dataList1[0].TermCode,
              value: resYear.data.data.dataList1[0].TermID,
            };
            let currentYear = new Date().getFullYear();
            let crYrArray = yrArray.filter((obj) => parseInt(obj.label) === currentYear);

            iniYearId = crYrArray.length
              ? crYrArray[0].value
              : resYear.data.data.dataList1[0].TermID;
            iniYear = crYrArray.length
              ? crYrArray[0].label
              : resYear.data.data.dataList1[0].TermCode;
            setSelectedYear(crYrArray.length ? crYrArray[0] : newSelection);
            setYearList(yrArray);
          }
        }
      } else {
        ToasterError(resYear.message);
      }
    } else {
      ToasterError(resYear.message);
    }
    if (resMonth.status === 200) {
      if (resMonth.data.success) {
        if (resMonth.data.data !== undefined && resMonth.data.data) {
          if (
            resMonth.data.data.dataList1 !== undefined &&
            resMonth.data.data.dataList1 &&
            resMonth.data.data.dataList1.length
          ) {
            let MonthArray: any = [];
            resMonth.data.data.dataList1.forEach((itm) => {
              MonthArray = [
                ...MonthArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];
            });

            setSelectedMonth({
              label: resMonth.data.data.dataList1[currentMonth].TermCode,
              value: resMonth.data.data.dataList1[currentMonth].TermID,
            });
            iniMonthID = resMonth.data.data.dataList1[currentMonth].TermID;
            iniMonth = resMonth.data.data.dataList1[currentMonth].TermCode;
            setMonthList(MonthArray);
          }
        }
      } else {
        ToasterError(resYear.message);
      }
    } else {
      ToasterError(resYear.message);
    }

    setInputs({
      ...inputs,
      monthID: iniMonthID,
      yearID: iniYearId,
      year: iniYear,
      month: iniMonth,
    });
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("groupRoomCountReportFilter"))) {
      HandleRemoveOtherFilters("groupRoomCountReportFilter");
      HandleFilters("groupRoomCountReportFilter", {
        pagePath: "/GroupMonthViewReport",
        pageName: "groupRoomCountReport",
        filters: [],
        isSort: [],
      });
    }
    fetchYearList();
  }, []);

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {GroupRoomCountGRC_page_rights.is_view ? (
              !pageLoading ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={6} xl={3} className="mb-3">
                      <FloatingLabel controlId="floatingSelectGrid" label="">
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={selectedYear}
                          onChange={(itm: any) => {
                            setSelectedYear(itm);
                            setInputs({
                              ...inputs,
                              yearID: itm.value,
                              year: itm.label,
                            });
                          }}
                          options={yearList}
                          name="colors"
                          placeholder="Select Year"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                        />
                      </FloatingLabel>
                    </Col>
                    <Col lg={6} xl={3} className="mb-3">
                      <FloatingLabel controlId="floatingSelectGrid" label="">
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={selectedMonth}
                          onChange={(itm: any) => {
                            setSelectedMonth(itm);
                            setInputs({
                              ...inputs,
                              monthID: itm.value,
                              month: itm.label,
                            });
                          }}
                          options={monthList}
                          name="colors"
                          placeholder="Select Month"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="custom-profile-page">
                        <Modal.Footer className="border-0 justify-content-start">
                          {!loading ? (
                            <Button
                              onClick={() => {
                                if (GroupRoomCountGRC_page_rights.is_view) {
                                  fetchGRPMonthList(iniSTR);
                                } else {
                                  CommonService.unauthorizedAction();
                                }
                              }}
                              variant="primary ms-0"
                            >
                              <span className="me-2">
                                <img src={GenerateReport} alt="" />
                              </span>
                              Generate Report
                            </Button>
                          ) : (
                            <Button type="button" variant="primary">
                              <Spinner
                                size="sm"
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Loading
                            </Button>
                          )}
                        </Modal.Footer>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {ShowModal && (
          <GroupRoomCountModal
            ReportData={inputs}
            show={ShowModal}
            onHide={() => setShowModal(false)}
          />
        )}
      </div>
    </>
  );
}

export default GroupMonthViewReport;
