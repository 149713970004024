import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import { AppConfigData, ToasterError, ToasterSuccess } from "../../Services/CommonServices";
import { useSelector } from "react-redux";
import { ExportAccountFileOfInvalidData } from "../../Services/ImportServises/ImportServises";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import TableView from "../../Component/Table/Index";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";

export default function ImportAccountModal(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const SuccessfullImportdHeader = useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Account Owner",
        accessor: "owner",
      },
      {
        Header: "Local City",
        accessor: "localCity",
      },
      {
        Header: "Local State",
        accessor: "localState",
      },
      {
        Header: "Local Country",
        accessor: "localCountry",
      },
      {
        Header: "Local Phone",
        accessor: "localPhone",
      },
      {
        Header: "HQ Phone",
        accessor: "hqPhone",
      },
      {
        Header: "General Phone",
        accessor: "generalPhone",
      },
      {
        Header: "Website",
        accessor: "website",
      },
    ],
    []
  );

  let tempSuccessfullImportedData: any = [];
  const [SuccessfullImportedData, setSuccessfullImportedData] = useState([]);

  SuccessfullImportedData &&
    SuccessfullImportedData.map((item: any) => {
      tempSuccessfullImportedData.push({
        className: {
          // status: "centerAlignClass text-center",
          // top: "centerAlignClass text-center",
          // target: "centerAlignClass text-center",
          // growth: "centerAlignClass text-center",
          // anticipatedRevenue: "rightAlignClass text-end",
          // lastActivity: "rightAlignClass text-end",
        },
        controlRender: {},

        accountName: item.accountName,
        owner: item.accountOwner,
        localCity: item.city,
        localState: item.state,
        localCountry: item.country,
        localPhone: item.contactNo,
        hqPhone: item.headPhoneNo,
        generalPhone: item.phone,
        website: item.website,
      });
    });

  const InvalidImportdHeader = useMemo(
    () => [
      {
        Header: "Error Message",
        accessor: "ErrorMessage",
        className: "w-500",
      },
      {
        Header: "AccountName	",
        accessor: "AccountName",
      },
      {
        Header: "HotelName",
        accessor: "HotelName",
      },
      {
        Header: "AccountOwner",
        accessor: "AccountOwner",
      },
      {
        Header: "RFP",
        accessor: "RFP",
      },
      {
        Header: "LNR",
        accessor: "LNR",
      },
      {
        Header: "Group",
        accessor: "Group",
      },
      {
        Header: "MeetingCatering",
        accessor: "MeetingCatering",
      },
      {
        Header: "LocalStreet",
        accessor: "LocalStreet",
      },
      {
        Header: "LocalCity",
        accessor: "LocalCity",
      },
      {
        Header: "LocalState",
        accessor: "LocalState",
      },
      {
        Header: "LocalCountry",
        accessor: "LocalCountry",
      },
      {
        Header: "LocalZipCode",
        accessor: "LocalZipCode",
      },
      {
        Header: "LocalPhone",
        accessor: "LocalPhone",
      },
      {
        Header: "LocalPhoneExt",
        accessor: "LocalPhoneExt",
      },
      {
        Header: "HQStreet",
        accessor: "HQStreet",
      },
      {
        Header: "HQCity",
        accessor: "HQCity",
      },
      {
        Header: "HQState",
        accessor: "HQState",
      },
      {
        Header: "HQCountry",
        accessor: "HQCountry",
      },
      {
        Header: "HQZipCode",
        accessor: "HQZipCode",
      },
      {
        Header: "HQPhone",
        accessor: "HQPhone",
      },
      {
        Header: "HQPhoneExt",
        accessor: "HQPhoneExt",
      },
      {
        Header: "GeneralPhone",
        accessor: "GeneralPhone",
      },
      {
        Header: "GeneralPhoneExt",
        accessor: "GeneralPhoneExt",
      },
      {
        Header: "Website",
        accessor: "Website",
      },
      {
        Header: "Notes",
        accessor: "Notes",
      },
      {
        Header: "SourceField",
        accessor: "SourceField",
      },
    ],
    []
  );

  let tempInvalidImportedData: any = [];
  const [InvalidImportedData, setInvalidImportedData] = useState([]);

  InvalidImportedData &&
    InvalidImportedData.map((item: any) => {
      tempInvalidImportedData.push({
        controlRender: {
          RFP:
            item.isRFP === true ? <GreenLabel successLabel="Yes" /> : <RedLabel dangerLabel="No" />,
          LNR:
            item.isLNR === true ? <GreenLabel successLabel="Yes" /> : <RedLabel dangerLabel="No" />,
          Group:
            item.isGroup === true ? (
              <GreenLabel successLabel="Yes" />
            ) : (
              <RedLabel dangerLabel="No" />
            ),
          MeetingCatering:
            item.isMeetingCatering === true ? (
              <GreenLabel successLabel="Yes" />
            ) : (
              <RedLabel dangerLabel="No" />
            ),
        },
        className: {
          ErrorMessage: "text-danger w-300 whiteSpaceNowrap",
        },
        ErrorMessage: item.errorMessage,
        AccountName: item.accountName,
        HotelName: item.hotelName,
        AccountOwner: item.accountOwner,
        RFP: item.isRFP ? "Yes" : "No",
        LNR: item.isLNR ? "Yes" : "No",
        Group: item.isGroup ? "Yes" : "No",
        MeetingCatering: item.isMeetingCatering ? "Yes" : "No",
        LocalStreet: item.billingStreet1,
        LocalCity: item.billingCity,
        LocalState: item.billingState,
        LocalCountry: item.billingCountry,
        LocalZipCode: item.billingZip,
        LocalPhone: item.phone,
        LocalPhoneExt: item.phoneExt,
        HQStreet: item.addressStreet1,
        HQCity: item.city,
        HQState: item.state,
        HQCountry: item.country,
        HQZipCode: item.zipCode,
        HQPhone: item.headPhoneNo,
        HQPhoneExt: item.headExt,
        GeneralPhone: item.contactNo,
        GeneralPhoneExt: item.companyExt,
        Website: item.website,
        Notes: item.comment,
        SourceField: item.sourceField,
      });
    });

  useEffect(() => {
    if (
      props.ModuleData.validData !== undefined &&
      props.ModuleData.validData !== null &&
      props.ModuleData.validData.length !== 0
    ) {
      setSuccessfullImportedData(props.ModuleData.validData);
    }

    if (
      props.ModuleData.inValidData !== undefined &&
      props.ModuleData.inValidData !== null &&
      props.ModuleData.inValidData.length !== 0
    ) {
      setInvalidImportedData(props.ModuleData.inValidData);
    }
  }, [props && props.ModuleData]);

  async function ExportAccountErrorData() {
    let inputArrayOnj: any = [];
    InvalidImportedData &&
      InvalidImportedData.map((item: any) => {
        inputArrayOnj.push({
          accountName: item.accountName,
          hotelName: item.hotelName,
          accountOwner: item.accountOwner,
          // rfp: item.isRFP,
          // lnr: item.isLNR,
          // group: item.isGroup,
          // meetingCatering: item.isMeetingCatering,
          rfp: item.isRFP ? "Yes" : "No",
          lnr: item.isLNR ? "Yes" : "No",
          group: item.isGroup ? "Yes" : "No",
          meetingCatering: item.isMeetingCatering ? "Yes" : "No",
          localStreet: item.billingStreet1,
          localCity: item.billingCity,
          localState: item.billingState,
          localCountry: item.billingCountry,
          localZipCode: item.billingZip,
          localPhone: item.phone,
          localPhoneExt: item.phoneExt,
          hqStreet: item.addressStreet1,
          hqCity: item.city,
          hqState: item.state,
          hqCountry: item.country,
          hqZipCode: item.zipCode,
          hqPhone: item.headPhoneNo,
          hqPhoneExt: item.headExt,
          generalPhone: item.contactNo,
          generalPhoneExt: item.companyExt,
          website: item.website,
          notes: item.comment,
          sourceField: item.sourceField,
        });
      });

    let input: any = {
      inValidData: inputArrayOnj,
    };

    let res: any = await ExportAccountFileOfInvalidData(input, Token);

    if (res.data !== undefined && res.data !== null) {
      let Message = `Accounts Export SuccessFully`;
      if (res.data.statusCode === 200) {
        window.open(AppConfigData.APIBasePath + res.data.data);
        ToasterSuccess(Message);
      } else {
        ToasterError(res.data.message);
      }
    } else {
      ToasterError(res.message);
    }
  }

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center justify-content-between w-100"
        >
          <div> Import (Accounts) </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col xs={12}>
            <h6> Imported Data</h6>
            <div>
              <TableView
                columnsData={SuccessfullImportdHeader}
                tableData={tempSuccessfullImportedData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-5">
            <Row>
              <Col md={11}>
                <h6> Invalid Data</h6>
              </Col>
              <Col md={1}>
                <Button className="mb-2" variant="primary" onClick={() => ExportAccountErrorData()}>
                  <span className="me-2">
                    <img src={WhiteSaveIcon} alt="" />
                  </span>
                  Export
                </Button>
              </Col>
            </Row>

            <div>
              <TableView
                columnsData={InvalidImportdHeader}
                tableData={tempInvalidImportedData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
