import { combineReducers, createStore } from "redux";
import { headerReducer, sessionReducer, dashboardReducer } from "./Reducer";

const rootReducer = combineReducers({
  session: sessionReducer,
  header: headerReducer,
  dashboard: dashboardReducer,
});

const store = createStore(rootReducer);

export default store;
