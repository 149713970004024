import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useState } from "react";
import { Navbar } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { useDispatch, useSelector } from "react-redux";
// import "./App.css";
// import LogoIcon from "./Images/Gmail-Logo.png";
import draftIcon from "./Images/draftIcon.png";
import inboxIcon from "./Images/inboxIcon.png";
import sentIcon from "./Images/sentIcon.png";
import spamIcon from "./Images/spamIcon.png";
import trashIcon from "./Images/trashIcon.png";
// import { UserData } from "./Redux/Action";
import Drafts from "./mail_folders/Drafts";
import Inbox from "./mail_folders/Inbox";
import Sent from "./mail_folders/Sent";
import Spam from "./mail_folders/Spam";
import Trash from "./mail_folders/Trash";

function Gmail() {
  const [ketActive, setKetActive] = useState("inbox");
  let GTOKEN = localStorage.getItem("G_Token");


  // const UsrInfo = useSelector((state) => state.dashboard);

  // let G_Token = localStorage.getItem("gmail_cred");
  // let dispatch = new useDispatch();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      localStorage.setItem("G_Token", tokenResponse.access_token);

      const userInfo = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
      });

      localStorage.setItem("G_Info", JSON.stringify(userInfo.data));

      // if (userInfo !== undefined && userInfo !== "" && userInfo !== null) {
      //   dispatch(UserData(userInfo));
      // }

      localStorage.setItem("UserInfo", JSON.stringify(userInfo.data));
      window.location.reload();
    },
    onError: (errorResponse) => console.log(errorResponse),
    isSignedIn: true,
    accessType: "offline",
    scope: "https://www.googleapis.com/auth/gmail.readonly",
    // responseType: "code",
    prompt: "consent",
    // flow: "auth-code",
  });

  function googleLogOut() {
    localStorage.removeItem("G_Token");
    localStorage.removeItem("G_Info");
    window.location.reload();
  }

  return (
    <>
      <div className="App">
        {GTOKEN ? (
          <div className="inbox_main">
            <Row>
              <Tab.Container id="left-tabs-example" defaultActiveKey={ketActive}>
                <Col xxl={2} xl={2} lg={2} md={2}>
                  <div className="nav-pills">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="inbox"
                          className="    d-flex
    justify-space-between inbox_body_1
    align-items-center"
                          onClick={() => setKetActive("inbox")}
                        >
                          <img src={inboxIcon} alt="inbox icon" />
                          <div
                            className="ms-2 d-flex
    justify-content-between
    align-items-center "
                          >
                            <span>Inbox</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="sent"
                          className="    d-flex
    justify-space-between inbox_body_1
    align-items-center"
                          onClick={() => setKetActive("sent")}
                        >
                          <img src={sentIcon} alt="sent icon" />
                          <div
                            className="ms-2 d-flex
    justify-content-between
    align-items-center "
                          >
                            <span>Sent</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="drafts"
                          className="    d-flex
    justify-space-between inbox_body_1
    align-items-center"
                          onClick={() => setKetActive("drafts")}
                        >
                          <img src={draftIcon} alt="draft icon" />{" "}
                          <div
                            className="ms-2 d-flex
    justify-content-between
    align-items-center "
                          >
                            <span>Drafts</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="deleted-emails"
                          className="    d-flex
    justify-space-between inbox_body_1
    align-items-center"
                          onClick={() => setKetActive("deleted-emails")}
                        >
                          <img src={trashIcon} alt="trash icon" />{" "}
                          <div
                            className="ms-2 d-flex
    justify-content-between
    align-items-center "
                          >
                            <span>Trash</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="spam"
                          className="    d-flex
    justify-space-between inbox_body_1
    align-items-center"
                          onClick={() => setKetActive("spam")}
                        >
                          <img src={spamIcon} alt="spam icon" />

                          <div
                            className="ms-2 d-flex
    justify-content-between
    align-items-center "
                          >
                            <span>Spam</span>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Button onClick={googleLogOut} variant="primary">
                      Sign Out
                    </Button>
                  </div>
                </Col>

                <Col xxl={10} xl={10} lg={10} md={10} className="mt-4 mt-lg-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="inbox">{ketActive === "inbox" && <Inbox />}</Tab.Pane>
                    <Tab.Pane eventKey="sent">{ketActive === "sent" && <Sent />}</Tab.Pane>
                    <Tab.Pane eventKey="drafts">{ketActive === "drafts" && <Drafts />}</Tab.Pane>
                    <Tab.Pane eventKey="deleted-emails">
                      {ketActive === "deleted-emails" && <Trash />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="spam">{ketActive === "spam" && <Spam />}</Tab.Pane>
                  </Tab.Content>
                </Col>
              </Tab.Container>
            </Row>
          </div>
        ) : (
          <>
            <div className="logo-live">
              <div>
                <Button onClick={googleLogin} variant="primary">
                  Login
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Gmail;
