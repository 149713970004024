import axios from "axios";
import { AppConfigData } from "../CommonServices";
import { Cookies } from "react-cookie-consent";
//#region AdditionalSetings Category List
export const getProjectTermCategoryList = async (accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/AdditionalSettings/GetProjectTermList",
      data: "",
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
//#endregion
//#region AdditionalSetings Category Details By Category type :
export const getProjectTermByCategory = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/AdditionalSettings/GetProjectTermListByCategory",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SupportDetailSave = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Support/SupportDetailSave",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const getBrandAccountManager = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Contacts/GetBrandAccountManagerList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
// export const getEditAccountDetails = async (ReqData: any, accessToken) => {
//   try {
//     const response = await axios({
//       method: "POST",
//       url: AppConfigData.APIBasePath + "client/api/Account/GetByPrimaryKeyWithAllDetails",
//       data: ReqData,
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "bearer " + accessToken,
//       },
//     });
//     return response;
//   } catch (error :any) {
//     let data : any = null
//     if(error.response.status === 401){
//       data = {
//         data: {
//           success: false,
//           StatusCode: "401",
//           Message: "Session has been expired.",
//         },
//       };
//       Cookies.remove("SystemSettingDate");
//       localStorage.clear()
//       window.location.pathname = '/'
//     }else{
//     data = {
//         data: {
//           success: false,
//           StatusCode: "02",
//           Message: "Something Wrong in API",
//         },
//       };
//     }
//     return data;
//   }
// };

//#endregion
export const DeleteAdditionalSetting = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/AdditionalSettings/Delete",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SaveAdditionalSetting = async (ReqData: any, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/AdditionalSettings/Save",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
