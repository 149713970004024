import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
import GreenLabel from "../../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../../Component/Labels/RedLabel/Index";
import TableView from "../../../Component/Table/Index";
import UserModal from "../../../Model/UserModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
  getDateInFormat,
  getDateTimeInDBFormat,
  getDateInDBFormatForExcell,
  getSortDataByObjKey,
} from "../../../Services/CommonServices";
import {
  CheckUserLimit,
  DeleteUserDetail,
  getUserListData,
} from "../../../Services/Setup&Config/UserSetupService";

function Users() {
  let page_rights_User = CommonService.getPageRight("User");

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const [modalShow, setModalShow] = React.useState(false);
  const [UserListData, setUserListData]: any = React.useState({
    IsData: true,
    data: [],
  });
  const [UserID, setUserID]: any = React.useState({
    employeeID: "00000000-0000-0000-0000-000000000000",
    userID: "00000000-0000-0000-0000-000000000000",
  });

  const usersTableColumns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        className: "w-300",
      },
      {
        Header: "Password",
        accessor: "password",
        // className: "w-300",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact No.",
        accessor: "contact",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Last Login",
        accessor: "lastLogin",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "content-end table-action sticky-action",
      },
    ],
    []
  );

  let usersTableData: any = [];
  UserListData.data.map((item) => {
    usersTableData.push({
      controlRender: {
        status:
          item.BlockStatus !== undefined && item.BlockStatus !== null && item.BlockStatus !== "" ? (
            item.BlockStatus === "Active" ? (
              <GreenLabel successLabel={item.BlockStatus} />
            ) : (
              <RedLabel dangerLabel={item.BlockStatus} />
            )
          ) : (
            ""
          ),
        email: <a href={"mailto:" + item.EmailID}>{item.EmailID}</a>,
        contact:
          item.ContactNo && item.ContactNo.includes(" ") ? (
            <a href={"tel:" + item.ContactNo}>{item.ContactNo}</a>
          ) : (
            <a href={"tel:" + item.ContactNo}>{item.ContactNo}</a>
          ),
        lastLogin: getDateInFormat(item.LastLoginDate),
      },
      className: {
        action: "table-action sticky-action",
        lastLogin: "text-end",
      },
      password: item.Password,
      name: item.UserName,
      email: item.EmailID,
      contact: item.ContactNo,
      lastLogin: getDateTimeInDBFormat(item.LastLoginDate), // item.LastLoginDate === null ? "Never" : item.LastLoginDate && item.LastLoginDate.split("T")[0],
      status: item.BlockStatus,
      action: (
        <div className="btn-icon-grp-table justify-content-end">
          <Button
            className="btn-icon editIcon"
            onClick={() => {
              if (page_rights_User.is_update) {
                setUserID({
                  ...UserID,
                  ["employeeID"]: item.EmployeeID,
                  ["userID"]: item.UserID,
                });
                setModalShow(true);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
          <Button
            className="btn-icon cancelIcon"
            onClick={() => {
              if (page_rights_User.is_delete) {
                DeleteUser(item);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
        </div>
      ),
      // ),
    });
  });

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("userFilter"))) {
      HandleRemoveOtherFilters("userFilter");
      HandleFilters("userFilter", {
        pagePath: "/SetupAndConfigurations/user",
        pageName: "user",
        filters: [],
        isSort: [],
      });
    }
    getUserData();
  }, []);

  async function getUserData() {
    let input: any = {
      clientID: clientID,
      userName: "",
      displayName: "",
      emailID: "",
      gmailEmailID: "",
      fName: "",
      lName: "",
    };
    setUserListData({ IsData: true, data: [] });
    let res: any = await getUserListData(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null) {
        let resToSet: any = [];
        if (
          dataSelector.auth_UserDetail.U_UserTypeID !== undefined &&
          dataSelector.auth_UserDetail.U_UserTypeID !== null &&
          dataSelector.auth_UserDetail.U_UserTypeID.toUpperCase() ===
            "3D8522C8-EBFF-470F-95DD-B8C2AD1B678F"
        ) {
          res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.map((item) => {
              if (item.UserTypeID !== "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
                resToSet.push(item);
              } else {
              }
            });
        } else {
          resToSet = res.data.data.dataList1;
        }

        setUserListData({ IsData: false, data: resToSet });
      }
    }
  }

  function showExistLimit() {
    ToasterError("Contracted User Limit Over");
  }

  async function checkLimitOfUser() {
    let res: any = await CheckUserLimit(Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data === true) {
          setModalShow(true);
        } else {
          showExistLimit();
        }
      }
    }
  }

  function DeleteUser(data) {
    Swal.fire({
      title: "Are you sure want to Delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          userID: data.UserID,
          employeeID: data.EmployeeID,
        };

        let res: any = await DeleteUserDetail(input, Token);

        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              ToasterSuccess("User Delete Successfully");

              getUserData();
            } else {
              ToasterError("Something Went Wrong");
            }
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }

  function exportUserData() {
    const DateValue = new Date();
    let localFilters = JSON.parse(localStorage.getItem("userFilter"));

    let mainTableData: any = [];

    UserListData.data !== undefined &&
      UserListData.data !== null &&
      UserListData.data.map((item: any) => {
        mainTableData.push({
          EmployeeID: item.EmployeeID !== null && item.EmployeeID !== "null" ? item.EmployeeID : "",
          EmployeeCode:
            item.EmployeeCode !== null && item.EmployeeCode !== "null" ? item.EmployeeCode : "",
          EmployeeName:
            item.EmployeeName !== null && item.EmployeeName !== "null" ? item.EmployeeName : "",
          DepartmentID:
            item.DepartmentID !== null && item.DepartmentID !== "null" ? item.DepartmentID : "",
          EmployeeRegNo:
            item.EmployeeRegNo !== null && item.EmployeeRegNo !== "null" ? item.EmployeeRegNo : "",
          Add1: item.Add1 !== null && item.Add1 !== "null" ? item.Add1 : "",
          Add2: item.Add2 !== null && item.Add2 !== "null" ? item.Add2 : "",
          City: item.City !== null && item.City !== "null" ? item.City : "",
          State: item.State !== null && item.State !== "null" ? item.State : "",
          ZipCode: item.Zipcode !== null && item.Zipcode !== "null" ? item.Zipcode : "",
          Country: item.Country !== null && item.Country !== "null" ? item.Country : "",
          MobileNo: item.MobileNo !== null && item.MobileNo !== "null" ? item.MobileNo : "",
          ExtraNo: item.ExtraNo !== null && item.ExtraNo !== "null" ? item.ExtraNo : "",
          FirstName: item.FName !== null && item.FName !== "null" ? item.FName : "",
          LastName: item.LName !== null && item.LName !== "null" ? item.LName : "",
          DepartmentName:
            item.DepartmentName !== null && item.DepartmentName !== "null"
              ? item.DepartmentName
              : "",
          UserID: item.UserID !== null && item.UserID !== "null" ? item.UserID : "",
          Notes: item.Notes !== null && item.Notes !== "null" ? item.Notes : "",
          Ext: item.Ext !== null && item.Ext !== "null" ? item.Ext : "",
          IsBlock: item.IsBlock !== null && item.IsBlock !== "null" ? item.IsBlock : "",
          IsTSA: item.IsTSA !== null && item.IsTSA !== "null" ? item.IsTSA : "",
          name: item.UserName !== null && item.UserName !== "null" ? item.UserName : "",
          password: item.Password !== null && item.Password !== "null" ? item.Password : "",
          email: item.EmailID !== null && item.EmailID !== "null" ? item.EmailID : "",
          contact: item.ContactNo !== null && item.ContactNo !== "null" ? item.ContactNo : "",
          status: item.BlockStatus !== null && item.BlockStatus !== "null" ? item.BlockStatus : "",
          lastLogin: getDateInDBFormatForExcell(item.LastLoginDate),
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            EmployeeCode: item.EmployeeCode,
            EmployeeName: item.EmployeeName,
            Email: item.email,
            EmployeeRegNo: item.EmployeeRegNo,
            Add1: item.Add1,
            Add2: item.Add2,
            City: item.City,
            State: item.State,
            ZipCode: item.Zipcode,
            Country: item.Country,
            ContactNo: item.contact,
            MobileNo: item.MobileNo,
            ExtraNo: item.ExtraNo,
            FirstName: item.FName,
            LastName: item.LName,
            DepartmentName: item.DepartmentName,
            Notes: item.Notes,
            Password: item.password,
            logindate: item.lastLogin,
            Ext: item.Ext,
            IsBlock: item.IsBlock,
            IsTSA: item.IsTSA,
          });
        });

        let FileName =
          "User" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(ascDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            EmployeeCode: item.EmployeeCode,
            EmployeeName: item.EmployeeName,
            Email: item.email,
            EmployeeRegNo: item.EmployeeRegNo,
            Add1: item.Add1,
            Add2: item.Add2,
            City: item.City,
            State: item.State,
            ZipCode: item.Zipcode,
            Country: item.Country,
            ContactNo: item.contact,
            MobileNo: item.MobileNo,
            ExtraNo: item.ExtraNo,
            FirstName: item.FName,
            LastName: item.LName,
            DepartmentName: item.DepartmentName,
            Notes: item.Notes,
            Password: item.password,
            logindate: item.lastLogin,
            Ext: item.Ext,
            IsBlock: item.IsBlock,
            IsTSA: item.IsTSA,
          });
        });

        let FileName =
          "User" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(descDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            EmployeeCode: item.EmployeeCode,
            EmployeeName: item.EmployeeName,
            Email: item.email,
            EmployeeRegNo: item.EmployeeRegNo,
            Add1: item.Add1,
            Add2: item.Add2,
            City: item.City,
            State: item.State,
            ZipCode: item.Zipcode,
            Country: item.Country,
            ContactNo: item.contact,
            MobileNo: item.MobileNo,
            ExtraNo: item.ExtraNo,
            FirstName: item.FName,
            LastName: item.LName,
            DepartmentName: item.DepartmentName,
            Notes: item.Notes,
            Password: item.password,
            logindate: item.lastLogin,
            Ext: item.Ext,
            IsBlock: item.IsBlock,
            IsTSA: item.IsTSA,
          });
        });

        let FileName =
          "User" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(normalDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          EmployeeCode: item.EmployeeCode,
          EmployeeName: item.EmployeeName,
          Email: item.email,
          EmployeeRegNo: item.EmployeeRegNo,
          Add1: item.Add1,
          Add2: item.Add2,
          City: item.City,
          State: item.State,
          ZipCode: item.Zipcode,
          Country: item.Country,
          ContactNo: item.contact,
          MobileNo: item.MobileNo,
          ExtraNo: item.ExtraNo,
          FirstName: item.FName,
          LastName: item.LName,
          DepartmentName: item.DepartmentName,
          Notes: item.Notes,
          Password: item.password,
          logindate: item.lastLogin,
          Ext: item.Ext,
          IsBlock: item.IsBlock,
          IsTSA: item.IsTSA,
        });
      });

      let FileName =
        "User" +
        DateValue.getFullYear() +
        "-" +
        (DateValue.getMonth() + 1) +
        "-" +
        DateValue.getDate();
      let binaryWS = XLSX.utils.json_to_sheet(normalInitDataBind);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
      XLSX.writeFile(wb, FileName + ".xlsx");
    }

    // Previous Export Code :
    // let mainTableData: any = [];
    // UserListData.data !== undefined &&
    //   UserListData.data !== null &&
    //   UserListData.data.map((item: any) => {
    //     mainTableData.push({
    //       EmployeeID: item.EmployeeID,
    //       EmployeeCode: item.EmployeeCode,
    //       EmployeeName: item.EmployeeName,
    //       Email: item.EmailID,
    //       DepartmentID: item.DepartmentID,
    //       EmployeeRegNo: item.EmployeeRegNo,
    //       Add1: item.Add1,
    //       Add2: item.Add2,
    //       City: item.City,
    //       State: item.State,
    //       ZipCode: item.Zipcode,
    //       Country: item.Country,
    //       ContactNo: item.ContactNo,
    //       MobileNo: item.MobileNo,
    //       ExtraNo: item.ExtraNo,
    //       FirstName: item.FName,
    //       LastName: item.LName,
    //       DepartmentName: item.DepartmentName,
    //       UserID: item.UserID,
    //       Notes: item.Notes,
    //       Password: item.Password,
    //       logindate: item.LastLoginDate && item.LastLoginDate.split("T")[0],
    //       Ext: item.Ext,
    //       IsBlock: item.IsBlock,
    //       IsTSA: item.IsTSA,
    //     });
    //   });
    // let FileName = "User Export";
    // let binaryWS = XLSX.utils.json_to_sheet(mainTableData);
    // var wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
    // XLSX.writeFile(wb, FileName + ".xlsx");
  }

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Export Data</Tooltip>}>
          <Button
            className="btn-icon exportFileIcon"
            disabled={UserListData.data.length !== 0 ? false : true}
            onClick={() => {
              if (page_rights_User.is_export) {
                exportUserData();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add User</Tooltip>}>
          <Button
            onClick={() => {
              if (page_rights_User.is_create) {
                checkLimitOfUser();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            className="btn-icon addFormIcon"
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <Row>
          <Col xs={12}>
            <div className="lead-height-table">
              <TableView
                columnsData={usersTableColumns}
                tableData={usersTableData}
                isData={UserListData.IsData}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modalShow === true && (
        <UserModal
          getUserData={getUserData}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setUserID({
              employeeID: "00000000-0000-0000-0000-000000000000",
              userID: "00000000-0000-0000-0000-000000000000",
            });
          }}
          UserID={UserID}
        />
      )}
    </>
  );
}
export default Users;
