/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner, Table } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import { updateLeadDataFetch } from "../../Redux/actions";
import { ToasterError, ToasterSuccess } from "../../Services/CommonServices";
import { getEmailAssignToDropdownList, saveSuspectLead } from "../../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
// eslint-disable-next-line import/no-named-as-default
import AddSuspect from "../AddSuspect";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../Component/Loader/PageLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Cookies } from "react-cookie-consent";

export default function AddLeadModal({ modalShowSuspects, setModalShowSuspects }) {
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  let navigate = useNavigate();
  const [fullModalShowSuspects, setFullModalShowSuspects] = useState(false);
  const hotelsData: any = useSelector((state: any) => state.header.Hotels) || [];
  const [SourceDropdownList] = useState({ data: [] });
  const globalHotelData: any = useSelector((state: any) => state.dashboard);

  const dispatch = useDispatch();
  let allHotelsName: any = [];
  allHotelsName = hotelsData.propertyDetail;

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const lead_data_reload_count = dataSelector.lead_data_reload_count;
  const isData = dataSelector.auth_UserDetail;
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;

  let initialHotelToSet: any = [];

  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.forEach((item: any) => {
      initialHotelToSet = [
        ...initialHotelToSet,
        {
          label: item.HotelCode + " " + item.PropertyName,
          value: item.PropertyID,
        },
      ];
    });

  let initialFormInputs = {
    companyName: "",
    isGroup: false,
    lead_Source_Term: "",
    lastName: "",
    firstName: "",
    lstPropertyIDs: [],
    dosid: "00000000-0000-0000-0000-000000000000",
    lead_Status_Term: "Open",
    priority: "Low",
    ext: "",
    countrycode: "",
    telephoneNo: "",
    groupType: "",
    email: "",
    comment: "",
    lstLeadBookingDate: [],
    // unused
    mobCountryCode: "",
    accountID: "00000000-0000-0000-0000-000000000000",
    address: "",
    city: "",
    clientID: "00000000-0000-0000-0000-000000000000",
    country: "",
    createdOn: "",
    isActive: true,
    isConvertAcc: null,
    lastContactedBy: "00000000-0000-0000-0000-000000000000",
    lastContactedOn: null,
    leadID: "00000000-0000-0000-0000-000000000000",
    leadNo: "",
    leadRatings: 0,
    mobileNo: "",
    ownerID: "00000000-0000-0000-0000-000000000000",
    propertyID: "00000000-0000-0000-0000-000000000000",
    referenceBy: "",
    referenceItem: "",
    seqNo: 0,
    state: "",
    street: "",
    territeryID: "00000000-0000-0000-0000-000000000000",
    updatelog: "",
    website: "",
    zipcode: "",
  };

  let initialFormInputEr = {
    telephoneNo: "",
    companyName: "",
    lstPropertyIDs: "",
    email: "",
  };

  const validation_msg = {
    invalid_telephoneNo: "Enter Valid Contact No.",
    companyName: "This field is required.",
    lstPropertyIDs: "This field is required.",
    email: "This field is required.",
    telephoneNo: "This field is required.",
    invalid_email: "Email is invalid.",
    email_or_contact: "Email or contact is required.",
  };

  let initialGroupInputEr = {
    groupType: "",
  };

  const grp_validation_msg = {
    groupType: "This field is required.",
  };

  // const [hotelOptions, setHotelOptions] = useState(initialHotelToSet);

  const [selectedHotelOptions, setSelectedHotelOptions]: any = useState([]);

  const [groupTypeData, setGroupTypeData]: any = useState([]);
  const [groupInuptCount, setGroupInuptCount] = useState(1);

  const [groupTab, setGroupTab]: any = useState({
    1: {
      start_date: "",
      end_date: "",
      room: "",
    },
  });

  const [groupTabEr, setGroupTabEr]: any = useState({
    1: {
      start_date: "",
      // end_date: "",
      room: "",
    },
  });

  const [accountOwners, setAccountOwner]: any = useState([]);

  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [sourceDropdownList, setSourceDropdownList]: any = useState([]);

  const [formInputs, setFormInputs] = useState<any>(initialFormInputs);

  const [groupInputsEr, setGroupInputsEr] = useState<any>(initialGroupInputEr);

  const [formInputsEr, setFormInputsEr] = useState<any>(initialFormInputEr);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [StatusData, setStatusData]: any = useState([]);

  const [PriorityData, setPriorityData]: any = useState([]);

  const handleClosePopup = () => {
    setModalShowSuspects(false);
    setFormInputsEr(initialFormInputEr);
    setGroupInputsEr(initialGroupInputEr);
    setFormInputs(initialFormInputs);
    setSourceDropdownList([]);
    setAccountOwner([]);
    setGroupTabEr({
      1: {
        start_date: "",
        end_date: "",
        room: "",
      },
    });
    setGroupTab({
      1: {
        start_date: "",
        end_date: "",
        room: "",
      },
    });
    setGroupInuptCount(1);
    setGroupTypeData([]);
    setSelectedHotelOptions([]);
  };

  const getAccountOwnerDropdown = async (PropID) => {
    let input = {
      clientID: clientID,
      propertyIDs: PropID,
    };
    // input.propertyIDs = PropID;

    // if (userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
    //   input.propertyIDs = null
    // }

    let res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        setAccountOwner(res.data.data.dataList1);
        let userIDRes = res.data.data.dataList1.filter((item) => item.UserID === userId);
        setFormInputs({
          ...formInputs,
          lstPropertyIDs: [PropID],
          dosid: userIDRes.length && userIDRes[0].UserID,
        });
      }
    }
  };

  const fetchPreData = async () => {
    let inputs = {
      category: "Source",
      clientID: clientID,
    };
    const res: any = await getProjectTermByCategory(inputs, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setSourceDropdownList(res.data.data.dataList1);
      }
    }
  };

  const fetchGroupTypeData = async () => {
    let input = {
      clientID: clientID, // "00000000-0000-0000-0000-000000000000",
      category: "Lead Group Type",
    };
    // input.clientID = clientID;
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data !== null && res.data !== undefined) {
          setGroupTypeData(res.data.data.dataList1);
        }
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    let error_json = {};
    let lenCheck = ["lstPropertyIDs"];
    Object.keys(formInputsEr).forEach((form_key) => {
      error_json = {
        ...error_json,
        [form_key]: "",
      };
    });
    let removeKey = ["telephoneNo", "email"];
    Object.keys(formInputsEr).forEach((form_key) => {
      if (lenCheck.includes(form_key)) {
        if (!formInputs[form_key].length) {
          error_json = {
            ...error_json,
            [form_key]: validation_msg[form_key],
          };
          isValid = false;
        }
      } else {
        if (
          (formInputs[form_key] === "" || !formInputs[form_key]) &&
          !removeKey.includes(form_key)
        ) {
          error_json = {
            ...error_json,
            [form_key]: validation_msg[form_key],
          };
          isValid = false;
        } else {
          if (removeKey.includes(form_key)) {
            // if (formInputs['email'] == "" && formInputs['telephoneNo'] == "") {
            //   error_json = {
            //     ...error_json,
            //     'email': validation_msg['email_or_contact'],
            //   };
            //   isValid = false;
            // }
          }

          if (form_key === "email") {
            let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (formInputs[form_key].slice(-19) !== "@houston.nae.school") {
              if (formInputs[form_key] !== "" && !formInputs[form_key].match(mailformat)) {
                error_json = {
                  ...error_json,
                  [form_key]: validation_msg.invalid_email,
                };
                isValid = false;
              }
            } else {
              let emailFormate = /^[@.a-zA-Z1-9]+$/;
              if (!formInputs[form_key].match(emailFormate)) {
                error_json = {
                  ...error_json,
                  [form_key]: validation_msg.invalid_email,
                };
                isValid = false;
              }
            }
          }
          if (form_key === "telephoneNo") {
            if (
              formInputs[form_key] !== "" &&
              formInputs[form_key].trim().replaceAll("-", "").length < 10
            ) {
              error_json = {
                ...error_json,
                [form_key]: validation_msg.invalid_telephoneNo,
              };
              isValid = false;
            }
          }
        }
      }
    });
    setFormInputsEr(error_json);
    return isValid;
  };

  const validateGroupForm = () => {
    let isValid = true;
    let error_json = {};
    Object.keys(groupInputsEr).forEach((form_key) => {
      error_json = {
        ...error_json,
        [form_key]: "",
      };
    });
    Object.keys(groupInputsEr).forEach((form_key) => {
      if (formInputs[form_key] === "" || !formInputs[form_key]) {
        error_json = {
          ...error_json,
          [form_key]: grp_validation_msg[form_key],
        };
        isValid = false;
      }
    });
    let errosJson = {};
    Object.keys(groupTabEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          start_date: "",
          end_date: "",
          room: "",
        },
      };
    });

    Object.keys(groupTab).forEach((key_gr) => {
      if (groupTab[key_gr].start_date === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            start_date: "Start date is required.",
            end_date: errosJson[key_gr].end_date,
            room: errosJson[key_gr].room,
          },
        };
        isValid = false;
      }
      if (groupTab[key_gr].end_date === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            end_date: "End date is required.",
            start_date: errosJson[key_gr].start_date,
            room: errosJson[key_gr].room,
          },
        };
        isValid = false;
      }
      if (groupTab[key_gr].room === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            room: "Room no. required.",
            end_date: errosJson[key_gr].end_date,
            start_date: errosJson[key_gr].start_date,
          },
        };
        isValid = false;
      }
    });
    setGroupTabEr(errosJson);

    setGroupInputsEr(error_json);
    return isValid;
  };

  const handleFormSave = async (e) => {
    e.preventDefault();
    let validateData = true;
    let isValidateForm = await validateForm();
    if (formInputs.isGroup) {
      validateData = await validateGroupForm();
    }
    if (validateData && isValidateForm) {
      if (formInputs.telephoneNo || formInputs.email) {
        let lstLeadBookingDateToAppend: any = [];
        Object.keys(groupTab).forEach((grp_key) => {
          lstLeadBookingDateToAppend = [
            ...lstLeadBookingDateToAppend,
            {
              bookingDate: groupTab[grp_key].start_date,
              noofRoom: groupTab[grp_key].room,
              endDate: groupTab[grp_key].start_date,
            },
          ];
        });

        setSubmitLoading(true);
        const res: any = await saveSuspectLead(
          {
            ...formInputs,
            lstLeadBookingDate: lstLeadBookingDateToAppend,
            telephoneNo: formInputs.countrycode + " " + formInputs.telephoneNo,
          },
          Token
        );
        if (res.data !== undefined) {
          let Message = "Lead Inserted SuccessFully";
          if (res.data.statusCode === 200) {
            setSubmitLoading(false);
            ToasterSuccess(Message);
            handleClosePopup();
            dispatch(updateLeadDataFetch(lead_data_reload_count + 1));

            if (res.data.data) {
              navigate("/suspect/details", {
                state: { leadID: res.data.data.leadID, leadName: res.data.data.companyName },
              });
            }
          } else {
            ToasterError(res.data.message);
            setSubmitLoading(false);
          }
        } else {
          setSubmitLoading(false);
          ToasterError(res.message);
        }
      } else {
        ToasterError("Please enter one of the following: Office Phone or Email.");
      }
    }
  };

  let MySourceOption: any = [];
  sourceDropdownList.map((item: any) => {
    MySourceOption.push({
      value: item.Term,
      label: item.Term,
    });
  });

  //Assign To Dropdown
  let optionsAssignTo: any = [];
  accountOwners !== undefined &&
    accountOwners !== null &&
    accountOwners.length > 0 &&
    accountOwners.map((item: any) => {
      optionsAssignTo.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  let MyGroupOption: any = [];
  groupTypeData.map((item: any) => {
    MyGroupOption.push({
      value: item.Term,
      label: item.Term,
    });
  });

  useEffect(() => {
    sethotelChangeLoading(true);
    if (!SelectedPropertyID.includes(",")) {
      setSelectedHotelOptions({ label: SelectedPropertyName, value: SelectedPropertyID });
      getAccountOwnerDropdown(SelectedPropertyID);
    }

    getAllDataOfProjectTerm();
    // getStatusAndPriorityByProjectTerm();
    // fetchGroupTypeData();
    // fetchPreData();
  }, []);

  async function getAllDataOfProjectTerm() {
    let input: any = {
      clientID: clientID, // "00000000-0000-0000-0000-000000000000",
      category: "Lead Status, Priority, Lead Group Type, Source",
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Lead Status");
          setStatusData(tempStatus);
          let tempPriority =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Priority");
          setPriorityData(tempPriority);
          let tempGoupType =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Lead Group Type");
          setGroupTypeData(tempGoupType);
          let tempSourceDropdown =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Source");
          setSourceDropdownList(tempSourceDropdown);
        }
      }
    }
    sethotelChangeLoading(false);
  }

  return (
    <>
      <Modal
        show={modalShowSuspects}
        onHide={() => handleClosePopup()}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center justify-content-between w-100"
          >
            <div>Lead Information</div>

            {["radio"].map((type: any) => (
              <div
                key={`inline-${type}`}
                className="option-field suspect-radio-label  d-flex align-items-center"
              >
                <Form.Check
                  inline
                  label="Company"
                  name="grpType"
                  value="Company"
                  type={type}
                  id="Company"
                  className="d-flex align-items-center m-0"
                  checked={!formInputs.isGroup}
                  // value={SuspectSave.lead_Status_Term} //|| "Open"}
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      isGroup: !e.target.checked,
                    });
                  }}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Group"
                  name="grpType"
                  value="Group"
                  type={type}
                  id="Group"
                  className="d-flex align-items-center m-0"
                  checked={formInputs.isGroup}
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      isGroup: e.target.checked,
                    });
                  }}
                />
              </div>
            ))}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col xs={12} className="mb-2">
                {/* <h6>Suspect Information</h6> */}
              </Col>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Company / Group Name">
                  <Form.Control
                    type="text"
                    placeholder="Company / Group Name"
                    value={formInputs.companyName}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        companyName: e.target.value ? e.target.value : "",
                      });
                    }}
                    isInvalid={formInputsEr.companyName}
                  />
                  {formInputsEr.companyName && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.companyName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                {/* <FloatingLabel controlId="floatingSelectGrid" label="Source">
                <Form.Select
                  className="pt-0 pb-0"
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      lead_Source_Term: e.target.value ? e.target.value : "",
                    });
                  }}
                >
                  <option
                    selected={
                      formInputs.lead_Source_Term === "" || !formInputs.lead_Source_Term
                        ? true
                        : false
                    }
                    value=""
                  >
                    Select Source
                  </option>
                  {sourceDropdownList.map((item: any, index) => (
                    <option
                      selected={formInputs.lead_Source_Term === item.Term ? true : false}
                      key={index}
                      value={item.Term}
                    >
                      {item.Term}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel> */}

                <FloatingLabel controlId="floatingSelectGrid" label="">
                  <Select
                    value={MySourceOption.filter((obj) =>
                      formInputs.lead_Source_Term.includes(obj.value)
                    )}
                    options={MySourceOption}
                    onChange={(e: any) => {
                      setFormInputs({
                        ...formInputs,
                        lead_Source_Term: e.value ? e.value : "",
                      });
                    }}
                    name="colors"
                    placeholder="Select Source"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={false}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="First Name">
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    value={formInputs.firstName}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        firstName: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Last Name">
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    value={formInputs.lastName}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        lastName: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <div className={`${formInputsEr.lstPropertyIDs && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Hotels"
                      className={`multiselect ${formInputsEr.lstPropertyIDs && "is-invalid"}`}
                      options={initialHotelToSet}
                      onChange={(items: any) => {
                        sethotelChangeLoading(true);
                        setSelectedHotelOptions(items);
                        let lstAccProToAppend: any = [];
                        lstAccProToAppend = [...lstAccProToAppend, items.value];
                        setFormInputs({
                          ...formInputs,
                          lstPropertyIDs: lstAccProToAppend,
                        });
                        getAccountOwnerDropdown(items.value.toString());
                      }}
                      value={selectedHotelOptions}
                      defaultValue={selectedHotelOptions}
                    />
                  </FloatingLabel>
                </div>
                <small style={{ color: "#dc3545" }}>
                  {formInputsEr.lstPropertyIDs && formInputsEr.lstPropertyIDs}{" "}
                </small>
              </Col>
              <Col xs={6} className="mb-3">
                {/* <FloatingLabel controlId="floatingSelectGrid" label="">
                <Form.Select
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      dosid: e.target.value ? e.target.value : "",
                    });
                  }}
                  className={`form-control ${formInputsEr.dosid && "is-invalid"}`}
                >
                  <option
                    value=""
                    selected={formInputs.dosid == "" || !formInputs.dosid ? true : false}
                  >
                    Assigned To
                  </option>

                  {accountOwners.length > 0 &&
                    accountOwners.map((item: any, index) => (
                      <option
                        id={formInputs.dosid}
                        selected={formInputs.dosid == item.UserID ? true : false}
                        key={index}
                        value={item.UserID}
                      >
                        {item.UserName}
                      </option>
                    ))}
                </Form.Select>
              </FloatingLabel> */}

                <FloatingLabel controlId="floatingSelectGrid" label="">
                  <Select
                    placeholder="Select Assigned To"
                    value={optionsAssignTo.filter(
                      (obj) => formInputs.dosid && formInputs.dosid.includes(obj.value)
                    )}
                    // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                    options={optionsAssignTo}
                    onChange={(e: any) => {
                      setFormInputs({
                        ...formInputs,
                        dosid: e.value ? e.value : "",
                      });
                    }}
                    isDisabled={!formInputs.lstPropertyIDs ? true : false}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <label className="for-radio-header" htmlFor="Status">
                  Status
                </label>
                {["radio"].map((type: any) => (
                  <div key={`inline-${type}`} className="option-field">
                    {StatusData.length > 0 &&
                      StatusData.map((item) => (
                        <>
                          {" "}
                          <Form.Check
                            inline
                            label={item.TermCode}
                            name={item.TermCode}
                            type={type}
                            id={item.TermCode}
                            // checked={formInputs.lead_Status_Term === "Not Viable" ? true : false}
                            checked={formInputs.lead_Status_Term === item.TermCode ? true : false}
                            // checked={SuspectSave.lead_Status_Term === item.TermCode ? true : false}
                            value={formInputs.lead_Status_Term}
                            onChange={() =>
                              setFormInputs({
                                ...formInputs,
                                ["lead_Status_Term"]: item.TermCode,
                              })
                            }
                          />
                        </>
                      ))}

                    {/* <Form.Check
                    inline
                    label="Open"
                    name="grpstatus"
                    type={type}
                    id="Open"
                    checked={formInputs.lead_Status_Term === "Open" ? true : false}
                    value={"Open"}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        lead_Status_Term: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="Not Viable"
                    name="grpstatus"
                    type={type}
                    id="Not Viable"
                    checked={formInputs.lead_Status_Term === "Not Viable" ? true : false}
                    value={"Not Viable"} //|| "Open"}
                    // value={formInputs.lead_Status_Term}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        lead_Status_Term: e.target.value,
                      });
                    }}
                  /> */}
                  </div>
                ))}
              </Col>
              <Col xs={6} className="mb-3">
                <label className="for-radio-header" htmlFor="Priority">
                  Priority
                </label>
                {["radio"].map((type: any) => (
                  <div key={`inline-${type}`} className="option-field">
                    {PriorityData.length > 0 &&
                      PriorityData.map((item) => (
                        <>
                          <Form.Check
                            inline
                            label={item.TermCode}
                            name={item.TermCode}
                            type={type}
                            id={item.TermCode}
                            // checked={SuspectSave.priority === item.TermCode ? true : false}
                            checked={formInputs.priority === item.TermCode ? true : false}
                            value={formInputs.priority}
                            onChange={() =>
                              setFormInputs({
                                ...formInputs,
                                priority: item.TermCode,
                              })
                            }
                          />
                        </>
                      ))}

                    {/* <Form.Check
                    inline
                    label="Low"
                    name="grpPriority"
                    type={type}
                    id="Low"
                    checked={formInputs.priority === "Low" ? true : false}
                    value={"Low"} //|| "Low"}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        priority: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="Normal"
                    name="grpPriority"
                    type={type}
                    id="Normal"
                    checked={formInputs.priority === "Normal" ? true : false}
                    value={"Normal"} //|| "Low"}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        priority: e.target.value,
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="Urgent"
                    name="grpPriority"
                    type={type}
                    id="Urgent"
                    checked={formInputs.priority === "Urgent" ? true : false}
                    value={"Urgent"} //|| "Low"}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        priority: e.target.value,
                      });
                    }}
                  /> */}
                  </div>
                ))}
              </Col>
            </Row>

            <Row>
              {formInputs.isGroup === true ? (
                <>
                  <Row>
                    <Col xs={12} className="mt-3 mb-2">
                      <h6>Group Information</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="mb-3">
                      <div className={`${groupInputsEr.groupType && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            placeholder="Select Group Type"
                            // value={MyGroupOption.filter(
                            //   (obj) =>
                            //     SuspectSave.groupType && SuspectSave.groupType.includes(obj.value)
                            // )}
                            options={MyGroupOption}
                            // onChange={(e) =>
                            //   setSuspectSave({
                            //     ...SuspectSave,
                            //     ["groupType"]: e.value,
                            //   })
                            // }
                            onChange={(e: any) => {
                              setFormInputs({
                                ...formInputs,
                                groupType: e.value ? e.value : "",
                              });
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {groupInputsEr.groupType && groupInputsEr.groupType}{" "}
                      </small>
                    </Col>
                    <Col xs={6} className="mb-3">
                      <Row>
                        <table className="lead_info_date_design">
                          <thead>
                            <tr>
                              <th> Start date </th>
                              <th> Number of Rooms </th>
                              <th>
                                <div className="d-flex justify-content-end">Action</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(groupTab).map((group_key) => (
                              <>
                                <tr>
                                  <td>
                                    {/* <FloatingLabel controlId="floatingInput" label="">
                                    <Form.Control
                                      type="date"
                                      placeholder=""
                                      min={"2010-01-01"}
                                      max={groupTab[group_key].end_date}
                                      value={groupTab[group_key].start_date}
                                      onChange={(e) => {
                                        const startDateValue = e.target.value;
                                        setGroupTab({
                                          ...groupTab,
                                          [group_key]: {
                                            ...groupTab[group_key],
                                            start_date: startDateValue,
                                            end_date: startDateValue,
                                          },
                                        });
                                      }}
                                      isInvalid={groupTabEr[group_key].start_date !== ""}
                                    />
                                    {groupTabEr[group_key].start_date !== "" && (
                                      <Form.Control.Feedback type="invalid">
                                        {groupTabEr[group_key].start_date}
                                      </Form.Control.Feedback>
                                    )}
                                  </FloatingLabel> */}
                                    <div className="DatePicker-col">
                                      <DatePicker
                                        selected={
                                          groupTab[group_key].start_date &&
                                          groupTab[group_key].start_date
                                        }
                                        className={`form-control ${
                                          groupTabEr[group_key].start_date !== "" && "is-invalid"
                                        }`}
                                        onChange={(e) => {
                                          const startDateValue = e ? e : "";
                                          setGroupTab({
                                            ...groupTab,
                                            [group_key]: {
                                              ...groupTab[group_key],
                                              start_date: startDateValue,
                                              end_date: startDateValue,
                                            },
                                          });
                                        }}
                                        dateFormat={Cookies.get("SystemSettingDate")}
                                        placeholderText={Cookies.get("SystemSettingDate")}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                      />
                                      {groupTabEr[group_key].start_date !== "" && (
                                        <small style={{ color: "#dc3545" }}>
                                          {groupTabEr[group_key].start_date}
                                        </small>
                                      )}
                                    </div>
                                  </td>

                                  <td>
                                    <FloatingLabel controlId="floatingInput" label="">
                                      <Form.Control
                                        type="number"
                                        onWheel={(event: any) => {
                                          event.target.blur();
                                        }}
                                        onKeyDown={(e: any) => {
                                          if (e.keyCode == "38" || e.keyCode == "40") {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder="Enter rooms"
                                        value={groupTab[group_key].room}
                                        onChange={(e) => {
                                          const limit: any = 2;
                                          const roomNo = e.target.value.slice(0, limit);
                                          setGroupTab({
                                            ...groupTab,
                                            [group_key]: {
                                              ...groupTab[group_key],
                                              room: roomNo,
                                            },
                                          });
                                        }}
                                        isInvalid={groupTabEr[group_key].room !== ""}
                                      />
                                      {groupTabEr[group_key].room !== "" && (
                                        <Form.Control.Feedback type="invalid">
                                          {groupTabEr[group_key].room}
                                        </Form.Control.Feedback>
                                      )}
                                    </FloatingLabel>
                                  </td>
                                  <td>
                                    <div className="btn-icon-grp-table justify-content-end">
                                      {parseInt(group_key) === groupInuptCount ? (
                                        <Button
                                          className="btn-icon addFormIcon"
                                          onClick={() => {
                                            setGroupTab({
                                              ...groupTab,
                                              [groupInuptCount + 1]: {
                                                start_date: "",

                                                room: "",
                                              },
                                            });
                                            setGroupTabEr({
                                              ...groupTabEr,
                                              [groupInuptCount + 1]: {
                                                start_date: "",

                                                room: "",
                                              },
                                            });
                                            setGroupInuptCount(groupInuptCount + 1);
                                          }}
                                        ></Button>
                                      ) : (
                                        <Button
                                          className="btn-icon cancelIcon"
                                          onClick={() => {
                                            let newGroupJson: any = {};
                                            let newErGroupjson: any = {};
                                            Object.keys(groupTab).forEach((grp_key) => {
                                              if (group_key !== grp_key) {
                                                newGroupJson = {
                                                  ...newGroupJson,
                                                  [grp_key]: groupTab[grp_key],
                                                };
                                              }
                                            });
                                            Object.keys(groupTabEr).forEach((gr_er_key) => {
                                              if (gr_er_key !== group_key) {
                                                newErGroupjson = {
                                                  ...newErGroupjson,
                                                  [gr_er_key]: groupTabEr[gr_er_key],
                                                };
                                              }
                                            });
                                            setGroupTab(newGroupJson);
                                            setGroupTabEr(newErGroupjson);
                                          }}
                                        ></Button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr></tr>
                          </tbody>
                        </table>
                      </Row>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col xs={12}>
                    <Row>
                      <Table>
                        <thead>
                          <tr>
                            <th> Start date </th>
                            <th> Number of Rooms </th>
                            <th>
                              <div className="d-flex justify-content-end">Action</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {Object.keys(groupTab).map((group_key) => (
                            <>
                              <tr>
                                <td>
                                  <FloatingLabel controlId="floatingInput" label="">
                                    <Form.Control
                                      type="date"
                                      placeholder=""
                                      min={"2010-01-01"}
                                      max={groupTab[group_key].end_date}
                                      value={groupTab[group_key].start_date}
                                      onChange={(e) => {
                                        const startDateValue = e.target.value;
                                        setGroupTab({
                                          ...groupTab,
                                          [group_key]: {
                                            ...groupTab[group_key],
                                            start_date: startDateValue,
                                            end_date: startDateValue,
                                          },
                                        });
                                      }}
                                      isInvalid={groupTabEr[group_key].start_date !== ""}
                                    />
                                    {groupTabEr[group_key].start_date !== "" && (
                                      <Form.Control.Feedback type="invalid">
                                        {groupTabEr[group_key].start_date}
                                      </Form.Control.Feedback>
                                    )}
                                  </FloatingLabel>
                                </td>

                                <td>
                                  <FloatingLabel controlId="floatingInput" label="">
                                    <Form.Control
                                      type="number"
                                      onWheel={(event: any) => {
                                        event.target.blur();
                                      }}
                                      onKeyDown={(e: any) => {
                                        if (e.keyCode == "38" || e.keyCode == "40") {
                                          e.stopPropagation();
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder="Enter rooms"
                                      value={groupTab[group_key].room}
                                      onChange={(e) => {
                                        const limit: any = 2;
                                        const roomNo = e.target.value.slice(0, limit);
                                        setGroupTab({
                                          ...groupTab,
                                          [group_key]: {
                                            ...groupTab[group_key],
                                            room: roomNo,
                                          },
                                        });
                                      }}
                                      isInvalid={groupTabEr[group_key].room !== ""}
                                    />
                                    {groupTabEr[group_key].room !== "" && (
                                      <Form.Control.Feedback type="invalid">
                                        {groupTabEr[group_key].room}
                                      </Form.Control.Feedback>
                                    )}
                                  </FloatingLabel>
                                </td>
                                <td>
                                  <div className="btn-icon-grp-table justify-content-end">
                                    {parseInt(group_key) === groupInuptCount ? (
                                      <Button
                                        className="btn-icon addFormIcon"
                                        onClick={() => {
                                          setGroupTab({
                                            ...groupTab,
                                            [groupInuptCount + 1]: {
                                              start_date: "",

                                              room: "",
                                            },
                                          });
                                          setGroupTabEr({
                                            ...groupTabEr,
                                            [groupInuptCount + 1]: {
                                              start_date: "",

                                              room: "",
                                            },
                                          });
                                          setGroupInuptCount(groupInuptCount + 1);
                                        }}
                                      ></Button>
                                    ) : (
                                      <Button
                                        className="btn-icon cancelIcon"
                                        onClick={() => {
                                          let newGroupJson: any = {};
                                          let newErGroupjson: any = {};
                                          Object.keys(groupTab).forEach((grp_key) => {
                                            if (group_key !== grp_key) {
                                              newGroupJson = {
                                                ...newGroupJson,
                                                [grp_key]: groupTab[grp_key],
                                              };
                                            }
                                          });
                                          Object.keys(groupTabEr).forEach((gr_er_key) => {
                                            if (gr_er_key !== group_key) {
                                              newErGroupjson = {
                                                ...newErGroupjson,
                                                [gr_er_key]: groupTabEr[gr_er_key],
                                              };
                                            }
                                          });
                                          setGroupTab(newGroupJson);
                                          setGroupTabEr(newErGroupjson);
                                        }}
                                      ></Button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                          <tr></tr>
                        </tbody>
                      </Table>
                    </Row>
                  </Col>
                </Row> */}
                </>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Col xs={12} className="mt-3 mb-2">
                <h6>Contact Information</h6>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="mb-3">
                <Row>
                  <Col xs={4} xl={3} xxl={3}>
                    <FloatingLabel controlId="floatingInput" label="Country Code">
                      <Form.Control
                        type="text"
                        maxLength={6}
                        placeholder="Country Code"
                        value={formInputs.countrycode}
                        onChange={(e) => {
                          setFormInputs({
                            ...formInputs,
                            countrycode: e.target.value ? e.target.value : "",
                          });
                        }}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={4} xl={3} xxl={3}>
                    <div className={`${formInputsEr.telephoneNo && "red-border-masking"}`}>
                      <FloatingLabel controlId="floatingInput" label="Office Phone">
                        <PatternFormat
                          className="form-control"
                          displayType="input"
                          // value={SuspectSave.telephoneNo}
                          format="###-###-####"
                          // onChange={(e) =>
                          //   setSuspectSave({
                          //     ...SuspectSave,
                          //     ["telephoneNo"]: e.target.value,
                          //   })
                          // }
                          value={formInputs.telephoneNo}
                          onChange={(e) => {
                            setFormInputs({
                              ...formInputs,
                              telephoneNo:
                                formInputs.countrycode + " " + e.target.value ? e.target.value : "",
                            });
                          }}
                        />
                      </FloatingLabel>
                    </div>
                    <small style={{ color: "#dc3545" }}>
                      {formInputsEr.telephoneNo && formInputsEr.telephoneNo}{" "}
                    </small>
                  </Col>
                  <Col xs={3} xl={3} xxl={2}>
                    <FloatingLabel controlId="floatingInput" label="Ext">
                      <Form.Control
                        value={formInputs.ext}
                        onChange={(e) => {
                          setFormInputs({
                            ...formInputs,
                            ext: e.target.value ? e.target.value : "",
                          });
                        }}
                        type="text"
                        placeholder="Ext"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/*  */}
            <Row>
              <Col xs={12} className="mb-3">
                <Col xs={12} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Email">
                    <Form.Control
                      type="email"
                      autoComplete="new-password"
                      placeholder="email"
                      value={formInputs.email}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          email: e.target.value ? e.target.value : "",
                        });
                      }}
                      isInvalid={formInputsEr.email}
                    />
                    {formInputsEr.email && (
                      <Form.Control.Feedback type="invalid">
                        {formInputsEr.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col xs={12} className="mb-3">
                  <FloatingLabel controlId="floatingPassword" label="Notes">
                    <Form.Control
                      as="textarea"
                      placeholder="comment"
                      style={{ height: "100px" }}
                      value={formInputs.comment}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          comment: e.target.value ? e.target.value : "",
                        });
                      }}
                    />
                  </FloatingLabel>
                </Col>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          {!submitLoading ? (
            <Button
              variant="primary"
              type="button"
              onClick={(e) => {
                handleFormSave(e);
              }}
            >
              <span className="me-2">
                <img src={WhiteSaveIcon} alt="" />
              </span>
              Save
            </Button>
          ) : (
            <Button className="btn btn-primary justify-content-center" type="button">
              <Spinner size="sm" animation="border" variant="light" />
            </Button>
          )}
          <Button
            onClick={() => {
              setFullModalShowSuspects(true);
            }}
            type="button"
            variant="primary"
          >
            <span className="me-2">
              <i className="fas fa-redo" aria-hidden="true"></i>
            </span>
            Go to full form
          </Button>
        </Modal.Footer>
        {fullModalShowSuspects === true && (
          <AddSuspect
            leadID={""}
            show={fullModalShowSuspects}
            allHotelsName={allHotelsName}
            sourceDropdownList={SourceDropdownList}
            isData={isData}
            getSuspectdata={() => {
              setModalShowSuspects(false);
            }}
            Token={Token}
            onHide={() => {
              setFullModalShowSuspects(false);
            }}
          ></AddSuspect>
        )}
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
