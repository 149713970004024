/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  getGlobalHotels,
  selectedLogo,
  selectedPortfolioID,
  selectedPortfolioName,
  selectedPropertyID,
  selectedPropertyName,
} from "../../../Redux/actions";
import { GetHotelsListData } from "../../../Services/Setup&Config/HotelService";
import { useNavigate } from "react-router-dom";

function HotelSearch({ setHeaderHotelSearch }) {
  let navigate: any = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  const userID: any = dataSelector.auth_UserDetail.U_UserID;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [, setclassname] = useState("All");
  let hotelsData: any = useSelector((item: any) => item.header.Hotels);
  const dispatch = useDispatch();
  let AllHotelsName: any = [];
  let AllHotelIDs: any = [];
  let Portfolios: any;

  if (hotelsData.length > 0) {
    Portfolios = [...new Set(hotelsData.map((item) => item.Portfolio))];
  }

  hotelsData.length > 0 &&
    hotelsData.map((item: any) => {
      AllHotelsName.push(item.PropertyName);
      AllHotelIDs.push(item.PropertyID);
    });

  function selectedHotel(item) {
    item.PropertyName;
    item.PropertyID;
    item.HotelCode + " " + item.PropertyName;
    let temp: any;
    setclassname(item);
    if (item === "All") {
      temp = AllHotelIDs.toString();
      getPropertiesByID(AllHotelIDs.toString());
      dispatch(selectedPropertyID(temp));
      setHeaderHotelSearch(false);
      localStorage.setItem("SelectedPropertyID", temp);
      dispatch(selectedPropertyName("All"));
      dispatch(selectedLogo(""));

      // Navigate to main listing

      if (window.location.pathname === "/suspect/details") {
        navigate("/lead");
      } else if (window.location.pathname === "/accounts-view") {
        navigate("/account");
      } else if (window.location.pathname === "/contact/details") {
        navigate("/contact");
      } else if (window.location.pathname === "/activity/details") {
        navigate("/activityList");
      } else if (window.location.pathname === "/Opportunity/RFP/details") {
        navigate("/opportunity/rfp");
      } else if (window.location.pathname === "/Opportunity/LNR/details") {
        navigate("/opportunity/lnr");
      } else if (window.location.pathname === "/Opportunity/Group/details") {
        navigate("/opportunity/group");
      } else if (window.location.pathname === "/Opportunity/MeetingAndCatering/details") {
        navigate("/opportunity/meetingAndCatering");
      }
    } else {
      getPropertiesByID(item.PropertyID);
      dispatch(selectedPropertyID(item.PropertyID));
      setHeaderHotelSearch(false);
      localStorage.setItem("SelectedPropertyID", item.PropertyID);
      dispatch(selectedPropertyName(item.HotelCode + " " + item.PropertyName));

      dispatch(selectedLogo(item.PropertyLogo));

      // Navigate to main listing

      if (window.location.pathname === "/suspect/details") {
        navigate("/lead");
      } else if (window.location.pathname === "/accounts-view") {
        navigate("/account");
      } else if (window.location.pathname === "/contact/details") {
        navigate("/contact");
      } else if (window.location.pathname === "/activity/details") {
        navigate("/activityList");
      } else if (window.location.pathname === "/Opportunity/RFP/details") {
        navigate("/opportunity/rfp");
      } else if (window.location.pathname === "/Opportunity/LNR/details") {
        navigate("/opportunity/lnr");
      } else if (window.location.pathname === "/Opportunity/Group/details") {
        navigate("/opportunity/group");
      } else if (window.location.pathname === "/Opportunity/MeetingAndCatering/details") {
        navigate("/opportunity/meetingAndCatering");
      }
    }
  }

  //#region  Previous Code :

  //#region  hotels Grouping with Client

  if (hotelsData !== null) {
  }
  //#endregion

  //#endregion End previous Code :

  function handleSelectPortfolio(portfolio) {
    let selectedPortfolioHotelIDs: any = [];
    let PortfolioName: any = "";
    let PortfolioID: any = "";
    let SelectedPortfolioLogo: any = "";
    hotelsData.length > 0 &&
      hotelsData.map((item: any) => {
        if (portfolio === item.Portfolio) {
          selectedPortfolioHotelIDs.push(item.PropertyID);
          PortfolioName = item.Portfolio;
          PortfolioID = item.PortfolioID;
          SelectedPortfolioLogo = item.PortfolioLogo;
        }
      });
    getPropertiesByID(selectedPortfolioHotelIDs.toString());
    dispatch(selectedPropertyID(selectedPortfolioHotelIDs.toString()));

    dispatch(selectedPortfolioName(PortfolioName));
    dispatch(selectedPortfolioID(PortfolioID));

    dispatch(selectedLogo(SelectedPortfolioLogo));

    setHeaderHotelSearch(false);

    dispatch(selectedPropertyName(portfolio));

    // Navigate to main listing

    if (window.location.pathname === "/suspect/details") {
      navigate("/lead");
    } else if (window.location.pathname === "/accounts-view") {
      navigate("/account");
    } else if (window.location.pathname === "/contact/details") {
      navigate("/contact");
    } else if (window.location.pathname === "/activity/details") {
      navigate("/activityList");
    } else if (window.location.pathname === "/Opportunity/RFP/details") {
      navigate("/opportunity/rfp");
    } else if (window.location.pathname === "/Opportunity/LNR/details") {
      navigate("/opportunity/lnr");
    } else if (window.location.pathname === "/Opportunity/Group/details") {
      navigate("/opportunity/group");
    } else if (window.location.pathname === "/Opportunity/MeetingAndCatering/details") {
      navigate("/opportunity/meetingAndCatering");
    }
  }

  async function getPropertiesByID(IDs) {
    let tempHotels: any = [];
    hotelsData.length > 0 &&
      hotelsData.map((item: any) => {
        if (IDs.includes(item.PropertyID)) {
          tempHotels.push(item);
        }
      });

    // Set selected hotels Globally.
    dispatch(getGlobalHotels(tempHotels));
  }

  const [newPortfolio, setnewPortfolio]: any = useState([]);
  const [newHotels, setnewHotels]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  const handleOnSearch = async (e) => {
    let chars = e.target.value;
    let NEwValue: any = [];

    if (chars) {
      setLoading(true);
      let input: any = {
        clientID: clientID,
        propertyName: chars,
        propertyCode: chars,
        portfolio: chars,

        propertyStatus_Term: "Active",
        hotelCode: chars,

        // userID:  userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3" ? null : userID && userID,
        userID: userID,
      };

      let res: any = await GetHotelsListData(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data) {
            let newPortfolios = [...new Set(res.data.data.dataList1.map((item) => item.Portfolio))];
            let newHotelsData = res.data.data.dataList1;
            setnewPortfolio(newPortfolios);
            setnewHotels(newHotelsData);
          } else {
            setnewPortfolio([]);
            setnewHotels([]);
            setLoading(true);
          }
        } else {
          setnewPortfolio([]);
          setnewHotels([]);

          setLoading(true);
        }
      } else {
        setLoading(true);
      }
    } else {
      setLoading(false);
    }
  };

  function handleOnSelect(event) {
    dispatch(selectedPropertyID(event.id));
    dispatch(selectedPropertyName(event.name));
    setHeaderHotelSearch(false);
  }

  return (
    <>
      <div className="header-search-div">
        <Row>
          <Col lg={3} className="w-100 hotel-search">
            <div className="addfild p-2">
              <input
                type={"search"}
                className="form-control"
                placeholder="Search Hotels"
                onChange={(e) => handleOnSearch(e)}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div className="header-hotel-search">
        {!loading ? (
          <div className="mega-menu">
            <ul>
              <li className="bottom-line">
                <h6
                  onClick={() => {
                    selectedHotel("All");
                  }}
                >
                  All Hotels
                </h6>
              </li>
              {Portfolios !== undefined &&
                Portfolios !== null &&
                Portfolios.length > 0 &&
                Portfolios.sort().map((itemPortfolio: any) => (
                  <>
                    <li className="bottom-line">
                      <h6 onClick={() => handleSelectPortfolio(itemPortfolio)}>
                        {itemPortfolio}
                        <span>
                          <i className="fa-regular fa-chevron-right"></i>
                        </span>
                      </h6>
                      <ul className="nav-submenu">
                        {hotelsData.length > 0 &&
                          hotelsData.sort().map(
                            (item: any) =>
                              itemPortfolio === item.Portfolio && (
                                <>
                                  <li className="bottom-line">
                                    <span onClick={() => selectedHotel(item)}>
                                      {item.HotelCode + " " + item.PropertyName}
                                    </span>
                                  </li>
                                </>
                              )
                          )}
                      </ul>
                    </li>
                  </>
                ))}
            </ul>
          </div>
        ) : (
          <div className="mega-menu">
            <ul>
              {newPortfolio !== undefined && newPortfolio !== null
                ? newPortfolio.length > 0 &&
                  newPortfolio.sort().map((itemPortfolio: any) => (
                    <>
                      <li className="bottom-line">
                        <h6 onClick={() => handleSelectPortfolio(itemPortfolio)}>
                          {itemPortfolio}
                          <span>
                            <i className="fa-regular fa-chevron-right"></i>
                          </span>
                        </h6>
                        <ul className="nav-submenu">
                          {newHotels.length > 0 &&
                            newHotels.sort().map((item: any) => (
                              <>
                                {itemPortfolio === item.Portfolio && (
                                  <li className="bottom-line">
                                    <span onClick={() => selectedHotel(item)}>
                                      {item.HotelCode + " " + item.PropertyName}
                                    </span>
                                  </li>
                                )}
                              </>
                            ))}
                        </ul>
                      </li>
                    </>
                  ))
                : ""}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default HotelSearch;
