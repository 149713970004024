import { ENUMS } from "../Constants";

export const updateUserData = (data: any, data1: any , user_rights , side_menu) => ({
  type: ENUMS.actions.UPDATE_USER,

  payload: {
    is_logged: true,
    auth_UserDetail: data1,
    access_Token: data.dataList2[0].accessToken,
    logo: data.dataList2[0].logo,
    sidemenu_rights:side_menu,
    user_rights :user_rights,
  },
});
export const isLogochangeData = (data) => ({
  type: ENUMS.actions.ISLOGOCHANGEDATA,
  payload: data,
});
export const hotelsUpdateData = (data) => ({
  type: ENUMS.actions.UPDATE_HOTEL_DETAILS,
  payload: data,
});
export const dashboardUpdateData = (data) => ({
  type: ENUMS.actions.DASHBOARD_DETAILS,
  payload: data,
});
export const yearSelectData = (data) => ({
  type: ENUMS.actions.YEAR_DETAILS,
  payload: data,
});
export const selectedLogo = (data) => ({
  type: ENUMS.actions.SELECTED_LOGO,
  payload: data,
});
export const selectedPropertyID = (data) => ({
  type: ENUMS.actions.SELECTED_PROPERTY,
  payload: data,
});
export const selectedPropertyName = (data) => ({
  type: ENUMS.actions.SELECTED_PROPERTY_NAME,
  payload: data,
});

export const selectedPortfolioName = (data) => ({
  type: ENUMS.actions.SELECTED_PORTFOLIO,
  payload: data,
});
export const selectedPortfolioID = (data) => ({
  type: ENUMS.actions.SELECTED_PORTFOLIO_ID,
  payload: data,
});

export const getSelectedAccount = (data) => ({
  type: ENUMS.actions.SELECTED_ACCOUNT,
  payload: data,
});
export const getSelectedContact = (data) => ({
  type: ENUMS.actions.SELECTED_CONTACT,
  payload: data,
});

export const updateAuthUserDetails = (data: any) => ({
  type: ENUMS.actions.UPDATE_AUTH_USER_DTLS,

  payload: data,
});

export const getGlobalHotels = (data) => ({
  type: ENUMS.actions.GLOBAL_HOTELS,
  payload: data,
});
export const updateLeadDataFetch = (data) => ({
  type: ENUMS.actions.UPDATE_LEAD_FETCH_COUNT,
  payload: data,
});
export const updateAccountDataFetch = (data) => ({
  type: ENUMS.actions.UPDATE_ACCOUNT_FETCH_COUNT,
  payload: data,
});
export const updateContactDataFetch = (data) => ({
  type: ENUMS.actions.UPDATE_CONTACT_FETCH_COUNT,
  payload: data,
});
export const updateActivityDataFetch = (data) => ({
  type: ENUMS.actions.UPDATE_ACTIVITY_FETCH_COUNT,
  payload: data,
});