import React from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import TableView from "../../Component/Table/Index";

function MyVerticallyCenteredModalContact(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      className="quickAdd-popup"
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Client</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Client Code">
                <Form.Control type="text" placeholder="Client Code" />
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingSelectGrid" label="Subscription Type">
                <Form.Select>
                  <option value="1">Select Subscription Type</option>
                  <option value="2">Annual</option>
                  <option value="3">Monthly</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="First Name">
                <Form.Control type="text" placeholder="First Name" />
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Last Name">
                <Form.Control type="text" placeholder="Last Name" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Company Name">
                <Form.Control type="text" placeholder="First Name" />
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Client Phone">
                <Form.Control type="text" placeholder="Reports to" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Email">
                <Form.Control type="email" placeholder="Email" />
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Date of Expiry Date">
                <Form.Control type="text" placeholder="Department" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Registration Date">
                <Form.Control type="text" placeholder="-" />
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Contracted Users">
                <Form.Control type="text" placeholder="Contracted Users" />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Row>
                <Col xs={6}>
                  <FloatingLabel controlId="floatingInput" label="Mobile Phone">
                    <Form.Control type="text" placeholder="Mobile Phone" />
                  </FloatingLabel>
                </Col>
                <Col xs={6}>
                  <FloatingLabel controlId="floatingInput" label="Mobile Phone">
                    <Form.Control type="text" placeholder="Mobile Phone" />
                  </FloatingLabel>
                </Col>
              </Row>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Notes">
                <Form.Control as="textarea" placeholder="Notes" />
              </FloatingLabel>
            </Col>
          </Row>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button variant="primary" onClick={props.onHide}>
          <span className="me-2">
            <img src={WhiteSaveIcon} alt="" />
          </span>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function SetupAndConfigurations() {
  const [modalShowContact, setModalShowContact] = React.useState(false);

  const setupAndConfigurationTableColumns = React.useMemo(
    () => [
      {
        Header: "Client Code",
        accessor: "clientCode",
      },
      {
        Header: "Client Name",
        accessor: "clientName",
      },
      {
        Header: "Reg. Date",
        accessor: "regDate",
      },
      {
        Header: "Exp. Date",
        accessor: "expDate",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact Number",
        accessor: "contactNo",
      },
      {
        Header: "Portfolio(s)",
        accessor: "portfolios",
      },
      {
        Header: "Properties",
        accessor: "properties",
      },
      {
        Header: "User(s)",
        accessor: "users",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ],
    []
  );

  const setupAndConfigurationTableData = [
    {
      clientCode: "SH71738",
      clientName: <GreenLabel successLabel="Alpesh Shukla" />,
      regDate: "11-27-2018",
      expDate: "11-27-2018",
      email: "info@essicompanies.com",
      contactNo: "877-794-4710",
      portfolios: "0",
      properties: "1/1",
      users: "1/1",
      action: (
        <div className="btn-icon-grp-table">
          <Button className="btn-icon timeIcon"></Button>
          <Button className="btn-icon editIcon" onClick={() => setModalShowContact(true)}></Button>
          <Button className="btn-icon cancelIcon"></Button>
        </div>
      ),
    },
    {
      clientCode: "SH71738",
      clientName: <GreenLabel successLabel="Alpesh Shukla" />,
      regDate: "11-27-2018",
      expDate: "11-27-2018",
      email: "info@essicompanies.com",
      contactNo: "877-794-4710",
      portfolios: "0",
      properties: "1/1",
      users: "1/1",
      action: (
        <div className="btn-icon-grp-table">
          <Button className="btn-icon timeIcon"></Button>
          <Button className="btn-icon editIcon" onClick={() => setModalShowContact(true)}></Button>
          <Button className="btn-icon cancelIcon"></Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <Container fluid={true} className="p-0 position-relative">
        <div className="btn-icon-grp position-static justify-content-end">
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Client</Tooltip>}>
            <Button
              className="btn-icon addFormIcon"
              onClick={() => setModalShowContact(true)}
            ></Button>
          </OverlayTrigger>
        </div>
        <Row>
          <Col xs={12}>
            <TableView
              columnsData={setupAndConfigurationTableColumns}
              tableData={setupAndConfigurationTableData}
            />
          </Col>
        </Row>
      </Container>
      <MyVerticallyCenteredModalContact
        show={modalShowContact}
        onHide={() => setModalShowContact(false)}
      />
    </>
  );
}

export default SetupAndConfigurations;
