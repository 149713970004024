import React, { useEffect, useState } from "react";
import ReportAccounts from "../../Asset/Images/Other/report-accounts.svg";
import HardRet from "../../Asset/Images/Other/hard-ret.svg";
import ReportCalendar from "../../Asset/Images/Other/report-calendar.svg";
import ReportEmail from "../../Asset/Images/Other/report-email.svg";
import ReportNormal from "../../Asset/Images/Other/report-normal.svg";
import ReportNots from "../../Asset/Images/Other/report-nots.svg";
import ReportUserCall from "../../Asset/Images/Other/report-user-call.svg";
import ReportUser from "../../Asset/Images/Other/report-user.svg";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";

import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import WeeklyReportUserData from "./WeeklyReportUserData";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
import DashboardWeeklyReportUserData from "./DashboardWeeklyReportUserData";
import {
  DashboardWeeklyReportSingleFileExportPDF,
  getDashboardWeeklyReportList,
} from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";
import { CommonService, ToasterError } from "../../Services/CommonServices";
import { useNavigate } from "react-router";
import moment from "moment";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import { AppConfigData } from "../../Services/CommonServices";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardWeeklyReportModal = (props) => {
  let page_rights = CommonService.getPageRight("Account");
  let navigate = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token: any = dataSelector.access_Token;
  const [loading, setloading] = useState(false);
  const [Downloading, setDownloading] = useState(false);
  const [DashboardReportData, setDashboardReportData]: any = useState([]);

  useEffect(() => {
    let getResInput: any = {
      propertyID: "",
      userIDs: "",
    };

    getResInput = {
      ...getResInput,
      propertyID: props.inputs.propertyIDs,
      userIDs: props.inputs.userIDs,
    };

    fetchDashboardData(getResInput);
  }, []);

  async function fetchDashboardData(inputData) {
    setloading(true);
    let res: any = await getDashboardWeeklyReportList(inputData, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          if (
            res.data.data.lstDashBoardWeeklyReportData !== undefined &&
            res.data.data.lstDashBoardWeeklyReportData !== null &&
            res.data.data.lstDashBoardWeeklyReportData !== "" &&
            res.data.data.lstDashBoardWeeklyReportData.length
          ) {
            setDashboardReportData((t) => [...t, ...res.data.data.lstDashBoardWeeklyReportData]);
          }
        }
      }
    }
    setloading(false);
  }

  async function exportPDF() {
    let inputData: any = {
      propertyID: props.inputs.propertyIDs,
      userIDs: props.inputs.userIDs,
      trmp: props.inputs.userIDs,
    };

    setDownloading(true);
    let res: any = await DashboardWeeklyReportSingleFileExportPDF(inputData, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloading(false);
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    } else {
      ToasterError(res.data.message);
      setDownloading(false);
    }
  }

  const labels = ["Blitz", "Client Visit", "Networking", "SiteVisit", "Telemarketing"];

  const DonutOptions = {
    responsive: true,
    plugins: {
      precision: 0,
      legend: {
        position: "top",
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
        text: "Activities",
      },
    },
  };

  let donutData: any = [];
  let donutLabels: any = [];

  let page_rights_Account = CommonService.getPageRight("Account");
  let page_rights_LNR = CommonService.getPageRight("LNR");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_Group = CommonService.getPageRight("Group");
  let page_rights_MeetingAndCatering = CommonService.getPageRight("MeetingAndCatering");
  let page_rights_Contacts = CommonService.getPageRight("Contacts");
  let page_rights_Lead = CommonService.getPageRight("Lead");

  function handleNavigate(itm) {
    if (itm.AssociationType_Term.toLowerCase() == "rfp oppo") {
      if (page_rights_RFP.is_view) {
        navigate("/Opportunity/RFP/details", { state: { OppoID: itm.AssociationID } });
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lnr oppo") {
      if (page_rights_LNR.is_view) {
        navigate("/Opportunity/LNR/details", { state: { LNROppoID: itm.AssociationID } });
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "group oppo") {
      if (page_rights_Group.is_view) {
        navigate("/Opportunity/Group/details", { state: { GroupOppoID: itm.AssociationID } });
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "mac oppo") {
      if (page_rights_MeetingAndCatering.is_view) {
        navigate("/Opportunity/MeetingAndCatering/details", {
          state: { MACOppoID: itm.AssociationID },
        });
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "lead") {
      if (page_rights_Lead.is_view) {
        navigate("/suspect/details", {
          state: { leadID: itm.AssociationID, leadName: itm.AccountName },
        });
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "contact") {
      if (page_rights_Contacts.is_view) {
        navigate("/contact/details", {
          state: { ContactID: itm.AssociationID, ContactName: itm.AccountName },
        });
      } else {
        CommonService.unauthorizedAction();
      }
    }

    if (itm.AssociationType_Term.toLowerCase() == "account") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
      } else {
        CommonService.unauthorizedAction();
      }
    }
    if (itm.AssociationType_Term.toLowerCase() == "accounts") {
      if (page_rights_Account.is_view) {
        navigate("/accounts-view", {
          state: { AccountID: itm.AssociationID, AccountName: itm.AccountName },
        });
      } else {
        CommonService.unauthorizedAction();
      }
    }
  }

  function handleHideShow(item) {
    let datasets = [
      {
        label: "Total Goals",
        data: [
          item.lstActivityGoalData[0].BlitzTotalGoal,
          item.lstActivityGoalData[0].ClientVisitTotalGoal,
          item.lstActivityGoalData[0].NetworkingTotalGoal,
          item.lstActivityGoalData[0].SiteVisitTotalGoal,
          item.lstActivityGoalData[0].TelemarketingTotalGoal,
        ], //datatotalhours,
        backgroundColor: "#cc0000",
      },
      {
        label: "Completed Goals",
        data: [
          item.lstActivityGoalData[0].BlitzCompleteGoal,
          item.lstActivityGoalData[0].ClientVisitCompleteGoal,
          item.lstActivityGoalData[0].NetworkingCompleteGoal,
          item.lstActivityGoalData[0].SiteVisitCompleteGoal,
          item.lstActivityGoalData[0].TelemarketingCompleteGoal,
        ], // datacompletedhours,
        backgroundColor: "green",
      },
      {
        label: "Remaining",
        data: [
          item.lstActivityGoalData[0].BlitzRemainingGoal,
          item.lstActivityGoalData[0].ClientVisitRemainingGoal,
          item.lstActivityGoalData[0].NetworkingRemainingGoal,
          item.lstActivityGoalData[0].SiteVisitRemainingGoal,
          item.lstActivityGoalData[0].TelemarketingRemainingGoal,
        ], // dataremaininghours,
        backgroundColor: "#94b03f",
      },
      {
        label: "Extra Goals",
        data: [
          item.lstActivityGoalData[0].BlitzExtraGoal,
          item.lstActivityGoalData[0].ClientVisitExtraGoal,
          item.lstActivityGoalData[0].NetworkingExtraGoal,
          item.lstActivityGoalData[0].SiteVisitExtraGoal,
          item.lstActivityGoalData[0].TelemarketingExtraGoal,
        ], //datatotalhours,
        backgroundColor: "blue",
      },
    ];
    return datasets;
  }

  function handleHideShowChart(item) {
    let tmpTotal = 0;
    tmpTotal =
      item.lstActivityGoalData[0].BlitzTotalGoal +
      item.lstActivityGoalData[0].ClientVisitTotalGoal +
      item.lstActivityGoalData[0].NetworkingTotalGoal +
      item.lstActivityGoalData[0].SiteVisitTotalGoal +
      item.lstActivityGoalData[0].TelemarketingTotalGoal +
      item.lstActivityGoalData[0].BlitzCompleteGoal +
      item.lstActivityGoalData[0].ClientVisitCompleteGoal +
      item.lstActivityGoalData[0].NetworkingCompleteGoal +
      item.lstActivityGoalData[0].SiteVisitCompleteGoal +
      item.lstActivityGoalData[0].TelemarketingCompleteGoal +
      item.lstActivityGoalData[0].BlitzRemainingGoal +
      item.lstActivityGoalData[0].ClientVisitRemainingGoal +
      item.lstActivityGoalData[0].NetworkingRemainingGoal +
      item.lstActivityGoalData[0].SiteVisitRemainingGoal +
      item.lstActivityGoalData[0].TelemarketingRemainingGoal +
      item.lstActivityGoalData[0].BlitzExtraGoal +
      item.lstActivityGoalData[0].ClientVisitExtraGoal +
      item.lstActivityGoalData[0].NetworkingExtraGoal +
      item.lstActivityGoalData[0].SiteVisitExtraGoal +
      item.lstActivityGoalData[0].TelemarketingExtraGoal;

    if (tmpTotal > 0) {
      return true;
    } else {
      return false;
    }
  }

  const BarOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: true,
      },
      title: {
        display: true,
        font: {
          size: 20,
        },
      },
    },
  };

  let BarChartDataLabels: any = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getBarGroupData = (data: any) => {
    const dataGroupPrev: any = [],
      dataGroupPresent: any = [];

    let presentYear: any = data.year;
    let previousYear: any = data.prevyear;

    data.dataList1 !== undefined &&
      data.dataList1 !== null &&
      data.dataList1.length &&
      data.dataList1.map((itm: any, idx) => {
        if (itm.Year === previousYear) {
          dataGroupPrev.push(itm.Group);
        } else {
          dataGroupPresent.push(itm.Group);
        }
      });

    let tempGroupDataSets: any = [
      {
        label: previousYear,
        data: dataGroupPrev,
        backgroundColor: "#4dc9f6",
      },
      {
        label: presentYear,
        data: dataGroupPresent,
        backgroundColor: "#537bc4",
      },
    ];

    return tempGroupDataSets;
  };
  const getBarMeetingData = (data: any) => {
    const dataMeetingPrev: any = [],
      dataMeetingPresent: any = [];

    let presentYear: any = data.year;
    let previousYear: any = data.prevyear;

    data.dataList1 !== undefined &&
      data.dataList1 !== null &&
      data.dataList1.length &&
      data.dataList1.map((itm: any, idx) => {
        if (itm.Year === previousYear) {
          dataMeetingPrev.push(itm.MeetingAndCatering);
        } else {
          dataMeetingPresent.push(itm.MeetingAndCatering);
        }
      });

    let tempMeetingDataSets: any = [
      {
        label: previousYear,
        data: dataMeetingPrev,
        backgroundColor: "#4dc9f6",
      },
      {
        label: presentYear,
        data: dataMeetingPresent,
        backgroundColor: "#537bc4",
      },
    ];

    return tempMeetingDataSets;
  };

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>Dashboard Weekly Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading ? (
          DashboardReportData.length ? (
            DashboardReportData.map((item: any, index) => (
              <>
                <div style={{ padding: "10px", margin: "10px", border: "1px solid #2f4cad" }}>
                  {item.userData.PropertyLogo !== undefined && item.userData.PropertyLogo ? (
                    <div className="text-center mt-3">
                      <img
                        src={item.userData.PropertyLogo}
                        style={{ width: "200px", maxHeight: "150px" }}
                        alt=""
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div key={index} className="report-col mt-3">
                    <div className="report-title">
                      <span>
                        {item.userData && item.userData.PropertyName + " "}(
                        {item.userData && item.userData.DisplayName})
                      </span>
                    </div>
                  </div>
                  <Row>
                    <Col md={4}>
                      <div
                        className="mt-4"
                        style={{
                          backgroundColor: "#fff",
                          padding: "20px!important",
                          margin: "auto",
                        }}
                      >
                        {
                          ((donutLabels = []),
                          (donutData = []),
                          item.lstActivityCompletedDataCount !== undefined &&
                            item.lstActivityCompletedDataCount &&
                            item.lstActivityCompletedDataCount.length > 0 &&
                            item.lstActivityCompletedDataCount.map((itm, index) => {
                              donutLabels.push(itm.ActivityType_Term),
                                donutData.push(itm.CompletedCount);
                            }))
                        }
                        {item.lstActivityCompletedDataCount !== undefined &&
                          item.lstActivityCompletedDataCount &&
                          item.lstActivityCompletedDataCount.length > 0 && (
                            <Doughnut
                              options={DonutOptions}
                              data={{
                                labels: donutLabels,
                                datasets: [
                                  {
                                    label: donutLabels,
                                    data: donutData,
                                    backgroundColor: [
                                      "#4dc9f6",
                                      "#f67019",
                                      "#f53794",
                                      "#537bc4",
                                      "#acc236",
                                      "#166a8f",
                                      "#00a950",
                                      "#58595b",
                                      "#8549ba",
                                    ],
                                    borderColor: [
                                      "#4dc9f6",
                                      "#f67019",
                                      "#f53794",
                                      "#537bc4",
                                      "#acc236",
                                      "#166a8f",
                                      "#00a950",
                                      "#58595b",
                                      "#8549ba",
                                    ],
                                    borderWidth: 1,
                                  },
                                ],
                              }}
                            />
                          )}
                      </div>
                    </Col>
                    <Col md={8}>
                      <div
                        className="mt-4"
                        style={{
                          backgroundColor: "#fff",
                          padding: "20px!important",
                          margin: "auto",
                        }}
                      >
                        {handleHideShowChart(item) === true &&
                          item.lstActivityGoalData &&
                          item.lstActivityGoalData.length > 0 && (
                            <Bar
                              options={{
                                plugins: {
                                  title: {
                                    position: "top",
                                    display: true,
                                    text:
                                      "Productivity Goals (" +
                                      item.dateData.WeekStartDate +
                                      "-" +
                                      item.dateData.WeekEndDate +
                                      ")",
                                    font: {
                                      size: 18,
                                    },
                                  },
                                  legend: {
                                    position: "bottom",
                                    align: "center",
                                  },
                                },
                                responsive: true,
                              }}
                              data={{
                                labels,
                                datasets: handleHideShow(item),
                              }}
                            />
                          )}
                      </div>
                    </Col>
                  </Row>

                  {/* New Chart */}
                  <Row>
                    <Col md={6}>
                      <div className="white-box graph-box-middle sales-chart h-100">
                        <div className="heading-area d-flex align-items-center justify-content-between">
                          <h5>Group Anticipated Value By Month </h5>
                        </div>
                        <Bar
                          options={BarOptions}
                          data={{
                            labels: BarChartDataLabels,
                            datasets: getBarGroupData(item.anticipatedData),
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="white-box graph-box-middle sales-chart h-100">
                        <div className="heading-area d-flex align-items-center justify-content-between">
                          <h5>Meeting Catering Anticipated Value By Month </h5>
                        </div>
                        <Bar
                          options={BarOptions}
                          data={{
                            labels: BarChartDataLabels,
                            datasets: getBarMeetingData(item.anticipatedData),
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {item.salesfunnelData !== undefined &&
                        item.salesfunnelData &&
                        item.salesfunnelData.length !== 0 && (
                          <div className="white-box graph-box-middle sales-chart h-100">
                            <div className="heading-area d-flex align-items-center justify-content-between">
                              <h5>Opportunity Sales Funnel</h5>
                            </div>
                            <br />
                            <div className="w-100 text-center sales-funnel-chart">
                              <div className="funnel-chart">
                                {item.salesfunnelData.length > 0 ? (
                                  item.salesfunnelData.map((item: any, index: any) => (
                                    <>
                                      <div
                                        // style={{ cursor: "pointer" }}
                                        // onClick={() => handleSalesFunnel(item)}
                                        className={`funnel-chart-list ${item.StatusTerm}`}
                                      >
                                        {item.StatusTerm}
                                        <span className="funnel-chart-price">
                                          {item.AnticipatedRevenue}
                                        </span>
                                      </div>
                                    </>
                                  ))
                                ) : (
                                  <PageLoader />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                    </Col>
                  </Row>
                  {/* New Chart End */}

                  <Row>
                    {item.lstActivityCompletedDataCount !== undefined &&
                    item.lstActivityCompletedDataCount &&
                    item.lstActivityCompletedDataCount.length > 0 ? (
                      <>
                        <h4 className="mt-4" style={{ fontWeight: "600" }}>
                          Previous Week Activities{" "}
                          {"(" +
                            item.dateData.WeekStartDate +
                            "-" +
                            item.dateData.WeekEndDate +
                            ")"}{" "}
                        </h4>

                        <div className="d-flex">
                          <h6>Total Completed Activities &nbsp; {":"} &nbsp; </h6>
                          {item.lstActivityCompletedDataCount &&
                            item.lstActivityCompletedDataCount.map((myCount) => (
                              <h6 className="mb-2">
                                {" "}
                                {myCount.ActivityType_Term} ({myCount.CompletedCount}) ,
                              </h6>
                            ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <Col xs={12} xl={12} className="mb-3 mb-xl-0 mt-3">
                          <div className="information-division">
                            <h3 className="inner-header m-0">
                              Previous Week Activities{" "}
                              {"(" +
                                item.dateData.WeekStartDate +
                                "-" +
                                item.dateData.WeekEndDate +
                                ")"}{" "}
                            </h3>
                            <div className="tab-inner-box position-relative">
                              <div className="customtablemetting">
                                <div className="report-col">
                                  <div className="col-box-bar mb-3">No records found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    )}

                    {item.lstActivityCompletedDataCount &&
                      item.lstActivityCompletedDataCount.map((myCount) => (
                        <>
                          <Col md={12}>
                            <div className="information-division">
                              <h3 className="inner-header m-0">
                                {myCount.ActivityType_Term} : {myCount.CompletedCount}
                              </h3>
                            </div>
                          </Col>

                          {item.lstActivityCompletedData &&
                            item.lstActivityCompletedData.map((myActivityData) =>
                              myActivityData.ActivityType_Term === myCount.ActivityType_Term ? (
                                <>
                                  <Col lg={12} xl={12} className="mb-3">
                                    <Card className="border-0">
                                      <Card.Body>
                                        <Row className="marginbottom-10px">
                                          <Col xs={9}>
                                            <div className="d-flex flex-direction-row align-items-start">
                                              <div className="reports-icons me-2">
                                                <img src={HardRet} alt="" />
                                              </div>
                                              <div className="report-card-title">
                                                {myActivityData.ActivitySubject}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col
                                            xs={3}
                                            className="d-flex justify-content-end align-items-start"
                                          >
                                            <label className="success-label">
                                              {myActivityData.ActivityStatus_Term}
                                            </label>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={12} className="marginbottom-10px">
                                            <div className="d-flex flex-direction-row align-items-center">
                                              <div className="reports-icons me-2">
                                                <img src={ReportUser} alt="" />
                                              </div>
                                              <div
                                                className="report-content-one report-user-content"
                                                role="button"
                                                tabIndex={-1}
                                                style={{ cursor: "pointer", color: "blue" }}
                                                onClick={() => {
                                                  handleNavigate(myActivityData);
                                                }}
                                                onKeyDown={() => {
                                                  handleNavigate(myActivityData);
                                                }}
                                              >
                                                {myActivityData.AccountName
                                                  ? myActivityData.AccountName.replace("#", " ")
                                                  : ""}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={12} className="marginbottom-10px">
                                            <div className="d-flex flex-direction-row align-items-center">
                                              <div className="reports-icons me-2">
                                                <img src={ReportUserCall} alt="" />
                                              </div>
                                              <div className="report-content-one">
                                                {myActivityData.ContactName}
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={6} className="marginbottom-10px">
                                            <div className="d-flex flex-direction-row align-items-center">
                                              <div className="reports-icons me-2">
                                                <img src={ReportNormal} alt="" />
                                              </div>
                                              <div className="report-content-one">
                                                {myActivityData.Priority_Term}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={6} className="marginbottom-10px">
                                            <div className="d-flex flex-direction-row align-items-center">
                                              <div className="reports-icons me-2">
                                                <img src={ReportEmail} alt="" />
                                              </div>
                                              <div className="report-content-one">
                                                {myActivityData.ActivityType_Term}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={6} className="marginbottom-10px">
                                            <div className="d-flex flex-direction-row align-items-center">
                                              <div className="reports-icons me-2">
                                                <img src={ReportAccounts} alt="" />
                                              </div>
                                              <div className="report-content-one">
                                                {myActivityData.AssociationType_Term}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={6} className="marginbottom-10px">
                                            <div className="d-flex flex-direction-row align-items-center">
                                              <div className="reports-icons me-2">
                                                <img src={ReportCalendar} alt="" />
                                              </div>
                                              <div className="report-content-one">
                                                {myActivityData.StartDate !== undefined &&
                                                myActivityData.StartDate !== null
                                                  ? myActivityData.StartDate.includes("T")
                                                    ? moment(
                                                        myActivityData.StartDate.split("T")[0]
                                                      ).format("MM/DD/YYYY")
                                                    : !myActivityData.StartDate.includes("T")
                                                    ? moment(myActivityData.StartDate).format(
                                                        "MM/DD/YYYY"
                                                      )
                                                    : "-"
                                                  : "-"}{" "}
                                                -{" "}
                                                {myActivityData.EndDate !== undefined &&
                                                myActivityData.EndDate !== null
                                                  ? myActivityData.EndDate.includes("T")
                                                    ? moment(
                                                        myActivityData.EndDate.split("T")[0]
                                                      ).format("MM/DD/YYYY")
                                                    : !myActivityData.EndDate.includes("T")
                                                    ? moment(myActivityData.EndDate).format(
                                                        "MM/DD/YYYY"
                                                      )
                                                    : "-"
                                                  : "-"}
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={12}>
                                            <div className="d-flex flex-direction-row align-items-start mt-2">
                                              <div className="reports-icons me-2">
                                                <img src={ReportNots} alt="" />
                                              </div>
                                              <div className="report-content-one">
                                                <div
                                                  className="activity_detail_div_html"
                                                  dangerouslySetInnerHTML={{
                                                    __html: myActivityData.ActivityDetails
                                                      ? myActivityData.ActivityDetails
                                                      : "-",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card.Body>
                                    </Card>
                                  </Col>
                                </>
                              ) : (
                                ""
                              )
                            )}
                        </>
                      ))}
                  </Row>
                  <Row>
                    {item.lstUpcomingActivityDataCount !== undefined &&
                    item.lstUpcomingActivityDataCount &&
                    item.lstUpcomingActivityDataCount.length > 0 ? (
                      <>
                        <h4 className="mt-5" style={{ fontWeight: "600" }}>
                          Next Week Activities{" "}
                          {"(" +
                            item.dateData.NextWeekStartDate +
                            "-" +
                            item.dateData.NextWeekEndDate +
                            ")"}{" "}
                        </h4>
                        <div className="d-flex">
                          <h6>Total Open Activities &nbsp; {":"} &nbsp; </h6>
                          {item.lstUpcomingActivityDataCount &&
                            item.lstUpcomingActivityDataCount.map((myCount) => (
                              <h6 className="mb-2">
                                {" "}
                                {myCount.ActivityType_Term} ({myCount.UpcomingCount}) ,
                              </h6>
                            ))}
                        </div>
                      </>
                    ) : (
                      <>
                        <Col xs={12} xl={12} className="mb-3 mb-xl-0 mt-3">
                          <div className="information-division">
                            <h3 className="inner-header m-0">
                              {" "}
                              Next Week Activities{" "}
                              {"(" +
                                item.dateData.NextWeekStartDate +
                                "-" +
                                item.dateData.NextWeekEndDate +
                                ")"}{" "}
                            </h3>
                            <div className="tab-inner-box position-relative">
                              <div className="customtablemetting">
                                <div className="report-col">
                                  <div className="col-box-bar mb-3">No records found</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                  {item.lstUpcomingActivityDataCount &&
                    item.lstUpcomingActivityDataCount.map((myCount) => (
                      <>
                        <Col md={12}>
                          <div className="information-division">
                            <h3 className="inner-header m-0">
                              {" "}
                              {myCount.ActivityType_Term} : {myCount.UpcomingCount}
                            </h3>
                          </div>
                        </Col>
                        {item.lstUpcomingActivityData &&
                          item.lstUpcomingActivityData.map((myActivityData) =>
                            myActivityData.ActivityType_Term === myCount.ActivityType_Term ? (
                              <>
                                <Col lg={12} xl={12} className="mb-3">
                                  <Card className="border-0">
                                    <Card.Body>
                                      <Row className="marginbottom-10px">
                                        <Col xs={9}>
                                          <div className="d-flex flex-direction-row align-items-start">
                                            <div className="reports-icons me-2">
                                              <img src={HardRet} alt="" />
                                            </div>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={3}
                                          className="d-flex justify-content-end align-items-start"
                                        >
                                          <label className="danger-label">
                                            {myActivityData.ActivityStatus_Term}
                                          </label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={12} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportUser} alt="" />
                                            </div>
                                            {/* <div className="report-content-one report-user-content">
                                              {myActivityData.AccountName}
                                            </div> */}
                                            <div
                                              className="report-content-one report-user-content"
                                              role="button"
                                              tabIndex={-1}
                                              style={{ cursor: "pointer", color: "blue" }}
                                              onClick={() => {
                                                handleNavigate(myActivityData);
                                              }}
                                              onKeyDown={() => {
                                                handleNavigate(myActivityData);
                                              }}
                                            >
                                              {myActivityData.AccountName
                                                ? myActivityData.AccountName.replace("#", " ")
                                                : ""}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={12} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportUserCall} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.ContactName}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={6} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportNormal} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.Priority_Term}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={6} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportEmail} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.ActivityType_Term}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={6} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportAccounts} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.AssociationType_Term}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs={6} className="marginbottom-10px">
                                          <div className="d-flex flex-direction-row align-items-center">
                                            <div className="reports-icons me-2">
                                              <img src={ReportCalendar} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              {myActivityData.StartDate !== undefined &&
                                              myActivityData.StartDate !== null
                                                ? myActivityData.StartDate.includes("T")
                                                  ? moment(
                                                      myActivityData.StartDate.split("T")[0]
                                                    ).format("MM/DD/YYYY")
                                                  : !myActivityData.StartDate.includes("T")
                                                  ? moment(myActivityData.StartDate).format(
                                                      "MM/DD/YYYY"
                                                    )
                                                  : "-"
                                                : "-"}
                                              -
                                              {myActivityData.EndDate !== undefined &&
                                              myActivityData.EndDate !== null
                                                ? myActivityData.EndDate.includes("T")
                                                  ? moment(
                                                      myActivityData.EndDate.split("T")[0]
                                                    ).format("MM/DD/YYYY")
                                                  : !myActivityData.EndDate.includes("T")
                                                  ? moment(myActivityData.EndDate).format(
                                                      "MM/DD/YYYY"
                                                    )
                                                  : "-"
                                                : "-"}
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs={12}>
                                          <div className="d-flex flex-direction-row align-items-start">
                                            <div className="reports-icons me-2">
                                              <img src={ReportNots} alt="" />
                                            </div>
                                            <div className="report-content-one">
                                              <div
                                                className="activity_detail_div_html"
                                                dangerouslySetInnerHTML={{
                                                  __html: myActivityData.ActivityDetails
                                                    ? myActivityData.ActivityDetails
                                                    : "-",
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </>
                            ) : (
                              ""
                            )
                          )}
                      </>
                    ))}

                  <Col xs={12} xl={12} className="mb-3 mb-xl-0 mt-3">
                    <div className="information-division">
                      <h3 className="inner-header m-0">Accounts</h3>
                      <div className="tab-inner-box position-relative">
                        <div className="customtablemetting">
                          <div className="report-col">
                            <div className="col-box-bar mb-3">
                              {item.lstAccountData !== undefined &&
                              item.lstAccountData &&
                              item.lstAccountData.length
                                ? item.lstAccountData.map((item_key, index) => (
                                    <label
                                      className="report-label"
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (page_rights.is_view) {
                                          navigate("/accounts-view", {
                                            state: {
                                              AccountID: item_key.AccountID,
                                              AccountName: item_key.AccountName,
                                            },
                                          });
                                        } else {
                                          CommonService.unauthorizedAction();
                                        }
                                      }}
                                    >
                                      {item_key.AccountName
                                        ? item_key.AccountName.replace("#", " ")
                                        : ""}
                                    </label>
                                  ))
                                : "No records found"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* )} */}
                </div>
              </>
            ))
          ) : (
            ""
          )
        ) : (
          <PageLoader />
        )}

        {Downloading && (
          <div className="calender-loader-main">
            <PageLoader />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          disabled={DashboardReportData.length ? false : true}
          onClick={(e) => {
            e.preventDefault();
            exportPDF();
          }}
        >
          <span className="me-2">
            <img src={PdfIcon} alt="" />
          </span>
          Export PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DashboardWeeklyReportModal;
