import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import Table from "../../Component/Table/Index";
import AddHotelsModal from "../../Model/AddHotelsModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
  getDateTimeInDBFormat,
  getSortDataByObjKey,
} from "../../Services/CommonServices";
import {
  CheckPropertiesLimit,
  GetHotelsListData,
  deleteHotelsData,
} from "../../Services/Setup&Config/HotelService";
import DashboardBlueCard from "../../Component/DashboardBlueCard/Index";

function Hotels() {
  const navigate = useNavigate();
  let page_rights_Hotel = CommonService.getPageRight("Hotels");
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const DateValue = new Date();

  const [modalShow, setModalShow] = React.useState(false);
  const [EditPropertiesID, setEditPropertiesID] = React.useState("");
  const [HotelGraphDataCount, setHotelGraphDataCount]: any = React.useState([]);
  let [PropertiesListData, setPropertiesListData]: any = React.useState({
    IsData: true,
    data: [],
  });

  const handleHotelDetailsView = (property_id) => {
    navigate(`/SetupAndConfigurations/hotels/hotelDetails/${property_id}`);
  };

  const hotelTableColumns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
        className: "w-135",
      },
      {
        Header: "Hotel Name",
        accessor: "propertyName",
        className: "w-243",
      },
      {
        Header: "Portfolios",
        accessor: "portfolio",
        className: "w-300",
      },
      {
        Header: "Status",
        accessor: "status",
        className: "w-185",
      },
      {
        Header: "Email",
        accessor: "email",
        className: "w-300",
      },
      {
        Header: "City",
        accessor: "city",
        className: "w-135",
      },
      {
        Header: "Contact No.",
        accessor: "contact",
        className: "w-196",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "actionsort table-action sticky-action",
      },
    ],
    []
  );

  //#region Excel done with data
  function downloadExcel() {
    const DateValue = new Date();
    let localFilters = JSON.parse(localStorage.getItem("hotelFilter"));

    let mainTableData: any = [];

    PropertiesListData.data !== undefined &&
      PropertiesListData.data !== null &&
      PropertiesListData.data.map((item: any) => {
        mainTableData.push({
          code: item.HotelCode,
          propertyName: item.PropertyName,
          PropertyCode: item.PropertyCode,
          portfolio: item.Portfolio,
          PropertyAddress: item.PropertyAddress,
          Country: item.Country,
          State: item.State,
          Region: item.Region,
          Area: item.Area,
          ZipCode: item.ZipCode,
          city: item.City,
          contact: item.PrimaryContactNo,
          email: item.PrimaryEmailID,
          status: item.PropertyStatus_Term,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            PropertyName: item.propertyName,
            PropertyCode: item.PropertyCode,
            Portfolio: item.portfolio,
            PropertyAddress: item.PropertyAddress,
            Country: item.Country,
            State: item.State,
            Region: item.Region,
            Area: item.Area,
            ZipCode: item.ZipCode,
            City: item.city,
            PrimaryContactNo: item.contact,
            PrimaryEmailID: item.email,
            PropertyStatus: item.status,
          });
        });

        let FileName =
          "Hotel" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(ascDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            PropertyName: item.propertyName,
            PropertyCode: item.PropertyCode,
            Portfolio: item.portfolio,
            PropertyAddress: item.PropertyAddress,
            Country: item.Country,
            State: item.State,
            Region: item.Region,
            Area: item.Area,
            ZipCode: item.ZipCode,
            City: item.city,
            PrimaryContactNo: item.contact,
            PrimaryEmailID: item.email,
            PropertyStatus: item.status,
          });
        });

        let FileName =
          "Hotel" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(descDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            PropertyName: item.propertyName,
            PropertyCode: item.PropertyCode,
            Portfolio: item.portfolio,
            PropertyAddress: item.PropertyAddress,
            Country: item.Country,
            State: item.State,
            Region: item.Region,
            Area: item.Area,
            ZipCode: item.ZipCode,
            City: item.city,
            PrimaryContactNo: item.contact,
            PrimaryEmailID: item.email,
            PropertyStatus: item.status,
          });
        });

        let FileName =
          "Hotel" +
          DateValue.getFullYear() +
          "-" +
          (DateValue.getMonth() + 1) +
          "-" +
          DateValue.getDate();
        let binaryWS = XLSX.utils.json_to_sheet(normalDataBind);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
        XLSX.writeFile(wb, FileName + ".xlsx");
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          PropertyName: item.propertyName,
          PropertyCode: item.PropertyCode,
          Portfolio: item.portfolio,
          PropertyAddress: item.PropertyAddress,
          Country: item.Country,
          State: item.State,
          Region: item.Region,
          Area: item.Area,
          ZipCode: item.ZipCode,
          City: item.city,
          PrimaryContactNo: item.contact,
          PrimaryEmailID: item.email,
          PropertyStatus: item.status,
        });
      });

      let FileName =
        "Hotel" +
        DateValue.getFullYear() +
        "-" +
        (DateValue.getMonth() + 1) +
        "-" +
        DateValue.getDate();
      let binaryWS = XLSX.utils.json_to_sheet(normalInitDataBind);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
      XLSX.writeFile(wb, FileName + ".xlsx");
    }

    // Previous Export Code :
    // let mainTableData: any = [];
    // PropertiesListData.data !== undefined &&
    //   PropertiesListData.data !== null &&
    //   PropertiesListData.data.map((item: any) => {
    //     mainTableData.push({
    //       PropertyName: item.PropertyName,
    //       PropertyCode: item.PropertyCode,
    //       Portfolio: item.Portfolio,
    //       PropertyAddress: item.PropertyAddress,
    //       Country: item.Country,
    //       State: item.State,
    //       Region: item.Region,
    //       Area: item.Area,
    //       ZipCode: item.ZipCode,
    //       City: item.City,
    //       PrimaryContactNo: item.PrimaryContactNo,
    //       PrimaryEmailID: item.PrimaryEmailID,
    //       PropertyStatus: item.PropertyStatus_Term,
    //     });
    //   });
    // let FileName =
    //   "RFP" +
    //   DateValue.getFullYear() +
    //   "-" +
    //   (DateValue.getMonth() + 1) +
    //   "-" +
    //   DateValue.getDate();
    // let binaryWS = XLSX.utils.json_to_sheet(mainTableData);
    // var wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, binaryWS, FileName);
    // XLSX.writeFile(wb, FileName + ".xlsx");
  }
  //#endregion

  let hotelTableData: any = [];

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("hotelFilter"))) {
      HandleRemoveOtherFilters("hotelFilter");
      HandleFilters("hotelFilter", {
        pagePath: "/SetupAndConfigurations/hotels",
        pageName: "hotel",
        filters: [],
        isSort: [],
      });
    }
    getHotelsList();
  }, []);

  async function EditProperties(item: any) {
    setEditPropertiesID(item.PropertyID);
    setModalShow(true);
  }
  function showExistLimit() {
    ToasterError("Contracted Properties Limit Over");
  }
  async function checkLimitOfProperties() {
    let res: any = await CheckPropertiesLimit(Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data === true) {
          setModalShow(true);
        } else {
          showExistLimit();
        }
      }
    }
  }
  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "",
      hotelCode: "",
      city: "",
      userID: userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3" ? null : userId && userId,
      // userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListData(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          if (res.data.data.dataList2 && res.data.data.dataList2.length > 0) {
            setHotelGraphDataCount(res.data.data.dataList2[0]);
          }
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      }
    }
  }
  PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      hotelTableData.push({
        controlRender: {
          propertyName: (
            <div
              className="cursor-pointer"
              onClick={() => {
                if (page_rights_Hotel.is_view) {
                  handleHotelDetailsView(item.PropertyID);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              role="button"
              tabIndex={0}
              onKeyDown={() => {
                handleHotelDetailsView(item.PropertyID);
              }}
            >
              {item.PropertyName}
            </div>
          ),
          status:
            item.PropertyStatus_Term !== null &&
            item.PropertyStatus_Term !== "" &&
            item.PropertyStatus_Term !== undefined &&
            (item.PropertyStatus_Term === "Active" ? (
              <GreenLabel successLabel={item.PropertyStatus_Term} />
            ) : (
              <RedLabel dangerLabel={item.PropertyStatus_Term} />
            )),
          contact:
            item.PrimaryContactNo && item.PrimaryContactNo.includes(" ") ? (
              <a className="text-decoration-none" href={"tel:" + item.PrimaryContactNo}>
                {item.PrimaryContactNo}
              </a>
            ) : (
              <a className="text-decoration-none" href={"tel:" + item.PrimaryContactNo}>
                {item.PrimaryContactNo}
              </a>
            ),
          email: (
            <a className="text-decoration-none" href={"mailto:" + item.PrimaryEmailID}>
              {item.PrimaryEmailID}
            </a>
          ),
        },

        className: {
          action: "table-action sticky-action",
        },
        code: item.HotelCode,
        propertyName: item.PropertyName,
        portfolio: item.Portfolio,
        city: item.City,
        contact:
          item.PrimaryContactNo && item.PrimaryContactNo.includes(" ")
            ? item.PrimaryContactNo //.split(" ")[0]
            : item.PrimaryContactNo,
        email: item.PrimaryEmailID,
        status: item.PropertyStatus_Term,
        action: (
          <span className="btn-icon-grp-table justify-content-end  align-items-center">
            <Button
              className="btn-icon button btn btn-primary editIcon acteditbtn"
              onClick={() => {
                if (page_rights_Hotel.is_update) {
                  EditProperties(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (page_rights_Hotel.is_delete) {
                  DeleteHotels(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </span>
        ),
      });
    });
  function DeleteHotels(data: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          keyID: data.PropertyID,
        };

        let res: any = await deleteHotelsData(input, Token);

        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
              ToasterSuccess("Hotel Deleted Successfully");
              getHotelsList();
            }
          } else {
            ToasterError(res.data.message);
          }
        }
      }
    });
  }
  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Export Data</Tooltip>}>
          <Button
            className="btn-icon exportFileIcon"
            onClick={() => {
              if (page_rights_Hotel.is_export) {
                downloadExcel();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            disabled={PropertiesListData.data.length !== 0 ? false : true}
          ></Button>
        </OverlayTrigger>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Hotel</Tooltip>}>
          <Button
            className="btn-icon addFormIcon"
            onClick={() => {
              if (page_rights_Hotel.is_create) {
                checkLimitOfProperties();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <Row className="mb-0">
          <Col md={12}>
            <Row>
              {/* {averageData.length > 0 ? (
                    averageData.map((data: modelObj, key: any) => ( */}
              <Col
                // onClick={() => handleNavigateToList(data)}
                className="dashboard-blue-card-col"
                md={6}
                lg={4}
                // key={key}
              >
                <DashboardBlueCard
                  numberData={
                    HotelGraphDataCount.totalHotelCount ? HotelGraphDataCount.totalHotelCount : "0"
                  }
                  cardHeader={"Total Hotels"}
                  imgData={"properties.png"}
                />
              </Col>
              <Col
                // onClick={() => handleNavigateToList(data)}
                className="dashboard-blue-card-col"
                md={6}
                lg={4}
                // key={key}
              >
                <DashboardBlueCard
                  numberData={
                    HotelGraphDataCount.activeHotelCount
                      ? HotelGraphDataCount.activeHotelCount
                      : "0"
                  }
                  cardHeader={"Active Hotels"}
                  imgData={"properties.png"}
                />
              </Col>
              <Col
                // onClick={() => handleNavigateToList(data)}
                className="dashboard-blue-card-col"
                md={6}
                lg={4}
                // key={key}
              >
                <DashboardBlueCard
                  numberData={
                    HotelGraphDataCount.inActiveHotelCount
                      ? HotelGraphDataCount.inActiveHotelCount
                      : "0"
                  }
                  cardHeader={"InActive Hotels"}
                  imgData={"properties.png"}
                />
              </Col>
              {/* // ))
                  // ) : (
                  //   <div>
                  //     <PageLoader />
                  //   </div>
                  // )} */}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <div className="lead-height-table">
              <Table
                columnsData={hotelTableColumns}
                tableData={hotelTableData}
                isData={PropertiesListData.IsData}
                id="emp"
              />
            </div>
          </Col>
        </Row>
        {modalShow && (
          <AddHotelsModal
            getHotelsList={getHotelsList}
            show={modalShow}
            onHide={() => {
              setModalShow(false);
              setEditPropertiesID("");
            }}
            EditPropertiesID={EditPropertiesID}
          />
        )}
      </Container>
    </>
  );
}

export default Hotels;
