import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Table, Col, Card, Container } from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import moment, { isMoment } from "moment";
import XLSX from "sheetjs-style";
import NoDataFoundImage from "../../Component/NoData/NoDataFoundImage";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  InteractionItem,
} from "chart.js";
import {
  Bar,
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import {
  LNRGridReportList,
  RFPGridReportList,
  TraceReportData,
} from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";

import HardRet from "../../Asset/Images/Other/hard-ret.svg";
import ReportAccounts from "../../Asset/Images/Other/report-accounts.svg";
import ReportCalendar from "../../Asset/Images/Other/report-calendar.svg";
import ReportEmail from "../../Asset/Images/Other/report-email.svg";
import ReportNormal from "../../Asset/Images/Other/report-normal.svg";
import ReportNots from "../../Asset/Images/Other/report-nots.svg";
import ReportUserCall from "../../Asset/Images/Other/report-user-call.svg";
import ReportUser from "../../Asset/Images/Other/report-user.svg";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  getDateInFormat,
  getDateTimeInDBFormat,
  getDateInDBFormatForExcell,
  getSortDataByObjKey,
} from "../../Services/CommonServices";
import TableView from "../../Component/Table/Index";
import { useNavigate } from "react-router-dom";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
const LNRGridReportModal = ({
  ReportInput,
  activityData,
  oppoData,
  inputs,
  showReport,
  setShowReport,

  propertyID,
  status,
  startDate,
  endDate,
  userIDs,
}) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [loading, setLoading] = useState(false);
  const [ReportData, setReportData]: any = useState([]);
  const navigate = useNavigate();

  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_account = CommonService.getPageRight("Account");

  const [pageLoading, setPageLoading] = useState(true);
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [totalRecord, setTotalRecord] = useState([]);

  const fetchLNRGridList = async () => {
    let input = {
      propertyIDs: ReportInput.propertyID,
      oppoOwnerIDs: ReportInput.userIDs,
      statuses: ReportInput.status,
      startDate: ReportInput.startDate,
      endDate: ReportInput.endDate,
    };

    setPageLoading(true);
    let res = await LNRGridReportList(input, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstGridData !== undefined &&
            res.data.data.lstGridData &&
            res.data.data.lstGridData.length
          ) {
            setTotalRecord(res.data.data.lstStatusCountData);
            setData(res.data.data.lstGridData);
            setPageLoading(false);
          } else {
            setData([]);
            setTotalRecord([]);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      } else {
        setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      setLoading(false);
      ToasterError(res.data.message);
    }
  };

  const tablLNRGridColumns = React.useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "PropertyName",
      },
      {
        Header: "Account",
        accessor: "AccountName",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Opportunity",
        accessor: "OppoName",
      },
      {
        Header: "OppoOwner",
        accessor: "OppoOwner",
      },
      {
        Header: "Anticipated Revenue",
        accessor: "AnticipatedRevenue",
      },
      {
        Header: "City Spend RNs",
        accessor: "CitySpendRNS",
      },
      {
        Header: "Hotelligence RNs",
        accessor: "HotelligenceRNS",
      },
      {
        Header: "TTMHotelligence",
        accessor: "TTMHotelligence",
      },
      {
        Header: "Hotelligence ADR",
        accessor: "HotelligenceADR",
      },
      {
        Header: "Comp set Hotel1",
        accessor: "CompSetHotel1",
      },
      {
        Header: "Comp set Hotel1 Rate",
        accessor: "CompSetHotel1Rate",
      },
      {
        Header: "Comp set Hotel2",
        accessor: "CompSetHotel2",
      },
      {
        Header: "Comp set Hotel2 Rate",
        accessor: "CompSetHotel2Rate",
      },
      {
        Header: "Rate StartDate",
        accessor: "RateStartDate",
      },
      {
        Header: "Rate EndDate",
        accessor: "RateEndDate",
      },
      {
        Header: "Client Target Rate",
        accessor: "ClientTargetRate",
      },
      {
        Header: "RNs Goal",
        accessor: "RNSGoal",
      },
      {
        Header: "Business Case DueDate",
        accessor: "BusinessCaseDueDate",
      },
      {
        Header: "Lost Reason",
        accessor: "LostReason",
      },
    ],
    []
  );

  var rightAlignClass = { textAlign: "right" };
  var centerAlignClass = { textAlign: "center" };

  let tablLNRGridData: any = [];
  data.map((item: any) => {
    tablLNRGridData.push({
      className: {
        AnticipatedRevenue: "rightAlignClass text-end",
        ClientTargetRate: "rightAlignClass text-end",

        CitySpendRNS: "rightAlignClass text-center",
        HotelligenceRNS: "rightAlignClass text-center",
        TTMHotelligence: "rightAlignClass text-center",
        HotelligenceADR: "rightAlignClass text-center",
        CompSetHotel1Rate: "rightAlignClass text-center",
        CompSetHotel2Rate: "rightAlignClass text-center",

        RateStartDate: "centerAlignClass text-center",
        RateEndDate: "centerAlignClass text-center",
        RNSGoal: "centerAlignClass text-center",
        BusinessCaseDueDate: "centerAlignClass text-center",

        action: "table-action",
      },
      controlRender: {
        AccountName: (
          <div
            role="button"
            tabIndex={-1}
            onClick={() => {
              if (page_rights_account.is_view) {
                accountDetailsView(item.AccountID, item.AccountName);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            onKeyDown={() => {
              if (page_rights_account.is_view) {
                accountDetailsView(item.AccountID, item.AccountName);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          >
            {item.AccountName ? item.AccountName.replace("#", " ") : ""}
          </div>
        ),

        RateStartDate: getDateInFormat(item.RateStartDate),
        RateEndDate: getDateInFormat(item.RateEndDate),
        BusinessCaseDueDate: getDateInFormat(item.BusinessCaseDueDate),
      },
      PropertyName: item.PropertyName,
      AccountName: item.AccountName,
      OppoOwner: item.OppoOwner,
      Status: item.Status,
      Source: item.Source,
      OppoName: item.OppoName,
      AnticipatedRevenue: item.AnticipatedRevenue,
      CitySpendRNS: item.CitySpendRNS,
      HotelligenceRNS: item.HotelligenceRNS,
      TTMHotelligence: item.TTMHotelligence,
      HotelligenceADR: item.HotelligenceADR,
      CompSetHotel1: item.CompSetHotel1,
      CompSetHotel1Rate: item.CompSetHotel1Rate,
      CompSetHotel2: item.CompSetHotel2,
      CompSetHotel2Rate: item.CompSetHotel2Rate,
      RateStartDate: getDateTimeInDBFormat(item.RateStartDate),
      RateEndDate: getDateTimeInDBFormat(item.RateEndDate),
      ClientTargetRate: item.ClientTargetRate,
      RNSGoal: item.RNSGoal,
      BusinessCaseDueDate: getDateTimeInDBFormat(item.BusinessCaseDueDate),
      LostReason: item.LostReason,
    });
  });

  function accountDetailsView(id, name) {
    navigate("/accounts-view", { state: { AccountID: id, AccountName: name } });
  }

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("lnrGridReportFilter"))) {
      HandleRemoveOtherFilters("lnrGridReportFilter");
      HandleFilters("lnrGridReportFilter", {
        pagePath: "/LNRGridTable",
        pageName: "lnrGridReport",
        filters: [],
        isSort: [],
      });
    }
    fetchLNRGridList();
    // fetchReportData();
  }, []);

  async function fetchReportData() {
    let input: any = {
      propertyIDs: ReportInput.propertyIDs,
      userIDs: ReportInput.userIDs,
      startDate: ReportInput.startDate.split("T")[0],
      endDate: ReportInput.endDate.split("T")[0],
    };

    setLoading(true);
    let res: any = await TraceReportData(input, Token);
    setLoading(false);

    if (res.data.success) {
      if (res.data.data !== "undefined" && res.data.data) {
        // setReportData(res.data.data.lstReportData);
        setReportData(res.data.data);
        setLoading(false);
        setShowReport(true);
      } else {
        setShowReport(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }

  const handleClosePopup = () => {
    setShowReport(false);
  };

  async function getFilteredExportData(newArray) {
    let localFilters = JSON.parse(localStorage.getItem("lnrGridReportFilter"));
    let mainTableData: any = [];
    newArray !== undefined &&
      newArray !== null &&
      newArray.map((item: any) => {
        mainTableData.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
          OppoOwner: item.OppoOwner,
          Status: item.Status,
          Source: item.Source,
          OppoName: item.OppoName,
          AnticipatedRevenue: item.AnticipatedRevenue,
          CitySpendRNS: item.CitySpendRNS,
          HotelligenceRNS: item.HotelligenceRNS,
          TTMHotelligence: item.TTMHotelligence,
          HotelligenceADR: item.HotelligenceADR,
          CompSetHotel1: item.CompSetHotel1,
          CompSetHotel1Rate: item.CompSetHotel1Rate,
          CompSetHotel2: item.CompSetHotel2,
          CompSetHotel2Rate: item.CompSetHotel2Rate,
          RateStartDate: getDateInDBFormatForExcell(item.RateStartDate),
          RateEndDate: getDateInDBFormatForExcell(item.RateEndDate),
          ClientTargetRate: item.ClientTargetRate,
          RNSGoal: item.RNSGoal,
          BusinessCaseDueDate: getDateInDBFormatForExcell(item.BusinessCaseDueDate),
          LostReason: item.LostReason,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
            OppoOwner: item.OppoOwner,
            Status: item.Status,
            Source: item.Source,
            OppoName: item.OppoName,
            AnticipatedRevenue: item.AnticipatedRevenue,
            CitySpendRNS: item.CitySpendRNS,
            HotelligenceRNS: item.HotelligenceRNS,
            TTMHotelligence: item.TTMHotelligence,
            HotelligenceADR: item.HotelligenceADR,
            CompSetHotel1: item.CompSetHotel1,
            CompSetHotel1Rate: item.CompSetHotel1Rate,
            CompSetHotel2: item.CompSetHotel2,
            CompSetHotel2Rate: item.CompSetHotel2Rate,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            ClientTargetRate: item.ClientTargetRate,
            RNSGoal: item.RNSGoal,
            BusinessCaseDueDate: item.BusinessCaseDueDate,
            LostReason: item.LostReason,
          });
        });

        return ascDataBind;
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
            OppoOwner: item.OppoOwner,
            Status: item.Status,
            Source: item.Source,
            OppoName: item.OppoName,
            AnticipatedRevenue: item.AnticipatedRevenue,
            CitySpendRNS: item.CitySpendRNS,
            HotelligenceRNS: item.HotelligenceRNS,
            TTMHotelligence: item.TTMHotelligence,
            HotelligenceADR: item.HotelligenceADR,
            CompSetHotel1: item.CompSetHotel1,
            CompSetHotel1Rate: item.CompSetHotel1Rate,
            CompSetHotel2: item.CompSetHotel2,
            CompSetHotel2Rate: item.CompSetHotel2Rate,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            ClientTargetRate: item.ClientTargetRate,
            RNSGoal: item.RNSGoal,
            BusinessCaseDueDate: item.BusinessCaseDueDate,
            LostReason: item.LostReason,
          });
        });

        return descDataBind;
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
            OppoOwner: item.OppoOwner,
            Status: item.Status,
            Source: item.Source,
            OppoName: item.OppoName,
            AnticipatedRevenue: item.AnticipatedRevenue,
            CitySpendRNS: item.CitySpendRNS,
            HotelligenceRNS: item.HotelligenceRNS,
            TTMHotelligence: item.TTMHotelligence,
            HotelligenceADR: item.HotelligenceADR,
            CompSetHotel1: item.CompSetHotel1,
            CompSetHotel1Rate: item.CompSetHotel1Rate,
            CompSetHotel2: item.CompSetHotel2,
            CompSetHotel2Rate: item.CompSetHotel2Rate,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            ClientTargetRate: item.ClientTargetRate,
            RNSGoal: item.RNSGoal,
            BusinessCaseDueDate: item.BusinessCaseDueDate,
            LostReason: item.LostReason,
          });
        });

        return normalDataBind;
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
          OppoOwner: item.OppoOwner,
          Status: item.Status,
          Source: item.Source,
          OppoName: item.OppoName,
          AnticipatedRevenue: item.AnticipatedRevenue,
          CitySpendRNS: item.CitySpendRNS,
          HotelligenceRNS: item.HotelligenceRNS,
          TTMHotelligence: item.TTMHotelligence,
          HotelligenceADR: item.HotelligenceADR,
          CompSetHotel1: item.CompSetHotel1,
          CompSetHotel1Rate: item.CompSetHotel1Rate,
          CompSetHotel2: item.CompSetHotel2,
          CompSetHotel2Rate: item.CompSetHotel2Rate,
          RateStartDate: item.RateStartDate,
          RateEndDate: item.RateEndDate,
          ClientTargetRate: item.ClientTargetRate,
          RNSGoal: item.RNSGoal,
          BusinessCaseDueDate: item.BusinessCaseDueDate,
          LostReason: item.LostReason,
        });
      });
      return normalInitDataBind;
    }
  }

  const exportData = async () => {
    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    let ws = XLSX.utils.json_to_sheet([], {
      // header: [`LNR Grid - ${selectedYear.label}`],
      header: [
        `LNR Grid  (${
          moment(ReportInput.startDate).format("MM/DD/YYYY") +
          " - " +
          moment(ReportInput.endDate).format("MM/DD/YYYY")
        })`,
      ],
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
    ws["!merges"] = merge;

    XLSX.utils.sheet_add_aoa(ws, [Object.keys(totalRecord[0])], { origin: "A3" });
    XLSX.utils.sheet_add_json(ws, totalRecord, {
      skipHeader: true,
      origin: "A4",
    });

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    for (var R = rangeTotalAccounts.s.r; R <= rangeTotalAccounts.e.r; ++R) {
      for (var C = rangeTotalAccounts.s.c; C <= rangeTotalAccounts.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: C === 1 || C === 2 ? "right" : "left",
              },
            };
          }
        }
      }
    }
    const newArray = data.map(({ AccountID, ...keepAttrs }) => keepAttrs);

    // ------------------- Sort Data By Filter :

    let newArraySortedData = await getFilteredExportData(newArray);

    // ------------------- Sort Data By Filter :

    XLSX.utils.sheet_add_aoa(
      ws,
      [Object.keys(newArraySortedData[0]).filter((obj) => obj !== "AccountID")],
      {
        origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 3),
      }
    );
    XLSX.utils.sheet_add_json(ws, newArraySortedData, {
      header: Object.keys(newArraySortedData[0]).filter((obj) => obj !== "AccountID"),
      skipHeader: true,
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 4),
    });

    const rowDataCount: any = XLSX.utils.decode_range(ws["!ref"] ?? "");

    for (var R = rowCount + 2; R <= rowDataCount.e.r; ++R) {
      for (var C = rowDataCount.s.c; C <= rowDataCount.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (typeof ws[cell_ref] !== "undefined") {
          if (rowCount + 2 === R) {
            ws[cell_ref].s = headStylesCell;
          }
          // else if(R ===  2){
          //   ws[cell_ref].s = headStylesCell
          // }
          else {
            // Commented Old One
            // let checkArray = [4, 5, 6, 7, 8, 10, 13, 16, 17];
            // ws[cell_ref].s = {
            //   ...cellStyle,
            //   alignment: {
            //     ...cellStyle.alignment,
            //     horizontal: checkArray.includes(C) ? "right" : "left",
            //   },
            // };

            let checkForRightArray = [6, 15, 16, 17, 19];
            let checkForCenterArray = [7, 8, 9, 10, 12, 14, 18];

            if (checkForRightArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "right",
                },
              };
            } else if (checkForCenterArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "center",
                },
              };
            }
          }
        }
      }
    }

    let cols: any = [];

    Object.keys(newArraySortedData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    // let filename = "LNR-GRID" + selectedYear.label + ".xlsx";
    let filename = `LNR-GRID  (${moment(ReportInput.endDate).format("MM-DD-YYYY")}).xlsx`;
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename);
  };

  return (
    <Modal
      show={showReport}
      onHide={() => {
        handleClosePopup();
      }}
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          LNR Grid Report - (
          {moment(ReportInput.startDate).format("MM/DD/YYYY") +
            " - " +
            moment(ReportInput.endDate).format("MM/DD/YYYY")}
          )
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {!pageLoading ? (
            <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
              <TableView
                isData={loading}
                columnsData={tablLNRGridColumns}
                tableData={tablLNRGridData}
              />
            </Col>
          ) : (
            <PageLoader />
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={handleClosePopup}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          disabled={data.length && !loading ? false : true}
          onClick={(e) => {
            e.preventDefault();
            if (data.length && !loading) {
              exportData();
            }
          }}
          type="button"
          variant="primary"
        >
          <span className="me-2">
            <img src={ExportIcon} alt="" />
          </span>
          Export Excel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LNRGridReportModal;
