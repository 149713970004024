import ProfileSideTabs from "./ProfileSideTabs";

function UserProfile() {
  return (
    <>
      <ProfileSideTabs />
    </>
  );
}

export default UserProfile;
