import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import TableView from "../../../Component/Table/Index";
import AddEditHotelDetailsCompetitorModal from "../../../Model/AddEditHotelDetailsCompetitorModal";
import { CommonService, ToasterError, ToasterSuccess } from "../../../Services/CommonServices";
import {
  CompSetHotelGetList,
  CompSetHotelsDelete,
} from "../../../Services/Setup&Config/HotelService";

export default function CompetitorHotelTab({ showModel, setShowModel, property_id }) {
  let page_rights_Hotel = CommonService.getPageRight("Hotels");
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  let [CompetitorList, setCompetitorList]: any = React.useState({ IsData: true, data: [] });
  const [competitorID, setCompetitorID] = React.useState("");

  const compTableColumns = React.useMemo(
    () => [
      {
        Header: "Brand Name",
        accessor: "BrandName",
      },
      {
        Header: "Hotel Name",
        accessor: "HotelName",
      },
      {
        Header: "Website URL",
        accessor: "WebsiteURL",
      },
      {
        Header: "RateShop URL",
        accessor: "RateShopURL",
      },
      {
        Header: "Action",
        accessor: "Action",
        className: "actionsort table-action sticky-action",
      },
    ],
    []
  );

  let compTableData: any = [];
  CompetitorList.data.length &&
    CompetitorList.data.map((item) => {
      compTableData.push({
        controlRender: {
          WebsiteURL: (
            <a
              href={
                item.WebsiteURL && item?.WebsiteURL?.includes("http")
                  ? item.WebsiteURL
                  : "//" + item.WebsiteURL
              }
              target="_blank"
            >
              {item.WebsiteURL !== undefined && item.WebsiteURL ? item.WebsiteURL : "-"}
            </a>
          ),
          RateShopURL: (
            <a
              href={
                item.RateShopURL && item?.RateShopURL?.includes("http")
                  ? item.RateShopURL
                  : "//" + item.RateShopURL
              }
              target="_blank"
            >
              {item.RateShopURL ? item.RateShopURL : "-"}
            </a>
          ),
        },
        className: {
          Action: "table-action sticky-action",
        },
        BrandName: item.BrandName,
        HotelName:  item.HotelName,
        WebsiteURL: item.WebsiteURL,
        RateShopURL: item.RateShopURL,
        Action: (
          <span className="btn-icon-grp-table justify-content-end  align-items-center">
            <Button
              className="btn-icon button btn btn-primary editIcon acteditbtn"
              onClick={() => {
                if (page_rights_Hotel.is_update) {
                  setCompetitorID(item.CompHotelID);
                  setShowModel(true);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (page_rights_Hotel.is_delete) {
                  DeleteHotels(item.CompHotelID);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </span>
        ),
      });
    });

  const fetchCompetitorListData = async () => {
    let data = {
      keyID: property_id,
    };

    setCompetitorList({ IsData: true, data: [] });
    let res: any = await CompSetHotelGetList(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            setCompetitorList({ IsData: false, data: res.data.data.dataList1 });
          } else {
            setCompetitorList({ IsData: false, data: [] });
          }
        } else {
          setCompetitorList({ IsData: false, data: [] });
        }
      } else {
        setCompetitorList({ IsData: false, data: [] });
      }
    } else {
      setCompetitorList({ IsData: false, data: [] });
    }
  };

  function DeleteHotels(CompHotelID: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          keyID: CompHotelID,
        };
        let res: any = await CompSetHotelsDelete(input, Token);
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
            ToasterSuccess("Competitor Hotel Deleted Successfully");

              fetchCompetitorListData();
            }
          } else {
ToasterError(res.data.message)
          }
        }
      }
    });
  }

  React.useEffect(() => {
    if (property_id) {
      fetchCompetitorListData();
    }
  }, []);
  return (
    <>
      <div className="opportunity-height-table">
        <TableView
          columnsData={compTableColumns}
          tableData={compTableData}
          isData={CompetitorList.IsData}
        />

        {showModel && (
          <AddEditHotelDetailsCompetitorModal
            property_id={property_id}
            competitorID={competitorID}
            show={showModel}
            fetchCompetitorListData={fetchCompetitorListData}
            onHide={() => {
              setShowModel(false);
              setCompetitorID("");
            }}
          />
        )}
      </div>
    </>
  );
}
