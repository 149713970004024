import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import { getDateInFormatMMMMDYYYY } from "../../Services/CommonServices";
import { useSelector } from "react-redux";

import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import TableView from "../../Component/Table/Index";

import XLSX from "sheetjs-style";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";

export default function ImportMeetingCateringModel(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const tableRef = useRef(null);

  const SuccessfullImportdHeader = useMemo(
    () => [
      {
        Header: "Opportunity name",
        accessor: "Opportunityname",
        className: "w-243",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Hotels",
        accessor: "Hotels",
      },
      {
        Header: "Account",
        accessor: "Account",
      },
      {
        Header: "Opportunity Owner",
        accessor: "OpportunityOwner",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "StartDate",
        accessor: "StartDate",
      },
      {
        Header: "EndDate",
        accessor: "EndDate",
      },
      {
        Header: "AnticipetedRevanue",
        accessor: "AnticipetedRevanue",
      },
    ],
    []
  );

  const InvalidImportdHeader = useMemo(
    () => [
      {
        Header: "Reason",
        accessor: "Reason",
      },
      {
        Header: "Opportunity name",
        accessor: "Opportunityname",
        className: "w-243",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Hotels",
        accessor: "Hotels",
      },
      {
        Header: "Account",
        accessor: "Account",
      },
      {
        Header: "Opportunity Owner",
        accessor: "OpportunityOwner",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "StartDate",
        accessor: "StartDate",
      },
      {
        Header: "EndDate",
        accessor: "EndDate",
      },
      {
        Header: "AnticipetedRevanue",
        accessor: "AnticipetedRevanue",
      },
    ],
    []
  );

  let tempSuccessfullImportedData: any = [];
  let tempInvalidImportedData: any = [];

  const [ImportedData, setImportedData] = useState([]);

  ImportedData &&
    ImportedData.map((item: any) => {
      if (item.Isvalid === true) {
        tempSuccessfullImportedData.push({
          controlRender: {
            Status:
              item.Status === "8. Expired Rate" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "7. Lost / RFP Declined" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "6. Rate Renewal" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : (
                <GreenLabel successLabel={item.Status} />
              ),
          },
          className: {
            Reason: "text-danger w-300 whiteSpaceNowrap",
            AnticipetedRevanue: "text-end",
            StartDate: "text-end",
            EndDate: "text-end",
          },
          Opportunityname: item.Opportunityname,
          Source: item.Source,
          Hotels: item.Hotels,
          Account: item.Account,
          OpportunityOwner: item.OpportunityOwner,
          Status: item.Status,
          StartDate: getDateInFormatMMMMDYYYY(item.StartDate),

          EndDate: getDateInFormatMMMMDYYYY(item.EndDate),

          AnticipetedRevanue:
            item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
              ? "$" + item.AnticipetedRevanue
              : "",
        });
      } else {
        tempInvalidImportedData.push({
          controlRender: {
            Status:
              item.Status === "8. Expired Rate" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "7. Lost / RFP Declined" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "6. Rate Renewal" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : (
                <GreenLabel successLabel={item.Status} />
              ),
          },
          className: {
            Reason: "text-danger w-300 whiteSpaceNowrap",
            AnticipetedRevanue: "text-end",
            StartDate: "text-end",
            EndDate: "text-end",
          },
          Reason: item.Reason,
          Opportunityname: item.Opportunityname,
          Source: item.Source,
          Hotels: item.Hotels,
          Account: item.Account,
          OpportunityOwner: item.OpportunityOwner,
          Status: item.Status,
          StartDate: getDateInFormatMMMMDYYYY(item.StartDate),

          EndDate: getDateInFormatMMMMDYYYY(item.EndDate),

          AnticipetedRevanue:
            item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
              ? "$" + item.AnticipetedRevanue
              : "",
        });
      }
    });

  useEffect(() => {
    if (
      props.ModuleData !== undefined &&
      props.ModuleData !== null &&
      props.ModuleData.length !== 0
    ) {
      setImportedData(props.ModuleData);
    }
  }, [props && props.ModuleData]);

  function downloadTable() {
    const table = document.getElementById("tableRef");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Meeting Catering");
    XLSX.writeFile(wb, "MeetingCatering_Opportunity.xlsx");
  }

  const exportData = () => {
    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    let ws = XLSX.utils.json_to_sheet([], {});

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    let tempData: any = [];
    ImportedData &&
      ImportedData.map((item: any, idx) => {
        if (item.Isvalid === false) {
          tempData.push({
            Opportunityname: item.Opportunityname,
            Source: item.Source,
            Hotels: item.Hotels,
            Account: item.Account,
            OpportunityOwner: item.OpportunityOwner,
            Status: item.Status,
            StartDate: getDateInFormatMMMMDYYYY(item.StartDate),

            EndDate: getDateInFormatMMMMDYYYY(item.EndDate),

            AnticipetedRevanue: item.AnticipetedRevanue,
          });
        }
      });

    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [
          "Opportunityname",
          "Source",
          "Hotels",
          "Account",
          "OpportunityOwner",
          "Status",
          "StartDate",
          "EndDate",
          "AnticipetedRevanue",
        ],
      ],
      { origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 1) }
    );

    XLSX.utils.sheet_add_json(ws, tempData, {
      header: [
        "Opportunityname",
        "Source",
        "Hotels",
        "Account",
        "OpportunityOwner",
        "Status",
        "StartDate",
        "EndDate",
        "AnticipetedRevanue",
      ],
      skipHeader: true,
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 2),
    });

    let cols: any = [];

    Object.keys(tempData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    let filename = `MeetingCatering.xlsx`;
    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename);
  };

  let MACErrTableExport: any = () => {
    return (
      <>
        <thead>
          <tr>
            <th>
              <b> Opportunity name </b>
            </th>
            <th>
              <b> Source </b>
            </th>
            <th>
              <b> Hotels </b>
            </th>
            <th>
              <b> Account </b>
            </th>
            <th>
              <b> Opportunity Owner </b>
            </th>
            <th>
              <b> Status </b>
            </th>
            <th>
              <b> StartDate </b>
            </th>
            <th>
              <b> EndDate </b>
            </th>
            <th>
              <b> AnticipetedRevanue </b>
            </th>
          </tr>
        </thead>

        <tbody>
          {ImportedData &&
            ImportedData.map((item: any, idx) => {
              if (item.Isvalid === false) {
                return (
                  <tr key={idx}>
                    <td className="text-danger w-300 whiteSpaceNowrap">{item.Opportunityname}</td>
                    <td>{item.Source}</td>
                    <td>{item.Hotels}</td>
                    <td>{item.Account}</td>
                    <td>{item.OpportunityOwner}</td>
                    <td>{item.Status}</td>
                    <td>{getDateInFormatMMMMDYYYY(item.StartDate)}</td>
                    <td>{getDateInFormatMMMMDYYYY(item.EndDate)}</td>
                    <td>
                      {" "}
                      {item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
                        ? "$" + item.AnticipetedRevanue
                        : ""}
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </>
    );
  };

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center justify-content-between w-100"
          >
            <div> Import (Meeting Catering Opportunity) </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col xs={12}>
              <h6> Imported Data</h6>
              <div>
                <TableView
                  columnsData={SuccessfullImportdHeader}
                  tableData={tempSuccessfullImportedData}
                  isData={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-5">
              <Row>
                <Col md={11}>
                  <h6> Invalid Data</h6>
                </Col>
                <Col md={1}>
                  <Button
                    className="mb-2"
                    variant="primary"
                    disabled={tempInvalidImportedData.length ? false : true}
                    onClick={downloadTable}
                  >
                    <span className="me-2">
                      <img src={WhiteSaveIcon} alt="" />
                    </span>
                    Export
                  </Button>
                </Col>
              </Row>

              <div>
                <TableView
                  columnsData={InvalidImportdHeader}
                  tableData={tempInvalidImportedData}
                  isData={false}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Table
        id={"tableRef"}
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <MACErrTableExport />
      </Table>
    </>
  );
}
