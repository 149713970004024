// import React from "react";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "./authConfig";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/esm/Dropdown";

// /**
//  * Renders a drop down button with child buttons for logging in with a popup or redirect
//  */
// export const SignInButton = () => {
//   const { instance } = useMsal();

//   const handleLogin = (loginType) => {
//     if (loginType === "popup") {
//       instance.loginPopup(loginRequest).catch((e) => {
//         console.log(e);
//       });
//     } else if (loginType === "redirect") {
//       instance.loginRedirect(loginRequest).catch((e) => {
//         console.log(e);
//       });
//     }
//   };
//   return (
//     <div className="">
//       <DropdownButton variant="savebtnclient primary-btn-drop" className="ml-auto " drop="left" title="Sign In">
//         {/* <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>
//           Sign in using Popup
//         </Dropdown.Item> */}
//         <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>
//           Sign in using Redirect
//         </Dropdown.Item>
//       </DropdownButton>
//     </div>
//   );
// };

import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { Button } from "react-bootstrap";

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    // if (loginType === "popup") {
    //     instance.loginPopup(loginRequest).catch(e => {
    //         console.log(e);
    //     });
    // } else if (loginType === "redirect") {
    //     instance.loginRedirect(loginRequest).catch(e => {
    //         console.log(e);
    //     });
    // }
    instance.loginRedirect(loginRequest).catch((e) => {
      // console.log(e);
    });
  };
  return (
    // <DropdownButton variant="outline-light" className="ml-auto" drop="left" title="Sign In">
    //     <Dropdown.Item as="button" onClick={() => handleLogin("popup")}>Sign in using Popup</Dropdown.Item>
    //     <Dropdown.Item as="button" onClick={() => handleLogin("redirect")}>Sign in using Redirect</Dropdown.Item>
    // </DropdownButton>
    <Button onClick={() => handleLogin()}>Login</Button>
  );
};
