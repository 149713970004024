import { useEffect, useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Cookies } from "react-cookie-consent";
import InntelligentHotel from "../../../src/Asset/Images/Header/InntelligentHotel.png";
import LoginButtonIcon from "../../../src/Asset/Images/Icons/LoginButtonIcon.svg";
import LoginKeyIcon from "../../../src/Asset/Images/Icons/LoginKeyIcon.svg";
import LoginUserIcon from "../../../src/Asset/Images/Icons/LoginUserIcon.svg";
import Loader from "../../Component/Loader/Index";
import { ENUMS } from "../../Constants";
import { isLogochangeData, updateUserData } from "../../Redux/actions";
import {
  ToasterError,
  fnCheckValidationOfObject,
  handleClientLogin,
} from "../../Services/CommonServices";
import { Login, SendUserOTPEmail, VerifyClientLogin } from "../../Services/LoginServices";

function AuthLogin() {
  const [IsloginClick, setIsloginClick] = useState(false);
  const [ShowPassword, setShowPassword] = useState(false);
  const [clientLoginLoader, setClientLoginLoader] = useState(true);

  const navigate = useNavigate();
  const [input, setInput] = useState({
    userName: "",
    password: "",
    clientID: null,
    // errors: {
    //   userName: "",
    //   password: "",
    // },
    errors: {
      userName: "",
      password: "",
      ValidationRules: [
        {
          FieldName: "userName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "password",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
  const CryptoJS: any = require("crypto-js");
  const dispatch = useDispatch();

  const location = useLocation();
  const query_params = new URLSearchParams(location.search);

  async function loginClick() {
    let obj = fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });
    if (obj.isValid) {
      setIsloginClick(true);
      const res: any = await Login(input);
      setIsloginClick(false);

      if (res.data.statusCode === 200) {
        const data: any = res.data.data && res.data.data.dataList1;

        if (data.length > 1) {
          navigate("/clientSelection", { state: { data: data, inputValue: input } });
        } else {
          const Value = data;
          if (Value !== null || Value !== "") {
            let Verifyinput = {
              userID: Value[0].U_UserID,
              dbConnectionID: Value[0].DBConnectionID,
              clientID: Value[0].C_ClientID,
              from: "Client",
            };

            // Logic to set DBCOnnectionID in local with encription :
            localStorage.setItem("loginTime", Date.now());
            localStorage.setItem(
              "DBConnectionID",
              CryptoJS.AES.encrypt(Value[0].DBConnectionID, cryptoKey)
            );
            //This Method to we do a Client Selection for same api

            const resdata: any = await VerifyClientLogin(Verifyinput);
            if (resdata !== "") {
              if (resdata.data.data.dataList1[0].IsTSA) {
                let input = {
                  keyID:
                    resdata.data.data.dataList1[0].U_UserID &&
                    resdata.data.data.dataList1[0].U_UserID,
                };
                let res: any = await SendUserOTPEmail(
                  input,
                  resdata.data.data.dataList2 && resdata.data.data.dataList2[0].accessToken
                );

                if (res.data.success) {
                  let initialRights = ENUMS.side_menu;
                  let newRights = {};

                  Object.keys(initialRights).forEach((key_id) => {
                    if (initialRights[key_id].hasChild) {
                      let newChild: any = [];
                      initialRights[key_id].child.forEach((ch_itm) => {
                        let newInnerChild: any = [];

                        if (ch_itm.hasChild) {
                          ch_itm.child.forEach((inner_ch_itm) => {
                            let resDataCheck1 = resdata.data.data.dataList3[0].filter(
                              (itm) => itm.RightName == inner_ch_itm.RightName
                            );

                            if (resDataCheck1.length) {
                              newInnerChild = [
                                ...newInnerChild,
                                {
                                  ...inner_ch_itm,
                                  is_view: resDataCheck1[0].IsView,
                                  is_create: resDataCheck1[0].IsCreate,
                                  is_update: resDataCheck1[0].IsEdit,
                                  is_delete: resDataCheck1[0].IsDelete,
                                  is_export: resDataCheck1[0].IsExport,
                                },
                              ];
                            } else {
                              newInnerChild = [
                                ...newInnerChild,
                                {
                                  ...inner_ch_itm,
                                  is_view: false,
                                  is_create: false,
                                  is_update: false,
                                  is_delete: false,
                                  is_export: false,
                                },
                              ];
                            }
                          });
                        }
                        let resDataCheck2 = resdata.data.data.dataList3[0].filter(
                          (itm) => itm.RightName == ch_itm.RightName
                        );

                        if (ch_itm.hasChild) {
                          newChild = [
                            ...newChild,
                            {
                              ...ch_itm,
                              child: newInnerChild,
                            },
                          ];
                        } else {
                          if (resDataCheck2.length) {
                            newChild = [
                              ...newChild,
                              {
                                ...ch_itm,
                                is_view: resDataCheck2[0].IsView,
                                is_create: resDataCheck2[0].IsCreate,
                                is_update: resDataCheck2[0].IsEdit,
                                is_delete: resDataCheck2[0].IsDelete,
                                is_export: resDataCheck2[0].IsExport,
                              },
                            ];
                          } else {
                            newChild = [
                              ...newChild,
                              {
                                ...ch_itm,
                                is_view: false,
                                is_create: false,
                                is_update: false,
                                is_delete: false,
                                is_export: false,
                              },
                            ];
                          }
                        }
                        newRights = {
                          ...newRights,
                          [key_id]: {
                            ...ENUMS.side_menu[key_id],
                            child: newChild,
                          },
                        };
                      });
                    } else {
                      let resDataCheck3 = resdata.data.data.dataList3[0].filter(
                        (itm) => itm.RightName == initialRights[key_id].RightName
                      );
                      if (resDataCheck3.length) {
                        newRights = {
                          ...newRights,
                          [key_id]: {
                            ...ENUMS.side_menu[key_id],
                            is_view: resDataCheck3.length
                              ? resDataCheck3[0].IsView
                              : initialRights[key_id].is_view,
                            is_create: resDataCheck3.length
                              ? resDataCheck3[0].IsCreate
                              : initialRights[key_id].is_create,
                            is_update: resDataCheck3.length
                              ? resDataCheck3[0].IsEdit
                              : initialRights[key_id].is_update,
                            is_delete: resDataCheck3.length
                              ? resDataCheck3[0].IsDelete
                              : initialRights[key_id].is_delete,
                            is_export: resDataCheck3.length
                              ? resDataCheck3[0].IsExport
                              : initialRights[key_id].is_export,
                          },
                        };
                      } else {
                        if (initialRights[key_id].RightName === "Dashboard") {
                          newRights = {
                            ...newRights,
                            [key_id]: {
                              ...ENUMS.side_menu[key_id],
                              is_view: true,
                              is_create: true,
                              is_update: true,
                              is_delete: true,
                              is_export: true,
                            },
                          };
                        } else {
                          newRights = {
                            ...newRights,
                            [key_id]: {
                              ...ENUMS.side_menu[key_id],
                              is_view: false,
                              is_create: false,
                              is_update: false,
                              is_delete: false,
                              is_export: false,
                            },
                          };
                        }
                      }
                    }
                  });

                  navigate("/twoStepAuthentication", {
                    state: {
                      UserID:
                        resdata.data.data.dataList1[0].U_UserID &&
                        resdata.data.data.dataList1[0].U_UserID,
                      token:
                        resdata.data.data.dataList2 && resdata.data.data.dataList2[0].accessToken,
                      loggedDetail: resdata.data.data,
                    },
                  });
                } else {
                  ToasterError("Something Went Wrong!!");
                }
              } else {
                localStorage.setItem(
                  "accessToken",
                  CryptoJS.AES.encrypt(
                    resdata.data.data.dataList2 && resdata.data.data.dataList2[0].accessToken,
                    cryptoKey
                  )
                );
                localStorage.setItem(
                  "authUser",
                  CryptoJS.AES.encrypt(JSON.stringify(resdata.data.data.dataList1[0]), cryptoKey)
                );
                localStorage.setItem(
                  "tokenExpire",
                  resdata.data.data.dataList2 && resdata.data.data.dataList2[0].tokenExpiresIn
                );

                let initialRights = ENUMS.side_menu;
                let newRights = {};

                Object.keys(initialRights).forEach((key_id) => {
                  if (initialRights[key_id].hasChild) {
                    let newChild: any = [];
                    initialRights[key_id].child.forEach((ch_itm) => {
                      let newInnerChild: any = [];

                      if (ch_itm.hasChild) {
                        ch_itm.child.forEach((inner_ch_itm) => {
                          let resDataCheck1 = resdata.data.data.dataList3[0].filter(
                            (itm) => itm.RightName == inner_ch_itm.RightName
                          );

                          if (resDataCheck1.length) {
                            newInnerChild = [
                              ...newInnerChild,
                              {
                                ...inner_ch_itm,
                                is_view: resDataCheck1[0].IsView,
                                is_create: resDataCheck1[0].IsCreate,
                                is_update: resDataCheck1[0].IsEdit,
                                is_delete: resDataCheck1[0].IsDelete,
                                is_export: resDataCheck1[0].IsExport,
                              },
                            ];
                          } else {
                            newInnerChild = [
                              ...newInnerChild,
                              {
                                ...inner_ch_itm,
                                is_view: false,
                                is_create: false,
                                is_update: false,
                                is_delete: false,
                                is_export: false,
                              },
                            ];
                          }
                        });
                      }
                      let resDataCheck2 = resdata.data.data.dataList3[0].filter(
                        (itm) => itm.RightName == ch_itm.RightName
                      );

                      if (ch_itm.hasChild) {
                        newChild = [
                          ...newChild,
                          {
                            ...ch_itm,
                            child: newInnerChild,
                          },
                        ];
                      } else {
                        if (resDataCheck2.length) {
                          newChild = [
                            ...newChild,
                            {
                              ...ch_itm,
                              is_view: resDataCheck2[0].IsView,
                              is_create: resDataCheck2[0].IsCreate,
                              is_update: resDataCheck2[0].IsEdit,
                              is_delete: resDataCheck2[0].IsDelete,
                              is_export: resDataCheck2[0].IsExport,
                            },
                          ];
                        } else {
                          newChild = [
                            ...newChild,
                            {
                              ...ch_itm,
                              is_view: false,
                              is_create: false,
                              is_update: false,
                              is_delete: false,
                              is_export: false,
                            },
                          ];
                        }
                      }
                      newRights = {
                        ...newRights,
                        [key_id]: {
                          ...ENUMS.side_menu[key_id],
                          child: newChild,
                        },
                      };
                    });
                  } else {
                    let resDataCheck3 = resdata.data.data.dataList3[0].filter(
                      (itm) => itm.RightName == initialRights[key_id].RightName
                    );
                    if (resDataCheck3.length) {
                      newRights = {
                        ...newRights,
                        [key_id]: {
                          ...ENUMS.side_menu[key_id],
                          is_view: resDataCheck3.length
                            ? resDataCheck3[0].IsView
                            : initialRights[key_id].is_view,
                          is_create: resDataCheck3.length
                            ? resDataCheck3[0].IsCreate
                            : initialRights[key_id].is_create,
                          is_update: resDataCheck3.length
                            ? resDataCheck3[0].IsEdit
                            : initialRights[key_id].is_update,
                          is_delete: resDataCheck3.length
                            ? resDataCheck3[0].IsDelete
                            : initialRights[key_id].is_delete,
                          is_export: resDataCheck3.length
                            ? resDataCheck3[0].IsExport
                            : initialRights[key_id].is_export,
                        },
                      };
                    } else {
                      if (initialRights[key_id].RightName === "Dashboard") {
                        newRights = {
                          ...newRights,
                          [key_id]: {
                            ...ENUMS.side_menu[key_id],
                            is_view: true,
                            is_create: true,
                            is_update: true,
                            is_delete: true,
                            is_export: true,
                          },
                        };
                      } else {
                        newRights = {
                          ...newRights,
                          [key_id]: {
                            ...ENUMS.side_menu[key_id],
                            is_view: false,
                            is_create: false,
                            is_update: false,
                            is_delete: false,
                            is_export: false,
                          },
                        };
                      }
                    }
                  }
                });
                localStorage.setItem("sidemenu_rights", JSON.stringify(newRights));
                localStorage.setItem("user_rights", JSON.stringify(resdata.data.data.dataList3[0]));

                localStorage.setItem(
                  "AppLogo",
                  resdata.data.data.dataList2 && resdata.data.data.dataList2[0].logo !== null
                    ? resdata.data.data.dataList2[0].logo
                    : InntelligentHotel
                );
                dispatch(
                  isLogochangeData(
                    resdata.data.data.dataList2 && resdata.data.data.dataList2[0].logo !== null
                      ? resdata.data.data.dataList2[0].logo
                      : InntelligentHotel
                  )
                );
                dispatch(
                  updateUserData(
                    resdata.data.data,
                    resdata.data.data.dataList1[0],
                    resdata.data.data.dataList3[0],
                    newRights
                  )
                );
                navigate("/home");
              }
            }
          }
        }
      } else {
        ToasterError("Please Enter Valid Credentials");
      }
    }
  }

  function validate() {
    let isValid = true;
    const errors: any = {};
    if (!input.userName) {
      errors.userName = "Email";
      isValid = false;
    } else {
      errors.userName = "";
    }

    if (!input.password) {
      errors.password = "Password is required";
      isValid = false;
    } else {
      errors.password = "";
    }

    return isValid;
  }

  function showPassword() {
    setShowPassword(!ShowPassword);
  }

  const loginAsClient = async () => {
    const username = query_params.get("username");
    const pwd = query_params.get("pwd");
    const token = query_params.get("token");
    const clientid = query_params.get("clientid");

    if (username && pwd && token && clientid) {
      let data_log: any = await handleClientLogin(username, pwd, token, clientid);
      if (data_log) {
        if (
          data_log.data !== undefined &&
          data_log.data &&
          data_log.data.data &&
          data_log.data.data.data &&
          data_log.data.data.data.dataList5 &&
          data_log.data.data.data.dataList5[0] &&
          data_log.data.data.data.dataList5[0][0].IsAdminAccess === true
        ) {
          if (data_log.is_client_sel) {
            navigate("/clientSelection", {
              state: { data: data_log.data, inputValue: data_log.payload },
            });
            setClientLoginLoader(false);
          } else {
            dispatch(
              updateUserData(
                data_log.data.data.data,
                data_log.data.data.data.dataList1[0],
                data_log.data.data.data.dataList3[0],
                data_log.rights
              )
            );
            setClientLoginLoader(false);
            navigate("/home");
          }
        } else {
          localStorage.clear();
          Cookies.remove("SystemSettingDate");

          ToasterError(
            `Please contact client administrator.
        Client disabled setting which allows super administrator to login as client.`
          );
          navigate("/");
          setClientLoginLoader(false);
        }
      } else {
        navigate("/");
        setClientLoginLoader(false);
      }
    } else {
      setClientLoginLoader(false);
    }
  };
  useEffect(() => {
    loginAsClient();
  }, []);

  return !clientLoginLoader ? (
    <div className="login-section">
      <div className="login-title">
        Sign in to
        <span className="d-block">
          your account <span className="three-dots">...</span>
        </span>
      </div>

      <p className="login-description">
        Specific-Measureable-Action Plan Driven-Realistic-TIme Oriented Customer Relationship
        Management System, built by hoteliers for hoteliers, offering organization, automation and
        synchronization of all your sales offers via a cloud based platform.
      </p>
      <Form>
        <div className={`${input.errors.userName && "red-border-masking"}`}>
          <InputGroup className="">
            <InputGroup.Text id="basic-addon1">
              <span>
                <img src={LoginUserIcon} alt="" width={24} height={24} />
              </span>
            </InputGroup.Text>
            <FormControl
              type="email"
              autoComplete="new-password"
              placeholder="Username"
              aria-label="Username"
              name="userName"
              aria-describedby="basic-addon1"
              onChange={(e) =>
                setInput({
                  ...input,
                  [e.target.name]: e.target.value,
                })
              }
            />
          </InputGroup>
        </div>
        <small style={{ color: "#dc3545" }}>
          {" "}
          {input.errors.userName && input.errors.userName}
        </small>
        <br />
        <div className={`${input.errors.password && "red-border-masking mt-2"}`}>
          <InputGroup className="position-relative">
            <InputGroup.Text id="basic-addon1">
              <span>
                <img src={LoginKeyIcon} alt="" width={24} height={24} />
              </span>
            </InputGroup.Text>
            <FormControl
              onChange={(e) =>
                setInput({
                  ...input,
                  [e.target.name]: e.target.value,
                })
              }
              type={ShowPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              aria-label="Password"
              aria-describedby="basic-addon1"
              autoComplete="current-password"
            />

            <div className="eye-visible">
              {ShowPassword ? (
                <span
                  role="button"
                  tabIndex={-1}
                  onClick={() => showPassword()}
                  onKeyDown={() => showPassword()}
                >
                  <i className="fa-solid fa-eye-slash"></i>
                </span>
              ) : (
                <span
                  role="button"
                  tabIndex={-1}
                  onClick={() => showPassword()}
                  onKeyDown={() => showPassword()}
                >
                  <i className="fa-solid fa-eye"></i>
                </span>
              )}
            </div>
          </InputGroup>
        </div>
        <small style={{ color: "#dc3545" }}>
          {" "}
          {input.errors.password && input.errors.password}
        </small>
        <Form.Group className="form-button d-flex justify-content-between">
          {IsloginClick === false ? (
            <button onClick={loginClick} className="btn btn-primary" type="button">
              <span className="me-3">
                <img src={LoginButtonIcon} alt="" />
              </span>
              Login
            </button>
          ) : (
            <button className="btn btn-primary" type="button">
              <span className="me-3">
                <i className="fa-duotone fa-spinner fa-spin"></i>
              </span>
              Login...
            </button>
          )}
          <Link className="forgot-link d-flex align-items-center" to="/forgotPassword">
            Forgot Password ?
          </Link>
        </Form.Group>
      </Form>
    </div>
  ) : (
    <Loader />
  );
}

export default AuthLogin;
