import React, { useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { ToasterError, ToasterSuccess } from "../../Services/CommonServices";
import {
  GetImportSampleFile,
  ImportLNROpportunities,
} from "../../Services/ImportServises/ImportServises";
import { useSelector } from "react-redux";
import exporticon from "../../Asset/Images/Icons/exporticon.svg";
import ImportLNRModal from "../../Model/ImportModal/ImportLNRModal";

const LNRImport = () => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [uploadLoading, setUploadLoading] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [ModuleData, setModuleData]: any = useState({});
  const [modalShow, setModalShow]: any = useState(false);

  const [formInputs, setFormInputs] = useState<any>({
    FormFiles: [],
  });

  const attachFileRef = useRef<any>(null);

  const handleAttachmentFile = (file_res) => {
    setUploadLoading(true);
    let isValidFile = true;
    let totalSize = 0;
    let isLenValid = true;
    file_res.forEach((file_el) => {
      totalSize = totalSize + parseFloat((file_el.size / 1000000).toFixed(2));
      if (!file_el.name.match(/\.(xls|xlsx)$/)) {
        isValidFile = false;
        setUploadLoading(false);
      }
    });

    if (file_res.length > 5) {
      isLenValid = false;
      ToasterError("Maximum 5 files are allowed.");
      setUploadLoading(false);
    } else {
      if (!isValidFile) {
        ToasterError(" Only .xls, .xlsx file only");
        setUploadLoading(false);
      } else {
        if (totalSize >= 25) {
          ToasterError("Total files size should be less than 25MB.");
          setUploadLoading(false);
        }
      }
    }

    if (isValidFile && totalSize < 25 && isLenValid) {
      setFormInputs({
        ...formInputs,
        FormFiles: [...formInputs.FormFiles, ...file_res],
      });
      setUploadLoading(false);
    }
  };

  const handleRemoveFile = (file_data, ind) => {
    let newFilesArray: any = [];
    formInputs.FormFiles.forEach((element_file, i) => {
      if (ind != i) {
        newFilesArray = [...newFilesArray, element_file];
      }
    });
    setFormInputs({
      ...formInputs,
      FormFiles: newFilesArray,
    });
  };

  async function SampleFileDownload() {
    let input: any = {
      ModuleName: "LNR",
    };

    let res: any = await GetImportSampleFile(input, Token);
    if (res.data !== undefined && res.data !== null) {
      let Message = `LNR Sample Download SuccessFully`;
      if (res.data.statusCode === 200) {
        window.open(res.data.data);
        ToasterSuccess(Message);
      } else {
        ToasterError(res.data.message);
      }
    } else {
      ToasterError(res.message);
    }
  }

  async function handleImportFile() {
    let formData: any = new FormData();
    formData.append("file", formInputs.FormFiles[0]);
    let res: any;
    setImportLoading(true);
    res = await ImportLNROpportunities(formData, Token);
    setImportLoading(false);
    if (res.data !== undefined && res.data !== null) {
      if (res.data.statusCode === 200) {
        setModuleData(res.data.data);
        setModalShow(true);
      } else {
        ToasterError(res.data.message);
      }
    } else {
      ToasterError(res.message);
    }
  }

  return (
    <>
      <div
        id="contained-modal-title-vcenter"
        className="d-flex align-items-center justify-content-between w-100"
      ></div>
      <>
        <div className="upload-file-col w-50">
          {formInputs.FormFiles.length ? (
            <>
              <div className="attach-parent-aac-files">
                {formInputs.FormFiles.map((file_itm, i) => {
                  return (
                    <>
                      <div className="attach-parent-aac-file">
                        <div className="attach-parent-aac-file-section">
                          <div className="attach-parent-image">
                            {file_itm.name && file_itm.name.split(".")[1] === "xls" && (
                              <img src={exporticon} alt="" />
                            )}
                            {file_itm.name && file_itm.name.split(".")[1] === "xlsx" && (
                              <img src={exporticon} alt="" />
                            )}
                          </div>
                          <div
                            onClick={() => {
                              handleRemoveFile(file_itm, i);
                            }}
                            className="btn-icon-grp-table close-icon"
                          >
                            <Button className="btn-icon cancelIcon"></Button>
                          </div>
                        </div>
                        <div className="attach-parent-child">
                          <div title={file_itm.name} className="attach-name">
                            {file_itm.name}
                          </div>
                          <div className="attach-size">
                            {parseFloat((file_itm.size / 1000000).toFixed(2))} MB
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
              {/* Upload Button */}
              <div className="attach-parent-aac-file-button mt-auto">
                {!uploadLoading ? (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      handleImportFile();
                    }}
                    className="btn btn-primary w-100 justify-content-center"
                    type="button"
                    disabled={importLoading}
                  >
                    Import File
                  </Button>
                ) : (
                  <Button className="btn btn-primary w-100 justify-content-center" type="button">
                    <Spinner size="sm" animation="border" variant="light" />
                  </Button>
                )}
              </div>
            </>
          ) : (
            <>
              {/* Upload Doc's */}
              <div className="upload-file-top-text">Select and drop ".xls , .xlsx" file only</div>
              <div
                onClick={() => {
                  attachFileRef.current.click();
                }}
                onDragOver={(e: any) => {
                  e.preventDefault();
                }}
                onDrop={(e: any) => {
                  e.preventDefault();
                  handleAttachmentFile(Array.from(e.dataTransfer.files));
                }}
                className="upload-file-top-middle"
              >
                Drop Files to upload
                <span className="d-block">(or click)</span>
              </div>
              <input
                type="file"
                accept=".xls , .xlsx"
                onChange={(e: any) => {
                  e.preventDefault();
                  handleAttachmentFile(Array.from(e.target.files));
                }}
                ref={attachFileRef}
                // multiple={true}
                className="upload-file-field"
              />
            </>
          )}
        </div>
        <div className="mt-3 w-50">
          <span>
            <Button variant="primary" onClick={() => SampleFileDownload()}>
              <span className="me-2">{/* <img src={WhiteSaveIcon} alt="" /> */}</span>
              Sample File
            </Button>
          </span>
        </div>

        {modalShow && (
          <ImportLNRModal
            ModuleData={ModuleData}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
      </>
    </>
  );
};

export default LNRImport;
