import React from "react";
import { matchSorter } from "match-sorter";
import Table from "react-bootstrap/Table";
import Skeleton from "react-loading-skeleton";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { useSticky } from "react-table-sticky";
import BothSort from "../../Asset/Images/Icons/bothSort.svg";
import SortDown from "../../Asset/Images/Icons/sortDown.svg";
import SortUp from "../../Asset/Images/Icons/sortUp.svg";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      className="table-search form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row: any) => row.values[id]] });
}
// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function TableWithoutPagginationComponent({ columns, data, isData }) {
  if (isData === undefined || isData === null) {
    isData = true;
  }

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 500000 },
    },
    useFilters,
    useSortBy,
    usePagination,
    useSticky
  );

  return (
    <>
      <Table responsive className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, keyTr) => (
            //  <tr>
            //         <th>
            //           <div>{column.canFilter ? column.render("Filter") : null}</div>
            //         </th>
            //       </tr>
            <tr {...headerGroup.getHeaderGroupProps()} key={keyTr}>
              {headerGroup.headers.map((column: any, keyMain) => (
                <th
                  className={column.className}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={keyMain}
                >
                  <span className="d-flex align-items-center">
                    {column.render("Header")}
                    <span className="text-sort-icon">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img src={SortDown} alt="" />
                        ) : (
                          <img src={SortUp} alt="" />
                        )
                      ) : (
                        <img src={BothSort} alt="" />
                      )}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
          {headerGroups.map((headerGroup, keyTr) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={keyTr}>
              {headerGroup.headers.map(
                (column, keyMain) =>
                  column.Header !== "Action" && (
                    <th key={keyMain}>
                      <div>{column.canFilter ? column.render("Filter") : null}</div>
                    </th>
                  )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {isData
            ? headerGroups.map((headerGroup, keyTr) => (
                <tr
                  className="Table-Searching-Data"
                  {...headerGroup.getHeaderGroupProps()}
                  key={keyTr}
                >
                  <td colSpan={headerGroup.headers.length} className="no-bg">
                    <Skeleton count={10} height={30} />
                  </td>
                </tr>
              ))
            : page.length !== 0
            ? page.map((row: any, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={i}>
                    {row.cells.map((cell, j) => {
                      return (
                        <td
                          className={`${
                            row.original.className !== undefined &&
                            row.original.className !== null &&
                            row.original.className[cell.column.id] !== undefined &&
                            row.original.className[cell.column.id] !== null
                              ? row.original.className[cell.column.id]
                              : ""
                          }`}
                          {...cell.getCellProps()}
                          key={j}
                        >
                          {row.original.controlRender !== undefined &&
                          row.original.controlRender !== null &&
                          row.original.controlRender[cell.column.id] !== undefined &&
                          row.original.controlRender[cell.column.id] !== null
                            ? row.original.controlRender[cell.column.id]
                            : cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            : headerGroups.map((headerGroup, keyTr) => (
                <tr
                  className="Table-Searching-Data"
                  {...headerGroup.getHeaderGroupProps()}
                  key={keyTr}
                >
                  <td colSpan={headerGroup.headers.length} className="no-bg">
                    <div className="noRecordFound">
                      <img src={noRecordFound} alt="" />
                    </div>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
      {/* <div className="pagination">
          <div>
            <span>
              Page {pageIndex + 1} of {pageOptions.length}
            </span>
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
  
          <div className="d-flex">
            <span className="d-flex align-items-center">
              <span className="page-num">Page no.</span>
              <input
                type="number"
onWheel={(event: any) => { event.target.blur() }}
                    onKeyDown={(e :any )=>{                       
                                             if(e.keyCode == '38' || e.keyCode == '40'){
                                                 e.stopPropagation()
                                                 e.preventDefault()
                                             }
                                         }}
                defaultValue={pageIndex + 1}
                pattern="^[0-9]"
                min="1"
                step="1"
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <i className="fa-solid fa-backward"></i>
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              <i className="fa-solid fa-caret-left"></i>
            </button>
  
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              <i className="fa-solid fa-caret-right"></i>
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <i className="fa-solid fa-forward"></i>
            </button>
          </div>
        </div> */}
    </>
  );
}
export default function TableWithoutPaggination(props: any) {
  return (
    <TableWithoutPagginationComponent
      isData={props.isData}
      columns={props.columnsData}
      data={props.tableData}
    />
  );
}
