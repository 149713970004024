import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GreenLabel from "../../../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../../../Component/Labels/RedLabel/Index";
import TableView from "../../../../Component/Table/Index";
import { profileGetActivities } from "../../../../Services/ProfileServices";
import { getDateInFormat, getDateTimeInDBFormat } from "../../../../Services/CommonServices";
export default function Overview() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const overviewTableColumns = React.useMemo(
    () => [
      {
        Header: "Objective",
        accessor: "objective",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "End Date",
        accessor: "endDate",
      },
    ],
    []
  );

  const overviewTableData: any = [];
  data.map((itm: any) => {
    overviewTableData.push({
      controlRender: {
        startDate: getDateInFormat(itm.StartDate),
        endDate: getDateInFormat(itm.EndDate),
      },
      objective: itm.ActivitySubject,
      status:
        itm.ActivityStatus_Term === "Complete" ? (
          <GreenLabel successLabel="Completed" />
        ) : (
          <RedLabel dangerLabel="Open" />
        ),
      type: itm.ActivityType_Term,
      startDate: getDateTimeInDBFormat(itm.StartDate),
      endDate: getDateTimeInDBFormat(itm.EndDate),
    });
  });

  const fetchData = async () => {
    let input = {
      keyID: userId,
    };
    let res: any = await profileGetActivities(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== null && typeof res.data.data !== "undefined") {
          setData(res.data.data.dataList1);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableView
      columnsData={overviewTableColumns}
      tableData={overviewTableData}
      isData={isLoading}
    />
  );
}
