import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import RoundKnob from "../../Component/Knob/Index";
import BlueLabel from "../../Component/Labels/BlueLabel/Index";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import TableView from "../../Component/Table/Index";
import { getSelectedAccount } from "../../Redux/actions";
import {
  getAccountCountList,
  getAccountList,
  getSelectedAccountDetails,
} from "../../Services/LeftMenuServices";

function AccountsInactive() {
  let [AccountCount, setAccountCount] = React.useState({
    maintenanceAccount: 0,
    newAccounts: 0,
    noActivityAccount: 0,
    totalAccounts: 0,
  });
  const [roundKnobValue] = useState(100);
  const [roundKnobValue1, setroundKnobValue1] = useState(0);
  const [roundKnobValue2, setroundKnobValue2] = useState(0);
  const dataSelector: any = useSelector((state: any) => state.session);
  const isData = dataSelector.auth_UserDetail;
  const AllPropertyID: any = localStorage.getItem("All Hotels");
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const [IsClassworkListDateLodding, setIsClassworkListDateLodding] = useState(false);
  const Token = dataSelector.access_Token;

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let SearchedAlphabet = "";
  let NewAccounts = 0;
  let NoActivity = 0;
  const mainTableColumns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Top",
        accessor: "top",
      },
      {
        Header: "Target",
        accessor: "target",
      },
      {
        Header: "Growth",
        accessor: "growth",
      },
      {
        Header: "Anticipated Revenue(C.Y.)",
        accessor: "anticipatedRevenue",
      },
      {
        Header: "Last Activity",
        accessor: "lastActivity",
      },
    ],
    []
  );
  let [AccountList, setAccountList] = React.useState([
    {
      accountCode: "",
      accountID: "",
      accountName: "",
      accountType_Term: "",
      anticipatedRev: "$ 0.00",
      city: "",
      clientID: "",
      companyPhone: null,
      isActive: true,
      isTargetAccount: false,
      isTargetGrowthAccount: false,
      isTop: false,
      lastActivityDate: "",
      listCount: 1,
      maxAnticipatedRev: 0,
      ownerName: "",
      propertyID: "",
      shippingStreet: null,
      state: "",
      totalCount: "",
      zipCode: "",
    },
  ]);
  function fnStatusLabel(status) {
    if (status.includes("Inactive") === true) {
      return <RedLabel dangerLabel={"In Active"} />;
    } else if (status.includes("Prospect") === true) {
      return <BlueLabel blueLabel={status} />;
    } else if (status.includes("Active") === true) {
      return <GreenLabel successLabel={status} />;
    } else {
      return "";
    }
  }
  let mainTableData: any = [];

  AccountList.map((item) => {
    mainTableData.push({
      code: item.accountCode,
      accountName: (
        <Link to="" onClick={() => getAccountData(item)}>
          {item.accountName}
        </Link>
      ),
      owner: item.ownerName,
      status: fnStatusLabel(item.accountType_Term),
      top: (
        <Form.Check
          inline
          name="group1"
          type="checkbox"
          checked={item.isTop}
          id="inline-checkbox-1"
        />
      ),
      target: (
        <Form.Check
          inline
          name="group1"
          type="checkbox"
          checked={item.isTargetAccount}
          id="inline-checkbox-1"
        />
      ),
      growth: (
        <Form.Check
          inline
          name="group1"
          type="checkbox"
          checked={item.isTargetGrowthAccount}
          id="inline-checkbox-1"
        />
      ),
      anticipatedRevenue: "$" + item.maxAnticipatedRev,
      lastActivity: item.lastActivityDate,
    });
  });

  React.useEffect(() => {}, []);

  async function getAccountData(data: any) {
    let input = {
      accountID: data.accountID,
    };

    let res: any = await getSelectedAccountDetails(input, Token);
    dispatch(getSelectedAccount(res));

    navigate("/accounts/details");
  }

  async function abcdBar(data) {
    SearchedAlphabet = data;
    let input = {
      clientID: "",
      userID: null,
      propertyID: "",
      accountType_Term: "Inactive",
      exporttype: "AccountList",
      displayLength: 20000,
      displayStart: 0,
      sortcolumn: 1,
      sortdir: "asc",
      search: "",
      accountCodeSearch: "",
      accountNameSearch: "",
      accountOwnerSearch: "",
      accountStatusSearch: "",
      anticipatedRevenueFrom: null,
      anticipatedRevenueTo: null,
      lastActivityDateSearch: "",
      searchChar: "",
      isTop: "",
      isTargetAccount: "",
      isTargetGrowthAccount: "",
      singleCharSearch: "",
    };

    input.clientID = isData.C_ClientID;
    if (SearchedAlphabet !== "All") {
      input.searchChar = SearchedAlphabet;
    } else {
      input.searchChar = "All";
    }

    input.propertyID = AllPropertyID;
    const res: any = await getAccountList(input, Token);
    setAccountList(res.data.data.accounts);
  }

  return (
    <>
      <div className="main-section">
        <Container fluid={true} className="p-0">
          <Row>
            <Col xs={12} md={6} lg={4}>
              <div className="roundknob d-flex align-items-center justify-content-center">
                <RoundKnob roundKnobValue={roundKnobValue} />
                <div className="total-values first-card">
                  <h4>Total Accounts</h4>
                  <h2>{AccountCount.totalAccounts}</h2>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="roundknob d-flex align-items-center justify-content-center">
                <RoundKnob roundKnobValue={roundKnobValue1} />
                <div className="total-values">
                  <h4>New Accounts last 30 Days</h4>
                  <h2>{AccountCount.newAccounts}</h2>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} lg={4}>
              <div className="roundknob d-flex align-items-center justify-content-center">
                <RoundKnob roundKnobValue={roundKnobValue2} />
                <div className="total-values">
                  <h4>No Activity in Past Year</h4>
                  <h2>{AccountCount.noActivityAccount}</h2>
                </div>
              </div>
            </Col>
          </Row>
          <div className="abcd-pill flex-wrap d-flex align-items-center justify-content-center mt-4">
            <button onClick={() => abcdBar("A")} className="mt-2">
              A
            </button>
            <button onClick={() => abcdBar("B")} className="mt-2">
              B
            </button>
            <button onClick={() => abcdBar("C")} className="mt-2">
              C
            </button>
            <button onClick={() => abcdBar("D")} className="mt-2">
              D
            </button>
            <button onClick={() => abcdBar("E")} className="mt-2">
              E
            </button>
            <button onClick={() => abcdBar("F")} className="mt-2">
              F
            </button>
            <button onClick={() => abcdBar("G")} className="mt-2">
              G
            </button>
            <button onClick={() => abcdBar("H")} className="mt-2">
              H
            </button>
            <button onClick={() => abcdBar("I")} className="mt-2">
              I
            </button>
            <button onClick={() => abcdBar("J")} className="mt-2">
              J
            </button>
            <button onClick={() => abcdBar("K")} className="mt-2">
              K
            </button>
            <button onClick={() => abcdBar("L")} className="mt-2">
              L
            </button>
            <button onClick={() => abcdBar("M")} className="mt-2">
              M
            </button>
            <button onClick={() => abcdBar("N")} className="mt-2">
              N
            </button>
            <button onClick={() => abcdBar("O")} className="mt-2">
              O
            </button>
            <button onClick={() => abcdBar("P")} className="mt-2">
              P
            </button>
            <button onClick={() => abcdBar("Q")} className="mt-2">
              Q
            </button>
            <button onClick={() => abcdBar("R")} className="mt-2">
              R
            </button>
            <button onClick={() => abcdBar("S")} className="mt-2">
              S
            </button>
            <button onClick={() => abcdBar("T")} className="mt-2">
              T
            </button>
            <button onClick={() => abcdBar("U")} className="mt-2">
              U
            </button>
            <button onClick={() => abcdBar("V")} className="mt-2">
              V
            </button>
            <button onClick={() => abcdBar("W")} className="mt-2">
              W
            </button>
            <button onClick={() => abcdBar("X")} className="mt-2">
              X
            </button>
            <button onClick={() => abcdBar("Y")} className="mt-2">
              Y
            </button>
            <button onClick={() => abcdBar("Z")} className="mt-2">
              Z
            </button>
            <button onClick={() => abcdBar("All")} className="all-button mt-2">
              All
            </button>
          </div>
          <Row className="mt-3">
            {IsClassworkListDateLodding === false ? (
              <TableView columnsData={mainTableColumns} tableData={mainTableData} />
            ) : (
              <Skeleton count={5} height={32} />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AccountsInactive;
