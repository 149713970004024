import React, { useState } from "react";
import { Button, Form, Modal, Table, FloatingLabel } from "react-bootstrap";
import { ENUMS } from "../../Constants";
import { ToasterError, getBase64 } from "../../Services/CommonServices";
import { toast } from "react-toastify";

export const UserProfileAvatar = (props) => {
  const urlToObject = async (imageurl) => {
    const response = await fetch(imageurl);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], "image.jpg", { type: blob.type });
    return file;
  };

  function SelectLogo(e: any) {
    let file: any = URL.createObjectURL(e.target.files[0]);
    if (e.target.files[0].name.match(/\.(jpg|jpeg|png|webp)$/)) {
      props.setFormInputs({
        ...props.formInputs,
        FormFiles: e.target.files[0],
        image_url: file,
      });

      props.setLogo(e.target.files[0]);
      props.setLogoUpload(file);

      props.onHide(true);
      return 0;
    } else {
      ToasterError("Please select .jpg|.jpeg|.png|.webp");
    }
  }

  async function handleImage(e) {
    let file = await urlToObject(e.target.src);
    getBase64(file)
      .then((result) => {
        props.setFormInputs({
          ...props.formInputs,
          FormFiles: file,
          image_url: result,
        });
        props.setLogo(file);
        props.setLogoUpload(result);
      })
      .catch((err) => {
        err;
      });
    props.onHide(true);
    return 0;
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-30w"
      className="quickAdd-popup"
      scrollable={true}
      //   onHide={handleClose}
    >
      <Modal.Header closeButton>Choose Avatar</Modal.Header>
      <Modal.Body>
        <div className="row" style={{ display: "flex" }}>
          {ENUMS.AvtarList.map((itm, idx) => (
            <img
              key={idx}
              src={itm}
              style={{
                margin: "10px",
                minWidth: "90px",
                minHeight: "90px",
                maxWidth: "90px",
                maxHeight: "90px",
              }}
              alt={"Userlogo"}
              onClick={(e) => handleImage(e)}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-3">
          <input accept=".jpg,.jpeg,.png,.webp" type="file" onChange={SelectLogo} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};
