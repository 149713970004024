import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import { toast } from "react-toastify";
import { getUserRoleList } from "../Services/Setup&Config/UserSetupService";
import { useSelector } from "react-redux";

import PropertySalesGroupSettings from "../Component/SalesGroupSettings/PropertySalesGroupSettings";

export default function ProdGoals({
  showProdGoalsModal,
  setShowProdGoalsModal,
  prodInputs,
  setProdInputs,
  prodInputsEr,
  setProdInputsEr,
}) {
  
  const [rolesOptions, setRolesOptions] = useState([]);

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  const validateForm = () => {
    let isValid = true;
    let error_json = {};
    Object.keys(prodInputsEr).forEach((htl_id) => {
      error_json = {
        ...error_json,
        [htl_id]: {
          propertyID: "",
          // roleID: "",
        },
      };
    });
    Object.keys(error_json).forEach((er_ht_key) => {
      Object.keys(error_json[er_ht_key]).forEach((inner_key) => {
        if (!prodInputs[er_ht_key][inner_key] && prodInputs[er_ht_key][inner_key] === "") {
          error_json = {
            ...error_json,
            [er_ht_key]: {
              ...error_json[er_ht_key],
              [inner_key]: "This field is required.",
            },
          };
          isValid = false;
        }
      });
    });
    setProdInputsEr(error_json);
    return isValid;
  };

  const handleClosePopup = () => {
    if (validateForm()) {
      setShowProdGoalsModal(false);
    }
  };

  const handleClosePopupWihtoutSave = () => {
    // setProdInputs({})
    setShowProdGoalsModal(false);
    // setProdInputsEr({})
  };

  const userRoleList = async () => {
    let inputs = {
      roleCode: null,
      roleName: null,
      clientID: clientID,
    };
    const res: any = await getUserRoleList(inputs, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        let iniRoleList: any = [];
        res.data.data.dataList1.forEach((elm) => {
          if (elm.AssociationWithProperty) {
            iniRoleList = [
              ...iniRoleList,
              {
                label: elm.RoleName,
                // value: elm.RoleID
              },
            ];
          }
        });
        setRolesOptions(iniRoleList);
      }
    }
  };

  useEffect(() => {
    // userRoleList();
  }, []);

  return (
    <>
      <Modal
        show={showProdGoalsModal}
        onHide={() => handleClosePopupWihtoutSave()}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup user_hotel_center"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Hotels Role and Establish Monthly Productivity Goals
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {Object.keys(prodInputs).map((htl_id, i) => {
              return (
                <PropertySalesGroupSettings
                  key={i}
                  prodInputsEr={prodInputsEr}
                  prodInputs={prodInputs}
                  setProdInputs={setProdInputs}
                  rolesOptions={rolesOptions}
                  htl_id={htl_id}
                />
              );
            })}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              handleClosePopupWihtoutSave();
            }}
          >
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            type="button"
            onClick={(e) => {
              handleClosePopup();
            }}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
