import React, { useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import SquarePlus from "../Asset/Images/Icons/square-plus.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../Component/Loader/PageLoader";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import CreatableSelect from "react-select/creatable";

const RoomTypeTaxDetailModal = (props: any) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [inputList, setInputList]: any = useState([
    // { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, TaxRowNo: 0 },
  ]);
  const [IsvalidState, setIsvalidState] = React.useState({ status: false, Value: "" });

  const [HotelTaxDropdownList, setHotelTaxDropdownList] = useState([]);
  const [loading, setLoading] = React.useState(false);
  let validationJson = {
    taxTitle: "",
    rateOrPercentage: "",
  };
  const [inputListEr, setInputListEr]: any = useState([validationJson]);

  React.useEffect(() => {
    getHotelTaxDropdown();
    if (
      props.StoreEditRoomTypeTaxdata.length > 0 &&
      props.StoreEditRoomTypeTaxdata !== undefined &&
      props.StoreEditRoomTypeTaxdata !== null
    ) {
      let JsonToIm: any = [];
      let JsonObj: any = {};

      let JsonToImEr: any = [];
      let JsonObjEr: any = {};

      props.StoreEditRoomTypeTaxdata.map((item) => {
        JsonObj = {
          ...JsonObj,
          taxTitle: item.taxTitle,
          rateOrPercentage: item.rateOrPercentage,
          orderNo: item.orderNo,
          isPercentage: item.isPercentage,
          taxRowNo: props.TaxRoomTypeRowNo,
        };
        JsonToIm.push(JsonObj);
        JsonObjEr = {
          ...JsonObjEr,
          taxTitle: "",
          rateOrPercentage: "",
        };
        JsonToImEr.push(JsonObjEr);
      });
      if (JsonToIm.length > 0) {
        setInputList(JsonToIm);
        setInputListEr(JsonToImEr);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let optionsTax: any = [
    {
      value: null,
      label: "Select Service Tax",
     
    },
  ];
  HotelTaxDropdownList.map((item: any) => {
    optionsTax.push({
      label: item.Term,
      value: item.Term,
      node: "Creatable"
    });
  });
  const handleAddROOMRowClick = () => {
    setInputList([
      ...inputList,
      { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, TaxRowNo: 0 },
    ]);
    setInputListEr([...inputListEr, { taxTitle: "", rateOrPercentage: "" }]);
  };
  const handleInputRoomChange = (event, index) => {


    if (event === null) {
      const list: any = [...inputList];

      list[index]["taxTitle"] = "";
    }
     else if (event.node === "Creatable") {
      if (event.value !== null) {
        const list: any = [...inputList];

        let value = event.value.trim();
        list[index]["taxTitle"] = value;
      }
    } 
    else if (event.__isNew__) 
    {
      if (event.value !== null && event.value !== "") {
        const list: any = [...inputList];
        let value = event.value.trim();
        list[index]["taxTitle"] = value;
      }
    } 
    else if (event.target && event.target.nodeName !== undefined && event.target.nodeName === "SELECT")
     {
      const list: any = [...inputList];
      if(event.target.value === "Yes"){
        list[index]["isPercentage"] =true;
      }
    }
     else {
      if (event.value !== null) {
        
        let { name, value }: any = event.target;
        const list = [...inputList];

        value = value.trim();
        list[index][name] = value;

        if (value) {
          let pattern: any = /^\d+(\.)?\d*%?$/;
          if (pattern.test(value)) {
            if (value !== null && value !== "" && value !== undefined) {
              if (value.includes("%")) {
                list[index]["isPercentage"] = true;
                list[index]["rateOrPercentage"] = value ? value : "";
              } 
              // else {
              //   list[index]["isPercentage"] = false;
              //   list[index]["rateOrPercentage"] = value ? value : "";
              // }
            }
            list[index]["taxRowNo"] = props.TaxRowNo;
            list[index]["orderNo"] = index;
            setIsvalidState({ status: false, Value: "" });
            setInputList(list);
          } else {
            setIsvalidState({ status: true, Value: value });
            list[index][name] = "";
            setInputList(list);
          }
        } else {
          list[index][name] = value;
          setInputList(list);
        }
      }
    }
  };
  // const handleInputRoomChange = (event, index) => {
  //   let { name, value }: any = event.target;
  //   const list = [...inputList];
  //   value = value.trim();
  //   list[index][name] = value;

  //   if(event.target.nodeName !== "SELECT"){
  //   if (value) {
  //     let pattern: any = /^\d+(\.)?\d*%?$/;
  //     if (pattern.test(value)) {
  //       if (value !== null && value !== "" && value !== undefined) {
  //         if (value.includes("%")) {
  //           list[index]["isPercentage"] = true;
  //           list[index]["rateOrPercentage"] = value ? value : "";
  //         } else {
  //           list[index]["isPercentage"] = false;
  //           list[index]["rateOrPercentage"] = value ? value : "";
  //         }
  //       }
  //       list[index]["taxRowNo"] = props.TaxRowNo;
  //       list[index]["orderNo"] = index;
  //       setIsvalidState({ status: false, Value: "" });
  //       setInputList(list);
  //     } else {
  //       setIsvalidState({ status: true, Value: value });
  //       list[index][name] = "";
  //       setInputList(list);
  //     }
  //   } else {
  //     list[index][name] = value;
  //     setInputList(list);
  //   }}
  // };

  function CheckKeyEvent(event) {
    if (
      (event.charCode >= 48 && event.charCode <= 57) ||
      event.charCode === 46 ||
      event.charCode === 37
    ) {
      if (event.charCode === 46) {
        if (event.target.value.indexOf(".") >= 0) {
          event.preventDefault();
        }
      }

      if (event.charCode === 37) {
        if (event.target.value.indexOf("%") >= 0) {
          event.preventDefault();
        }
      }
    } else {
      event.preventDefault();
    }
  }
  const validateForm = () => {
    let is_validate = true;
    let newInputEr = [...inputListEr];
    inputList.forEach((itm, i) => {
      Object.keys(validationJson).forEach((key) => {
        if ((!itm[key] && parseInt(itm[key]) !== 0) || itm[key] === "") {
          is_validate = false;
          newInputEr[i][key] = "This field is required.";
        } else {
          newInputEr[i][key] = "";
        }
      });
    });
    setInputListEr(newInputEr);
    return is_validate;
  };

  function GetValue() {
    if (validateForm()) {
      props.storeRoomTypeTax(props.TaxRoomTypeRowNo, inputList);
      setInputList([
        ...inputList,
        { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, taxRowNo: 0 },
      ]);
      setInputListEr([...inputListEr, { taxTitle: "", rateOrPercentage: "" }]);
      props.onHide(false);
    }
  }
  function RemoveRoomTypeTaxRow(id: any) {
    const list = [...inputList];
    const listER = [...inputListEr];

    if (id !== null && id !== "" && id !== undefined) {
      list.splice(id, 1);
      listER.splice(id, 1);
    }

    setInputList(list);
    setInputListEr(listER);
  }
  async function getHotelTaxDropdown() {
    let inputs = {
      category: "Hotel Tax",
      clientID: clientID,
    };
    setLoading(true);
    const resDropdown: any = await getProjectTermByCategory(inputs, Token);
    setLoading(false);
    if (resDropdown.data.success) {
      if (resDropdown.data !== null && resDropdown.data !== undefined) {
        if (resDropdown.data.data !== undefined && resDropdown.data.data !== null) {
          setHotelTaxDropdownList(resDropdown.data.data.dataList1);
        }
      }
    }
  }
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="tax-information-popup quickAdd-popup"
        scrollable={true}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.RoomNamePass && props.RoomNamePass + " -"} Tax Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="tax-information customtablemetting">
            {!loading ? (
              <Table className="table-without-space mb-0" responsive bordered>
                <thead>
                  <tr>
                    <th className="settitle w-50">Type of Tax</th>
                    <th className="settitle w-30">Tax Type</th>
                    <th className="settitle w-50">Tax</th>
                    <th className="settitle w-50">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inputList.map((x, i) => {
                    
                    return (
                      <>
                        <tr>
                          <td className="w-300">
                            <div
                              className={`${
                                inputListEr[i].taxTitle !== "" &&
                                inputListEr[i].taxTitle !== undefined &&
                                "red-border-masking"
                              }`}
                            >
                              <CreatableSelect
                               menuPortalTarget={document.body}
                               styles={{
                                   menuPortal: (provided) => ({
                                   ...provided,
                                   zIndex: 99999999,
                                   }),
                                   menu: (base) => ({
                                       ...base,
                                       zIndex: 2,
                                     }),
                               }}
                                placeholder={"Select Tax"}
                                isClearable
                                options={optionsTax}
                                onChange={(event: any) => handleInputRoomChange(event, i)}
                                className="ClassSelectCreatable bordered"
                                defaultValue={
                                  x.taxTitle !== ""
                                    ? {
                                        label: x.taxTitle,
                                        value: x.taxTitle,
                                      }
                                    : {
                                        label: "Select Tax",
                                        value: "Select Tax",
                                      }
                                }
                              />
                              {/* <Form.Select
                                aria-label="Default select example"
                                name="taxTitle"
                                onChange={(e) => handleInputRoomChange(e, i)}
                                disabled={props && props.details === true ? true : false}
                              >
                                <option>Select Service Tax</option>
                                {HotelTaxDropdownList.map((item: any, index) => (
                                  <option
                                    selected={item.Term === x.taxTitle ? true : false}
                                    key={index}
                                    value={item.Term}
                                  >
                                    {item.Term}
                                  </option>
                                ))}
                              </Form.Select> */}
                            </div>
                            {/* <small style={{ color: "#dc3545" }}>
                              {inputListEr[i].taxTitle !== "" && inputListEr[i].taxTitle !== undefined ? inputListEr[i].taxTitle : ""}
                            </small> */}
                          </td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="ispercentage"
                              
                              onChange={(e) =>
                                handleInputRoomChange(e, i)
                              }
                            >
                              <option selected={x.isPercentage === true && "Yes"} value="Yes">%</option>
                              <option selected={x.isPercentage === false && "No"} value="No">$</option>
                            </Form.Select>
                          </td>
                          <td className="bordernone">
                            <Form.Group>
                              <Form.Control
                                className={`multiselect ${
                                  inputListEr[i].rateOrPercentage !== "" && "is-invalid"
                                }`}
                                type="text"
                                maxLength={256}
                                value={x.rateOrPercentage}
                                name="rateOrPercentage"
                                onKeyPress={(e) => CheckKeyEvent(e)}
                                onChange={(e) => handleInputRoomChange(e, i)}
                              />
                              {IsvalidState.status === true && (
                                <Form.Control.Feedback type="invalid">
                                  {"Invalid Input!! (" + IsvalidState.Value + ")"}
                                </Form.Control.Feedback>
                              )}
                            </Form.Group>
                          </td>
                          <td>
                            <span className="btn-icon-grp-table justify-content-end">
                              <Button
                                className="btn-icon cancelIcon"
                                onClick={() => RemoveRoomTypeTaxRow(i)}
                              ></Button>
                            </span>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                  {/* {inputList.length - 1 === i && ( */}
                  <tr>
                    <td colSpan={4} className="bluetext">
                      <div
                        role="button"
                        tabIndex={-1}
                        onClick={handleAddROOMRowClick}
                        onKeyDown={handleAddROOMRowClick}
                      >
                        <img src={SquarePlus} alt="" width={15} height={15} className="me-1" />
                        Add More
                      </div>
                    </td>
                  </tr>
                  {/* )} */}
                </tbody>
              </Table>
            ) : (
              <PageLoader />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button variant="primary" onClick={() => GetValue()}>
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default RoomTypeTaxDetailModal;
