import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import WhiteSaveIcon from "../../../../Asset/Images/Icons/whiteSaveIcon.svg";
import Avatar from "../../../../Asset/Images/Profile/Avatar.png";
import { UserProfileAvatar } from "../../../../Model/AvtarModal/UserProfileAvtar";
import { updateAuthUserDetails, updateRingCentralPermission } from "../../../../Redux/actions";
import {
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
} from "../../../../Services/CommonServices";
import { updateUser } from "../../../../Services/ProfileServices";
import { getSingleUserSelectedData } from "../../../../Services/Setup&Config/UserSetupService";

export const ProfileDetails = () => {
  const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
  const CryptoJS: any = require("crypto-js");

  const dataSelector: any = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  let ringCEntral_permission: any = dataSelector.update_ringCEntral_permission;

  const Token = dataSelector.access_Token;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const userId = dataSelector.auth_UserDetail.U_UserID;
  //

  const [showAvatarModal, setShowAvatarModal] = useState(false);

  let initial = {
    username: "",
    FName: "",
    LName: "",
    FormFiles: null,
    image_url:
      dataSelector.auth_UserDetail.U_DisplayAvtar &&
      dataSelector.auth_UserDetail.U_DisplayAvtar !== ""
        ? dataSelector.auth_UserDetail.U_DisplayAvtar
        : null,
  };
  const [formInputs, setFormInputs] = useState<any>(initial);

  //

  const [isLoading, setIsLoading] = useState(false);

  const [profileDetails, setProfileDetails] = useState({
    FName: "",
    LName: "",
    GmailEmailID: "",
    GmailPassword: "",
    SMTPIncommingServer: "",
    SMTPOutgoingServer: "",
    IncommingPort: "",
    OutgoingPort: "",
    FormFiles: "",
    AllowRingCentral: false,
    errors: {
      FName: "",
      LName: "",
      GmailEmailID: "",
      GmailPassword: "",
      SMTPIncommingServer: "",
      SMTPOutgoingServer: "",
      IncommingPort: "",
      OutgoingPort: "",
      ValidationRules: [
        {
          FieldName: "FName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "LName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "GmailEmailID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "GmailEmailID",
          ValidationType: "email",
          ValidationMessage: "Enter Valid Email Value",
        },
        {
          FieldName: "GmailPassword",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "SMTPIncommingServer",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "SMTPOutgoingServer",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "IncommingPort",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "OutgoingPort",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  // Upload LOGO :
  const [Logo, setLogo]: any = useState({
    name: "",
    link: [],
    errors: "",
  });
  const [LogoUpload, setLogoUpload]: any = useState(null);

  function SelectLogo(e: any) {
    if (e.target.files.length !== 0) {
      setLogo({ ...Logo, ["link"]: e.target.files });

      const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
      setLogoUpload(fileLoaded);
    }
  }

  useEffect(() => {
    getSingleUserFullDetails();
  }, []);

  async function getSingleUserFullDetails() {
    let input: any = {
      keyID: userId,
    };
    let res: any = await getSingleUserSelectedData(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setProfileDetails({
            ...profileDetails,
            FName: res.data.data.fName,
            LName: res.data.data.lName,
            GmailEmailID: res.data.data.gmailEmailID,
            GmailPassword: res.data.data.gmailPassword,
            SMTPIncommingServer: res.data.data.smtpIncommingServer,
            SMTPOutgoingServer: res.data.data.smtpOutgoingServer,
            IncommingPort: res.data.data.incommingPort,
            OutgoingPort: res.data.data.outgoingPort,
            AllowRingCentral: res.data.data.allowRingCentral,
          });
          setLogoUpload(res.data.data.displayAvtar);
        }
      }
    }
  }

  async function SaveProfileData() {
    let obj = fnCheckValidationOfObject(profileDetails);

    setProfileDetails({
      ...obj.obj,
    });
    if (obj.isValid) {
      var formData: any = new FormData();
      formData.append("UserID", userId);
      formData.append(
        "AllowRingCentral",
        profileDetails.AllowRingCentral !== null ? profileDetails.AllowRingCentral : false
      );
      formData.append("FName", profileDetails.FName !== null ? profileDetails.FName : "");
      formData.append("LName", profileDetails.LName !== null ? profileDetails.LName : "");
      formData.append(
        "GmailEmailID",
        profileDetails.GmailEmailID !== null ? profileDetails.GmailEmailID : ""
      );
      formData.append(
        "GmailPassword",
        profileDetails.GmailPassword !== null ? profileDetails.GmailPassword : ""
      );
      formData.append(
        "SMTPIncommingServer",
        profileDetails.SMTPIncommingServer !== null ? profileDetails.SMTPIncommingServer : ""
      );
      formData.append(
        "SMTPOutgoingServer",
        profileDetails.SMTPOutgoingServer !== null ? profileDetails.SMTPOutgoingServer : ""
      );
      formData.append(
        "IncommingPort",
        profileDetails.IncommingPort !== null
          ? profileDetails.IncommingPort === ""
            ? 0
            : profileDetails.IncommingPort
          : 0
      );
      formData.append(
        "OutgoingPort",
        profileDetails.OutgoingPort !== null
          ? profileDetails.OutgoingPort === ""
            ? 0
            : profileDetails.OutgoingPort
          : 0
      );

      if (Logo) {
        formData.append("FormFiles", Logo);
      }
      setIsLoading(true);

      let res = await updateUser(formData, Token);

      if (res.data.success) {
        if (typeof res.data.data !== "undefined" && res.data.data) {
          ToasterSuccess(res.data.message);
          localStorage.setItem(
            "authUser",
            CryptoJS.AES.encrypt(
              JSON.stringify({
                ...dataSelector.auth_UserDetail,
                U_DisplayAvtar: res.data.data.displayAvtar ? res.data.data.displayAvtar : "",
                U_FName: res.data.data.fName,
                U_LName: res.data.data.lName,
              }),
              cryptoKey
            )
          );
          dispatch(
            updateAuthUserDetails({
              ...dataSelector.auth_UserDetail,
              U_DisplayAvtar: res.data.data.displayAvtar ? res.data.data.displayAvtar : "",
              U_FName: res.data.data.fName,
              U_LName: res.data.data.lName,
            })
          );
          window.location.reload();
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        ToasterError(res.data.message);
      }
    }
  }

  return (
    <>
      <div className="custom-profile-col">
        <Row>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="First Name" label="First Name">
              <Form.Control
                type="text"
                placeholder="First Name"
                value={profileDetails.FName}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["FName"]: e.target.value,
                  })
                }
                className={`form-control ${profileDetails.errors.FName && "is-invalid"}`}
              />
              {profileDetails.errors.FName && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.FName}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="Last Name" label="Last Name">
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={profileDetails.LName}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["LName"]: e.target.value,
                  })
                }
                className={`form-control ${profileDetails.errors.LName && "is-invalid"}`}
              />
              {profileDetails.errors.LName && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.LName}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="Gmail Email ID" label="Gmail Email ID">
              <Form.Control
                type="text"
                placeholder="Gmail Email ID"
                value={profileDetails.GmailEmailID}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["GmailEmailID"]: e.target.value,
                  })
                }
                className={`form-control ${profileDetails.errors.GmailEmailID && "is-invalid"}`}
                autoComplete="new-password"
              />
              {profileDetails.errors.GmailEmailID && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.GmailEmailID}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>

          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="Gmail Password" label="Gmail Password">
              <Form.Control
                type="password"
                placeholder="Gmail Password"
                value={profileDetails.GmailPassword}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["GmailPassword"]: e.target.value,
                  })
                }
                className={`form-control ${profileDetails.errors.GmailPassword && "is-invalid"}`}
                autoComplete="new-password"
              />
              {profileDetails.errors.GmailPassword && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.GmailPassword}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="SMTP Incomming Server" label="SMTP Incomming Server">
              <Form.Control
                type="text"
                placeholder="SMTP Incomming Server"
                value={profileDetails.SMTPIncommingServer}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["SMTPIncommingServer"]: e.target.value,
                  })
                }
                className={`form-control ${
                  profileDetails.errors.SMTPIncommingServer && "is-invalid"
                }`}
              />
              {profileDetails.errors.SMTPIncommingServer && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.SMTPIncommingServer}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="SMTP Outgoing Server" label="SMTP Outgoing Server">
              <Form.Control
                type="text"
                placeholder="SMTP Outgoing Server"
                value={profileDetails.SMTPOutgoingServer}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["SMTPOutgoingServer"]: e.target.value,
                  })
                }
                className={`form-control ${
                  profileDetails.errors.SMTPOutgoingServer && "is-invalid"
                }`}
              />
              {profileDetails.errors.SMTPOutgoingServer && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.SMTPOutgoingServer}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="Incomming Port" label="Incomming Port">
              <Form.Control
                type="number"
                onWheel={(event: any) => {
                  event.target.blur();
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode == "38" || e.keyCode == "40") {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                placeholder="Incomming Port"
                value={profileDetails.IncommingPort}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["IncommingPort"]: e.target.value,
                  })
                }
                className={`form-control ${profileDetails.errors.IncommingPort && "is-invalid"}`}
              />
              {profileDetails.errors.IncommingPort && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.IncommingPort}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
          <Col lg={6} xl={6} className="mb-3">
            <FloatingLabel controlId="Outgoing Port" label="Outgoing Port">
              <Form.Control
                type="number"
                onWheel={(event: any) => {
                  event.target.blur();
                }}
                onKeyDown={(e: any) => {
                  if (e.keyCode == "38" || e.keyCode == "40") {
                    e.stopPropagation();
                    e.preventDefault();
                  }
                }}
                placeholder="Outgoing Port"
                value={profileDetails.OutgoingPort}
                onChange={(e) =>
                  setProfileDetails({
                    ...profileDetails,
                    ["OutgoingPort"]: e.target.value,
                  })
                }
                className={`form-control ${profileDetails.errors.OutgoingPort && "is-invalid"}`}
              />
              {profileDetails.errors.OutgoingPort && (
                <Form.Control.Feedback type="invalid">
                  {profileDetails.errors.OutgoingPort}
                </Form.Control.Feedback>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={4} xxl={3} className="mt-3 mb-2">
            <Form.Check
              inline
              label={"Hide RingCentral ?"}
              name="Hide_RingCentral"
              id={"Hide_RingCentral"}
              checked={profileDetails.AllowRingCentral}
              onChange={(e) =>
                setProfileDetails({
                  ...profileDetails,
                  ["AllowRingCentral"]: e.target.checked,
                })
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} xl={4} xxl={3} className="mt-3">
            <div className="mt-5">
              {LogoUpload && (
                <div className="lower-area-img">
                  <span>
                    <img
                      src={LogoUpload ? LogoUpload : Avatar}
                      style={{
                        objectFit: "cover",
                        borderRadius: "52%",
                      }}
                      alt=""
                      width="136"
                      height="136"
                    />
                  </span>
                </div>
              )}
            </div>
            <div role="button" onClick={() => setShowAvatarModal(true)} className="comninputbtn">
              <span>Choose picture </span>
            </div>
          </Col>
        </Row>

        <Modal.Footer className="border-0 justify-content-start mt-2">
          <div className=" mb-4">
            {!isLoading ? (
              <Button
                onClick={(e) => {
                  SaveProfileData(e);
                }}
                type="button"
                variant="primary"
              >
                <span className="me-2">
                  <img src={WhiteSaveIcon} alt="" />
                </span>
                Save
              </Button>
            ) : (
              <Button type="button" variant="primary">
                <Spinner size="sm" className="me-2" animation="border" variant="light" />
                Loading
              </Button>
            )}
          </div>
        </Modal.Footer>
      </div>
      {showAvatarModal && (
        <UserProfileAvatar
          setFormInputs={setFormInputs}
          setLogoUpload={setLogoUpload}
          setLogo={setLogo}
          // LogoUpload={LogoUpload}
          formInputs={formInputs}
          show={showAvatarModal}
          onHide={() => {
            setShowAvatarModal(false);
          }}
        ></UserProfileAvatar>
      )}
    </>
  );
};
