import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import TableView from "../../Component/Table/Index";
function ClientProperties() {
  const clientPropertiesTableColumns = React.useMemo(
    () => [
      {
        Header: "Hotel Code",
        accessor: "hotelCode",
      },
      {
        Header: "Seconday Name",
        accessor: "secondayName",
      },
      {
        Header: "Portfolio",
        accessor: "portfolio",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );
  const clientPropertiesTableData = [
    {
      hotelCode: "HI1234",
      secondayName: "Holiday Inn Express Stafford",
      portfolio: "Holiday Inn Express Stafford",
      city: "-",
      status: <GreenLabel successLabel="Active" />,
      contactName: "Alpesh Shukla",
      email: "info@essicompanies.com",
    },
  ];
  return (
    <Container fluid={true} className="p-0 position-relative">
      <div className="btn-icon-grp position-static justify-content-end">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Client</Tooltip>}>
          <Button className="btn-icon addFormIcon"></Button>
        </OverlayTrigger>
      </div>
      <Row>
        <Col xs={12}>
          <TableView
            columnsData={clientPropertiesTableColumns}
            tableData={clientPropertiesTableData}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ClientProperties;
