import { useEffect, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import GroupImport from "./GroupImport";
import RFPImport from "./RFPImport";
import LNRImport from "./LNRImport";
import MeetingCateringImport from "./MeetingCateringImport";
import { HandleFilters, HandleRemoveOtherFilters } from "../../Services/CommonServices";
import LeadImport from "./LeadImport";
import AccountImport from "./AccountImport";
import ContactImport from "./ContactImport";
import ActivityImport from "./ActivityImport";

function Import() {
  const [tabControl, setTabControl]: any = useState("Lead");
  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("importFilter"))) {
      HandleRemoveOtherFilters("importFilter");
      HandleFilters("importFilter", {
        pagePath: "/import",
        pageName: "import",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey={tabControl}>
        <Row>
          <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="Lead" onClick={() => setTabControl("Lead")}>
                Lead 
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Account" onClick={() => setTabControl("Account")}>
                 Account 
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Contact" onClick={() => setTabControl("Contact")}>
                 Contact 
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="Activity" onClick={() => setTabControl("Activity")}>
                Activity 
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="RFP" onClick={() => setTabControl("RFP")}>
                  RFP
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="LNR" onClick={() => setTabControl("LNR")}>
                  LNR
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Group" onClick={() => setTabControl("Group")}>
                  Group
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="Meeting Catering"
                  onClick={() => setTabControl("Meeting Catering")}
                >
                  Meeting Catering
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
            <Tab.Pane eventKey="Lead" onClick={() => setTabControl("Lead")}>
                {tabControl === "Lead" && <LeadImport />}
              </Tab.Pane>
              <Tab.Pane eventKey="Account" onClick={() => setTabControl("Account")}>
                {tabControl === "Account" && <AccountImport />}
              </Tab.Pane>

              <Tab.Pane eventKey="Contact" onClick={() => setTabControl("Contact")}>
                {tabControl === "Contact" && <ContactImport />}
              </Tab.Pane>
              {/* <Tab.Pane eventKey="Activity" onClick={() => setTabControl("Activity")}>
                {tabControl === "Activity" && <ActivityImport />}
              </Tab.Pane> */}
              <Tab.Pane eventKey="RFP" onClick={() => setTabControl("RFP")}>
                {tabControl === "RFP" && <RFPImport />}
              </Tab.Pane>
              <Tab.Pane eventKey="LNR" onClick={() => setTabControl("LNR")}>
                {tabControl === "LNR" && <LNRImport />}
              </Tab.Pane>

              <Tab.Pane eventKey="Group" onClick={() => setTabControl("Group")}>
                {tabControl === "Group" && <GroupImport />}
              </Tab.Pane>
              <Tab.Pane
                eventKey="Meeting Catering"
                onClick={() => setTabControl("Meeting Catering")}
              >
                {tabControl === "Meeting Catering" && <MeetingCateringImport />}
              </Tab.Pane>
            </Tab.Content>
          </Col>
          {/* <Col sm={2}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="Leads" onClick={() => setTabControl("Leads")}>
                  Leads
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Accounts" onClick={() => setTabControl("Accounts")}>
                  Accounts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Contacts" onClick={() => setTabControl("Contacts")}>
                  Contacts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Activities" onClick={() => setTabControl("Activities")}>
                  Activities
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="Leads" onClick={() => setTabControl("Leads")}>
                {tabControl === "Leads" && <LeadImport />}
              </Tab.Pane>
              <Tab.Pane eventKey="Accounts" onClick={() => setTabControl("Accounts")}>
                {tabControl === "Accounts" && <AccountImport />}
              </Tab.Pane>

              <Tab.Pane eventKey="Contacts" onClick={() => setTabControl("Contacts")}>
                {tabControl === "Contacts" && <ContactImport />}
              </Tab.Pane>
              <Tab.Pane eventKey="Activities" onClick={() => setTabControl("Activities")}>
                {tabControl === "Activities" && <ActivityImport />}
              </Tab.Pane>
            </Tab.Content>
          </Col> */}
        </Row>
      </Tab.Container>
    </>
  );
}

export default Import;
