import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  AppConfigData,
  ToasterError,
  ToasterSuccess,
  getDateInFormatMMMMDYYYY,
} from "../../Services/CommonServices";
import { useSelector } from "react-redux";
import { ExportLeadFileOfInvalidData } from "../../Services/ImportServises/ImportServises";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import TableView from "../../Component/Table/Index";
import moment from "moment";
import { useDownloadExcel } from "react-export-table-to-excel";
import XLSX from "sheetjs-style";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";

export default function ImportGroupModal(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const tableRef = useRef(null);

  const SuccessfullImportdHeader = useMemo(
    () => [
      {
        Header: "Opportunity name",
        accessor: "Opportunityname",
        className: "w-243",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Hotels",
        accessor: "Hotels",
      },
      {
        Header: "Account",
        accessor: "Account",
      },
      {
        Header: "Opportunity Owner",
        accessor: "OpportunityOwner",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Check in Date",
        accessor: "CheckinDate",
      },
      {
        Header: "Check Out Date",
        accessor: "CheckOutDate",
      },
      {
        Header: "AnticipetedRevanue",
        accessor: "AnticipetedRevanue",
      },
    ],
    []
  );

  const InvalidImportdHeader = useMemo(
    () => [
      {
        Header: "Reason",
        accessor: "Reason",
      },
      {
        Header: "Opportunity name",
        accessor: "Opportunityname",
        className: "w-243",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Hotels",
        accessor: "Hotels",
      },
      {
        Header: "Account",
        accessor: "Account",
      },
      {
        Header: "Opportunity Owner",
        accessor: "OpportunityOwner",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Check in Date",
        accessor: "CheckinDate",
      },
      {
        Header: "Check Out Date",
        accessor: "CheckOutDate",
      },
      {
        Header: "AnticipetedRevanue",
        accessor: "AnticipetedRevanue",
      },
    ],
    []
  );

  let tempSuccessfullImportedData: any = [];
  let tempInvalidImportedData: any = [];

  const [ImportedData, setImportedData] = useState([]);

  ImportedData &&
    ImportedData.map((item: any) => {
      if (item.Isvalid === true) {
        tempSuccessfullImportedData.push({
          controlRender: {
            Status:
              item.Status === "8. Expired Rate" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "7. Lost / RFP Declined" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "6. Rate Renewal" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : (
                <GreenLabel successLabel={item.Status} />
              ),
          },
          className: {
            Reason: "text-danger w-300 whiteSpaceNowrap",
            AnticipetedRevanue: "text-end",
            CheckinDate: "text-end",
            CheckOutDate: "text-end",
          },
          Opportunityname: item.Opportunityname,
          Source: item.Source,
          Hotels: item.Hotels,
          Account: item.Account,
          OpportunityOwner: item.OpportunityOwner,
          Status: item.Status,
          CheckinDate: getDateInFormatMMMMDYYYY(item.CheckinDate),
          CheckOutDate: getDateInFormatMMMMDYYYY(item.CheckOutDate),
          AnticipetedRevanue:
            item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
              ? "$" + item.AnticipetedRevanue
              : "",
        });
      } else {
        tempInvalidImportedData.push({
          controlRender: {
            Status:
              item.Status === "8. Expired Rate" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "7. Lost / RFP Declined" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "6. Rate Renewal" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : (
                <GreenLabel successLabel={item.Status} />
              ),
          },
          className: {
            Reason: "text-danger w-300 whiteSpaceNowrap",
            AnticipetedRevanue: "text-end",
            CheckinDate: "text-end",
            CheckOutDate: "text-end",
          },
          Reason: item.Reason,
          Opportunityname: item.Opportunityname,
          Source: item.Source,
          Hotels: item.Hotels,
          Account: item.Account,
          OpportunityOwner: item.OpportunityOwner,
          Status: item.Status,
          CheckinDate: getDateInFormatMMMMDYYYY(item.CheckinDate),
          CheckOutDate: getDateInFormatMMMMDYYYY(item.CheckOutDate),
          AnticipetedRevanue:
            item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
              ? "$" + item.AnticipetedRevanue
              : "",
        });
      }
    });

  useEffect(() => {
    if (
      props.ModuleData !== undefined &&
      props.ModuleData !== null &&
      props.ModuleData.length !== 0
    ) {
      setImportedData(props.ModuleData);
    }
  }, [props && props.ModuleData]);

  async function ExportLeadErrorData() {
    let inputArrayOnj: any = [];

    ImportedData &&
      InvalidImportedData.map((item: any) => {
        inputArrayOnj.push({
          companyOrGroupName: item.companyName,
          isGroup: item.isGroup === true ? "Yes" : "No",
          source: item.lead_Source_Term,
          firstName: item.firstName,
          lastName: item.lastName,
          hotelName: item.hotelName,
          assignTo: item.assignTo,
          officePhone: item.telephoneNo,
          officePhoneExt: item.ext,
          mobilePhone: item.mobileNo,
          address: item.address,
          email: item.email,
          city: item.city,
          state: item.state,
          country: item.country,
          zipCode: item.zipcode,
          website: item.website,
          notes: item.comment,
          groupType: item.groupType,
          startDate: item.bookingDate && moment(item.bookingDate).format("YYYY-MM-DD"),
          endDate: item.endDate && moment(item.endDate).format("YYYY-MM-DD"),
          noofRooms: item.noofRooms,
        });
      });

    let input: any = {
      inValidData: inputArrayOnj,
    };

    let res: any = await ExportLeadFileOfInvalidData(input, Token);

    if (res.data !== undefined && res.data !== null) {
      let Message = `Leads Export SuccessFully`;
      if (res.data.statusCode === 200) {
        window.open(AppConfigData.APIBasePath + res.data.data);
        ToasterSuccess(Message);
      } else {
        ToasterError(res.data.message);
      }
    } else {
      ToasterError(res.message);
    }
  }

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Group_Opportunity.xlsx",
    sheet: "Group",
  });

  function downloadTable() {
    const table = document.getElementById("tableRef");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Group");
    XLSX.writeFile(wb, "Group_Opportunity.xlsx");
  }

  let GroupErrTableExport: any = () => {
    return (
      <>
        <thead>
          <tr>
            <th>
              <b> Opportunity name </b>
            </th>
            <th>
              <b> Source </b>
            </th>
            <th>
              <b> Hotels </b>
            </th>
            <th>
              <b> Account </b>
            </th>
            <th>
              <b> Opportunity Owner </b>
            </th>
            <th>
              <b> Status </b>
            </th>
            <th>
              <b> Check in Date </b>
            </th>
            <th>
              <b> Check Out Date </b>
            </th>
            <th>
              <b> AnticipetedRevanue </b>
            </th>
          </tr>
        </thead>

        <tbody>
          {ImportedData &&
            ImportedData.map((item: any, idx) => {
              if (item.Isvalid === false) {
                return (
                  <tr key={idx}>
                    <td className="text-danger w-300 whiteSpaceNowrap">{item.Opportunityname}</td>
                    <td>{item.Source}</td>
                    <td>{item.Hotels}</td>
                    <td>{item.Account}</td>
                    <td>{item.OpportunityOwner}</td>
                    <td>{item.Status}</td>
                    <td>{getDateInFormatMMMMDYYYY(item.CheckinDate)}</td>
                    <td>{getDateInFormatMMMMDYYYY(item.CheckOutDate)}</td>
                    <td>
                      {" "}
                      {item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
                        ? "$" + item.AnticipetedRevanue
                        : ""}
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </>
    );
  };

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center justify-content-between w-100"
          >
            <div> Import (Group Opportunity) </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col xs={12}>
              <h6> Imported Data</h6>
              <div>
                <TableView
                  columnsData={SuccessfullImportdHeader}
                  tableData={tempSuccessfullImportedData}
                  isData={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-5">
              <Row>
                <Col md={11}>
                  <h6> Invalid Data</h6>
                </Col>
                <Col md={1}>
                  <Button
                    className="mb-2"
                    variant="primary"
                    disabled={tempInvalidImportedData.length ? false : true}
                    // onClick={onDownload}
                    onClick={downloadTable}
                    //   onClick={() => ExportLeadErrorData()}
                  >
                    <span className="me-2">
                      <img src={WhiteSaveIcon} alt="" />
                    </span>
                    Export
                  </Button>
                </Col>
              </Row>

              <div>
                <TableView
                  columnsData={InvalidImportdHeader}
                  tableData={tempInvalidImportedData}
                  isData={false}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Table
        id={"tableRef"}
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <GroupErrTableExport />
      </Table>
    </>
  );
}
