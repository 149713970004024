import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
export async function callMail(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMail, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
export async function EmailAttachments(accessToken, emailId) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`https://graph.microsoft.com/v1.0/me/messages/${emailId}/attachments`, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
export async function EmailNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`https://graph.microsoft.com/v1.0/me/messages?%24top=10&%24skip=${number}`, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
export async function EmailThread(accessToken, id, id2) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`https://graph.microsoft.com/v1.0/groups`, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

export async function getmaildata(accessToken, id) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMail + "/" + id, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
export async function getDeletedmaildata(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphDeletedMail, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email inbox data
export async function getEmailInbox(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMailInbox, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
// email inbox data next/prev
export async function getEmailInboxNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(
    `https://graph.microsoft.com/v1.0/me/mailFolders('Inbox')/messages?%24top=10&%24skip=${number}`,
    options
  )
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Sent data
export async function getEmailSent(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMailSent, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Sent data next/prev
export async function getEmailSentNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };
  10;
  return fetch(
    `https://graph.microsoft.com/v1.0/me/mailFolders('SentItems')/messages?%24top=10&%24skip=${number}`,
    options
  )
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
// email Drafts data
export async function getEmailDrafts(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMailDrafts, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Drafts data next/prev
export async function getEmailDraftsNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };
  10;
  return fetch(
    `https://graph.microsoft.com/v1.0/me/mailFolders('Drafts')/messages?%24top=10&%24skip=${number}`,
    options
  )
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Deleted data
export async function getEmailTrash(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMailTrash, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Drafts data next/prev
export async function getEmailTrashNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };
  10;
  return fetch(
    `https://graph.microsoft.com/v1.0/me/mailFolders('DeletedItems')/messages?%24top=10&%24skip=${number}`,
    options
  )
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Spam data
export async function getEmailSpam(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMailSpam, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email Drafts data next/prev
export async function getEmailSpamNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };
  10;
  return fetch(
    `https://graph.microsoft.com/v1.0/me/mailFolders('JunkEmail')/messages?%24top=10&%24skip=${number}`,
    options
  )
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email All data
export async function getEmailAll(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMailAll, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}

// email All data next/prev
export async function getEmailAllNextPrev(accessToken, number) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(`https://graph.microsoft.com/v1.0/me/messages?%24top=10&%24skip=${number}`, options)
    .then((response) => response.json())
    .catch((error) => {
      // console.log(error)
    });
}
