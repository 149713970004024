import axios from "axios";
import { AppConfigData } from "./CommonServices";


export const profileGetAccounts = async (ReqData, accessToken) => {
  
  try {
      const response = await axios({
        method: "POST",
        url:
          AppConfigData.APIBasePath +
          "client/api/User/AccountsGetList",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      let data: any = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
      return data;
    }
  };

  
  export const profileGetProperties = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url:
          AppConfigData.APIBasePath +
          "client/api/User/PropertiesGetList",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      let data: any = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
      return data;
    }
  };

  
  export const profileGetActivities = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url:
          AppConfigData.APIBasePath +
          "client/api/User/ActivitiesGetList",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      let data: any = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
      return data;
    }
  };

  
  export const profileGetActions = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url:
          AppConfigData.APIBasePath +
          "client/api/User/ActionLogGetList",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      let data: any = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
      return data;
    }
  };
  export const changePassword = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url:
          AppConfigData.APIBasePath +
          "client/api/User/ChangePassword",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      let data: any = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
      return data;
    }
  };
  export const updateUser = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url:
          AppConfigData.APIBasePath +
          "client/api/User/Update",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error) {
      let data: any = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
      return data;
    }
  };
  