import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";

import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  CommonService,
  fnCheckValidationOfObject,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";
import { getClientDetailByID, updateClientData } from "../../Services/Setup&Config/ClientServices";
import { PatternFormat } from "react-number-format";
import PageLoader from "../../Component/Loader/PageLoader";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";

function CreateNewClients() {
  let page_rights_Company = CommonService.getPageRight("Company");
  const [loading, setLoading] = useState(false);
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const isData = dataSelector.auth_UserDetail;
  const [BusinessTypeDropdownList, setBusinessTypeDropdownList]: any = useState([]);
  const [businessTypeObj, setbusinessTypeObj] = React.useState({
    value: "",
    label: "Select Business Type",
  });
  const [CLientData, setCLientData]: any = React.useState({
    countryCode: "",
    companyName: "",
    businessTypes_Term: "",
    firstName: "",
    lastName: "",
    clientName: "",
    email: "",
    contactNo: "",
    contractedUsers: "",
    contractedProperties: "",
    subscriptionPack: "",
    clientID: null,
    clientCode: "",
    dateofRegistration: "",
    clientStatus_Term: "",
    paymentOption: "",
    invoiceContactName: "",
    invoiceContactEmail: "",
    invoiceContactPhone: "",
    errors: {
      // companyName: "",
      businessTypes_Term: "",
      // firstName: "",
      // lastName: "",
      // email: "",
      contactNo: "",
      ValidationRules: [
        // {
        //   FieldName: "companyName",
        //   ValidationType: "required",
        //   ValidationMessage: "This Field is required.",
        // },
        {
          FieldName: "businessTypes_Term",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        // {
        //   FieldName: "firstName",
        //   ValidationType: "required",
        //   ValidationMessage: "This Field is required.",
        // },
        // {
        //   FieldName: "lastName",
        //   ValidationType: "required",
        //   ValidationMessage: "This Field is required.",
        // },

        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
        {
          FieldName: "email",
          ValidationType: "email",
          ValidationMessage: "Please enter valid email.",
        },
        {
          FieldName: "contactNo",
          ValidationType: "range",
          ValidationMessage: "Must enter 10 digits.",
        },
        {
          FieldName: "contactNo",
          ValidationType: "required",
          ValidationMessage: "This Field is required.",
        },
      ],
    },
  });

  let optionsBusinessType: any = [
    {
      value: "",
      label: "Select Business Type*",
    },
  ];

  BusinessTypeDropdownList !== undefined &&
    BusinessTypeDropdownList &&
    BusinessTypeDropdownList.length &&
    BusinessTypeDropdownList.map((item: any, index) =>
      optionsBusinessType.push({
        label: item.TermCode,
        value: item.TermCode,
      })
    );

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("companyprofileFilter"))) {
      HandleRemoveOtherFilters("companyprofileFilter");
      HandleFilters("companyprofileFilter", {
        pagePath: "/SetupAndConfigurations/companyProfile",
        pageName: "generalSetting",
        filters: [],
        isSort: [],
      });
    }
    getClientDetail();
    getBusinessTypeDropdown();
  }, []);

  async function getBusinessTypeDropdown() {
    let inputs = {
      category: "Sub Type",
      clientID: clientID,
    };

    const res: any = await getProjectTermByCategory(inputs, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        setBusinessTypeDropdownList(res.data.data.dataList1); // setRoomSetupDropdownList([...RoomSetupDropdownList, res.data.data.dataList1]);
      }
    }
  }

  async function getClientDetail() {
    let input: any = {
      keyID: isData.U_ClientID,
    };
    setLoading(true);
    let res: any = await getClientDetailByID(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null) {
        setCLientData({
          ...CLientData,
          clientID: res.data.data.clientData.clientID,
          clientCode: res.data.data.clientData.clientCode,
          dateofRegistration:
            res.data.data.clientData.dateofRegistration &&
            res.data.data.clientData.dateofRegistration.split("T")[0],
          dateofExpriry:
            res.data.data.subscription.DateOfExpire &&
            res.data.data.subscription.DateOfExpire.split("T")[0],
          clientStatus_Term: res.data.data.clientData.clientStatus_Term,
          companyName: res.data.data.clientData.companyName,
          businessTypes_Term: res.data.data.clientData.businessTypes_Term,
          firstName: res.data.data.clientData.firstName,
          lastName: res.data.data.clientData.lastName,
          clientName: res.data.data.clientData.clientName,
          email: res.data.data.clientData.email,

          contactNo:
            res.data.data.clientData.contactNo && !res.data.data.clientData.contactNo.includes(" ")
              ? res.data.data.clientData.contactNo
              : res.data.data.clientData.contactNo.split(" ")[1],

          countryCode:
            res.data.data.clientData.contactNo && !res.data.data.clientData.contactNo.includes(" ")
              ? ""
              : res.data.data.clientData.contactNo.split(" ")[0],

          contractedUsers: res.data.data.clientData.contractedUsers,
          contractedProperties: res.data.data.clientData.contractedProperties,
          subscriptionPack: res.data.data.subscription.subscriptionType,
          paymentOption: res.data.data.clientData.paymentOption,
          invoiceContactName: res.data.data.clientData.invoiceContactName,
          invoiceContactEmail: res.data.data.clientData.invoiceContactEmail,
          invoiceContactPhone: res.data.data.clientData.invoiceContactPhone,
          packageCode: res.data.data.clientData.packageCode,
          promotionCode: res.data.data.clientData.promotionCode,
          lastInvoiceDate:
            res.data.data.subscription.DateOfExpire &&
            res.data.data.subscription.DateOfExpire.split("T")[0],
          isSubscribeForNewsLetter: res.data.data.clientData.isSubscribeForNewsLetter,
        });
      }
    }
    setLoading(false);
  }

  async function SaveClientData() {
    let obj = fnCheckValidationOfObject(CLientData);
    setCLientData({
      ...obj.obj,
    });
    if (obj.isValid) {
      let input: any = {
        clientID: CLientData.clientID,
        clientCode: CLientData.clientCode,
        contactNo: CLientData.countryCode + " " + CLientData.contactNo,
        businessTypes_Term: CLientData.businessTypes_Term,
        isSubscribeForNewsLetter: CLientData.isSubscribeForNewsLetter,
      };

      setOnProcessHide(true);
      let res: any = await updateClientData(input, Token);
      setOnProcessHide(false);
      if (res.data.success) {
        if (res.data !== undefined) {
          if (res.data.statusCode === 200) {
            ToasterSuccess(res.data.message);
          }
        } else {
          ToasterError(res.message);
        }
      }
    }
  }

  return (
    <>
      <div className="custom-profile-page">
        <div className="container-fluid p-0">
          <div className="custom-profile-col">
            <Row>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="First Name">
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    maxLength={164}
                    disabled={true}
                    value={CLientData.firstName}
                    onChange={(e) =>
                      setCLientData({
                        ...CLientData,
                        ["firstName"]: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Last Name">
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    maxLength={164}
                    disabled={true}
                    value={CLientData.lastName}
                    onChange={(e) =>
                      setCLientData({
                        ...CLientData,
                        ["lastName"]: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    type="email"
                    disabled
                    autoComplete="new-password"
                    maxLength={164}
                    placeholder="Email"
                    value={CLientData.email}
                    onChange={(e) =>
                      setCLientData({
                        ...CLientData,
                        ["email"]: e.target.value,
                      })
                    }
                    autoComplete="new-password"
                  />
                </FloatingLabel>
              </Col>

              <Col lg={6} xl={3} className="mb-3 d-flex">
                <FloatingLabel className="me-2 w-50" controlId="countryCode" label="Code">
                  <Form.Control
                    type="text"
                    placeholder="Country Code"
                    maxLength={66}
                    value={CLientData.countryCode}
                    onChange={(e) =>
                      setCLientData({
                        ...CLientData,
                        ["countryCode"]: e.target.value,
                      })
                    }
                  />
                </FloatingLabel>
                <FloatingLabel className="w-50" controlId="contactNo" label="">
                  <div className={`${CLientData.errors.contactNo && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingInput" label="Contact No.*">
                      <PatternFormat
                        className="form-control"
                        displayType="input"
                        value={CLientData.contactNo}
                        format="###-###-####"
                        onChange={(e) =>
                          setCLientData({
                            ...CLientData,
                            ["contactNo"]: e.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </div>
                  {CLientData.errors.contactNo && (
                    <>
                      <span className="feedbackss">{CLientData.errors.contactNo}</span>
                      <br />
                    </>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={3} className="mb-3 fild-input">
                <div className={`${CLientData.errors.businessTypes_Term && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingInput" label="">
                    <CreatableSelect
                      isClearable
                      options={optionsBusinessType}
                      onChange={(event: any) => {
                        setCLientData({
                          ...CLientData,
                          ["businessTypes_Term"]: event !== null ? event.value : "",
                        });
                      }}
                      placeholder="Select Business Type*"
                      value={
                        CLientData.businessTypes_Term !== ""
                          ? {
                              label: CLientData.businessTypes_Term,
                              value: CLientData.businessTypes_Term,
                            }
                          : {
                              label: "Select Business Type*",
                              value: "",
                            }
                      }
                    />
                  </FloatingLabel>
                </div>
                {CLientData.errors.businessTypes_Term && (
                  <>
                    <span className="feedbackss">{CLientData.errors.businessTypes_Term}</span>
                  </>
                )}
              </Col>

              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="code" label="Code">
                  <Form.Control
                    type="text"
                    value={CLientData.clientCode}
                    maxLength={15}
                    // placeholder=""
                    onChange={(e) => {
                      setCLientData({
                        ...CLientData,
                        ["clientCode"]: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="packageCode" label="Package Code">
                  <Form.Control
                    type="text"
                    maxLength={39}
                    value={CLientData.packageCode}
                    placeholder="Package Code"
                    disabled
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="promotionCode" label="Promotion Code">
                  <Form.Control
                    type="text"
                    maxLength={15}
                    value={CLientData.promotionCode}
                    placeholder="Promotion Code"
                    disabled
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="dateOfRegistration" label="Date of Registration">
                  <Form.Control
                    type="date"
                    value={CLientData.dateofRegistration}
                    placeholder="Date of Registration"
                    disabled
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="dateOfExpiry" label="Date of Expiry">
                  <Form.Control
                    type="date"
                    placeholder="Date of Expiry"
                    value={CLientData.dateofExpriry}
                    disabled
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3} className="mb-3">
                <FloatingLabel controlId="lastInvoiceDate" label="Last Invoice Date">
                  <Form.Control
                    type="date"
                    placeholder="Last Invoice Date"
                    value={CLientData.lastInvoiceDate}
                    disabled
                  />
                </FloatingLabel>
              </Col>
              <Col lg={6} xl={3}>
                <FloatingLabel controlId="status" label="Status">
                  <Form.Control
                    type="text"
                    value={CLientData.clientStatus_Term}
                    placeholder="Active"
                    disabled
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col lg={6} xl={3} className="mb-3 d-flex align-items-center">
                {["checkbox"].map((type: any) => (
                  <div key={`inline-${type}`} className="option-field">
                    <Form.Check
                      inline
                      label="Subscribe News Letter"
                      name="group16"
                      id="Subscribe News Letter"
                      type={type}
                      checked={CLientData.isSubscribeForNewsLetter === true ? true : false}
                      onChange={(e) =>
                        setCLientData({
                          ...CLientData,
                          ["isSubscribeForNewsLetter"]: e.target.checked,
                        })
                      }
                    />
                  </div>
                ))}
              </Col>
            </Row>
          </div>
          <Modal.Footer className="border-0 justify-content-start">
            <Button
              variant="primary ms-0"
              onClick={() => {
                if (page_rights_Company.is_update) {
                  SaveClientData();
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              disabled={OnProcessHide === true ? true : false}
            >
              <span className="me-2">
                <img src={WhiteSaveIcon} alt="" />
              </span>
              Save
            </Button>
          </Modal.Footer>
        </div>
      </div>
      {loading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default CreateNewClients;
