import moment from "moment";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import WeeklyReportsModal from "../../Model/Reports/WeeklyReportsModal";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { WeeklyReportSingleFileExportPDF } from "../../Services/ReportServices";

function WeeklyReport() {
  let WeeklyReport_page_rights = CommonService.getPageRight("WeeklyReport");

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [ReportData, setReportData]: any = useState({
    propertyID: "",
    startDate: "",
    endDate: "",
    userIDs: "",
    isIncludeActivity: false,
    errors: {
      propertyID: "",
      startDate: null,
      endDate: "",
      userIDs: "",
      ValidationRules: [
        {
          FieldName: "propertyID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "userIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "startDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "endDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [Downloading, setDownloading] = useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);
  const Token = dataSelector.access_Token;
  const [UserDropdownData, setUserDropdownData]: any = useState([]);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selHotels, setSelHotels]: any = useState({
    label: "Select Property",
    value: "",
  });
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });

  let ArrayPropertyDropdown: any = [];

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("weeklyReportFilter"))) {
      HandleRemoveOtherFilters("weeklyReportFilter");
      HandleFilters("weeklyReportFilter", {
        pagePath: "/WeeklyReport",
        pageName: "weeklyReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  React.useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels({
          value: SelectedPropertyID,
          label: SelectedPropertyName,
        });
        setReportData({
          ...ReportData,
          ["propertyID"]: SelectedPropertyID,
        });

        SelectedPropertyData(SelectedPropertyID);
      }
    }

    getHotelsList();
  }, [SelectedPropertyName]);

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  let HotelsDropdown: any = [
    {
      label: "Select Property",
      value: "",
    },
  ];
  let UsersDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  UserDropdownData !== undefined &&
    UserDropdownData !== null &&
    UserDropdownData.map((item: any) => {
      UsersDropdown.push({
        label: item.UserName,
        value: item.UserID,
      });
    });
  function getPropertyDropdown() {
    if (
      SelectedPropertyID !== "" &&
      SelectedPropertyID !== undefined &&
      SelectedPropertyID !== null
    ) {
      ArrayPropertyDropdown.push(SelectedPropertyID.length !== 0 && SelectedPropertyID.split(","));
    }
  }
  async function SelectedPropertyData(data: any) {
    sethotelChangeLoading(true);

    setSelectedUsers([]);
    UsersDropdown = [{}];
    if (data !== "" && ReportData.propertyID !== data) {
      setReportData({
        ...ReportData,
        ["userIDs"]: "",
        ["propertyID"]: data,
      });
    } else {
      setReportData({
        ...ReportData,
        ["userIDs"]: "",
        ["propertyID"]: "",
      });
    }

    let input = {
      clientID: clientID,
      propertyIDs: data,
    };

    const res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          if (
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1 !== ""
          ) {
            setUserDropdownData(res.data.data.dataList1);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  }

  function selectedUser(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].value);
      }
      setReportData({
        ...ReportData,
        ["userIDs"]: abc.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["userIDs"]: "",
      });
    }
  }
  function SaveGenerateReport() {
    let obj = fnCheckValidationOfObject(ReportData);
    setReportData({
      ...obj.obj,
    });
    if (obj.isValid) {
      setModalShow(true);
    }
  }

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setReportData({
        ...ReportData,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setReportData({
        ...ReportData,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  async function exportPDF() {
    let obj = fnCheckValidationOfObject(ReportData);
    setReportData({
      ...obj.obj,
    });
    if (obj.isValid) {
      let inputData: any = {
        propertyID: ReportData.propertyID,
        startDate: ReportData.startDate,
        endDate: ReportData.endDate,
        userIDs: ReportData.userIDs,
        isIncludeActivity: ReportData.isIncludeActivity,
      };

      setDownloading(true);
      let res: any = await WeeklyReportSingleFileExportPDF(inputData, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setDownloading(false);
          } else {
            ToasterError(res.data.message);
            setDownloading(false);
          }
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>

          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {WeeklyReport_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col lg={12} className="mb-4">
                          <div
                            className={`${ReportData.errors.propertyID && "red-border-masking"}`}
                          >
                            <FloatingLabel label="">
                              <Select
                                styles={{
                                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder="Select Property"
                                options={HotelsDropdown}
                                // value={selHotels}
                                value={HotelsDropdown.filter(
                                  (itm) =>
                                    ReportData.propertyID &&
                                    itm.value.includes(ReportData.propertyID)
                                )}
                                onChange={(event: any) => {
                                  SelectedPropertyData(event.value);
                                  setSelHotels(event);
                                }}
                              ></Select>
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.propertyID && ReportData.errors.propertyID}{" "}
                          </small>
                        </Col>
                        <Col lg={12} className="mb-4">
                          <div className={`${ReportData.errors.userIDs && "red-border-masking"}`}>
                            <FloatingLabel controlId="selectUser" label="">
                              <Select
                                styles={{
                                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                }}
                                placeholder="Select User"
                                value={selectedUsers}
                                options={UsersDropdown}
                                isMulti
                                onChange={(e: any) => {
                                  setSelectedUsers(e);
                                  selectedUser(e);
                                }}
                              ></Select>
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.userIDs && ReportData.errors.userIDs}{" "}
                          </small>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <div className={`${ReportData.errors.startDate && "red-border-masking"}`}>
                            <div className="form-floating">
                              <DatePicker
                                className="form-control form-control-date datepicker-react"
                                placeholderText="Select Start & End Date"
                                minDate={new Date("2010/01/01")}
                                maxDate={new Date("9999/12/31")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  handleDateRange(update);
                                  setDateRange(update);
                                }}
                              />
                            </div>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.startDate && ReportData.errors.startDate}{" "}
                          </small>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xl={4} className="mb-3 d-flex align-items-center">
                      {["checkbox"].map((type: any) => (
                        <div key={`inline-${type}`} className="option-field">
                          <Form.Check
                            inline
                            label="Include Activities"
                            name="group16"
                            type={type}
                            id="Include Activities"
                            onChange={(e: any) =>
                              setReportData({
                                ...ReportData,
                                ["isIncludeActivity"]: e.target.checked,
                              })
                            }
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Modal.Footer className="border-0 justify-content-start">
                        <Button
                          variant="primary ms-0"
                          onClick={() => {
                            if (WeeklyReport_page_rights.is_view) {
                              SaveGenerateReport();
                            } else {
                              CommonService.unauthorizedAction();
                            }
                          }}
                        >
                          <span className="me-2">
                            <img src={GenerateReport} alt="" />
                          </span>
                          Generate Report
                        </Button>
                        <Button
                          variant="primary ms-0"
                          disabled={Downloading ? true : false}
                          onClick={(e) => {
                            if (WeeklyReport_page_rights.is_export) {
                              e.preventDefault();
                              exportPDF();
                            } else {
                              CommonService.unauthorizedAction();
                            }
                          }}
                        >
                          <span className="me-2">
                            <img src={PdfIcon} alt="" />
                          </span>
                          Export PDF
                        </Button>
                      </Modal.Footer>
                    </Col>
                  </Row>
                  {modalShow && (
                    <WeeklyReportsModal
                      StateValue={ReportData}
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    ></WeeklyReportsModal>
                  )}
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default WeeklyReport;
