import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { GetMailDetailsByFolder } from "../../Services/GmailServices/MailServices";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../../../Asset/Images/Icons/blackCancelIcon.svg";
// import GmailInboxLinkWith from "../GmailInboxLinkWith";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { EmailAttachments } from "../../Microsoft/Microsoft/components/graph";
import { loginRequest } from "../../Microsoft/Microsoft/components/authConfig";
import {
  FaFile,
  FaFileAlt,
  FaFileArchive,
  FaFileCode,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileVideo,
  FaFileWord,
  FaGoogle,
} from "react-icons/fa";
import axios from "axios";
import GmailInboxLinkWith from "../../GmailInboxLinkWith";

const GmailViewCommonModal = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;

  const [ModalShowLinkWith, setModalShowLinkWith] = React.useState(false);
  const [AttachmentData, setAttachmentData] = useState([]);

  // const [modalShowChartFirst, setModalShowChartFirst] = React.useState(false);
  const [MailDetails, setMailDetails]: any = React.useState({
    IsData: true,
    data: "",
  });

  const contentTypeToIcon = {
    "application/pdf": <FaFilePdf />,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": <FaFileExcel />,
    "application/vnd.google-apps.spreadsheet": <FaGoogle />,
    "application/msword": <FaFileWord />,
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": <FaFileWord />,
    "application/vnd.ms-powerpoint": <FaFilePowerpoint />,
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": (
      <FaFilePowerpoint />
    ),
    "application/zip": <FaFileArchive />,
    "application/x-rar-compressed": <FaFileArchive />,
    "application/x-tar": <FaFileArchive />,
    "application/x-7z-compressed": <FaFileArchive />,
    "application/x-gzip": <FaFileArchive />,
    "application/x-bzip2": <FaFileArchive />,
    "text/plain": <FaFileAlt />,
    "text/html": <FaFileAlt />,
    "text/css": <FaFileAlt />,
    "text/javascript": <FaFileCode />,
    "application/json": <FaFileCode />,
    "application/xml": <FaFileCode />,
  };

  const AccessToken = localStorage.getItem("G_Token");
  const Gmail_Info = JSON.parse(localStorage.getItem("G_Info"));
  const [AttachmentShow, setAttachmentShow] = useState(false);
  const [emailBody, setEmailBody] = useState();
  const [emailHtml, setEmailHtml]: any = useState();
  const [attachments, setAttachments] = useState([]);

  async function fetchAttachment(EmailID, element) {
    if (element.mimeType === "text/plain") {
      try {
        const encodedHtml = element.body.data;
        const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));

        setEmailHtml(decodedHtml);
        setAttachmentShow(true);
      } catch (error) {
        setAttachmentShow(false);
        console.error("Error decoding HTML data:", error);
      }
    } else {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/gmail/v1/users/${Gmail_Info?.email}/messages/${EmailID}/attachments/${element.body.attachmentId}`,
          {
            headers: { Authorization: `Bearer ${AccessToken}` },
            responseType: "arraybuffer",
          }
        );
        const base64Data = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), "")
        );
        const decoded = atob(base64Data);

        // Determine the attachment type based on MIME type
        let attachmentType;
        if (element.mimeType.startsWith("image/")) {
          attachmentType = "image";
        } else if (element.mimeType === "application/pdf") {
          attachmentType = "pdf";
        } else if (
          element.mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          attachmentType = "spreadsheet";
        } else if (element.mimeType === "text/html") {
          attachmentType = "html";
        } else if (element.mimeType === "audio/mpeg") {
          attachmentType = "audio";
        } else if (element.mimeType === "video/mp4") {
          attachmentType = "video";
        } else if (element.mimeType === "text/plain") {
          attachmentType = "plain";
        } else if (element.mimeType === "application/json") {
          attachmentType = "json";
        } else if (element.mimeType === "application/vnd.oasis.opendocument.text") {
          attachmentType = "odt";
        } else if (
          element.mimeType ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ) {
          attachmentType = "ppt";
        } else {
          // Handle other types or unknown types as needed
          attachmentType = "other";
        }

        // Create an object representing the attachment
        const attachmentData = {
          type: attachmentType,
          data: JSON.parse(decoded),
          name: element.filename,
          mimeType: element.mimeType,
          srcid: "cid:" + element.headers.filter((i) => i.name == "X-Attachment-Id")[0].value,
        };

        setAttachments((prevAttachments) => [...prevAttachments, attachmentData]);
        setAttachmentShow(true);
      } catch (error) {
        console.error("Error fetching attachment data:", error);
        setAttachmentShow(false);
      }
    }
  }

  async function processParts(parts) {
    for (let i = 0; i < parts.length; i++) {
      const element = parts[i];
      if (element.mimeType === "multipart/mixed") {
        // If the part is "multipart/mixed," process its subparts recursively
        await processParts(element.parts);
      } else if (element.mimeType === "text/html" || element.mimeType === "text/x-amp-html") {
        try {
          const encodedHtml = element.body.data;
          const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));
          const cleanedHtml = decodedHtml.replace(/[^\x20-\x7E]/g, "");
          setEmailHtml(cleanedHtml);
        } catch (error) {
          console.error("Error decoding HTML data:", error);
        }
      } else if (element.mimeType === "multipart/alternative") {
        let htmlb = element.parts.filter((f) => f.mimeType === "text/html");
        let Imgb = element.parts.filter((f) => f.mimeType.startsWith("image/"));
        try {
          const encodedHtml = htmlb[0].body.data;
          const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));
          const cleanedHtml = decodedHtml.replace(/[^\x20-\x7E]/g, "");
          setEmailHtml(cleanedHtml);
          // setEmailHtml(decodedHtml);
        } catch (error) {
          console.error("Error decoding HTML data:", error);
        }
        if (Imgb.length > 0) {
          Imgb.forEach((element) => {
            fetchAttachment(props.SelectedMailDetails.id, element);
          });
        }
      } else if (element.mimeType === "multipart/related") {
        let Malter = element.parts.filter((f) => f.mimeType.startsWith("multipart/alternative"));

        Malter.forEach((element) => {
          if (Malter.length > 0) {
            let htmlb = element.parts.filter((f) => f.mimeType === "text/html");
            let Imgb = element.parts.filter((f) => f.mimeType.startsWith("image/"));
            try {
              const encodedHtml = htmlb[0].body.data;
              const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));
              const cleanedHtml = decodedHtml.replace(/[^\x20-\x7E]/g, "");
              setEmailHtml(cleanedHtml);
              // setEmailHtml(decodedHtml);
            } catch (error) {
              console.error("Error decoding HTML data:", error);
            }
            if (Imgb.length > 0) {
              Imgb.forEach((element) => {
                fetchAttachment(props.SelectedMailDetails.id, element);
              });
            }
          }
        });

        let htmlb = element.parts.filter((f) => f.mimeType === "text/html");
        let Imgb = element.parts.filter((f) => f.mimeType.startsWith("image/"));

        try {
          const encodedHtml = htmlb[0].body.data;
          const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));
          const cleanedHtml = decodedHtml.replace(/[^\x20-\x7E]/g, "");
          setEmailHtml(cleanedHtml);
          // setEmailHtml(decodedHtml);
        } catch (error) {
          console.error("Error decoding HTML data:", error);
        }
        if (Imgb.length > 0) {
          Imgb.forEach((element) => {
            fetchAttachment(props.SelectedMailDetails.id, element);
          });
        }
      } else {
        fetchAttachment(props.SelectedMailDetails.id, element);
      }
    }
  }

  useEffect(() => {
    if (props.SelectedMailDetails && props.SelectedMailDetails.id) {
      props.SelectedMailDetails.messages.forEach((element) => {
        const parts = element.payload.parts;
        if (parts !== undefined || element.payload.mimeType === "multipart/mixed") {
          processParts(parts);
        }

        if (element.payload.mimeType === "text/html") {
          try {
            const encodedHtml = element.payload.body.data;
            const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));
            const cleanedHtml = decodedHtml.replace(/[^\x20-\x7E]/g, "");
            setEmailHtml(cleanedHtml);
            // setEmailHtml(decodedHtml);
            setAttachmentShow(true);
          } catch (error) {
            console.error("Error decoding HTML data:", error);
          }
        }

        if (element.payload.mimeType === "text/plain") {
          try {
            const encodedHtml = element.payload.body.data;
            const decodedHtml = atob(encodedHtml.replace(/-/g, "+").replace(/_/g, "/"));
            setEmailHtml(decodedHtml);
            setAttachmentShow(true);
          } catch (error) {
            setAttachmentShow(false);
            console.error("Error decoding HTML data:", error);
          }
        }
      });
    }
  }, [props.SelectedMailDetails]);

  // function fnImg(value) {
  //   var imgMain = document.getElementsByTagName("img");
  //   if (imgMain.length > 0) {
  //     Array.from(imgMain).forEach((element) => {
  //       console.log("oooooooooooooooo", element);
  //       element.src = `data:${value.mimeType};base64,${value.data.data
  //         .replace(/-/g, `+`)
  //         .replace(/_/g, `/`)}`;
  //     });
  //   }
  // }

  // function fnImg(value) {
  //   var imgMain = document.getElementsByTagName("img");
  //   if (imgMain.length > 0) {
  //     Array.from(imgMain).forEach((element) => {
  //       console.log("oooooooooooooooo", element);
  //       if (element.alt === value.name) {
  //         element.src = `data:${value.mimeType};base64,${value.data.data
  //           .replace(/-/g, `+`)
  //           .replace(/_/g, `/`)}`;
  //       }
  //     });
  //   }
  // }

  function fnImg(value) {
    let img = document.querySelectorAll('img[src="' + value.srcid + '"]');

    if (img !== null && img.length !== 0) {
      for (let i = 0; i < img.length; i++) {
        img[i].src = `data:${value.mimeType};base64,${value.data.data
          .replace(/-/g, `+`)
          .replace(/_/g, `/`)}`;
      }
    }
  }
  return (
    <Modal
      {...props}
      className="GmailModalMain"
      size="lg"
      dialogClassName="modal-50w modal-dialog-scrollable"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter" className="col-12">
          <div className="information-division">
            <h3 className="inner-header m-0">
              {props.SelectedMailDetails !== undefined &&
              props.SelectedMailDetails !== null &&
              props.SelectedMailDetails !== "" &&
              props.SelectedMailDetails
                ? props.SelectedMailDetails?.messages[0]?.payload?.headers.find(
                    (header) => header.name === "Subject"
                  )?.value
                : "(no subject)"}
            </h3>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} xl={12} className="mb-3 mb-xl-0">
            <div className="information-division">
              <div className="tab-inner-box position-relative">
                <p className="box-detials">
                  {MailDetails.IsData ? (
                    <>
                      <p
                        id="EmailHtmlData"
                        className="htmlcontentemail"
                        dangerouslySetInnerHTML={{
                          __html:
                            emailHtml !== undefined && emailHtml.replaceAll("Â&nbsp;", "&nbsp;"),
                        }}
                      ></p>
                      <div>
                        {attachments?.length > 0 &&
                          attachments.map((attachment, attachmentIndex) => (
                            <Col md={6}>
                              <div key={attachmentIndex} className="attachment mb-3 tab-content">
                                {attachment.type === "image" && (
                                  <>
                                    {fnImg(attachment)}

                                    {/* <div className="image-attachment">
                                      <FaFileImage size={48} color="blue" />
                                      <img
                                        src={`data:${
                                          attachment.mimeType
                                        };base64,${attachment.data.data
                                          .replace(/-/g, `+`)
                                          .replace(/_/g, `/`)}`}
                                        alt={attachment.name}
                                        className="image-attachment"
                                      />
                                      <p className="mt-2">{attachment.name}</p>
                                      <a
                                        href={`data:${
                                          attachment.mimeType
                                        };base64,${attachment.data.data
                                          .replace(/-/g, `+`)
                                          .replace(/_/g, `/`)}`}
                                        download={attachment.name}
                                      >
                                        <Button variant="primary mt-3">Download</Button>
                                      </a>
                                    </div> */}
                                  </>
                                )}
                                {/* {attachment.type === "pdf" && (
                                  <div>
                                    <FaFilePdf size={48} color="red" />
                                    <iframe
                                      src={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      title={attachment.name}
                                      width="100%"
                                      height="500px"
                                    />
                                    <p className="mt-2">{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "html" && (
                                  <div>
                                    <FaFileAlt size={48} color="orange" />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: attachment.data.data,
                                      }}
                                    />
                                    <p className="mt-2">{attachment.name}</p>
                                    <a
                                      href={`data:${attachment.mimeType};base64,${attachment.data.data}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "audio" && (
                                  <div>
                                    <FaFileAudio size={48} color="blue" />
                                    <audio controls>
                                      <source
                                        src={`data:application/pdf;base64,${attachment.data.data
                                          .replace(/-/g, `+`)
                                          .replace(/_/g, `/`)}`}
                                        type="audio/mpeg"
                                      />
                                      Your browser does not support the audio element.
                                    </audio>
                                    <p className="mt-2">{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "plain" && (
                                  <div>
                                    <p>{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "spreadsheet" && (
                                  <div>
                                    <FaFileCode size={48} color="blue" />
                                    <p>{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "json" && (
                                  <div>
                                    <FaFileCode size={48} color="blue" />
                                    <p>{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "video" && (
                                  <div>
                                    <FaFileVideo size={48} color="blue" />
                                    <p>{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "ppt" && (
                                  <div>
                                    <FaFilePowerpoint size={48} color="blue" />
                                    <p>{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
                                {attachment.type === "odt" && (
                                  <div>
                                    <FaFileWord size={48} color="blue" />

                                    <p>{attachment.name}</p>
                                    <a
                                      href={`data:application/pdf;base64,${attachment.data.data
                                        .replace(/-/g, `+`)
                                        .replace(/_/g, `/`)}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )}
 
                                {attachment.type === "other" && (
                                  <div>
                                    <FaFileCode size={48} color="blue" />
                                    <p>Unsupported attachment type: {attachment.mimeType}</p>
                                    <a
                                      href={`data:${attachment.mimeType};base64,${attachment.data.data}`}
                                      download={attachment.name}
                                    >
                                      <Button variant="primary mt-3">Download</Button>
                                    </a>
                                  </div>
                                )} */}
                              </div>
                            </Col>
                          ))}
                      </div>
                    </>
                  ) : (
                    <Skeleton count={20} height={20} />
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            {" "}
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          disabled={
            emailHtml !== undefined && emailHtml !== "" && emailHtml !== null ? false : true
          }
          onClick={() => setModalShowLinkWith(true)}
          variant="primary"
          // className="savebtnclient btn btn-primary float-start me-2 mt-3"
        >
          Link With &nbsp;<i className="fa-regular fa-arrow-right"></i>
        </Button>
      </Modal.Footer>
      {ModalShowLinkWith === true && (
        <GmailInboxLinkWith
          getRequestData={props.SelectedMailDetails && props.SelectedMailDetails}
          MailDetails={document.getElementById("EmailHtmlData")?.innerHTML}
          setModalShow={props.onHide}
          folder={"gmail"}
          subject={
            props.SelectedMailDetails !== undefined &&
            props.SelectedMailDetails !== null &&
            props.SelectedMailDetails !== "" &&
            props.SelectedMailDetails
              ? props.SelectedMailDetails?.messages[0]?.payload?.headers.find(
                  (header) => header.name === "Subject"
                )?.value
              : "(no subject)"
          }
          show={ModalShowLinkWith}
          onHide={() => setModalShowLinkWith(false)}
        />
      )}
    </Modal>
  );
};

export default GmailViewCommonModal;
