import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import { getMeetingRoomBookedReportList } from "../../Services/ReportServices";
import XLSX, { utils } from "sheetjs-style";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import { useNavigate } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";

const MeetingRoomBookedReportModal = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [loading, setloading] = useState(false);
  const [MACRoomBookedData, setMACRoomBookedData]: any = useState([]);
  const [MACRoomBookedStatus, setMACRoomBookedStatus] = useState([]);

  useEffect(() => {
    fetchMeetingRoomBookedData();
  }, []);

  async function fetchMeetingRoomBookedData() {
    let input: any = {
      propertyIDs: props.MeetingRoomBookedReportData.propertyIDs,
      yearID: props.MeetingRoomBookedReportData.yearID,
      monthID: props.MeetingRoomBookedReportData.monthID,
    };
    setloading(true);
    let res: any = await getMeetingRoomBookedReportList(input, Token);
    setloading(false);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          let tempStatus: any = [];
          let tempStatusSec: any = [];
          let temp: any = [];
          res.data.data.lstReportData.length &&
            res.data.data.lstReportData.map((item, index) => {
              if (
                tempStatus.includes(item["Status"]) === false &&
                item["Status"].includes("Total") === false
              ) {
                tempStatus.push(item["Status"]);
              }
            });
          setMACRoomBookedData(res.data.data.lstReportData);
          setMACRoomBookedStatus(tempStatus);
        }
      }
    }
  }

  function ExportExcel() {
    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    //For First sheet lstPositioningStatementData

    // if (MarketingReportData.lstPositioningStatementData.length) {
    let ws = XLSX.utils.json_to_sheet([], {
      header: [
        `Meeting & Catering Opportunity -  (${
          props.MeetingRoomBookedReportData.month + " " + props.MeetingRoomBookedReportData.year
        })`,
      ],
    });

    let tempArray: any = [...MACRoomBookedData];
    tempArray.forEach((e) => {
      delete e.AccountID;
    });

    // Create the required headers Title :
    let tempHead: any = [];
    let dateHead =
      props.MeetingRoomBookedReportData.month + " " + props.MeetingRoomBookedReportData.year;

    Object.keys(tempArray[0]).forEach((itm) => {
      if (itm.includes(dateHead)) {
        tempHead.push(itm.replace(dateHead, "").trim());
      } else {
        tempHead.push(itm);
      }
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
    ws["!merges"] = merge;

    // XLSX.utils.sheet_add_aoa(ws, [Object.keys(tempArray[0])], { origin: "A3" });
    XLSX.utils.sheet_add_aoa(ws, [tempHead], { origin: "A3" });
    XLSX.utils.sheet_add_json(ws, tempArray, {
      skipHeader: true,
      origin: "A4",
    });

    const range: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = range.e.r;
    const columnCount = range.e.c;

    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else if (C === 4 && R > 2) {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: "right",
              },
            };
          } else if (C === 5 && R > 2) {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: "right",
              },
            };
          } else if (C > 36 && R > 2) {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: "right",
              },
            };
          } else {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: "left",
              },
            };
          }
        }
      }
    }

    let cols1: any = [];

    Object.keys(tempArray[0]).forEach((itm: any) => {
      // cols1 = [...cols1, { wch: 22.07 }];

      if (itm === "Anticipated Revenue") {
        itm = { wch: 22.07 };
        cols1.push(itm);
      } else if (itm === "StartDate") {
        itm = { wch: 10.5 };
        cols1.push(itm);
      } else if (itm === "EndDate") {
        itm = { wch: 10.5 };
        cols1.push(itm);
      } else if (itm === "Net") {
        itm = { wch: 6.5 };
        cols1.push(itm);
      } else {
        itm = { wch: 22.07 };
        cols1.push(itm);
      }
    });
    ws["!cols"] = cols1;
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Meeting Opportunity Month view");

    let filename =
      "MeetingOpportunity" +
      props.MeetingRoomBookedReportData.month +
      props.MeetingRoomBookedReportData.year +
      ".xlsx";

    XLSX.writeFile(wb, filename);
  }

  let navigate = useNavigate();
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      "MeetingOpportunity" +
      props.MeetingRoomBookedReportData.month +
      props.MeetingRoomBookedReportData.year,
    sheet: "Meeting Catering Opportunity Month View",
  });
  let RoomBookedTable: any = () => {
    return (
      <>
        {MACRoomBookedStatus !== undefined &&
          MACRoomBookedStatus &&
          MACRoomBookedStatus.length &&
          MACRoomBookedStatus.map((itm, idx) => {
            return (
              <>
                <thead>
                  <tr>
                    <th>{itm}</th>
                    {MACRoomBookedData !== undefined &&
                      MACRoomBookedData &&
                      MACRoomBookedData.length && (
                        <>
                          <th>PropertyName</th>
                          <th>AccountName</th>
                          <th style={{ textAlign: "right" }}>Date</th>
                          {Object.keys(MACRoomBookedData[0]).map((item, index) => {
                            if (index < Object.keys(MACRoomBookedData[0]).length - 8) {
                              return <th key={index + 1}>{index + 1}</th>;
                            }
                          })}

                          <th style={{ textAlign: "right" }}>Net($)</th>
                          <th style={{ textAlign: "right" }}>Anticipated Revenue($)</th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {MACRoomBookedData.filter(
                    (item) => item.Status === itm || item.Status === itm + " Total"
                  ).map((data, index1) => {
                    {
                      return (
                        <tr className={data.Status.includes(" Total") ? "total" : ""}>
                          <td> <p className="forbreakline"> {data["OppoName"]} </p></td>
                          {MACRoomBookedData !== undefined &&
                            MACRoomBookedData &&
                            MACRoomBookedData.length && (
                              <>
                                <td> <p className="forbreakline">{data["PropertyName"]} </p></td>
                                <td>
                                <p className="forbreakline">
                                  <div
                                    className="report-content-one report-user-content"
                                    role="button"
                                    tabIndex={-1}
                                    style={{ cursor: "pointer", color: "blue" }}
                                    onClick={() => {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: data["AccountID"],
                                          AccountName: data["AccountName"],
                                        },
                                      });
                                    }}
                                    onKeyDown={() => {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: data["AccountID"],
                                          AccountName: data["AccountName"],
                                        },
                                      });
                                    }}
                                  >
                                    {data["AccountName"]}
                                  </div>
                                  </p>
                                </td>
                                <td>{data["Date"]}</td>
                                {Object.keys(MACRoomBookedData[0]).map((item, index) => {
                                  if (index < Object.keys(MACRoomBookedData[0]).length - 8) {
                                    return <td key={index + 1}><p className="onlywhitespace"> {data[index + 1]}</p></td>;
                                  }
                                })}

                                <td style={{ textAlign: "right" }}>{data["Net($)"]}</td>
                                <td style={{ textAlign: "right" }}>
                                  {data["Anticipated Revenue($)"]}
                                </td>
                              </>
                            )}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </>
            );
          })}
      </>
    );
  };

  let RoomBookedTableExportExcell: any = () => {
    return (
      <>
        <thead>
          <tr>
            <th
              colSpan={2}
              style={{ background: "#417FF9", color: "#ffffff", fontSize: "11", fontWeight: "600" }}
            >
              Meeting & Catering Opportunity -
              ({" " + props.MeetingRoomBookedReportData.month + " " + props.MeetingRoomBookedReportData.year})
            </th>
          </tr>
          <tr></tr>
        </thead>
        {MACRoomBookedStatus !== undefined &&
          MACRoomBookedStatus &&
          MACRoomBookedStatus.length &&
          MACRoomBookedStatus.map((itm, idx) => {
            return (
              <>
                <thead>
                  <tr>
                    <th style={{textAlign:"left"}}>{itm}</th>
                    {MACRoomBookedData !== undefined &&
                      MACRoomBookedData &&
                      MACRoomBookedData.length && (
                        <>
                          <th style={{textAlign:"left"}}>PropertyName</th>
                          <th style={{textAlign:"left"}}>AccountName</th>
                          <th style={{ textAlign: "right" }}>Date</th>
                          {Object.keys(MACRoomBookedData[0]).map((item, index) => {
                            if (index < Object.keys(MACRoomBookedData[0]).length - 8) {
                              return <th key={index + 1}>{index + 1}</th>;
                            }
                          })}

                          <th style={{ textAlign: "right" }}>Net($)</th>
                          <th style={{ textAlign: "right" }}>Anticipated Revenue($)</th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {MACRoomBookedData.filter(
                    (item) => item.Status === itm || item.Status === itm + " Total"
                  ).map((data, index1) => {
                    {
                      return (
                        <tr className={data.Status.includes(" Total") ? "total" : ""}>
                          <td>{data["OppoName"]}</td>
                          {MACRoomBookedData !== undefined &&
                            MACRoomBookedData &&
                            MACRoomBookedData.length && (
                              <>
                                <td>{data["PropertyName"]}</td>
                                <td>
                                  <div
                                    className="report-content-one report-user-content"
                                    role="button"
                                    tabIndex={-1}
                                    style={{ cursor: "pointer", color: "blue" }}
                                    onClick={() => {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: data["AccountID"],
                                          AccountName: data["AccountName"],
                                        },
                                      });
                                    }}
                                    onKeyDown={() => {
                                      navigate("/accounts-view", {
                                        state: {
                                          AccountID: data["AccountID"],
                                          AccountName: data["AccountName"],
                                        },
                                      });
                                    }}
                                  >
                                    {data["AccountName"]}
                                  </div>
                                </td>
                                <td>{data["Date"]}</td>
                                {Object.keys(MACRoomBookedData[0]).map((item, index) => {
                                  if (index < Object.keys(MACRoomBookedData[0]).length - 8) {
                                    return <td key={index + 1}>{data[index + 1]}</td>;
                                  }
                                })}

                                <td style={{ textAlign: "right" }}>{data["Net($)"]}</td>
                                <td style={{ textAlign: "right" }}>
                                  {data["Anticipated Revenue($)"]}
                                </td>
                              </>
                            )}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>
            Meeting & Catering Opportunity - (
            {props.MeetingRoomBookedReportData.month && props.MeetingRoomBookedReportData.month}{" "}
            {props.MeetingRoomBookedReportData.year && props.MeetingRoomBookedReportData.year})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col xs={12} xl={12} className="mb-3 mb-xl-0">
              <div className="information-division">
                <h3 className="inner-header m-0">Meeting & Catering Opportunity </h3>
                <div className="tab-inner-box position-relative">
                  {/* <div className="customtablemetting"> */}
                  {!loading ? (
                    MACRoomBookedData.length ? (
                      <Table responsive bordered className="table-without-space mb-0">
                        <RoomBookedTable />
                      </Table>
                    ) : (
                      <>
                        <div className="opportunity">
                          <div className="noRecordFound">
                            <img src={noRecordFound} alt="" />
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <PageLoader />
                    </>
                  )}
                  {/* </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary ms-0"
            disabled={MACRoomBookedData.length ? false : true}
            onClick={onDownload}
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
        </Modal.Footer>
      </Modal>
      <Table
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <RoomBookedTableExportExcell />
      </Table>
    </>
  );
};

export default MeetingRoomBookedReportModal;
