import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import TableView from "../../../../Component/Table/Index";
import { getDashboardUserList } from "../../../../Services/DashboardServices";
import { profileGetActions } from "../../../../Services/ProfileServices";

export default function RecActions() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;
  const SelectedPropertyIDs: any = useSelector((state: any) => state.dashboard);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const U_UserTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;

  const [userSelect, setUserSelect]: any = useState(userId);
  const [UserListData, setUserListData]: any = React.useState({
    IsData: true,
    data: [],
  });

  const recentActionTableColumns = React.useMemo(
    () => [
      {
        Header: "Action Date",
        accessor: "actionDate",
      },
      {
        Header: "Module",
        accessor: "module",
      },
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Action By",
        accessor: "actionBy",
      },
      {
        Header: "Details",
        accessor: "details",
      },
    ],
    []
  );

  let initialUser: any = [
    {
      label: dataSelector.auth_UserDetail.U_FName + dataSelector.auth_UserDetail.U_LName,
      value: userId,
    },
  ];

  UserListData.data !== undefined &&
    UserListData.data &&
    UserListData.data.length > 0 &&
    UserListData.data.map((item: any) => {
      initialUser.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  const recentActionTableData: any = [];
  data.map((itm: any) => {
    recentActionTableData.push({
      actionDate: moment(itm.ActionDate).format("MM-DD-YYYY h:mm A"),
      details: itm.Details,
      actionBy: itm.UserName,
      action: itm.OperationType_Term,
      module: itm.Module,
    });
  });

  const fetchData = async (userId) => {
    let input = {
      keyID: userId,
    };

    setIsLoading(true);
    let res: any = await profileGetActions(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== null && typeof res.data.data !== "undefined") {
          setData(res.data.data.dataList1);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  async function getAssignedToData(selectedHotelId) {
    let inputs = {
      userID: dataSelector.auth_UserDetail.U_UserID,
      propertyIDs: selectedHotelId,
    };

    setUserListData({ IsData: false, data: [] });
    const res: any = await getDashboardUserList(inputs, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined) {
        setUserListData({ IsData: false, data: res.data.data.dataList1 });
      }
    }
  }

  async function handleUser(e: any) {
    setUserSelect(e.value);
    fetchData(e.value);
  }

  useEffect(() => {
    setUserSelect(userId);
    if (SelectedPropertyIDs.SelectedPropertyID.length > 0) {
      getAssignedToData(SelectedPropertyIDs.SelectedPropertyID);
    }
    fetchData(userId);
  }, [SelectedPropertyIDs.SelectedPropertyID]);

  return (
    <>
      {U_UserTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3" && (
        <Row className="mb-2">
          <Col xl={8}></Col>
          <Col xl={4}>
            <FloatingLabel controlId="floatingSelectGrid" label="">
              <Select
                placeholder="Select User"
                className="multiselect"
                onChange={(e: any) => handleUser(e)}
                value={initialUser.filter((obj) => userSelect && userSelect.includes(obj.value))}
                options={initialUser}
                defaultValue={initialUser.filter(
                  (obj) => userSelect && userSelect.includes(obj.value)
                )}
              />
            </FloatingLabel>
          </Col>
        </Row>
      )}

      <Row>
        <Col xl={12}>
          <TableView
            columnsData={recentActionTableColumns}
            tableData={recentActionTableData}
            isData={isLoading}
          />
        </Col>
      </Row>
    </>
  );
}
