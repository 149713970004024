import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import TableView from "../../../Component/Table/Index";
import { AddUserRoleModal } from "../../../Model/AddUserRoleModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../../Services/CommonServices";
import {
  DeleteUserRoleRights,
  getUserRoleList,
} from "../../../Services/Setup&Config/UserSetupService";

function UsersRole() {
  let page_rights_UserRole = CommonService.getPageRight("UserRole");

  let [UserRoleList, setUserRoleList] = React.useState({ IsData: true, data: [] });
  let [EditRoleID, setEditRoleID] = React.useState(null);
  const [modalShowuserrole, setModalShowuserrole] = React.useState(false);
  const roleTableHeader = React.useMemo(
    () => [
      {
        Header: "Role Code",
        accessor: "rolecode",
        className: "w-300",
      },
      {
        Header: "User Role",
        accessor: "roleName",
        className: "w-200",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "actionsort table-action sticky-action",
      },
    ],
    []
  );
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("userRoleFilter"))) {
      HandleRemoveOtherFilters("userRoleFilter");
      HandleFilters("userRoleFilter", {
        pagePath: "/SetupAndConfigurations/userRole",
        pageName: "userRole",
        filters: [],
        isSort: [],
      });
    }
    getRoleListData();
  }, []);

  async function getRoleListData() {
    let inputs = {
      roleCode: null,
      roleName: null,
      clientID: clientID,
    };
    setUserRoleList({ IsData: true, data: [] });
    const res: any = await getUserRoleList(inputs, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setUserRoleList({ IsData: false, data: res.data.data.dataList1 });
      }
    }
  }
  let roleTableData: any = [];
  UserRoleList.data.map((item: any) => {
    roleTableData.push({
      className: {
        action: "table-action sticky-action",
      },
      rolecode: item.RoleCode,
      roleName: item.RoleName,
      action: (
        <div className="btn-icon-grp-table justify-content-end align-items-center">
          <Button
            className="btn-icon btn button btn btn-primary editIcon acteditbtn"
            onClick={() => {
              if (page_rights_UserRole.is_update) {
                getEditData(item);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
          {item.IsDeletable === true ? (
            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (page_rights_UserRole.is_delete) {
                  DeleteUserRole(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          ) : (
            ""
          )}
        </div>
      ),
    });
  });

  async function getEditData(data: any) {
    setEditRoleID(data.RoleID);
    setModalShowuserrole(true);
  }

  function DeleteUserRole(data: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let inputs = {
          keyID: data.RoleID,
        };
        const res: any = await DeleteUserRoleRights(inputs, Token);

        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              ToasterSuccess("User Role Deleted Successfully");

              getRoleListData();
            } else if (result.isDenied) {
              ToasterError(res.message);
            }
          }
        }
      }
    });
  }

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add User Role</Tooltip>}>
          <Button
            onClick={() => {
              if (page_rights_UserRole.is_create) {
                setModalShowuserrole(true);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            className="btn-icon addFormIcon"
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <Row>
          <Col xs={12} className="portfolio-Table">
            <div className="lead-height-table">
              <TableView
                columnsData={roleTableHeader}
                tableData={roleTableData}
                isData={UserRoleList.IsData}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modalShowuserrole && (
        <AddUserRoleModal
          show={modalShowuserrole}
          onHide={() => {
            setModalShowuserrole(false);
            setEditRoleID(null);
          }}
          roleID={EditRoleID}
          getRoleListData={getRoleListData}
        ></AddUserRoleModal>
      )}
    </>
  );
}

export default UsersRole;
