import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import { getPortfolioDataByID, SaveOrUpdatePortfolio } from "../Services/PortFolioServices";

const AddPortFolio = (props) => {
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  let initials: any = {
    portfolioID: "",
    portfolioName: "",
    // isAllPortfolio: "",
    // portfolioCode: "",
    errors: {
      portfolioName: "",
      ValidationRules: [
        {
          FieldName: "portfolioName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  };
  const [PortFolioSave, setPortFolioSave]: any = useState({
    portfolioID: "00000000-0000-0000-0000-000000000000",
    portfolioName: "",
    isAllPortfolio: false,
    portfolioCode: "",
    file: "",
    errors: {
      portfolioName: "",
      ValidationRules: [
        {
          FieldName: "portfolioName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  // Upload LOGO :
  const [Logo, setLogo]: any = useState({
    name: "",
    link: [],
    errors: "",
  });
  const [LogoUpload, setLogoUpload]: any = useState(null);

  function SelectLogo(e: any) {
    if (e.target.files.length !== 0) {
      if (e.target.files[0].name.match(/\.(jpg|jpeg|png|webp)$/)) {
        setLogo({ ...Logo, ["link"]: e.target.files });

        const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
        setLogoUpload(fileLoaded);
      } else {
        ToasterError("Please select .jpg|.jpeg|.png|.webp");
      }
    }
  }

  React.useEffect(() => {
    if (props.portfolioID !== "" && props.portfolioID !== undefined) {
      getPortfolio();
    }
  }, []);

  async function getPortfolio() {
    let input: any = {
      keyID: props && props.portfolioID,
    };
    setOnProcessHide(true);
    let res: any = await getPortfolioDataByID(input, props.Token);
    setOnProcessHide(false);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
        setLogoUpload(res.data.data.portfolioLogo);
        setPortFolioSave({
          ...PortFolioSave,
          ["portfolioID"]: res.data.data.portfolioID,
          ["portfolioName"]: res.data.data.portfolioName,
        });
      }
    }
  }

  async function SavePortFolio() {
    let obj = fnCheckValidationOfObject(PortFolioSave);
    setPortFolioSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      if (PortFolioSave.portfolioID !== "") {
        // Update
        let formData: any = new FormData();
        formData.append("PortfolioID", PortFolioSave.portfolioID);
        formData.append("PortfolioName", PortFolioSave.portfolioName);
        formData.append("IsAllPortfolio", PortFolioSave.isAllPortfolio);
        formData.append("PortfolioCode", PortFolioSave.portfolioCode);
        formData.append("file", Logo.link[0] !== undefined ? Logo.link[0] : null);

        let Message: any = "PortFolio Saved SuccessFully";
        setOnProcessHide(true);
        // const res: any = await SaveOrUpdatePortfolio(PortFolioSave, props.Token);
        const res: any = await SaveOrUpdatePortfolio(formData, props.Token);
        setOnProcessHide(false);
        if (res.data !== undefined) {
          if (res.data.statusCode === 200) {
            ToasterSuccess(Message);
            props.GetPortFolioList();
            handleClose();
            props.onHide(false);
          }
        } else {
          ToasterError(res.message);
        }
      } else {
        // Add
        // let input: any = {
        //   portfolioName: PortFolioSave.portfolioName,
        // };
        let formData: any = new FormData();
        formData.append("PortfolioID", PortFolioSave.portfolioID);
        formData.append("PortfolioName", PortFolioSave.portfolioName);
        formData.append("IsAllPortfolio", PortFolioSave.isAllPortfolio);
        formData.append("PortfolioCode", PortFolioSave.portfolioCode);
        formData.append("file", Logo.link[0]);

        let Message: any = "PortFolio Updated SuccessFully";
        setOnProcessHide(true);
        // const res: any = await SaveOrUpdatePortfolio(input, props.Token);
        const res: any = await SaveOrUpdatePortfolio(formData, props.Token);
        setOnProcessHide(false);
        if (res.data !== undefined) {
          if (res.data.statusCode === 200) {
            ToasterSuccess(Message);
            props.GetPortFolioList();
            handleClose();
            props.onHide(false);
          }
        } else {
          ToasterError(res.message);
        }
      }
    }
  }

  const handleClose = () => {
    setPortFolioSave(initials);
    props.onHide(false);
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={props.hotelAddPortfolio ? "modal-30w modal-dialog-centered" : "modal-50w"}
        className={
          props.hotelAddPortfolio ? "quickAdd-popup competitor_hotel_center" : "quickAdd-popup"
        }
        scrollable={true}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Portfolio</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col xs={props.hotelAddPortfolio ? 12 : 6} className="mb-3">
                <FloatingLabel controlId="floatingSelectGrid" label="Portfolio Name">
                  <Form.Control
                    type="text"
                    maxLength={369}
                    placeholder="Portfolio Name"
                    name="portfolioName"
                    value={PortFolioSave.portfolioName}
                    onChange={(event) =>
                      setPortFolioSave({
                        ...PortFolioSave,
                        ["portfolioName"]: event.target.value,
                      })
                    }
                    className={`form-control ${
                      PortFolioSave.errors && PortFolioSave.errors.portfolioName && "is-invalid"
                    }`}
                  />
                  {PortFolioSave.errors && PortFolioSave.errors.portfolioName && (
                    <Form.Control.Feedback type="invalid">
                      {PortFolioSave.errors.portfolioName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={props.hotelAddPortfolio ? 12 : 6} className="mb-3">
                {/* <h2 className="paymenttitle">Logo</h2> */}
                <div className="custom-file comaninputfile">
                  {LogoUpload && (
                    <img
                      src={LogoUpload}
                      style={{
                        display: "flex",
                        border: "2px solid tomato",
                        maxWidth: "300px",
                        maxHeight: "300px",
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div className="comninputbtn">
                  <span>Select Logo</span>
                  <input type="file" onChange={SelectLogo} accept="image/*" id="inputGroupFile01" />
                </div>
              </Col>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => SavePortFolio()}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddPortFolio;
