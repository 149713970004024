import React from "react";
import axios from "axios";
import { AppConfigData } from "./CommonServices";
import { Cookies } from "react-cookie-consent";

export const Login = async (ReqData) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "authmanagement/api/Auth/ClientLogin",
      data: ReqData,
      // headers: {
      //   "Content-Type": "application/json",
      //   accept: "*/*",
      //   // Authorization: "Bearer ",
      // },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const ForgatePassword = async (ReqData) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/User/ForgetPassword",
      data: ReqData,
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: "bearer " + accessToken,
      // },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const VerifyClientLogin = async (ReqData) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "authmanagement/api/Auth/VerifyClientLogin",
      data: ReqData,
      // headers: {
      //   "Content-Type": "application/json",
      //   accept: "*/*",
      //   // Authorization: "Bearer ",
      // },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};

export const VerifyClientOTP = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/User/VerifyOTP",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const SendUserOTPEmail = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/User/SendOTPEmail",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const Logout = () => {
  return <div>Logout</div>;
};
