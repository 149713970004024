import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { Button, Modal, Row, Table, Col, Card, Container } from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import moment, { isMoment } from "moment";
import XLSX from "sheetjs-style";
import NoDataFoundImage from "../../Component/NoData/NoDataFoundImage";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  InteractionItem,
} from "chart.js";
import {
  Bar,
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import {
  LostBusinessGridReportList,
  RFPGridReportList,
  TraceReportData,
} from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";

import HardRet from "../../Asset/Images/Other/hard-ret.svg";
import ReportAccounts from "../../Asset/Images/Other/report-accounts.svg";
import ReportCalendar from "../../Asset/Images/Other/report-calendar.svg";
import ReportEmail from "../../Asset/Images/Other/report-email.svg";
import ReportNormal from "../../Asset/Images/Other/report-normal.svg";
import ReportNots from "../../Asset/Images/Other/report-nots.svg";
import ReportUserCall from "../../Asset/Images/Other/report-user-call.svg";
import ReportUser from "../../Asset/Images/Other/report-user.svg";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  getDateInFormat,
  getDateTimeInDBFormat,
  getDateInDBFormatForExcell,
  getSortDataByObjKey,
} from "../../Services/CommonServices";
import TableView from "../../Component/Table/Index";
import { useNavigate } from "react-router-dom";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import { getSalesFunnnelGridReportList } from "../../Services/DashboardServices";

const OpportunitySalesFunnelReportModal = ({ ReportInput, showReport, setShowReport }) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [loading, setLoading] = useState(false);
  const [ReportData, setReportData]: any = useState([]);
  const navigate = useNavigate();

  let page_rights = CommonService.getPageRight("Account");
  let page_rights_RFP = CommonService.getPageRight("RFP");
  let page_rights_account = CommonService.getPageRight("Account");
  let SalesFunnelReport_page_rights = CommonService.getPageRight("SalesFunnelReport");

  const [pageLoading, setPageLoading] = useState(true);
  const [yearList, setYearList] = useState([]);
  const [selectedYear, setSelectedYear] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [totalRecord, setTotalRecord] = useState([]);

  const fetchSalesFunnelList = async () => {
    let input = {
      oppoOwnerIDs: ReportInput.oppoOwnerIDs,
      propertyIDs: ReportInput.propertyIDs,
      status: ReportInput.status,
      year: ReportInput.year,
    };

    setPageLoading(true);
    let res = await getSalesFunnnelGridReportList(input, Token);

    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstGridData !== undefined &&
            res.data.data.lstGridData &&
            res.data.data.lstGridData.length
          ) {
            setTotalRecord(res.data.data.lstOppoCountData);
            setData(res.data.data.lstGridData);
            setPageLoading(false);
          } else {
            setData([]);
            setTotalRecord([]);
            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      } else {
        setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      setLoading(false);
      ToasterError(res.data.message);
    }
  };

  const tablRFPeGridColumns = React.useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "PropertyName",
      },
      {
        Header: "Account Name",
        accessor: "AccountName",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Opportunity Type",
        accessor: "OpportunityType",
      },
      {
        Header: "Opportunity",
        accessor: "OppoName",
      },
      {
        Header: "Oppo Owner",
        accessor: "OppoOwner",
      },
      {
        Header: "Total Anticipated Revenue",
        accessor: "TotalAnticipatedRevenue",
      },
      {
        Header: "Rate StartDate",
        accessor: "RateStartDate",
      },
      {
        Header: "Rate EndDate",
        accessor: "RateEndDate",
      },
      {
        Header: "Client Target Rate",
        accessor: "ClientTargetRate",
      },
      {
        Header: "RNS Goal",
        accessor: "RNSGoal",
      },
      {
        Header: "LostReason",
        accessor: "LostReason",
      },
    ],
    []
  );

  const tablRFPeGridData: any = [];
  var rightAlignClass = { textAlign: "right" };
  var centerAlignClass = { textAlign: "center" };

  data.length > 0 &&
    data.map((item: any, index: any) => {
      tablRFPeGridData.push({
        className: {
          RateStartDate: "rightAlignClass text-end",
          RateEndDate: "rightAlignClass text-end",
          TotalAnticipatedRevenue: "rightAlignClass text-end",
          ClientTargetRate: "rightAlignClass text-end",
          RNSGoal: "centerAlignClass text-center",
        },
        controlRender: {
          AccountName: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (page_rights.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              onKeyDown={() => {
                if (page_rights.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              {item.AccountName ? item.AccountName.replace("#", " ") : ""}
            </div>
          ),
          RateStartDate: getDateInFormat(item.RateStartDate),
          RateEndDate: getDateInFormat(item.RateEndDate),
        },
        AccountName: item.AccountName,
        ClientTargetRate: item.ClientTargetRate,
        LostReason: item.LostReason,
        OppoName: item.OppoName,
        OppoOwner: item.OppoOwner,
        OpportunityType: item.OpportunityType,
        PropertyName: item.PropertyName,
        RNSGoal: item.RNSGoal,
        RateEndDate: getDateTimeInDBFormat(item.RateEndDate),
        RateStartDate: getDateTimeInDBFormat(item.RateStartDate),
        Source: item.Source,
        TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
      });
    });

  useEffect(() => {
    fetchSalesFunnelList();
  }, []);

  const handleClosePopup = () => {
    setShowReport(false);
  };

  async function getFilteredExportData(newArray) {
    let localFilters = JSON.parse(localStorage.getItem("salesFunnelReportFilter"));
    let mainTableData: any = [];
    newArray !== undefined &&
      newArray !== null &&
      newArray.map((item: any) => {
        mainTableData.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
          Source: item.Source,
          OppoName: item.OppoName,
          OppoOwner: item.OppoOwner,
          OpportunityType: item.OpportunityType,
          TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
          RateStartDate: getDateInDBFormatForExcell(item.RateStartDate),
          RateEndDate: getDateInDBFormatForExcell(item.RateEndDate),
          ClientTargetRate: item.ClientTargetRate,
          RNSGoal: item.RNSGoal,
          LostReason: item.LostReason,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
            Source: item.Source,
            OppoName: item.OppoName,
            OppoOwner: item.OppoOwner,
            OpportunityType: item.OpportunityType,
            TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            ClientTargetRate: item.ClientTargetRate,
            RNSGoal: item.RNSGoal,
            LostReason: item.LostReason,
          });
        });

        return ascDataBind;
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
            Source: item.Source,
            OppoName: item.OppoName,
            OppoOwner: item.OppoOwner,
            OpportunityType: item.OpportunityType,
            TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            ClientTargetRate: item.ClientTargetRate,
            RNSGoal: item.RNSGoal,
            LostReason: item.LostReason,
          });
        });

        return descDataBind;
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            PropertyName: item.PropertyName,
            AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
            Source: item.Source,
            OppoName: item.OppoName,
            OppoOwner: item.OppoOwner,
            OpportunityType: item.OpportunityType,
            TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
            RateStartDate: item.RateStartDate,
            RateEndDate: item.RateEndDate,
            ClientTargetRate: item.ClientTargetRate,
            RNSGoal: item.RNSGoal,
            LostReason: item.LostReason,
          });
        });

        return normalDataBind;
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          PropertyName: item.PropertyName,
          AccountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
          Source: item.Source,
          OppoName: item.OppoName,
          OppoOwner: item.OppoOwner,
          OpportunityType: item.OpportunityType,
          TotalAnticipatedRevenue: item.TotalAnticipatedRevenue,
          RateStartDate: item.RateStartDate,
          RateEndDate: item.RateEndDate,
          ClientTargetRate: item.ClientTargetRate,
          RNSGoal: item.RNSGoal,
          LostReason: item.LostReason,
        });
      });
      return normalInitDataBind;
    }
  }

  const exportData = async () => {
    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    let ws = XLSX.utils.json_to_sheet([], {
      // header: [`RFP Grid - ${selectedYear.label}`],
      header: [
        `Opportunity Sales Funnel Report  (${ReportInput.status + ", " + ReportInput.year})`,
      ],
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
    ws["!merges"] = merge;

    XLSX.utils.sheet_add_aoa(ws, [Object.keys(totalRecord[0])], { origin: "A3" });
    XLSX.utils.sheet_add_json(ws, totalRecord, {
      skipHeader: true,
      origin: "A4",
    });

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    for (var R = rangeTotalAccounts.s.r; R <= rangeTotalAccounts.e.r; ++R) {
      for (var C = rangeTotalAccounts.s.c; C <= rangeTotalAccounts.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: C === 1 || C === 2 ? "right" : "left",
              },
            };
          }
        }
      }
    }
    const newArray = data.map(({ AccountID, ...keepAttrs }) => keepAttrs);

    // ------------------- Sort Data By Filter :

    let newArraySortedData = await getFilteredExportData(newArray);

    // ------------------- Sort Data By Filter :

    XLSX.utils.sheet_add_aoa(
      ws,
      [Object.keys(newArraySortedData[0]).filter((obj) => obj !== "AccountID")],
      {
        origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 3),
      }
    );
    XLSX.utils.sheet_add_json(ws, newArraySortedData, {
      header: Object.keys(newArraySortedData[0]).filter((obj) => obj !== "AccountID"),
      skipHeader: true,
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 4),
    });

    const rowDataCount: any = XLSX.utils.decode_range(ws["!ref"] ?? "");

    for (var R = rowCount + 2; R <= rowDataCount.e.r; ++R) {
      for (var C = rowDataCount.s.c; C <= rowDataCount.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (typeof ws[cell_ref] !== "undefined") {
          if (rowCount + 2 === R) {
            ws[cell_ref].s = headStylesCell;
          }
          // else if(R ===  2){
          //   ws[cell_ref].s = headStylesCell
          // }
          else {
            // Comented Old one
            // let checkArray = [6, 7, 8, 9, 10];
            // ws[cell_ref].s = {
            //   ...cellStyle,
            //   alignment: {
            //     ...cellStyle.alignment,
            //     horizontal: checkArray.includes(C) ? "right" : "left",
            //   },
            // };
            let checkForRightArray = [6, 7, 8, 9];
            let checkForCenterArray = [10];

            if (checkForRightArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "right",
                },
              };
            } else if (checkForCenterArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "center",
                },
              };
            }
          }
        }
      }
    }
    // for (var R = headRange.s.r; R <= headRange.e.r; ++R) {
    //   for (var C = headRange.s.c; C <= headRange.e.c; ++C) {
    //     var cell_address = { c: C, r: R };
    //     var cell_ref = XLSX.utils.encode_cell(cell_address);

    //     ws[cell_ref].s = {
    //       font: { bold: true },
    //       border: {
    //         top: { style: "thin", color: { auto: 1 } },
    //         bottom: { style: "thin", color: { auto: 1 } },
    //         left: { style: "thin", color: { auto: 1 } },
    //         right: { style: "thin", color: { auto: 1 } }
    //       }
    //     };
    //   }
    // }
    // const rangeTotalRecords = { s: { c: 0, r: 3 }, e: { c: Object.keys(totalRecord[0]).length - 1, r: 3 } };

    // for (var R = rangeTotalRecords.s.r; R <= rangeTotalRecords.e.r; ++R) {
    //   for (var C = rangeTotalRecords.s.c; C <= rangeTotalRecords.e.c; ++C) {
    //     var cell_address = { c: C, r: R };
    //     var cell_ref = XLSX.utils.encode_cell(cell_address);

    //     ws[cell_ref].s = {
    //       font: { bold: true },
    //       border: {
    //         top: { style: "thin", color: { auto: 1 } },
    //         bottom: { style: "thin", color: { auto: 1 } },
    //         left: { style: "thin", color: { auto: 1 } },
    //         right: { style: "thin", color: { auto: 1 } }
    //       }
    //     };
    //   }
    // }

    let cols: any = [];

    Object.keys(newArraySortedData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    let filename = `Sales Funnel (${moment(new Date()).format("MM-DD-YYYY")}).xlsx`;

    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename);
  };

  return (
    <Modal
      show={showReport}
      onHide={() => {
        handleClosePopup();
      }}
      fullscreen
      aria-labelledby="contained-modal-title-vcenter"
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Opportunity Sales Funnel Report - ({ReportInput.status + ", "}
          {ReportInput.year})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          {!pageLoading ? (
            <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
              <TableView
                isData={loading}
                columnsData={tablRFPeGridColumns}
                tableData={tablRFPeGridData}
              />
            </Col>
          ) : (
            <PageLoader />
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={handleClosePopup}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          disabled={data.length && !loading ? false : true}
          onClick={(e) => {
            if (SalesFunnelReport_page_rights.is_export) {
              e.preventDefault();
              if (data.length && !loading) {
                exportData();
              }
            } else {
              CommonService.unauthorizedAction();
            }
          }}
          type="button"
          variant="primary"
        >
          <span className="me-2">
            <img src={ExportIcon} alt="" />
          </span>
          Export Excel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OpportunitySalesFunnelReportModal;
