import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  getWeeklyReportList,
  MeetingCateringByDateReportList,
  OpportunityBySourceReportList,
} from "../../Services/ReportServices";
import XLSX, { utils } from "sheetjs-style";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { Button, Modal } from "react-bootstrap";
import TableView from "../../Component/Table/Index";
import {
  CommonService,
  getDateInFormat,
  getDateTimeInDBFormat,
  getDateInDBFormatForExcell,
  getSortDataByObjKey,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
} from "../../Services/CommonServices";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import { useNavigate } from "react-router-dom";

function MeetingAndCateringByDateModal(props: any) {
  const [loading, setLoading] = useState(false);

  const [MeetingAndCateringByDateTotalRecord, setMeetingAndCateringByDateTotalRecord]: any =
    useState([]);

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [dateTitle, setdateTitle]: any = useState({
    stDate: "",
    endDate: "",
  });

  React.useEffect(() => {
    if (props.MeetingAndCateringTitle) {
      // props.MeetingAndCateringTitle !== undefined &&
      //   props.MeetingAndCateringTitle !== null &&
      //   props.MeetingAndCateringTitle !== "" &&
      //   props.MeetingAndCateringTitle.startDate + "-" +
      //   props.MeetingAndCateringTitle.endDate
      setdateTitle({
        ...dateTitle,
        stDate: getDateInFormat(props.MeetingAndCateringTitle.startDate),
        endDate: getDateInFormat(props.MeetingAndCateringTitle.endDate),
      });
    }

    getMeetingAndCateringByDate();
  }, []);

  const tableMeetingAndCateringByDateHeader = useMemo(
    () => [
      {
        Header: "Start Date",
        accessor: "StartDate",
      },
      {
        Header: "End Date",
        accessor: "EndDate",
      },
      {
        Header: "Day Of Week",
        accessor: "DayOfWeek",
      },
      {
        Header: "Account Name",
        accessor: "AccountName",
      },
      {
        Header: "Opportunity Name",
        accessor: "OpportunityName",
      },
      {
        Header: "Event Date Details",
        accessor: "EventDateDetails",
      },
      {
        Header: "Event Start Time",
        accessor: "EventStartTime",
      },
      {
        Header: "Event End Time",
        accessor: "EventEndTime",
      },
      {
        Header: "Meeting Room",
        accessor: "MeetingRoom",
      },
      {
        Header: "Setup",
        accessor: "Setup",
      },
      {
        Header: "Meeting Room Revenue",
        accessor: "MeetingRoomRevenue",
      },
      {
        Header: "No Of People",
        accessor: "NoOfPeople",
      },
      {
        Header: "Is Food(Y/N)",
        accessor: "IsFood",
      },
      {
        Header: "Total Anticipated Food Revenue",
        accessor: "TotalAnticipatedFoodRevenue",
      },
      {
        Header: "Is Beverage(Y/N)",
        accessor: "IsBeverage",
      },
      {
        Header: "Total Anticipated Beverage Revenue",
        accessor: "TotalAnticipatedBeverageRevenue",
      },
      {
        Header: "TotalAnticipated F&B Revenue",
        accessor: "TotalAnticipatedFBRevenue",
      },
      {
        Header: "Is AVEquipment(Y/N)",
        accessor: "IsAVEquipment",
      },
      {
        Header: "Total Anticipated AV & MIS Revenue",
        accessor: "TotalAnticipatedAVMISRevenue",
      },
    ],
    []
  );

  let page_rights_account = CommonService.getPageRight("Account");
  let navigate = useNavigate();

  let tableMeetingAndCateringByDateData: any = [];

  MeetingAndCateringByDateTotalRecord.length > 0 &&
    MeetingAndCateringByDateTotalRecord.map((item) => {
      tableMeetingAndCateringByDateData.push({
        controlRender: {
          AccountName: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                if (page_rights_account.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              onKeyDown={() => {
                if (page_rights_account.is_view) {
                  navigate("/accounts-view", {
                    state: { AccountID: item.AccountID, AccountName: item.AccountName },
                  });
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            >
              {item.AccountName ? item.AccountName.replace("#", " ") : ""}
            </div>
          ),
          StartDate: getDateInFormat(item.StartDate),
          EndDate: getDateInFormat(item.EndDate),
          EventDateDetails: getDateInFormat(item.EventDateDetails),
        },
        className: {
          action: "table-action sticky-action",
          StartDate: "text-end",
          EndDate: "text-end",
          EventStartTime: "text-end",
          EventEndTime: "text-end",
          EventDateDetails: "text-end",
          MeetingRoomRevenue: "text-end",
          TotalAnticipatedFoodRevenue: "text-end",
          TotalAnticipatedAVMISRevenue: "text-end",
          TotalAnticipatedBeverageRevenue: "text-end",
          TotalAnticipatedFBRevenue: "text-end",
          NoOfPeople: "text-center",
        },
        StartDate: getDateTimeInDBFormat(item.StartDate),
        EndDate: getDateTimeInDBFormat(item.EndDate),
        DayOfWeek: item.DayOfWeek,
        AccountName: item.AccountName,
        OpportunityName: item.OppoName,
        EventDateDetails: getDateTimeInDBFormat(item.EventDateDetails),
        EventStartTime: item.StartTime,
        EventEndTime: item.EndTime,
        MeetingRoom: item.MeetingRoom,
        Setup: item.Setup,
        MeetingRoomRevenue: "$" + item.MeetingRoomRevenue,
        NoOfPeople: item.NumberOfPeople,
        IsFood: item.IsFood,
        TotalAnticipatedFoodRevenue: "$" + item.FoodRevenue,
        IsBeverage: item.IsBeverage,
        TotalAnticipatedBeverageRevenue: "$" + item.BeverageRevenue,
        TotalAnticipatedFBRevenue: "$" + item.FoodBeverageRevenue,
        IsAVEquipment: item.IsAVEquipment,
        TotalAnticipatedAVMISRevenue: "$" + item.AvEquipmentRevenue,
      });
    });

  async function getMeetingAndCateringByDate() {
    setLoading(true);
    let res: any = await MeetingCateringByDateReportList(
      props.MeetingAndCateringByDateReport,
      Token
    );

    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstReportData !== undefined &&
            res.data.data.lstReportData &&
            res.data.data.lstReportData.length
          ) {
            setMeetingAndCateringByDateTotalRecord(res.data.data.lstReportData);
            setLoading(false);
          } else {
            setMeetingAndCateringByDateTotalRecord([]);
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      setLoading(false);
      ToasterError(res.data.message);
    }
  }

  async function getFilteredExportData(newArray) {
    let localFilters = JSON.parse(localStorage.getItem("meetingCateringByDateReportFilter"));
    let mainTableData: any = [];
    newArray !== undefined &&
      newArray !== null &&
      newArray.map((item: any) => {
        mainTableData.push({
          StartDate: getDateInDBFormatForExcell(item.StartDate),
          EndDate: getDateInDBFormatForExcell(item.EndDate),
          DayOfWeek: item.DayOfWeek,
          AccountName: item.AccountName,
          OpportunityName: item.OpportunityName,
          EventDateDetails: getDateInDBFormatForExcell(item.EventDateDetails),
          EventStartTime: item.EventStartTime,
          EventEndTime: item.EventEndTime,
          MeetingRoom: item.MeetingRoom,
          Setup: item.Setup,
          MeetingRoomRevenue: "$" + item.MeetingRoomRevenue,
          NoOfPeople: item.NoOfPeople,
          IsFood: item.IsFood,
          TotalAnticipatedFoodRevenue: "$" + item.TotalAnticipatedFoodRevenue,
          IsBeverage: item.IsBeverage,
          TotalAnticipatedBeverageRevenue: "$" + item.TotalAnticipatedBeverageRevenue,
          TotalAnticipatedFBRevenue: "$" + item.TotalAnticipatedFBRevenue,
          IsAVEquipment: item.IsAVEquipment,
          TotalAnticipatedAVMISRevenue: "$" + item.TotalAnticipatedAVMISRevenue,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            StartDate: item.StartDate,
            EndDate: item.EndDate,
            DayOfWeek: item.DayOfWeek,
            AccountName: item.AccountName,
            OpportunityName: item.OpportunityName,
            EventDateDetails: item.EventDateDetails,
            EventStartTime: item.EventStartTime,
            EventEndTime: item.EventEndTime,
            MeetingRoom: item.MeetingRoom,
            Setup: item.Setup,
            MeetingRoomRevenue: item.MeetingRoomRevenue,
            NoOfPeople: item.NoOfPeople,
            IsFood: item.IsFood,
            TotalAnticipatedFoodRevenue: item.TotalAnticipatedFoodRevenue,
            IsBeverage: item.IsBeverage,
            TotalAnticipatedBeverageRevenue: item.TotalAnticipatedBeverageRevenue,
            TotalAnticipatedFBRevenue: item.TotalAnticipatedFBRevenue,
            IsAVEquipment: item.IsAVEquipment,
            TotalAnticipatedAVMISRevenue: item.TotalAnticipatedAVMISRevenue,
          });
        });

        return ascDataBind;
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            StartDate: item.StartDate,
            EndDate: item.EndDate,
            DayOfWeek: item.DayOfWeek,
            AccountName: item.AccountName,
            OpportunityName: item.OpportunityName,
            EventDateDetails: item.EventDateDetails,
            EventStartTime: item.EventStartTime,
            EventEndTime: item.EventEndTime,
            MeetingRoom: item.MeetingRoom,
            Setup: item.Setup,
            MeetingRoomRevenue: item.MeetingRoomRevenue,
            NoOfPeople: item.NoOfPeople,
            IsFood: item.IsFood,
            TotalAnticipatedFoodRevenue: item.TotalAnticipatedFoodRevenue,
            IsBeverage: item.IsBeverage,
            TotalAnticipatedBeverageRevenue: item.TotalAnticipatedBeverageRevenue,
            TotalAnticipatedFBRevenue: item.TotalAnticipatedFBRevenue,
            IsAVEquipment: item.IsAVEquipment,
            TotalAnticipatedAVMISRevenue: item.TotalAnticipatedAVMISRevenue,
          });
        });

        return descDataBind;
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            StartDate: item.StartDate,
            EndDate: item.EndDate,
            DayOfWeek: item.DayOfWeek,
            AccountName: item.AccountName,
            OpportunityName: item.OpportunityName,
            EventDateDetails: item.EventDateDetails,
            EventStartTime: item.EventStartTime,
            EventEndTime: item.EventEndTime,
            MeetingRoom: item.MeetingRoom,
            Setup: item.Setup,
            MeetingRoomRevenue: item.MeetingRoomRevenue,
            NoOfPeople: item.NoOfPeople,
            IsFood: item.IsFood,
            TotalAnticipatedFoodRevenue: item.TotalAnticipatedFoodRevenue,
            IsBeverage: item.IsBeverage,
            TotalAnticipatedBeverageRevenue: item.TotalAnticipatedBeverageRevenue,
            TotalAnticipatedFBRevenue: item.TotalAnticipatedFBRevenue,
            IsAVEquipment: item.IsAVEquipment,
            TotalAnticipatedAVMISRevenue: item.TotalAnticipatedAVMISRevenue,
          });
        });

        return normalDataBind;
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          StartDate: item.StartDate,
          EndDate: item.EndDate,
          DayOfWeek: item.DayOfWeek,
          AccountName: item.AccountName,
          OpportunityName: item.OpportunityName,
          EventDateDetails: item.EventDateDetails,
          EventStartTime: item.EventStartTime,
          EventEndTime: item.EventEndTime,
          MeetingRoom: item.MeetingRoom,
          Setup: item.Setup,
          MeetingRoomRevenue: item.MeetingRoomRevenue,
          NoOfPeople: item.NoOfPeople,
          IsFood: item.IsFood,
          TotalAnticipatedFoodRevenue: item.TotalAnticipatedFoodRevenue,
          IsBeverage: item.IsBeverage,
          TotalAnticipatedBeverageRevenue: item.TotalAnticipatedBeverageRevenue,
          TotalAnticipatedFBRevenue: item.TotalAnticipatedFBRevenue,
          IsAVEquipment: item.IsAVEquipment,
          TotalAnticipatedAVMISRevenue: item.TotalAnticipatedAVMISRevenue,
        });
      });
      return normalInitDataBind;
    }
  }

  const exportMeetingAndCateringByDate = async () => {
    const DateValue = new Date();

    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    let ws = XLSX.utils.json_to_sheet([], {
      header: [
        `Total Meeting And Catering ` + "(" + dateTitle.stDate + "-" + dateTitle.endDate + ")",
      ],
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }];
    ws["!merges"] = merge;

    let tableMeetingAndCateringByDateDataExp: any = [];

    MeetingAndCateringByDateTotalRecord !== undefined &&
      MeetingAndCateringByDateTotalRecord !== null &&
      MeetingAndCateringByDateTotalRecord !== "" &&
      MeetingAndCateringByDateTotalRecord.length > 0 &&
      MeetingAndCateringByDateTotalRecord.map((item) => {
        tableMeetingAndCateringByDateDataExp.push({
          StartDate: item.StartDate,
          EndDate: item.EndDate,
          DayOfWeek: item.DayOfWeek,
          AccountName: item.AccountName,
          OpportunityName: item.OppoName,
          EventDateDetails: item.EventDateDetails,
          EventStartTime: item.StartTime,
          EventEndTime: item.EndTime,
          MeetingRoom: item.MeetingRoom,
          Setup: item.Setup,
          MeetingRoomRevenue: item.MeetingRoomRevenue,
          NoOfPeople: item.NumberOfPeople,
          IsFood: item.IsFood,
          TotalAnticipatedFoodRevenue: item.FoodRevenue,
          IsBeverage: item.IsBeverage,
          TotalAnticipatedBeverageRevenue: item.BeverageRevenue,
          TotalAnticipatedFBRevenue: item.FoodBeverageRevenue,
          IsAVEquipment: item.IsAVEquipment,
          TotalAnticipatedAVMISRevenue: item.AvEquipmentRevenue,
        });
      });

    // ------------------- Sort Data By Filter :

    let newArraySortedData = await getFilteredExportData(tableMeetingAndCateringByDateDataExp);

    // ------------------- Sort Data By Filter :

    XLSX.utils.sheet_add_aoa(ws, [Object.keys(newArraySortedData[0])], {
      origin: "A3",
    });
    XLSX.utils.sheet_add_json(ws, newArraySortedData, {
      skipHeader: true,
      origin: "A4",
    });

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    for (var R = rangeTotalAccounts.s.r; R <= rangeTotalAccounts.e.r; ++R) {
      for (var C = rangeTotalAccounts.s.c; C <= rangeTotalAccounts.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else {
            // ws[cell_ref].s = {
            //   ...cellStyle,
            //   alignment: {
            //     ...cellStyle.alignment,
            //     horizontal: C === 1 || C === 2 ? "right" : "left",
            //   },
            // };

            start: end: center: 11;

            let checkForCenterArray = [11];
            let checkForRightArray = [0, 1, 5, 6, 7, 10, 13, 15, 16, 18];

            if (checkForRightArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "right",
                },
              };
            } else if (checkForCenterArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "center",
                },
              };
            }
          }
        }
      }
    }

    let cols: any = [];

    Object.keys(newArraySortedData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    let filename =
      "Meeting-and-Catering" +
      DateValue.getFullYear() +
      "-" +
      (DateValue.getMonth() + 1) +
      "-" +
      DateValue.getDate();

    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename + ".xlsx");
  };

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          Meeting & Catering by Date ({dateTitle.stDate + "-" + dateTitle.endDate})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TableView
          isData={loading}
          columnsData={tableMeetingAndCateringByDateHeader}
          tableData={tableMeetingAndCateringByDateData}
        />
      </Modal.Body>

      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary ms-0"
          disabled={MeetingAndCateringByDateTotalRecord.length ? false : true}
          onClick={(e) => {
            e.preventDefault();
            exportMeetingAndCateringByDate();
          }}
        >
          <span className="me-2">
            <img src={ExportIcon} alt="" />
          </span>
          Export Excel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MeetingAndCateringByDateModal;
