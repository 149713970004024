import React, { useState } from "react";
import { Button, Form, Modal, Table, FloatingLabel } from "react-bootstrap";
import { useSelector } from "react-redux";
// import { fnCheckValidationOfObject, ToasterError } from "../Service/CommonServices";
// import {
//   getEditUserRoleRights,
//   SaveUpdateUserRole,
// } from "../../../Services/Setup&Config/UserSetupService";
import { toast } from "react-toastify";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import { getRoleRights, SaveUpdateUserRole } from "../Services/Setup&Config/UserSetupService";
import PageLoader from "../Component/Loader/PageLoader";
// import { DisplayText } from "../src/Service/CommonServices";

export const AddUserRoleModal = (props) => {
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  // let [EditRolesResponse, setEditRolesResponse]: any = React.useState();
  const initialState = {
    roleID: "00000000-0000-0000-0000-000000000000",
    roleCode: "",
    roleName: "",
    details: "",
    isDefault: true,
    defaultValue: "",
    associationWithProperty: true,
    orderNo: 0,
    lstRoleRight: [
      {
        rightID: null,
        IsView: false,
        IsCreate: false,
        IsEdit: false,
        IsDelete: false,
        IsExport: false,
      },
    ],
    errors: {
      roleName: "",
      ValidationRules: [
        {
          FieldName: "roleName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  };
  const [saveRole, setsaveRole]: any = useState<any>(initialState);
  const [allTopSelection, setAllTopSelection] = useState<any>({});
  const [allSelection, setAllSelection] = useState({});

  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.

  React.useEffect(() => {
    if (props.roleID !== undefined && props.roleID !== null && props.roleID !== "") {
      sethotelChangeLoading(true);
      getEditData(props.roleID);
    } else {
      sethotelChangeLoading(true);
      getRolesListMenu();
    }
  }, []);

  const handleCheckBox = (item, mode, e) => {
    if (mode === "view") {
      saveRole.lstRoleRight[item].IsView = e.target.checked;
      (saveRole.lstRoleRight[item].IsCreate = !e.target.checked
        ? false
        : saveRole.lstRoleRight[item].IsCreate),
        (saveRole.lstRoleRight[item].IsEdit = !e.target.checked
          ? false
          : saveRole.lstRoleRight[item].IsEdit),
        (saveRole.lstRoleRight[item].IsDelete = !e.target.checked
          ? false
          : saveRole.lstRoleRight[item].IsDelete),
        (saveRole.lstRoleRight[item].IsExport = !e.target.checked
          ? false
          : saveRole.lstRoleRight[item].IsExport);
    } else if (mode === "create") {
      {
        (saveRole.lstRoleRight[item].IsView =
          e.target.checked === true ? true : saveRole.lstRoleRight[item].IsView),
          (saveRole.lstRoleRight[item].IsCreate = e.target.checked);
        // saveRole.lstRoleRight[item].IsEdit = false,
        // saveRole.lstRoleRight[item].IsDelete = false,
        // saveRole.lstRoleRight[item].IsExport = false
      }
    } else if (mode === "update") {
      // saveRole.lstRoleRight[item].IsView = saveRole.lstRoleRight[item].IsView === true ? true : e.target.checked,
      // saveRole.lstRoleRight[item].IsCreate = saveRole.lstRoleRight[item].IsCreate === true ? true : e.target.checked,
      (saveRole.lstRoleRight[item].IsView =
        e.target.checked === true ? true : saveRole.lstRoleRight[item].IsView),
        (saveRole.lstRoleRight[item].IsEdit = e.target.checked);
      // saveRole.lstRoleRight[item].IsDelete = false,
      // saveRole.lstRoleRight[item].IsExport = false
    } else if (mode === "delete") {
      // saveRole.lstRoleRight[item].IsView = saveRole.lstRoleRight[item].IsView === true ? true : e.target.checked,
      // saveRole.lstRoleRight[item].IsCreate = saveRole.lstRoleRight[item].IsCreate === true ? true : e.target.checked,
      // saveRole.lstRoleRight[item].IsEdit = saveRole.lstRoleRight[item].IsEdit === true ? true : e.target.checked,
      (saveRole.lstRoleRight[item].IsView =
        e.target.checked === true ? true : saveRole.lstRoleRight[item].IsView),
        (saveRole.lstRoleRight[item].IsDelete = e.target.checked);
      // saveRole.lstRoleRight[item].IsExport = false
    } else if (mode === "export") {
      // saveRole.lstRoleRight[item].IsView = saveRole.lstRoleRight[item].IsView === true ? true : e.target.checked,
      //   saveRole.lstRoleRight[item].IsCreate = saveRole.lstRoleRight[item].IsCreate === true ? true : e.target.checked,
      //   saveRole.lstRoleRight[item].IsEdit = saveRole.lstRoleRight[item].IsEdit === true ? true : e.target.checked,
      //   saveRole.lstRoleRight[item].IsDelete = saveRole.lstRoleRight[item].IsDelete === true ? true : e.target.checked,
      (saveRole.lstRoleRight[item].IsView =
        e.target.checked === true ? true : saveRole.lstRoleRight[item].IsView),
        (saveRole.lstRoleRight[item].IsExport = e.target.checked);
    }
    let module_name = saveRole.lstRoleRight[item].ModuleName;

    let module_filtered_data = saveRole.lstRoleRight.filter(
      (obj: any) => obj.ModuleName === module_name
    );

    let rightForAllSelection = {
      IsView: true,
      IsCreate: true,
      IsEdit: true,
      IsExport: true,
      IsDelete: true,
    };
    module_filtered_data.forEach((itm) => {
      if (!itm.IsView) {
        rightForAllSelection = {
          ...rightForAllSelection,
          IsView: false,
        };
      }
      if (!itm.IsCreate) {
        rightForAllSelection = {
          ...rightForAllSelection,
          IsCreate: false,
        };
      }
      if (!itm.IsEdit) {
        rightForAllSelection = {
          ...rightForAllSelection,
          IsEdit: false,
        };
      }
      if (!itm.IsExport) {
        rightForAllSelection = {
          ...rightForAllSelection,
          IsExport: false,
        };
      }
      if (!itm.IsDelete) {
        rightForAllSelection = {
          ...rightForAllSelection,
          IsDelete: false,
        };
      }
    });

    let rightForAllTopSelection: any = {
      IsView: true,
      IsCreate: true,
      IsEdit: true,
      IsExport: true,
      IsDelete: true,
    };
    saveRole.lstRoleRight.forEach((itm) => {
      if (!itm.IsView) {
        rightForAllTopSelection = {
          ...rightForAllTopSelection,
          IsView: false,
        };
      }
      if (!itm.IsCreate) {
        rightForAllTopSelection = {
          ...rightForAllTopSelection,
          IsCreate: false,
        };
      }
      if (!itm.IsEdit) {
        rightForAllTopSelection = {
          ...rightForAllTopSelection,
          IsEdit: false,
        };
      }
      if (!itm.IsExport) {
        rightForAllTopSelection = {
          ...rightForAllTopSelection,
          IsExport: false,
        };
      }
      if (!itm.IsDelete) {
        rightForAllTopSelection = {
          ...rightForAllTopSelection,
          IsDelete: false,
        };
      }
    });

    setAllTopSelection({
      ...allTopSelection,
      ...rightForAllTopSelection,
    });

    setAllSelection({
      ...allSelection,
      [module_name]: {
        ...allSelection[module_name],
        ...rightForAllSelection,
      },
    });

    setsaveRole({ ...saveRole });
  };
  async function getEditData(EditRoleID) {
    let inputs = {
      keyID: EditRoleID,
    };
    // const res: any = await getEditUserRoleRights(inputs, Token);
    const res: any = await getRoleRights(inputs, Token);

    if (res.data.success) {
      // setsaveRole(res.data.data);

      if (res.data !== undefined && res.data.data.dataList1.length != 0) {
        let allSelectionToSet = {};
        res.data.data.dataList2.forEach((itm) => {
          let rightForAllSelection = {
            IsView: true,
            IsCreate: true,
            IsEdit: true,
            IsExport: true,
            IsDelete: true,
          };
          let module_filtered_data = res.data.data.dataList2.filter(
            (obj: any) => obj.ModuleName === itm.ModuleName
          );
          module_filtered_data.forEach((itm_inner) => {
            if (!itm_inner.IsView) {
              rightForAllSelection = {
                ...rightForAllSelection,
                IsView: false,
              };
            }
            if (!itm_inner.IsCreate) {
              rightForAllSelection = {
                ...rightForAllSelection,
                IsCreate: false,
              };
            }
            if (!itm_inner.IsEdit) {
              rightForAllSelection = {
                ...rightForAllSelection,
                IsEdit: false,
              };
            }
            if (!itm_inner.IsExport) {
              rightForAllSelection = {
                ...rightForAllSelection,
                IsExport: false,
              };
            }
            if (!itm_inner.IsDelete) {
              rightForAllSelection = {
                ...rightForAllSelection,
                IsDelete: false,
              };
            }
          });

          allSelectionToSet = {
            ...allSelectionToSet,
            [itm.ModuleName]: {
              ...rightForAllSelection,
            },
          };
        });

        setAllSelection(allSelectionToSet);

        let allTopSelectionToAppend = {};
        res.data.data.dataList2.forEach((itm) => {
          allTopSelectionToAppend = {
            ...allTopSelectionToAppend,
            IsView: itm.IsView,
            IsEdit: itm.IsEdit,
            IsDelete: itm.IsDelete,
            IsExport: itm.IsExport,
            IsCreate: itm.IsCreate,
          };
        });
        setAllTopSelection(allTopSelectionToAppend);

        setsaveRole({
          ...saveRole,
          roleID:
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1[0].RoleID,
          roleCode:
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1[0].RoleCode,
          roleName:
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1[0].RoleName,
          details:
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1[0].Details,
          isDefault: true,
          defaultValue: "",
          associationWithProperty: false,
          orderNo: 0,
          lstRoleRight: res.data.data.dataList2 && res.data.data.dataList2,
          errors: {
            roleName: "",
            ValidationRules: [
              {
                FieldName: "roleName",
                ValidationType: "required",
                ValidationMessage: "This field is required.",
              },
            ],
          },
        });
      }
    }
    sethotelChangeLoading(false);
  }
  async function getRolesListMenu() {
    let inputs = {
      keyID: "00000000-0000-0000-0000-000000000000",
    };
    // const res: any = await getEditUserRoleRights(inputs, Token);
    const res: any = await getRoleRights(inputs, Token);

    if (res.data.success) {
      // setsaveRole(res.data.data);

      if (res.data !== undefined && res.data.data.dataList2.length != 0) {
        setsaveRole({
          ...saveRole,
          lstRoleRight: res.data.data.dataList2,
          errors: {
            roleName: "",
            ValidationRules: [
              {
                FieldName: "roleName",
                ValidationType: "required",
                ValidationMessage: "This field is required.",
              },
            ],
          },
        });
        let allSelectionToSet = {};
        res.data.data.dataList2.forEach((itm) => {
          allSelectionToSet = {
            ...allSelectionToSet,
            [itm.ModuleName]: {
              IsView: false,
              IsCreate: false,
              IsDelete: false,
              IsEdit: false,
              IsExport: false,
            },
          };
        });
        let allTopSelectionToAppend = {};
        res.data.data.dataList2.forEach((itm) => {
          allTopSelectionToAppend = {
            ...allTopSelectionToAppend,
            IsView: itm.IsView,
            IsEdit: itm.IsEdit,
            IsDelete: itm.IsDelete,
            IsExport: itm.IsExport,
            IsCreate: itm.IsCreate,
          };
        });
        setAllTopSelection(allTopSelectionToAppend);
        setAllSelection(allSelectionToSet);
      }
    }
    sethotelChangeLoading(false);
  }

  async function submitForm() {
    let obj = fnCheckValidationOfObject(saveRole);
    setsaveRole({
      ...obj.obj,
    });
    let input = {
      roleID: saveRole.roleID,
      roleCode: saveRole.roleCode,
      roleName: saveRole.roleName,
      details: saveRole.details,
      isDefault: true,
      defaultValue: saveRole.roleName,
      associationWithProperty: true,
      orderNo: 0,
      lstRoleRight: saveRole.lstRoleRight,
    };
    if (obj.isValid) {
      setOnProcessHide(true);

      const res: any = await SaveUpdateUserRole(input, Token);

      setOnProcessHide(false);
      if (res.data.success) {
        if (res.data !== undefined) {
          if (res.data.statusCode === 200) {
            ToasterSuccess(res.data.message);
            handleClose();
            props.getRoleListData();
          }
        } else {
          ToasterError(res.message);
        }
      }
    } else {
      setOnProcessHide(true);
      ToasterError("Please Select Atleast One Role");
      setOnProcessHide(false);
    }
  }

  const handleClose = () => {
    setsaveRole(initialState);
    props.onHide(false);
  };

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {saveRole.roleID !== undefined &&
          saveRole.roleID !== null &&
          saveRole.roleID !== "00000000-0000-0000-0000-000000000000" ? (
            <Modal.Title id="contained-modal-title-vcenter">Edit User Role</Modal.Title>
          ) : (
            <Modal.Title id="contained-modal-title-vcenter">Add User Role</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12">
                <FloatingLabel controlId="RoleCode" label="Role Code">
                  <Form.Control
                    type="text"
                    maxLength={66}
                    placeholder="RoleCode"
                    onChange={(e) =>
                      setsaveRole({
                        ...saveRole,
                        ["roleCode"]: e.target.value,
                      })
                    }
                    value={saveRole.roleCode}
                  />
                </FloatingLabel>
                {/* <Form.Group>
                <Form.Label>Role Code</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={256}
                  placeholder="RoleCode"
                  onChange={(e) =>
                    setsaveRole({
                      ...saveRole,
                      ["roleCode"]: e.target.value,
                    })
                  }
                  value={saveRole.roleCode}
                />
              </Form.Group> */}
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12">
                <FloatingLabel controlId="RoleName" label="Role Name*">
                  <Form.Control
                    type="text"
                    maxLength={164}
                    placeholder="RoleName"
                    onChange={(e) =>
                      setsaveRole({
                        ...saveRole,
                        ["roleName"]: e.target.value,
                      })
                    }
                    value={saveRole.roleName}
                    isInvalid={saveRole.errors && saveRole.errors.roleName}
                  />
                  {saveRole.errors.roleName && (
                    <Form.Control.Feedback type="invalid">
                      {saveRole.errors.roleName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>

                {/* <Form.Group>
                <Form.Label>Role Name* </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={160}
                  placeholder="RoleName"
                  onChange={(e) =>
                    setsaveRole({
                      ...saveRole,
                      ["roleName"]: e.target.value,
                    })
                  }
                  value={saveRole.roleName}
                  isInvalid={saveRole.errors && saveRole.errors.roleName}
                />
                {saveRole.errors.roleName && (
                  <Form.Control.Feedback type="invalid">
                    {saveRole.errors.roleName}
                  </Form.Control.Feedback>
                )}
              </Form.Group> */}
              </div>
              <div className="col-xl-4 col-lg-12 col-md-12">
                <FloatingLabel controlId="Description" label="Description">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    onChange={(e) =>
                      setsaveRole({
                        ...saveRole,
                        ["details"]: e.target.value,
                      })
                    }
                    defaultValue={saveRole.details}
                  />
                </FloatingLabel>
                {/* <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={256}
                  placeholder="Description"
                  onChange={(e) =>
                    setsaveRole({
                      ...saveRole,
                      ["details"]: e.target.value,
                    })
                  }
                  defaultValue={saveRole.details}
                />
              </Form.Group> */}
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="d-flex justify-content-end middleboerder">
                  <Button className="cancelbtnclient me-2" onClick={props.onHide}>
                    <i className="fa-regular fa-circle-xmark me-2"></i>Cancel
                  </Button>
                  <Button
                    className="savebtnclient"
                    disabled={OnProcessHide === true ? true : false}
                    onClick={submitForm}
                  >
                    <i className="fa-solid fa-floppy-disk me-2"></i>Save
                  </Button>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 mytableheight">
                <Table className="customtable" responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th className="width-250px">Menu Name</th>
                      {Object.keys(allTopSelection).length ? (
                        <>
                          <th className="me-0 custom-role-sel">
                            <Form.Check
                              onChange={(e) => {
                                setAllTopSelection({
                                  ...allTopSelection,
                                  IsView: e.target.checked,
                                  IsCreate: !e.target.checked ? false : allTopSelection.IsCreate,
                                  IsEdit: !e.target.checked ? false : allTopSelection.IsEdit,
                                  IsDelete: !e.target.checked ? false : allTopSelection.IsDelete,
                                  IsExport: !e.target.checked ? false : allTopSelection.IsExport,
                                });
                                let newArrayGetRolesList: any = [];
                                saveRole.lstRoleRight.forEach((itm) => {
                                  newArrayGetRolesList = [
                                    ...newArrayGetRolesList,
                                    {
                                      ...itm,
                                      IsView: e.target.checked,
                                      IsEdit: !e.target.checked ? false : itm.IsEdit,
                                      IsDelete: !e.target.checked ? false : itm.IsDelete,
                                      IsExport: !e.target.checked ? false : itm.IsExport,
                                      IsCreate: !e.target.checked ? false : itm.IsCreate,
                                    },
                                  ];
                                });

                                let newAllSelWhTopSelection = {};
                                Object.keys(allSelection).forEach((module) => {
                                  newAllSelWhTopSelection = {
                                    ...newAllSelWhTopSelection,
                                    [module]: {
                                      ...allSelection[module],
                                      IsView: e.target.checked,
                                      IsEdit: !e.target.checked
                                        ? false
                                        : allSelection[module].IsEdit,
                                      IsDelete: !e.target.checked
                                        ? false
                                        : allSelection[module].IsDelete,
                                      IsExport: !e.target.checked
                                        ? false
                                        : allSelection[module].IsExport,
                                      IsCreate: !e.target.checked
                                        ? false
                                        : allSelection[module].IsCreate,
                                    },
                                  };
                                });

                                setAllSelection(newAllSelWhTopSelection);
                                setsaveRole({
                                  ...saveRole,
                                  lstRoleRight: newArrayGetRolesList,
                                });
                              }}
                              checked={allTopSelection.IsView}
                              inline
                              type="checkbox"
                              label="View"
                              className="me-0"
                            />
                          </th>
                          <th className="me-0 custom-role-sel">
                            <Form.Check
                              onChange={(e) => {
                                setAllTopSelection({
                                  ...allTopSelection,
                                  IsView: e.target.checked === true ? true : allTopSelection.IsView,
                                  IsCreate: e.target.checked,
                                });

                                let newArrayGetRolesList: any = [];
                                saveRole.lstRoleRight.forEach((itm) => {
                                  newArrayGetRolesList = [
                                    ...newArrayGetRolesList,
                                    {
                                      ...itm,
                                      IsView: e.target.checked === true ? true : itm.IsView,
                                      IsCreate: e.target.checked,
                                    },
                                  ];
                                });

                                let newAllSelWhTopSelection = {};
                                Object.keys(allSelection).forEach((module) => {
                                  newAllSelWhTopSelection = {
                                    ...newAllSelWhTopSelection,
                                    [module]: {
                                      ...allSelection[module],
                                      IsView:
                                        e.target.checked === true
                                          ? true
                                          : allSelection[module].IsView,
                                      IsCreate: e.target.checked,
                                    },
                                  };
                                });
                                setAllSelection(newAllSelWhTopSelection);
                                setsaveRole({
                                  ...saveRole,
                                  lstRoleRight: newArrayGetRolesList,
                                });
                              }}
                              checked={allTopSelection.IsCreate}
                              inline
                              type="checkbox"
                              label="Create"
                              className="me-0"
                            />
                          </th>
                          <th className="me-0 custom-role-sel">
                            <Form.Check
                              onChange={(e) => {
                                setAllTopSelection({
                                  ...allTopSelection,
                                  IsView: e.target.checked === true ? true : allTopSelection.IsView,
                                  IsEdit: e.target.checked,
                                });
                                let newArrayGetRolesList: any = [];
                                saveRole.lstRoleRight.forEach((itm) => {
                                  newArrayGetRolesList = [
                                    ...newArrayGetRolesList,
                                    {
                                      ...itm,
                                      IsView: e.target.checked === true ? true : itm.IsView,
                                      IsEdit: e.target.checked,
                                    },
                                  ];
                                });
                                let newAllSelWhTopSelection = {};
                                Object.keys(allSelection).forEach((module) => {
                                  newAllSelWhTopSelection = {
                                    ...newAllSelWhTopSelection,
                                    [module]: {
                                      ...allSelection[module],
                                      IsView:
                                        e.target.checked === true
                                          ? true
                                          : allSelection[module].IsView,
                                      IsEdit: e.target.checked,
                                    },
                                  };
                                });
                                setAllSelection(newAllSelWhTopSelection);
                                setsaveRole({
                                  ...saveRole,
                                  lstRoleRight: newArrayGetRolesList,
                                });
                              }}
                              checked={allTopSelection.IsEdit}
                              inline
                              type="checkbox"
                              label="Update"
                              className="me-0"
                            />
                          </th>
                          <th className="me-0 custom-role-sel">
                            <Form.Check
                              onChange={(e) => {
                                setAllTopSelection({
                                  ...allTopSelection,
                                  IsView: e.target.checked === true ? true : allTopSelection.IsView,
                                  IsDelete: e.target.checked,
                                });
                                let newArrayGetRolesList: any = [];
                                saveRole.lstRoleRight.forEach((itm) => {
                                  newArrayGetRolesList = [
                                    ...newArrayGetRolesList,
                                    {
                                      ...itm,
                                      IsView: e.target.checked === true ? true : itm.IsView,
                                      IsDelete: e.target.checked,
                                    },
                                  ];
                                });
                                let newAllSelWhTopSelection = {};
                                Object.keys(allSelection).forEach((module) => {
                                  newAllSelWhTopSelection = {
                                    ...newAllSelWhTopSelection,
                                    [module]: {
                                      ...allSelection[module],
                                      IsView:
                                        e.target.checked === true
                                          ? true
                                          : allSelection[module].IsView,
                                      IsDelete: e.target.checked,
                                    },
                                  };
                                });
                                setAllSelection(newAllSelWhTopSelection);
                                setsaveRole({
                                  ...saveRole,
                                  lstRoleRight: newArrayGetRolesList,
                                });
                              }}
                              checked={allTopSelection.IsDelete}
                              inline
                              type="checkbox"
                              label="Delete"
                              className="me-0"
                            />
                          </th>
                          <th className="me-0 custom-role-sel">
                            <Form.Check
                              onChange={(e) => {
                                setAllTopSelection({
                                  ...allTopSelection,
                                  IsView: e.target.checked === true ? true : allTopSelection.IsView,
                                  IsExport: e.target.checked,
                                });
                                let newArrayGetRolesList: any = [];
                                saveRole.lstRoleRight.forEach((itm) => {
                                  newArrayGetRolesList = [
                                    ...newArrayGetRolesList,
                                    {
                                      ...itm,
                                      IsView: e.target.checked === true ? true : itm.IsView,
                                      IsExport: e.target.checked,
                                    },
                                  ];
                                });
                                let newAllSelWhTopSelection = {};
                                Object.keys(allSelection).forEach((module) => {
                                  newAllSelWhTopSelection = {
                                    ...newAllSelWhTopSelection,
                                    [module]: {
                                      ...allSelection[module],
                                      IsView:
                                        e.target.checked === true
                                          ? true
                                          : allSelection[module].IsView,
                                      IsExport: e.target.checked,
                                    },
                                  };
                                });
                                setAllSelection(newAllSelWhTopSelection);
                                setsaveRole({
                                  ...saveRole,
                                  lstRoleRight: newArrayGetRolesList,
                                });
                              }}
                              checked={allTopSelection.IsExport}
                              inline
                              type="checkbox"
                              label="Export"
                              className="me-0"
                            />
                          </th>
                        </>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Top Menu
                      </th>
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 1 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            {item.MenuName === "Profile" ? (
                              <>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    defaultChecked={true}
                                    checked={true}
                                    onChange={(e: any) => {
                                      handleCheckBox(index, "view", e);
                                    }}
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="me-0"
                                    defaultChecked={true}
                                    checked={true}
                                    onChange={(e) => {
                                      handleCheckBox(index, "create", e);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    defaultChecked={true}
                                    checked={true}
                                    onChange={(e) => {
                                      handleCheckBox(index, "update", e);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    onChange={(e) => {
                                      handleCheckBox(index, "delete", e);
                                    }}
                                    defaultChecked={true}
                                    checked={true}
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    onChange={(e) => {
                                      handleCheckBox(index, "export", e);
                                    }}
                                    checked={true}
                                    defaultChecked={true}
                                  />
                                </td>
                              </>
                            ) : (
                              <>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    defaultChecked={item.IsView}
                                    checked={item.IsView}
                                    onChange={(e: any) => {
                                      handleCheckBox(index, "view", e);
                                    }}
                                  />
                                </td>

                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="me-0"
                                    checked={item.IsCreate}
                                    defaultChecked={item.IsCreate}
                                    onChange={(e) => {
                                      handleCheckBox(index, "create", e);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    checked={item.IsEdit}
                                    defaultChecked={item.IsEdit}
                                    onChange={(e) => {
                                      handleCheckBox(index, "update", e);
                                    }}
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    onChange={(e) => {
                                      handleCheckBox(index, "delete", e);
                                    }}
                                    checked={item.IsDelete}
                                    defaultChecked={item.IsDelete}
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    onChange={(e) => {
                                      handleCheckBox(index, "export", e);
                                    }}
                                    checked={item.IsExport}
                                    defaultChecked={item.IsExport}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Dashboard
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Dashboard'].IsView}
                                  checked={allSelection['Dashboard'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Dashboard': {
                                        ...allSelection['Dashboard'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Dashboard'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Dashboard'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Dashboard'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Dashboard'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Dashboard') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Dashboard'].IsCreate}
                                  checked={allSelection['Dashboard'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Dashboard': {
                                        ...allSelection['Dashboard'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Dashboard'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Dashboard') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Dashboard'].IsEdit}
                                  checked={allSelection['Dashboard'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Dashboard': {
                                        ...allSelection['Dashboard'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Dashboard'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Dashboard') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Dashboard'].IsDelete}
                                  checked={allSelection['Dashboard'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Dashboard': {
                                        ...allSelection['Dashboard'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Dashboard'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Dashboard') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Dashboard'].IsExport}
                                  checked={allSelection['Dashboard'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Dashboard': {
                                        ...allSelection['Dashboard'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Dashboard'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Dashboard') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 2 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={true}
                                defaultChecked={true}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={true}
                                defaultChecked={true}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={true}
                                defaultChecked={true}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={true}
                                defaultChecked={true}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={true}
                                defaultChecked={true}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Lead
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Lead'].IsView}
                                  checked={allSelection['Lead'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Lead': {
                                        ...allSelection['Lead'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Lead'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Lead'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Lead'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Lead'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Lead') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Lead'].IsCreate}
                                  checked={allSelection['Lead'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Lead': {
                                        ...allSelection['Lead'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Lead'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Lead') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Lead'].IsEdit}
                                  checked={allSelection['Lead'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Lead': {
                                        ...allSelection['Lead'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Lead'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Lead') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Lead'].IsDelete}
                                  checked={allSelection['Lead'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Lead': {
                                        ...allSelection['Lead'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Lead'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Lead') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Lead'].IsExport}
                                  checked={allSelection['Lead'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Lead': {
                                        ...allSelection['Lead'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Lead'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Lead') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 3 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Account
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Account'].IsView}
                                  checked={allSelection['Account'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Account': {
                                        ...allSelection['Account'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Account'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Account'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Account'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Account'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Account') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Account'].IsCreate}
                                  checked={allSelection['Account'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Account': {
                                        ...allSelection['Account'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Account'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Account') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Account'].IsEdit}
                                  checked={allSelection['Account'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Account': {
                                        ...allSelection['Account'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Account'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Account') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Account'].IsDelete}
                                  checked={allSelection['Account'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Account': {
                                        ...allSelection['Account'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Account'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Account') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Account'].IsExport}
                                  checked={allSelection['Account'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Account': {
                                        ...allSelection['Account'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Account'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Account') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 4 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Contacts
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Contacts'].IsView}
                                  checked={allSelection['Contacts'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Contacts': {
                                        ...allSelection['Contacts'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Contacts'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Contacts'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Contacts'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Contacts'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Contacts') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Contacts'].IsCreate}
                                  checked={allSelection['Contacts'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Contacts': {
                                        ...allSelection['Contacts'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Contacts'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Contacts') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Contacts'].IsEdit}
                                  checked={allSelection['Contacts'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Contacts': {
                                        ...allSelection['Contacts'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Contacts'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Contacts') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Contacts'].IsDelete}
                                  checked={allSelection['Contacts'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Contacts': {
                                        ...allSelection['Contacts'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Contacts'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Contacts') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Contacts'].IsExport}
                                  checked={allSelection['Contacts'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Contacts': {
                                        ...allSelection['Contacts'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Contacts'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Contacts') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 5 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Activities
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Activities'].IsView}
                                  checked={allSelection['Activities'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Activities': {
                                        ...allSelection['Activities'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Activities'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Activities'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Activities'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Activities'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Activities') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Activities'].IsCreate}
                                  checked={allSelection['Activities'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Activities': {
                                        ...allSelection['Activities'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Activities'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Activities') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Activities'].IsEdit}
                                  checked={allSelection['Activities'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Activities': {
                                        ...allSelection['Activities'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Activities'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Activities') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Activities'].IsDelete}
                                  checked={allSelection['Activities'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Activities': {
                                        ...allSelection['Activities'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Activities'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Activities') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Activities'].IsExport}
                                  checked={allSelection['Activities'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Activities': {
                                        ...allSelection['Activities'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Activities'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Activities') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 6 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Opportunity
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Opportunity'].IsView}
                                  checked={allSelection['Opportunity'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Opportunity': {
                                        ...allSelection['Opportunity'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Opportunity'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Opportunity'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Opportunity'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Opportunity'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Opportunity') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Opportunity'].IsCreate}
                                  checked={allSelection['Opportunity'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Opportunity': {
                                        ...allSelection['Opportunity'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Opportunity'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Opportunity') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Opportunity'].IsEdit}
                                  checked={allSelection['Opportunity'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Opportunity': {
                                        ...allSelection['Opportunity'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Opportunity'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Opportunity') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Opportunity'].IsDelete}
                                  checked={allSelection['Opportunity'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Opportunity': {
                                        ...allSelection['Opportunity'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Opportunity'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Opportunity') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Opportunity'].IsExport}
                                  checked={allSelection['Opportunity'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Opportunity': {
                                        ...allSelection['Opportunity'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Opportunity'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Opportunity') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 7 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      {/* <th className="titletr" colSpan={7}>
                            Miscellaneous
                          </th> */}
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Miscellaneous'].IsView}
                                  checked={allSelection['Miscellaneous'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Miscellaneous': {
                                        ...allSelection['Miscellaneous'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Miscellaneous'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Miscellaneous'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Miscellaneous'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Miscellaneous'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Miscellaneous') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Miscellaneous'].IsCreate}
                                  checked={allSelection['Miscellaneous'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Miscellaneous': {
                                        ...allSelection['Miscellaneous'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Miscellaneous'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Miscellaneous') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Miscellaneous'].IsEdit}
                                  checked={allSelection['Miscellaneous'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Miscellaneous': {
                                        ...allSelection['Miscellaneous'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Miscellaneous'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Miscellaneous') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Miscellaneous'].IsDelete}
                                  checked={allSelection['Miscellaneous'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Miscellaneous': {
                                        ...allSelection['Miscellaneous'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Miscellaneous'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Miscellaneous') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Miscellaneous'].IsExport}
                                  checked={allSelection['Miscellaneous'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Miscellaneous': {
                                        ...allSelection['Miscellaneous'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Miscellaneous'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Miscellaneous') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 8 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Setup and Configuration
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Setup and Configuration'].IsView}
                                  checked={allSelection['Setup and Configuration'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Setup and Configuration': {
                                        ...allSelection['Setup and Configuration'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Setup and Configuration'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Setup and Configuration'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Setup and Configuration'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Setup and Configuration'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Setup and Configuration') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Setup and Configuration'].IsCreate}
                                  checked={allSelection['Setup and Configuration'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Setup and Configuration': {
                                        ...allSelection['Setup and Configuration'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Setup and Configuration'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Setup and Configuration') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Setup and Configuration'].IsEdit}
                                  checked={allSelection['Setup and Configuration'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Setup and Configuration': {
                                        ...allSelection['Setup and Configuration'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Setup and Configuration'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Setup and Configuration') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Setup and Configuration'].IsDelete}
                                  checked={allSelection['Setup and Configuration'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Setup and Configuration': {
                                        ...allSelection['Setup and Configuration'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Setup and Configuration'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Setup and Configuration') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Setup and Configuration'].IsExport}
                                  checked={allSelection['Setup and Configuration'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Setup and Configuration': {
                                        ...allSelection['Setup and Configuration'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Setup and Configuration'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Setup and Configuration') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 9 && item.MenuName !== "Mail Settings" ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                    <tr>
                      <th className="titletr" colSpan={7}>
                        Reports
                      </th>
                      {/* {Object.keys(allSelection).length ? <>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Reports'].IsView}
                                  checked={allSelection['Reports'].IsView}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Reports': {
                                        ...allSelection['Reports'],
                                        IsView: e.target.checked,
                                        IsCreate: !e.target.checked ? false : allSelection['Reports'].IsCreate,
                                        IsEdit: !e.target.checked ? false : allSelection['Reports'].IsEdit,
                                        IsDelete: !e.target.checked ? false : allSelection['Reports'].IsDelete,
                                        IsExport: !e.target.checked ? false : allSelection['Reports'].IsExport
                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Reports') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,
                                          IsView: e.target.checked,
                                          IsCreate: !e.target.checked ? false : itm.IsCreate,
                                          IsEdit: !e.target.checked ? false : itm.IsEdit,
                                          IsDelete: !e.target.checked ? false : itm.IsDelete,
                                          IsExport: !e.target.checked ? false : itm.IsExport,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />

                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Reports'].IsCreate}
                                  checked={allSelection['Reports'].IsCreate}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Reports': {
                                        ...allSelection['Reports'],

                                        IsCreate: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Reports'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Reports') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsCreate: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Reports'].IsEdit}
                                  checked={allSelection['Reports'].IsEdit}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Reports': {
                                        ...allSelection['Reports'],

                                        IsEdit: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Reports'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Reports') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsEdit: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Reports'].IsDelete}
                                  checked={allSelection['Reports'].IsDelete}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Reports': {
                                        ...allSelection['Reports'],

                                        IsDelete: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Reports'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Reports') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsDelete: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                            <th>
                                <Form.Check
                                  inline
                                  name="group9"
                                  type="checkbox"
                                  id="inline-radio-9"
                                  className="mx-0 px-0"
                                  defaultChecked={allSelection['Reports'].IsExport}
                                  checked={allSelection['Reports'].IsExport}
                                  onChange={(e: any) => {
                                    setAllSelection({
                                      ...allSelection,
                                      'Reports': {
                                        ...allSelection['Reports'],

                                        IsExport: e.target.checked,
                                        IsView: e.target.checked === true ? true : allSelection['Reports'].IsView,

                                      }
                                    })
                                    let newlstRoleRight: any = []
                                    saveRole.lstRoleRight.forEach((itm) => {
                                      if (itm.ModuleName === 'Reports') {
                                        newlstRoleRight = [...newlstRoleRight, {
                                          ...itm,

                                          IsExport: e.target.checked,
                                          IsView: e.target.checked === true ? true : itm.IsView,
                                        }]
                                      } else {
                                        newlstRoleRight = [...newlstRoleRight, itm]
                                      }
                                    })
                                    setsaveRole({
                                      ...saveRole,
                                      lstRoleRight: newlstRoleRight
                                    })
                                  }}
                                />
                            </th>
                          </> : ""} */}
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 10 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              {/* <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="me-0"
                                    checked={item.IsCreate}
                                    defaultChecked={item.IsCreate}
                                    onChange={(e) => {
                                      handleCheckBox(index, "create", e);
                                    }}
                                  /> */}
                            </td>
                            <td>
                              {/* <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    checked={item.IsEdit}
                                    defaultChecked={item.IsEdit}
                                    onChange={(e) => {
                                      handleCheckBox(index, "update", e);
                                    }} 
                                  />*/}
                            </td>
                            <td>
                              {/* <Form.Check
                                    inline
                                    name="group9"
                                    type="checkbox"
                                    id="inline-radio-9"
                                    className="mx-0 px-0"
                                    onChange={(e) => {
                                      handleCheckBox(index, "delete", e);
                                    }}
                                    checked={item.IsDelete}
                                    defaultChecked={item.IsDelete}
                                  /> */}
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}

                    <tr>
                      <th className="titletr" colSpan={7}>
                        Import
                      </th>
                    </tr>
                    {saveRole &&
                      saveRole.lstRoleRight.map((item: any, index) =>
                        item.OrderID === 11 ? (
                          <tr key={index}>
                            <td>{item.MenuName}</td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsView}
                                defaultChecked={item.IsView}
                                onChange={(e: any) => {
                                  handleCheckBox(index, "view", e);
                                }}
                              />
                            </td>

                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="me-0"
                                checked={item.IsCreate}
                                defaultChecked={item.IsCreate}
                                onChange={(e) => {
                                  handleCheckBox(index, "create", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                checked={item.IsEdit}
                                defaultChecked={item.IsEdit}
                                onChange={(e) => {
                                  handleCheckBox(index, "update", e);
                                }}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "delete", e);
                                }}
                                checked={item.IsDelete}
                                defaultChecked={item.IsDelete}
                              />
                            </td>
                            <td>
                              <Form.Check
                                inline
                                name="group9"
                                type="checkbox"
                                id="inline-radio-9"
                                className="mx-0 px-0"
                                onChange={(e) => {
                                  handleCheckBox(index, "export", e);
                                }}
                                checked={item.IsExport}
                                defaultChecked={item.IsExport}
                              />
                            </td>
                          </tr>
                        ) : (
                          ""
                        )
                      )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
