import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  CommonService,
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import { SaveSupportReq } from "../Services/LeftMenuServices";
import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddHelpDesk = (props) => {

  let navigate = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  const [IsDataCheck, setIsDataCheck] = React.useState(false);
  const [Supportlist, setSupportlist]: any = React.useState([]);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [HelpDeskSave, setHelpDeskSave]: any = useState({
    title: "",
    category: "",
    description: "",
    errors: {
      title: "",
      category: "",
      description: "",
      ValidationRules: [
        {
          FieldName: "title",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "category",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "description",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });
  let category_options: any = [
    {
      value: "",
      label: "Select Category",
      color: "",
    },
  ];
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const [GetCategoryList, setGetCategoryList]: any = React.useState([]);
  GetCategoryList.map((item: any) => {
    // if(item.Category === "SupportCategory"){
    category_options.push({
      value: item.TermCode,
      label: item.TermCode,
      color: "",
    });
    // }
  });
  const handleClose = () => {
    setHelpDeskSave({});
    props.onHide(false);
  };

  React.useEffect(() => {
    CategoryList();
  }, []);
  async function CategoryList() {
    let input: any = {
      category: "SupportCategory",
      clientID: clientID,
    };
    // setShowSkeleton(true);
    const res: any = await getProjectTermByCategory(input, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetCategoryList(res.data.data.dataList1);
        }
        // setShowSkeleton(false);
      }
    }
  }

  async function SaveHelpDesk() {
    let obj = fnCheckValidationOfObject(HelpDeskSave);
    setHelpDeskSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      // if (HelpDeskSave.HelpDeskID !== "") {
      //   // Update
      //   // let Message: any = "PortFolio";
      //   setOnProcessHide(true);
      //   const res: any =  await SaveSupportReq(HelpDeskSave, props.Token);
      //
      //   setOnProcessHide(false);
      //   if (res.data !== undefined) {
      //     if (res.data.statusCode === 200) {
      //       // ToasterSuccess(Message);
      //       props.GetPortFolioList();
      //       handleClose();
      //       props.onHide(false);
      //     }
      //   } else {
      //   //   ToasterError(res.message);
      //   }
      // } else {
      // Add
      let body: any = {
        title: HelpDeskSave.title,
        category: HelpDeskSave.category,
        description: HelpDeskSave.description,
      };

      let Message: any = "Your ticket is raised Successfully.";

      setOnProcessHide(true);

      // return;
      const res: any = await SaveSupportReq(body, Token);
      setOnProcessHide(false);
      if (res.data !== undefined) {
        if (res.data.statusCode === 200) {
          ToasterSuccess(Message);
          // ToasterSuccess(Message + "RequestId : " + res.data.data.supportID);
          if(props.getSupportdata) {
            props.getSupportdata();
          } else {
            navigate("/support")
          }
          props.onHide(true);
        }
      } else {
        ToasterError(res.message);
      }
      // }
    }
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">New Support Ticket</Modal.Title>
          {/* {HelpDeskSave.status  !== undefined && HelpDeskSave.status  !== null &&   */}
          {/* <label className="info-label">{"Inprogress"}</label> */}
          {/* } */}
        </Modal.Header>
        <Modal.Body>
          <>
            {/* <Row> */}
            {/* Desrhaj */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <div
                  className={`${
                    HelpDeskSave.errors && HelpDeskSave.errors.category && "red-border-masking"
                  }`}
                >
                  {/* <FloatingLabel controlId="floatingSelectGrid" label=""> */}
                  <h5 className="support_ticket_add">Category</h5>
                  <Select
                    options={category_options}
                    onChange={(event: any) =>
                      setHelpDeskSave({
                        ...HelpDeskSave,
                        ["category"]: event.value,
                      })
                    }
                    // isMulti
                    name="colors"
                    placeholder="Select Category"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isDisabled={false}
                  />
                  {/* </FloatingLabel> */}
                  <small style={{ color: "#dc3545" }}>
                    {HelpDeskSave.errors && HelpDeskSave.errors.category}
                  </small>
                </div>
              </Form.Group>
            </Col>
            <h5 className="support_ticket_add">Title</h5>
            {/* <FloatingLabel controlId="floatingInput" label="Issue"> */}
            {/* <Form.Control type="text" placeholder="Hotel Code" disabled /> */}
            <Form.Control
              id="Issue"
              type="text"
              maxLength={164}
              placeholder="Title"
              // value={
              //   PropertySave.HChain.split("-")[0].trim() + PropertySave.HPropertyCode
              // }
              onChange={(e) =>
                setHelpDeskSave({
                  ...HelpDeskSave,
                  ["title"]: e.target.value,
                })
              }
              isInvalid={HelpDeskSave.errors && HelpDeskSave.errors.title}
              />
              {HelpDeskSave.errors && HelpDeskSave.errors.title && (
                <Form.Control.Feedback type="invalid">
                  {HelpDeskSave.errors && HelpDeskSave.errors.title}
                </Form.Control.Feedback>
              )}
            {/* </FloatingLabel> */}
            {/* <small style={{ color: "#dc3545" }}>
              {HelpDeskSave.errors && HelpDeskSave.errors.title}
            </small> */}

            <Col xs={12} className="mb-3 mt-3">
              <h5 className="support_ticket_add">Description</h5>
              <div className="modal-CKEditor">
                <div
                  className={`${
                    HelpDeskSave.errors && HelpDeskSave.errors.description && "red-border-masking"
                  }`}
                >
                  <CKEditor
                    editor={Editor}
                    data={HelpDeskSave.description}
                    config={{
                      extraPlugins: [CommonService.uploadPlugin],
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setHelpDeskSave({
                        ...HelpDeskSave,
                        ["description"]: data,
                      });
                    }}
                  />
                </div>
                <small style={{ color: "#dc3545" }}>
                  {HelpDeskSave.errors && HelpDeskSave.errors.description}
                </small>
              </div>
            </Col>

            {/* <Col xs={12} className="mb-3">
                <FloatingLabel controlId="floatingSelectGrid" label="Contact Technical Support">
                  <Form.Control
                    as="textarea"
                    placeholder="Contact Technical Support"
                    value={HelpDeskSave.description}
                    onChange={(event) =>
                      setHelpDeskSave({
                        ...HelpDeskSave,
                        ["description"]: event.target.value,
                      })
                    }
                    className={`form-control ${
                      HelpDeskSave.errors && HelpDeskSave.errors.description && "is-invalid"
                    }`}
                  />
                  {HelpDeskSave.errors && HelpDeskSave.errors.description && (
                    <Form.Control.Feedback type="invalid">
                      {HelpDeskSave.errors.description}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Col> */}
            {/* </Row> */}
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => SaveHelpDesk()}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddHelpDesk;
