import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";

import BlackCancelIcon from "../../../Asset/Images/Icons/blackCancelIcon.svg";
import PageLoader from "../../../Component/Loader/PageLoader";

const TaxViewModal = (props: any) => {
  const [inputList, setInputList]: any = useState([
    { taxTitle: "", rateOrPercentage: "", orderNo: 0, isPercentage: false, TaxRowNo: 0 },
  ]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (
      props.StoreEditRoomTypeTaxdata.length > 0 &&
      props.StoreEditRoomTypeTaxdata !== undefined &&
      props.StoreEditRoomTypeTaxdata !== null
    ) {
      setLoading(true);
      let JsonToIm: any = [];
      let JsonObj: any = {};

      props.StoreEditRoomTypeTaxdata.map((item) => {
        JsonObj = {
          ...JsonObj,
          taxTitle: item.taxTitle,
          rateOrPercentage: item.rateOrPercentage,
          orderNo: item.orderNo,
          isPercentage: item.isPercentage,
          taxRowNo: props.TaxRoomTypeRowNo,
        };
        JsonToIm.push(JsonObj);
      });
      if (JsonToIm.length > 0) {
        setInputList(JsonToIm);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="tax-information-popup quickAdd-popup"
        scrollable={true}
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.RoomNamePass && props.RoomNamePass + " -"} Tax Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="tax-information customtablemetting">
            {!loading ? (
              <Table className="table-without-space mb-0" responsive bordered>
                <thead>
                  <tr>
                    <th className="settitle w-50">Type of Tax</th>
                    <th className="settitle w-50">% of Tax</th>
                  </tr>
                </thead>
                <tbody>
                  {inputList.length !== 0 ? (
                    inputList.map((x) => {
                      return (
                        <>
                          <tr>
                            <td>{x.taxTitle}</td>
                            <td className="bordernone">
                              {x.isPercentage === false
                                ? "$" + x.rateOrPercentage
                                : x.rateOrPercentage}
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <td className="text-center" colSpan={2}>
                      No data found
                    </td>
                  )}
                </tbody>
              </Table>
            ) : (
              <PageLoader />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TaxViewModal;
