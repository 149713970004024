import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GreenLabel from "../../../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../../../Component/Labels/RedLabel/Index";
import TableView from "../../../../Component/Table/Index";
import { profileGetProperties } from "../../../../Services/ProfileServices";

export default function Hotels() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const hotelsTableColumns = React.useMemo(
    () => [
      {
        Header: "Hotel Name",
        accessor: "hotelName",
      },
      {
        Header: "Portfolio",
        accessor: "portfolio",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Web",
        accessor: "web",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );
  const hotelsTableData: any = [];
  data.map((itm: any) => {
    hotelsTableData.push({
      hotelName: itm.HotelName,
      portfolio: itm.Portfolio,
      city: itm.City,
      state: itm.State,
      contactNo: itm.Contact,
      email: itm.Email,
      web: itm.Web,
      status:
        itm.Status == "Active" ? (
          <GreenLabel successLabel="Active" />
        ) : (
          <RedLabel dangerLabel="Inactive" />
        ),
    });
  });

  const fetchData = async () => {
    let input = {
      keyID: userId,
    };
    let res: any = await profileGetProperties(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== null && typeof res.data.data !== "undefined") {
          setData(res.data.data.dataList1);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <TableView columnsData={hotelsTableColumns} tableData={hotelsTableData} isData={isLoading} />
  );
}
