import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "../components/authConfig";
import { getEmailInboxNextPrev, getEmailInbox } from "../components/graph";
import { getDateFormatIn_MonthName_Date } from "../../../Services/CommonServices";
import NoDataFoundImage from "../../../Component/NoData/NoDataFoundImage";
import GmailViewCommonModal from "../../../Model/GmailModals/GmailViewCommonModal";
import MicrosoftViewCommonModal from "../../../Model/MicrosoftModals/MicrosoftModals/MicrosoftViewCommonModal";
// import MicrosoftViewCommonModal from "../../../Model/MicrosoftModals/MicrosoftViewCommonModal";
// import MicrosoftViewCommonModal from '../../../Model/MicrosoftModals/MicrosoftViewCommonModal'
const Inbox = () => {
  const { instance, accounts } = useMsal();
  const [MailList, setMailList] = useState({ IsData: false, data: [] });
  const [IsLastPage, setIsLastPage] = useState(false);
  const [NextShow, setNextShow] = useState(false);
  const [PrevShow, setPrevShow] = useState(false);
  const NextShowRef = useRef(false);
  const [_PageNo, set_PageNo] = useState(1);
  const [_TotalPageNo, set_TotalPageNo] = useState(1);
  const ForLoader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const nextPrevRef = useRef(0);
  const [ShowMailsDetailsModal, setShowMailsDetailsModal] = useState(false);
  const [SelectedMailDetails, setSelectedMailDetails] = useState([]);

  // inbox email data
  function GetInboxEmail() {
    setMailList({ IsData: false, data: [] });
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        getEmailInbox(response.accessToken)
          .then((Response) => {
            if (Response?.value?.length < 10 && Response?.value?.length === 0) {
              setNextShow(false);
              setPrevShow(false);
            } else if (
              Response?.value?.length === 10 &&
              Response["@odata.nextLink"].slice(-7) === "skip=10"
            ) {
              setNextShow(true);
              setPrevShow(false);
            } else if (Response?.value?.length < 10) {
              setNextShow(false);
              // setPrevShow(true);
            }

            setMailList({ IsData: true, data: Response.value });
          })
          .catch((error) => {
            console.error("email inbox error ::", error);
            setMailList({ IsData: true, data: [] });
          });
      })
      .catch((error) => {
        console.error("Error acquiring access token:", error);
      });
  }

  function fnNextPrev(type) {
    if (type === "next") {
      nextPrevRef.current = nextPrevRef.current + 10;
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          getEmailInboxNextPrev(response.accessToken, nextPrevRef.current)
            .then((Response) => {
              if (
                Response["@odata.nextLink"] !== undefined &&
                Response["@odata.nextLink"].slice(-7) === "skip=10"
              ) {
                setNextShow(true);
                setPrevShow(false);
              } else if (Response.value.length < 10) {
                setNextShow(false);
                setPrevShow(true);
              } else if (Response["@odata.nextLink"] === undefined) {
                setNextShow(false);
                setPrevShow(false);
              } else if (Response["@odata.nextLink"].slice(-7) !== "skip=10") {
                setNextShow(true);
                setPrevShow(true);
              }
              setMailList({ IsData: true, data: Response.value });
            })
            .catch((error) => {
              console.error("Error fetching email data:", error);
            });
        })
        .catch((error) => {
          console.error("Error acquiring access token:", error);
        });
    }
    if (type === "prev") {
      nextPrevRef.current = nextPrevRef.current - 10;
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          getEmailInboxNextPrev(response.accessToken, nextPrevRef.current)
            .then((Response) => {
              if (
                Response["@odata.nextLink"] !== undefined &&
                Response["@odata.nextLink"].slice(-7) === "skip=10"
              ) {
                setNextShow(true);
                setPrevShow(false);
              } else if (Response["@odata.nextLink"].slice(-7) !== "skip=10") {
                setNextShow(true);
                setPrevShow(true);
              } else {
                setNextShow(false);
                setPrevShow(false);
              }

              setMailList({ IsData: true, data: Response.value });
            })
            .catch((error) => {
              console.error("Error fetching email data:", error);
            });
        })
        .catch((error) => {
          console.error("Error acquiring access token:", error);
        });
    }
  }

  useEffect(() => {
    GetInboxEmail();
  }, []);

  return (
    <div>
      <Col className="mt-4 mt-lg-0">
        <Row>
          {MailList.IsData ? (
            <>
              {/* <div className="">
                {IsLastPage !== true && _PageNo <= _TotalPageNo && (
                  <button
                    type="button"
                    className="savebtnclient btn btn-primary float-end"
                    onClick={(e) => PageChange(1)}
                  >
                    <i className="fa-regular fa-arrow-right me-2"></i>Next
                  </button>
                )}
                {_PageNo > 1 && (
                  <button
                    type="button"
                    className="savebtnclient btn btn-primary float-end me-2"
                    onClick={(e) => PageChange(-1)}
                  >
                    <i className="fa-regular fa-arrow-left me-2"></i>Previous
                  </button>
                )}
              </div> */}
              <div className="mt-2     justify-content-end d-flex">
                {PrevShow && (
                  <Button onClick={() => fnNextPrev("prev")} variant="primary me-2">
                    <i className="fa-regular fa-arrow-left me-2"></i> Previous
                  </Button>
                )}

                {NextShow && (
                  <Button onClick={() => fnNextPrev("next")} variant="primary">
                    <i className="fa-regular fa-arrow-right me-2"></i> Next
                  </Button>
                )}
              </div>
            </>
          ) : (
            ForLoader.map((item, index) => (
              <div className="card" key={index}>
                <div className="card-body">
                  <div className="card-text">
                    <Skeleton count={1} height={20} />
                  </div>
                  <div className="card-text">
                    <Skeleton count={1} height={20} />
                  </div>
                </div>
              </div>
            ))
          )}
        </Row>
        <Row className="mt-2">
          <div style={{ maxHeight: "700px", overflow: "auto" }}>
            {MailList.IsData ? (
              <>
                {MailList?.data?.length > 0 ? (
                  MailList.data.map((item, index) => (
                    <div
                      aria-hidden="true"
                      style={{ fontSize: "small" }}
                      className="card"
                      key={index}
                      onClick={(e) => {
                        // getMailDetailsData(item.index, item.messageId);
                        setSelectedMailDetails(item);
                      }}
                    >
                      <div
                        aria-hidden="true"
                        className="card-body"
                        onClick={() => setShowMailsDetailsModal(true)}
                      >
                        <div className="card-text">
                          {/* <b>{item.sender}</b> */}
                          <b>
                            {item.sender.emailAddress.name
                              ? item.sender.emailAddress.name
                              : item.sender.emailAddress.address}
                          </b>
                          <small className="text-muted float-end">
                            {getDateFormatIn_MonthName_Date(item.receivedDateTime)}
                          </small>
                        </div>
                        <div className="card-text">
                          {item.subject !== undefined &&
                          item.subject !== null &&
                          item.subject !== ""
                            ? item.subject
                            : "(no subject)"}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFoundImage />
                )}
              </>
            ) : (
              ForLoader.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-body">
                    <div className="card-text">
                      <Skeleton count={1} height={20} />
                    </div>
                    <div className="card-text">
                      <Skeleton count={1} height={20} />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </Row>
      </Col>

      {ShowMailsDetailsModal && (
        <MicrosoftViewCommonModal
          folderName={"inbox"}
          SelectedMailDetails={SelectedMailDetails}
          show={ShowMailsDetailsModal}
          onHide={() => setShowMailsDetailsModal(false)}
        />
      )}
    </div>
  );
};

export default Inbox;
