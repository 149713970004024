import "@szhsin/react-menu/dist/index.css";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DefaultInntelligentHotel from "../Asset/Images/DefaultLoginIcons/InntelligentHotel.png";
import MenuIcon from "../Asset/Images/Header/menu-icon.png";
import MenuTop from "../Component/TopMenu/MenuTop";

function DefaultHeader() {
  const dataSelector: any = useSelector((state: any) => state.dashboard);
  const ImagePath = dataSelector.isLogochangeData;
  const showSelectedLogo = dataSelector.SelectedLogo;
  const [show, setShow] = useState(false);
  let imageget: any = localStorage.getItem("AppLogo");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="header-top-new-block 123456789">
        <div className="main-header">
          <div className="logo">
            <Link to="/home">
              {showSelectedLogo !== undefined && showSelectedLogo && showSelectedLogo !== "" ? (
                <>
                  <img
                    className="light-visible"
                    src={showSelectedLogo}
                    alt=""
                    width={237}
                    height={46}
                  />
                  <img
                    className="dark-visible"
                    src={showSelectedLogo}
                    alt=""
                    width={237}
                    height={46}
                  />
                </>
              ) : ImagePath || imageget !== "null" ? (
                <>
                  <img
                    className="light-visible"
                    src={ImagePath || imageget}
                    alt=""
                    width={237}
                    height={46}
                  />
                  <img
                    className="dark-visible"
                    src={ImagePath || imageget}
                    alt=""
                    width={237}
                    height={46}
                  />
                </>
              ) : (
                <>
                  <img
                    className="light-visible"
                    src={DefaultInntelligentHotel}
                    alt=""
                    width={237}
                    height={46}
                  />
                  <img
                    className="dark-visible"
                    src={DefaultInntelligentHotel}
                    alt=""
                    width={237}
                    height={46}
                  />
                </>
              )}
            </Link>
          </div>
          <div className="d-none d-lg-flex align-items-lg-center justify-content-lg-around w-100">
            <MenuTop />
          </div>
          <Button variant="primary d-lg-none ms-auto" onClick={handleShow}>
            <img className="menu-icon" src={MenuIcon} alt="" />
          </Button>
          {show && (
            <Offcanvas show={show} onHide={handleClose} responsive="lg" placement="end">
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menu</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <MenuTop />
              </Offcanvas.Body>
            </Offcanvas>
          )}
        </div>
      </div>
    </>
  );
}

export default DefaultHeader;
