import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import { AppConfigData, CommonService, ToasterError } from "../../Services/CommonServices";
import {
  MeetingPaceReportList,
  MeetingPaceReportListExport,
  MeetingPaceReportListExportPDF,
} from "../../Services/ReportServices";
import PageLoader from "../../Component/Loader/PageLoader";

const MeetingPaceReportModal = (props) => {
  let CateringPaceReport_page_rights = CommonService.getPageRight("CateringPaceReport");
  const dataSelector: any = useSelector((state: any) => state.session);
  const [DownloadingPDF, setDownloadingPDF] = React.useState(false);
  const Token = dataSelector.access_Token;
  const [yearList, setYearList]: any = React.useState([]);
  const [Loading, setLoading]: any = React.useState(false);
  const [MeetingPaceReportData, setMeetingPaceReportData]: any = useState([]);
  const [Downloading, setDownloading] = React.useState(false);

  async function GenerateExcel() {
    let input: any = {
      propertyID: props.groupPaceReportData.propertyID,
      yearID: props.groupPaceReportData.yearID,
    };

    setDownloading(true);
    let res: any = await MeetingPaceReportListExport(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloading(false);
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    } else {
      ToasterError(res.data.message);
      setDownloading(false);
    }
    // }
  }

  async function fnMeetingPaceReportData() {
    let input: any = {
      propertyID: props.groupPaceReportData.propertyID,
      yearID: props.groupPaceReportData.yearID,
    };
    setLoading(true);
    let res: any = await MeetingPaceReportList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          setMeetingPaceReportData(res.data.data);
          setLoading(false);
        } else {
          ToasterError(res.data.message);
          setLoading(false);
        }
      } else {
        ToasterError(res.data.message);
        setLoading(false);
      }
    } else {
      ToasterError(res.data.message);
      setLoading(false);
    }
  }

  async function exportPDF() {
    let input: any = {
      propertyID: props.groupPaceReportData.propertyID,
      yearID: props.groupPaceReportData.yearID,
    };

    setDownloadingPDF(true);
    let res: any = await MeetingPaceReportListExportPDF(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloadingPDF(false);
        } else {
          ToasterError(res.data.message);
          setDownloadingPDF(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloadingPDF(false);
      }
    } else {
      ToasterError(res.data.message);
      setDownloadingPDF(false);
    }
  }

  React.useEffect(() => {
    fnMeetingPaceReportData();
  }, []);
  // Definites
  let CurrentDefinitesMonth1Revenue = 0;
  let CurrentDefinitesMonth2Revenue = 0;
  let CurrentDefinitesMonth3Revenue = 0;
  let CurrentDefinitesQuarter1 = 0;
  let PreviousDefinitesyearMonth1Revenue = 0;
  let PreviousDefinitesyearMonth2Revenue = 0;
  let PreviousDefinitesyearMonth3Revenue = 0;
  let PreviousDefinitesQuarter1 = 0;

  let CurrentDefinitesMonth4Revenue = 0;
  let CurrentDefinitesMonth5Revenue = 0;
  let CurrentDefinitesMonth6Revenue = 0;
  let CurrentDefinitesQuarter2 = 0;
  let PreviousDefinitesyearMonth4Revenue = 0;
  let PreviousDefinitesyearMonth5Revenue = 0;
  let PreviousDefinitesyearMonth6Revenue = 0;
  let PreviousDefinitesQuarter2 = 0;

  let CurrentDefinitesMonth7Revenue = 0;
  let CurrentDefinitesMonth8Revenue = 0;
  let CurrentDefinitesMonth9Revenue = 0;
  let CurrentDefinitesQuarter3 = 0;
  let PreviousDefinitesyearMonth7Revenue = 0;
  let PreviousDefinitesyearMonth8Revenue = 0;
  let PreviousDefinitesyearMonth9Revenue = 0;
  let PreviousDefinitesQuarter3 = 0;

  let CurrentDefinitesMonth10Revenue = 0;
  let CurrentDefinitesMonth11Revenue = 0;
  let CurrentDefinitesMonth12Revenue = 0;
  let CurrentDefinitesQuarter4 = 0;
  let PreviousDefinitesyearMonth10Revenue = 0;
  let PreviousDefinitesyearMonth11Revenue = 0;
  let PreviousDefinitesyearMonth12Revenue = 0;
  let PreviousDefinitesQuarter4 = 0;
  // Tentatives
  let CurrentTentativesMonth1Revenue = 0;
  let CurrentTentativesMonth2Revenue = 0;
  let CurrentTentativesMonth3Revenue = 0;
  let CurrentTentativesQuarter1 = 0;
  let PreviousTentativesyearMonth1Revenue = 0;
  let PreviousTentativesyearMonth2Revenue = 0;
  let PreviousTentativesyearMonth3Revenue = 0;
  let PreviousTentativesQuarter1 = 0;

  let CurrentTentativesMonth4Revenue = 0;
  let CurrentTentativesMonth5Revenue = 0;
  let CurrentTentativesMonth6Revenue = 0;
  let CurrentTentativesQuarter2 = 0;
  let PreviousTentativesyearMonth4Revenue = 0;
  let PreviousTentativesyearMonth5Revenue = 0;
  let PreviousTentativesyearMonth6Revenue = 0;
  let PreviousTentativesQuarter2 = 0;

  let CurrentTentativesMonth7Revenue = 0;
  let CurrentTentativesMonth8Revenue = 0;
  let CurrentTentativesMonth9Revenue = 0;
  let CurrentTentativesQuarter3 = 0;
  let PreviousTentativesyearMonth7Revenue = 0;
  let PreviousTentativesyearMonth8Revenue = 0;
  let PreviousTentativesyearMonth9Revenue = 0;
  let PreviousTentativesQuarter3 = 0;

  let CurrentTentativesMonth10Revenue = 0;
  let CurrentTentativesMonth11Revenue = 0;
  let CurrentTentativesMonth12Revenue = 0;
  let CurrentTentativesQuarter4 = 0;
  let PreviousTentativesyearMonth10Revenue = 0;
  let PreviousTentativesyearMonth11Revenue = 0;
  let PreviousTentativesyearMonth12Revenue = 0;
  let PreviousTentativesQuarter4 = 0;
  // Actual Pickup
  let CurrentActualPickupMonth1Revenue = 0;
  let CurrentActualPickupMonth2Revenue = 0;
  let CurrentActualPickupMonth3Revenue = 0;
  let CurrentActualPickupQuarter1 = 0;
  let PreviousActualPickupyearMonth1Revenue = 0;
  let PreviousActualPickupyearMonth2Revenue = 0;
  let PreviousActualPickupyearMonth3Revenue = 0;
  let PreviousActualPickupQuarter1 = 0;

  let CurrentActualPickupMonth4Revenue = 0;
  let CurrentActualPickupMonth5Revenue = 0;
  let CurrentActualPickupMonth6Revenue = 0;
  let CurrentActualPickupQuarter2 = 0;
  let PreviousActualPickupyearMonth4Revenue = 0;
  let PreviousActualPickupyearMonth5Revenue = 0;
  let PreviousActualPickupyearMonth6Revenue = 0;
  let PreviousActualPickupQuarter2 = 0;

  let CurrentActualPickupMonth7Revenue = 0;
  let CurrentActualPickupMonth8Revenue = 0;
  let CurrentActualPickupMonth9Revenue = 0;
  let CurrentActualPickupQuarter3 = 0;
  let PreviousActualPickupyearMonth7Revenue = 0;
  let PreviousActualPickupyearMonth8Revenue = 0;
  let PreviousActualPickupyearMonth9Revenue = 0;
  let PreviousActualPickupQuarter3 = 0;

  let CurrentActualPickupMonth10Revenue = 0;
  let CurrentActualPickupMonth11Revenue = 0;
  let CurrentActualPickupMonth12Revenue = 0;
  let CurrentActualPickupQuarter4 = 0;
  let PreviousActualPickupyearMonth10Revenue = 0;
  let PreviousActualPickupyearMonth11Revenue = 0;
  let PreviousActualPickupyearMonth12Revenue = 0;
  let PreviousActualPickupQuarter4 = 0;

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>
            Meeting Pace Report ({props.groupPaceReportData && props.groupPaceReportData.year})
          </Modal.Title>
        </Modal.Header>

        {Loading ? (
          <PageLoader />
        ) : (
          <Modal.Body className="meeting-pace-report" style={{fontWeight:"550"}}>
            {
            MeetingPaceReportData.propertyData !== undefined &&
            MeetingPaceReportData.propertyData &&
            MeetingPaceReportData.propertyData.PropertyLogo ? (
              <div className="text-center mt-3">
                <img src={MeetingPaceReportData.propertyData.PropertyLogo} style={{ width: "200px", maxHeight: "150px" }} alt="" />
              </div>
            ) : (
              ""
            )}
            <div className="report-col mt-3 mb-3">
              <div className="report-title">
                <span>
                  {MeetingPaceReportData.propertyData &&
                    MeetingPaceReportData.propertyData.PropertyName}
                </span>
              </div>
            </div>
            <Table striped bordered hover className="mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">January</th>
                  <th className="text-center">February</th>
                  <th className="text-center">March</th>
                  <th className="text-center">Quarter 1</th>
                </tr>
                <tr>
                  <th></th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 1 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth1Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 2 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth2Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth3Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentDefinitesQuarter1 =
                        CurrentDefinitesMonth1Revenue +
                        CurrentDefinitesMonth2Revenue +
                        CurrentDefinitesMonth3Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 1 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth1Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 2 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth2Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth3Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousDefinitesQuarter1 =
                        PreviousDefinitesyearMonth1Revenue +
                        PreviousDefinitesyearMonth2Revenue +
                        PreviousDefinitesyearMonth3Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth1Revenue - PreviousDefinitesyearMonth1Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth1Revenue - PreviousDefinitesyearMonth1Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth1Revenue - PreviousDefinitesyearMonth1Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth2Revenue - PreviousDefinitesyearMonth2Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth2Revenue - PreviousDefinitesyearMonth2Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth2Revenue - PreviousDefinitesyearMonth2Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth3Revenue - PreviousDefinitesyearMonth3Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth3Revenue - PreviousDefinitesyearMonth3Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth3Revenue - PreviousDefinitesyearMonth3Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesQuarter1 - PreviousDefinitesQuarter1 > 0
                        ? "font-green"
                        : CurrentDefinitesQuarter1 - PreviousDefinitesQuarter1 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesQuarter1 - PreviousDefinitesQuarter1}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 1 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth1Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 2 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth2Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth3Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentTentativesQuarter1 =
                        CurrentTentativesMonth1Revenue +
                        CurrentTentativesMonth2Revenue +
                        CurrentTentativesMonth3Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 1 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth1Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 2 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth2Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth3Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousTentativesQuarter1 =
                        PreviousTentativesyearMonth1Revenue +
                        PreviousTentativesyearMonth2Revenue +
                        PreviousTentativesyearMonth3Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth1Revenue - PreviousTentativesyearMonth1Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth1Revenue - PreviousTentativesyearMonth1Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth1Revenue - PreviousTentativesyearMonth1Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth2Revenue - PreviousTentativesyearMonth2Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth2Revenue - PreviousTentativesyearMonth2Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth2Revenue - PreviousTentativesyearMonth2Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth3Revenue - PreviousTentativesyearMonth3Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth3Revenue - PreviousTentativesyearMonth3Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth3Revenue - PreviousTentativesyearMonth3Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesQuarter1 - PreviousTentativesQuarter1 > 0
                        ? "font-green"
                        : CurrentTentativesQuarter1 - PreviousTentativesQuarter1 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesQuarter1 - PreviousTentativesQuarter1}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth1Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 2 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth2Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth3Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentActualPickupQuarter1 =
                        CurrentActualPickupMonth1Revenue +
                        CurrentActualPickupMonth2Revenue +
                        CurrentActualPickupMonth3Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Actual Room -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 1 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth1Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 2 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth2Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 3 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth3Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousActualPickupQuarter1 =
                        PreviousActualPickupyearMonth1Revenue +
                        PreviousActualPickupyearMonth2Revenue +
                        PreviousActualPickupyearMonth3Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth1Revenue - PreviousActualPickupyearMonth1Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth1Revenue -
                            PreviousActualPickupyearMonth1Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth1Revenue - PreviousActualPickupyearMonth1Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth2Revenue - PreviousActualPickupyearMonth2Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth2Revenue -
                            PreviousActualPickupyearMonth2Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth2Revenue - PreviousActualPickupyearMonth2Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth3Revenue - PreviousActualPickupyearMonth3Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth3Revenue -
                            PreviousActualPickupyearMonth3Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth3Revenue - PreviousActualPickupyearMonth3Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupQuarter1 - PreviousActualPickupQuarter1 > 0
                        ? "font-green"
                        : CurrentActualPickupQuarter1 - PreviousActualPickupQuarter1 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupQuarter1 - PreviousActualPickupQuarter1}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover className="mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">April</th>
                  <th className="text-center">May</th>
                  <th className="text-center">June</th>
                  <th className="text-center">Quarter 2</th>
                </tr>
                <tr>
                  <th></th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 4 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth4Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 5 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth5Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 6 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth6Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentDefinitesQuarter2 =
                        CurrentDefinitesMonth4Revenue +
                        CurrentDefinitesMonth5Revenue +
                        CurrentDefinitesMonth6Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 4 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth4Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 5 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth5Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 6 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth6Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousDefinitesQuarter2 =
                        PreviousDefinitesyearMonth4Revenue +
                        PreviousDefinitesyearMonth5Revenue +
                        PreviousDefinitesyearMonth6Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth4Revenue - PreviousDefinitesyearMonth4Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth4Revenue - PreviousDefinitesyearMonth4Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth4Revenue - PreviousDefinitesyearMonth4Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth5Revenue - PreviousDefinitesyearMonth5Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth5Revenue - PreviousDefinitesyearMonth5Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth5Revenue - PreviousDefinitesyearMonth5Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth6Revenue - PreviousDefinitesyearMonth6Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth6Revenue - PreviousDefinitesyearMonth6Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth6Revenue - PreviousDefinitesyearMonth6Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesQuarter2 - PreviousDefinitesQuarter2 > 0
                        ? "font-green"
                        : CurrentDefinitesQuarter2 - PreviousDefinitesQuarter2 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesQuarter2 - PreviousDefinitesQuarter2}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 4 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth4Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 5 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth5Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 6 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth6Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentTentativesQuarter2 =
                        CurrentTentativesMonth4Revenue +
                        CurrentTentativesMonth5Revenue +
                        CurrentTentativesMonth6Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 4 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth4Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 5 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth5Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 6 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth6Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousTentativesQuarter2 =
                        PreviousTentativesyearMonth4Revenue +
                        PreviousTentativesyearMonth5Revenue +
                        PreviousTentativesyearMonth6Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth4Revenue - PreviousTentativesyearMonth4Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth4Revenue - PreviousTentativesyearMonth4Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth4Revenue - PreviousTentativesyearMonth4Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth5Revenue - PreviousTentativesyearMonth5Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth5Revenue - PreviousTentativesyearMonth5Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth5Revenue - PreviousTentativesyearMonth5Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth6Revenue - PreviousTentativesyearMonth6Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth6Revenue - PreviousTentativesyearMonth6Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth6Revenue - PreviousTentativesyearMonth6Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesQuarter2 - PreviousTentativesQuarter2 > 0
                        ? "font-green"
                        : CurrentTentativesQuarter2 - PreviousTentativesQuarter2 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesQuarter2 - PreviousTentativesQuarter2}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 4 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth4Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 5 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth5Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 6 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth6Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentActualPickupQuarter2 =
                        CurrentActualPickupMonth4Revenue +
                        CurrentActualPickupMonth5Revenue +
                        CurrentActualPickupMonth6Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Actual Room -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 4 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth4Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 5 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth5Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 6 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth6Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousActualPickupQuarter2 =
                        PreviousActualPickupyearMonth4Revenue +
                        PreviousActualPickupyearMonth5Revenue +
                        PreviousActualPickupyearMonth6Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth4Revenue - PreviousActualPickupyearMonth4Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth4Revenue -
                            PreviousActualPickupyearMonth4Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth4Revenue - PreviousActualPickupyearMonth4Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth5Revenue - PreviousActualPickupyearMonth5Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth5Revenue -
                            PreviousActualPickupyearMonth5Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth5Revenue - PreviousActualPickupyearMonth5Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth6Revenue - PreviousActualPickupyearMonth6Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth6Revenue -
                            PreviousActualPickupyearMonth6Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth6Revenue - PreviousActualPickupyearMonth6Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupQuarter2 - PreviousActualPickupQuarter2 > 0
                        ? "font-green"
                        : CurrentActualPickupQuarter2 - PreviousActualPickupQuarter2 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupQuarter2 - PreviousActualPickupQuarter2}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover className="mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">July</th>
                  <th className="text-center">August</th>
                  <th className="text-center">September</th>
                  <th className="text-center">Quarter 3</th>
                </tr>
                <tr>
                  <th></th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 7 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth7Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 8 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth8Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 9 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth9Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentDefinitesQuarter3 =
                        CurrentDefinitesMonth7Revenue +
                        CurrentDefinitesMonth8Revenue +
                        CurrentDefinitesMonth9Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 7 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth7Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 8 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth8Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 9 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth9Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousDefinitesQuarter3 =
                        PreviousDefinitesyearMonth7Revenue +
                        PreviousDefinitesyearMonth8Revenue +
                        PreviousDefinitesyearMonth9Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth7Revenue - PreviousDefinitesyearMonth7Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth7Revenue - PreviousDefinitesyearMonth7Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth7Revenue - PreviousDefinitesyearMonth7Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth8Revenue - PreviousDefinitesyearMonth8Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth8Revenue - PreviousDefinitesyearMonth8Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth8Revenue - PreviousDefinitesyearMonth8Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth9Revenue - PreviousDefinitesyearMonth9Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth9Revenue - PreviousDefinitesyearMonth9Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth9Revenue - PreviousDefinitesyearMonth9Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesQuarter3 - PreviousDefinitesQuarter3 > 0
                        ? "font-green"
                        : CurrentDefinitesQuarter3 - PreviousDefinitesQuarter3 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesQuarter3 - PreviousDefinitesQuarter3}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 7 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth7Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 8 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth8Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 9 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth9Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentTentativesQuarter3 =
                        CurrentTentativesMonth7Revenue +
                        CurrentTentativesMonth8Revenue +
                        CurrentTentativesMonth9Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 7 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth7Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 8 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth8Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 9 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth9Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousTentativesQuarter3 =
                        PreviousTentativesyearMonth7Revenue +
                        PreviousTentativesyearMonth8Revenue +
                        PreviousTentativesyearMonth9Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth7Revenue - PreviousTentativesyearMonth7Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth7Revenue - PreviousTentativesyearMonth7Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth7Revenue - PreviousTentativesyearMonth7Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth8Revenue - PreviousTentativesyearMonth8Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth8Revenue - PreviousTentativesyearMonth8Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth8Revenue - PreviousTentativesyearMonth8Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth9Revenue - PreviousTentativesyearMonth9Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth9Revenue - PreviousTentativesyearMonth9Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth9Revenue - PreviousTentativesyearMonth9Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesQuarter3 - PreviousTentativesQuarter3 > 0
                        ? "font-green"
                        : CurrentTentativesQuarter3 - PreviousTentativesQuarter3 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesQuarter3 - PreviousTentativesQuarter3}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 7 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth7Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 8 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth8Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 9 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth9Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentActualPickupQuarter3 =
                        CurrentActualPickupMonth7Revenue +
                        CurrentActualPickupMonth8Revenue +
                        CurrentActualPickupMonth9Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Actual Room -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 7 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth7Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 8 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth8Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 9 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth9Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousActualPickupQuarter3 =
                        PreviousActualPickupyearMonth7Revenue +
                        PreviousActualPickupyearMonth8Revenue +
                        PreviousActualPickupyearMonth9Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth7Revenue - PreviousActualPickupyearMonth7Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth7Revenue -
                            PreviousActualPickupyearMonth7Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth7Revenue - PreviousActualPickupyearMonth7Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth8Revenue - PreviousActualPickupyearMonth8Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth8Revenue -
                            PreviousActualPickupyearMonth8Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth8Revenue - PreviousActualPickupyearMonth8Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth9Revenue - PreviousActualPickupyearMonth9Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth9Revenue -
                            PreviousActualPickupyearMonth9Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth9Revenue - PreviousActualPickupyearMonth9Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupQuarter3 - PreviousActualPickupQuarter3 > 0
                        ? "font-green"
                        : CurrentActualPickupQuarter3 - PreviousActualPickupQuarter3 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupQuarter3 - PreviousActualPickupQuarter3}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table striped bordered hover className="mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">October</th>
                  <th className="text-center">November</th>
                  <th className="text-center">December</th>
                  <th className="text-center">Quarter 4</th>
                </tr>
                <tr>
                  <th></th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 10 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth10Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 11 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth11Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 12 && (
                          <td className="text-end">
                            {(CurrentDefinitesMonth12Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentDefinitesQuarter4 =
                        CurrentDefinitesMonth10Revenue +
                        CurrentDefinitesMonth11Revenue +
                        CurrentDefinitesMonth12Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Definites -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 10 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth10Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 11 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth11Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearDefiniteData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 12 && (
                          <td className="text-end">
                            {(PreviousDefinitesyearMonth12Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousDefinitesQuarter4 =
                        PreviousDefinitesyearMonth10Revenue +
                        PreviousDefinitesyearMonth11Revenue +
                        PreviousDefinitesyearMonth12Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth10Revenue - PreviousDefinitesyearMonth10Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth10Revenue - PreviousDefinitesyearMonth10Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth10Revenue - PreviousDefinitesyearMonth10Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth11Revenue - PreviousDefinitesyearMonth11Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth11Revenue - PreviousDefinitesyearMonth11Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth11Revenue - PreviousDefinitesyearMonth11Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesMonth12Revenue - PreviousDefinitesyearMonth12Revenue > 0
                        ? "font-green"
                        : CurrentDefinitesMonth12Revenue - PreviousDefinitesyearMonth12Revenue === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesMonth12Revenue - PreviousDefinitesyearMonth12Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentDefinitesQuarter4 - PreviousDefinitesQuarter4 > 0
                        ? "font-green"
                        : CurrentDefinitesQuarter4 - PreviousDefinitesQuarter4 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentDefinitesQuarter4 - PreviousDefinitesQuarter4}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData && MeetingPaceReportData.propertyData.Year}
                  </td>
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 10 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth10Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 11 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth11Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstCurrentYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 12 && (
                          <td className="text-end">
                            {(CurrentTentativesMonth12Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentTentativesQuarter4 =
                        CurrentTentativesMonth10Revenue +
                        CurrentTentativesMonth11Revenue +
                        CurrentTentativesMonth12Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Tentatives -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 10 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth10Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 11 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth11Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearTentativeData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 12 && (
                          <td className="text-end">
                            {(PreviousTentativesyearMonth12Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousTentativesQuarter4 =
                        PreviousTentativesyearMonth10Revenue +
                        PreviousTentativesyearMonth11Revenue +
                        PreviousTentativesyearMonth12Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth10Revenue - PreviousTentativesyearMonth10Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth10Revenue - PreviousTentativesyearMonth10Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth10Revenue - PreviousTentativesyearMonth10Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth11Revenue - PreviousTentativesyearMonth11Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth11Revenue - PreviousTentativesyearMonth11Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth11Revenue - PreviousTentativesyearMonth11Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesMonth12Revenue - PreviousTentativesyearMonth12Revenue > 0
                        ? "font-green"
                        : CurrentTentativesMonth12Revenue - PreviousTentativesyearMonth12Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesMonth12Revenue - PreviousTentativesyearMonth12Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentTentativesQuarter4 - PreviousTentativesQuarter4 > 0
                        ? "font-green"
                        : CurrentTentativesQuarter4 - PreviousTentativesQuarter4 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentTentativesQuarter4 - PreviousTentativesQuarter4}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 10 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth10Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 11 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth11Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualPickupData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 12 && (
                          <td className="text-end">
                            {(CurrentActualPickupMonth12Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (CurrentActualPickupQuarter4 =
                        CurrentActualPickupMonth10Revenue +
                        CurrentActualPickupMonth11Revenue +
                        CurrentActualPickupMonth12Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>
                    Actual Room -{" "}
                    {MeetingPaceReportData.propertyData &&
                      MeetingPaceReportData.propertyData.PreviousYear}
                  </td>
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 10 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth10Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 11 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth11Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  {MeetingPaceReportData.lstPreviousYearActualData !== undefined &&
                    MeetingPaceReportData.lstPreviousYearActualData.map((Defitem) => (
                      <>
                        {Defitem.MonthNo === 12 && (
                          <td className="text-end">
                            {(PreviousActualPickupyearMonth12Revenue = Defitem.AnticipatedRevenue)}
                          </td>
                        )}
                      </>
                    ))}
                  <td className="text-end">
                    {
                      (PreviousActualPickupQuarter4 =
                        PreviousActualPickupyearMonth10Revenue +
                        PreviousActualPickupyearMonth11Revenue +
                        PreviousActualPickupyearMonth12Revenue)
                    }
                  </td>
                </tr>
                <tr>
                  <td>Variance</td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth10Revenue - PreviousActualPickupyearMonth10Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth10Revenue -
                            PreviousActualPickupyearMonth10Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth10Revenue - PreviousActualPickupyearMonth10Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth11Revenue - PreviousActualPickupyearMonth11Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth11Revenue -
                            PreviousActualPickupyearMonth11Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth11Revenue - PreviousActualPickupyearMonth11Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupMonth12Revenue - PreviousActualPickupyearMonth12Revenue > 0
                        ? "font-green"
                        : CurrentActualPickupMonth12Revenue -
                            PreviousActualPickupyearMonth12Revenue ===
                          0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupMonth12Revenue - PreviousActualPickupyearMonth12Revenue}
                  </td>
                  <td
                    className={`text-end ${
                      CurrentActualPickupQuarter4 - PreviousActualPickupQuarter4 > 0
                        ? "font-green"
                        : CurrentActualPickupQuarter4 - PreviousActualPickupQuarter4 === 0
                        ? ""
                        : "font-red"
                    }`}
                  >
                    {CurrentActualPickupQuarter4 - PreviousActualPickupQuarter4}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        )}

        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary ms-2"
            onClick={() => {
              if (CateringPaceReport_page_rights.is_export) {
                GenerateExcel();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            disabled={Downloading === true ? true : false}
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
          <Button
            variant="primary ms-0"
            disabled={DownloadingPDF === true ? true : false}
            onClick={() => {
              if (CateringPaceReport_page_rights.is_export) {
                exportPDF();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          >
            <span className="me-2">
              <img src={PdfIcon} alt="" />
            </span>
            Export PDF
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MeetingPaceReportModal;
