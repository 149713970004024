import React, { useState } from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import BlueLabel from "../../Component/Labels/BlueLabel/Index";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import TableView from "../../Component/Table/Index";
import AddHelpDesk from "../../Model/AddHelpDesk";
import AddViewTicket from "../../Model/AddViewTicket";

import eyeIcon from "../../Asset/Images/Icons/eye.svg";
import { getSupportList, ReOpenTicket } from "../../Services/LeftMenuServices";
import {
  getDateInFormat,
  getDateTimeInDBFormat,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";
import { useNavigate } from "react-router-dom";

export function SupportPage() {
  let navigate = useNavigate();
  const [HelpDesk, setHelpDesk] = useState(false);
  const [ViewTicket, setViewTicket] = useState(false);
  const [ViewID, setViewID] = useState("");
  const [ViewClientID, setViewClientID] = useState("");
  const [ViewClientUserName, setViewClientUserName] = useState("");
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const mainTable = React.useMemo(
    () => [
      {
        Header: "Ticket no.",
        accessor: "TicketNo",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Title",
        accessor: "Title",
      },
      {
        Header: "Raised On",
        accessor: "CreatedOn",
      },
      {
        Header: "Assign To",
        accessor: "AssignToUserName",
      },

      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "table-action",
      },
    ],
    []
  );
  const [Suspectlist, setSuspectlist] = React.useState([]);
  const [IsDataCheck, setIsDataCheck] = React.useState(false);
  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("supportFilter"))) {
      HandleRemoveOtherFilters("supportFilter");
      HandleFilters("supportFilter", {
        pagePath: "/support",
        pageName: "support",
        filters: [],
        isSort: [],
      });
    }
    getSupportdata();
  }, []);
  async function getSupportdata() {
    setIsDataCheck(true);

    const res: any = await getSupportList(Token);

    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setSuspectlist(res.data.data.dataList1);

          setIsDataCheck(false);
        } else {
          setSuspectlist([]);
          setIsDataCheck(false);
        }
      }
    }
  }

  let mainTableData: any = [];
  Suspectlist &&
    Suspectlist.map((item: any) => {
      mainTableData.push({
        controlRender: {
          Status:
            item.Status !== null &&
            item.Status !== "" &&
            item.Status !== undefined &&
            (item.Status === "Open" ? (
              <RedLabel dangerLabel={item.Status} />
            ) : item.Status === "Closed" ? (
              <GreenLabel successLabel={item.Status} />
            ) : item.Status === "InProgress" ? (
              <BlueLabel blueLabel={item.Status} />
            ) : (
              <></>
            )),
          TicketNo: (
            <div
              role="button"
              tabIndex={-1}
              onClick={() => {
                navigate("/support/details", { state: { SupportID: item.SupportID } });
              }}
              onKeyDown={() => {
                navigate("/support/details", { state: { SupportID: item.SupportID } });
              }}
            >
              {item.SupportID !== undefined && item.SupportID !== null
                ? item.SupportID.replaceAll("-", "")
                : item.SupportID}
            </div>
          ),
          CreatedOn: getDateInFormat(item.CreatedOn),
        },
        TicketNo:
          item.SupportID !== undefined && item.SupportID !== null
            ? item.SupportID.replaceAll("-", "")
            : item.SupportID,
        category: item.Category,
        Title: item.Title,
        CreatedOn: getDateTimeInDBFormat(item.CreatedOn),
        AssignToUserName: item.AssignToUserName !== null && item.AssignToUserName,
        AssignToEmail: item.AssignToEmail,
        Status: item.Status,

        action: (
          <div className="btn-icon-grp-table">
            {item.Status !== "Closed" && (
              <Button
                className="btn-icon button btn btn-primary editIcon acteditbtn"
                onClick={() => {
                  setViewID(item.SupportID);
                  setViewClientID(item.ClientID);
                  setViewClientUserName(item.UserName);
                  setViewTicket(true);
                }}
              ></Button>
            )}{" "}
            {item.Status === "Closed" && (
              <>
                <span
                  className="me-2"
                  onClick={() => {
                    setViewID(item.SupportID);
                    setViewTicket(true);
                  }}
                >
                  <img src={eyeIcon} alt="" />
                </span>
              </>
            )}
          </div>
        ),
      });
    });
  function ReOpenTicketData(data) {
    Swal.fire({
      title: "Are you sure want to Re-Open Ticket ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          KeyID: data,
        };

        let res: any = await ReOpenTicket(input, Token);

        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              ToasterSuccess("Ticket Open Successfully");

              getSupportdata();
            } else {
              ToasterError("Something Went Wrong");
            }
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }
  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Support Ticket</Tooltip>}>
          <Button
            className="btn-icon addFormIcon"
            onClick={() => {
              setHelpDesk(true);
            }}
          ></Button>
        </OverlayTrigger>
      </div>

      <Container fluid={true} className="p-0">
        <Row>
          <Col xs={12}>
            <TableView columnsData={mainTable} tableData={mainTableData} isData={IsDataCheck} />
          </Col>
        </Row>
      </Container>
      {HelpDesk && (
        <AddHelpDesk
          show={HelpDesk}
          onHide={() => setHelpDesk(false)}
          getSupportdata={getSupportdata}
        ></AddHelpDesk>
      )}
      {ViewTicket && (
        <AddViewTicket
          show={ViewTicket}
          ViewID={ViewID}
          ViewClientID={ViewClientID}
          ViewClientUserName={ViewClientUserName}
          onHide={() => setViewTicket(false)}
        ></AddViewTicket>
      )}
    </>
  );
}

export default SupportPage;
function setModalShowEditHelpDesk(arg0: boolean) {
  throw new Error("Function not implemented.");
}
