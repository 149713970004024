import axios from "axios";
import { AppConfigData } from "../CommonServices";
import { Cookies } from "react-cookie-consent";


export const GetEmailsListingByFolderName = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url: AppConfigData.APIBasePath + "client/api/Mail/GetEmailsByFolder",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error :any) {
      let data : any = null
      if(error.response.status === 401){
        data = {
          data: {
            success: false,
            StatusCode: "401",
            Message: "Session has been expired.",
          },
        };
        Cookies.remove("SystemSettingDate");
        localStorage.clear()
        window.location.pathname = '/'
      }else{
      data = {
          data: {
            success: false,
            StatusCode: "02",
            Message: "Something Wrong in API",
          },
        };
      }
      return data;
    }
  };

  export const GetMailDetailsByFolder = async (ReqData, accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url: AppConfigData.APIBasePath + "client/api/Mail/GetMailDetailsByFolder",
        data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error :any) {
      let data : any = null
      if(error.response.status === 401){
        data = {
          data: {
            success: false,
            StatusCode: "401",
            Message: "Session has been expired.",
          },
        };
        Cookies.remove("SystemSettingDate");
        localStorage.clear()
        window.location.pathname = '/'
      }else{
      data = {
          data: {
            success: false,
            StatusCode: "02",
            Message: "Something Wrong in API",
          },
        };
      }
      return data;
    }
  };

  export const GetKnowledgeTutorialsList = async (accessToken) => {
    try {
      const response = await axios({
        method: "POST",
        url: AppConfigData.APIBasePath + "client/api/KnowledgeTutorials/GetKnowledgeTutorialsList",
        // data: ReqData,
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + accessToken,
        },
      });
      return response;
    } catch (error :any) {
      let data : any = null
      if(error.response.status === 401){
        data = {
          data: {
            success: false,
            StatusCode: "401",
            Message: "Session has been expired.",
          },
        };
        Cookies.remove("SystemSettingDate");
        localStorage.clear()
        window.location.pathname = '/'
      }else{
      data = {
          data: {
            success: false,
            StatusCode: "02",
            Message: "Something Wrong in API",
          },
        };
      }
      return data;
    }
  };