import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import PageLoader from "../Component/Loader/PageLoader";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import {
  UpdateAccountRuleByID,
  getAccountRuleByID,
} from "../Services/Setup&Config/AccountRulesService";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";

function AddAccountRuleModal(props) {
  const [loading, setLoading] = React.useState(false);

  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  let initials: any = {
    objective: "",
    accountRulesConfigurationID: null,
    accountRulesName: "",
    accountRulesTypeID: null,
    orderNo: 0,
    details: "",
    clientID: null,
    isDisabled: false,
    updateBy: null,
    updateOn: new Date(),
    statusID: null,
    typeID: null,
    startDate: "",
    startDateRules: "",
    endDate: "",
    endDateRules: "",
    status: "",
    type: "",
    errors: {
      orderNo: "",
      StatusID: "",
      TypeID: "",
      objective: "",
      ValidationRules: [
        {
          FieldName: "objective",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "orderNo",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "StatusID",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "TypeID",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  };
  const [AddAccountRule, setAddAccountRule] = React.useState(initials);
  const [Status, setStatus] = React.useState([]);
  const [AccountRulesDate, setAccountRulesDate] = React.useState([]);
  const [RuleDate, setRuleDate] = React.useState([]);
  const [ActivityType, setActivityType] = React.useState([]);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.

  React.useEffect(() => {
    sethotelChangeLoading(true)
    getAccountRulesDataByProjectTerm();
    getAccountRuleData();
  }, []);

  async function getAccountRuleData() {
    let input: any = {
      keyID: props.ruleID,
    };
    let res: any = await getAccountRuleByID(input, props.Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null) {
        setAddAccountRule({
          ...AddAccountRule,
          accountRulesConfigurationID: res.data.data.accountRulesConfigurationID,
          accountRulesName: res.data.data.accountRulesName,
          AccountRulesTypeID: res.data.data.accountRulesTypeID,
          ClientID: res.data.data.clientID,
          details: res.data.data.details,
          endDate: res.data.data.endDate,
          endDateRules: res.data.data.endDateRules,
          IsDisabled: res.data.data.isDisabled,
          orderNo: res.data.data.orderNo,
          startDate: res.data.data.startDate,
          startDateRules: res.data.data.startDateRules,
          status: res.data.data.status,
          StatusID: res.data.data.statusID,
          type: res.data.data.type,
          TypeID: res.data.data.typeID,
          UpdateBy: res.data.data.updateBy,
          UpdateOn: res.data.data.updateOn,
          objective: res.data.data.objective,
        });
      }
    }
    sethotelChangeLoading(false)
  }

  async function getAccountRulesDataByProjectTerm() {
    let input: any = {
      category: "Status,AccountRulesDateRules,AccountRulesDate,Activity Type",
      clientID: clientID,
    };
    // setLoading(true);
    let res: any = await getProjectTermByCategory(input, props.Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let status: any = [];

          let RulesDateRules: any = [];

          let RulesDate: any = [];

          let ActivityType: any = [];

          res.data.data.dataList1.map((item: any) => {
            if (item.Category === "Status") {
              status.push(item);
            }
            if (item.Category === "AccountRulesDateRules") {
              RulesDateRules.push(item);
            }
            if (item.Category === "AccountRulesDate") {
              RulesDate.push(item);
            }
            if (item.Category === "Activity Type") {
              ActivityType.push(item);
            }
          });

          setRuleDate(RulesDate);
          setActivityType(ActivityType);
          setStatus(status);
          setAccountRulesDate(RulesDateRules);
        }
      }
    }
    // setLoading(false);
  }

  async function SaveRule() {
    let obj = fnCheckValidationOfObject(AddAccountRule);
    setAddAccountRule({
      ...obj.obj,
    });
    if (obj.isValid) {
      let status: any = document.getElementById("statusDropdown");
      let type: any = document.getElementById("typeDropdown");

      let input: any = {
        accountRulesConfigurationID: AddAccountRule.accountRulesConfigurationID,
        accountRulesName: AddAccountRule.accountRulesName,
        startDate: AddAccountRule.startDate,
        startDateRules: AddAccountRule.startDateRules,
        endDate: AddAccountRule.endDate,
        endDateRules: AddAccountRule.endDateRules,
        status: status.options[status.selectedIndex].text,
        type: type.options[type.selectedIndex].text,
        orderNo: AddAccountRule.orderNo,
        details: AddAccountRule.details,
        statusID: AddAccountRule.StatusID,
        typeID: AddAccountRule.TypeID,
        objective: AddAccountRule.objective,
      };
      setOnProcessHide(true);
      let res: any = await UpdateAccountRuleByID(input, props.Token);
      setOnProcessHide(false);
      if (res.data.success) {
        if (res.data !== undefined) {
          let Message = "Rule updated SuccessFully";
          if (res.data.statusCode === 200) {
            ToasterSuccess(Message);
            if (props.getAccountRulesDate) {
              props.getAccountRulesDate();
            }
            props.onHide(true);
          }
        } else {
          ToasterError(res.message);
        }
      }
    }
  }

  return (
    <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      className="quickAdd-popup"
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Account Rules</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading ? (
          <>
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Form.Group>
                    <h6> {AddAccountRule.accountRulesName}</h6>
                  </Form.Group>
                </div>
                <div className="col-md-9 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Start Date Rules</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={AddAccountRule.startDateRules}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["startDateRules"]: event.target.value,
                        })
                      }
                    >
                      <option value="">Select Start Date Rule</option>
                      {AccountRulesDate.length > 0 &&
                        AccountRulesDate.map((item: any, index) => (
                          <option key={index} value={item.Term}>
                            {item.Term}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Start Date</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={AddAccountRule.startDate}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["startDate"]: event.target.value,
                        })
                      }
                    >
                      <option value="">Select Start Date</option>
                      {RuleDate.length > 0 &&
                        RuleDate.map((item: any, index) => (
                          <option key={index} value={item.Term}>
                            {item.Term}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-9 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">End Date Rules</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={AddAccountRule.endDateRules}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["endDateRules"]: event.target.value,
                        })
                      }
                    >
                      <option value="">Select End Date Rule</option>
                      {AccountRulesDate.length > 0 &&
                        AccountRulesDate.map((item: any, index) => (
                          <option key={index} value={item.Term}>
                            {item.Term}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-3 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">End Date</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={AddAccountRule.endDate}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["endDate"]: event.target.value,
                        })
                      }
                    >
                      <option value="">Select End Date</option>
                      {RuleDate.length > 0 &&
                        RuleDate.map((item: any, index) => (
                          <option key={index} value={item.Term}>
                            {item.Term}
                          </option>
                        ))}
                    </Form.Select>
                  </Form.Group>
                </div>

                <div className="col-md-4 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Order No.*</Form.Label>
                    <Form.Control
                      type="number"
                      onWheel={(event: any) => {
                        event.target.blur();
                      }}
                      onKeyDown={(e: any) => {
                        if (e.keyCode == "38" || e.keyCode == "40") {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                      placeholder="Order no."
                      value={AddAccountRule.orderNo}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["orderNo"]: event.target.value,
                        })
                      }
                      isInvalid={AddAccountRule.errors.orderNo}
                    />
                    {AddAccountRule.errors.orderNo && (
                      <Form.Control.Feedback type="invalid">
                        {AddAccountRule.errors.orderNo}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>

                <div className="col-md-4 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Status*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={AddAccountRule.StatusID}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["StatusID"]: event.target.value,
                        })
                      }
                      id="statusDropdown"
                      className={`form-control   ${AddAccountRule.errors.StatusID && "is-invalid"}`}
                    >
                      <option value="">Select Status</option>
                      {Status.length > 0 &&
                        Status.map((item: any, index) => (
                          <option key={index} value={item.TermID}>
                            {item.TermCode}
                          </option>
                        ))}
                    </Form.Select>
                    {AddAccountRule.errors.StatusID && (
                      <Form.Control.Feedback type="invalid">
                        {AddAccountRule.errors.StatusID}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-4 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Type*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      value={AddAccountRule.TypeID}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["TypeID"]: event.target.value,
                        })
                      }
                      id="typeDropdown"
                      className={`form-control ${AddAccountRule.errors.TypeID && "is-invalid"}`}
                    >
                      <option value="">Select Type</option>
                      {ActivityType.length > 0 &&
                        ActivityType.map((item: any, index) => (
                          <option key={index} value={item.TermID}>
                            {item.Term}
                          </option>
                        ))}
                    </Form.Select>
                    {AddAccountRule.errors.TypeID && (
                      <Form.Control.Feedback type="invalid">
                        {AddAccountRule.errors.TypeID}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-12 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Objective*</Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={360}
                      placeholder="Objective"
                      value={AddAccountRule.objective}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["objective"]: event.target.value,
                        })
                      }
                      isInvalid={AddAccountRule.errors.objective}
                    />
                    {AddAccountRule.errors.objective && (
                      <Form.Control.Feedback type="invalid">
                        {AddAccountRule.errors.objective}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </div>
                <div className="col-md-12 mb-3">
                  <Form.Group>
                    <Form.Label className="ps-0 pe-0 mx-0 mb-1">Details</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter Details..."
                      value={AddAccountRule.details}
                      onChange={(event) =>
                        setAddAccountRule({
                          ...AddAccountRule,
                          ["details"]: event.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </>
        ) : (
          <>
            <PageLoader />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          className="savebtnclient"
          type="submit"
          onClick={SaveRule}
          disabled={OnProcessHide === true ? true : false}
        >
          <i className="fa-solid fa-floppy-disk me-2"></i>Save
        </Button>
      </Modal.Footer>
    </Modal>
    {hotelChangeLoading ? (
          <div className="calender-loader-main">
            <PageLoader />
          </div>
        ) : (
          ""
        )}
        </>
  );
}

export default AddAccountRuleModal;
