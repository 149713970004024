import moment from "moment";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import GoalReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import GroupGridReportModal from "../../Model/Reports/GroupGridReportModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
function GroupGridTable() {
  let GroupGrid_page_rights = CommonService.getPageRight("GroupGrid");

  const [ReportData, setReportData]: any = useState({
    propertyID: "",
    status: "",
    startDate: "",
    endDate: "",
    userIDs: "",
    errors: {
      propertyID: "",
      startDate: null,
      endDate: "",
      status: "",
      userIDs: "",
      ValidationRules: [
        {
          FieldName: "propertyID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "userIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "startDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "endDate",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "status",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });
  const [selectedUsers, setSelectedUsers] = useState([]);

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const [UserDropdownData, setUserDropdownData]: any = useState([]);
  const [selHotels, setSelHotels]: any = useState([]);
  const [AllDropdown, setAllDropdown]: any = useState([]);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  let [PropertiesListData, setPropertiesListData]: any = React.useState({
    IsData: true,
    data: [],
  });
  const [showReport, setShowReport]: any = useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  let ArrayPropertyDropdown: any = [];

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("groupGridReportFilter"))) {
      HandleRemoveOtherFilters("groupGridReportFilter");
      HandleFilters("groupGridReportFilter", {
        pagePath: "/GroupGridTable",
        pageName: "groupGridReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  React.useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);
        setReportData({
          ...ReportData,
          ["propertyID"]: SelectedPropertyID,
        });
        userByProperty(SelectedPropertyID);
      }
    }

    getAllDropdown();
    getHotelsList();
    getPropertyDropdown();
  }, [SelectedPropertyName]);

  async function userByProperty(data) {
    let input = {
      clientID: clientID,
      propertyIDs: data.toString(),
    };
    sethotelChangeLoading(true);
    const res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          if (
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1 !== ""
          ) {
            setUserDropdownData(res.data.data.dataList1);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  }

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      }
    }
  }

  let HotelsDropdown: any = [{ label: "All Hotels", value: "" }];
  let UsersDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });
  UserDropdownData !== undefined &&
    UserDropdownData !== null &&
    UserDropdownData.map((item: any) => {
      UsersDropdown.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  let optionStatus: any = [];

  AllDropdown !== undefined &&
    AllDropdown !== null &&
    AllDropdown.map((item: any) => {
      optionStatus.push({
        label: item.Term,
        value: item.TermCode,
      });
    });

  async function getAllDropdown() {
    let inputs = {
      category: "GRP_Opp",
      clientID: clientID,
    };
    const res: any = await getProjectTermByCategory(inputs, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          setAllDropdown(res.data.data.dataList1);
        }
      }
    }
  }

  function getPropertyDropdown() {
    if (
      SelectedPropertyID !== "" &&
      SelectedPropertyID !== undefined &&
      SelectedPropertyID !== null
    ) {
      ArrayPropertyDropdown.push(SelectedPropertyID.length !== 0 && SelectedPropertyID.split(","));
    }
  }
  async function SelectedPropertyData(data: any) {
    let temp: any = [];
    if (data.length) {
      data.map((item: any) => {
        temp.push(item.value);
      });
      // setReportData({
      //   ...ReportData,
      //   ["propertyID"]: temp.toString(),
      // });

      let input = {
        clientID: clientID,
        propertyIDs: temp.toString(),
      };
      sethotelChangeLoading(true);
      const res: any = await getEmailAssignToDropdownList(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            if (
              res.data.data.dataList1 !== undefined &&
              res.data.data.dataList1 !== null &&
              res.data.data.dataList1 !== ""
            ) {
              setUserDropdownData(res.data.data.dataList1);

              // Logic for selected user by property.
              if (selectedUsers.length) {
                let resultedUsers: any = [];
                selectedUsers.forEach((element: any) => {
                  if (res.data.data.dataList1.length) {
                    res.data.data.dataList1.forEach((data: any) => {
                      if (data.UserID === element.value) {
                        resultedUsers.push({ label: element.label, value: element.value });
                      }
                    });
                  }
                });
                setSelectedUsers(resultedUsers);
                let fiteredUsers: any = [];
                resultedUsers.forEach((obj) => {
                  fiteredUsers.push(obj.value);
                });

                setReportData({
                  ...ReportData,
                  propertyID: temp.toString(),
                  userIDs: fiteredUsers.toString(),
                });
              } else {
                setReportData({
                  ...ReportData,
                  propertyID: temp.toString(),
                });
              }
            }
          }
        }
      }
    } else {
      setReportData({
        ...ReportData,
        ["propertyID"]: "",
        ["userIDs"]: "",
      });
      setSelectedUsers([]);
      setUserDropdownData([]);
    }
    sethotelChangeLoading(false);
  }

  function selectedUser(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].value);
      }
      setReportData({
        ...ReportData,
        ["userIDs"]: abc.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["userIDs"]: "",
      });
    }
  }

  function selectedStatus(event: any) {
    let abc: any = [];
    if (event.length) {
      for (let index = 0; index < event.length; index++) {
        abc.push(event[index].value);
      }

      setReportData({
        ...ReportData,
        ["status"]: abc.toString(),
      });
    } else {
      setReportData({
        ...ReportData,
        ["status"]: "",
      });
    }
  }

  function SaveGenerateReport() {
    let obj = fnCheckValidationOfObject(ReportData);
    setReportData({
      ...obj.obj,
    });
    if (obj.isValid) {
      setShowReport(true);
    }
  }

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setReportData({
        ...ReportData,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setReportData({
        ...ReportData,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>

          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {GroupGrid_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col lg={12} className="mb-4">
                          <div
                            className={`${ReportData.errors.propertyID && "red-border-masking"}`}
                          >
                            <FloatingLabel label="">
                              <Select
                                placeholder="Select Property"
                                options={HotelsDropdown}
                                isMulti
                                value={selHotels}
                                defaultValue={HotelsDropdown.filter(
                                  (itm) =>
                                    ReportData.propertyID &&
                                    itm.value.includes(ReportData.propertyID)
                                )}
                                // onChange={(event: any) => {
                                //   setSelHotels(event);
                                //   SelectedPropertyData(event);
                                // }}
                                onChange={(event: any) => {
                                  if (event.length) {
                                    let data = event.filter((itm) => itm.label === "All Hotels");
                                    HotelsDropdown.splice(data, 1);
                                    if (data.length) {
                                      SelectedPropertyData(HotelsDropdown);
                                      setSelHotels(HotelsDropdown);
                                    } else {
                                      setSelHotels(event);
                                      SelectedPropertyData(event);
                                    }
                                  } else {
                                    setSelHotels([]);
                                    SelectedPropertyData([]);
                                  }
                                }}
                              ></Select>
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.propertyID && ReportData.errors.propertyID}{" "}
                          </small>
                        </Col>
                        <Col lg={12} className="mb-4">
                          <div className={`${ReportData.errors.userIDs && "red-border-masking"}`}>
                            <FloatingLabel controlId="selectUser" label="">
                              <Select
                                placeholder="Select User"
                                options={UsersDropdown}
                                isMulti
                                value={selectedUsers}
                                onChange={(e: any) => {
                                  selectedUser(e);
                                  setSelectedUsers(e);
                                }}
                              ></Select>
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.userIDs && ReportData.errors.userIDs}{" "}
                          </small>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <div className={`${ReportData.errors.status && "red-border-masking"}`}>
                            <FloatingLabel controlId="selectUser" label="">
                              <Select
                                placeholder="Select Status"
                                options={optionStatus}
                                onChange={(e: any) => selectedStatus(e)}
                                isMulti
                              ></Select>
                            </FloatingLabel>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.status && ReportData.errors.status}{" "}
                          </small>
                        </Col>

                        <Col lg={6} className="mb-4">
                          <div className={`${ReportData.errors.startDate && "red-border-masking"}`}>
                            <div className="form-floating">
                              <DatePicker
                                className="form-control form-control-date datepicker-react"
                                placeholderText="Select Start & End Date"
                                minDate={new Date("2010/01/01")}
                                maxDate={new Date("9999/12/31")}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {
                                  handleDateRange(update);
                                  setDateRange(update);
                                }}
                              />
                            </div>
                          </div>
                          <small style={{ color: "#dc3545" }}>
                            {ReportData.errors.startDate && ReportData.errors.startDate}{" "}
                          </small>{" "}
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <Button
                        variant="primary ms-0"
                        onClick={() => {
                          if (GroupGrid_page_rights.is_view) {
                            SaveGenerateReport();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        <span className="me-2">
                          <img src={GoalReport} alt="" />
                        </span>
                        Generate Report
                      </Button>
                    </Col>
                  </Row>

                  {showReport === true && (
                    <GroupGridReportModal
                      ReportInput={ReportData}
                      show={showReport}
                      onHide={() => setShowReport(false)}
                      showReport={showReport}
                      setShowReport={setShowReport}
                    />
                  )}
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default GroupGridTable;
