import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./Main.css";
import store from "./Redux";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Views/Microsoft/components/authConfig";
import { GoogleOAuthProvider } from "@react-oauth/google";

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="567599086904-qr6brkc44b3679ptuekjs47cc4veoo4p.apps.googleusercontent.com">
      {/* <MsalProvider instance={msalInstance}> */}
      <React.StrictMode>
        <App />
      </React.StrictMode>
      {/* </MsalProvider> */}
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
