import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LoginLogo } from "../../../src/Asset/Images/LoginPage/login-logo.svg";
import AuthLogin from "./AuthLogin";
import { ClientSelection } from "./ClientSelection";
import ForgotPass from "./ForgotPass";
import TwoStepAuthentication from "./TwoStepAuthentication";

function AuthLayout() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const is_logged = dataSelector.is_logged;
  const un = useNavigate();

  return (
    <div className="login-page">
      <div className="container-fluid p-0">
        <Row className="m-0">
          <Col lg="8" className="p-0">
            <div className="left-col d-flex h-100 w-100">
              <div className="login-logo">
                <span>
                  <LoginLogo />
                </span>
              </div>
            </div>
          </Col>
          <Col lg="4" className="p-0">
            <div className="right-col d-flex h-100 w-100"></div>
          </Col>
          {window.location.pathname === "/" ? (
            <AuthLogin />
          ) : window.location.pathname === "/clientSelection" ? (
            <ClientSelection />
          ) : window.location.pathname === "/forgotPassword" ? (
            <ForgotPass />
          ) : window.location.pathname === "/twoStepAuthentication" ? (
            <TwoStepAuthentication />
          ) : (
            <AuthLogin />
          )}
        </Row>
      </div>
    </div>
  );
}

export default AuthLayout;
