import React, { useState } from "react";

const ReadMoreButton = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded]: any = useState(false);
  let truncatedText: any = "";
  if (text !== undefined && text !== null && text !== "") {
    truncatedText = isExpanded ? text : text?.slice(0, maxLength);
  }

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className="mt-1" dangerouslySetInnerHTML={{ __html: truncatedText }}></div>
      {/* <p>{truncatedText}</p> */}
      {text.length > maxLength && (
        <button style={{ color: "#4f52f4" }} onClick={toggleExpand}>
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default ReadMoreButton;
