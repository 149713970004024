import { useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoginButtonIcon from "../../../src/Asset/Images/Icons/LoginButtonIcon.svg";
import LoginUserIcon from "../../../src/Asset/Images/Icons/LoginUserIcon.svg";
import { ForgatePassword } from "../../Services/LoginServices";
import {
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";

function ForgotPass() {
  const [IsloginClick, setIsloginClick] = useState(false);

  const [input, setInput] = useState({
    email: "",
    errors: {
      email: "",
      ValidationRules: [
        {
          FieldName: "email",
          ValidationType: "email",
          ValidationMessage: "Enter valid Email address.",
        },
        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  async function sendLink(e) {
    e.preventDefault();

    let obj = fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });

    if (obj.isValid) {
      setIsloginClick(true);
      const res: any = await ForgatePassword(input);
      if (res.data !== null && res.data !== undefined) {
        if (res.data.status === 1) {
          ToasterSuccess("Link Send Successfully");
        } else if (res.data.status === 0) {
          ToasterError(res.data.message);
        } else if (res.data.statusCode === 400) {
          ToasterError("User Name Email InValid");
        } else {
          ToasterError("Something Went Wrong");
        }
      }
      setIsloginClick(false);
    }
  }

  return (
    <div className="login-section">
      <div className="login-title">Forgot Password</div>
      <p className="login-description">
        Enter your &ldquo; Inntelligent CRM &rdquo; registered email.
      </p>
      <Form>
        <div className={`${input.errors.email && "red-border-masking"}`}>
          <InputGroup className="">
            <InputGroup.Text id="basic-addon1">
              <span>
                <img src={LoginUserIcon} alt="" />
              </span>
            </InputGroup.Text>
            <FormControl
              type="email"
              autoComplete="new-password"
              placeholder="Enter Registered E-mail"
              aria-label="Enter Registered E-mail"
              aria-describedby="basic-addon1"
              onChange={(e) =>
                setInput({
                  ...input,
                  ["email"]: e.target.value,
                })
              }
            />
          </InputGroup>
        </div>
        <small style={{ color: "#dc3545" }}> {input.errors.email && input.errors.email}</small>
        <Form.Group className="form-button d-flex ">
          {IsloginClick === false ? (
            <Link onClick={(e) => sendLink(e)} className="btn btn-primary" to="/home">
              {/* <span className="me-3">
                <img src={LoginButtonIcon} alt="" />
              </span> */}
              Verify
            </Link>
          ) : (
            <Link className="btn btn-primary" to="/home">
              {/* <span className="me-3">
                <i className="fa-duotone fa-spinner fa-spin"></i>
              </span> */}
              ...Verifying
            </Link>
          )}

          <Link to="/" style={{ marginLeft: "10px" }} className="btn btn-primary" type="button">
            {/* <span className="me-3"><img src={LoginButtonIcon} alt="" /></span> */}
            Back
          </Link>
        </Form.Group>
      </Form>
    </div>
  );
}

export default ForgotPass;
