import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  AppConfigData,
  CommonService,
  ToasterError,
  ToasterSuccess,
  getDateInFormat,
} from "../../Services/CommonServices";
import { useSelector } from "react-redux";
import { ExportContactFileOfInvalidData } from "../../Services/ImportServises/ImportServises";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import TableView from "../../Component/Table/Index";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import BlueLabel from "../../Component/Labels/BlueLabel/Index";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function ImportContactModal(props: any) {
  let page_rights = CommonService.getPageRight("Lead");
  const navigate = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  // const SuccessfullImportdHeader contactsTableColumns = useMemo(
  //   () => [
  //     {
  //       Header: "Company/ Group Name",
  //       accessor: "companyGroupName",
  //       className: "w-243",
  //     },
  //     {
  //       Header: "Name",
  //       accessor: "name",
  //     },
  //     {
  //       Header: "Email",
  //       accessor: "email",
  //     },
  //     {
  //       Header: "Status",
  //       accessor: "status",
  //     },
  //     {
  //       Header: "Source",
  //       accessor: "source",
  //     },
  //     {
  //       Header: "Mobile No.",
  //       accessor: "mobileNo",
  //     },
  //     {
  //       Header: "Telephone No.",
  //       accessor: "telephoneNo",
  //     },
  //     {
  //       Header: "Date Created",
  //       accessor: "dateCreated",
  //     },

  //     {
  //       Header: "Priority",
  //       accessor: "priority",
  //     },
  //   ],
  //   []
  // );

  const SuccessfullImportdHeader = React.useMemo(
    () => [
      {
        Header: "Contact Name",
        accessor: "contactName",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Hotel Name",
        accessor: "hotelName",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
      },
      {
        Header: "Notes",
        accessor: "note",
      },
    ],
    []
  );

  let tempSuccessfullImportedData: any = [];
  const [SuccessfullImportedData, setSuccessfullImportedData] = useState([]);

  SuccessfullImportedData &&
    SuccessfullImportedData.map((item: any) => {
      tempSuccessfullImportedData.push({
        // controlRender: {
        //   email: <a href={"mailto:" + item.email}>{item.email}</a>,
        //   contactNo:
        //     item.contactMobNo && item.contactMobNo.includes(" ") ? (
        //       <a className="text-decoration-none" href={"tel:" + item.contactMobNo}>
        //         {item.contactMobNo.split(" ")[1]}
        //       </a>
        //     ) : (
        //       <a className="text-decoration-none" href={"tel:" + item.contactMobNo}>
        //         {item.contactMobNo}
        //       </a>
        //     ),
        // },
        className: {
          // action: "table-action sticky-action",
        },
        accountName: item.accountName,
        hotelName: item.hotelName,
        title: item.titleSecond,
        // prefix: item.Title,
        contactName:
          item.firstName !== undefined &&
          item.firstName !== null &&
          item.firstName !== " " &&
          item.firstName !== "null" &&
          item.firstName !== "undefined"
            ? item.firstName + " " + item.lastName
            : "",
        department: item.contactType,
        email: item.email,
        note: item.note,
        contactNo:
          item.contactMobNo && item.contactMobNo.includes(" ")
            ? item.contactMobNo.split(" ")[1]
            : item.contactMobNo,
      });
    });

  const InvalidImportdHeader = useMemo(
    () => [
      {
        Header: "Error Message",
        accessor: "ErrorMessage",
        className: "w-500",
      },
      {
        Header: "First Name",
        accessor: "FirstName",
      },
      {
        Header: "Last Name",
        accessor: "LastName",
      },
      {
        Header: "BirthDay",
        accessor: "BirthDay",
      },
      {
        Header: "Hotel Name",
        accessor: "HotelName",
      },
      {
        Header: "Account Name",
        accessor: "AccountName",
      },
      {
        Header: "Reports To",
        accessor: "ReportsTo",
      },
      {
        Header: "Title",
        accessor: "Title",
      },
      {
        Header: "Department",
        accessor: "Department",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Mobile No",
        accessor: "MobileNo",
      },
      {
        Header: "Notes",
        accessor: "Notes",
      },
    ],
    []
  );

  let tempInvalidImportedData: any = [];
  const [InvalidImportedData, setInvalidImportedData] = useState([]);

  InvalidImportedData &&
    InvalidImportedData.map((item: any) => {
      tempInvalidImportedData.push({
        controlRender: {
          // ErrorMessage: item.errorMessage,
        },
        className: {
          ErrorMessage: "text-danger w-300 whiteSpaceNowrap",
        },
        FirstName: item.firstName,
        LastName: item.lastName,
        BirthDay: getDateInFormat(item.birthday),
        HotelName: item.hotelName,
        AccountName: item.accountName,
        ReportsTo: item.reportingTo,
        Title: item.titleSecond,
        Department: item.contactType,
        Email: item.email,
        MobileNo: item.contactMobNo,
        Notes: item.note,
        ErrorMessage: item.errorMessage,
      });
    });

  useEffect(() => {
    if (
      props.ModuleData.validData !== undefined &&
      props.ModuleData.validData !== null &&
      props.ModuleData.validData.length !== 0
    ) {
      setSuccessfullImportedData(props.ModuleData.validData);
    }

    if (
      props.ModuleData.inValidData !== undefined &&
      props.ModuleData.inValidData !== null &&
      props.ModuleData.inValidData.length !== 0
    ) {
      setInvalidImportedData(props.ModuleData.inValidData);
    }
  }, [props && props.ModuleData]);

  async function ExportContactErrorData() {
    let inputArrayOnj: any = [];

    InvalidImportedData &&
      InvalidImportedData.map((item: any) => {
        inputArrayOnj.push({
          firstName: item.firstName,
          lastName: item.lastName,
          birthDay: item.birthday ? moment(item.birthday).format("YYYY-MM-DD") : "",
          hotelName: item.hotelName,
          accountName: item.accountName,
          reportsTo: item.reportingTo,
          title: item.titleSecond,
          department: item.contactType,
          email: item.email,
          mobileNo: item.contactMobNo,
          notes: item.note,
        });
      });

    let input: any = {
      inValidData: inputArrayOnj,
    };

    let res: any = await ExportContactFileOfInvalidData(input, Token);

    if (res.data !== undefined && res.data !== null) {
      let Message = `Contacts Export SuccessFully`;
      if (res.data.statusCode === 200) {
        window.open(AppConfigData.APIBasePath + res.data.data);
        ToasterSuccess(Message);
      } else {
        ToasterError(res.data.message);
      }
    } else {
      ToasterError(res.message);
    }
  }

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center justify-content-between w-100"
        >
          <div> Import (Contacts) </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col xs={12}>
            <h6> Imported Data</h6>
            <div>
              <TableView
                columnsData={SuccessfullImportdHeader}
                tableData={tempSuccessfullImportedData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-5">
            <Row>
              <Col md={11}>
                <h6> Invalid Data</h6>
              </Col>
              <Col md={1}>
                <Button className="mb-2" variant="primary" onClick={() => ExportContactErrorData()}>
                  <span className="me-2">
                    <img src={WhiteSaveIcon} alt="" />
                  </span>
                  Export
                </Button>
              </Col>
            </Row>

            <div>
              <TableView
                columnsData={InvalidImportdHeader}
                tableData={tempInvalidImportedData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
