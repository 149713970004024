/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import GoalReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import DashboardWeeklyReportModal from "../../Model/Reports/DashboardWeeklyReportModal";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
} from "../../Services/CommonServices";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
import { DashboardWeeklyReportSingleFileExportPDF } from "../../Services/ReportServices";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";

const DashboardWeeklyReport = () => {
  let DashboardWeeklyReport_page_rights = CommonService.getPageRight("DashboardWeeklyReport");

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [showReport, setShowReport]: any = useState(false);

  const [loading, setLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers]: any = useState([]);
  const [selHotels, setSelHotels]: any = useState([]);
  const [Downloading, setDownloading] = useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );

  let iniInputData = {
    propertyIDs: "",
    userIDs: "",
  };

  const [inputs, setInputs]: any = useState(iniInputData);
  const [inputsEr, setInputsEr] = useState(iniInputData);

  const [ReportInput, setReportInput]: any = useState({});

  const fetchUsersList = async (data_ids) => {
    let input = {
      clientID: clientID,
      propertyIDs: data_ids,
    };
    let res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        let userListDataToAppend: any = [];
        res.data.data.dataList1.forEach((res_data) => {
          userListDataToAppend = [
            ...userListDataToAppend,
            {
              label: res_data.UserName,
              value: res_data.UserID,
            },
          ];
        });
        setUsersList(userListDataToAppend);
      } else {
        setUsersList([]);
      }
    } else {
      setUsersList([]);
    }
    sethotelChangeLoading(false);
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("dashboardWeeklyReportFilter"))) {
      HandleRemoveOtherFilters("dashboardWeeklyReportFilter");
      HandleFilters("dashboardWeeklyReportFilter", {
        pagePath: "/DashboardWeeklyReport",
        pageName: "dashboardWeeklyReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);
        setInputs({
          ...inputs,
          propertyIDs: SelectedPropertyID,
        });
        fetchUsersList(SelectedPropertyID);
      }
    }

    getHotelsList();
  }, [SelectedPropertyName]);

  let HotelsDropdown: any = [];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  const validateForm = () => {
    let is_validate = true;
    let error_json: any = {};
    Object.keys(inputsEr).forEach((er_key) => {
      error_json = {
        ...error_json,
        [er_key]: "",
      };
    });
    Object.keys(error_json).forEach((er_key) => {
      if (inputs[er_key] === "") {
        is_validate = false;
        error_json = {
          ...error_json,
          [er_key]: "This field is required.",
        };
      }
    });
    setInputsEr(error_json);
    return is_validate;
  };

  const handleGenerateReport = async () => {
    if (validateForm()) {
      let inputToAppend = inputs;

      setReportInput(inputToAppend);
      setShowReport(true);
    }
  };

  async function SelectedPropertyData(data: any) {
    sethotelChangeLoading(true);
    let tempID: any = data.value;

    if (data) {
      setInputs({
        ...inputs,
        propertyIDs: tempID,
        userIDs: "",
      });
      fetchUsersList(tempID);
    } else {
      setInputs({
        ...inputs,
        propertyIDs: "",
        userIDs: "",
      });
      setUsersList([]);
      setSelectedUsers([]);
    }
    setSelectedUsers([]);
  }

  async function exportPDF() {
    if (validateForm()) {
      let inputData: any = {
        propertyID: inputs.propertyIDs,
        userIDs: inputs.userIDs,
      };

      setDownloading(true);
      let res: any = await DashboardWeeklyReportSingleFileExportPDF(inputData, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setDownloading(false);
          } else {
            ToasterError(res.data.message);
            setDownloading(false);
          }
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {DashboardWeeklyReport_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={12} xl={12} className="mb-4">
                      <div className={`${inputsEr.propertyIDs && "red-border-masking"}`}>
                        <FloatingLabel label="">
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                            placeholder="Select Property"
                            options={HotelsDropdown}
                            // value={selHotels}
                            value={HotelsDropdown.filter(
                              (itm) => inputs.propertyIDs && itm.value.includes(inputs.propertyIDs)
                            )}
                            onChange={(event: any) => {
                              setSelHotels(event);
                              SelectedPropertyData(event);
                            }}
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputsEr.propertyIDs && inputsEr.propertyIDs}{" "}
                      </small>
                    </Col>
                    <Col lg={12} xl={12} className="mb-3">
                      <div className={`${inputsEr.userIDs && "red-border-masking"}`}>
                        <FloatingLabel controlId="selectUser" label="">
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                            value={selectedUsers}
                            onChange={(itm: any) => {
                              setSelectedUsers(itm);
                              let userIds = "";
                              itm.forEach((id_user: any) => {
                                userIds = userIds.length
                                  ? userIds + "," + id_user.value
                                  : id_user.value;
                              });
                              setInputs({
                                ...inputs,
                                userIDs: userIds,
                              });
                            }}
                            options={usersList}
                            name="colors"
                            placeholder="Select Users"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isDisabled={false}
                            isMulti={true}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputsEr.userIDs && inputsEr.userIDs}{" "}
                      </small>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="">
                        <Modal.Footer className="border-0 justify-content-start">
                          {!loading ? (
                            <>
                              <Button
                                type="button"
                                onClick={(e) => {
                                  if (DashboardWeeklyReport_page_rights.is_view) {
                                    e.preventDefault();
                                    handleGenerateReport();
                                  } else {
                                    CommonService.unauthorizedAction();
                                  }
                                }}
                                variant="primary ms-0"
                              >
                                <span className="me-2">
                                  <img src={GoalReport} alt="" />
                                </span>
                                Generate Report
                              </Button>

                              <Button
                                variant="primary ms-0"
                                disabled={Downloading ? true : false}
                                onClick={(e) => {
                                  if (DashboardWeeklyReport_page_rights.is_export) {
                                    e.preventDefault();
                                    exportPDF();
                                  } else {
                                    CommonService.unauthorizedAction();
                                  }
                                }}
                              >
                                <span className="me-2">
                                  <img src={PdfIcon} alt="" />
                                </span>
                                Export PDF
                              </Button>
                            </>
                          ) : (
                            <Button type="button" variant="primary">
                              <Spinner
                                size="sm"
                                className="me-2"
                                animation="border"
                                variant="light"
                              />
                              Loading
                            </Button>
                          )}
                        </Modal.Footer>
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {showReport === true && (
          <DashboardWeeklyReportModal
            inputs={inputs}
            selectedHotel={selHotels}
            selectedUsers={selectedUsers}
            show={showReport}
            onHide={() => setShowReport(false)}
            showReport={showReport}
            setShowReport={setShowReport}
          />
        )}
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default DashboardWeeklyReport;
