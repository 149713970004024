import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PageLoader from "../../../Component/Loader/PageLoader";
import { CommonService, ToasterError, ToasterSuccess } from "../../../Services/CommonServices";
import {
    GetSalesRevenueSources,
    SalesRevenueSourcesSave,
} from "../../../Services/Setup&Config/HotelService";

export default function SalesChecklist({ property_id }) {
  let page_rights_Hotel = CommonService.getPageRight("Hotels");

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [submitLoading, setSubmitLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkListFormDetails, setCheckListFormDetails] = useState({
    hotelPlannerLeads: "",
    corporateGroupLeads: "",
    bookingofReActiveGroup: "",
    meetingSpaceBookings: "",
    brandLeads: "",
    rfpResponse: "",
    businessCaseBuilding: "",
    governmentTravelSources: "",
    stateTravelSources: "",
    proActiveSalesEfforts: "",
    leadGeneration: "",
    compSetResearch: "",
    cVentLeads: "",
    growthofExistingAccounts: "",
    parkingLotDriveLeads: "",
    currentGuestQualifications: "",
    topAccountGrowth: "",
    declinedRFPQualification: "",
    acceptedRFPGrowth: "",
    bidClerkResearchandTarget: "",
    eventPlanningGrandOpening: "",
    emailMarketingCampaigns: "",
    frontDeskSalesTraining: "",
    cvbLeads: "",
    hT360ResearchandGrowth: "",
    eventResearchandGrowth: "",
    propertyID: property_id,
  });

  const handleSubmit = async () => {
    let data = {
      ...checkListFormDetails,
    };
    setSubmitLoading(true);
    let res: any = await SalesRevenueSourcesSave(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        ToasterSuccess(res.data.message);
        setSubmitLoading(false);
      } else {
        ToasterError("Something Went Wrong!!!");
        setSubmitLoading(false);
      }
    }
  };

  const fetchCheckListDetails = async () => {
    let data = {
      keyID: property_id,
    };

    let res: any = await GetSalesRevenueSources(data, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            setCheckListFormDetails({
              hotelPlannerLeads: res.data.data.dataList1[0].HotelPlannerLeads
                ? res.data.data.dataList1[0].HotelPlannerLeads
                : "",
              corporateGroupLeads: res.data.data.dataList1[0].CorporateGroupLeads
                ? res.data.data.dataList1[0].CorporateGroupLeads
                : "",
              bookingofReActiveGroup: res.data.data.dataList1[0].BookingofReActiveGroup
                ? res.data.data.dataList1[0].BookingofReActiveGroup
                : "",
              meetingSpaceBookings: res.data.data.dataList1[0].MeetingSpaceBookings
                ? res.data.data.dataList1[0].MeetingSpaceBookings
                : "",
              brandLeads: res.data.data.dataList1[0].BrandLeads
                ? res.data.data.dataList1[0].BrandLeads
                : "",
              rfpResponse: res.data.data.dataList1[0].RFPResponse
                ? res.data.data.dataList1[0].RFPResponse
                : "",
              businessCaseBuilding: res.data.data.dataList1[0].BusinessCaseBuilding
                ? res.data.data.dataList1[0].BusinessCaseBuilding
                : "",
              governmentTravelSources: res.data.data.dataList1[0].GovernmentTravelSources
                ? res.data.data.dataList1[0].GovernmentTravelSources
                : "",
              stateTravelSources: res.data.data.dataList1[0].StateTravelSources
                ? res.data.data.dataList1[0].StateTravelSources
                : "",
              proActiveSalesEfforts: res.data.data.dataList1[0].ProActiveSalesEfforts
                ? res.data.data.dataList1[0].ProActiveSalesEfforts
                : "",
              leadGeneration: res.data.data.dataList1[0].LEADGeneration
                ? res.data.data.dataList1[0].LEADGeneration
                : "",
              compSetResearch: res.data.data.dataList1[0].CompSetResearch
                ? res.data.data.dataList1[0].CompSetResearch
                : "",
              cVentLeads: res.data.data.dataList1[0].CVentLeads
                ? res.data.data.dataList1[0].CVentLeads
                : "",
              growthofExistingAccounts: res.data.data.dataList1[0].GrowthofExistingAccounts
                ? res.data.data.dataList1[0].GrowthofExistingAccounts
                : "",
              parkingLotDriveLeads: res.data.data.dataList1[0].ParkingLotDriveLeads
                ? res.data.data.dataList1[0].ParkingLotDriveLeads
                : "",
              currentGuestQualifications: res.data.data.dataList1[0].CurrentGuestQualifications
                ? res.data.data.dataList1[0].CurrentGuestQualifications
                : "",
              topAccountGrowth: res.data.data.dataList1[0].TopAccountGrowth
                ? res.data.data.dataList1[0].TopAccountGrowth
                : "",
              declinedRFPQualification: res.data.data.dataList1[0].DeclinedRFPQualification
                ? res.data.data.dataList1[0].DeclinedRFPQualification
                : "",
              acceptedRFPGrowth: res.data.data.dataList1[0].AcceptedRFPGrowth
                ? res.data.data.dataList1[0].AcceptedRFPGrowth
                : "",
              bidClerkResearchandTarget: res.data.data.dataList1[0].BidClerkResearchandTarget
                ? res.data.data.dataList1[0].BidClerkResearchandTarget
                : "",
              eventPlanningGrandOpening: res.data.data.dataList1[0].EventPlanningGrandOpening
                ? res.data.data.dataList1[0].EventPlanningGrandOpening
                : "",
              emailMarketingCampaigns: res.data.data.dataList1[0].EmailMarketingCampaigns
                ? res.data.data.dataList1[0].EmailMarketingCampaigns
                : "",
              frontDeskSalesTraining: res.data.data.dataList1[0].FrontDeskSalesTraining
                ? res.data.data.dataList1[0].FrontDeskSalesTraining
                : "",
              cvbLeads: res.data.data.dataList1[0].CVBLeads
                ? res.data.data.dataList1[0].CVBLeads
                : "",
              hT360ResearchandGrowth: res.data.data.dataList1[0].HT360ResearchandGrowth
                ? res.data.data.dataList1[0].HT360ResearchandGrowth
                : "",
              eventResearchandGrowth: res.data.data.dataList1[0].EventResearchandGrowth
                ? res.data.data.dataList1[0].EventResearchandGrowth
                : "",
              propertyID: property_id,
            });
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchCheckListDetails();
    }
  }, []);

  return (
    <>
      <div className="information-division">
        <Form>
          <h3 className="inner-header m-0">Sales Revenue Sources</h3>
          <div className="tab-inner-box position-relative">
            {!loading ? (
              <Row className="mb-2">
                <Col xs={6}>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Hotel Planner Leads">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.hotelPlannerLeads}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              hotelPlannerLeads: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Hotel Planner Leads"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Corporate Group Leads">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.corporateGroupLeads}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              corporateGroupLeads: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Corporate Group Leads"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Booking of Re-Active Group">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.bookingofReActiveGroup}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              bookingofReActiveGroup: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Booking of Re-Active Group"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Meeting Space Bookings">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.meetingSpaceBookings}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              meetingSpaceBookings: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Meeting Space Bookings"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Brand Leads">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.brandLeads}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              brandLeads: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Brand Leads"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="RFP Response">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.rfpResponse}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              rfpResponse: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="RFP Response"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Business Case Building">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.businessCaseBuilding}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              businessCaseBuilding: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Business Case Building"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Government Travel Sources">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.governmentTravelSources}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              governmentTravelSources: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Government Travel Sources"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="State Travel Sources">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.stateTravelSources}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              stateTravelSources: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="State Travel Sources"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Pro-Active Sales Efforts">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.proActiveSalesEfforts}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              proActiveSalesEfforts: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Pro-Active Sales Efforts"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="LEAD Generation">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.leadGeneration}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              leadGeneration: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="LEAD Generation"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Comp Set Research">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.compSetResearch}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              compSetResearch: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Comp Set Research"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="CVent Leads">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.cVentLeads}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              cVentLeads: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="CVent Leads"
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Growth of Existing Accounts">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.growthofExistingAccounts}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              growthofExistingAccounts: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Growth of Existing Accounts"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Parking Lot Drive Leads">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.parkingLotDriveLeads}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              parkingLotDriveLeads: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Parking Lot Drive Leads"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Current Guest Qualifications">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.currentGuestQualifications}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              currentGuestQualifications: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Current Guest Qualifications"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Top Account Growth">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.topAccountGrowth}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              topAccountGrowth: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Top Account Growth"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Declined RFP Qualification">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.declinedRFPQualification}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              declinedRFPQualification: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Declined RFP Qualification"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Accepted RFP Growth">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.acceptedRFPGrowth}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              acceptedRFPGrowth: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Accepted RFP Growth"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="BidClerk Research and Target">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.bidClerkResearchandTarget}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              bidClerkResearchandTarget: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="BidClerk Research and Target"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Event Planning - Grand Opening"
                      >
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.eventPlanningGrandOpening}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              eventPlanningGrandOpening: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Event Planning - Grand Opening"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Email Marketing Campaigns">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.emailMarketingCampaigns}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              emailMarketingCampaigns: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Email Marketing Campaigns"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Front Desk Sales Training">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.frontDeskSalesTraining}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              frontDeskSalesTraining: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Front Desk Sales Training"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="CVB Leads">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.cvbLeads}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              cvbLeads: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="CVB Leads"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="HT360 Research and Growth">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.hT360ResearchandGrowth}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              hT360ResearchandGrowth: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="HT360 Research and Growth"
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <FloatingLabel controlId="floatingInput" label="Event Research and Growth">
                        <Form.Control
                          type="text"
                          value={checkListFormDetails.eventResearchandGrowth}
                          onChange={(e) => {
                            setCheckListFormDetails({
                              ...checkListFormDetails,
                              eventResearchandGrowth: e.target.value ? e.target.value : "",
                            });
                          }}
                          placeholder="Event Research and Growth"
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {!submitLoading ? (
                    <Button
                      type="button"
                      onClick={() => {
                        if (page_rights_Hotel.is_create) {
                          handleSubmit();
                        } else {
                          CommonService.unauthorizedAction();
                        }
                      }}
                      className="savebtnclient"
                    >
                      <i className="fa-solid fa-floppy-disk me-2"></i>Save
                    </Button>
                  ) : (
                    <Button className="btn btn-primary justify-content-center" type="button">
                      <Spinner size="sm" animation="border" variant="light" />
                    </Button>
                  )}
                </Col>
              </Row>
            ) : (
              <PageLoader />
            )}
          </div>
        </Form>
      </div>
    </>
  );
}
