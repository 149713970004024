import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import GoalReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
} from "../../Services/CommonServices";
import { getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import {
  MainMonthlyRollupReportListExport,
  MonthlyRollupReportList,
} from "../../Services/ReportServices";
import ReportNavigations from "./ReportNavigations";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MonthlyPortfolioReportModal from "../../Model/Reports/MonthlyPortfolioReportModal";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";

function MonthlyPortfolioReport() {
  let MonthlyRollupReport_page_rights = CommonService.getPageRight("MonthlyRollupReport");

  const navigate = useNavigate();
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [hotels, setHotelsList] = useState([]);
  const [selectedHotel, setSelectedHotels] = useState([]);

  const [showReport, setShowReport] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [usersList, setUsersList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  let iniInputData = {
    propertyIDs: "",
    userIDs: "",
    startDate: "",
    endDate: "",
  };
  const [inputs, setInputs] = useState(iniInputData);
  const [inputsEr, setInputsEr] = useState(iniInputData);
  const [OnProcessHide, setOnProcessHide]: any = useState(false);

  const [activityData, setActivityData] = useState([]);
  const [oppoData, setOppoData] = useState([]);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const [selHotels, setSelHotels]: any = useState([]);
  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });

  const fetchUsersList = async (data_ids) => {
    let input = {
      clientID: clientID,
      propertyIDs: data_ids,
    };
    sethotelChangeLoading(true);
    let res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        let userListDataToAppend: any = [];
        res.data.data.dataList1.forEach((res_data) => {
          userListDataToAppend = [
            ...userListDataToAppend,
            {
              label: res_data.UserName,
              value: res_data.UserID,
            },
          ];
        });
        setUsersList(userListDataToAppend);

        // Logic for selected user by property.
        if (selectedUsers.length) {
          let resultedUsers: any = [];
          selectedUsers.forEach((element: any) => {
            if (res.data.data.dataList1.length) {
              res.data.data.dataList1.forEach((data: any) => {
                if (data.UserID === element.value) {
                  resultedUsers.push({ label: element.label, value: element.value });
                }
              });
            }
          });
          setSelectedUsers(resultedUsers);
          let fiteredUsers: any = [];
          resultedUsers.forEach((obj) => {
            fiteredUsers.push(obj.value);
          });

          setInputs({
            ...inputs,
            propertyIDs: data_ids,
            userIDs: fiteredUsers.toString(),
          });
        } else {
          setInputs({
            ...inputs,
            propertyIDs: data_ids,
            // userIDs: fiteredUsers.toString(),
          });
        }
      } else {
        setUsersList([]);
      }
    } else {
      setUsersList([]);
    }
    sethotelChangeLoading(false);
  };

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("monthlyRoleupReportFilter"))) {
      HandleRemoveOtherFilters("monthlyRoleupReportFilter");
      HandleFilters("monthlyRoleupReportFilter", {
        pagePath: "/MonthlyPortfolioReport",
        pageName: "monthlyRoleupReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setSelHotels([
          {
            value: SelectedPropertyID,
            label: SelectedPropertyName,
          },
        ]);
        setInputs({
          ...inputs,
          propertyIDs: SelectedPropertyID,
        });
        fetchUsersList(SelectedPropertyID);
      }
    }

    getHotelsList();
  }, [SelectedPropertyName]);

  let HotelsDropdown: any = [{ label: "All Hotels", value: "" }];

  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  const validateForm = () => {
    let is_validate = true;
    let error_json: any = {};
    Object.keys(inputsEr).forEach((er_key) => {
      error_json = {
        ...error_json,
        [er_key]: "",
      };
    });
    Object.keys(error_json).forEach((er_key) => {
      if (inputs[er_key] === "") {
        is_validate = false;
        error_json = {
          ...error_json,
          [er_key]: "This field is required.",
        };
      }
    });
    setInputsEr(error_json);
    return is_validate;
  };
  const handleGenerateReport = async () => {
    if (validateForm()) {
      setLoading(true);
      let res = await MonthlyRollupReportList(inputs, Token);
      if (res.data.success) {
        if (typeof res.data.data !== "undefined" && res.data.data) {
          setActivityData(res.data.data.lstActivityData);
          setOppoData(res.data.data.lstOpportunityData);
          setLoading(false);
          setShowReport(true);
        } else {
          setShowReport(true);
          setActivityData([]);
          setOppoData([]);
          setLoading(false);
        }
      } else {
        setActivityData([]);
        setOppoData([]);
        setLoading(false);
      }
    }
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setInputs({
        ...inputs,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setInputs({
        ...inputs,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  async function exportExelData(e) {
    if (validateForm()) {
      e.preventDefault();
      setOnProcessHide(true);
      const res: any = await MainMonthlyRollupReportListExport(inputs, Token);
      if (res.data.success) {
        if (res.data.statusCode === 200) {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setOnProcessHide(false);
          } else {
            ToasterError(res.data.message);
            setOnProcessHide(false);
          }
        } else {
          ToasterError("Something Went Wrong.");
          setOnProcessHide(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setOnProcessHide(false);
      }
    }
  }

  async function SelectedPropertyData(data: any) {
    let temp: any = [];
    if (data.length) {
      data.map((item: any) => {
        temp.push(item.value);
      });

      // setInputs({
      //   ...inputs,
      //   propertyIDs: temp.toString(),
      // });
      fetchUsersList(temp.toString());
    } else {
      setInputs({
        ...inputs,
        propertyIDs: "",
        userIDs: "",
      });
      setUsersList([]);
      setSelectedUsers([]);
    }
  }

  return (
    <div className="report-page">
      <Row>
        <Col xxl={3} xl={3} lg={3} md={4}>
          <ReportNavigations />
        </Col>
        <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
          {MonthlyRollupReport_page_rights.is_view ? (
            PropertiesListData.IsData === false ? (
              <div className="custom-profile-tabs-col">
                <Row>
                  <Col lg={12} xl={12} className="mb-3">
                    <div className={`${inputsEr.propertyIDs && "red-border-masking"}`}>
                      <FloatingLabel controlId="floatingSelectGrid" label="">
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={selHotels}
                          defaultValue={HotelsDropdown.filter(
                            (itm) => inputs.propertyIDs && itm.value.includes(inputs.propertyIDs)
                          )}
                          // onChange={(itm: any) => {
                          //   setSelectedHotels(itm);
                          //   setSelHotels(itm);
                          //   if (itm.length) {
                          //     let propIds = "";
                          //     itm.forEach((id_prop: any) => {
                          //       propIds = propIds.length
                          //         ? propIds + "," + id_prop.value
                          //         : id_prop.value;
                          //     });
                          //     fetchUsersList(propIds);
                          //     setInputs({
                          //       ...inputs,
                          //       propertyIDs: propIds,
                          //     });
                          //   } else {
                          //     setUsersList([]);
                          //     setSelectedUsers([]);
                          //     setInputs({
                          //       ...inputs,
                          //       propertyIDs: "",
                          //       userIDs: "",
                          //     });
                          //   }

                          // }}

                          onChange={(event: any) => {
                            if (event.length) {
                              let data = event.filter((itm) => itm.label === "All Hotels");
                              HotelsDropdown.splice(data, 1);
                              if (data.length) {
                                SelectedPropertyData(HotelsDropdown);
                                setSelHotels(HotelsDropdown);
                              } else {
                                setSelHotels(event);
                                SelectedPropertyData(event);
                              }
                            } else {
                              setSelHotels([]);
                              SelectedPropertyData([]);
                            }
                          }}
                          options={HotelsDropdown}
                          name="colors"
                          placeholder="Select Hotels"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                          isMulti={true}
                        />
                        {inputsEr.propertyIDs && (
                          <Form.Control.Feedback type="invalid">
                            {inputsEr.propertyIDs}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </div>
                    <small style={{ color: "#dc3545" }}>
                      {inputsEr.propertyIDs && inputsEr.propertyIDs}{" "}
                    </small>
                  </Col>
                  <Col lg={8} xl={8} className="mb-3">
                    <div className={`${inputsEr.userIDs && "red-border-masking"}`}>
                      <FloatingLabel controlId="selectUser" label="">
                        <Select
                          styles={{
                            menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          }}
                          value={selectedUsers}
                          onChange={(itm: any) => {
                            setSelectedUsers(itm);
                            if (itm.length) {
                              let userIds = "";
                              itm.forEach((id_user: any) => {
                                userIds = userIds.length
                                  ? userIds + "," + id_user.value
                                  : id_user.value;
                              });
                              setInputs({
                                ...inputs,
                                userIDs: userIds,
                              });
                            } else {
                              setInputs({
                                ...inputs,
                                userIDs: "",
                              });
                            }
                          }}
                          options={usersList}
                          name="colors"
                          placeholder="Select Users"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                          isMulti={true}
                        />
                        {inputsEr.userIDs && (
                          <Form.Control.Feedback type="invalid">
                            {inputsEr.userIDs}
                          </Form.Control.Feedback>
                        )}
                      </FloatingLabel>
                    </div>
                    <small style={{ color: "#dc3545" }}>
                      {inputsEr.userIDs && inputsEr.userIDs}{" "}
                    </small>
                  </Col>

                  <Col lg={4} xl={4} className="mb-4">
                    <div className={`${inputsEr.startDate && "red-border-masking"}`}>
                      <div className="form-floating">
                        <DatePicker
                          className="form-control form-control-date datepicker-react"
                          placeholderText="Select Start & End Date"
                          minDate={new Date("2010/01/01")}
                          maxDate={new Date("9999/12/31")}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            handleDateRange(update);
                            setDateRange(update);
                          }}
                        />
                      </div>
                    </div>
                    <small style={{ color: "#dc3545" }}>
                      {inputsEr.startDate && inputsEr.startDate}{" "}
                    </small>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="">
                      <Modal.Footer className="border-0 justify-content-start">
                        {!loading ? (
                          <>
                            <Button
                              type="button"
                              onClick={(e) => {
                                if (MonthlyRollupReport_page_rights.is_view) {
                                  e.preventDefault();
                                  handleGenerateReport();
                                } else {
                                  CommonService.unauthorizedAction();
                                }
                              }}
                              variant="primary ms-0"
                            >
                              <span className="me-2">
                                <img src={GoalReport} alt="" />
                              </span>
                              Generate Report
                            </Button>
                            <Button
                              variant="primary ms-0"
                              onClick={(e) => {
                                if (MonthlyRollupReport_page_rights.is_export) {
                                  e.preventDefault();
                                  exportExelData(e);
                                } else {
                                  CommonService.unauthorizedAction();
                                }
                              }}
                              disabled={OnProcessHide === true ? true : false}
                            >
                              <span className="me-2">
                                <img src={ExportIcon} alt="" />
                              </span>
                              Export Excel
                            </Button>
                          </>
                        ) : (
                          <Button type="button" variant="primary">
                            <Spinner
                              size="sm"
                              className="me-2"
                              animation="border"
                              variant="light"
                            />
                            Loading
                          </Button>
                        )}
                      </Modal.Footer>
                    </div>
                  </Col>
                </Row>

                {!loading ? (
                  <MonthlyPortfolioReportModal
                    activityData={activityData}
                    inputs={inputs}
                    loading={loading}
                    oppoData={oppoData}
                    showReport={showReport}
                    setShowReport={setShowReport}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                <PageLoader />
              </Col>
            )
          ) : (
            <Unauthorized />
          )}
        </Col>
      </Row>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default MonthlyPortfolioReport;
