// import "primereact/resources/themes/lara-light-indigo/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primeicons/primeicons.css";
// import "primereact/resources/primereact.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-pro-sidebar/dist/css/styles.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Asset/Styles/App.scss";
import "./Asset/Styles/Component.scss";
import "./Asset/Styles/custome_calender_style.css";
import DefaultLayout from "./Container/DefaultLayout";
import AuthLayout from "./Views/Authentication/AuthLayout";

function App() {
  return (
    <div>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Routes>
          <Route path="/" element={<AuthLayout />}></Route>
          <Route path="/login" element={<AuthLayout />}></Route>
          <Route path="/clientSelection" element={<AuthLayout />}></Route>
          <Route path="/forgotPassword" element={<AuthLayout />}></Route>
          <Route path="/twoStepAuthentication" element={<AuthLayout />}></Route>
          <Route path="/*" element={<DefaultLayout />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
