import axios from "axios";
import { AppConfigData } from "./CommonServices";
import { Cookies } from "react-cookie-consent";

let cancelTokensHashMap = new Map();

export const getProperty = async (ReqData, accessToken) => {
  try {
    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "propertymanagement/api/Property/PropertyList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
export const GetGlobalSearchResultList = async (ReqData: any, accessToken) => {
  try {
    if (cancelTokensHashMap.has(AppConfigData.APIBasePath + "client/api/Common/GetGlobalSearchResultList")) {
      cancelTokensHashMap.get(AppConfigData.APIBasePath + "client/api/Common/GetGlobalSearchResultList").cancel();
      cancelTokensHashMap.delete(AppConfigData.APIBasePath + "client/api/Common/GetGlobalSearchResultList");
    }

    // let cancelToken :any =null;
    let cancelToken: any = axios.CancelToken.source();
    cancelTokensHashMap.set(AppConfigData.APIBasePath + "client/api/Common/GetGlobalSearchResultList", cancelToken);


    const response = await axios({
      method: "POST",
      url: AppConfigData.APIBasePath + "client/api/Common/GetGlobalSearchResultList",
      data: ReqData,
      headers: {
        "Content-Type": "application/json",
        Authorization: "bearer " + accessToken,
      },
      cancelToken: cancelToken.token,
    });
    return response;
  } catch (error :any) {
    let data : any = null
    if(error.response.status === 401){
      data = {
        data: {
          success: false,
          StatusCode: "401",
          Message: "Session has been expired.",
        },
      };
      Cookies.remove("SystemSettingDate");
      localStorage.clear()
      window.location.pathname = '/'
    }else{
    data = {
        data: {
          success: false,
          StatusCode: "02",
          Message: "Something Wrong in API",
        },
      };
    }
    return data;
  }
};
