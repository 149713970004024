// import React from "react";
// import { useMsal } from "@azure/msal-react";
// import DropdownButton from "react-bootstrap/DropdownButton";
// import Dropdown from "react-bootstrap/esm/Dropdown";
// import { AppConfigData } from "../../../Services/CommonServices";

// /**
//  * Renders a sign-out button
//  */
// export const SignOutButton = () => {
//   const { instance } = useMsal();

//   const handleLogout = (logoutType) => {
//     if (logoutType === "popup") {
//       localStorage.removeItem("maildata1");
//       sessionStorage.clear();
//       instance.logoutPopup({
//         // postLogoutRedirectUri: "/",
//         // mainWindowRedirectUri: "/"
//         postLogoutRedirectUri: `${AppConfigData.ClientBasePath}microsoft/`, // "http://localhost:8080/microsoft/",
//         mainWindowRedirectUri: `${AppConfigData.ClientBasePath}microsoft/`, // "http://localhost:8080/microsoft/"
//       });
//     } else if (logoutType === "redirect") {
//       localStorage.removeItem("maildata1");
//       sessionStorage.clear();
//       instance.logoutRedirect({
//         // postLogoutRedirectUri: "/",
//         postLogoutRedirectUri: `${AppConfigData.ClientBasePath}microsoft/`, //"http://localhost:8080/microsoft/",
//       });
//     }
//   };
//   return (
//     <DropdownButton variant="savebtnclient" className="ml-auto " drop="left" title="Sign Out">
//       <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>
//         Sign out using Popup
//       </Dropdown.Item>
//       <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>
//         Sign out using Redirect
//       </Dropdown.Item>
//     </DropdownButton>
//   );
// };


import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/esm/Dropdown";
import { Button } from "react-bootstrap";
import { AppConfigData } from "../../../Services/CommonServices";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    // if (logoutType === "popup") {
    //   localStorage.removeItem("maildata1");
    //   sessionStorage.clear();
    //   instance.logoutPopup({
    //     postLogoutRedirectUri: "/",
    //     mainWindowRedirectUri: "/",
    //   });
    // } else if (logoutType === "redirect") {
    //   localStorage.removeItem("maildata1");
    //   sessionStorage.clear();
    //   instance.logoutRedirect({
    //     postLogoutRedirectUri: "/",
    //   });
    // }
    localStorage.removeItem("maildata1");
    sessionStorage.clear();
    instance.logoutRedirect({
      // postLogoutRedirectUri: "/",
      postLogoutRedirectUri: `${AppConfigData.ClientBasePath}microsoft/`,
      mainWindowRedirectUri: `${AppConfigData.ClientBasePath}microsoft/`,
    });
  };
  return (
    // <DropdownButton
    //   variant="outline-light"
    //   className="ml-auto"
    //   drop="left"
    //   title="Sign Out"
    // >
    //   <Dropdown.Item as="button" onClick={() => handleLogout("popup")}>
    //     Sign out using Popup
    //   </Dropdown.Item>
    //   <Dropdown.Item as="button" onClick={() => handleLogout("redirect")}>
    //     Sign out using Redirect
    //   </Dropdown.Item>
    // </DropdownButton>
    <Button onClick={() => handleLogout()}>Sign out</Button>
  );
};