import { ENUMS, INITIALSTATES } from "../../Constants";

export default function headerReducer(state = INITIALSTATES.headerInitState, action) {
  switch (action.type) {
    case ENUMS.actions.UPDATE_HOTEL_DETAILS:
      return { ...state, Hotels: action.payload };

    case ENUMS.actions.ISUPDATE_LOGO:
      return { ...state, Updated_Logo: action.payload };

    default:
      return state;
  }
}
