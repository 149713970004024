/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../Component/Loader/PageLoader";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import {
  getAccountDropdownData,
  getSelectedContactDataByID,
  SaveContactDetails,
} from "../Services/DashboardServices";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getSelectedAccountDetails } from "../Services/LeftMenuServices";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
const AddContactFullForm = (props) => {
  // hotels ke data me thodi dikkat aari thi to mene temprary direct le lie the hotels yaha.
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  let navigate = useNavigate();
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const [loading, setLoading] = React.useState(false);
  const [IsBirthdayValid, setIsBirthdayValid] = React.useState({ status: false, message: "" });
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  // Commented bcs discuss it :
  // const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;

  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const [accountListData, setaccountListData]: any = React.useState([]);
  let initial: any = {
    contactID: "00000000-0000-0000-0000-000000000000",
    accountID: "",
    ownerID: "",
    contactName: "",
    title: "",
    firstName: "",
    lastName: "",
    createdOn: new Date().toISOString().split("T")[0],
    source_Term: "",
    website: "",
    email: "",
    contactMobNo: "",
    countryCodeMob: "",
    contactNo: "",
    countryCodeOffice: "",
    contactFaxNo: "",
    contactOtherNo: "",
    street1: "",
    street2: "",
    area: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    reportsTo: "",
    clientID: clientID && clientID,
    updateOn: new Date().toISOString().split("T")[0],
    updateBy: "",
    isActive: true,
    isBlock: false,
    lastContactedBy: "",
    lastContactedOn: new Date().toISOString().split("T")[0],
    lastContactComment: "",
    refContactID: "",
    contactType: "",
    titleSecond: "",
    birthday: "",
    note: "",
    reportingTo: "",
    hotelId: "",
    ext: "",
    errors: {
      // title: "",
      hotelId: "",
      firstName: "",
      accountID: "",
      contactNo: "",
      contactMobNo: "",
      email: "",
      // birthday: "",
      ValidationRules: [
        // {
        //   FieldName: "title",
        //   ValidationType: "required",
        //   ValidationMessage: "Select prefix please.",
        // },
        {
          FieldName: "hotelId",
          ValidationType: "required",
          ValidationMessage: "Select Hotels please.",
        },
        {
          FieldName: "firstName",
          ValidationType: "required",
          ValidationMessage: "Enter firstName",
        },
        {
          FieldName: "accountID",
          ValidationType: "required",
          ValidationMessage: "Select Account please.",
        },
        {
          FieldName: "contactNo",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "contactMobNo",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
        // {
        //   FieldName: "birthday",
        //   ValidationType: "required",
        //   ValidationMessage: "Please select birth-date.",
        // },
        {
          FieldName: "email",
          ValidationType: "email",
          ValidationMessage: "Enter valid email address.",
        },
      ],
    },
  };
  const [saveContact, setSaveContact] = React.useState(initial);
  const [ProjectTermData, setProjectTermData]: any = React.useState([]);
  const [prefixData, setprefixData]: any = React.useState({
    value: "",
    label: "Select Prefix",
  });
  let optionsPrefix: any = [
    {
      value: "",
      label: "Select Prefix",
    },
    {
      value: "Dr.",
      label: "Dr.",
    },
    {
      value: "Mr.",
      label: "Mr.",
    },
    {
      value: "Ms.",
      label: "Ms.",
    },
    {
      value: "Mrs.",
      label: "Mrs.",
    },
  ];

  let optionsHotel: any = [
    // {
    //   value: null,
    //   label: "Select Hotel",
    // },
  ];

  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.map((item: any) => {
      optionsHotel.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  let optionsAccount: any = [
    // {
    //   value: null,
    //   label: "Select Account",
    // },
  ];
  accountListData.length > 0 &&
    accountListData.map((item: any) => {
      optionsAccount.push({
        label: item.AccountName,
        value: item.AccountID,
      });
    });

  let StateDropdown: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.Category === "State") {
        StateDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });
  let CountryDropdown: any = [];
  ProjectTermData !== undefined &&
    ProjectTermData !== null &&
    ProjectTermData.length > 0 &&
    ProjectTermData.map((item: any) => {
      if (item.Category === "Country") {
        CountryDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });

  React.useEffect(() => {
    // getAccountsDDTData("");
    sethotelChangeLoading(true);
    getCountryStateDropdown();
    if (props.GetContactId === "00000000-0000-0000-0000-000000000000") {
      if (!SelectedPropertyID.includes(",")) {
        getAccountsDDTData(SelectedPropertyID);
        setSaveContact({
          ...saveContact,
          ["hotelId"]: SelectedPropertyID,
        });
      }
    }
    if (props.AccountID) {
      getAccountsDDTData(props.PropertyID);
      setSaveContact({
        ...saveContact,
        ["hotelId"]: props.PropertyID,
        ["accountID"]: props.AccountID,
      });
    }

    if (
      typeof props.GetContactId !== "undefined" &&
      props.GetContactId &&
      props.GetContactId !== "00000000-0000-0000-0000-000000000000"
    ) {
      selectedContactDetails();
    }
    getCountryStateDropdown();
  }, []);

  async function getCountryStateDropdown() {
    let inputs = {
      category: "State,Country",
      clientID: clientID,
    };
    //Api for souce but used for Reuse Code
    const res: any = await getProjectTermByCategory(inputs, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setProjectTermData(res.data.data.dataList1);
      }
    }
    sethotelChangeLoading(false);
  }

  async function selectedContactDetails() {
    let input: any = {
      keyID: props.GetContactId && props.GetContactId,
    };
    // setLoading(true);
    sethotelChangeLoading(true);
    let res: any = await getSelectedContactDataByID(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        getAccountsDDTData(res.data.data.hotelId);
        if (res.data.data.title === "") {
          setprefixData({
            label: "Select Prefix",
            value: "",
          });
        } else {
          setprefixData({
            label: res.data.data.title,
            value: res.data.data.title,
          });
        }

        setSaveContact({
          ...saveContact,
          accountID: res.data.data.accountID,
          area: res.data.data.area,
          birthday: res.data.data.birthday && new Date(res.data.data.birthday),
          // res.data.data.birthday !== null && res.data.data.birthday !== undefined && res.data.data.birthday !== "" &&
          city: res.data.data.city,
          clientID: res.data.data.clientID,
          contactFaxNo: res.data.data.contactFaxNo,
          contactID: res.data.data.contactID,
          //
          contactMobNo:
            res.data.data.contactMobNo && !res.data.data.contactMobNo.includes(" ")
              ? res.data.data.contactMobNo
              : res.data.data.contactMobNo && res.data.data.contactMobNo.split(" ")[1],
          countryCodeMob:
            res.data.data.contactMobNo && !res.data.data.contactMobNo.includes(" ")
              ? ""
              : res.data.data.contactMobNo && res.data.data.contactMobNo.split(" ")[0],
          //
          // contactMobNo
          //             :
          //             res.data.data.contactMobNo.split(" ")[1],
          //           countryCodeMob: res.data.data.contactMobNo.split(" ")[0],
          contactName: res.data.data.contactName,
          contactNo:
            res.data.data.contactNo && !res.data.data.contactNo.includes(" ")
              ? res.data.data.contactNo
              : res.data.data.contactNo && res.data.data.contactNo.split(" ")[1],

          countryCodeOffice:
            res.data.data.contactNo && !res.data.data.contactNo.includes(" ")
              ? ""
              : res.data.data.contactNo && res.data.data.contactNo.split(" ")[0],

          contactOtherNo: res.data.data.contactOtherNo,
          contactType: res.data.data.contactType,
          country: res.data.data.country,
          createdOn: res.data.data.createdOn,
          email: res.data.data.email,
          ext: res.data.data.ext,
          firstName: res.data.data.firstName,
          hotelId: res.data.data.hotelId,
          isActive: res.data.data.isActive,
          isBlock: res.data.data.isBlock,
          lastContactComment: res.data.data.lastContactComment,
          lastContactedBy: res.data.data.lastContactedBy,
          lastContactedOn: res.data.data.lastContactedOn,
          lastName: res.data.data.lastName,
          note: res.data.data.note,
          ownerID: res.data.data.ownerID,
          refContactID: res.data.data.refContactID,
          reportingTo: res.data.data.reportingTo,
          reportsTo: res.data.data.reportsTo,
          source_Term: res.data.data.source_Term,
          state: res.data.data.state,
          street1: res.data.data.street1,
          street2: res.data.data.street2,
          title: res.data.data.title,
          titleSecond: res.data.data.titleSecond,
          updateBy: res.data.data.updateBy,
          updateOn: res.data.data.updateOn,
          website: res.data.data.website,
          zipCode: res.data.data.zipCode,
        });
        // setLoading(false);
        sethotelChangeLoading(false);
      }
    }
  }

  async function getAccountsDDTData(selectedHotelId) {
    if (selectedHotelId) {
      let input: any = {
        propertiesIDs: null,
      };
      if (selectedHotelId !== undefined && selectedHotelId !== null && selectedHotelId !== "") {
        input.propertiesIDs = selectedHotelId;
      }

      let res: any = await getAccountDropdownData(input, Token);

      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            setaccountListData(res.data.data.dataList1);
          }
        }
      }
    }
    sethotelChangeLoading(false);
  }

  async function SaveContact(event: any) {
    if (saveContact.birthday !== "" && saveContact.birthday !== null) {
      if (saveContact.birthday < new Date("1/1/1753").toISOString().split("T")[0]) {
        setIsBirthdayValid({ status: true, message: "Please Enter Date greater than 1753-01-01" });
      } else if (IsBirthdayValid.status) {
        setIsBirthdayValid({ status: false, message: "" });
      }
    }

    event.preventDefault();
    let obj = fnCheckValidationOfObject(saveContact);
    // This functionality is under discussion :
    // if (props.AccountID) {
    //   obj.obj.accountID = props.AccountID
    //   obj.obj.hotelId = "00000000-0000-0000-0000-000000000000"
    // }
    setSaveContact({
      ...obj.obj,
    });
    if (obj.isValid) {
      if (
        saveContact.contactID !== "00000000-0000-0000-0000-000000000000" &&
        saveContact.contactID !== undefined &&
        saveContact.contactID !== null
      ) {
        //  Update
        let input = {
          contactID: saveContact.contactID,
          accountID: saveContact.accountID,
          ownerID: saveContact.ownerID,
          contactName: saveContact.firstName.trim() + " " + saveContact.lastName.trim(),
          title: saveContact.title,
          firstName: saveContact.firstName,
          lastName: saveContact.lastName,
          createdOn: saveContact.createdOn,
          source_Term: saveContact.source_Term,
          website: saveContact.website,
          email: saveContact.email,
          contactMobNo: saveContact.countryCodeMob + " " + saveContact.contactMobNo,
          contactNo: saveContact.countryCodeOffice + " " + saveContact.contactNo,
          contactFaxNo: saveContact.contactFaxNo,
          contactOtherNo: saveContact.contactOtherNo,
          street1: saveContact.street1,
          street2: saveContact.street2,
          area: saveContact.area,
          city: saveContact.city,
          zipCode: saveContact.zipCode,
          state: saveContact.state,
          country: saveContact.country,
          reportsTo: saveContact.reportsTo,
          clientID: saveContact.clientID,
          updateOn: saveContact.updateOn,
          updateBy: saveContact.updateBy,
          isActive: saveContact.isActive,
          isBlock: saveContact.isBlock,
          lastContactedBy: saveContact.lastContactedBy,
          lastContactedOn: saveContact.lastContactedOn,
          lastContactComment: saveContact.lastContactComment,
          refContactID: saveContact.refContactID,
          contactType: saveContact.contactType,
          titleSecond: saveContact.titleSecond,
          birthday: saveContact.birthday && moment(saveContact.birthday).format("YYYY-MM-DD"),
          note: saveContact.note,
          reportingTo: saveContact.reportingTo,
          hotelId: saveContact.hotelId,
          ext: saveContact.ext,
        };
        setOnProcessHide(true);
        const res: any = await SaveContactDetails(input, Token);
        setOnProcessHide(false);

        if (res.data !== undefined) {
          let Message = "Contact updated SuccessFully";
          if (res.data.statusCode === 200) {
            ToasterSuccess(Message);
            props.contactdetails();
            props.onHide(false);
            // navigate("/contact/details", {
            //   state: { ContactID: res.data.data.contactID, ContactName: res.data.data.contactName },
            // });
          } else if (res.data.statusCode === 400) {
            ToasterError("Please Enter Date greater then '01-01-1753'");
          }
        } else {
          ToasterError(res.message);
        }
      } else {
        // SAVE :
        let input = {
          contactID: saveContact.contactID,
          accountID: saveContact.accountID,
          ownerID: saveContact.ownerID,
          contactName: saveContact.firstName + " " + saveContact.lastName,
          title: saveContact.title,
          firstName: saveContact.firstName,
          lastName: saveContact.lastName,
          createdOn: saveContact.createdOn,
          source_Term: saveContact.source_Term,
          website: saveContact.website,
          email: saveContact.email,
          contactMobNo: saveContact.countryCodeMob + " " + saveContact.contactMobNo,
          contactNo: saveContact.countryCodeOffice + " " + saveContact.contactNo,
          contactFaxNo: saveContact.contactFaxNo,
          contactOtherNo: saveContact.contactOtherNo,
          street1: saveContact.street1,
          street2: saveContact.street2,
          area: saveContact.area,
          city: saveContact.city,
          zipCode: saveContact.zipCode,
          state: saveContact.state,
          country: saveContact.country,
          reportsTo: saveContact.reportsTo,
          clientID: saveContact.clientID,
          updateOn: saveContact.updateOn,
          updateBy: saveContact.updateBy,
          isActive: saveContact.isActive,
          isBlock: saveContact.isBlock,
          lastContactedBy: saveContact.lastContactedBy,
          lastContactedOn: saveContact.lastContactedOn,
          lastContactComment: saveContact.lastContactComment,
          refContactID: saveContact.refContactID,
          contactType: saveContact.contactType,
          titleSecond: saveContact.titleSecond,
          birthday: saveContact.birthday,
          note: saveContact.note,
          reportingTo: saveContact.reportingTo,
          hotelId: saveContact.hotelId,
          ext: saveContact.ext,
        };

        setOnProcessHide(true);
        const res: any = await SaveContactDetails(input, Token);
        setOnProcessHide(false);

        if (res.data !== undefined) {
          let Message = "Contact Saved SuccessFully";
          if (res.data.statusCode === 200) {
            ToasterSuccess(Message);
            props.contactdetails();
            props.onHide(false);
            if (
              saveContact.contactID === "00000000-0000-0000-0000-000000000000" ||
              saveContact.contactID === undefined ||
              saveContact.contactID === null
            ) {
              if (!props.handleNavigateToDetails) {
                if (res.data.data) {
                  navigate("/contact/details", {
                    state: {
                      ContactID: res.data.data.contactID,
                      ContactName: res.data.data.contactName,
                    },
                  });
                }
              }
            }
          } else if (res.data.statusCode === 400) {
            ToasterError("Please Enter Date greater then '01-01-1753'");
          }
        } else {
          ToasterError(res.message);
        }
      }
    }
  }

  async function handleAddressCheck(e: any) {
    let data: any = accountListData.find((obj) => obj.AccountID === saveContact.accountID);

    if (e.target.checked) {
      if (data && data !== undefined) {
        let input: any = {
          keyID: data.AccountID,
        };

        let res: any = await getSelectedAccountDetails(input, Token);
        if (res.data.success) {
          if (res.data.data !== undefined && res.data.data !== null) {
            setSaveContact({
              ...saveContact,
              ["note"]: res.data.data.accountData.comment,
              ["area"]: res.data.data.accountData.billingStreet1,
              ["accountID"]: res.data.data.accountData.accountID,
              ["city"]: res.data.data.accountData.billingCity,
              ["zipCode"]: res.data.data.accountData.billingZip,
              ["state"]: res.data.data.accountData.billingState,
              ["country"]: res.data.data.accountData.billingCountry,
            });
          }
        }
      }
    } else {
      setSaveContact({
        ...saveContact,
        ["accountID"]: saveContact.accountID,
        ["note"]: "",
        ["area"]: "",
        ["city"]: "",
        ["zipCode"]: "",
        ["state"]: "",
        ["country"]: "",
        // ["contactFaxNo"]: "",
      });
    }

    // accountListData.map((item: any) => {
    //   if (item.AccountID === e.value) {
    //     setSaveContact({
    //       ...saveContact,
    //       ["accountID"]: item.AccountID,
    //       ["street1"]: e.value,
    //       ["city"]: e.value,
    //       ["zipCode"]: e.value,
    //       ["state"]: e.value,
    //       ["country"]: e.value,
    //       ["contactFaxNo"]: e.value,
    //     })
    //   }
    // })

    // setSaveContact({
    //   ...saveContact,
    //   ["accountID"]: e.value,
    // })
  }

  //#region  Old Code
  function SelectHotel(event: any) {
    let selectedHotelId: any = event.value;
    sethotelChangeLoading(true);
    getAccountsDDTData(selectedHotelId);
    setSaveContact({
      ...saveContact,
      ["hotelId"]: selectedHotelId,
      ["accountID"]: "",
    });
  }

  async function SelectAccountsID(event: any) {
    setSaveContact({
      ...saveContact,
      ["accountID"]: event.value,
    });
    let input: any = {
      keyID: event.value,
    };

    let res: any = await getSelectedAccountDetails(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null) {
        setSaveContact({
          ...saveContact,
          ["note"]: res.data.data.accountData.comment,
          ["area"]: res.data.data.accountData.billingStreet1,
          ["accountID"]: res.data.data.accountData.accountID,
          ["city"]: res.data.data.accountData.billingCity,
          ["zipCode"]: res.data.data.accountData.billingZip,
          ["state"]: res.data.data.accountData.billingState,
          ["country"]: res.data.data.accountData.billingCountry,
        });
      }
    }
  }

  //#endregion end

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Contact Information</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!loading ? (
            <>
              <Row>
                <Col xs={6} className="mb-3">
                  {/* <FloatingLabel controlId="floatingSelectGrid" label="Prefix">
                    <Form.Select
                      value={saveContact.title}
                      onChange={(e) =>
                        setSaveContact({
                          ...saveContact,
                          ["title"]: e.target.value,
                        })
                      }
                    >
                      <option value=""> Select Prefix </option>
                      <option value="Dr."> Dr. </option>
                      <option value="Mr."> Mr. </option>
                      <option value="Ms."> Ms. </option>
                      <option value="Mrs."> Mrs. </option>
                    </Form.Select>
                  </FloatingLabel> */}

                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    {/* <div className={`${saveContact.errors.title && "red-border-masking"}`}> */}
                      <Select
                        placeholder="Prefix"
                        // className={`form-control ${saveContact.errors.title && "is-invalid"}`}
                        options={optionsPrefix}
                        // value={optionsPrefix.filter(
                        //   (obj) => saveContact.title !== null && saveContact.title.includes(obj.value)
                        // )}
                        value={prefixData}
                        onChange={(e) => {
                          setprefixData(e),
                            setSaveContact({
                              ...saveContact,
                              ["title"]: e.value,
                            });
                        }}
                      />
                    {/* </div> */}
                    {/* <small style={{ color: "#dc3545" }}>
                      {" "}
                      {saveContact.errors.title && saveContact.errors.title}{" "}
                    </small> */}
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Email">
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      maxLength={164}
                      value={saveContact.email}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["email"]: event.target.value,
                        })
                      }
                      className={`form-control ${saveContact.errors.email && "is-invalid"}`}
                    />
                    {saveContact.errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {saveContact.errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="First Name">
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      maxLength={164}
                      value={saveContact.firstName}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["firstName"]: event.target.value,
                        })
                      }
                      className={`form-control ${saveContact.errors.firstName && "is-invalid"}`}
                    />
                    {saveContact.errors.firstName && (
                      <Form.Control.Feedback type="invalid">
                        {saveContact.errors.firstName}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Last Name">
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      maxLength={164}
                      value={saveContact.lastName}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["lastName"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <div className="DatePicker-col">
                    <FloatingLabel controlId="floatingInput" label="Birthday">
                      <DatePicker
                        // minDate={new Date("1753/01/01")}
                        minDate={new Date("1900/01/01")}
                        maxDate={new Date("9999/12/31")}
                        selected={saveContact.birthday && saveContact.birthday}
                        className={`form-control ${saveContact.errors.birthday && "is-invalid"}`}
                        onChange={(e) => {
                          setSaveContact({
                            ...saveContact,
                            ["birthday"]: e,
                          });
                        }}
                        placeholderText={Cookies.get("SystemSettingDate")}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        // minDate={
                        //   SaveMeetingCatering.endDate !== null && SaveMeetingCatering.endDate > SaveMeetingCatering.startDate ? SaveMeetingCatering.startDate : new Date()
                        // }
                        dateFormat={Cookies.get("SystemSettingDate")}
                      />
                      {saveContact.errors.birthday && (
                        <Form.Control.Feedback type="invalid">
                          {saveContact.errors.birthday}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </div>
                  {/* <div className={`${IsBirthdayValid.status === true && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingInput" label="Birthday">
                      <Form.Control
                        type="date"
                        min={"1753-01-01"}
                        max={"9999-12-31"}
                        placeholder="Birthday"
                        value={saveContact.birthday}
                        onChange={(event) =>
                          setSaveContact({
                            ...saveContact,
                            ["birthday"]: event.target.value,
                          })
                        }
                        // className={`form-control ${saveContact.errors.birthday && "is-invalid"}`}
                      />
                     
                    </FloatingLabel>
                    <small style={{ color: "#dc3545" }}>
                      {IsBirthdayValid.status === true && IsBirthdayValid.message}
                    </small>
                  </div> */}
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Reports to">
                    <Form.Control
                      type="text"
                      value={saveContact.reportingTo}
                      placeholder="Reports to"
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["reportingTo"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Title">
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      maxLength={364}
                      value={saveContact.titleSecond}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["titleSecond"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Department">
                    <Form.Control
                      type="text"
                      value={saveContact.contactType}
                      maxLength={164}
                      placeholder="contactType"
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["contactType"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              {!props.AccountID ? (
                <Row>
                  <Col xs={6} className="mb-3">
                    <Form.Group>
                      <div className={`${saveContact.errors.hotelId && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            placeholder="Select Hotels"
                            options={optionsHotel}
                            value={optionsHotel.filter(
                              (obj) =>
                                saveContact.hotelId !== null &&
                                saveContact.hotelId.includes(obj.value)
                            )}
                            onChange={(e) => {
                              SelectHotel(e);
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {" "}
                        {saveContact.errors.hotelId && saveContact.errors.hotelId}{" "}
                      </small>
                    </Form.Group>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <Form.Group>
                      <div className={`${saveContact.errors.accountID && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            placeholder="Select Account"
                            options={optionsAccount}
                            value={optionsAccount.filter(
                              (obj) =>
                                saveContact.accountID &&
                                saveContact.accountID !== null &&
                                saveContact.accountID !== undefined &&
                                saveContact.accountID.includes(obj.value)
                            )}
                            onChange={(e) => {
                              SelectAccountsID(e);
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {" "}
                        {saveContact.errors.accountID && saveContact.errors.accountID}{" "}
                      </small>
                    </Form.Group>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row>
                <Col xs={6} className="mb-3">
                  {/* <label className="form-label ps-0 ms-0">Mobile Phone :</label> */}
                  <Row>
                    <Col xs={6}>
                      <FloatingLabel controlId="floatingInput" label="Code">
                        <Form.Control
                          type="text"
                          placeholder="Code"
                          value={saveContact.countryCodeMob}
                          maxLength={6}
                          onChange={(event) =>
                            setSaveContact({
                              ...saveContact,
                              ["countryCodeMob"]: event.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={6}>
                      <FloatingLabel controlId="floatingInput" label="">
                        <div
                          className={`${saveContact.errors.contactMobNo && "red-border-masking"}`}
                        >
                          <FloatingLabel controlId="floatingInput" label="Mobile Phone">
                            <PatternFormat
                              className="form-control"
                              displayType="input"
                              placeholder="Mobile Phone"
                              format="###-###-####"
                              value={saveContact.contactMobNo}
                              onChange={(event) =>
                                setSaveContact({
                                  ...saveContact,
                                  ["contactMobNo"]: event.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {saveContact.errors.contactMobNo && (
                          <>
                            <span className="feedbackss">{saveContact.errors.contactMobNo}</span>
                            <br />
                          </>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} className="mb-3">
                  {/* <label className="form-label ps-0 ms-0">Office Phone :</label> */}
                  <Row>
                    <Col xs={3}>
                      <FloatingLabel controlId="floatingInput" label="Code">
                        <Form.Control
                          type="text"
                          placeholder="Code"
                          value={saveContact.countryCodeOffice}
                          maxLength={6}
                          onChange={(event) =>
                            setSaveContact({
                              ...saveContact,
                              ["countryCodeOffice"]: event.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={6}>
                      {/* <FloatingLabel controlId="floatingInput" label="Mobile No.">
                      <MaskedInput
                        className="form-control"
                        placeholder="___-___-____"
                        mask={[
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        value={saveContact.contactNo}
                        onChange={(event) =>
                          setSaveContact({
                            ...saveContact,
                            ["contactNo"]: event.target.value,
                          })
                        }
                      />
                    </FloatingLabel> */}

                      <FloatingLabel controlId="floatingInput" label="">
                        <div className={`${saveContact.errors.contactNo && "red-border-masking"}`}>
                          <FloatingLabel controlId="floatingInput" label="Office Phone">
                            <PatternFormat
                              className="form-control"
                              placeholder="Office Phone"
                              displayType="input"
                              // value={SuspectSave.telephoneNo}
                              format="###-###-####"
                              value={saveContact.contactNo}
                              onChange={(event) =>
                                setSaveContact({
                                  ...saveContact,
                                  ["contactNo"]: event.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {saveContact.errors.contactNo && (
                          <>
                            <span className="feedbackss">{saveContact.errors.contactNo}</span>
                            <br />
                          </>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col xs={3}>
                      <FloatingLabel controlId="floatingInput" label="Ext">
                        <Form.Control
                          type="text"
                          placeholder="Ext"
                          maxLength={9}
                          value={saveContact.ext}
                          onChange={(event) =>
                            setSaveContact({
                              ...saveContact,
                              ["ext"]: event.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* {!props.AccountID ? ( */}
              <Row>
                <Col xs={6} className="mb-3">
                  <Form.Check
                    inline
                    label="Same as Account"
                    name="grpType"
                    value="Company"
                    id="Same as Account"
                    // type={type}
                    // id={`inline-${type}-Start With`}
                    // className="d-flex align-items-center m-0"
                    // checked={SuspectSave.isGroup === false ? true : false}
                    // value={SuspectSave.lead_Status_Term} //|| "Open"}
                    onChange={(e) => handleAddressCheck(e)}
                    // defaultChecked
                  />
                </Col>
              </Row>
              {/* ) : (
                ""
              )} */}

              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="City">
                    <Form.Control
                      type="text"
                      placeholder="City"
                      maxLength={164}
                      value={saveContact.city}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["city"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                {/* <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="State">
                    <Form.Control
                      type="text"
                      value={saveContact.state}
                      placeholder="State"
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["state"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col> */}

                <Col xs={6} className="mb-3">
                  <div>
                    <FloatingLabel controlId="floatingInput" label="">
                      <CreatableSelect
                        isClearable
                        options={StateDropdown}
                        onChange={(event: any) => {
                          setSaveContact({
                            ...saveContact,
                            ["state"]: event !== null ? event.value : "",
                          });
                        }}
                        placeholder="State"
                        value={
                          saveContact.state !== ""
                            ? {
                                label: saveContact.state,
                                value: saveContact.state,
                              }
                            : {
                                label: "State",
                                value: "State",
                              }
                        }
                      />
                    </FloatingLabel>
                  </div>
                </Col>
              </Row>
              <Row>
                {/* <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Country">
                    <Form.Control
                      type="text"
                      value={saveContact.country}
                      placeholder="Country"
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["country"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col> */}
                <Col xs={6} className="mb-3">
                  <div>
                    <FloatingLabel controlId="floatingInput" label="">
                      <CreatableSelect
                        placeholder={"Country"}
                        isClearable
                        options={CountryDropdown}
                        onChange={(event: any) => {
                          setSaveContact({
                            ...saveContact,
                            ["country"]: event !== null ? event.value : "",
                          });
                        }}
                        value={
                          saveContact.country !== ""
                            ? {
                                label: saveContact.country,
                                value: saveContact.country,
                              }
                            : {
                                label: "Country",
                                value: "Country",
                              }
                        }
                      />
                    </FloatingLabel>
                  </div>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="ZipCode">
                    <Form.Control
                      type="text"
                      placeholder="ZipCode"
                      maxLength={12}
                      value={saveContact.zipCode}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["zipCode"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Linked In Profile">
                    <Form.Control
                      type="text"
                      placeholder="Website"
                      maxLength={164}
                      value={saveContact.website}
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["website"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6}>
                  {/* <FloatingLabel controlId="floatingInput" label="Fax No.">
                  <Form.Control
                    type="number"
onWheel={(event: any) => { event.target.blur() }}
                    onKeyDown={(e :any )=>{                       
                                             if(e.keyCode == '38' || e.keyCode == '40'){
                                                 e.stopPropagation()
                                                 e.preventDefault()
                                             }
                                         }}
                    placeholder="Fax no."
                    value={saveContact.contactFaxNo}
                    onChange={(event) =>
                      setSaveContact({
                        ...saveContact,
                        ["contactFaxNo"]: event.target.value,
                      })
                    }
                  />
                </FloatingLabel> */}

                  <FloatingLabel controlId="floatingInput" label="">
                    <FloatingLabel controlId="floatingInput" label="Fax No.">
                      <PatternFormat
                        className="form-control"
                        placeholder="Fax No."
                        displayType="input"
                        format="###-###-####"
                        value={saveContact.contactFaxNo}
                        onChange={(event) =>
                          setSaveContact({
                            ...saveContact,
                            ["contactFaxNo"]: event.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Address">
                    <Form.Control
                      as="textarea"
                      maxLength={364}
                      style={{ height: "100px" }}
                      value={saveContact.area}
                      placeholder="Address"
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["area"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Notes">
                    <Form.Control
                      as="textarea"
                      style={{ height: "100px" }}
                      value={saveContact.note}
                      placeholder="Notes"
                      onChange={(event) =>
                        setSaveContact({
                          ...saveContact,
                          ["note"]: event.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          ) : (
            <PageLoader />
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={SaveContact}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default AddContactFullForm;
