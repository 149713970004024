import React, { useRef, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import PageLoader from "../../Component/Loader/PageLoader";
import { CommonService } from "../../Services/CommonServices";
import { AccountActualReportList } from "../../Services/ReportServices";

import { useDownloadExcel } from "react-export-table-to-excel";

function ActualReportModal(props: any) {
  let ActualReport_page_rights = CommonService.getPageRight("Actual Report");

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  const [loading, setloading] = useState(false);
  const [loadProcess, setloadProcess] = useState(false);
  const [loadProcessPDF, setloadProcessPDF] = useState(false);
  const [ExportLoading, setExportLoading] = useState(false); // Hotel Selection Loader State.

  const [ReportGetData, setReportGetData]: any = React.useState([]);

  React.useEffect(() => {
    FetchActualReportData();
  }, []);

  async function FetchActualReportData() {
    let input: any = {
      clientID: clientID,
      propertyID: props.ActualReportData.propertyID,
      accountID: props.ActualReportData.accountIDs,
      year: props.ActualReportData.year,
    };
    setloading(true);
    setloadProcess(true);
    setloadProcessPDF(true);

    let res: any = await AccountActualReportList(input, Token);
    setloading(false);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          setloadProcess(false);
          setloadProcessPDF(false);
          setReportGetData(res.data.data.lstData);
        }
      }
    }
  }

  let ActualReportTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-30">
            <th className="text-center"> Company </th>
            <th className="text-center"> Opportunity Name </th>
            <th className="text-center"> Oppo Type </th>
            <th colSpan={12} className="text-center">
              Actual Monthly
            </th>
            <th colSpan={3} className="text-center">
              Actualized
            </th>
          </tr>
          <tr className="h-30">
            <th> </th>
            <th> </th>
            <th> </th>
            <th className="text-center">Jan</th>
            <th className="text-center">Feb</th>
            <th className="text-center">March</th>
            <th className="text-center">April</th>
            <th className="text-center">May</th>
            <th className="text-center">Jun</th>
            <th className="text-center">July</th>
            <th className="text-center">Aug</th>
            <th className="text-center">Sept</th>
            <th className="text-center">Oct</th>
            <th className="text-center">Nov</th>
            <th className="text-center">Dec</th>

            <th className="text-center">RN</th>
            <th className="text-center">ADR</th>
            <th className="text-center">REV</th>
          </tr>
        </thead>
        <tbody>
          {ReportGetData !== undefined &&
            ReportGetData !== null &&
            ReportGetData !== "" &&
            ReportGetData.map((item, index) => {
              return (
                <tr key={index} className="h-40">
                  <td>{item.AccountName}</td>
                  <td>{item.OpportunityName}</td>
                  <td>{item.OppoType}</td>
                  <td style={{ textAlign: "right" }}>${item.January}</td>
                  <td style={{ textAlign: "right" }}>${item.February}</td>
                  <td style={{ textAlign: "right" }}>${item.March}</td>
                  <td style={{ textAlign: "right" }}>${item.April}</td>
                  <td style={{ textAlign: "right" }}>${item.May}</td>
                  <td style={{ textAlign: "right" }}>${item.June}</td>
                  <td style={{ textAlign: "right" }}>${item.July}</td>
                  <td style={{ textAlign: "right" }}>${item.August}</td>
                  <td style={{ textAlign: "right" }}>${item.September}</td>
                  <td style={{ textAlign: "right" }}>${item.October}</td>
                  <td style={{ textAlign: "right" }}>${item.November}</td>
                  <td style={{ textAlign: "right" }}>${item.December}</td>
                  <td style={{ textAlign: "center" }}>{item.RN}</td>
                  <td style={{ textAlign: "right" }}>${item.ADR}</td>
                  <td style={{ textAlign: "right" }}>${item.REV}</td>
                </tr>
              );
            })}
          {ReportGetData !== undefined && ReportGetData && !ReportGetData.length && (
            <tr className="h-30" align="center">
              <td colSpan={20}> No data found ...! </td>
            </tr>
          )}
        </tbody>
      </>
    );
  };

  const handleClose = () => {
    props.onHide(false);
  };

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Actual Report" + props.ActualReportData.year,
    sheet: "ActualReport",
  });

  let ActualTableExport: any = () => {
    return (
      <>
        <thead>
          <tr>
            <th
              colSpan={4}
              style={{ background: "#417FF9", color: "#ffffff", fontSize: "11", fontWeight: "600" }}
            >
              Actual Report (
              {props.ActualReportData.year + " : " + props.ActualReportData.propertyName})
            </th>
          </tr>
          <tr></tr>
          <tr>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              {" "}
              Company{" "}
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              {" "}
              Opportunity Name{" "}
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Oppo Type
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              colSpan={12}
              className="text-center"
            >
              Actual Monthly
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              colSpan={3}
              className="text-center"
            >
              Actualized
            </th>
          </tr>

          <tr>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              {" "}
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              {" "}
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              {" "}
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Jan
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Feb
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              March
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              April
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              May
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Jun
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              July
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Aug
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Sept
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Oct
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Nov
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              Dec
            </th>

            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              RN
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              ADR
            </th>
            <th
              style={{
                background: "#0D83BB",
                color: "#ffffff",
                fontSize: "11",
                fontWeight: "600",
              }}
              className="text-center"
            >
              REV
            </th>
          </tr>
        </thead>

        <tbody>
          {ReportGetData !== undefined &&
            ReportGetData !== null &&
            ReportGetData !== "" &&
            ReportGetData.map((item, index) => {
              return (
                <tr key={index} className="h-40">
                  <td>{item.AccountName}</td>
                  <td>{item.OpportunityName}</td>
                  <td>{item.OppoType}</td>
                  <td style={{ textAlign: "right" }}>${item.January}</td>
                  <td style={{ textAlign: "right" }}>${item.February}</td>
                  <td style={{ textAlign: "right" }}>${item.March}</td>
                  <td style={{ textAlign: "right" }}>${item.April}</td>
                  <td style={{ textAlign: "right" }}>${item.May}</td>
                  <td style={{ textAlign: "right" }}>${item.June}</td>
                  <td style={{ textAlign: "right" }}>${item.July}</td>
                  <td style={{ textAlign: "right" }}>${item.August}</td>
                  <td style={{ textAlign: "right" }}>${item.September}</td>
                  <td style={{ textAlign: "right" }}>${item.October}</td>
                  <td style={{ textAlign: "right" }}>${item.November}</td>
                  <td style={{ textAlign: "right" }}>${item.December}</td>
                  <td style={{ textAlign: "center" }}>{item.RN}</td>
                  <td style={{ textAlign: "right" }}>${item.ADR}</td>
                  <td style={{ textAlign: "right" }}>${item.REV}</td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  };

  return (
    <>
      <Modal {...props} onHide={handleClose} fullscreen dialogClassName="remove-tabIndex">
        <Modal.Header closeButton>
          <Modal.Title>
            Actual Report (
            {props.ActualReportData.year + " : " + props.ActualReportData.propertyName})
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!loading ? (
            <>
              <Row className="mt-4">
                <Col lg={12}>
                  <div className="information-division">
                    <h3 className="inner-header m-0">Actual Report</h3>
                    <div className="tab-inner-box position-relative">
                      <div className="customtablemetting">
                        <Table responsive bordered className="table-without-space mb-0">
                          <ActualReportTable />
                        </Table>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <PageLoader />
            </>
          )}
        </Modal.Body>

        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary ms-0"
            disabled={ReportGetData.length ? false : true}
            onClick={() => {
              if (ActualReport_page_rights.is_export) {
                onDownload();
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
        </Modal.Footer>
      </Modal>
      {ExportLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
      <Table
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <ActualTableExport />
      </Table>
    </>
  );
}

export default ActualReportModal;
