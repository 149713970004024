import React from "react";
import { Button, Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import PageLoader from "../../Component/Loader/PageLoader";
import GroupPaceReportModal from "../../Model/Reports/GroupPaceReportModal";
import {
  AppConfigData,
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import {
  GroupPaceReportListExport,
  GroupPaceReportListExportPDF,
} from "../../Services/ReportServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
import GoalReport from "../../Asset/Images/Other/generate-report.png";

function GroupPaceReport() {
  let GroupPaceReport_page_rights = CommonService.getPageRight("GroupPaceReport");

  const dataSelector: any = useSelector((state: any) => state.session);
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);
  let currentYear = new Date().getFullYear();
  const [selectedYear, setselectedYear]: any = React.useState(currentYear);
  const [showModal, setshowModal] = React.useState(false);
  const [yearList, setYearList]: any = React.useState([]);
  const [Downloading, setDownloading] = React.useState(false);
  const [DownloadingPDF, setDownloadingPDF] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [ReportData, setReportData]: any = React.useState({
    propertyID: "",
    propertyName: "Select property",
    year: "",
    prevYear: "",
    yearID: "",
    prevYearID: "",
    errors: {
      propertyID: "",
      ValidationRules: [
        {
          FieldName: "propertyID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  let HotelsDropdown: any = [];

  hotelsData !== undefined &&
    hotelsData !== null &&
    hotelsData.length > 0 &&
    hotelsData.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("groupPaceReportFilter"))) {
      HandleRemoveOtherFilters("groupPaceReportFilter");
      HandleFilters("groupPaceReportFilter", {
        pagePath: "/GroupPaceReport",
        pageName: "groupPaceReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  React.useEffect(() => {
    fetchYearDropdown();
  }, [SelectedPropertyID]);

  async function fetchYearDropdown() {
    let yearInput = {
      clientID: clientID,
      category: "year",
    };
    setLoading(true);
    let resYear = await getProjectTermByCategory(yearInput, Token);

    if (resYear.status === 200) {
      if (resYear.data.success) {
        if (resYear.data.data !== undefined && resYear.data.data) {
          if (
            resYear.data.data.dataList1 !== undefined &&
            resYear.data.data.dataList1 &&
            resYear.data.data.dataList1.length
          ) {
            let yrArray: any = [];

            let tempPrevID: any;
            resYear.data.data.dataList1.forEach((itm) => {
              yrArray = [
                ...yrArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];

              if (selectedYear.toString() === itm.TermCode) {
                setReportData({
                  ...ReportData,
                  ["year"]: itm.TermCode,
                  ["yearID"]: itm.TermID,
                  ["propertyID"]: SelectedPropertyID.includes(",") ? "" : SelectedPropertyID,
                  ["propertyName"]:
                    SelectedPropertyName === "" || SelectedPropertyName === "All"
                      ? "Select Property"
                      : SelectedPropertyName,
                });
              }
            });

            setYearList(yrArray);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    }
  }

  async function hamdleGenerateReport() {
    let tempPrevYear: any;
    let tempPrevID: any;
    tempPrevYear = selectedYear - 1;

    yearList.length &&
      yearList.map((itm) => {
        if (tempPrevYear.toString() === itm.label) {
          tempPrevID = itm.value;
          tempPrevYear = itm.label;
        }
      });

    let obj = fnCheckValidationOfObject(ReportData);
    obj.obj.prevYear = tempPrevYear;
    obj.obj.prevYearID = tempPrevID;

    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      setshowModal(true);
    }
  }

  async function GenerateExcel() {
    let tempPrevYear: any;
    let tempPrevID: any;
    tempPrevYear = selectedYear - 1;

    yearList.length &&
      yearList.map((itm) => {
        if (tempPrevYear.toString() === itm.label) {
          tempPrevID = itm.value;
          tempPrevYear = itm.label;
        }
      });

    let obj = fnCheckValidationOfObject(ReportData);
    obj.obj.prevYear = tempPrevYear;
    obj.obj.prevYearID = tempPrevID;

    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      let input: any = {
        propertyID: ReportData.propertyID,
        yearID: ReportData.yearID,
      };

      setDownloading(true);
      let res: any = await GroupPaceReportListExport(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setDownloading(false);
          } else {
            ToasterError(res.data.message);
            setDownloading(false);
          }
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setDownloading(false);
      }
    }
  }

  async function exportPDF() {
    let tempPrevYear: any;
    let tempPrevID: any;
    tempPrevYear = selectedYear - 1;

    yearList.length &&
      yearList.map((itm) => {
        if (tempPrevYear.toString() === itm.label) {
          tempPrevID = itm.value;
          tempPrevYear = itm.label;
        }
      });

    let obj = fnCheckValidationOfObject(ReportData);
    obj.obj.prevYear = tempPrevYear;
    obj.obj.prevYearID = tempPrevID;

    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      let input: any = {
        propertyID: ReportData.propertyID,
        yearID: ReportData.yearID,
      };

      setDownloadingPDF(true);
      let res: any = await GroupPaceReportListExportPDF(input, Token);
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            window.open(AppConfigData.APIBasePath + res.data.data);
            setDownloadingPDF(false);
          } else {
            ToasterError(res.data.message);
            setDownloadingPDF(false);
          }
        } else {
          ToasterError(res.data.message);
          setDownloadingPDF(false);
        }
      } else {
        ToasterError("Something Went Wrong.");
        setDownloadingPDF(false);
      }
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>

          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {GroupPaceReport_page_rights.is_view ? (
              !loading ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={8} className="mb-4">
                      <div
                        className={`${
                          ReportData.errors && ReportData.errors.propertyID && "red-border-masking"
                        }`}
                      >
                        <FloatingLabel label="">
                          <Select
                            placeholder="Select Property"
                            options={HotelsDropdown}
                            // value={{ value: ReportData.propertyID, label: ReportData.propertyName }}
                            value={HotelsDropdown.filter(
                              (itm) =>
                                ReportData.propertyID && itm.value.includes(ReportData.propertyID)
                            )}
                            onChange={(event: any) =>
                              setReportData({
                                ...ReportData,
                                ["propertyID"]: event.value,
                                ["propertyName"]: event.label,
                              })
                            }
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {ReportData.errors && ReportData.errors.propertyID}{" "}
                      </small>
                    </Col>
                    <Col lg={4} className="mb-4">
                      <FloatingLabel controlId="selectUser" label="">
                        <Select
                          value={yearList.filter((obj) => obj.label === selectedYear.toString())}
                          onChange={(itm: any) => {
                            setselectedYear(itm.label);
                            setReportData({
                              ...ReportData,
                              ["year"]: itm.label,
                              ["yearID"]: itm.value,
                            });
                          }}
                          options={yearList}
                          name="colors"
                          placeholder="Select Year"
                          className="basic-multi-select"
                          isDisabled={false}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Modal.Footer className="border-0 justify-content-start">
                      <Button
                        variant="primary ms-0"
                        onClick={() => {
                          if (GroupPaceReport_page_rights.is_view) {
                            hamdleGenerateReport();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        <span className="me-2">
                          <img src={GoalReport} alt="" />
                        </span>
                        Generate Report
                      </Button>
                      <Button
                        variant="primary ms-0"
                        onClick={() => {
                          if (GroupPaceReport_page_rights.is_export) {
                            GenerateExcel();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                        disabled={Downloading === true ? true : false}
                      >
                        <span className="me-2">
                          <img src={ExportIcon} alt="" />
                        </span>
                        Export Excel
                      </Button>

                      <Button
                        variant="primary ms-0"
                        disabled={DownloadingPDF === true ? true : false}
                        onClick={(e) => {
                          if (GroupPaceReport_page_rights.is_export) {
                            e.preventDefault();
                            exportPDF();
                          } else {
                            CommonService.unauthorizedAction();
                          }
                        }}
                      >
                        <span className="me-2">
                          <img src={PdfIcon} alt="" />
                        </span>
                        Export PDF
                      </Button>
                    </Modal.Footer>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {showModal && (
          <GroupPaceReportModal
            groupPaceReportData={ReportData}
            show={showModal}
            onHide={() => setshowModal(false)}
          />
        )}
      </div>
    </>
  );
}

export default GroupPaceReport;
