import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import TableView from "../../Component/Table/Index";
import AddAdditionalSetting from "../../Model/AddAdditionalSetting";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";
import {
  DeleteAdditionalSetting,
  getProjectTermByCategory,
  getProjectTermCategoryList,
} from "../../Services/Setup&Config/AdditionalSettingService";

function AdditionalSettings() {
  let page_rights_AdditionalSetting = CommonService.getPageRight("AdditionalSettings");

  const [modalShow, setModalShow] = React.useState(false);
  const AdditionalSettingTableHeader = React.useMemo(
    () => [
      {
        Header: "Category",
        accessor: "category",
        // className: "w-300",
      },
      {
        Header: "Term Code",
        accessor: "termcode",
        // className: "w-300",
      },
      {
        Header: "Term",
        accessor: "term",
        // className: "w-300",
      },
      {
        Header: "Description",
        accessor: "description",
        // className: "w-300",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "actionsort table-action sticky-action",
      },
    ],
    []
  );
  const [GetCategoryList, setGetCategoryList]: any = React.useState([]);
  let AdditionalTableData: any = [];

  const [CategoryDetailedData, setCategoryDetailedData] = React.useState({
    IsData: true,
    data: [],
  });
  const [getFilterCategory, setFilterCategory] = React.useState("");
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("additionalSettingFilter"))) {
      HandleRemoveOtherFilters("additionalSettingFilter");
      HandleFilters("additionalSettingFilter", {
        pagePath: "/SetupAndConfigurations/additionalSettings",
        pageName: "additionalSetting",
        filters: [],
        isSort: [],
      });
    }
    CategoryList();
    CategoryDetails({ category: "" }, null);
  }, []);

  let category_options: any = [
    {
      value: "",
      label: "Select Category",
      color: "",
    },
  ];

  GetCategoryList.map((item: any) => {
    category_options.push({
      value: item.Category,
      label: item.Category,
      color: "",
    });
  });
  CategoryDetailedData.data.map((item: any) => {
    AdditionalTableData.push({
      className: {
        action: "table-action sticky-action",
      },
      termcode: item.TermCode,
      description: item.Description,
      term: item.Term,
      category: item.Category,
      action:
        item.IsDefault === false ? (
          <div className="btn-icon-grp-table justify-content-end">
            <button
              type="button"
              onClick={() => {
                if (page_rights_AdditionalSetting.is_delete) {
                  DeleteTerm(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
              className="btn-icon cancelIcon btn btn-primary"
            ></button>
          </div>
        ) : (
          ""
        ),
    });
  });
  function DeleteTerm(value: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          KeyID: value.TermID,
        };

        let res: any = await DeleteAdditionalSetting(input, Token);

        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              ToasterSuccess("Project Term Deleted Successfully");

              CategoryDetails({ category: getFilterCategory }, getFilterCategory);
            } else {
              ToasterError("Something Went Wrong");
            }
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }
  async function CategoryList() {
    const res: any = await getProjectTermCategoryList(Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetCategoryList(res.data.data.dataList1);
        }
      }
    }
  }
  async function CategoryDetails(selectedCategory, eventKeyValue) {
    setFilterCategory(eventKeyValue !== null && eventKeyValue !== undefined ? eventKeyValue : "");
    let input = {
      clientID: clientID, // null,
      category:
        selectedCategory.category !== undefined &&
        selectedCategory.category !== null &&
        selectedCategory.category !== ""
          ? selectedCategory.category
          : null,
    };
    setCategoryDetailedData({ IsData: true, data: [] });
    const res: any = await getProjectTermByCategory(input, Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setCategoryDetailedData({ IsData: false, data: res.data.data.dataList1 });
      }
    }
  }

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <div className="width-220px me-2">
          <Select
            placeholder="Select Category"
            className="multiselect"
            options={category_options}
            onChange={(e: any) => CategoryDetails({ category: e.value }, e.value)}
          />
        </div>
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Term</Tooltip>}>
          <Button
            onClick={() => {
              if (page_rights_AdditionalSetting.is_create) {
                setModalShow(true);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            className="btn-icon addFormIcon"
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <Row>
          <Col xs={12} className="portfolio-Table">
            <div className="lead-height-table">
              <TableView
                columnsData={AdditionalSettingTableHeader}
                tableData={AdditionalTableData}
                isData={CategoryDetailedData.IsData}
              />
            </div>
          </Col>
        </Row>
      </Container>

      {modalShow && (
        <AddAdditionalSetting
          show={modalShow}
          onHide={() => setModalShow(false)}
          // SelectedCategoryID={SelectedCategoryID}
          CategoryDetails={CategoryDetails}
          FilterCategory={getFilterCategory}
          CategoryList={CategoryList}
        ></AddAdditionalSetting>
      )}
    </>
  );
}

export default AdditionalSettings;
