import React from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

export default function GlobalSearchloading() {
    return (
        <SkeletonTheme baseColor="#dce1ee" highlightColor="#fff">
            <div className='row mt-4'>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
                <div className='col-4 mb-2'>
                    <div className='actitvity-cards card'>
                        <div className='card-header d-flex align-items-start justify-content-between p-0'>
                            <div className='activity-card-link cursor-pointer w-100 d-block'><Skeleton height="1.5rem" /></div>
                            <label className="w-50 d-block pe-0 me-0"><Skeleton height="1.5rem" /></label>
                        </div>
                        <div className='activity-code w-100'>
                            <h6 className='mt-2 mb-2'><Skeleton height="1.5rem" /></h6>
                        </div>
                        <div className='grey-labels'>
                            <Skeleton height="1.5rem" />
                        </div>
                    </div>
                </div>
            </div>
        </SkeletonTheme>)
}
