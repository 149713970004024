import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import { getAccountDropdownData, SaveContactDetails } from "../../Services/DashboardServices";
import { PatternFormat } from "react-number-format";
import { ToasterError, ToasterSuccess } from "../../Services/CommonServices";
import AddContactFullForm from "../AddContactFullForm";
import { useDispatch } from "react-redux";
import { updateContactDataFetch } from "../../Redux/actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie-consent";
import PageLoader from "../../Component/Loader/PageLoader";

export default function AddContactModal({ modalShowContact, setModalShowContact }) {
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );

  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [fullModalShowContact, setFullModalShowContact] = useState(false);
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const isData = dataSelector.auth_UserDetail;
  const Token = dataSelector.access_Token;

  // const userId: any = dataSelector.auth_UserDetail.U_UserID;
  // const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  let navigate = useNavigate();
  const hotelsData: any = useSelector((state: any) => state.header.Hotels) || [];
  const contact_data_reload_count = dataSelector.contact_data_reload_count;
  const dispatch = useDispatch();
  let initialHotelToSet: any = [];

  // hotelsData !== undefined && hotelsData !== null && hotelsData.length > 0 && hotelsData.forEach((item: any) => {
  //   initialHotelToSet = [...initialHotelToSet, {
  //     label: item.HotelCode +" "+ item.PropertyName,
  //     value: item.PropertyID,
  //   }]
  // });

  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.forEach((item: any) => {
      initialHotelToSet = [
        ...initialHotelToSet,
        {
          label: item.HotelCode + " " + item.PropertyName,
          value: item.PropertyID,
        },
      ];
    });

  let initialFormInputs = {
    title: "",
    hotelId: "",
    firstName: "",
    lastName: "",
    accountID: "",
    reportingTo: "",
    titleSecond: "",
    contactType: "",
    birthday: "",
    email: "",
    note: "",
    contactMobNo: "",
    countryCodeMob: "",
    // unused
    contactID: "00000000-0000-0000-0000-000000000000",
    ownerID: "",
    contactName: "",
    createdOn: new Date().toISOString().split("T")[0],
    source_Term: "",
    website: "",
    contactNo: "",
    countryCodeOffice: "",
    contactFaxNo: "",
    contactOtherNo: "",
    street1: "",
    street2: "",
    area: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    reportsTo: "",
    clientID: clientID && clientID,
    updateOn: new Date().toISOString().split("T")[0],
    updateBy: "",
    isActive: true,
    isBlock: false,
    lastContactedBy: "",
    lastContactedOn: new Date().toISOString().split("T")[0],
    lastContactComment: "",
    refContactID: "",
    ext: "",
  };

  let initialFormInputEr = {
    // title: "",
    hotelId: "",
    firstName: "",
    accountID: "",
    email: "",
    contactMobNo: "",
  };

  const validation_msg = {
    // title: "This field is required.",
    hotelId: "This field is required.",
    firstName: "This field is required.",
    accountID: "This field is required.",
    // email: "This field is required.",
    // contactMobNo: "This field is required.",
    invalid_contactMobNo: "Enter Valid Contact No.",
    invalid_email: "Email is invalid.",
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const [accountListData, setAccountListData] = useState([]);
  const [selectedAccountList, setSelectedAccountList] = useState([]);

  const [hotelOptions, setHotelOptions] = useState(initialHotelToSet);
  const [selectedHotelOptions, setSelectedHotelOptions]: any = useState([]);

  const [formInputs, setFormInputs] = useState<any>(initialFormInputs);
  const [formInputsEr, setFormInputsEr] = useState<any>(initialFormInputEr);

  const handleClosePopup = () => {
    setModalShowContact(false);
    setFormInputsEr(initialFormInputEr);
    setFormInputs(initialFormInputs);
    setSelectedHotelOptions([]);
    setSelectedAccountList([]);
    setAccountListData([]);
  };

  const getAccountsDDTData = async (selectedHotelId) => {
    let input: any = {
      propertiesIDs: null,
    };
    if (selectedHotelId !== undefined && selectedHotelId !== null && selectedHotelId !== "") {
      input.propertiesIDs = selectedHotelId;
    }

    let res: any = await getAccountDropdownData(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          let optionsAccount: any = [];
          res.data.data.dataList1.forEach((item_ac: any) => {
            optionsAccount = [
              ...optionsAccount,
              {
                label: item_ac.AccountName,
                value: item_ac.AccountID,
              },
            ];
          });
          setAccountListData(optionsAccount);
        }
      }
    }
    sethotelChangeLoading(false);
  };
  const validateForm = () => {
    let isValid = true;
    let error_json = {};
    Object.keys(formInputsEr).forEach((form_key) => {
      error_json = {
        ...error_json,
        [form_key]: "",
      };
    });
    Object.keys(formInputsEr).forEach((form_key) => {
      if (formInputs[form_key] === "" || !formInputs[form_key]) {
        if (form_key !== "email") {
          if (form_key !== "contactMobNo") {
            error_json = {
              ...error_json,
              [form_key]: validation_msg[form_key],
            };
            isValid = false;
          }
        }
      } else {
        if (form_key === "email") {
          let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (formInputs[form_key].slice(-19) !== "@houston.nae.school") {
            if (!formInputs[form_key].match(mailformat)) {
              error_json = {
                ...error_json,
                [form_key]: validation_msg.invalid_email,
              };
              isValid = false;
            }
          } else {
            let emailFormate = /^[@.a-zA-Z1-9]+$/;
            if (!formInputs[form_key].match(emailFormate)) {
              error_json = {
                ...error_json,
                [form_key]: validation_msg.invalid_email,
              };
              isValid = false;
            }
          }
        }

        // if (objRules.ValidationType.toLowerCase() === "range") {
        if (form_key === "contactMobNo") {
          if (formInputs[form_key].trim().replaceAll("-", "").length < 10) {
            error_json = {
              ...error_json,
              [form_key]: validation_msg.invalid_contactMobNo,
            };
            isValid = false;
          }
        }
        // }
        // if (form_key === "contactMobNo") {
        //   if (formInputs[form_key].trim().replaceAll("-", "").length < 10) {
        //     error_json = {
        //       ...error_json,
        //       [form_key]: validation_msg.invalid_contactMobNo,
        //     };
        //     isValid = false;
        //   }
        // if (formInputs[form_key].length > 12 || formInputs[form_key].length < 12 ) {
        //   error_json = {
        //     ...error_json,
        //     [form_key]: validation_msg.incvalid_contactMobNo,
        //   };
        //   isValid = false;
        // }
        // }
      }
    });
    setFormInputsEr(error_json);
    return isValid;
  };

  const handleFormSave = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSubmitLoading(true);

      const res: any = await SaveContactDetails(
        {
          ...formInputs,
          contactMobNo: formInputs.countryCodeMob + " " + formInputs.contactMobNo,
          contactName: formInputs.firstName + formInputs.lastName,
        },
        Token
      );

      if (res.data !== undefined) {
        let Message = "Contact Inserted SuccessFully";
        if (res.data.statusCode === 200) {
          ToasterSuccess(Message);
          setSubmitLoading(false);
          dispatch(updateContactDataFetch(contact_data_reload_count + 1));
          handleClosePopup();
          if (res.data.data) {
            navigate("/contact/details", {
              state: { ContactID: res.data.data.contactID, ContactName: res.data.data.contactName },
            });
          }
        } else {
          setSubmitLoading(false);
        }
      } else {
        ToasterError(res.message);
        setSubmitLoading(false);
      }
    }
  };

  useEffect(() => {
    sethotelChangeLoading(true);
    if (!SelectedPropertyID.includes(",")) {
      setSelectedHotelOptions({ label: SelectedPropertyName, value: SelectedPropertyID });
      setFormInputs({
        ...formInputs,
        hotelId: SelectedPropertyID,
      });
      getAccountsDDTData(SelectedPropertyID);
    }
  }, []);

  return (
    <>
      <Modal
        show={modalShowContact}
        onHide={() => handleClosePopup()}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Contact Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingSelectGrid" label="Prefix">
                  <Form.Select
                    // value={formInputs.title}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        title: e.target.value ? e.target.value : "",
                      });
                    }}
                    // className={`form-control ${formInputsEr.title && "is-invalid"}`}
                  >
                    <option
                      selected={formInputs.title === "" || !formInputs.title ? true : false}
                      value=""
                    >
                      {" "}
                      Select Prefix{" "}
                    </option>
                    <option selected={formInputs.title === "Dr." ? true : false} value="Dr.">
                      {" "}
                      Dr.{" "}
                    </option>
                    <option selected={formInputs.title === "Mr." ? true : false} value="Mr.">
                      {" "}
                      Mr.{" "}
                    </option>
                    <option selected={formInputs.title === "Ms." ? true : false} value="Ms.">
                      {" "}
                      Ms.{" "}
                    </option>
                    <option selected={formInputs.title === "Mrs." ? true : false} value="Mrs.">
                      {" "}
                      Mrs.{" "}
                    </option>
                  </Form.Select>
                  {/* {formInputsEr.title && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.title}
                    </Form.Control.Feedback>
                  )} */}
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                <Form.Group>
                  <div className={`${formInputsEr.hotelId && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingSelectGrid" label="">
                      <Select
                        placeholder="Select Hotel"
                        className={`multiselect ${formInputsEr.hotelId && "is-invalid"}`}
                        options={hotelOptions}
                        onChange={(item: any) => {
                          sethotelChangeLoading(true);
                          setSelectedHotelOptions(item);
                          // let hotelIdsToAppend: any = [];
                          // items.forEach((itm: any) => {
                          //   hotelIdsToAppend = [...hotelIdsToAppend, itm.value]
                          // })
                          setFormInputs({
                            ...formInputs,
                            hotelId: item.value,
                            accountID: "",
                          });
                          setSelectedAccountList([]);
                          getAccountsDDTData(item.value);
                        }}
                        defaultValue={selectedHotelOptions}
                        value={selectedHotelOptions}
                        // isMulti={true}
                      />
                    </FloatingLabel>
                  </div>
                </Form.Group>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.hotelId && formInputsEr.hotelId}{" "}
                </small>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="First Name">
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={formInputs.firstName}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        firstName: e.target.value ? e.target.value : "",
                      });
                    }}
                    className={`form-control ${formInputsEr.firstName && "is-invalid"}`}
                  />
                  {formInputsEr.firstName && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.firstName}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Last Name">
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={formInputs.lastName}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        lastName: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <Form.Group>
                  <div className={`${formInputsEr.accountID && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingSelectGrid" label="">
                      <Select
                        placeholder="Select Account"
                        options={accountListData}
                        value={selectedAccountList}
                        defaultValue={selectedAccountList}
                        onChange={(item: any) => {
                          setSelectedAccountList(item);
                          setFormInputs({
                            ...formInputs,
                            accountID: item.value ? item.value : "",
                          });
                        }}
                      />
                      {/* {formInputsEr.accountID && (
                  <Form.Control.Feedback type="invalid">
                    {formInputsEr.accountID}
                  </Form.Control.Feedback>
                )} */}
                    </FloatingLabel>
                  </div>
                </Form.Group>
                <small style={{ color: "#dc3545" }}>
                  {" "}
                  {formInputsEr.accountID && formInputsEr.accountID}{" "}
                </small>
              </Col>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Reports to">
                  <Form.Control
                    type="text"
                    placeholder="Reports to"
                    value={formInputs.reportingTo}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        reportingTo: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Title">
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    value={formInputs.titleSecond}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        titleSecond: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Department">
                  <Form.Control
                    type="text"
                    placeholder="contactType"
                    value={formInputs.contactType}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        contactType: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                {/* <div className="DatePicker-col h-100 contact-information-modal">
                  <FloatingLabel controlId="floatingInput" label="Birthday" className="h-100">
                    <DatePicker
                      selected={formInputs.birthday && formInputs.birthday}
                      className="form-control h-100"
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          ["birthday"]: e,
                        });
                      }}
                      dateFormat={Cookies.get("SystemSettingDate")}
                      placeholder={Cookies.get("SystemSettingDate")}          
                      minDate={new Date("1900/01/01")}
                      maxDate={new Date("9999/12/31")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FloatingLabel>
                </div> */}

                <div className="DatePicker-col">
                  <FloatingLabel controlId="floatingInput" label="Birthday">
                    <DatePicker
                      minDate={new Date("1900/01/01")}
                      maxDate={new Date("9999/12/31")}
                      selected={formInputs.birthday && formInputs.birthday}
                      className={`form-control`}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          ["birthday"]: e,
                        });
                      }}
                      placeholderText={Cookies.get("SystemSettingDate")}
                      dateFormat={Cookies.get("SystemSettingDate")}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </FloatingLabel>
                </div>
              </Col>

              <Col xs={6} className="mb-3">
                <FloatingLabel controlId="floatingInput" label="Email">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={formInputs.email}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        email: e.target.value ? e.target.value : "",
                      });
                    }}
                    isInvalid={formInputsEr.email}
                  />
                  {formInputsEr.email && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.email}
                    </Form.Control.Feedback>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Row>
                  <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Code">
                      <Form.Control
                        type="text"
                        maxLength={6}
                        value={formInputs.countryCodeMob}
                        onChange={(event) =>
                          setFormInputs({
                            ...formInputs,
                            countryCodeMob: event.target.value,
                          })
                        }
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Mobile No.">
                      <PatternFormat
                        format="###-###-####"
                        value={formInputs.contactMobNo}
                        onChange={(e) => {
                          setFormInputs({
                            ...formInputs,
                            contactMobNo:
                              formInputs.countryCodeMob + " " + e.target.value
                                ? e.target.value
                                : "",
                          });
                        }}
                        className={`form-control ${formInputsEr.contactMobNo && "is-invalid"}`}
                      />
                      {formInputsEr.contactMobNo && (
                        <Form.Control.Feedback type="invalid">
                          {formInputsEr.contactMobNo}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <FloatingLabel controlId="floatingInput" label="Notes">
                  <Form.Control
                    as="textarea"
                    placeholder="Notes"
                    style={{ height: "100px" }}
                    value={formInputs.note}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        note: e.target.value ? e.target.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          {!submitLoading ? (
            <Button
              variant="primary"
              type="button"
              onClick={(e) => {
                handleFormSave(e);
              }}
            >
              <span className="me-2">
                <img src={WhiteSaveIcon} alt="" />
              </span>
              Save
            </Button>
          ) : (
            <Button className="btn btn-primary justify-content-center" type="button">
              <Spinner size="sm" animation="border" variant="light" />
            </Button>
          )}
          <Button
            onClick={() => {
              setFullModalShowContact(true);
            }}
            type="button"
            variant="primary"
          >
            <span className="me-2">
              <i className="fas fa-redo" aria-hidden="true"></i>
            </span>
            Go to full form
          </Button>
        </Modal.Footer>
        {fullModalShowContact === true && (
          <AddContactFullForm
            isData={isData}
            Token={Token}
            GetContactId={"00000000-0000-0000-0000-000000000000"}
            show={fullModalShowContact}
            contactdetails={() => {
              setModalShowContact(false);
            }}
            onHide={() => {
              setFullModalShowContact(false);
            }}
          />
        )}
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}
