import { useEffect, useState } from "react";
import { Container, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { Cookies } from "react-cookie-consent";
import { useDispatch, useSelector } from "react-redux";
import DarkMode from "../../../Component/DarkMode/Index";
import PageLoader from "../../../Component/Loader/PageLoader";
import { Mainlogout, getDateInFormat } from "../../../Services/CommonServices";
import { getSingleUserSelectedData } from "../../../Services/Setup&Config/UserSetupService";
import ChangePass from "./ChangePass";
import Accounts from "./SIdeTables/Accounts";
import Hotels from "./SIdeTables/Hotels";
import Overview from "./SIdeTables/Overview";
import { ProfileDetails } from "./SIdeTables/ProfileDetails";
import RecActions from "./SIdeTables/RecActions";

function ProfileSideTabs() {
  const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
  const CryptoJS: any = require("crypto-js");

  const dataSelector: any = useSelector((state: any) => state.session);
  const dispatch = useDispatch();
  const Token = dataSelector.access_Token;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const userId = dataSelector.auth_UserDetail.U_UserID;

  let initial = {
    username: "",
    FName: "",
    LName: "",
    FormFiles: null,
    image_url:
      dataSelector.auth_UserDetail.U_DisplayAvtar &&
      dataSelector.auth_UserDetail.U_DisplayAvtar !== ""
        ? dataSelector.auth_UserDetail.U_DisplayAvtar
        : null,
  };
  const [formInputs, setFormInputs] = useState<any>(initial);

  const [UserData, setUserData]: any = useState({
    IsData: true,
    data: [],
    lastLoginDate: "",
  });

  useEffect(() => {
    getSingleUserFullDetails();
  }, []);

  async function getSingleUserFullDetails() {
    let input: any = {
      keyID: userId,
    };
    setUserData({ IsData: true, data: [] });
    let res: any = await getSingleUserSelectedData(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setFormInputs({
            ...formInputs,
            username: res.data.data.userName,
            FName: res.data.data.fName,
            LName: res.data.data.lName,
            image_url: res.data.data.displayAvtar,
          });
          let temp = formatDate(new Date(res.data.data.lastLoginDate));
          setUserData({ IsData: false, data: res.data.data, lastLoginDate: temp });
        }
      }
    }
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
        "-"
      ) +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  const [key, setKey] = useState("ProfileDetails");

  const tabList = [
    {
      key: "1",
      tabTitle: "Profile Details",
      tabContent: (
        <Container fluid={true} className="p-0 position-relative">
          <div className="clearfix">
            <div className="btn-icon-grp position-static float-end mt-n6"></div>
          </div>
          <div className="activity-tab-table">
            <ProfileDetails />
          </div>
        </Container>
      ),
    },
    {
      key: "2",
      tabTitle: "Change Password",
      tabContent: (
        <Container fluid={true} className="p-0 position-relative">
          <div className="clearfix">
            <div className="btn-icon-grp position-static float-end mt-n6"></div>
          </div>
          <div className="activity-tab-table">
            <ChangePass />
          </div>
        </Container>
      ),
    },
    {
      key: "3",
      tabTitle: "Overview",
      tabContent: (
        <Container fluid={true} className="p-0 position-relative">
          <div className="clearfix">
            <div className="btn-icon-grp position-static float-end mt-n6"></div>
          </div>
          <div className="activity-tab-table">
            <Overview />
          </div>
        </Container>
      ),
    },
    {
      key: "4",
      tabTitle: "Recent Actions",
      tabContent: (
        <Container fluid={true} className="p-0 position-relative">
          <div className="clearfix">
            <div className="btn-icon-grp position-static float-end mt-n6"></div>
          </div>
          <div className="activity-tab-table">
            <RecActions />
          </div>
        </Container>
      ),
    },
    {
      key: "5",
      tabTitle: "Hotels",
      tabContent: (
        <Container fluid={true} className="p-0 position-relative">
          <div className="clearfix">
            <div className="btn-icon-grp position-static float-end mt-n6"></div>
          </div>
          <div className="activity-tab-table">
            <Hotels />
          </div>
        </Container>
      ),
    },
    {
      key: "6",
      tabTitle: "Accounts",
      tabContent: (
        <Container fluid={true} className="p-0 position-relative">
          <div className="clearfix">
            <div className="btn-icon-grp position-static float-end mt-n6"></div>
          </div>
          <div className="activity-tab-table">
            <Accounts />
          </div>
        </Container>
      ),
    },
  ];


  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <Row>
          <div
            className="col-md-12 align-items-center"
            style={{ justifyContent: "end", display: "flex" }}
          >
            <div style={{ marginRight: "10px" }} className="fw-semibold">
              Last Login : {UserData.lastLoginDate && getDateInFormat(UserData.lastLoginDate)}
            </div>
            <div style={{ marginRight: "10px" }}>
              {/* <DarkMode themeValue={true} /> */}
            </div>
            <div style={{ marginRight: "10px" }}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>Logout</Tooltip>}>
                <button className="icon-btn" onClick={Mainlogout} title="Log out">
                  <i className="fa-light fa-arrow-right-from-bracket"></i>
                </button>
              </OverlayTrigger>
            </div>
          </div>
        </Row>
      </div>
      <Container fluid="true">
        {UserData.IsData === true ? (
          <PageLoader />
        ) : (
          <div className="main-section">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
              className="tab-component mb-3"
            >
              <Tab eventKey="ProfileDetails" title="Profile Details">
                {key === "ProfileDetails" && (
                  <Container fluid={true} className="p-0 position-relative">
                    <div className="clearfix">
                      <div className="btn-icon-grp position-static float-end mt-n6"></div>
                    </div>
                    <div className="activity-tab-table">
                      <ProfileDetails />
                    </div>
                  </Container>
                )}
              </Tab>
              <Tab eventKey="ChangePassword" title="Change Password">
                {key === "ChangePassword" && (
                  <Container fluid={true} className="p-0 position-relative">
                    <div className="clearfix">
                      <div className="btn-icon-grp position-static float-end mt-n6"></div>
                    </div>
                    <div className="activity-tab-table">
                      <ChangePass />
                    </div>
                  </Container>
                )}
              </Tab>
              <Tab eventKey="Overview" title="Overview">
                {key === "Overview" && (
                  <Container fluid={true} className="p-0 position-relative">
                    <div className="clearfix">
                      <div className="btn-icon-grp position-static float-end mt-n6"></div>
                    </div>
                    <div className="activity-tab-table">
                      <Overview />
                    </div>
                  </Container>
                )}
              </Tab>
              <Tab eventKey="RecentActions" title="Recent Actions">
                {key === "RecentActions" && (
                  <Container fluid={true} className="p-0 position-relative">
                    <div className="clearfix">
                      <div className="btn-icon-grp position-static float-end mt-n6"></div>
                    </div>
                    <div className="activity-tab-table">
                      <RecActions />
                    </div>
                  </Container>
                )}
              </Tab>
              <Tab eventKey="Hotels" title="Hotels">
                {key === "Hotels" && (
                  <Container fluid={true} className="p-0 position-relative">
                    <div className="clearfix">
                      <div className="btn-icon-grp position-static float-end mt-n6"></div>
                    </div>
                    <div className="activity-tab-table">
                      <Hotels />
                    </div>
                  </Container>
                )}
              </Tab>
              <Tab eventKey="Accounts" title="Accounts">
                {key === "Accounts" && (
                  <Container fluid={true} className="p-0 position-relative">
                    <div className="clearfix">
                      <div className="btn-icon-grp position-static float-end mt-n6"></div>
                    </div>
                    <div className="activity-tab-table">
                      <Accounts />
                    </div>
                  </Container>
                )}
              </Tab>
            </Tabs>
          </div>
        )}
      </Container>
    </>
  );
}

export default ProfileSideTabs;
