import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Col, Container, Table } from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import moment from "moment";
import XLSX from "sheetjs-style";
import { AccountReportList } from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import PageLoader from "../../Component/Loader/PageLoader";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  getDateInDBFormatForExcell,
  getDateInFormat,
  getDateTimeInDBFormat,
  getSortDataByObjKey,
} from "../../Services/CommonServices";
import TableView from "../../Component/Table/Index";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import BlueLabel from "../../Component/Labels/BlueLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import { useDownloadExcel } from "react-export-table-to-excel";

const AccountReportModal = ({ ReportInput, showReport, setShowReport }) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState<any>([]);

  const fetchAccountGridList = async () => {
    let input = {
      clientID: ReportInput.clientID,
      accountStatus: ReportInput.accountStatus,
      accountType: ReportInput.accountType,
      propertyIDs: ReportInput.propertyIDs,
      stayPattern: ReportInput.stayPattern,
      userIDs: ReportInput.userIDs,
      createdFrom: ReportInput.createdFrom,
      createdTo: ReportInput.createdTo,
      modifiedFrom: ReportInput.modifiedFrom,
      modifiedTo: ReportInput.modifiedTo,
      searchText: ReportInput.searchText,
      nameStartWith: ReportInput.nameStartWith,
      source: ReportInput.source,
    };

    setPageLoading(true);
    let res = await AccountReportList(input, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstData !== undefined &&
            res.data.data.lstData &&
            res.data.data.lstData.length
          ) {
            setData(res.data.data.lstData);
            setPageLoading(false);
          } else {
            setData([]);

            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      } else {
        setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      setLoading(false);
      ToasterError(res.data.message);
    }
  };

  const AccountGridColumns = React.useMemo(
    () => [
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "Owner",
        accessor: "owner",
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Top",
        accessor: "top",
      },
      {
        Header: "Target",
        accessor: "target",
      },
      {
        Header: "Growth",
        accessor: "growth",
      },
      {
        Header: "Anticipated Revenue(C.Y.)",
        accessor: "anticipatedRevenue",
      },
      {
        Header: "Last Activity",
        accessor: "lastActivity",
      },
    ],
    []
  );

  let AccountGridData: any = [];

  data !== undefined &&
    data !== null &&
    data.map((item: any) => {
      AccountGridData.push({
        className: {
          status: "centerAlignClass text-center",
          top: "centerAlignClass text-center",
          target: "centerAlignClass text-center",
          growth: "centerAlignClass text-center",
          anticipatedRevenue: "rightAlignClass text-end",
          lastActivity: "rightAlignClass text-end",
        },
        controlRender: {
          //   accountName: (
          //     <div
          //       role="button"
          //       tabIndex={-1}
          //       onClick={() => {
          //         if (page_rights.is_view) {
          //           accountDetailsView(item);
          //         } else {
          //           CommonService.unauthorizedAction();
          //         }
          //       }}
          //       onKeyDown={() => {
          //         if (page_rights.is_view) {
          //           accountDetailsView(item);
          //         } else {
          //           CommonService.unauthorizedAction();
          //         }
          //       }}
          //     >
          //       {item.AccountName}
          //     </div>
          //   ),
          status:
            item.Status_Term !== "" &&
            (item.Status_Term === "Active" ? (
              <GreenLabel successLabel={item.Status_Term} />
            ) : (
              <RedLabel dangerLabel={item.Status_Term} />
            )),

          top:
            item.IsTop === true ? (
              //   <button onClick={(e) => handleAccountSwitch(false, item, "Top")}>
              <GreenLabel successLabel="Yes" />
            ) : (
              //   </button>
              //   <button onClick={(e) => handleAccountSwitch(true, item, "Top")}>
              <RedLabel dangerLabel="No" />
              //   </button>
            ),
          target:
            item.IsTargetAccount === true ? (
              //   <button onClick={(e) => handleAccountSwitch(false, item, "Target")}>
              <GreenLabel successLabel="Yes" />
            ) : (
              //   </button>
              //   <button onClick={(e) => handleAccountSwitch(true, item, "Target")}>
              <RedLabel dangerLabel="No" />
              //   </button>
            ),
          growth:
            item.IsTargetGrowthAccount === true ? (
              //   <button onClick={(e) => handleAccountSwitch(false, item, "Growth")}>
              <GreenLabel successLabel="Yes" />
            ) : (
              //   </button>
              //   <button onClick={(e) => handleAccountSwitch(true, item, "Growth")}>
              <RedLabel dangerLabel="No" />
              //   </button>
            ),
          lastActivity: getDateInFormat(item.LastActivityDate),
        },

        source: item.Source,
        code: item.AccountCode,
        accountName: item.AccountName,
        owner: item.OwnerName,
        status: item.Status_Term,
        top: item.IsTop ? "Yes" : "No",
        target: item.IsTargetAccount ? "Yes" : "No",
        growth: item.IsTargetGrowthAccount ? "Yes" : "No",

        anticipatedRevenue: "$" + item.AnticipatedRev !== undefined && item.AnticipatedRev,
        lastActivity: getDateTimeInDBFormat(item.LastActivityDate),
      });
    });

  useEffect(() => {
    fetchAccountGridList();
  }, []);

  const handleClosePopup = () => {
    setShowReport(false);
  };

  // const tableRef = useRef(null);

  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: "Account Report" + ReportInput.createdFrom + ReportInput.createdTo,
  //   sheet: "AccountReport",
  // });

  // let AccountTableExport: any = () => {
  //   return (
  //     <>
  //       <thead>
  //         <tr>
  //           <th
  //             colSpan={4}
  //             style={{ background: "#417FF9", color: "#ffffff", fontSize: "11", fontWeight: "600" }}
  //           >
  //             Account Report Grid - (
  //             {ReportInput && ReportInput.createdFrom + " " + ReportInput.createdTo})
  //             {ReportInput.accountStatus ? "- (" + ReportInput.accountStatus + ") " : ""}
  //             {ReportInput.source ? "- (" + ReportInput.source + ")" : ""}
  //           </th>
  //         </tr>
  //         <tr></tr>
  //         <tr>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Code
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Account Name
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Owner
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Source
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Status
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Top
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Target
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Growth
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Anticipated Revenue(C.Y.)
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Last Activity
  //           </th>
  //         </tr>
  //       </thead>

  //       <tbody>
  //         {data.map((item: any, idx) => {
  //           return (
  //             <tr key={idx}>
  //               <td>{item.AccountCode}</td>
  //               <td>{item.AccountName}</td>
  //               <td>{item.OwnerName}</td>
  //               <td>{item.Source}</td>
  //               <td>{item.Status_Term}</td>
  //               <td style={{ textAlign: "center" }}>{item.IsTop ? "Yes" : "No"}</td>
  //               <td style={{ textAlign: "center" }}>{item.IsTargetAccount ? "Yes" : "No"}</td>
  //               <td style={{ textAlign: "center" }}>{item.IsTargetGrowthAccount ? "Yes" : "No"}</td>
  //               <td style={{ textAlign: "right" }}>{item.AnticipatedRev}</td>
  //               <td style={{ textAlign: "right" }}>{getDateInFormat(item.LastActivityDate)}</td>
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </>
  //   );
  // };

  async function getFilteredExportData(newArray) {
    let localFilters = JSON.parse(localStorage.getItem("accountReportFilter"));
    let mainTableData: any = [];
    newArray !== undefined &&
      newArray !== null &&
      newArray.map((item: any) => {
        mainTableData.push({
          code: item.code,
          accountName: item.accountName,
          owner: item.owner,
          source: item.source,
          status: item.status,
          top: item.top,
          target: item.target,
          growth: item.growth,
          anticipatedRevenue: item.anticipatedRevenue,
          lastActivity: item.lastActivity,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            Code: item.code,
            AccountName: item.accountName,
            Owner: item.owner,
            Source: item.source,
            Status: item.status,
            Top: item.top,
            Target: item.target,
            Growth: item.growth,
            AnticipatedRevenue: item.anticipatedRevenue,
            LastActivity: item.lastActivity,
          });
        });

        return ascDataBind;
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            Code: item.code,
            AccountName: item.accountName,
            Owner: item.owner,
            Source: item.source,
            Status: item.status,
            Top: item.top,
            Target: item.target,
            Growth: item.growth,
            AnticipatedRevenue: item.anticipatedRevenue,
            LastActivity: item.lastActivity,
          });
        });

        return descDataBind;
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            Code: item.code,
            AccountName: item.accountName,
            Owner: item.owner,
            Source: item.source,
            Status: item.status,
            Top: item.top,
            Target: item.target,
            Growth: item.growth,
            AnticipatedRevenue: item.anticipatedRevenue,
            LastActivity: item.lastActivity,
          });
        });

        return normalDataBind;
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          Code: item.code,
          AccountName: item.accountName,
          Owner: item.owner,
          Source: item.source,
          Status: item.status,
          Top: item.top,
          Target: item.target,
          Growth: item.growth,
          AnticipatedRevenue: item.anticipatedRevenue,
          LastActivity: item.lastActivity,
        });
      });
      return normalInitDataBind;
    }
  }

  const exportData = async () => {
    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    // Top Main Header : ---------------------------
    let ws = XLSX.utils.json_to_sheet([], {
      header: [
        `Account Report Grid - (
               ${ReportInput && ReportInput.createdFrom + " " + ReportInput.createdTo})
               ${ReportInput.accountStatus ? "- (" + ReportInput.accountStatus + ") " : ""}
               ${ReportInput.source ? "- (" + ReportInput.source + ")" : ""}`,
      ],
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }];
    ws["!merges"] = merge;

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    for (var R = rangeTotalAccounts.s.r; R <= rangeTotalAccounts.e.r; ++R) {
      for (var C = rangeTotalAccounts.s.c; C <= rangeTotalAccounts.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: C === 1 || C === 2 ? "right" : "left",
              },
            };
          }
        }
      }
    }
    const newArray = data.map(({ AccountID, ...keepAttrs }) => keepAttrs);

    let newArrayJsonToExportInExcell: any = [];
    data.length &&
      data.map((item: any) => {
        newArrayJsonToExportInExcell.push({
          code: item.AccountCode,
          accountName: item.AccountName ? item.AccountName.replace("#", " ") : "",
          owner: item.OwnerName,
          source: item.Source,
          status: item.Status_Term,
          top: item.IsTop ? "Yes" : "No",
          target: item.IsTargetAccount ? "Yes" : "No",
          growth: item.IsTargetGrowthAccount ? "Yes" : "No",
          anticipatedRevenue: "$" + item.AnticipatedRev !== undefined && item.AnticipatedRev,
          lastActivity: getDateInDBFormatForExcell(item.LastActivityDate),
        });
      });

    // ------------------- Sort Data By Filter :
    let newArraySortedData = await getFilteredExportData(newArrayJsonToExportInExcell);
    // ------------------- Sort Data By Filter End:

    XLSX.utils.sheet_add_aoa(ws, [Object.keys(newArraySortedData[0])], {
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 3),
    });
    XLSX.utils.sheet_add_json(ws, newArraySortedData, {
      header: Object.keys(newArraySortedData[0]),
      skipHeader: true,
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 4),
    });

    const rowDataCount: any = XLSX.utils.decode_range(ws["!ref"] ?? "");

    for (var R = rowCount + 2; R <= rowDataCount.e.r; ++R) {
      for (var C = rowDataCount.s.c; C <= rowDataCount.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (typeof ws[cell_ref] !== "undefined") {
          if (rowCount + 2 === R) {
            ws[cell_ref].s = headStylesCell;
          }
          // else if(R ===  2){
          //   ws[cell_ref].s = headStylesCell
          // }
          else {
            let checkForRightArray = [8, 9];
            let checkForCenterArray = [4, 5, 6, 7];
            if (checkForRightArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "right",
                },
              };
            } else if (checkForCenterArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "center",
                },
              };
            }
          }
        }
      }
    }

    let cols: any = [];

    Object.keys(newArraySortedData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    let filename = `AccountReport.xlsx`;

    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename);
  };

  return (
    <>
      <Modal
        show={showReport}
        onHide={() => {
          handleClosePopup();
        }}
        fullscreen
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Account Grid Report
            {ReportInput.createdFrom &&
              "- (" +
                moment(ReportInput.createdFrom).format("MM/DD/YYYY") +
                " - " +
                moment(ReportInput.createdTo).format("MM/DD/YYYY") +
                ")"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {!pageLoading ? (
              <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
                <TableView
                  isData={loading}
                  columnsData={AccountGridColumns}
                  tableData={AccountGridData}
                />
              </Col>
            ) : (
              <PageLoader />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={handleClosePopup}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            disabled={data.length && !loading ? false : true}
            onClick={(e) => {
              e.preventDefault();
              if (data.length && !loading) {
                exportData();
              }
            }}
            // onClick={onDownload}
            type="button"
            variant="primary"
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Table
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <AccountTableExport />
      </Table> */}
    </>
  );
};

export default AccountReportModal;
