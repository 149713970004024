import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import { updateAccountDataFetch } from "../../Redux/actions";
import { ToasterError, ToasterSuccess } from "../../Services/CommonServices";
import { SaveAccountListInfo, getEmailAssignToDropdownList } from "../../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import EditAccountInfo from "../../Views/Accounts/EditAccountDetails/EditAccountInfo";
import PageLoader from "../../Component/Loader/PageLoader";

export default function AddAccountModal({ modalShow, setModalShow }) {
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  let navigate = useNavigate();
  const [fullAccountModalShow, setFullAccountModalShow] = useState(false);
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const account_data_reload_count = dataSelector.account_data_reload_count;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const dispatch = useDispatch();
  let initialHotelToSet: any = [];

  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.forEach((item: any) => {
      initialHotelToSet = [
        ...initialHotelToSet,
        {
          label: item.HotelCode + " " + item.PropertyName,
          value: item.PropertyID,
        },
      ];
    });

  const [accountTypeCheck, setAccountTypeCheck] = useState([]);

  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [submitLoading, setSubmitLoading] = useState(false);

  const [hotelOptions, setHotelOptions] = useState(initialHotelToSet);
  const [selectedHotelOptions, setSelectedHotelOptions]: any = useState([]);

  const [selectedStatus, setSelectedStatus] = useState([]);

  const [accountOwners, setAccountOwner]: any = useState([]);

  const [StatusData, setStatusData]: any = React.useState([]);

  let initialFormInputs = {
    clientID: clientID,
    ownerID: "",
    accountCode: "",
    accountName: "",
    comment: "",
    website: "",
    accountType_Term: "Active",
    genCountryCode: "",
    contactNo: "",
    isBlock: null,
    isTop: false,
    isTargetAccount: false,
    isTargetGrowthAccount: false,
    lstAccountProperty: [],
    lstAccountType: [],
    phoneExt: "",
    //Unused Below
    sortCode: "",
    territeryID: "00000000-0000-0000-0000-000000000000",
    companyEmail: "",
    industryType_Term: "",
    subType_Term: "",
    ratings_Term: "",
    employees: "",
    numberOfRooms: 0,
    refAcctID: "00000000-0000-0000-0000-000000000000",
    contactName: "",
    contactEmail: "",
    addressStreet1: "",
    addressStreet2: "",
    area: "",
    city: "",
    zipCode: "",
    state: "",
    country: "",
    revenueManagerID: "00000000-0000-0000-0000-000000000000",
    tickerSymbol: "",
    ownership_Term: "",
    billingStreet1: "",
    billingStreet2: "",
    billingArea: "",
    billingCity: "",
    billingZip: "",
    billingState: "",
    billingCountry: "",
    accountDetail: "",
    lastContactedBy: "00000000-0000-0000-0000-000000000000",
    lastContactedComments: "",
    citySpend: 0,
    hotelllingSpend: 0,
    hotelHistorySpend: 0,
    totalOffices: 0,
    // propertyID: "00000000-0000-0000-0000-000000000000",
    companyDo: "",
    travelerAndDept: "",
    feederCities: "",
    gds: "",
    pseudoCityCode: "",
    iata: "",
    rateAccessCode: "",
    stayPattern: "",
    currentRate: 0,
    anticipatedRev: "",
    rateCode: 0,
    location: "",
    clientTargetRate: 0,
    features: "",
    transportationNeeds: "",
    other: "",
    currentHotelUsed: "",
    reservationMode: "",
    headquarterLocation: "",
    bookingURL: "",
    vanityBookingURL: "",
    compSetHotel1: "",
    compSetHotel1rate: 0,
    compSetHotel2: "",
    compSetHotel2rate: 0,
    rfpSource: "",
    phone: "",
    lostReason: "",
    isCommissionable: true,
    sourceField: "",
    travelAgencyName: "",
    travelAgencyAddress: "",
    travelAgencyContact: "",
    travelAgencyCity: "",
    travelAgencyState: "",
    travelAgencyZip: "",
    travelAgencyEmail: "",
    agencyExt: "",
    companyExt: "",
    preferredBilling: "",
    directBookingLink: "",
    headPhoneNo: "",
    headCountryCode: "",
    headExt: "",
  };

  const [formInputs, setFormInputs] = useState<any>(initialFormInputs);

  let optionsStatus: any = [];

  StatusData.length > 0 &&
    StatusData.map((item: any) => {
      optionsStatus.push({
        label: item.TermCode,
        value: item.TermCode,
      });
    });

  let optionsAccountOwner: any = [];
  accountOwners !== undefined &&
    accountOwners !== null &&
    accountOwners.length > 0 &&
    accountOwners.map((item: any) => {
      optionsAccountOwner.push({
        label: item.UserName,
        value: item.UserID,
      });
    });

  let initialFormInputEr = {
    contactNo: "",
    ownerID: "",
    accountName: "",
    accountType_Term: "",
    lstAccountProperty: "",
    lstAccountType: "",
  };

  const [formInputsEr, setFormInputsEr] = useState<any>(initialFormInputEr);

  const validation_msg = {
    ownerID: "This field is required.",
    accountName: "This field is required.",
    accountType_Term: "This field is required.",
    lstAccountProperty: "This field is required.",
    lstAccountType: "This field is required.",
    contactNo: "This field is required.",
    invalid_contactNo: "Enter Valid Contact No.",
  };

  const handleClosePopup = () => {
    setFormInputs(initialFormInputs);
    setFormInputsEr(initialFormInputEr);
    setAccountTypeCheck([]);
    setSelectedHotelOptions([]);
    setSelectedStatus([]);
    setAccountOwner([]);
    setModalShow(false);
  };

  const getAccountOwnerDropdown = async (PropID) => {
    let input = {
      clientID: clientID,
      propertyIDs: null,
    };
    input.propertyIDs = PropID;

    // if (userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
    //   input.propertyIDs = null;
    // }

    let res: any = await getEmailAssignToDropdownList(input, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined && res.data.data !== "") {
        setAccountOwner(res.data.data.dataList1);

        let userIDRes = res.data.data.dataList1.filter((item) => item.UserID === userId);
        let lstAccProToAppend: any = [];
        lstAccProToAppend = [
          ...lstAccProToAppend,
          {
            propertyID: PropID,
            isTop: true,
            // isTop: formInputs.isTop === "false" ? false : true,
          },
        ];

        setFormInputs({
          ...formInputs,
          lstAccountProperty: lstAccProToAppend,
          ownerID: userIDRes.length && userIDRes[0].UserID,
        });
      }
    }
    
  };

  const accountTypeDropdownList = async () => {
    let input = {
      category: "Account Type",
      clientID: clientID,
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
        setAccountTypeCheck(res.data.data.dataList1);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    let lenCheck = ["lstAccountType", "lstAccountProperty"];
    let removeKey = ["contactNo"];
    let error_json = {};
    Object.keys(formInputsEr).forEach((form_key) => {
      error_json = {
        ...error_json,
        [form_key]: "",
      };
    });
    Object.keys(formInputsEr).forEach((form_key) => {
      if (lenCheck.includes(form_key)) {
        if (!formInputs[form_key].length) {
          error_json = {
            ...error_json,
            [form_key]: validation_msg[form_key],
          };
          isValid = false;
        }
      } else {
        if (
          (formInputs[form_key] === "" || !formInputs[form_key]) &&
          !removeKey.includes(form_key)
        ) {
          error_json = {
            ...error_json,
            [form_key]: validation_msg[form_key],
          };
          isValid = false;
        } else {
          if (form_key === "contactNo") {
            if (
              formInputs[form_key] !== "" &&
              formInputs[form_key].trim().replaceAll("-", "").length < 10
            ) {
              error_json = {
                ...error_json,
                [form_key]: validation_msg.invalid_contactNo,
              };
              isValid = false;
            }
          }
        }
      }
    });
    setFormInputsEr(error_json);
    return isValid;
  };

  const handleFormSave = async (e) => {
    
    e.preventDefault();
    if (validateForm()) {
      setSubmitLoading(true);
      let res: any = await SaveAccountListInfo(
        { ...formInputs, contactNo: formInputs.genCountryCode + " " + formInputs.contactNo },
        Token
      );
      if (res.data !== undefined) {
        let Message = "Accounts Inserted SuccessFully";
        if (res.data.statusCode === 200) {
          
          ToasterSuccess(Message);
          setSubmitLoading(false);
          dispatch(updateAccountDataFetch(account_data_reload_count + 1));

          // window.location.reload();
          handleClosePopup();
          if(res.data.data){
            navigate("/accounts-view", { state: { AccountID: res.data.data.accountID, AccountName: res.data.data.accountName ,InAfterSaveDetail:true} });
            }
        } else if (res.data.statusCode === 208) {
          ToasterError(res.data.message);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } else {
        setSubmitLoading(false);
        ToasterError(res.message);
      }
    }
  };

  const fetchPreData = async () => {
    accountTypeDropdownList();
  };

  useEffect(() => {
    sethotelChangeLoading(true)
    if (!SelectedPropertyID.includes(",")) {
      
      setSelectedHotelOptions({ label: SelectedPropertyName, value: SelectedPropertyID });
      getAccountOwnerDropdown(SelectedPropertyID);
    }

    getAllDataOfProjectTerm();
    // getStatusAndPriorityByProjectTerm();
    // fetchPreData();
  }, []);

  async function getAllDataOfProjectTerm() {
    let input: any = {
      clientID: clientID, // "00000000-0000-0000-0000-000000000000",
      category: "Account Status, Account Type",
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Account Status");
          setStatusData(tempStatus);
          let tempAccountType =
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.filter((item) => item.Category === "Account Type");
          setAccountTypeCheck(tempAccountType);
        }
      }
    }
    sethotelChangeLoading(false)
  }

  return (
    <>
    <Modal
      show={modalShow}
      onHide={() => handleClosePopup()}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      className="quickAdd-popup"
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Accounts Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Account Code">
                <Form.Control
                  type="text"
                  placeholder="Account Code"
                  value={formInputs.accountCode}
                  disabled={true}
                  onChange={() => {
                    // setFormInputs({
                    //   ...formInputs,
                    //   accountCode: e.target.value ? e.target.value : "",
                    // })
                  }}
                />
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Account Name">
                <Form.Control
                  type="text"
                  placeholder="Account Name"
                  value={formInputs.accountName}
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      accountName: e.target.value ? e.target.value : "",
                    })
                  }
                  isInvalid={formInputsEr.accountName}
                />
                {formInputsEr.accountName && (
                  <Form.Control.Feedback type="invalid">
                    {formInputsEr.accountName}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <Form.Group>
                <div className={`${formInputsEr.lstAccountProperty && "red-border-masking"}`}>
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Hotels"
                      // className={`multiselect ${formInputsEr.lstAccountProperty && "is-invalid"}`}
                      options={hotelOptions}
                      onChange={(items: any) => {
                        sethotelChangeLoading(true)
                        setSelectedHotelOptions(items);
                        let lstAccProToAppend: any = [];
                        // items.forEach((itm: any) => {
                        lstAccProToAppend = [
                          ...lstAccProToAppend,
                          {
                            propertyID: items.value,
                            isTop: true,
                            // isTop: formInputs.isTop === "false" ? false : true,
                          },
                        ];
                        // })
                        setFormInputs({
                          ...formInputs,
                          lstAccountProperty: lstAccProToAppend,
                          ownerID: "",
                        });
                        getAccountOwnerDropdown(items.value.toString());
                      }}
                      value={selectedHotelOptions}
                      defaultValue={selectedHotelOptions}
                      // isMulti={true}
                    />
                    {/* {formInputsEr.lstAccountProperty && (
                      <Form.Control.Feedback type="invalid">
                        {formInputsEr.lstAccountProperty}
                      </Form.Control.Feedback>
                    )} */}
                  </FloatingLabel>
                </div>
              </Form.Group>
              <small style={{ color: "#dc3545" }}>
                {" "}
                {formInputsEr.lstAccountProperty && formInputsEr.lstAccountProperty}
              </small>
            </Col>
            <Col xs={6} className="mb-3">
              {/* <FloatingLabel controlId="floatingSelectGrid" label="Account Owner">
                <Form.Select
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      ownerID: e.target.value ? e.target.value : "",
                    });
                  }}
                  className={`form-control ${formInputsEr.ownerID && "is-invalid"}`}
                >
                  <option
                    value=""
                    selected={formInputs.ownerID == "" || !formInputs.ownerID ? true : false}
                  >
                    Select Account Owner
                  </option>

                  {accountOwners.length > 0 &&
                    accountOwners.map((item: any, index) => (
                      <option
                        id={formInputs.ownerID}
                        selected={formInputs.ownerID == item.UserID ? true : false}
                        key={index}
                        value={item.UserID}
                      >
                        {item.UserName}
                      </option>
                    ))}
                </Form.Select>
                {formInputsEr.ownerID && (
                  <Form.Control.Feedback type="invalid">
                    {formInputsEr.ownerID}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel> */}

              <div className={`${formInputsEr.ownerID && "red-border-masking"}`}>
                <FloatingLabel controlId="floatingInput" label="">
                  <Select
                    placeholder="Account Owner"
                    // className={`multiselect ${AccountField.errors.lstAccPro && "is-invalid"}`}
                    options={optionsAccountOwner}
                    value={optionsAccountOwner.filter(
                      (obj) => formInputs.ownerID && formInputs.ownerID.includes(obj.value)
                    )}
                    // onChange={(e: any) =>
                    //   setAccDetails({
                    //     ...AccDetails,
                    //     ["ownerID"]: e.value,
                    //   })
                    // }
                    onChange={(e: any) => {
                      setFormInputs({
                        ...formInputs,
                        ownerID: e.value ? e.value : "",
                      });
                    }}
                  />
                </FloatingLabel>
              </div>
              <small style={{ color: "#dc3545" }}>
                {" "}
                {formInputsEr.ownerID && formInputsEr.ownerID}{" "}
              </small>
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <label className="for-radio-header" htmlFor="AccountType">
                Account Type
              </label>
              <div className={`${formInputsEr.lstAccountType && "red-border-masking"}`}>
                {["checkbox"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    {accountTypeCheck.map((item: any, index) => {
                      return (
                        <Form.Check
                          inline
                          key={index}
                          checked={formInputs.lstAccountType.includes(item.Term) ? true : false}
                          label={item.Term}
                          name="group1"
                          id={"inline-rfp-Start" + index} // Solution
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFormInputs({
                                ...formInputs,
                                lstAccountType: [...formInputs.lstAccountType, item.Term],
                              });
                            } else {
                              let newArray: any = [];
                              formInputs.lstAccountType.forEach((array_itm: any) => {
                                if (array_itm !== item.Term) {
                                  newArray = [...newArray, array_itm];
                                }
                              });
                              setFormInputs({
                                ...formInputs,
                                lstAccountType: newArray,
                              });
                            }
                          }}
                        />
                      );
                    })}
                    {formInputsEr.lstAccountType && (
                      <Form.Control.Feedback type="invalid">
                        {formInputsEr.lstAccountType}
                      </Form.Control.Feedback>
                    )}
                  </div>
                ))}
              </div>
              <small style={{ color: "#dc3545" }}>
                {" "}
                {formInputsEr.lstAccountType && formInputsEr.lstAccountType}{" "}
              </small>
            </Col>
            <Col xs={6}>
           <br />
              {["checkbox"].map((type: any) => (
                <div key={`inline-${type}`} className="option-field">
                  <Form.Check
                    inline
                    label="Top Account"
                    type={type}
                    id="Top Account"
                    // checked={formInputs.isTop === "false" ? false : true}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        isTop: e.target.checked
                      });
                    }}
                  />

                  <Form.Check
                    inline
                    label="Target Account"
                    type={type}
                    id={`inline-${type}-Start` + 2}
                    // id={"IsTargetAccount"}
                    // checked={formInputs.isTargetAccount === "false" ? false : true}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        isTargetAccount: e.target.checked
                      });
                    }}
                  />
                  <Form.Check
                    inline
                    label="Top Growth Account"
                    type={type}
                    id={`inline-${type}-Start` + 3}
                    // id={"isTargetGrowthAccount"}
                    // checked={formInputs.isTargetGrowthAccount === "false" ? false : true}
                    onChange={(e) => {
                      setFormInputs({
                        ...formInputs,
                        isTargetGrowthAccount: e.target.checked
                      });
                    }}
                  />
                </div>
              ))}
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-3">
              <div className={`${formInputsEr.accountType_Term && "red-border-masking"}`}>
                <FloatingLabel controlId="floatingSelectGrid" label="">
                  <Select
                    placeholder="Select Status"
                    // value={selectedStatus}
                    value={optionsStatus.filter(
                      (obj) =>
                        formInputs.accountType_Term &&
                        formInputs.accountType_Term.includes(obj.label)
                    )}
                    defaultValue={selectedStatus}
                    className={`multiselect ${formInputsEr.accountType_Term && "is-invalid"}`}
                    options={optionsStatus}
                    onChange={(item: any) => {
                      setFormInputs({
                        ...formInputs,
                        accountType_Term: item.value,
                      });
                      setSelectedStatus(item);
                    }}
                  />
                  {/* {formInputsEr.isTop && (
                    <Form.Control.Feedback type="invalid">
                      {formInputsEr.isTop}
                    </Form.Control.Feedback>
                  )} */}
                </FloatingLabel>
              </div>
              <small style={{ color: "#dc3545" }}>
                {" "}
                {formInputsEr.accountType_Term && formInputsEr.accountType_Term}{" "}
              </small>
            </Col>

            <Col xs={6} className="mb-3">
              <Row>
                <Col xs={3}>
                  <FloatingLabel controlId="floatingInput" label="Code">
                    <Form.Control
                      type="text"
                      placeholder="Country Code"
                      maxLength={6}
                      value={formInputs.genCountryCode}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          genCountryCode: e.target.value ? e.target.value : "",
                        });
                      }}
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6}>
                  {/* <FloatingLabel controlId="floatingInput" label="Phone">
                    <Form.Control
                      type="number"
onWheel={(event: any) => { event.target.blur() }}
                    onKeyDown={(e :any )=>{                       
                                             if(e.keyCode == '38' || e.keyCode == '40'){
                                                 e.stopPropagation()
                                                 e.preventDefault()
                                             }
                                         }}
                      placeholder="Office Phone"
                      value={formInputs.contactNo}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          contactNo:
                            formInputs.genCountryCode + " " + e.target.value ? e.target.value : "",
                        });
                      }}
                    />
                  </FloatingLabel> */}

                  {/*  */}
                  <div className={`${formInputsEr.contactNo && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingInput" label="Phone">
                      <PatternFormat
                        className="form-control"
                        displayType="input"
                        // value={SuspectSave.telephoneNo}
                        format="###-###-####"
                        // onChange={(e) =>
                        //   setSuspectSave({
                        //     ...SuspectSave,
                        //     ["telephoneNo"]: e.target.value,
                        //   })
                        // }
                        value={formInputs.contactNo}
                        onChange={(e) => {
                          setFormInputs({
                            ...formInputs,
                            contactNo:
                              formInputs.genCountryCode + " " + e.target.value
                                ? e.target.value
                                : "",
                          });
                        }}
                      />
                    </FloatingLabel>
                  </div>
                  <small style={{ color: "#dc3545" }}>
                    {" "}
                    {formInputsEr.contactNo && formInputsEr.contactNo}{" "}
                  </small>
                </Col>
                <Col xs={3}>
                  <FloatingLabel controlId="floatingInput" label="Ext">
                    <Form.Control
                      value={formInputs.phoneExt}
                      onChange={(e) => {
                        setFormInputs({
                          ...formInputs,
                          phoneExt: e.target.value ? e.target.value : "",
                        });
                      }}
                      type="text"
                      placeholder="Ext"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Websites">
                <Form.Control
                  type="text"
                  placeholder="Websites"
                  value={formInputs.website}
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      website: e.target.value ? e.target.value : "",
                    });
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Notes">
                <Form.Control
                  as="textarea"
                  placeholder="comment"
                  style={{ height: "100px" }}
                  value={formInputs.comment}
                  onChange={(e) => {
                    setFormInputs({
                      ...formInputs,
                      comment: e.target.value ? e.target.value : "",
                    });
                  }}
                />
              </FloatingLabel>
            </Col>
          </Row>
        
        </>
      </Modal.Body>
     
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClosePopup}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        {!submitLoading ? (
          <Button
            variant="primary"
            type="button"
            onClick={(e) => {
              handleFormSave(e);
            }}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        ) : (
          <Button className="btn btn-primary justify-content-center" type="button">
            <Spinner size="sm" animation="border" variant="light" />
          </Button>
        )}
        <Button
          onClick={() => {
            setFullAccountModalShow(true);
          }}
          type="button"
          variant="primary"
        >
          <span className="me-2">
            <i className="fas fa-redo" aria-hidden="true"></i>
          </span>
          Go to full form
        </Button>
      </Modal.Footer>

      {fullAccountModalShow && (
        <EditAccountInfo
          AccountID={""}
          getAccountListData={() => {
            setModalShow(false);
          }}
          show={fullAccountModalShow}
          onHide={() => setFullAccountModalShow(false)}
        />
      )}
    </Modal>

{hotelChangeLoading ? (
          <div className="calender-loader-main">
            <PageLoader />
          </div>
        ) : (
          ""
        )}
</>
);
}
