import { Col, FloatingLabel, Form, Row } from "react-bootstrap";

export default function PropertySalesGroupSettings({
  prodInputsEr,
  prodInputs,
  setProdInputs,
  htl_id,
}) {
  return (
    <Row>
      <h6>{prodInputs[htl_id].hotelName}</h6>
      {/* <Col xs={6} className="mb-3"> */}
      {/* <div className={`${prodInputsEr[htl_id].roleID && "red-border-masking"}`}> */}
      {/* <FloatingLabel controlId="floatingSelectGrid" label=""> */}
      {/* <Select
                        placeholder="Select Role"
                        options={rolesOptions}
                        value={rolesOptions.filter((obj: any) => obj.value === prodInputs[htl_id].roleID)[0]}
                        onChange={(itm: any) => {
                            // setSelectedRole(itm)
                            setProdInputs({
                                ...prodInputs,
                                [htl_id]: {
                                    ...prodInputs[htl_id],
                                    roleID: itm.value
                                }

                            })
                        }
                        }
                    /> */}
      {/* </FloatingLabel> */}
      {/* </div> */}
      {/* <small style={{ color: "#dc3545" }} >  {prodInputsEr[htl_id].roleID && prodInputsEr[htl_id].roleID} </small> */}
      {/* </Col> */}
      <Col xs={6} className="mb-3">
        <div className="position-relative">
          <FloatingLabel controlId="floatingInput" label="Client Visit">
            <Form.Control
              type="number"
              onWheel={(event: any) => {
                event.target.blur();
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == "38" || e.keyCode == "40") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              placeholder="Client Visit"
              value={prodInputs[htl_id].clientVisit ? prodInputs[htl_id].clientVisit : ""}
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setProdInputs({
                  ...prodInputs,
                  [htl_id]: {
                    ...prodInputs[htl_id],
                    clientVisit: e.target.value ? e.target.value : "",
                  },
                });
              }}
              className={`form-control ${prodInputsEr[htl_id].clientVisit && "is-invalid"}`}
            />
            <div className="information-tag">
              <i
                className="prop-goal-icon-right-info fa-regular fa-circle-info"
                aria-hidden="true"
              ></i>
              <div className="information-content">
                <div className="information-top">
                  Total Client Visits Weekly - Number of Appointments (pre-scheduled or drop-ins)
                  with a clear objective to:
                </div>
                <div className="information-bottom">
                  <ul>
                    <li>
                      Build/Maintain relationship with existing client for additional referrals,
                      increased market share, introduction to fellow associates or departments
                      needing hotel rooms and/or meeting space, advancing the sales process
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {prodInputsEr[htl_id].clientVisit && (
              <Form.Control.Feedback type="invalid">
                {prodInputsEr[htl_id].clientVisit}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </div>
      </Col>
      <Col xs={6} className="mb-3">
        <div className="position-relative">
          <FloatingLabel controlId="floatingInput" label="Site Visit">
            <Form.Control
              type="number"
              onWheel={(event: any) => {
                event.target.blur();
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == "38" || e.keyCode == "40") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              placeholder="Site Visit"
              value={prodInputs[htl_id].siteVisit ? prodInputs[htl_id].siteVisit : ""}
              onChange={(e) => {
                setProdInputs({
                  ...prodInputs,
                  [htl_id]: {
                    ...prodInputs[htl_id],
                    siteVisit: e.target.value ? e.target.value : "",
                  },
                });
              }}
              className={`form-control ${prodInputsEr[htl_id].siteVisit && "is-invalid"}`}
            />
            <div className="information-tag">
              <i
                className="prop-goal-icon-right-info fa-regular fa-circle-info"
                aria-hidden="true"
              ></i>
              <div className="information-content">
                <div className="information-top">
                  Total Site Visits Weekly - Time spent on property with a client,potential client
                  or walk-in (with a clear objective) including:
                </div>
                <div className="information-bottom">
                  <ul>
                    <li>Property Tours</li>
                    <li>Breakfast/Lunch/Happy Hour invitation</li>
                    <li>
                      Walk-in interested in establishing transient rate / group booking / meeting /
                      event
                    </li>
                    <li>As well as long distance presentations to include Power Point of Hotel</li>
                  </ul>
                </div>
              </div>
            </div>
            {prodInputsEr[htl_id].siteVisit && (
              <Form.Control.Feedback type="invalid">
                {prodInputsEr[htl_id].siteVisit}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </div>
      </Col>
      <Col xs={6} className="mb-3">
        <div className="position-relative">
          <FloatingLabel controlId="floatingInput" label="Monthly Hours of Blitzing">
            <Form.Control
              type="number"
              onWheel={(event: any) => {
                event.target.blur();
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == "38" || e.keyCode == "40") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              placeholder="Monthly Hours of Blitzing"
              value={prodInputs[htl_id].blitz ? prodInputs[htl_id].blitz : ""}
              onChange={(e) => {
                setProdInputs({
                  ...prodInputs,
                  [htl_id]: {
                    ...prodInputs[htl_id],
                    blitz: e.target.value ? e.target.value : "",
                  },
                });
              }}
              className={`form-control ${prodInputsEr[htl_id].blitz && "is-invalid"}`}
            />
            <div className="information-tag">
              <i
                className="prop-goal-icon-right-info fa-regular fa-circle-info"
                aria-hidden="true"
              ></i>
              <div className="information-content">
                <div className="information-top">
                  Total Hours of Blitzing Weekly - Consider blitzing '&apos; hunting/seeking &apos;'
                  out new business opportunities typically within a 5 mile radius of your hotel.
                </div>
                <div className="information-bottom">
                  <ul>
                    <li>
                      Canvasing a specific outlined territory, building, office complex, industrial
                      area, etc. with the intention to find new points of contact to schedule an
                      appointment to further qualify their hotel needs
                    </li>
                    <li>
                      Outside calls targeted to a specific market segment (Medical, Retail,
                      Industrial, etc.) within your 'backyard'
                    </li>
                    <li>
                      Driving through competitor hotel parking lots looking for leads (Company names
                      on vehicles, logo'd shirts on individuals, exterior welcome signs, interior
                      reader board / event calendars) or following the competitors shuttle van to
                      area businesses when dropping off groups early morning.
                    </li>
                    <li>Internet research on comp set, google phone numbers, etc. for lead</li>
                  </ul>
                </div>
              </div>
            </div>
            {prodInputsEr[htl_id].blitz && (
              <Form.Control.Feedback type="invalid">
                {prodInputsEr[htl_id].blitz}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </div>
      </Col>
      <Col xs={6} className="mb-3">
        <div className="position-relative">
          <FloatingLabel controlId="floatingInput" label="Monthly Hours of Telemarketing">
            <Form.Control
              type="number"
              onWheel={(event: any) => {
                event.target.blur();
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == "38" || e.keyCode == "40") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              placeholder="Monthly Hours of Telemarketing"
              value={prodInputs[htl_id].telemarketing ? prodInputs[htl_id].telemarketing : ""}
              onChange={(e) => {
                setProdInputs({
                  ...prodInputs,
                  [htl_id]: {
                    ...prodInputs[htl_id],
                    telemarketing: e.target.value ? e.target.value : "",
                  },
                });
              }}
              className={`form-control ${prodInputsEr[htl_id].telemarketing && "is-invalid"}`}
            />
            <div className="information-tag">
              <i
                className="prop-goal-icon-right-info fa-regular fa-circle-info"
                aria-hidden="true"
              ></i>
              <div className="information-content">
                <div className="information-top">Total Hours of Telemarketing Weekly</div>
                <div className="information-bottom">
                  <ul>
                    <li>
                      Prospecting calls during a specified window of time looking for contacts or to
                      make your initial contact to qualify an account uncovered during blitzing,
                      moving those Suspects to Prospects
                    </li>
                    <li>
                      Prospecting calls on a specified targeted list (Hoovers, Manta, Leads from
                      internet research) etc
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {prodInputsEr[htl_id].telemarketing && (
              <Form.Control.Feedback type="invalid">
                {prodInputsEr[htl_id].telemarketing}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </div>
      </Col>
      <Col xs={6} className="mb-3">
        <div className="position-relative">
          <FloatingLabel controlId="floatingInput" label="Networking">
            <Form.Control
              type="number"
              onWheel={(event: any) => {
                event.target.blur();
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == "38" || e.keyCode == "40") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              placeholder="Networking"
              value={prodInputs[htl_id].networking ? prodInputs[htl_id].networking : ""}
              onChange={(e) => {
                setProdInputs({
                  ...prodInputs,
                  [htl_id]: {
                    ...prodInputs[htl_id],
                    networking: e.target.value ? e.target.value : "",
                  },
                });
              }}
              className={`form-control ${prodInputsEr[htl_id].networking && "is-invalid"}`}
            />
            <div className="information-tag">
              <i
                className="prop-goal-icon-right-info fa-regular fa-circle-info"
                aria-hidden="true"
              ></i>
              <div className="information-content">
                <div className="information-top">
                  Total Number of Networking Events attended Monthly Any meeting / function
                  involving group of people where you are representing your hotel.
                </div>
                <div className="information-bottom">
                  <ul>
                    <li>
                      Area Chamber of Commerce meetings, networking breakfasts, networking social
                      hours
                    </li>
                    <li>Convention Visitors Bureau meetings & events</li>
                    <li>Corporate gatherings at your hotel or offsite</li>
                    <li>
                      Association meetings & Events (Business Travel Association or GBTA, HSMAI, MPI
                      Luncheons)
                    </li>
                    <li>Tradeshow participation or attendance</li>
                  </ul>
                </div>
              </div>
            </div>
            <div></div>
            {prodInputsEr[htl_id].networking && (
              <Form.Control.Feedback type="invalid">
                {prodInputsEr[htl_id].networking}
              </Form.Control.Feedback>
            )}
          </FloatingLabel>
        </div>
      </Col>
    </Row>
  );
}
