import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import Select from "react-select";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import {
  getProjectTermCategoryList,
  SaveAdditionalSetting,
} from "../Services/Setup&Config/AdditionalSettingService";
export const AddAdditionalSetting = (props) => {
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [GetCategoryList, setGetCategoryList]: any = React.useState([]);

  const [AdditionalSettingSave, setAdditionalSettingSave]: any = useState({
    termCode: "",
    category: "Select Category",
    deaultValue: "",
    term: "",
    description: "",
    errors: {
      termCode: "",
      category: "",
      deaultValue: "",
      term: "",
      ValidationRules: [
        {
          FieldName: "termCode",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "category",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "deaultValue",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "term",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  // Upload LOGO :
  const [Logo, setLogo]: any = useState({
    name: "",
    link: [],
    errors: "",
  });
  const [LogoUpload, setLogoUpload]: any = useState(null);

  function SelectLogo(e: any) {
    if (e.target.files.length !== 0) {
      setLogo({ ...Logo, ["link"]: e.target.files });

      const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
      setLogoUpload(fileLoaded);
    }
  }

  React.useEffect(() => {
    CategoryList();
    if (props.FilterCategory) {
      setAdditionalSettingSave({
        ...AdditionalSettingSave,
        ["category"]: props.FilterCategory,
      });
    }
  }, []);
  let category_options: any = [
    {
      value: "Select Category",
      label: "Select Category",
      color: "",
    },
  ];

  GetCategoryList.map((item: any) => {
    category_options.push({
      value: item.Category,
      label: item.Category,
      color: "",
    });
  });
  async function CategoryList() {
    const res: any = await getProjectTermCategoryList(Token);

    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setGetCategoryList(res.data.data.dataList1);
      }
    }
  }

  async function SaveAdditionalSettingForm() {
    let obj = fnCheckValidationOfObject(AdditionalSettingSave);
    setAdditionalSettingSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      let formData: any = new FormData();
      formData.append("TermCode", AdditionalSettingSave.termCode);
      formData.append("Category", AdditionalSettingSave.category);
      formData.append("DeaultValue", AdditionalSettingSave.deaultValue);
      formData.append("Term", AdditionalSettingSave.term);
      formData.append("Description", AdditionalSettingSave.description);
      formData.append("file", Logo.link[0]);

      let Message = "Additional Setting Saved SuccessFully";
      setOnProcessHide(true);
      // const res: any = await SaveAdditionalSetting(AdditionalSettingSave, Token);
      const res: any = await SaveAdditionalSetting(formData, Token);
      setOnProcessHide(false);
      if (res.data !== undefined) {
        if (res.data.statusCode === 200) {
          ToasterSuccess(Message);
          // props.CategoryDetails({ category: AdditionalSettingSave.category }, AdditionalSettingSave.category)
          props.CategoryDetails({ category: props.FilterCategory }, props.FilterCategory);
          props.CategoryList();
          props.onHide(false);
        }
      } else {
        ToasterError(res.message);
      }
    }
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      className="quickAdd-popup"
      scrollable={true}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add Additional Setting</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Row className="mb-2">
            <Col xs={6} className="mb-3">
              <Form.Group>
                <div className={`${AdditionalSettingSave.errors.category && "red-border-masking"}`}>
                  <Select
                    placeholder="Select Category"
                    aria-label="Default select example"
                    // className={`multiselect ${AdditionalSettingSave.errors.category && "is-invalid"}`}
                    options={category_options}
                    // value={AdditionalSettingSave.category}
                    value={{
                      label: AdditionalSettingSave.category,
                      value: AdditionalSettingSave.category,
                    }}
                    onChange={(e: any) =>
                      setAdditionalSettingSave({
                        ...AdditionalSettingSave,
                        ["category"]: e.value,
                      })
                    }
                  />
                  {/* {AdditionalSettingSave.errors && AdditionalSettingSave.errors.category && (
                  <Form.Control.Feedback type="invalid">
                    {AdditionalSettingSave.errors.category}
                  </Form.Control.Feedback>
                )} */}
                </div>
                <small style={{ color: "#dc3545" }}>
                  {AdditionalSettingSave.errors && AdditionalSettingSave.errors.category}{" "}
                </small>
              </Form.Group>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Default Value">
                <Form.Control
                  type="text"
                  placeholder="Market Segment"
                  value={AdditionalSettingSave.deaultValue}
                  onChange={(e) =>
                    setAdditionalSettingSave({
                      ...AdditionalSettingSave,
                      ["deaultValue"]: e.target.value,
                    })
                  }
                  isInvalid={
                    AdditionalSettingSave.errors && AdditionalSettingSave.errors.deaultValue
                  }
                />
                {AdditionalSettingSave.errors && AdditionalSettingSave.errors.deaultValue && (
                  <Form.Control.Feedback type="invalid">
                    {AdditionalSettingSave.errors.deaultValue}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Term">
                <Form.Control
                  type="text"
                  maxLength={164}
                  placeholder="Who to Contact"
                  value={AdditionalSettingSave.term}
                  onChange={(e) =>
                    setAdditionalSettingSave({
                      ...AdditionalSettingSave,
                      ["term"]: e.target.value,
                    })
                  }
                  isInvalid={AdditionalSettingSave.errors && AdditionalSettingSave.errors.term}
                />
                {AdditionalSettingSave.errors && AdditionalSettingSave.errors.term && (
                  <Form.Control.Feedback type="invalid">
                    {AdditionalSettingSave.errors.term}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Code">
                <Form.Control
                  type="text"
                  placeholder="Market Segment"
                  maxLength={164}
                  value={AdditionalSettingSave.termCode}
                  onChange={(e) =>
                    setAdditionalSettingSave({
                      ...AdditionalSettingSave,
                      ["termCode"]: e.target.value,
                    })
                  }
                  isInvalid={AdditionalSettingSave.errors && AdditionalSettingSave.errors.termCode}
                />
                {AdditionalSettingSave.errors && AdditionalSettingSave.errors.termCode && (
                  <Form.Control.Feedback type="invalid">
                    {AdditionalSettingSave.errors.termCode}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Description">
                <Form.Control
                  as="textarea"
                  maxLength={364}
                  placeholder="Description"
                  value={AdditionalSettingSave.description}
                  onChange={(e) =>
                    setAdditionalSettingSave({
                      ...AdditionalSettingSave,
                      ["description"]: e.target.value,
                    })
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>
          {AdditionalSettingSave.category === "RoomSetup" ? (
            <Row>
              <Col xs={6} className="mb-3">
                {/* <h2 className="paymenttitle">Image</h2> */}
                <div className="custom-file comaninputfile">
                  {LogoUpload && (
                    <img
                      src={LogoUpload}
                      style={{
                        display: "flex",
                        border: "2px solid tomato",
                        maxWidth: "300px",
                        maxHeight: "300px",
                      }}
                      alt=""
                    />
                  )}
                </div>
                <div className="comninputbtn">
                  <span>Choose Image</span>
                  <input type="file" onChange={SelectLogo} accept="image/*" id="inputGroupFile01" />
                </div>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={SaveAdditionalSettingForm}
          disabled={OnProcessHide === true ? true : false}
        >
          <span className="me-2">
            <img src={WhiteSaveIcon} alt="" />
          </span>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAdditionalSetting;
