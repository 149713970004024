/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarHeader,
  SubMenu,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as CollapseIcon } from "../Asset/Images/Icons/sidebarIcons/arrowRight.svg";
import { ReactComponent as ReportsIcon } from "../Asset/Images/Icons/sidebarIcons/reports.svg";
import { ReactComponent as SupportIcon } from "../Asset/Images/Icons/sidebarIcons/supportIcon.svg";
import { ReactComponent as Knowledgebase } from "../Asset/Images/Icons/sidebarIcons/Knowledgebase.svg";
import AddHelpDesk from "../Model/AddHelpDesk";
import { AppConfigData } from "../Services/CommonServices";
const parse = require("html-react-parser");

function DefaultSidebar() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [HelpDesk, setHelpDesk] = useState(false);
  const navigate = useNavigate();

  const dataSelector: any = useSelector((state: any) => state.session);
  let sideMenuObject = dataSelector.sidemenu_rights;
  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };

  const routedActivation = {
    dashboard: ["/home"],
    component: ["/componentPage"],
    suspect: [
      "/lead",
      "/suspect/open",
      "/suspect/nonViable",
      "/suspect/openActivities",
      "/suspect/details",
      "/suspect/details",
    ],
    accounts: [
      "/account",
      "/accounts/active",
      "/accounts/active",
      "/accounts/inactive",
      "/accounts-view",
      "/accounts/details",
    ],
    contacts: ["/contact"],
    activities: [
      "/activity/all",
      "/activity/blitz",
      "/activity/clientVisit",
      "/activity/email",
      "/activity/followUp",
      "/activity/meeting",
      "/activity/networking",
      "/activity/phoneCall",
      "/activity/siteVisit",
      "/activity/teleMarketing",
      "/activity/others",
      "/activityList",
    ],
    opportunities: [
      "/opportunity/rfp",
      "/opportunity/lnr",
      "/opportunity/group",
      "/opportunity/meetingAndCatering",
    ],
    miscallaneous: ["/miscellaneous/calendar"],
    reports: ["/Reports"],
    import: ["/Import"],
    setup: [
      "/SetupAndConfigurations/createNewClient",
      "/SetupAndConfigurations/generalsetting",
      "/SetupAndConfigurations/companyProfile",
      "/SetupAndConfigurations/portfolios",
      "/SetupAndConfigurations/hotels",
      "/SetupAndConfigurations/user",
      "/SetupAndConfigurations/userRole",
      "/SetupAndConfigurations/accountRules",
      // "/SetupAndConfigurations/mailsetting",
      "/SetupAndConfigurations/marketSegmentType",
      "/SetupAndConfigurations/additionalSettings",
    ],
  };

  const setActiveFun = () => {
    setTimeout(() => {
      const routeKeys = Object.keys(routedActivation);
      const childClass = document.getElementsByClassName("sub-menu-active");
      const menuActiveClass = document.getElementsByClassName("menuActive");
      if (menuActiveClass.length) {
        menuActiveClass[0].classList.remove("menuActive");
      }
      if (childClass.length) {
        childClass[0].classList.remove("sub-menu-active");
      }
      routeKeys.forEach((routeitem) => {
        if (routedActivation[routeitem].includes(location.pathname)) {
          const parentDiv = document.getElementById(`${routeitem}`);
          if (parentDiv?.classList.contains("main-menu-title")) {
            const proInnerItem = parentDiv.getElementsByClassName("pro-inner-item");
            proInnerItem[0].classList.add("sub-menu-active");
          } else {
            if (parentDiv != null) {
              parentDiv.classList.add("sub-menu-active");
            }
          }
          return false;
        }
      });
    }, 200);
  };

  const SubMenuComponent = ({ sideChildKey, parentIt }) => {
    let isCanShow = false;

    if (parentIt.child.length) {
      parentIt.child.forEach((ch_ck_item) => {
        if (ch_ck_item.hasChild) {
          ch_ck_item.child.forEach((ch_ck_inner_item) => {
            if (!isCanShow) {
              isCanShow =
                ch_ck_inner_item.is_view ||
                ch_ck_inner_item.is_create ||
                ch_ck_inner_item.is_update ||
                ch_ck_inner_item.is_delete ||
                ch_ck_inner_item.is_export;
            }
          });
        } else {
          if (!isCanShow) {
            isCanShow =
              ch_ck_item.is_view ||
              ch_ck_item.is_create ||
              ch_ck_item.is_update ||
              ch_ck_item.is_delete ||
              ch_ck_item.is_export;
          }
        }
      });
    }

    if (isCanShow) {
      return (
        <SubMenu
          id={sideChildKey}
          key={sideChildKey}
          defaultOpen={
            parentIt.hasChild
              ? parentIt.child.filter((obj) => {
                  if (obj.hasChild) {
                    if (
                      obj.child.filter((iner_obj) => iner_obj.link == window.location.pathname)
                        .length
                    ) {
                      return obj;
                    }
                  } else {
                    if (obj.link == window.location.pathname) {
                      return obj;
                    } else if (
                      window.location.pathname.toLowerCase().includes(obj.link.toLowerCase())
                    ) {
                      return obj;
                    }
                  }
                }).length
                ? true
                : false
              : false
          }
          icon={parse(parentIt.Icon)}
          className="main-menu-title"
          title={parentIt.title}
        >
          {parentIt.child.map((child_item, i) => {
            if (child_item.hasChild) {
              return (
                <SubMenu
                  defaultOpen={
                    child_item.hasChild
                      ? child_item.child.filter((obj) => obj.link == window.location.pathname)
                          .length
                        ? true
                        : false
                      : false
                  }
                  key={child_item.title}
                  title={child_item.title}
                >
                  {child_item.child.map((inner_child_item, index) => {
                    if (
                      inner_child_item.is_view ||
                      inner_child_item.is_create ||
                      inner_child_item.is_update ||
                      inner_child_item.is_delete ||
                      inner_child_item.is_export
                    ) {
                      return (
                        <NavLink
                          onClick={(e) => {
                            setActiveFun();
                          }}
                          key={inner_child_item.title}
                          to={inner_child_item.link}
                        >
                          <MenuItem>{inner_child_item.title}</MenuItem>
                        </NavLink>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </SubMenu>
              );
            } else {
              if (
                child_item.is_view ||
                child_item.is_create ||
                child_item.is_update ||
                child_item.is_delete ||
                child_item.is_export
              ) {
                return child_item.title ? (
                  <NavLink
                    onClick={(e) => {
                      setActiveFun();
                    }}
                    key={i}
                    to={child_item.link}
                  >
                    <MenuItem>{child_item.title}</MenuItem>
                  </NavLink>
                ) : (
                  <></>
                );
              } else {
                return <></>;
              }
            }
          })}
        </SubMenu>
      );
    } else {
      return <></>;
    }
  };

  return (
    <>
      <ProSidebar collapsed={menuCollapse}>
        <SidebarHeader>
          <div
            className="closemenu"
            role="button"
            tabIndex={0}
            onClick={menuIconClick}
            onKeyDown={menuIconClick}
          >
            <CollapseIcon />
          </div>
        </SidebarHeader>
        <SidebarContent>
          <Menu>
            {sideMenuObject &&
              Object.keys(sideMenuObject).map((side_key) => {
                return !sideMenuObject[side_key].hasChild ? (
                  sideMenuObject[side_key].is_view ||
                  sideMenuObject[side_key].is_create ||
                  sideMenuObject[side_key].is_update ||
                  sideMenuObject[side_key].is_delete ||
                  (sideMenuObject[side_key].is_export && side_key !== "defaultAuthorized") ? (
                    <NavLink
                      id={side_key}
                      key={side_key}
                      to={sideMenuObject[side_key].link}
                      onClick={(e) => {
                        setActiveFun();
                        navigate(sideMenuObject[side_key].link);
                      }}
                    >
                      {
                        <MenuItem
                          icon={
                            side_key !== "import" ? (
                              parse(sideMenuObject[side_key].Icon)
                            ) : (
                              <i
                                style={{ fontSize: "21px" }}
                                className="fa-solid fa-file-import  me-0"
                              ></i>
                            )
                          }
                        >
                          {sideMenuObject[side_key].title}
                        </MenuItem>
                      }
                    </NavLink>
                  ) : (
                    side_key !== "import" && (
                      <>
                        <NavLink to="/DashboardWeeklyReport"></NavLink>
                        <div className="pro-menu-item">
                          <a
                            href={`${AppConfigData.ClientBasePath}DashboardWeeklyReport`}
                            target="_blank"
                            className={`${
                              location.pathname === "/DashboardWeeklyReport" && "active"
                            } ps-3 pro-inner-item`}
                          >
                            {menuCollapse && <ReportsIcon className="me-2" />}
                            {!menuCollapse && <ReportsIcon className="me-2" />}{" "}
                            {!menuCollapse && "Reports"}
                          </a>
                        </div>

                        {/* <NavLink
                          onClick={(e) => {
                            // window.open("/DashboardWeeklyReport", "_blank");
                            // window.open("/DashboardWeeklyReport");
                            // navigate(location.pathname);
                            // setActiveFun();
                          }}
                          to="/DashboardWeeklyReport"
                        >
                          <MenuItem
                            id="Report"
                            className="main-menu-title"
                            icon={<ReportsIcon />}
                            title="Reports"
                          >
                            Reports
                          </MenuItem>
                        </NavLink> */}

                        {/* Gmail Side Tab */}
                        {/* <NavLink
                          onClick={(e) => {
                            navigate("/gmail");
                            setActiveFun();
                          }}
                          to="/gmail"
                        >
                          <MenuItem
                            id="gmail"
                            className="main-menu-title"
                            icon={
                              <i
                                style={{ fontSize: "21px" }}
                                className="fa-light fa-mailbox me-0"
                              ></i>
                            }
                            title="Reports"
                          >
                            Gmail
                          </MenuItem>
                        </NavLink> */}

                        {/* Microsoft Side Tab */}
                        <NavLink
                          onClick={(e) => {
                            navigate("/microsoft");
                            setActiveFun();
                          }}
                          to="/microsoft"
                        >
                          <MenuItem
                            id="microsoft"
                            className="main-menu-title"
                            icon={
                              <i
                                style={{ fontSize: "21px" }}
                                className="fa-brands fa-windows me-0"
                              ></i>
                            }
                            title="microsoft"
                          >
                            Microsoft
                          </MenuItem>
                        </NavLink>

                        <NavLink
                          onClick={(e) => {
                            navigate("/knowledge-tutorials");
                            setActiveFun();
                          }}
                          to="/knowledge-tutorials"
                        >
                          <MenuItem
                            id="Knowledge-Tutorials"
                            className="main-menu-title"
                            icon={<Knowledgebase />}
                            // icon={<i className="fa-light fa-book"> </i>}
                            title="Knowledge-Tutorials"
                          >
                            Knowledge-Tutorials
                          </MenuItem>
                        </NavLink>
                        <NavLink
                          onClick={(e) => {
                            navigate("/support");
                            setActiveFun();
                          }}
                          to="/support"
                        >
                          <MenuItem
                            id="Support"
                            className="main-menu-title"
                            icon={<SupportIcon />}
                            title="Support"
                          >
                            Support
                          </MenuItem>
                        </NavLink>
                      </>
                    )
                  )
                ) : (
                  <SubMenuComponent sideChildKey={side_key} parentIt={sideMenuObject[side_key]} />
                );
              })}
          </Menu>
        </SidebarContent>
      </ProSidebar>
      {HelpDesk && <AddHelpDesk show={HelpDesk} onHide={() => setHelpDesk(false)}></AddHelpDesk>}
    </>
  );
}

export default DefaultSidebar;
