import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import GmailViewCommonModal from "../../../../Model/GmailModals/GmailModals/GmailViewCommonModal";
import Skeleton from "react-loading-skeleton";
import NoDataFoundImage from "../../../../Component/NoData/NoDataFoundImage";
import {
  ToasterError,
  ToasterSuccess,
  getDateFormatIn_MonthName_Date,
} from "../../../../Services/CommonServices";
import Swal from "sweetalert2";

const Inbox = (props) => {
  const AccessToken = localStorage.getItem("G_Token");
  const Gmail_Info = JSON.parse(localStorage.getItem("G_Info"));
  const [ShowMailsDetailsModal, setShowMailsDetailsModal] = useState(false);
  const [NextLastShow, setNextLastShow] = useState(true);
  const ForLoader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [SelectedMailDetails, setSelectedMailDetails] = useState({});
  const [MailList, setMailList] = useState({ IsData: false, data: [] });
  const [_PageToken, _setPageToken] = useState([""]);

  async function fetchGmailData(pageToken, step) {
    let PT = [..._PageToken];
    if (step.toUpperCase() === "PREV") {
      PT.pop();
      PT.pop();
    }

    try {
      const response = await axios.get(
        `https://gmail.googleapis.com/gmail/v1/users/${Gmail_Info?.email}/threads`,
        {
          headers: { Authorization: `Bearer ${AccessToken}` },
          params: {
            labelIds: "INBOX",
            maxResults: 10,
            pageToken: pageToken,
          },
        }
      );
      localStorage.setItem("GI_message", response);
      const newNextPageToken = response?.data?.nextPageToken;

      let IsExist = PT.filter((i) => i === newNextPageToken);
      if (IsExist !== null && IsExist.length === 0) {
        PT.push(newNextPageToken);
        _setPageToken(PT);
      } else {
        PT.push(newNextPageToken);
        _setPageToken(PT);
      }
      // console.log(PT);

      let L_show = PT.filter((i) => i === undefined);

      if (L_show.length > 0) {
        setNextLastShow(false);
      }

      const messages = response.data.threads;
      const emailPromises = messages.map(async (message) => {
        const emailDetails = await axios.get(
          `https://gmail.googleapis.com/gmail/v1/users/${Gmail_Info?.email}/threads/${message.id}`,
          {
            headers: { Authorization: `Bearer ${AccessToken}` },
          }
        );
        return emailDetails.data;
      });

      const emailsWithAttachments = await Promise.all(emailPromises);

      setMailList({ IsData: true, data: emailsWithAttachments });
    } catch (error) {
      LoginNewUserAlert();
    }
  }

  function LoginNewUserAlert() {
    Swal.fire({
      title: "",
      html: "System did not receive mails as you denied permission of read mails. <br /> <br />  If you want to see mails then please try to login again.",
      icon: "warning",
      // showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      // cancelButtonText: "No",
      confirmButtonText: "Ok",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setMailList({ IsData: true, data: [] });
        localStorage.removeItem("G_Token");
        window.location.reload();
      }
    });
  }

  useEffect(() => {
    fetchGmailData("", "");
  }, []);

  return (
    <>
      <div>
        <Col className="mt-4 mt-lg-0">
          <Row>
            {MailList.IsData ? (
              <>
                <div className="mt-2     justify-content-end d-flex">
                  {_PageToken.length > 2 && (
                    <Button
                      onClick={() => fetchGmailData(_PageToken[_PageToken.length - 3], "PREV")}
                      variant="primary me-2"
                    >
                      <i className="fa-regular fa-arrow-left me-2"></i> Previous
                    </Button>
                  )}

                  {NextLastShow && (
                    <Button
                      onClick={() => fetchGmailData(_PageToken[_PageToken.length - 1], "NEXT")}
                      variant="primary"
                    >
                      <i className="fa-regular fa-arrow-right me-2"></i> Next
                    </Button>
                  )}
                </div>
              </>
            ) : (
              ForLoader.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-body">
                    <div className="card-text">
                      <Skeleton count={1} height={20} />
                    </div>
                    <div className="card-text">
                      <Skeleton count={1} height={20} />
                    </div>
                  </div>
                </div>
              ))
            )}
          </Row>
          <Row className="mt-2">
            <div style={{ maxHeight: "700px", overflow: "auto" }}>
              {MailList.IsData ? (
                <>
                  {MailList?.data?.length > 0 ? (
                    MailList.data.map((item, index) => (
                      <div
                        aria-hidden="true"
                        style={{ fontSize: "small" }}
                        className="card"
                        key={index}
                        onClick={(e) => {
                          setSelectedMailDetails(item);
                        }}
                      >
                        <div
                          aria-hidden="true"
                          className="card-body"
                          onClick={() => setShowMailsDetailsModal(true)}
                        >
                          <div className="card-text">
                            {
                              item?.messages[0]?.payload?.headers.find(
                                (header) => header.name === "From"
                              )?.value
                            }
                            <small className="text-muted float-end">
                              {getDateFormatIn_MonthName_Date(
                                item?.messages[0]?.payload?.headers.find(
                                  (header) => header.name === "Date"
                                )?.value
                              )}
                            </small>
                          </div>
                          <div className="card-text">
                            {item?.messages[0]?.payload?.headers.find(
                              (header) => header.name === "Subject"
                            )?.value !== undefined &&
                            item?.messages[0]?.payload?.headers.find(
                              (header) => header.name === "Subject"
                            )?.value !== null &&
                            item?.messages[0]?.payload?.headers.find(
                              (header) => header.name === "Subject"
                            )?.value !== ""
                              ? item?.messages[0]?.payload?.headers.find(
                                  (header) => header.name === "Subject"
                                )?.value
                              : "(no subject)"}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <NoDataFoundImage />
                  )}
                </>
              ) : (
                ForLoader.map((item, index) => (
                  <div className="card" key={index}>
                    <div className="card-body">
                      <div className="card-text">
                        <Skeleton count={1} height={20} />
                      </div>
                      <div className="card-text">
                        <Skeleton count={1} height={20} />
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </Row>
        </Col>
      </div>
      {ShowMailsDetailsModal && (
        <GmailViewCommonModal
          folderName={"inbox"}
          SelectedMailDetails={SelectedMailDetails}
          show={ShowMailsDetailsModal}
          onHide={() => setShowMailsDetailsModal(false)}
        />
      )}
    </>
  );
};

export default Inbox;
