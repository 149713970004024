import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import { GetKnowledgeTutorialsList } from "../../Services/GmailServices/MailServices";
import { useSelector } from "react-redux";
import {
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";

const KnowledgeTutorials = () => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [TutorialsList, setTutorialsList]: any = useState([]);
  const [SelectedUrl, setSelectedUrl] = useState("");

  async function fetchTutorialsList() {
    let res: any = await GetKnowledgeTutorialsList(Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          if (
            res.data.data.dataList1 !== undefined &&
            res.data.data.dataList1 !== null &&
            res.data.data.dataList1.length > 0
          ) {
            setTutorialsList(res.data.data.dataList1);
            setSelectedUrl(res.data.data.dataList1[0].URL);
          } else {
            ToasterError("Something Went Wrong");
          }
        } else {
          ToasterError("Something Went Wrong");
        }
      } else {
        ToasterError("Something Went Wrong");
      }
    } else {
      ToasterError("Something Went Wrong");
    }
  }

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("knowledgeTutorialsFilter"))) {
      HandleRemoveOtherFilters("knowledgeTutorialsFilter");
      HandleFilters("knowledgeTutorialsFilter", {
        pagePath: "/knowledge-tutorials",
        pageName: "knowledgeTutorials",
        filters: [],
        isSort: [],
      });
    }
    fetchTutorialsList();
  }, []);

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            {TutorialsList.length > 0 &&
              TutorialsList.map((itm, index) => (
                <>
                  <div key={index} className="nav-pills">
                    <ul className="p-0 mb-0">
                      <li
                        onClick={() => setSelectedUrl(itm.URL)}
                        className={`nav-link${itm.URL === SelectedUrl ? " active" : ""}`}
                      >
                        {itm.Title}
                      </li>
                    </ul>
                  </div>
                </>
              ))}
          </Col>
          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {SelectedUrl && (
              <iframe
                width="100%"
                height="420"
                src={SelectedUrl}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            )}
          </Col>

          {/* <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            <iframe
              width="100%"
              height="420"
              src="https://www.youtube.com/embed/H2feT-JF8C4"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </Col> */}
          {/* <img src={ComingSoon}  width={"100%"} height={"400px"} alt="Coming-Soon" /> */}
        </Row>
      </div>
    </>
  );
};

export default KnowledgeTutorials;
