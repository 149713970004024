import { useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import WhiteSaveIcon from "../../../Asset/Images/Icons/whiteSaveIcon.svg";
import { changePassword } from "../../../Services/ProfileServices";
import { ToasterError, ToasterSuccess } from "../../../Services/CommonServices";

function ChangePass() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userEmail: any = dataSelector.auth_UserDetail.U_EmailID;
  const Token = dataSelector.access_Token;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const errorMessages = {
    current_password: "Current Password field is required.",
    password: "Password field is required.",
    confirm_password: "Confirm Password field is required.",
    not_matched: "Password and Confirm password must match.",
  };
  const initialState = {
    current_password: "",
    password: "",
    confirm_password: "",
  };
  const [formInputs, setFormInputs] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });
  const [formInputsErrors, setFormInputsErrors] = useState({
    current_password: "",
    password: "",
    confirm_password: "",
  });
  const checkValidation = () => {
    let fieldEmpty = false;
    let isValidate = true;
    let er_current_password = "";
    let er_password = "";
    let er_confirm_password = "";
    if (formInputs.current_password == "") {
      er_current_password = errorMessages.current_password;
      fieldEmpty = true;
      isValidate = false;
    }
    if (formInputs.password == "") {
      er_password = errorMessages.password;
      fieldEmpty = true;
      isValidate = false;
    }
    if (formInputs.confirm_password == "") {
      er_confirm_password = errorMessages.confirm_password;
      fieldEmpty = true;
      isValidate = false;
    }
    if (!fieldEmpty) {
      if (formInputs.confirm_password != formInputs.password) {
        isValidate = false;
        er_confirm_password = errorMessages.not_matched;
      }
    }
    setFormInputsErrors({
      ...formInputsErrors,
      current_password: er_current_password,
      password: er_password,
      confirm_password: er_confirm_password,
    });
    return isValidate;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (checkValidation()) {
      Swal.fire({
        title: "",
        html:
          "This password will change for all users where we find email : </br><b>" +
          userEmail +
          " </b></br></br> Are you sure want to change password ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          let input = {
            userID: userId,
            oldPassword: formInputs.current_password,
            newPassword: formInputs.password,
          };
          let res = await changePassword(input, Token);

          if (res.data.success) {
            if (res.data.statusCode === 200) {
              if (res.data.data && typeof res.data.data !== "undefined") {
                setIsLoading(false);
                ToasterSuccess(res.data.message);
              } else {
                setIsLoading(false);
              }
            } else if (res.data.statusCode === 404) {
              setIsLoading(false);
              ToasterError(res.data.message)

            }
          } else {
            setIsLoading(false);
            ToasterError(res.data.message)

          }
        }
      });
    }
  };
  return (
    <Container fluid>
      <Row>
        <Col xs={6} className="mb-3">
          <div className={`${formInputsErrors.current_password && "red-border-masking"}`}>
            <FloatingLabel controlId="floatingInput" label="Current Password">
              <Form.Control
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    current_password: e.target.value ? e.target.value : "",
                  });
                }}
                value={formInputs.current_password}
                type="password"
                placeholder="Current Password"
              />
            </FloatingLabel>
          </div>
          <small style={{ color: "#dc3545" }}>
            {formInputsErrors.current_password && formInputsErrors.current_password}
          </small>
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-3">
          <div className={`${formInputsErrors.password && "red-border-masking"}`}>
            <FloatingLabel controlId="floatingInput" label="New Password">
              <Form.Control
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    password: e.target.value ? e.target.value : "",
                  });
                }}
                value={formInputs.password}
                type="password"
                placeholder="New Password"
              />
            </FloatingLabel>
          </div>
          <small style={{ color: "#dc3545" }}>
            {formInputsErrors.password && formInputsErrors.password}
          </small>
        </Col>
      </Row>
      <Row>
        <Col xs={6} className="mb-3">
          <div className={`${formInputsErrors.confirm_password && "red-border-masking"}`}>
            <FloatingLabel controlId="floatingInput" label="Confirm New Password">
              <Form.Control
                onChange={(e) => {
                  setFormInputs({
                    ...formInputs,
                    confirm_password: e.target.value ? e.target.value : "",
                  });
                }}
                value={formInputs.confirm_password}
                type="password"
                placeholder="Confirm New Password"
              />
            </FloatingLabel>
          </div>
          <small style={{ color: "#dc3545" }}>
            {formInputsErrors.confirm_password && formInputsErrors.confirm_password}
          </small>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="mb-3 d-flex">
          {!isLoading ? (
            <Button
              onClick={(e) => {
                handleChangePassword(e);
              }}
              type="button"
              variant="primary me-2"
            >
              <span className="me-2">
                <img src={WhiteSaveIcon} alt="" />
              </span>
              Save
            </Button>
          ) : (
            <Button type="button" variant="primary">
              <Spinner size="sm" className="me-2" animation="border" variant="light" />
              Loading
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePass;
