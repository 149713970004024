import React from "react";
import { Col, Row } from "react-bootstrap";
import Tutorials from "./Tutorials";

const NewUserTour = () => {
  return (
    <div className="report-page">
      <Row>
        <Col xxl={3} xl={3} lg={3} md={4}>
          <Tutorials />
        </Col>
        <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
          <iframe
            width="100%"
            height="420"
            src="https://www.youtube.com/embed/eTSKCIVYEEo"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};

export default NewUserTour;
