import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CommonService, ToasterError, ToasterSuccess } from "../Services/CommonServices";
import { SendEmailToAllClientUser } from "../Services/DashboardServices";
import { getEmailSettingList } from "../Services/Setup&Config/MailSettingService";
import Select from "react-select";

export default function SendEmailToClients({ modalShow, setModalShow }) {
  let page_rights_Email = CommonService.getPageRight("Email");

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  const [isLoading, setIsLoading] = useState(false);
  const [EmailSettingList, setEmailSettingList] = useState([]);

  let emailListOption: any = [
    {
      label: "Select EmailID",
      value: null,
    },
  ];

  EmailSettingList !== undefined &&
    EmailSettingList &&
    EmailSettingList.length > 0 &&
    EmailSettingList.map((item: any) => {
      emailListOption.push({
        label: item.EmailID,
        value: item.EmailAccountID,
      });
    });

  let initialFormInputs = {
    clientID: clientID,
    subject: "",
    emailBody: "",
    emailAccountID: "",
  };

  let initialFormInputsEr = {
    subject: "",
    emailBody: "",
    emailAccountID: "",
  };
  const [formInputs, setFormInputs] = useState<any>(initialFormInputs);
  const [formInputsEr, setFormInputsEr] = useState<any>(initialFormInputsEr);

  const handleClosePopup = () => {
    setModalShow(false);
    setFormInputsEr(initialFormInputsEr);
    setFormInputs(initialFormInputs);
  };

  const validateForm = () => {
    let error_json = {};
    Object.keys(formInputsEr).forEach((frm_key) => {
      error_json = {
        ...error_json,
        [frm_key]: "",
      };
    });
    let is_validate = true;

    Object.keys(formInputsEr).forEach((frm_key) => {
      if (!formInputs[frm_key] || formInputs[frm_key] === "") {
        error_json = {
          ...error_json,
          [frm_key]: "This field is required.",
        };
        is_validate = false;
      }
    });
    setFormInputsEr(error_json);
    return is_validate;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      let res = await SendEmailToAllClientUser(formInputs, Token);
      if (res.data.success) {
        if (res.data.statusCode === 200) {
          if (res.data.data && typeof res.data.data !== "undefined") {
            setIsLoading(false);
            ToasterSuccess(res.data.message);
            handleClosePopup();
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          ToasterError("No users found to send email");
        }
      } else {
        setIsLoading(false);
        ToasterError(res.data.message);
      }
    }
  };

  async function getEmailSettingListData() {
    let input: any = {
      clientID: clientID,
      emailID: "",
      mailServer: "",
      smtpAddress: "",
      incomingMailServer: "",
      outgoingMailServer: "",
      userName: "",
      portNo: "",
    };
    setEmailSettingList([]);
    let res: any = await getEmailSettingList(input, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setEmailSettingList(res.data.data.dataList1);
        }
      }
    }
  }

  useEffect(() => {
    getEmailSettingListData();
  }, []);

  return (
    <Modal
      show={modalShow}
      className="clientpoupadd"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => handleClosePopup()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Send Email - CRM Updates</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-md-12 mb-4">
              {/* <FloatingLabel controlId="floatingSelectGrid" label=""> */}
              <Form.Group>
                <Form.Label className="ps-0 ms-0">Email Send From</Form.Label>
                <div
                  className={`${
                    formInputsEr.emailAccountID &&
                    formInputsEr.emailAccountID &&
                    "red-border-masking"
                  }`}
                >
                  <Select
                    placeholder="Select EmailID"
                    // className="multiselect"
                    options={emailListOption}
                    // value={formInputs.emailAccountID}
                    value={emailListOption.filter(
                      (obj) =>
                        formInputs.emailAccountID && formInputs.emailAccountID.includes(obj.value)
                    )}
                    onChange={(e) =>
                      setFormInputs({
                        ...formInputs,
                        emailAccountID: e.value,
                      })
                    }
                  />
                </div>
              </Form.Group>

              <small style={{ color: "#dc3545" }}>
                {" "}
                {formInputsEr.emailAccountID && formInputsEr.emailAccountID}{" "}
              </small>
            </div>
            <div className="col-md-12 mb-4">
              <Form.Group>
                <Form.Label className="ps-0 ms-0">Subject</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Subject"
                  value={formInputs.subject}
                  onChange={(e) =>
                    setFormInputs({
                      ...formInputs,
                      subject: e.target.value,
                    })
                  }
                  isInvalid={formInputsEr.subject && formInputsEr.subject}
                />
                {formInputsEr.subject && (
                  <Form.Control.Feedback type="invalid">
                    {formInputsEr.subject}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="col-md-12">
              <Form.Group>
                <Form.Label className="ps-0 ms-0">Message</Form.Label>
              </Form.Group>
              <div className={`${formInputsEr.emailBody && "red-border"}`}>
                <CKEditor
                  editor={Editor}
                  config={{
                    extraPlugins: [CommonService.uploadPlugin],
                  }}
                  data={formInputs.emailBody}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    setFormInputs({
                      ...formInputs,
                      emailBody: data,
                    });
                  }}
                />
              </div>
              {formInputsEr.emailBody && (
                <span className="feedbackss">{formInputsEr.emailBody}</span>
              )}
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {!isLoading ? (
          <Button
            className="savebtnclient"
            onClick={(e) => {
              if (page_rights_Email.is_create) {
                handleSubmit(e);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          >
            <i className="fa-solid fa-floppy-disk me-2"></i>Send to All Users
          </Button>
        ) : (
          <Button type="button" variant="primary">
            <Spinner size="sm" className="me-2" animation="border" variant="light" />
            Loading
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
