import { useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ByDefaultHotaleIcon from "../../../Asset/Images/Icons/ByDefaultHotaleIcon.svg";
import PageLoader from "../../../Component/Loader/PageLoader";
import NoDataFoundImage from "../../../Component/NoData/NoDataFoundImage";
import AddHotelsModal from "../../../Model/AddHotelsModal";
import { CommonService } from "../../../Services/CommonServices";
import { EditPropertiesByID } from "../../../Services/Setup&Config/HotelService";
import CompetitorHotelTab from "./CompetitorHotelTab";
import FoodAndAv from "./FoodAndAv";
import MergeDocument from "./MergeDocument";
import PropertyInformation from "./PropertyInformation";
import SalesChecklist from "./SalesChecklist";
import TaxInformationTab from "./TaxInformationTab";
import ThirdPartyWebsite from "./ThirdPartyWebsite";

function HotelDetails() {
  let page_rights_Hotel = CommonService.getPageRight("Hotels");
  const { property_id } = useParams<any>();
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [key, setKey] = useState("Hotel Information");
  const [modalShow, setModalShow] = useState(false);
  const [hotelDetails, setHotelDetails] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const fetchHotelDetailsByPropertyId = async () => {
    let data = {
      keyID: property_id,
    };

    let res: any = await EditPropertiesByID(data, Token);

    if (res.status === 200) {
      if (res.data.success) {
        if (res.data !== undefined && res.data !== null) {
          if (res.data.data !== undefined && res.data.data !== null) {
            setHotelDetails(res.data.data);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchHotelDetailsByPropertyId();
    }
  }, []);

  const [showModel, setShowModel] = useState(false);

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Edit</Tooltip>}>
          <Button className="btn-icon editIcon" onClick={() => setModalShow(true)}></Button>
        </OverlayTrigger>
        {key === "Competitor Hotel Tab" && (
          <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Competitor</Tooltip>}>
            <Button
              className="btn-icon addFormIcon"
              onClick={() => {
                if (page_rights_Hotel.is_create) {
                  setShowModel(true);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </OverlayTrigger>
        )}
      </div>
      <div className="custom-profile-page">
        <Container fluid="true">
          <div className="hotels mb-2">
            <span className="d-inline-block me-2">
              <img
                onError={(e: any) => {
                  e.target.src = ByDefaultHotaleIcon;
                }}
                src={
                  hotelDetails
                    ? hotelDetails.properties[0].PropertyLogo !== "" &&
                      hotelDetails.properties[0].PropertyLogo
                      ? hotelDetails.properties[0].PropertyLogo
                      : ByDefaultHotaleIcon
                    : ByDefaultHotaleIcon
                }
                width={45}
                height={45}
                className="rounded o-fit-cover"
                alt=""
              />
            </span>
            <span className="hotels-title">
              {hotelDetails ? hotelDetails.properties[0].PropertyName : ""}
            </span>
          </div>

          {!loading ? (
            hotelDetails ? (
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k: any) => setKey(k)}
                className="tab-component mb-3"
              >
                <Tab eventKey="Hotel Information" title="Hotel Information">
                  {key === "Hotel Information" && (
                    <PropertyInformation hotelDetails={hotelDetails} />
                  )}
                </Tab>
                <Tab eventKey="Competitor Hotel Tab" title="Competitor Hotels">
                  {key === "Competitor Hotel Tab" && (
                    <CompetitorHotelTab
                      showModel={showModel}
                      setShowModel={setShowModel}
                      property_id={property_id}
                    />
                  )}
                </Tab>
                <Tab eventKey="Merge Document" title="Merge Document">
                  {key === "Merge Document" && <MergeDocument property_id={property_id} />}
                </Tab>
                <Tab eventKey="Sales Checklist" title="Sales Checklist">
                  {key === "Sales Checklist" && <SalesChecklist property_id={property_id} />}
                </Tab>
                <Tab
                  eventKey="Third Party Website Login Details"
                  title="Third Party Website Login Details"
                >
                  {key === "Third Party Website Login Details" && (
                    <ThirdPartyWebsite property_id={property_id} />
                  )}
                </Tab>
                <Tab
                  eventKey="Food/Beverages and AV Equipment Setup"
                  title="Food/Beverages and AV Equipment Setup"
                >
                  {key === "Food/Beverages and AV Equipment Setup" && (
                    <FoodAndAv property_id={property_id} />
                  )}
                </Tab>
                <Tab eventKey="TAX Information" title="TAX Information">
                  {key === "TAX Information" && (
                    <TaxInformationTab hotelDetails={hotelDetails} property_id={property_id} />
                  )}
                </Tab>
              </Tabs>
            ) : (
              <NoDataFoundImage />
            )
          ) : (
            <PageLoader />
          )}
        </Container>
      </div>

      {modalShow && (
        <AddHotelsModal
          getHotelsList={fetchHotelDetailsByPropertyId}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setKey("Hotel Information");
            // setEditPropertiesID("")
          }}
          EditPropertiesID={property_id}
        />
      )}
    </>
  );
}

export default HotelDetails;
