import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GreenLabel from "../../../../Component/Labels/GreenLabel/Index";
import RedLabel from "../../../../Component/Labels/RedLabel/Index";
import TableView from "../../../../Component/Table/Index";
import { profileGetAccounts } from "../../../../Services/ProfileServices";

export default function Accounts() {
  const dataSelector: any = useSelector((state: any) => state.session);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const accountsTableColumns = React.useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "accountName",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Contact No",
        accessor: "contactNo",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Web",
        accessor: "web",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ],
    []
  );
  const accountsTableData: any = [];

  data.map((itm: any) => {
    accountsTableData.push({
      accountName: itm.AccountName
        ? itm.AccountName.replace("#", " ")
        : "",
      city: itm.City,
      state: itm.State,
      contactNo: itm.Contact,
      email: itm.Email,
      web: itm.Webs,
      status:
        itm.Status == "Active" ? (
          <GreenLabel successLabel="Active" />
        ) : (
          <RedLabel dangerLabel="Inactive" />
        ),
    });
  });

  const fetchData = async () => {
    let input = {
      keyID: userId,
    };
    let res: any = await profileGetAccounts(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== null && typeof res.data.data !== "undefined") {
          setData(res.data.data.dataList1);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableView
      columnsData={accountsTableColumns}
      tableData={accountsTableData}
      isData={isLoading}
    />
  );
}
