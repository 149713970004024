import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  AppConfigData,
  CommonService,
  ToasterError,
  ToasterSuccess,
  getDateInFormat,
} from "../../Services/CommonServices";
import { useSelector } from "react-redux";
import { ExportLeadFileOfInvalidData } from "../../Services/ImportServises/ImportServises";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import TableView from "../../Component/Table/Index";
import moment from "moment";

export default function ImportUploadDocument(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const SuccessfullImportdHeader = useMemo(
    () => [
      {
        Header: "Company/ Group Name",
        accessor: "companyGroupName",
        className: "w-243",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },

      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Assign To",
        accessor: "assignTo",
      },
      {
        Header: "Mobile No.",
        accessor: "mobileNo",
      },
    ],
    []
  );

  let tempSuccessfullImportedData: any = [];
  const [SuccessfullImportedData, setSuccessfullImportedData] = useState([]);

  SuccessfullImportedData &&
    SuccessfullImportedData.map((item: any) => {
      tempSuccessfullImportedData.push({
        controlRender: {},
        className: {},
        companyGroupName: item.companyName,
        assignTo: item.assignTo,
        name: item.firstName ? item.firstName : "" + " " + item.lastName ? item.lastName : "",
        email: item.email,

        source: item.lead_Source_Term,

        mobileNo:
          item.mobileNo !== undefined && item.mobileNo !== null && item.mobileNo !== ""
            ? item.mobileNo
            : "-",
      });
    });

  const InvalidImportdHeader = useMemo(
    () => [
      {
        Header: "Error Message",
        accessor: "ErrorMessage",
        className: "w-500",
      },
      {
        Header: "Company Or GroupName	",
        accessor: "CompanyOrGroupName",
      },
      {
        Header: "Is Group",
        accessor: "IsGroup",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "First Name",
        accessor: "FirstName",
      },
      {
        Header: "Last Name",
        accessor: "LastName",
      },
      {
        Header: "Hotel Name",
        accessor: "HotelName",
      },
      {
        Header: "Assign To",
        accessor: "AssignTo",
      },
      {
        Header: "Office Phone",
        accessor: "OfficePhone",
      },
      {
        Header: "Office Phone Ext",
        accessor: "OfficePhoneExt",
      },
      {
        Header: "Mobile Phone",
        accessor: "MobilePhone",
      },
      {
        Header: "Address",
        accessor: "Address",
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "City",
        accessor: "City",
      },
      {
        Header: "State",
        accessor: "State",
      },
      {
        Header: "Country",
        accessor: "Country",
      },
      {
        Header: "Zip Code",
        accessor: "ZipCode",
      },
      {
        Header: "Website",
        accessor: "Website",
      },
      {
        Header: "Notes",
        accessor: "Notes",
      },
      {
        Header: "Group Type",
        accessor: "GroupType",
      },
      {
        Header: "Start Date",
        accessor: "StartDate",
      },
      {
        Header: "End Date",
        accessor: "EndDate",
      },
      {
        Header: "No of Rooms",
        accessor: "NoofRooms",
      },
    ],
    []
  );

  let tempInvalidImportedData: any = [];
  const [InvalidImportedData, setInvalidImportedData] = useState([]);

  InvalidImportedData &&
    InvalidImportedData.map((item: any) => {
      tempInvalidImportedData.push({
        controlRender: {},
        className: {
          ErrorMessage: "text-danger w-300 whiteSpaceNowrap",
        },
        CompanyOrGroupName: item.companyName,
        IsGroup: item.isGroup === true ? "Yes" : "No",
        Source: item.lead_Source_Term,
        FirstName: item.firstName,
        LastName: item.lastName,
        HotelName: item.hotelName,
        AssignTo: item.assignTo,
        OfficePhone: item.telephoneNo,
        OfficePhoneExt: item.ext,
        MobilePhone: item.mobileNo,
        Address: item.address,
        Email: item.email,
        City: item.city,
        State: item.state,
        Country: item.country,
        ZipCode: item.zipcode,
        Website: item.website,
        Notes: item.comment,
        GroupType: item.groupType,
        StartDate: getDateInFormat(item.bookingDate),
        EndDate: getDateInFormat(item.endDate),
        NoofRooms: item.noofRooms,
        ErrorMessage: item.errorMessage,
      });
    });

  useEffect(() => {
    if (
      props.ModuleData.validData !== undefined &&
      props.ModuleData.validData !== null &&
      props.ModuleData.validData.length !== 0
    ) {
      setSuccessfullImportedData(props.ModuleData.validData);
    }

    if (
      props.ModuleData.inValidData !== undefined &&
      props.ModuleData.inValidData !== null &&
      props.ModuleData.inValidData.length !== 0
    ) {
      setInvalidImportedData(props.ModuleData.inValidData);
    }
  }, [props && props.ModuleData]);

  async function ExportLeadErrorData() {
    let inputArrayOnj: any = [];

    InvalidImportedData &&
      InvalidImportedData.map((item: any) => {
        inputArrayOnj.push({
          companyOrGroupName: item.companyName,
          isGroup: item.isGroup === true ? "Yes" : "No",
          source: item.lead_Source_Term,
          firstName: item.firstName,
          lastName: item.lastName,
          hotelName: item.hotelName,
          assignTo: item.assignTo,
          officePhone: item.telephoneNo,
          officePhoneExt: item.ext,
          mobilePhone: item.mobileNo,
          address: item.address,
          email: item.email,
          city: item.city,
          state: item.state,
          country: item.country,
          zipCode: item.zipcode,
          website: item.website,
          notes: item.comment,
          groupType: item.groupType,
          startDate: item.bookingDate && moment(item.bookingDate).format("YYYY-MM-DD"),
          endDate: item.endDate && moment(item.endDate).format("YYYY-MM-DD"),
          noofRooms: item.noofRooms,
        });
      });

    let input: any = {
      inValidData: inputArrayOnj,
    };

    let res: any = await ExportLeadFileOfInvalidData(input, Token);

    if (res.data !== undefined && res.data !== null) {
      let Message = `Leads Export SuccessFully`;
      if (res.data.statusCode === 200) {
        window.open(AppConfigData.APIBasePath + res.data.data);
        ToasterSuccess(Message);
      } else {
        ToasterError(res.data.message);
      }
    } else {
      ToasterError(res.message);
    }
  }

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="d-flex align-items-center justify-content-between w-100"
        >
          <div> Import (Leads) </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col xs={12}>
            <h6> Imported Data</h6>
            <div>
              <TableView
                columnsData={SuccessfullImportdHeader}
                tableData={tempSuccessfullImportedData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-5">
            <Row>
              <Col md={11}>
                <h6> Invalid Data</h6>
              </Col>
              <Col md={1}>
                <Button className="mb-2" variant="primary" onClick={() => ExportLeadErrorData()}>
                  <span className="me-2">
                    <img src={WhiteSaveIcon} alt="" />
                  </span>
                  Export
                </Button>
              </Col>
            </Row>

            <div>
              <TableView
                columnsData={InvalidImportdHeader}
                tableData={tempInvalidImportedData}
                isData={false}
              />
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
