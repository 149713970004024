import { useState } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoginButtonIcon from "../../../src/Asset/Images/Icons/LoginButtonIcon.svg";
import LoginKeyIcon from "../../../src/Asset/Images/Icons/LoginKeyIcon.svg";
import { ENUMS } from "../../Constants";
import { updateUserData } from "../../Redux/actions";
import { VerifyClientOTP } from "../../Services/LoginServices";

function TwoStepAuthentication() {
  const location: any = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cryptoKey = "SqT_InntelligentCRM_kamlesh_393987";
  const CryptoJS: any = require("crypto-js");
  const UserIDs = location.state.UserID;
  const Token = location.state.token;
  const [IsloginClick, setIsloginClick] = useState(false);
  const [input, setInput]: any = useState({
    otp: "",
    userID: UserIDs,
  });

  async function VarifyOtp() {
    setIsloginClick(true);
    const res: any = await VerifyClientOTP(input, Token);

    setIsloginClick(false);

    if (res.data.statusCode === 200) {
      if (location.state.loggedDetail) {
        LoggedUserDetailStore();
      }
    }
  }
  function LoggedUserDetailStore() {
    if (location.state.loggedDetail) {
      localStorage.setItem(
        "accessToken",
        CryptoJS.AES.encrypt(
          location.state.loggedDetail.dataList2 &&
            location.state.loggedDetail.dataList2[0].accessToken,
          cryptoKey
        )
      );
      localStorage.setItem(
        "authUser",
        CryptoJS.AES.encrypt(JSON.stringify(location.state.loggedDetail.dataList1[0]), cryptoKey)
      );
      localStorage.setItem(
        "tokenExpire",
        location.state.loggedDetail.dataList2 &&
          location.state.loggedDetail.dataList2[0].tokenExpiresIn
      );

      let initialRights = ENUMS.side_menu;
      let newRights = {};

      Object.keys(initialRights).forEach((key_id) => {
        if (initialRights[key_id].hasChild) {
          let newChild: any = [];
          initialRights[key_id].child.forEach((ch_itm) => {
            let newInnerChild: any = [];

            if (ch_itm.hasChild) {
              ch_itm.child.forEach((inner_ch_itm) => {
                let resDataCheck1 = location.state.loggedDetail.dataList3[0].filter(
                  (itm) => itm.RightName == inner_ch_itm.RightName
                );

                if (resDataCheck1.length) {
                  newInnerChild = [
                    ...newInnerChild,
                    {
                      ...inner_ch_itm,
                      is_view: resDataCheck1[0].IsView,
                      is_create: resDataCheck1[0].IsCreate,
                      is_update: resDataCheck1[0].IsEdit,
                      is_delete: resDataCheck1[0].IsDelete,
                      is_export: resDataCheck1[0].IsExport,
                    },
                  ];
                } else {
                  newInnerChild = [
                    ...newInnerChild,
                    {
                      ...inner_ch_itm,
                      is_view: false,
                      is_create: false,
                      is_update: false,
                      is_delete: false,
                      is_export: false,
                    },
                  ];
                }
              });
            }
            let resDataCheck2 = location.state.loggedDetail.dataList3[0].filter(
              (itm) => itm.RightName == ch_itm.RightName
            );

            if (ch_itm.hasChild) {
              newChild = [
                ...newChild,
                {
                  ...ch_itm,
                  child: newInnerChild,
                },
              ];
            } else {
              if (resDataCheck2.length) {
                newChild = [
                  ...newChild,
                  {
                    ...ch_itm,
                    is_view: resDataCheck2[0].IsView,
                    is_create: resDataCheck2[0].IsCreate,
                    is_update: resDataCheck2[0].IsEdit,
                    is_delete: resDataCheck2[0].IsDelete,
                    is_export: resDataCheck2[0].IsExport,
                  },
                ];
              } else {
                newChild = [
                  ...newChild,
                  {
                    ...ch_itm,
                    is_view: false,
                    is_create: false,
                    is_update: false,
                    is_delete: false,
                    is_export: false,
                  },
                ];
              }
            }
            newRights = {
              ...newRights,
              [key_id]: {
                ...ENUMS.side_menu[key_id],
                child: newChild,
              },
            };
          });
        } else {
          let resDataCheck3 = location.state.loggedDetail.dataList3[0].filter(
            (itm) => itm.RightName == initialRights[key_id].RightName
          );
          if (resDataCheck3.length) {
            newRights = {
              ...newRights,
              [key_id]: {
                ...ENUMS.side_menu[key_id],
                is_view: resDataCheck3.length
                  ? resDataCheck3[0].IsView
                  : initialRights[key_id].is_view,
                is_create: resDataCheck3.length
                  ? resDataCheck3[0].IsCreate
                  : initialRights[key_id].is_create,
                is_update: resDataCheck3.length
                  ? resDataCheck3[0].IsEdit
                  : initialRights[key_id].is_update,
                is_delete: resDataCheck3.length
                  ? resDataCheck3[0].IsDelete
                  : initialRights[key_id].is_delete,
                is_export: resDataCheck3.length
                  ? resDataCheck3[0].IsExport
                  : initialRights[key_id].is_export,
              },
            };
          } else {
            if (initialRights[key_id].RightName === "Dashboard") {
              newRights = {
                ...newRights,
                [key_id]: {
                  ...ENUMS.side_menu[key_id],
                  is_view: true,
                  is_create: true,
                  is_update: true,
                  is_delete: true,
                  is_export: true,
                },
              };
            } else {
              newRights = {
                ...newRights,
                [key_id]: {
                  ...ENUMS.side_menu[key_id],
                  is_view: false,
                  is_create: false,
                  is_update: false,
                  is_delete: false,
                  is_export: false,
                },
              };
            }
          }
        }
      });
      localStorage.setItem("sidemenu_rights", JSON.stringify(newRights));
      localStorage.setItem("user_rights", JSON.stringify(location.state.loggedDetail.dataList3[0]));
      localStorage.setItem(
        "AppLogo",
        location.state.loggedDetail.dataList2 && location.state.loggedDetail.dataList2[0].logo
      );
      dispatch(
        updateUserData(
          location.state.loggedDetail,
          location.state.loggedDetail.dataList1[0],
          location.state.loggedDetail.dataList3[0],
          newRights
        )
      );

      navigate("/home");
    }
  }
  return (
    <div className="login-section">
      <div className="login-title">
        Sign in to
        <span className="d-block">
          your account <span className="three-dots">...</span>
        </span>
      </div>
      <p className="login-description">
        Specific-Measureable-Action Plan Driven-Realistic-TIme Oriented Customer Relationship
        Management System, built by hoteliers for hoteliers, offering organization, automation and
        synchronization of all your sales offers via a cloud based platform.
      </p>
      <Form>
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">
            <span>
              <img src={LoginKeyIcon} alt="" width={24} height={24} />
            </span>
          </InputGroup.Text>
          <FormControl
            type="number"
            onWheel={(event: any) => {
              event.target.blur();
            }}
            onKeyDown={(e: any) => {
              if (e.keyCode == "38" || e.keyCode == "40") {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            placeholder="Enter OTP"
            aria-label="Enter OTP"
            aria-describedby="basic-addon1"
            onChange={(e) =>
              setInput({
                ...input,
                ["otp"]: e.target.value,
              })
            }
          />
        </InputGroup>
        <Form.Group className="form-button d-flex ">
          {IsloginClick === false ? (
            <button onClick={VarifyOtp} className="btn btn-primary" type="button">
              <span className="me-3">
                <img src={LoginButtonIcon} alt="" />
              </span>
              Login
            </button>
          ) : (
            <button className="btn btn-primary" type="button">
              <span className="me-3">
                <i className="fa-duotone fa-spinner fa-spin"></i>
              </span>
              Login...
            </button>
          )}
        </Form.Group>
      </Form>
    </div>
  );
}

export default TwoStepAuthentication;
