import { useEffect, useState } from "react";
import { Breadcrumb, Container, FloatingLabel, Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { validate as isValidUUID } from "uuid";
import HomeIcon from "../../../Asset/Images/DashboardIcon/home.svg";
import { BreadCrumb } from "../../../Constants/BreadCrumb";
import { yearSelectData } from "../../../Redux/actions";

function InPageNav() {
  let navigate = useNavigate();

  const dispatch = useDispatch();
  function SelectYear(value) {
    let dropdownVal = value.target.value;
    dispatch(yearSelectData(dropdownVal));
  }
  const location = useLocation();
  const [locationPath, setLocationPath] = useState("");
  const [currentPathName, setCurrentPathName] = useState("");

  const locationStateWiseBreadCrumb = ["/Opportunity/details"];

  var array: any = [];
  var CurrentYear = new Date().getFullYear();
  for (var i = 2014; i <= CurrentYear; i++) {
    array.push(i);
  }

  useEffect(() => {
    let newLink = "/";
    let totalArray = location.pathname.split("/").length;
    let newIdsURL: any = [];
    location.pathname.split("/").forEach((itm, i) => {
      if (itm) {
        if (isValidUUID(itm)) {
          newLink = newLink + (totalArray !== i + 1 ? ":id" + "/" : ":id");
          newIdsURL = [...newIdsURL, itm];
        } else {
          newLink = newLink + (totalArray !== i + 1 ? itm + "/" : itm);
        }
      }
    });
    setLocationPath(newLink);
    setCurrentPathName(location.pathname);
  }, [location.pathname]);

  const userSelection =
    window.location.pathname === "/home" ? (
      <div className="d-flex gap-2 justify-content-end">
        <span className="width-100px">
          <FloatingLabel controlId="floatingSelectGrid" label="Year">
            <Form.Select onChange={(e: any) => SelectYear(e)}>
              {array.map((data, key) => (
                <>
                  <option key={key} value={data} selected>
                    {data}
                  </option>
                </>
              ))}
            </Form.Select>
          </FloatingLabel>
        </span>
        <span className="width-220px">
          <FloatingLabel controlId="floatingSelectGrid" label="User">
            <Form.Select>
              <option value="1">Andrea Moreno</option>
              <option value="2">Andrea Moreno 2</option>
              <option value="3">Andrea Moreno 3</option>
            </Form.Select>
          </FloatingLabel>
        </span>
      </div>
    ) : (
      <></>
    );

  const CommonBreadCrumbWithState: any = ({ location_path }) => {
    let present_key = "general";
    if (location.state) {
      Object.keys(location.state).forEach((loc_key) => {
        if (loc_key in BreadCrumb[locationPath][0]) {
          present_key = loc_key;
        }
      });
    }

    return BreadCrumb[locationPath][0][present_key].map((itm, i) => {
      if (!itm.active) {
        return (
          <Breadcrumb.Item key={i} onClick={() => navigate(itm.link)}>
            {itm.name}
          </Breadcrumb.Item>
        );
      } else {
        return (
          <Breadcrumb.Item
            key={i}
            onClick={() =>
              itm.link.includes(":id") ? navigate(currentPathName) : navigate(itm.link)
            }
            active
          >
            {itm.name}
          </Breadcrumb.Item>
        );
      }
    });
  };

  return (
    <Container fluid={true} className="main-top-section justify-content-between p-0">
      <div className="row">
        <div className="col-9 col-lg-7 col-md-12 col-sm-12">
          <Breadcrumb className="align-items-center d-flex h-100 mb-3 mb-sm-0">
            <Breadcrumb.Item
              onClick={() => navigate("/home")}
              className="align-items-center d-flex"
            >
              <img src={HomeIcon} alt="" className="me-2" />
            </Breadcrumb.Item>
            {locationPath in BreadCrumb ? (
              locationStateWiseBreadCrumb.includes(locationPath) ? (
                <CommonBreadCrumbWithState location_path={locationPath} />
              ) : (
                BreadCrumb[locationPath].map((itm, i) => {
                  if (!itm.active) {
                    return (
                      <Breadcrumb.Item key={i} onClick={() => navigate(itm.link)}>
                        {itm.name}
                      </Breadcrumb.Item>
                    );
                  } else {
                    return (
                      <Breadcrumb.Item
                        key={i}
                        onClick={() => (itm.link.includes(":id") ? navigate(currentPathName) : "")}
                        active
                      >
                        {itm.name}
                      </Breadcrumb.Item>
                    );
                  }
                })
              )
            ) : (
              ""
            )}
          </Breadcrumb>
        </div>
      </div>
    </Container>
  );
}

export default InPageNav;
