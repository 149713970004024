import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import moment from "moment";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import {
  getAssignToUserList,
  getSupportDataById,
  SupportDetailBySupportID,
} from "../Services/LeftMenuServices";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import {
  CommonService,
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
  getDateInFormat,
  getTimeInFormat,
} from "../Services/CommonServices";
import PageLoader from "../Component/Loader/PageLoader";
import {
  SupportDetailSave,
  getProjectTermByCategory,
} from "../Services/Setup&Config/AdditionalSettingService";
import GreenLabel from "../Component/Labels/GreenLabel/Index";
import RedLabel from "../Component/Labels/RedLabel/Index";
import BlueLabel from "../Component/Labels/BlueLabel/Index";
import { toast } from "react-toastify";

export function AddViewTicket(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const userName: any = dataSelector.auth_UserDetail.U_DisplayName;

  const [GetCategoryList, setGetCategoryList]: any = useState([]);
  const [ResponseData, setResponseData]: any = useState([]);
  const [Supportlist, setSupportlist]: any = React.useState([]);
  const [IsDataCheck, setIsDataCheck] = React.useState(false);
  React.useEffect(() => {
    if (props.ViewID) {
      getSupportTicketdata();
      // CategoryList();
    }
    getSupportDetailBySupportID();
  }, []);

  let category_options: any = [
    {
      value: "",
      label: "Select Category",
      color: "",
    },
  ];
  GetCategoryList.map((item: any) => {
    category_options.push({
      value: item.TermCode,
      label: item.TermCode,
      color: "",
    });
  });

  const [HelpDeskSave, setHelpDeskSave]: any = useState({
    supportID: "",
    comment: "",
    replyBy: "",
    errors: {
      supportID: "",
      comment: "",
      replyBy: "",
      ValidationRules: [
        {
          FieldName: "comment",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  async function CategoryList() {
    let input: any = {
      category: "SupportCategory",
      clientID: clientID,
    };
    const res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setGetCategoryList(res.data.data.dataList1);
        }
      }
    }
  }

  async function getSupportTicketdata() {
    let input = {
      keyID: props.ViewID,
    };
    setIsDataCheck(true);
    const res: any = await getSupportDataById(input, Token);
    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setSupportlist(res.data.data);
          setIsDataCheck(false);
        } else {
          setSupportlist([]);
          setIsDataCheck(false);
        }
      }
    }
  }

  async function getSupportDetailBySupportID() {
    let body: any = {
      keyID: props.ViewID,
    };
    const res: any = await SupportDetailBySupportID(body, Token);
    if (res.data !== undefined) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setResponseData(res.data.data.dataList1);
        }
      }
    } else {
    }
  }

  async function fnAddComments() {
    let obj = fnCheckValidationOfObject(HelpDeskSave);
    setHelpDeskSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      let body: any = {
        supportID: props.ViewID,
        comment: HelpDeskSave.comment,
        replyBy: userName,
      };
      const res: any = await SupportDetailSave(body, Token);
      if (res.data !== undefined) {
        if (res.data.statusCode === 200) {
          ToasterSuccess("Comment Added Successfully");
          getSupportDetailBySupportID();
          setHelpDeskSave({
            ...HelpDeskSave,
            ["comment"]: "",
          });
        }
      } else {
        ToasterError(res.message);
      }
    }
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup support_ticket_procces support_ticket_modal"
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter" className="me-auto">
            Support Ticket
          </Modal.Title>
          {Supportlist.status !== null &&
            Supportlist.status !== "" &&
            Supportlist.status !== undefined &&
            (Supportlist.status === "Open" ? (
              <RedLabel dangerLabel={Supportlist.status} />
            ) : Supportlist.status === "Closed" ? (
              <GreenLabel successLabel={Supportlist.status} />
            ) : Supportlist.status === "InProgress" ? (
              <BlueLabel blueLabel={Supportlist.status} />
            ) : (
              <></>
            ))}
        </Modal.Header>
        <Modal.Body>
          {!IsDataCheck ? (
            <Row style={{ margin: "10px" }}>
              <div className="col-12 mt-1 support_ticket_procces_title">
                <h6 className="heading_hiding">Title</h6>
                <Form.Control
                  id="title"
                  type="text"
                  maxLength={164}
                  disabled
                  defaultValue={Supportlist.title}
                />
              </div>
              <Col xs={3} className="support_ticket_procces_left mt-3">
                <div className="mt-2">
                  <div className="font-weight-500">Category</div>
                  <span>{Supportlist.category}</span>
                </div>
                <div className="mt-2">
                  <div className="font-weight-500">Assign To</div>
                  <span>{Supportlist.assignToUserName}</span>
                </div>
              </Col>

              <Col xs={9} className="mt-3 support_ticket_procces_right">
                <Col xs={12} className="support_ticket_right_height">
                  <div className="response-div content_support_details">
                    <div className="font-weight-500">
                      {Supportlist.userName}
                      <span style={{ float: "right" }}>
                        {getDateInFormat(Supportlist.createdOn)}{" "}
                        {getTimeInFormat(Supportlist.createdOn)}
                      </span>
                    </div>
                    <div
                      className="mt-1"
                      dangerouslySetInnerHTML={{ __html: Supportlist.description }}
                    ></div>
                  </div>
                  {ResponseData.length > 0 &&
                    ResponseData.map((item, index) => (
                      <div className="response-div mt-1 content_support_details" key={index}>
                        <div className="font-weight-500">
                          {item.ReplyBy}
                          <span style={{ float: "right" }}>
                            {getDateInFormat(item.CreatedOn)} {getTimeInFormat(item.CreatedOn)}
                          </span>
                        </div>
                        <div
                          className="mt-1"
                          dangerouslySetInnerHTML={{ __html: item.Comment }}
                        ></div>{" "}
                      </div>
                    ))}
                </Col>
                {Supportlist.status !== null &&
                Supportlist.status !== "" &&
                Supportlist.status !== undefined &&
                (Supportlist.status === "Open" || Supportlist.status === "InProgress") ? (
                  <>
                    <Col xs={12}>
                      <div className="response-div mt-1 content_support_details">
                        <div className="col-md-12">
                          <div
                            className={`support_ticket_comment ${
                              HelpDeskSave.errors.comment && "red-border"
                            }`}
                          >
                            <CKEditor
                              editor={Editor}
                              config={{
                                extraPlugins: [CommonService.uploadPlugin],
                              }}
                              data={HelpDeskSave.comment}
                              onBlur={(event, editor) => {
                                const data = editor.getData();
                                setHelpDeskSave({
                                  ...HelpDeskSave,
                                  ["comment"]: data,
                                });
                              }}
                            />
                          </div>
                          {HelpDeskSave.errors.comment && (
                            <>
                              {" "}
                              <span className="feedbackss">This field is required.</span>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                    <div className="d-flex justify-content-end mt-1 mb-3">
                      <Button onClick={() => fnAddComments()}>Add Comments</Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          ) : (
            <PageLoader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AddViewTicket;
