import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  CommonService,
  ToasterError,
  ToasterSuccess,
  fnCheckValidationOfObject,
} from "../Services/CommonServices";
import { useLocation } from "react-router-dom";
import { SupportDetailSave } from "../Services/Setup&Config/AdditionalSettingService";

export default function AddSupportCommentModal({
  modalShow,
  setModalShow,
  supportData,
  getSupportDetailBySupportID,
}) {
  let location: any = useLocation();
  const dataSelector: any = useSelector((state: any) => state.session);
  const userName: any = dataSelector.auth_UserDetail.U_DisplayName;

  const Token = dataSelector.access_Token;

  const [HelpDeskSave, setHelpDeskSave]: any = useState({
    supportID: "",
    comment: "",
    replyBy: "",
    errors: {
      supportID: "",
      comment: "",
      replyBy: "",
      ValidationRules: [
        {
          FieldName: "comment",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  async function fnAddComments() {
    let obj = fnCheckValidationOfObject(HelpDeskSave);
    setHelpDeskSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      let body: any = {
        supportID: location.state && location.state.SupportID,
        comment: HelpDeskSave.comment,
        replyBy: userName,
      };
      const res: any = await SupportDetailSave(body, Token);
      if (res.data && res.data !== undefined) {
        if (res.data.statusCode === 200) {
          ToasterSuccess("Comment Added successfully");
          getSupportDetailBySupportID(location.state && location.state.SupportID);
          setHelpDeskSave({
            ...HelpDeskSave,
            ["comment"]: "",
          });
          setModalShow(false);
        } else {
          ToasterError(res.message);
        }
      } else {
        ToasterError(res.message);
      }
    }
  }

  return (
    <Modal
      show={modalShow}
      className="clientpoupadd"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={() => setModalShow(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add new comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <>
            <Row>
              <Col xs={12}>
                <div className="response-div mt-1 content_support_details">
                  <div className="col-md-12">
                    <div
                      className={`support_ticket_comment ${
                        HelpDeskSave.errors.comment && "red-border"
                      }`}
                    >
                      <CKEditor
                        editor={Editor}
                        config={{
                          extraPlugins: [CommonService.uploadPlugin],
                        }}
                        data={HelpDeskSave.comment}
                        onBlur={(event, editor) => {
                          const data = editor.getData();
                          setHelpDeskSave({
                            ...HelpDeskSave,
                            ["comment"]: data,
                          });
                        }}
                      />
                    </div>
                    <Row>
                      <Col xs={6}>
                        {HelpDeskSave.errors.comment && (
                          <>
                            <small className="feedbackss">This field is required.</small>
                          </>
                        )}
                      </Col>
                      <Col xs={6}>
                        <div className="d-flex justify-content-end mt-2 mb-3">
                          <Button onClick={() => fnAddComments()}>Post Comment</Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        </Form>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
