/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row, Table } from "react-bootstrap";
import { PatternFormat } from "react-number-format";
import { useSelector } from "react-redux";
import Select from "react-select";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import PageLoader from "../Component/Loader/PageLoader";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import {
  getEmailAssignToDropdownList,
  getLeadDetailByID,
  saveSuspectLead,
} from "../Services/LeftMenuServices";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import CreatableSelect from "react-select/creatable";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Cookies } from "react-cookie-consent";
import { updateLeadDataFetch } from "../Redux/actions";
import { useDispatch } from "react-redux";

export const AddSuspect = (props: any) => {
  //#region New Code...
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  let navigate = useNavigate();
 
  const dispatch = useDispatch();
 
  const globalHotelData: any = useSelector((state: any) => state.dashboard);
  const [loading, setLoading] = useState(false);
  const [OnProcessHide, setOnProcessHide] = useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const lead_data_reload_count = dataSelector.lead_data_reload_count;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const Token = dataSelector.access_Token;
  const [SourceDropdownList, setSourceDropdownList]: any = useState([]);
  const [assingnToHotelResponce, setAssingnToHotelResponce]: any = useState([]);
  const [StatusData, setStatusData]: any = useState([]);
  const [PriorityData, setPriorityData]: any = useState([]);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [SuspectSave, setSuspectSave]: any = useState({
    countrycode: "",
    mobCountryCode: "",
    accountID: "00000000-0000-0000-0000-000000000000",
    address: "",
    city: "",
    clientID: clientID, // "00000000-0000-0000-0000-000000000000",
    comment: "",
    companyName: "",
    country: "",
    createdOn: "",
    dosid: "00000000-0000-0000-0000-000000000000",
    email: "",
    ext: "",
    firstName: "",
    groupType: "",
    isActive: true,
    isConvertAcc: null,
    isGroup: false,
    lastContactedBy: "00000000-0000-0000-0000-000000000000",
    lastContactedOn: null,
    lastName: "",
    leadID: "00000000-0000-0000-0000-000000000000",
    leadNo: "",
    leadRatings: null,
    lead_Source_Term: "",
    lead_Status_Term: "Open",
    mobileNo: "",
    ownerID: "00000000-0000-0000-0000-000000000000",
    priority: "Low",
    propertyID: "00000000-0000-0000-0000-000000000000",
    referenceBy: "",
    referenceItem: "",
    seqNo: 0,
    state: "",
    street: "",
    telephoneNo: "",
    territeryID: "00000000-0000-0000-0000-000000000000",
    updatelog: "",
    website: "",
    zipcode: "",
    lstPropertyIDs: "",
    lstLeadBookingDate: [],
    errors: {
      email: "",
      companyName: "",
      lstPropertyIDs: "",
      // bookingDate: "",
      // endDate: "",
      // noofRoom: "",
      // groupType: "",
      telephoneNo: "",
      mobileNo: "",
      ValidationRules: [
        {
          FieldName: "email",
          ValidationType: "email",
          ValidationMessage: "Enter Valid Email Value",
        },
        {
          FieldName: "lstPropertyIDs",
          ValidationType: "required",
          ValidationMessage: "Please Select Hotels",
        },
        {
          FieldName: "companyName",
          ValidationType: "required",
          ValidationMessage: "Enter Company Name Value",
        },
        {
          FieldName: "telephoneNo",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
        {
          FieldName: "mobileNo",
          ValidationType: "range",
          ValidationMessage: "Enter Valid Contact No.",
        },
      ],
    },
  });
  const [groupTypeData, setGroupTypeData]: any = useState([]);
  const [groupInuptCount, setGroupInuptCount] = useState(1);
  const [groupTab, setGroupTab]: any = useState({
    1: {
      start_date: "",
      end_date: "",
      room: "",
    },
  }); //it is for group Tab
  const [groupTabEr, setGroupTabEr]: any = useState({
    1: {
      start_date: "",
      end_date: "",
      room: "",
    },
  }); //it is for group Tab

  let MySourceOption: any = [{ label: "Select Source", value: "Select Source" }];
  SourceDropdownList !== undefined &&
    SourceDropdownList !== null &&
    SourceDropdownList.length > 0 &&
    SourceDropdownList.map((item: any) => {
      if (item.Category === "Source") {
        MySourceOption.push({
          value: item.Term,
          label: item.Term,
        });
      }
    });
  let MyGroupOption: any = [];
  groupTypeData.map((item: any) => {
    MyGroupOption.push({
      value: item.Term,
      label: item.Term,
    });
  });
  let MyOption: any = [];
  globalHotelData.GlobalHotels !== undefined &&
    globalHotelData.GlobalHotels &&
    globalHotelData.GlobalHotels.length > 0 &&
    globalHotelData.GlobalHotels.map((item: any) => {
      if (item.portfolioName !== "All") {
        MyOption.push({
          label: item.HotelCode + " " + item.PropertyName,
          value: item.PropertyID,
        });
      }
    });

  //Assign To Dropdown
  let optionsAssignTo: any = [];
  assingnToHotelResponce !== undefined &&
    assingnToHotelResponce !== null &&
    assingnToHotelResponce.length > 0 &&
    assingnToHotelResponce.map((item: any) => {
      optionsAssignTo.push({
        label: item.UserName,
        value: item.UserID,
      });
    });
  let StateDropdown: any = [];
  SourceDropdownList !== undefined &&
    SourceDropdownList !== null &&
    SourceDropdownList.length > 0 &&
    SourceDropdownList.map((item: any) => {
      if (item.Category === "State") {
        StateDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });
  let CountryDropdown: any = [];
  SourceDropdownList !== undefined &&
    SourceDropdownList !== null &&
    SourceDropdownList.length > 0 &&
    SourceDropdownList.map((item: any) => {
      if (item.Category === "Country") {
        CountryDropdown.push({
          value: item.Term,
          label: item.TermCode + " - " + item.Term,
        });
      }
    });

  useEffect(() => {
    sethotelChangeLoading(true);
    if (!props.leadID) {
      if (!SelectedPropertyID.includes(",")) {
        getAssignedToData([SelectedPropertyID]);
      }
    }

    // getStatusAndPriorityByProjectTerm();
    if (props.leadID) {
      getLeadDetail();
    }
    getSourceDropdown();
    // GroupTypeData();
  }, []);

  async function getStatusAndPriorityByProjectTerm() {
    let input: any = {
      clientID: clientID,
      category: "Lead Status, Priority",
    };
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempStatus =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Lead Status");
          setStatusData(tempStatus);
          let tempPriority =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Priority");
          setPriorityData(tempPriority);
        }
      }
    }
  }

  async function getLeadDetail() {
    if (SuspectSave.leadID === "00000000-0000-0000-0000-000000000000") {
      setGroupTab({
        1: {
          start_date: "",
          end_date: "",
          room: "",
        },
      });
    }
    if (props.leadID !== "") {
      setGroupTab({
        1: {
          start_date: "",
          end_date: "",
          room: "",
        },
      });
      // setLoading(true);
      sethotelChangeLoading(true);
    }

    let input: any = {
      keyID: props && props.leadID,
    };
    let res: any = await getLeadDetailByID(input, Token);
    let listProperty: any = [];
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data.data.propertyData.length > 0) {
          res.data.data.propertyData.map((item) => {
            listProperty.push(item.PropertyID);
            // getAssignedToData(listProperty.toString());
            getAssignedToData(listProperty);
          });
        }
        if (res.data.data.leadBookingDateData.length > 0) {
          let JsonToIm = {};
          let JsonToImError = {};
          res.data.data.leadBookingDateData.map((item, index) => {
            JsonToIm = {
              ...JsonToIm,
              [index]: {
                start_date: new Date(item.BookingDate), // item.BookingDate.split("T")[0],
                end_date: new Date(item.EndDate), // item.EndDate.split("T")[0],
                room: item.NoofRoom,
              },
            };
            JsonToImError = {
              ...JsonToImError,
              [index]: {
                start_date: "",
                end_date: "",
                room: "",
              },
            };
          });
          setGroupTab(JsonToIm);
          setGroupTabEr(JsonToImError);
          setGroupInuptCount(res.data.data.leadBookingDateData.length - 1);
        }
        setSuspectSave({
          ...SuspectSave,
          accountID: res.data.data.leadData.accountID,
          address: res.data.data.leadData.address,
          city: res.data.data.leadData.city,
          clientID: res.data.data.leadData.clientID,
          comment: res.data.data.leadData.comment,
          companyName: res.data.data.leadData.companyName,
          country: res.data.data.leadData.country,
          createdOn: res.data.data.leadData.createdOn,
          dosid: res.data.data.leadData.dosid,
          email: res.data.data.leadData.email,
          ext: res.data.data.leadData.ext,
          firstName: res.data.data.leadData.firstName,
          groupType: res.data.data.leadData.groupType,
          isActive: res.data.data.leadData.isActive,
          isConvertAcc: res.data.data.leadData.isConvertAcc,
          isGroup: res.data.data.leadData.isGroup,
          lastContactedBy: res.data.data.leadData.lastContactedBy,
          lastContactedOn: res.data.data.leadData.lastContactedOn,
          lastName: res.data.data.leadData.lastName,
          leadID: res.data.data.leadData.leadID,
          leadNo: res.data.data.leadData.leadNo,
          leadRatings: res.data.data.leadData.leadRatings,
          lead_Source_Term: res.data.data.leadData.lead_Source_Term,
          lead_Status_Term: res.data.data.leadData.lead_Status_Term,
          mobileNo:
            res.data.data.leadData.mobileNo !== "" &&
            res.data.data.leadData.mobileNo &&
            !res.data.data.leadData?.mobileNo?.includes(" ")
              ? res.data.data.leadData.mobileNo
              : res.data.data.leadData.mobileNo?.split(" ")[1],
          mobCountryCode:
            res.data.data.leadData?.mobileNo !== "" &&
            res.data.data.leadData?.mobileNo &&
            !res.data.data.leadData?.mobileNo?.includes(" ")
              ? ""
              : res.data.data.leadData?.mobileNo?.split(" ")[0],
          ownerID: res.data.data.leadData.ownerID,
          priority: res.data.data.leadData.priority,
          propertyID: res.data.data.leadData.propertyID,
          referenceBy: res.data.data.leadData.referenceBy,
          referenceItem: res.data.data.leadData.referenceItem,
          seqNo: res.data.data.leadData.seqNo,
          state: res.data.data.leadData.state,
          street: res.data.data.leadData.street,
          countrycode:
            res.data.data.leadData?.telephoneNo &&
            !res.data.data.leadData?.telephoneNo?.includes(" ")
              ? ""
              : res.data.data.leadData?.telephoneNo?.split(" ")[0],

          telephoneNo:
            res.data.data.leadData?.telephoneNo &&
            !res.data.data.leadData?.telephoneNo?.includes(" ")
              ? res.data.data.leadData?.telephoneNo
              : res.data.data.leadData?.telephoneNo?.split(" ")[1],

          // telephoneNo: res.data.data.leadData.telephoneNo.split(" ")[1],
          // countrycode: res.data.data.leadData.telephoneNo.split(" ")[0],

          territeryID: res.data.data.leadData.territeryID,
          updatelog: res.data.data.leadData.updatelog,
          website: res.data.data.leadData.website,
          zipcode: res.data.data.leadData.zipcode,
          lstPropertyIDs: listProperty,
        });
      }
    }
    // setLoading(false);
    sethotelChangeLoading(false);
  }

  function handleSelectHotel(event) {
    let temp: any = [];

    temp.push(event.value);
    setSuspectSave({
      ...SuspectSave,
      ["lstPropertyIDs"]: temp,
      ["propertyID"]: event.value,
      ["dosid"]: "00000000-0000-0000-0000-000000000000",
    });
    sethotelChangeLoading(true);
    getAssignedToData(temp);
  }

  async function getAssignedToData(selectedHotelId) {
    let inputs = {
      clientID: clientID,
      propertyIDs: selectedHotelId.toString(),
    };

    // if (userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3") {
    //   inputs.propertyIDs = null;
    // }

    const res: any = await getEmailAssignToDropdownList(inputs, Token);
    if (res.data.success) {
      if (res.data.data !== null && res.data.data !== undefined) {
        setAssingnToHotelResponce(res.data.data.dataList1);
        if (!props.leadID) {
          let userIDRes = res.data.data.dataList1.filter((item) => item.UserID === userId);

          setSuspectSave({
            ...SuspectSave,
            ["lstPropertyIDs"]: selectedHotelId,
            ["dosid"]: userIDRes.length && userIDRes[0].UserID,
          });
        }
      }
    }
    sethotelChangeLoading(false);
  }

  async function getSourceDropdown() {
    let inputs = {
      category: "Source,State,Country,Lead Group Type,Lead Status,Priority",
      clientID: clientID,
    };
    // sethotelChangeLoading(true)
    //Api for souce but used for Reuse Code
    const res: any = await getProjectTermByCategory(inputs, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        setSourceDropdownList(res.data.data.dataList1);

        let temp: any = [];
        res.data.data.dataList1.length &&
          res.data.data.dataList1.map((item) => {
            if (item.Category === "Lead Group Type") {
              temp.push(item);
            }
          });
        let tempStatus =
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.filter((item) => item.Category === "Lead Status");
        setStatusData(tempStatus);
        let tempPriority =
          res.data.data.dataList1.length > 0 &&
          res.data.data.dataList1.filter((item) => item.Category === "Priority");
        setPriorityData(tempPriority);

        setGroupTypeData(temp);
      }
    }
    sethotelChangeLoading(false);
  }

  async function GroupTypeData() {
    let input = {
      clientID: clientID, // "00000000-0000-0000-0000-000000000000",
      category: "Lead Group Type",
    };
    // input.clientID = clientID;
    let res: any = await getProjectTermByCategory(input, Token);
    if (res.data.success) {
      if (res.data !== null && res.data !== undefined) {
        if (res.data !== null && res.data !== undefined) {
          setGroupTypeData(res.data.data.dataList1);
        }
      }
    }
  }

  const validateGorupData = () => {
    let isValidate = true;
    let errosJson = {};
    Object.keys(groupTabEr).forEach((er_key) => {
      errosJson = {
        ...errosJson,
        [er_key]: {
          start_date: "",
          end_date: "",
          room: "",
        },
      };
    });

    Object.keys(groupTab).forEach((key_gr) => {
      if (groupTab[key_gr].start_date === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            start_date: "Start date is required.",
            end_date: errosJson[key_gr].end_date,
            room: errosJson[key_gr].room,
          },
        };
        isValidate = false;
      }
      if (groupTab[key_gr].end_date === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            end_date: "End date is required.",
            start_date: errosJson[key_gr].start_date,
            room: errosJson[key_gr].room,
          },
        };
        isValidate = false;
      }
      if (groupTab[key_gr].room === "") {
        errosJson = {
          ...errosJson,
          [key_gr]: {
            ...errosJson[key_gr],
            room: "Room no. required.",
            end_date: errosJson[key_gr].end_date,
            start_date: errosJson[key_gr].start_date,
          },
        };
        isValidate = false;
      }
    });
    setGroupTabEr(errosJson);

    // groupData.forEach(group_key => {

    // });
    return isValidate;
  };

  async function SaveSuspectForm() {
    let groupData: any = [];
    let checkGroupValid: any;
    if (SuspectSave.isGroup === true) {
      checkGroupValid = validateGorupData();
      Object.keys(groupTab).forEach((er_key: any) => {
        groupData.push({
          bookingDate: groupTab[er_key].start_date,
          // endDate: groupTab[er_key].end_date,
          endDate: groupTab[er_key].start_date,
          noofRoom: groupTab[er_key].room,
        });
      });
    }
    let obj = fnCheckValidationOfObject(SuspectSave);
    setSuspectSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      if (SuspectSave.telephoneNo || SuspectSave.mobileNo || SuspectSave.email) {
        if (
          SuspectSave.leadID !== undefined &&
          SuspectSave.leadID !== null &&
          SuspectSave.leadID !== "00000000-0000-0000-0000-000000000000"
        ) {
          if (checkGroupValid === true || checkGroupValid === undefined) {
            // Edit
            let input: any = {
              leadID: SuspectSave.leadID,
              leadNo: SuspectSave.leadNo,
              companyName: SuspectSave.companyName,
              firstName: SuspectSave.firstName,
              lastName: SuspectSave.lastName,
              telephoneNo:
                SuspectSave.telephoneNo !== undefined
                  ? SuspectSave.countrycode + " " + SuspectSave.telephoneNo
                  : "",
              mobileNo:
                SuspectSave.mobileNo !== undefined
                  ? SuspectSave.mobCountryCode + " " + SuspectSave.mobileNo
                  : "",
              email: SuspectSave.email,
              website: SuspectSave.website,
              address: SuspectSave.address,
              street: SuspectSave.street,
              city: SuspectSave.city,
              state: SuspectSave.state,
              country: SuspectSave.country,
              zipcode: SuspectSave.zipcode,
              lead_Status_Term: SuspectSave.lead_Status_Term,
              lead_Source_Term: SuspectSave.lead_Source_Term,
              lastContactedOn: SuspectSave.lastContactedOn,
              lastContactedBy: SuspectSave.lastContactedBy,
              leadRatings: SuspectSave.leadRatings,
              referenceItem: SuspectSave.referenceItem,
              referenceBy: SuspectSave.referenceBy,
              territeryID: SuspectSave.territeryID,
              // propertyID: SuspectSave.propertyID,
              dosid: SuspectSave.dosid,
              priority: SuspectSave.priority,
              comment: SuspectSave.comment,
              isConvertAcc: SuspectSave.isConvertAcc,
              isGroup: SuspectSave.isGroup,
              groupType: SuspectSave.groupType,
              ext: SuspectSave.ext,
              accountID: SuspectSave.accountID,
              lstPropertyIDs: SuspectSave.lstPropertyIDs,
              lstLeadBookingDate: groupData,
            };
            setOnProcessHide(true);
            const res: any = await saveSuspectLead(input, Token);

            setOnProcessHide(false);
            if (res.data !== undefined) {
              let Message = "Lead Updated SuccessFully";

              if (res.data.statusCode === 200) {
                ToasterSuccess(Message);
                dispatch(updateLeadDataFetch(lead_data_reload_count + 1));
                props.getSuspectdata();
                props.onHide(false);
                if (
                  SuspectSave.leadID === undefined &&
                  SuspectSave.leadID === null &&
                  SuspectSave.leadID === "00000000-0000-0000-0000-000000000000" &&
                  SuspectSave.leadID === ""
                ) {
                  if (res.data.data) {
                    navigate("/suspect/details", {
                      state: { leadID: res.data.data.leadID, leadName: res.data.data.companyName },
                    });
                  }
                }
              } else if (res.data.statusCode === 208) {
                ToasterError(res.data.message);
                // props.getSuspectdata();
                // props.onHide(false);
              }
            } else {
              ToasterError(res.message);
            }
          }
        } else {
          let input: any = {
            leadID: SuspectSave.leadID,
            leadNo: SuspectSave.leadNo,
            companyName: SuspectSave.companyName,
            firstName: SuspectSave.firstName,
            lastName: SuspectSave.lastName,
            telephoneNo: SuspectSave.countrycode + " " + SuspectSave.telephoneNo,
            mobileNo: SuspectSave.mobCountryCode + " " + SuspectSave.mobileNo,
            email: SuspectSave.email,
            website: SuspectSave.website,
            address: SuspectSave.address,
            street: SuspectSave.street,
            city: SuspectSave.city,
            state: SuspectSave.state,
            country: SuspectSave.country,
            zipcode: SuspectSave.zipcode,
            lead_Status_Term: SuspectSave.lead_Status_Term,
            lead_Source_Term: SuspectSave.lead_Source_Term,
            lastContactedOn: SuspectSave.lastContactedOn,
            lastContactedBy: SuspectSave.lastContactedBy,
            leadRatings: SuspectSave.leadRatings,
            referenceItem: SuspectSave.referenceItem,
            referenceBy: SuspectSave.referenceBy,
            territeryID: SuspectSave.territeryID,
            // propertyID: SuspectSave.propertyID,
            dosid: SuspectSave.dosid,
            priority: SuspectSave.priority,
            comment: SuspectSave.comment,
            isConvertAcc: SuspectSave.isConvertAcc,
            isGroup: SuspectSave.isGroup,
            groupType: SuspectSave.groupType,
            ext: SuspectSave.ext,
            accountID: SuspectSave.accountID,
            lstPropertyIDs: SuspectSave.lstPropertyIDs,
            lstLeadBookingDate: groupData,
          };
          setOnProcessHide(true);
          const res: any = await saveSuspectLead(input, Token);

          setOnProcessHide(false);
          if (res.data !== undefined) {
            let Message = "Lead Saved SuccessFully";
            if (res.data.statusCode === 200) {
              ToasterSuccess(Message);
              props.getSuspectdata();
              props.onHide(false);
              if (res.data.data) {
                navigate("/suspect/details", {
                  state: { leadID: res.data.data.leadID, leadName: res.data.data.companyName },
                });
              }
            } else if (res.data.statusCode === 208) {
              ToasterError(res.data.message);
              // props.getSuspectdata();
              // props.onHide(false);
            }
          } else {
            ToasterError(res.message);
          }
        }
      } else {
        ToasterError("Please enter one of the following: Office Phone, Mobile Phone, or Email.");
      }
    }
  }

  const handleClose = () => {
    setSuspectSave({});
    props.onHide(false);
  };

  //#endregion new code

  function GroupTypeOrCompanyType(event) {
    let value = event.target.value;
    if (value === "Group") {
      let SuspectSaveError = SuspectSave.errors;
      // SuspectSaveError.ValidationRules.push({
      //   FieldName: "bookingDate",
      //   ValidationType: "required",
      //   ValidationMessage: "Please Select Booking Date",
      // });
      // SuspectSaveError.ValidationRules.push({
      //   FieldName: "endDate",
      //   ValidationType: "required",
      //   ValidationMessage: "Please Select End Date",
      // });
      // SuspectSaveError.ValidationRules.push({
      //   FieldName: "noofRoom",
      //   ValidationType: "required",
      //   ValidationMessage: "Enter No of Room",
      // });
      SuspectSaveError.ValidationRules.push({
        FieldName: "groupType",
        ValidationType: "required",
        ValidationMessage: "Select Group Type",
      });
      setSuspectSave({
        ...SuspectSave,
        ["errors"]: SuspectSaveError,
        ["isGroup"]: true,
      });
    } else {
      const SuspectSaveError = SuspectSave.errors;
      for (let i = 0; i < SuspectSaveError.ValidationRules.length; i++) {
        if (
          // SuspectSaveError.ValidationRules[i].FieldName.toLowerCase() === "bookingdate" ||
          // SuspectSaveError.ValidationRules[i].FieldName.toLowerCase() === "enddate" ||
          // SuspectSaveError.ValidationRules[i].FieldName.toLowerCase() === "noofroom" ||
          SuspectSaveError.ValidationRules[i].FieldName.toLowerCase() === "grouptype"
        ) {
          SuspectSaveError.ValidationRules.splice(i, 1);
          i = 0;
        }
      }
      setSuspectSave({
        ...SuspectSave,
        ["errors"]: SuspectSaveError,
        ["isGroup"]: false,
      });
    }
  }

  //#endregion

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        onHide={handleClose}
        scrollable={true}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center justify-content-between w-100"
          >
            <div>Lead Information</div>

            {["radio"].map((type: any) => (
              <div
                key={`inline-${type}`}
                className="option-field suspect-radio-label  d-flex align-items-center"
              >
                <Form.Check
                  inline
                  label="Company"
                  name="grpType"
                  value="Company"
                  type={type}
                  id="Company"
                  className="d-flex align-items-center m-0"
                  checked={SuspectSave.isGroup === false ? true : false}
                  // value={SuspectSave.lead_Status_Term} //|| "Open"}
                  onChange={(e) => GroupTypeOrCompanyType(e)}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Group"
                  name="grpType"
                  value="Group"
                  type={type}
                  id="Group"
                  className="d-flex align-items-center m-0"
                  checked={SuspectSave.isGroup === true ? true : false}
                  onChange={
                    (e) => GroupTypeOrCompanyType(e)
                    // setSuspectSave({
                    //   ...SuspectSave,
                    //   ["isGroup"]: e.target.checked,
                    // })
                  }
                />
              </div>
            ))}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!loading ? (
            <>
              <Row>
                <Col xs={12} className="mb-2">
                  {/* <h6>Suspect Information</h6> */}
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Company / Group Name">
                    {/* <input
        
        type="text"
        id="first_name"
        name="first_name"
       
      /> */}

                    <Form.Control
                      type="text"
                      placeholder="Company / Group Name"
                      maxLength={364}
                      value={SuspectSave.companyName}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["companyName"]: e.target.value,
                        })
                      }
                      isInvalid={SuspectSave.errors.companyName}
                    />
                    {SuspectSave.errors.companyName && (
                      <Form.Control.Feedback type="invalid">
                        {SuspectSave.errors.companyName}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      value={MySourceOption.filter(
                        (obj) =>
                          SuspectSave.lead_Source_Term &&
                          SuspectSave.lead_Source_Term.includes(obj.value)
                      )}
                      options={MySourceOption}
                      onChange={(e: any) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["lead_Source_Term"]: e.value,
                        })
                      }
                      name="colors"
                      placeholder="Select Source"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isDisabled={false}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="First Name">
                    <Form.Control
                      value={SuspectSave.firstName}
                      type="text"
                      placeholder="First Name"
                      maxLength={364}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["firstName"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Last Name">
                    <Form.Control
                      value={SuspectSave.lastName}
                      type="text"
                      maxLength={364}
                      placeholder="Last Name"
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["lastName"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <div className={`${SuspectSave.errors.lstPropertyIDs && "red-border-masking"}`}>
                    <FloatingLabel controlId="floatingSelectGrid" label="">
                      {SuspectSave.leadID !== undefined &&
                      SuspectSave.leadID !== null &&
                      SuspectSave.leadID !== "00000000-0000-0000-0000-000000000000" ? (
                        <Select
                          value={MyOption.filter(
                            (obj) =>
                              SuspectSave.lstPropertyIDs &&
                              SuspectSave.lstPropertyIDs.includes(obj.value)
                          )}
                          options={MyOption}
                          onChange={handleSelectHotel}
                          // isMulti
                          name="colors"
                          placeholder="Select Hotel"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={true}
                        />
                      ) : (
                        <Select
                          value={MyOption.filter(
                            (obj) =>
                              SuspectSave.lstPropertyIDs &&
                              SuspectSave.lstPropertyIDs.includes(obj.value)
                          )}
                          options={MyOption}
                          onChange={handleSelectHotel}
                          name="colors"
                          placeholder="Select Hotel"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isDisabled={false}
                        />
                      )}
                    </FloatingLabel>
                  </div>
                  <small style={{ color: "#dc3545" }}>
                    {" "}
                    {SuspectSave.errors.lstPropertyIDs && SuspectSave.errors.lstPropertyIDs}{" "}
                  </small>
                </Col>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingSelectGrid" label="">
                    <Select
                      placeholder="Select Assigned To"
                      value={optionsAssignTo.filter(
                        (obj) => SuspectSave.dosid && SuspectSave.dosid.includes(obj.value)
                      )}
                      // className={`form-control ${saveActivity.errors.activityTime && "is-invalid"}`}
                      options={optionsAssignTo}
                      onChange={(e: any) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["dosid"]: e.value,
                        })
                      }
                      isDisabled={!SuspectSave.lstPropertyIDs ? true : false}
                      // menuPortalTarget={document.body}
                      // styles={{
                      //   menuPortal: (provided) => ({
                      //     ...provided,
                      //     zIndex: 9999,
                      //   }),
                      // }}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <label className="for-radio-header" htmlFor="Status">
                    Status
                  </label>
                  {["radio"].map((type: any) => (
                    <div key={`inline-${type}`} className="option-field">
                      {StatusData.length > 0 &&
                        StatusData.map((item) => (
                          <>
                            {" "}
                            <Form.Check
                              inline
                              label={item.TermCode}
                              name={item.TermCode}
                              type={type}
                              id={item.TermCode}
                              checked={
                                SuspectSave.lead_Status_Term === item.TermCode ? true : false
                              }
                              value={SuspectSave.lead_Status_Term}
                              onChange={() =>
                                setSuspectSave({
                                  ...SuspectSave,
                                  ["lead_Status_Term"]: item.TermCode,
                                })
                              }
                            />
                          </>
                        ))}
                    </div>
                  ))}
                </Col>
                <Col xs={6} className="mb-3">
                  <label className="for-radio-header" htmlFor="Priority">
                    Priority
                  </label>
                  {["radio"].map((type: any) => (
                    <div key={`inline-${type}`} className="option-field">
                      {PriorityData.length > 0 &&
                        PriorityData.map((item) => (
                          <>
                            <Form.Check
                              inline
                              label={item.TermCode}
                              name={item.TermCode}
                              type={type}
                              id={item.TermCode}
                              checked={SuspectSave.priority === item.TermCode ? true : false}
                              value={SuspectSave.priority}
                              onChange={() =>
                                setSuspectSave({
                                  ...SuspectSave,
                                  ["priority"]: item.TermCode,
                                })
                              }
                            />
                          </>
                        ))}

                      {/* <Form.Check
                      inline
                      label="Normal"
                      name="grpPriority"
                      type={type}
                      id="Normal"
                      // defaultChecked
                      checked={SuspectSave.priority === "Normal" ? true : false}
                      value={SuspectSave.priority} // || "Normal"}
                      onChange={() =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["priority"]: "Normal",
                        })
                      }
                    />
                    <Form.Check
                      inline
                      label="Urgent"
                      name="grpPriority"
                      type={type}
                      id="Urgent"
                      checked={SuspectSave.priority === "Urgent" ? true : false}
                      value={SuspectSave.priority} //|| "Urgent"}
                      onChange={() =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["priority"]: "Urgent",
                        })
                      }
                    /> */}
                    </div>
                  ))}
                </Col>
              </Row>

              {SuspectSave.isGroup === true ? (
                <>
                  <Row>
                    <Col xs={12} className="mt-3 mb-2">
                      <h6>Group Information</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="mb-3">
                      <div className={`${SuspectSave.errors.groupType && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                                      maxMenuHeight={80}
                            placeholder="Select Group Type"
                            value={MyGroupOption.filter(
                              (obj) =>
                                SuspectSave.groupType && SuspectSave.groupType.includes(obj.value)
                            )}
                            options={MyGroupOption}
                            onChange={(e) =>
                              setSuspectSave({
                                ...SuspectSave,
                                ["groupType"]: e.value,
                              })
                            }
                            // menuPortalTarget={document.body}
                            // styles={{
                            //   menuPortal: (provided) => ({
                            //     ...provided,
                            //     zIndex: 9999,
                            //   }),
                            // }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {" "}
                        {SuspectSave.errors.groupType && SuspectSave.errors.groupType}{" "}
                      </small>
                    </Col>
                    <Col xs={6}>
                      <Row>
                        {/* My Data */}
                        <table className="lead_info_date_design">
                          <thead>
                            <tr>
                              <th> Start date </th>
                              {/* <th> End date </th> */}
                              <th> Number of Rooms </th>
                              <th>
                                <div className="d-flex justify-content-end">Action</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(groupTab).map((group_key) => (
                              <>
                                <tr>
                                  <td>
                                    {/* <FloatingLabel controlId="floatingInput" label="">
                                    <Form.Control
                                      type="date"
                                      placeholder=""
                                      // onChange={handleDate
                                      min={"2010-01-01"}
                                      // max={groupTab[group_key].end_date}
                                      value={groupTab[group_key].start_date}
                                      onChange={(e) => {
                                        const startDateValue = e.target.value;
                                        setGroupTab({
                                          ...groupTab,
                                          [group_key]: {
                                            ...groupTab[group_key],
                                            start_date: startDateValue,
                                            end_date: startDateValue,
                                          },
                                        });
                                      }}
                                      isInvalid={groupTabEr[group_key].start_date !== ""}
                                    />
                                    {groupTabEr[group_key].start_date !== "" && (
                                      <Form.Control.Feedback type="invalid">
                                        {groupTabEr[group_key].start_date}
                                      </Form.Control.Feedback>
                                    )}
                                  </FloatingLabel> */}

                                    <div className="DatePicker-col">
                                      {/* <FloatingLabel controlId="floatingInput" label="Start Date"> */}
                                      <DatePicker
                                        selected={
                                          groupTab[group_key].start_date &&
                                          groupTab[group_key].start_date
                                        }
                                        className={`form-control ${
                                          groupTabEr[group_key].start_date !== "" && "is-invalid"
                                        }`}
                                        onChange={(e) => {
                                          const startDateValue = e ? e : "";
                                          setGroupTab({
                                            ...groupTab,
                                            [group_key]: {
                                              ...groupTab[group_key],
                                              start_date: startDateValue,
                                              end_date: startDateValue,
                                            },
                                          });
                                        }}
                                        dateFormat={Cookies.get("SystemSettingDate")}
                                        placeholderText={Cookies.get("SystemSettingDate")}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                      />
                                      {groupTabEr[group_key].start_date !== "" && (
                                        <small style={{ color: "#dc3545" }}>
                                          {groupTabEr[group_key].start_date}
                                        </small>
                                      )}
                                      {/* </FloatingLabel> */}
                                    </div>
                                  </td>
                                  {/* <td>
                                  <FloatingLabel controlId="floatingInput" label="">
                                    <Form.Control
                                      type="date"
                                      placeholder=""
                                      disabled
                                      // onChange={handleDate}
                                      min={groupTab[group_key].start_date}
                                      value={groupTab[group_key].end_date}
                                      onChange={(e) => {
                                        const endDateValue = e.target.value;
                                        setGroupTab({
                                          ...groupTab,
                                          [group_key]: {
                                            ...groupTab[group_key],
                                            end_date: endDateValue,
                                          },
                                        });
                                      }}
                                      // isInvalid={SuspectSave.errors.companyName}
                                      isInvalid={groupTabEr[group_key].end_date !== ""}
                                      // isValidate={groupTabEr[group_key].end_date !== ""}
                                    />
                                    {groupTabEr[group_key].end_date !== "" && (
                                      <Form.Control.Feedback type="invalid">
                                        {groupTabEr[group_key].end_date}
                                      </Form.Control.Feedback>
                                    )}
                                  </FloatingLabel>
                                </td> */}
                                  <td>
                                    <FloatingLabel controlId="floatingInput" label="">
                                      <Form.Control
                                        type="number"
                                        onWheel={(event: any) => {
                                          event.target.blur();
                                        }}
                                        onKeyDown={(e: any) => {
                                          if (e.keyCode == "38" || e.keyCode == "40") {
                                            e.stopPropagation();
                                            e.preventDefault();
                                          }
                                        }}
                                        placeholder="Enter rooms"
                                        value={groupTab[group_key].room}
                                        // onChange={(e) => handleRoomNo(e, item)}
                                        onChange={(e) => {
                                          const limit: any = 2;
                                          const roomNo = e.target.value.slice(0, limit);
                                          setGroupTab({
                                            ...groupTab,
                                            [group_key]: {
                                              ...groupTab[group_key],
                                              room: roomNo,
                                            },
                                          });
                                        }}
                                        isInvalid={groupTabEr[group_key].room !== ""}
                                      />
                                      {groupTabEr[group_key].room !== "" && (
                                        <Form.Control.Feedback type="invalid">
                                          {groupTabEr[group_key].room}
                                        </Form.Control.Feedback>
                                      )}
                                    </FloatingLabel>
                                  </td>
                                  <td>
                                    <div className="btn-icon-grp-table justify-content-end">
                                      {parseInt(group_key) === groupInuptCount ? (
                                        <Button
                                          className="btn-icon addFormIcon"
                                          onClick={() => {
                                            setGroupTab({
                                              ...groupTab,
                                              [groupInuptCount + 1]: {
                                                start_date: "",
                                                end_date: "",
                                                room: "",
                                              },
                                            });
                                            setGroupTabEr({
                                              ...groupTabEr,
                                              [groupInuptCount + 1]: {
                                                start_date: "",
                                                end_date: "",
                                                room: "",
                                              },
                                            });
                                            setGroupInuptCount(groupInuptCount + 1);
                                          }}
                                        ></Button>
                                      ) : (
                                        <Button
                                          className="btn-icon cancelIcon"
                                          onClick={() => {
                                            let newGroupJson: any = {};
                                            let newErGroupjson: any = {};
                                            Object.keys(groupTab).forEach((grp_key) => {
                                              if (group_key !== grp_key) {
                                                newGroupJson = {
                                                  ...newGroupJson,
                                                  [grp_key]: groupTab[grp_key],
                                                };
                                              }
                                            });
                                            Object.keys(groupTabEr).forEach((gr_er_key) => {
                                              if (gr_er_key !== group_key) {
                                                newErGroupjson = {
                                                  ...newErGroupjson,
                                                  [gr_er_key]: groupTabEr[gr_er_key],
                                                };
                                              }
                                            });
                                            setGroupTab(newGroupJson);
                                            setGroupTabEr(newErGroupjson);
                                            // setGroupInuptCount(groupInuptCount - 1)
                                          }}
                                        ></Button>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </Row>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}

              <Row>
                <Col xs={12} className="mt-3 mb-2">
                  <h6>Contact Information</h6>
                </Col>
              </Row>

              <Row>
                {/* Office details */}
                <Col xs={6} className="mb-3">
                  <Row>
                    <Col xs={3}>
                      <FloatingLabel controlId="floatingInput" label="Code">
                        <Form.Control
                          type="text"
                          placeholder="Code"
                          maxLength={6}
                          value={SuspectSave.countrycode}
                          onChange={(e) =>
                            setSuspectSave({
                              ...SuspectSave,
                              ["countrycode"]: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={5}>
                      <FloatingLabel controlId="floatingInput" label="">
                        <div
                          className={`${SuspectSave.errors.telephoneNo && "red-border-masking"}`}
                        >
                          <FloatingLabel controlId="floatingInput" label="Office Phone">
                            <PatternFormat
                              className="form-control"
                              placeholder="Office Phone"
                              displayType="input"
                              value={SuspectSave.telephoneNo}
                              format="###-###-####"
                              onChange={(e) =>
                                setSuspectSave({
                                  ...SuspectSave,
                                  ["telephoneNo"]: e.target.value,
                                })
                              }
                            />
                          </FloatingLabel>
                        </div>
                        {SuspectSave.errors.telephoneNo && (
                          <>
                            <span className="feedbackss">{SuspectSave.errors.telephoneNo}</span>
                            <br />
                          </>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col xs={4}>
                      <FloatingLabel controlId="floatingInput" label="Ext">
                        <Form.Control
                          type="text"
                          placeholder="Ext"
                          maxLength={10}
                          value={SuspectSave.ext}
                          onChange={(e) =>
                            setSuspectSave({
                              ...SuspectSave,
                              ["ext"]: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                </Col>
                {/* Mobile details */}
                <Col xs={6} className="mb-3">
                  <Row>
                    <Col xs={4}>
                      <FloatingLabel controlId="floatingInput" label="Code">
                        <Form.Control
                          type="text"
                          placeholder="Code"
                          maxLength={6}
                          value={SuspectSave.mobCountryCode}
                          onChange={(e) =>
                            setSuspectSave({
                              ...SuspectSave,
                              ["mobCountryCode"]: e.target.value,
                            })
                          }
                        />
                      </FloatingLabel>
                    </Col>
                    <Col xs={8}>
                      {/* <FloatingLabel controlId="floatingInput" label=""> */}
                      <div className={`${SuspectSave.errors.mobileNo && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingInput" label="Mobile Phone">
                          <PatternFormat
                            className="form-control"
                            displayType="input"
                            placeholder="Mobile Phone"
                            value={SuspectSave.mobileNo}
                            format="###-###-####"
                            onChange={(e) =>
                              setSuspectSave({
                                ...SuspectSave,
                                ["mobileNo"]: e.target.value,
                              })
                            }
                          />
                        </FloatingLabel>
                      </div>
                      {SuspectSave.errors.mobileNo && (
                        <>
                          <span className="feedbackss">{SuspectSave.errors.mobileNo}</span>
                          <br />
                        </>
                      )}
                      {/* </FloatingLabel> */}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Address">
                    <Form.Control
                      as="textarea"
                      maxLength={364}
                      placeholder="Address"
                      value={SuspectSave.address}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["address"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>

                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Email">
                    <Form.Control
                      type="email"
                      placeholder="email"
                      maxLength={164}
                      value={SuspectSave.email}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["email"]: e.target.value,
                        })
                      }
                      isInvalid={SuspectSave.errors.email}
                    />
                    {SuspectSave.errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {SuspectSave.errors.email}
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="City">
                    <Form.Control
                      type="text"
                      maxLength={364}
                      placeholder="City"
                      value={SuspectSave.city}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["city"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>

                <Col xs={6} className="mb-3 fild-input">
                  <div>
                    {/* <div className={`${PropertySave.errors.HChain && "red-border-masking"}`}> */}
                    <FloatingLabel controlId="floatingInput" label="">
                      <CreatableSelect
                        isClearable
                        options={StateDropdown}
                        onChange={(event: any) => {
                          setSuspectSave({
                            ...SuspectSave,
                            ["state"]: event !== null ? event.value : "",
                          });
                        }}
                        placeholder="State"
                        value={
                          SuspectSave.state !== ""
                            ? {
                                label: SuspectSave.state,
                                value: SuspectSave.state,
                              }
                            : {
                                label: "State",
                                value: "State",
                              }
                        }
                      />
                    </FloatingLabel>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3 fild-input">
                  <div>
                    <FloatingLabel controlId="floatingInput" label="">
                      <CreatableSelect
                        placeholder={"Country"}
                        isClearable
                        options={CountryDropdown}
                        onChange={(event: any) => {
                          setSuspectSave({
                            ...SuspectSave,
                            ["country"]: event !== null ? event.value : "",
                          });
                        }}
                        value={
                          SuspectSave.country !== ""
                            ? {
                                label: SuspectSave.country,
                                value: SuspectSave.country,
                              }
                            : {
                                label: "Country",
                                value: "Country",
                              }
                        }
                      />
                    </FloatingLabel>
                  </div>
                </Col>

                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Zip Code">
                    <Form.Control
                      type="number"
                      maxLength={16}
                      onWheel={(event: any) => {
                        event.target.blur();
                      }}
                      onKeyDown={(e: any) => {
                        if (e.keyCode == "38" || e.keyCode == "40") {
                          e.stopPropagation();
                          e.preventDefault();
                        }
                      }}
                      placeholder="Zip Code"
                      value={SuspectSave.zipcode}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["zipcode"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xs={6} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Website">
                    <Form.Control
                      type="text"
                      maxLength={164}
                      placeholder="Website"
                      value={SuspectSave.website}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["website"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>

                <Col xs={12} className="mb-3">
                  <FloatingLabel controlId="floatingInput" label="Notes">
                    <Form.Control
                      as="textarea"
                      placeholder="Notes"
                      style={{ height: "100px" }}
                      value={SuspectSave.comment}
                      onChange={(e) =>
                        setSuspectSave({
                          ...SuspectSave,
                          ["comment"]: e.target.value,
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </>
          ) : (
            <PageLoader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={SaveSuspectForm}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default AddSuspect;
