import React from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import { CommonService, ToasterError, ToasterSuccess, fnCheckValidationOfObject } from "../Services/CommonServices";
import {
  EditmarketSegmentRecord,
  InsertUpdateMarketSegmentData,
} from "../Services/Setup&Config/MarketSegmentService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import Select from "react-select";
import { GetHotelsListData } from "../Services/Setup&Config/HotelService";
import PageLoader from "../Component/Loader/PageLoader";

function MarketSegmentModal(props) {
  const [OnProcessHide, setOnProcessHide] = React.useState(false);
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;

  const [marketSegmentHotels, setMarketSegmentHotels] = React.useState([])
  const [loading, setLoading] = React.useState(false);
  const [hotelChangeLoading, sethotelChangeLoading] = React.useState(false); // Hotel Selection Loader State.


  const initial: any = {
    marketSegmentID: "00000000-0000-0000-0000-000000000000",
    whotoContact: "",
    usualTypeofIncomingTravel: "",
    questions: "",
    isActive: true,
    clientID: "",
    updateBy: "",
    updateOn: "",
    hotelID: "",
    seqNo: "",
    orderNo: 0,
    marketSegmentName: "",
    errors: {
      whotoContact: "",
      usualTypeofIncomingTravel: "",
      questions: "",
      marketSegmentName: "",
      ValidationRules: [
        {
          FieldName: "whotoContact",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "usualTypeofIncomingTravel",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "questions",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "marketSegmentName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  };
  const [getMarketData, setgetMarketData]: any = React.useState({
    marketSegmentID: "00000000-0000-0000-0000-000000000000",
    whotoContact: "",
    usualTypeofIncomingTravel: "",
    questions: "",
    isActive: true,
    clientID: "",
    updateBy: "",
    updateOn: "",
    hotelID: "",
    seqNo: "",
    orderNo: 0,
    marketSegmentName: "",
    errors: {
      whotoContact: "",
      usualTypeofIncomingTravel: "",
      questions: "",
      marketSegmentName: "",
      ValidationRules: [
        {
          FieldName: "whotoContact",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "usualTypeofIncomingTravel",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "questions",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "marketSegmentName",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  });

  let MyOption: any = [
    {
      label: "Select Hotel",
      value: null,
    },
  ];

  marketSegmentHotels.length > 0 &&
  marketSegmentHotels.map((item: any) => {
        MyOption.push({
          label: item.HotelCode + " " + item.PropertyName,
          value: item.PropertyID,
        });
    });

  React.useEffect(() => {
    sethotelChangeLoading(true)
    getHotelsList()
    if(props && props.MarketSegmentID) {
      EditmarketSegmentData(props && props.MarketSegmentID);
    } else {
      sethotelChangeLoading(false)
    }
  }, []);

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      // userID: userTypeID === "78a001cf-27cc-408f-b68f-8bb216cbfef3" ? null : userId && userId,
      userID:userId,
    };
    let res: any = await GetHotelsListData(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          setMarketSegmentHotels(res.data.data.dataList1)
          // let temp: any = [];
          // res.data.data.dataList1.length > 0 &&
          //   res.data.data.dataList1.map((item) => {
          //     temp.push(item.PropertyID);
          //   });     

        }
      }
    }
  }

  async function EditmarketSegmentData(value) {
    let input: any = {
      keyID: value,
    };
    // setLoading(true)
    let res: any = await EditmarketSegmentRecord(input, Token);
    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== null && res.data.data !== undefined) {
          setgetMarketData({
            ...getMarketData,
            marketSegmentName: res.data.data.marketSegmentName,
            marketSegmentID: res.data.data.marketSegmentID,
            whotoContact: res.data.data.whotoContact,
            usualTypeofIncomingTravel: res.data.data.usualTypeofIncomingTravel,
            questions: res.data.data.questions,
            isActive: res.data.data.isActive,
            clientID: res.data.data.clientID,
            updateBy: res.data.data.updateBy,
            updateOn: res.data.data.updateOn,
            hotelID: res.data.data.hotelID,
            seqNo: res.data.data.seqNo,
            orderNo: res.data.data.orderNo,
          });
        }
      }
    }
    // setLoading(false)
    sethotelChangeLoading(false)
  }

  async function SaveMarketData() {
    let obj = fnCheckValidationOfObject(getMarketData);
    setgetMarketData({
      ...obj.obj,
    });
    if (obj.isValid) {
      if (
        getMarketData.marketSegmentID !== null &&
        getMarketData.marketSegmentID !== undefined &&
        getMarketData.marketSegmentID !== "00000000-0000-0000-0000-000000000000"
      ) {
        let input: any = {
          marketSegmentID: getMarketData.marketSegmentID,
          whotoContact: getMarketData.whotoContact,
          usualTypeofIncomingTravel: getMarketData.usualTypeofIncomingTravel,
          questions: getMarketData.questions,
          hotelID: getMarketData.hotelID,
          orderNo: getMarketData.orderNo,
          marketSegmentName: getMarketData.marketSegmentName,
        };
        setOnProcessHide(true);
        let res: any = await InsertUpdateMarketSegmentData(input, Token);
        setOnProcessHide(false);
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
           
              ToasterSuccess("Market Segment Updated Successfully");
              props && props.getMarketSegmentList();
              handleClose();
            }
          } else {
ToasterError(res.data.message)
          }
        }
      } else {
        let input: any = {
          marketSegmentID: getMarketData.marketSegmentID,
          whotoContact: getMarketData.whotoContact,
          usualTypeofIncomingTravel: getMarketData.usualTypeofIncomingTravel,
          questions: getMarketData.questions,
          hotelID: getMarketData.hotelID,
          orderNo: getMarketData.orderNo,
          marketSegmentName: getMarketData.marketSegmentName,
        };
        setOnProcessHide(true);
        let res: any = await InsertUpdateMarketSegmentData(input, Token);
        setOnProcessHide(false);
        if (res.data.success) {
          if (res.data !== undefined && res.data !== null && res.data !== "") {
            if (res.data.statusCode === 200) {
             
              ToasterSuccess("Market Segment Inserted Successfully");

              props && props.getMarketSegmentList();
              handleClose();
            }
          } else {
ToasterError(res.data.message)
          }
        }
      }
    }
  }

  const handleClose = () => {
    setgetMarketData(initial);
    props.onHide(false);
  };

  return (
    <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-50w"
      className="quickAdd-popup"
      scrollable={true}
      onHide={handleClose}
    >
      <Modal.Header>
        {getMarketData.marketSegmentID !== undefined &&
        getMarketData.marketSegmentID !== null &&
        getMarketData.marketSegmentID !== "00000000-0000-0000-0000-000000000000" ? (
          <Modal.Title id="contained-modal-title-vcenter"> Edit Market Segment Type</Modal.Title>
        ) : (
          <Modal.Title id="contained-modal-title-vcenter"> Add Market Segment Type</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
      {!loading ? (
        <>
          <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Market Segment">
                <Form.Control
                  type="text"
                  placeholder="Market Segment"
                  value={getMarketData.marketSegmentName}
                  onChange={(e) =>
                    setgetMarketData({
                      ...getMarketData,
                      ["marketSegmentName"]: e.target.value,
                    })
                  }
                  isInvalid={getMarketData.errors && getMarketData.errors.marketSegmentName}
                />
                {getMarketData.errors && getMarketData.errors.marketSegmentName && (
                  <Form.Control.Feedback type="invalid">
                    {getMarketData.errors.marketSegmentName}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              {/* <FloatingLabel controlId="floatingPassword" label="Who to Contact">
                <Form.Control
                  className="textarea-height"
                  as="textarea"
                  placeholder="Who to Contact"
                  value={getMarketData.whotoContact}
                  onChange={(e) =>
                    setgetMarketData({
                      ...getMarketData,
                      ["whotoContact"]: e.target.value,
                    })
                  }
                  isInvalid={getMarketData.errors && getMarketData.errors.whotoContact}
                />
                {getMarketData.errors && getMarketData.errors.whotoContact && (
                  <Form.Control.Feedback type="invalid">
                    {getMarketData.errors.whotoContact}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel> */}
          
                {/* <div className={`${getMarketData.errors.hotelID && "red-border-masking"}`}> */}
               
                    <Select
                      value={MyOption.filter(
                        (obj) =>
                        getMarketData.hotelID &&
                          getMarketData.hotelID.includes(obj.value)
                      )}
                      options={MyOption}
                      onChange={(e)=> {
                        setgetMarketData({
                          ...getMarketData,
                          ["hotelID"]: e.value,
                        });
                      }}
                      // isMulti
                      name="colors"
                      placeholder="Select Hotel"
                      // className="basic-multi-select"
                      // classNamePrefix="select"/
                    />
                {/* </div>
                <small style={{ color: "#dc3545" }}>
                  {getMarketData.errors.hotelID && getMarketData.errors.hotelID}{" "}
                </small> */}
            </Col>
          </Row>
          {/* <Row>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingInput" label="Usual Type of Incoming Travel">
                <Form.Control
                  className="textarea-height"
                  as="textarea"
                  placeholder="Usual Type of Incoming Travel"
                  value={getMarketData.usualTypeofIncomingTravel}
                  onChange={(e) =>
                    setgetMarketData({
                      ...getMarketData,
                      ["usualTypeofIncomingTravel"]: e.target.value,
                    })
                  }
                  isInvalid={getMarketData.errors && getMarketData.errors.usualTypeofIncomingTravel}
                />
                {getMarketData.errors && getMarketData.errors.usualTypeofIncomingTravel && (
                  <Form.Control.Feedback type="invalid">
                    {getMarketData.errors.usualTypeofIncomingTravel}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
            <Col xs={6} className="mb-3">
              <FloatingLabel controlId="floatingPassword" label="Questions">
                <Form.Control
                  as="textarea"
                  className="textarea-height"
                  placeholder="Questions"
                  value={getMarketData.questions}
                  onChange={(e) =>
                    setgetMarketData({
                      ...getMarketData,
                      ["questions"]: e.target.value,
                    })
                  }
                  isInvalid={getMarketData.errors && getMarketData.errors.questions}
                />
                {getMarketData.errors && getMarketData.errors.questions && (
                  <Form.Control.Feedback type="invalid">
                    {getMarketData.errors.questions}
                  </Form.Control.Feedback>
                )}
              </FloatingLabel>
            </Col>
          </Row> */}

          {/* CK Editors */}

          <Row>
            <Col xs={12} className="mb-3">
              <h3 className="inner-header m-0 mb-2">Who to Contact</h3>
              <div className="modal-CKEditor">
                <div
                  className={`${
                    getMarketData.errors && getMarketData.errors.whotoContact && "red-border"
                  }`}
                >
                  <CKEditor
                    editor={Editor}
                    data={getMarketData.whotoContact}
                    config={{
                      extraPlugins: [CommonService.uploadPlugin],
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setgetMarketData({
                        ...getMarketData,
                        ["whotoContact"]: data,
                      });
                    }}
                  />
                </div>
                {getMarketData.errors.whotoContact && (
                  <>
                    {" "}
                    <span className="feedbackss">This field is required.</span>
                  </>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="mb-3">
              <h3 className="inner-header m-0 mb-2">Usual Type of Incoming Travel</h3>
              <div className="modal-CKEditor">
                <div
                  className={`${
                    getMarketData.errors &&
                    getMarketData.errors.usualTypeofIncomingTravel &&
                    "red-border"
                  }`}
                >
                  <CKEditor
                    editor={Editor}
                    data={getMarketData.usualTypeofIncomingTravel}
                    // data={getMarketData.usualTypeofIncomingTravel ? getMarketData.usualTypeofIncomingTravel : "Abc"  }
                    config={{
                      extraPlugins: [CommonService.uploadPlugin],
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setgetMarketData({
                        ...getMarketData,
                        ["usualTypeofIncomingTravel"]: data,
                      });
                    }}
                  />
                </div>
                {getMarketData.errors.usualTypeofIncomingTravel && (
                  <>
                    {" "}
                    <span className="feedbackss">This field is required.</span>
                  </>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="mb-3">
              <h3 className="inner-header m-0 mb-2">Questions</h3>
              <div className="modal-CKEditor">
                <div
                  className={`${
                    getMarketData.errors && getMarketData.errors.questions && "red-border"
                  }`}
                >
                  <CKEditor
                    editor={Editor}
                    data={getMarketData.questions}
                    config={{
                      extraPlugins: [CommonService.uploadPlugin],
                    }}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setgetMarketData({
                        ...getMarketData,
                        ["questions"]: data,
                      });
                    }}
                  />
                </div>
                {getMarketData.errors.questions && (
                  <>
                    {" "}
                    <span className="feedbackss">This field is required.</span>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </>
      ) : <>
      <PageLoader />
      </>
                }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={SaveMarketData}
          disabled={OnProcessHide === true ? true : false}
        >
          <span className="me-2">
            <img src={WhiteSaveIcon} alt="" />
          </span>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
    {hotelChangeLoading ? (
          <div className="calender-loader-main">
            <PageLoader />
          </div>
        ) : (
          ""
        )}
</>
  );
}

export default MarketSegmentModal;
