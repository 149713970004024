import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import TableView from "../../Component/Table/Index";
import AddPortFolio from "../../Model/AddPortFolio";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";
import { DeletePortfolios, getPortfolioList } from "../../Services/PortFolioServices";

function Portfolios() {
  let page_rights_Portfolio = CommonService.getPageRight("Portfolios");

  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const isData = dataSelector.auth_UserDetail;
  const [modalShowPortFolio, setModalShowPortFolio] = React.useState(false);
  const [portfolioID, setportfolioID] = React.useState("");
  let [PortfolioList, setPortfolioList] = React.useState({ IsData: true, data: [] });
  const portfoliosTableColumns = React.useMemo(
    () => [
      {
        Header: "Portfolio Name",
        accessor: "portfolioName",
        className: "w-400",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "text-End table-action sticky-action",
      },
    ],
    []
  );
  let portfoliosTableData: any = [];
  PortfolioList.data.length &&
    PortfolioList.data.map((item: any) => {
      portfoliosTableData.push({
        className: {
          action: "table-action sticky-action",
        },
        portfolioName: item.PortfolioName,
        action: (
          <div className="btn-icon-grp-table justify-content-end">
            <Button
              className="btn-icon editIcon"
              onClick={() => {
                if (page_rights_Portfolio.is_update) {
                  editPortfolio(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (page_rights_Portfolio.is_delete) {
                  DeletePortFolio(item);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </div>
        ),
      });
    });
  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("portfolioFilter"))) {
      HandleRemoveOtherFilters("portfolioFilter");
      HandleFilters("portfolioFilter", {
        pagePath: "/SetupAndConfigurations/portfolios",
        pageName: "portfolio",
        filters: [],
        isSort: [],
      });
    }
    GetPortFolioList();
  }, []);

  function DeletePortFolio(data: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let input: any = {
          keyID: data.PortfolioID,
        };

        const res: any = await DeletePortfolios(input, Token);
        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              let Message = "Portfolio Deleted SuccessFully";

              ToasterSuccess(Message);

              GetPortFolioList();
            } else if (result.isDenied) {
              ToasterError(res.message);
            }
          }
        }
      }
    });
  }

  function editPortfolio(data) {
    setportfolioID(data.PortfolioID);
    setModalShowPortFolio(true);
  }

  async function GetPortFolioList() {
    let input = {
      portfolioName: null,
      clientID: isData && isData.U_ClientID,
    };
    setPortfolioList({ IsData: true, data: [] });
    const res: any = await getPortfolioList(input, Token);

    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPortfolioList({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPortfolioList({ IsData: false, data: [] });
        }
      }
    } else {
      ToasterError("something Went Wrong");
    }
  }

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Portfolio</Tooltip>}>
          <Button
            className="btn-icon addFormIcon"
            onClick={() => {
              if (page_rights_Portfolio.is_create) {
                setModalShowPortFolio(true);
                setportfolioID("");
              } else {
                CommonService.unauthorizedAction();
              }
            }}
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <Row>
          <Col xs={12} className="portfolio-Table profixwidth">
            <div className="lead-height-table">
              <TableView
                columnsData={portfoliosTableColumns}
                tableData={portfoliosTableData}
                isData={PortfolioList.IsData}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modalShowPortFolio === true && (
        <AddPortFolio
          show={modalShowPortFolio}
          onHide={() => setModalShowPortFolio(false)}
          Token={Token}
          portfolioID={portfolioID}
          GetPortFolioList={GetPortFolioList}
        ></AddPortFolio>
      )}
    </>
  );
}

export default Portfolios;
