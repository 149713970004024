import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import WhiteSaveIcon from "../../Asset/Images/Icons/whiteSaveIcon.svg";
import { getDateInFormatMMMMDYYYY } from "../../Services/CommonServices";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import TableView from "../../Component/Table/Index";
import XLSX from "sheetjs-style";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";

export default function ImportRFPModal(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const tableRef = useRef(null);

  const SuccessfullImportdHeader = useMemo(
    () => [
      {
        Header: "Opportunity name",
        accessor: "Opportunityname",
        className: "w-243",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Hotels",
        accessor: "Hotels",
      },
      {
        Header: "Account",
        accessor: "Account",
      },
      {
        Header: "Opportunity Owner",
        accessor: "OpportunityOwner",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Rate Start Date",
        accessor: "RateStartDate",
      },
      {
        Header: "Rate End Date",
        accessor: "RateEndDate",
      },
      {
        Header: "AnticipetedRevanue",
        accessor: "AnticipetedRevanue",
      },
    ],
    []
  );
  const InvalidImportdHeader = useMemo(
    () => [
      {
        Header: "Reason",
        accessor: "Reason",
      },
      {
        Header: "Opportunity name",
        accessor: "Opportunityname",
        className: "w-243",
      },
      {
        Header: "Source",
        accessor: "Source",
      },
      {
        Header: "Hotels",
        accessor: "Hotels",
      },
      {
        Header: "Account",
        accessor: "Account",
      },
      {
        Header: "Opportunity Owner",
        accessor: "OpportunityOwner",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      {
        Header: "Rate Start Date",
        accessor: "RateStartDate",
      },
      {
        Header: "Rate End Date",
        accessor: "RateEndDate",
      },
      {
        Header: "AnticipetedRevanue",
        accessor: "AnticipetedRevanue",
      },
    ],
    []
  );

  let tempSuccessfullImportedData: any = [];
  let tempInvalidImportedData: any = [];

  const [ImportedData, setImportedData] = useState([]);

  ImportedData &&
    ImportedData.map((item: any) => {
      if (item.Isvalid === true) {
        tempSuccessfullImportedData.push({
          controlRender: {
            Status:
              item.Status === "8. Expired Rate" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "7. Lost / RFP Declined" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "6. Rate Renewal" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : (
                <GreenLabel successLabel={item.Status} />
              ),
          },
          className: {
            AnticipetedRevanue: "text-end",
            RateStartDate: "text-end",
            RateEndDate: "text-end",
          },
          Opportunityname: item.Opportunityname,
          Source: item.Source,
          Hotels: item.Hotels,
          Account: item.Account,
          OpportunityOwner: item.OpportunityOwner,
          Status: item.Status,
          RateStartDate: getDateInFormatMMMMDYYYY(item.RateStartDate),
          RateEndDate: getDateInFormatMMMMDYYYY(item.RateEndDate),
          AnticipetedRevanue:
            item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
              ? "$" + item.AnticipetedRevanue
              : "",
        });
      } else {
        tempInvalidImportedData.push({
          controlRender: {
            Status:
              item.Status === "8. Expired Rate" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "7. Lost / RFP Declined" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : item.Status === "6. Rate Renewal" ? (
                <RedLabel dangerLabel={item.Status} />
              ) : (
                <GreenLabel successLabel={item.Status} />
              ),
          },
          className: {
            Reason: "text-danger w-300 whiteSpaceNowrap",
            AnticipetedRevanue: "text-end",
            RateStartDate: "text-end",
            RateEndDate: "text-end",
          },
          Reason: item.Reason,
          Opportunityname: item.Opportunityname,
          Source: item.Source,
          Hotels: item.Hotels,
          Account: item.Account,
          OpportunityOwner: item.OpportunityOwner,
          Status: item.Status,
          RateStartDate: getDateInFormatMMMMDYYYY(item.RateStartDate),
          RateEndDate: getDateInFormatMMMMDYYYY(item.RateEndDate),
          AnticipetedRevanue:
            item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
              ? "$" + item.AnticipetedRevanue
              : "",
        });
      }
    });

  useEffect(() => {
    if (
      props.ModuleData !== undefined &&
      props.ModuleData !== null &&
      props.ModuleData.length !== 0
    ) {
      setImportedData(props.ModuleData);
    }
  }, [props && props.ModuleData]);

  function downloadTable() {
    const table = document.getElementById("tableRef");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LNR");
    XLSX.writeFile(wb, "LNR_Opportunity.xlsx");
  }

  let RFPErrTableExport: any = () => {
    return (
      <>
        <thead>
          <tr>
            <th>
              <b> Opportunity name </b>
            </th>
            <th>
              <b> Source </b>
            </th>
            <th>
              <b> Hotels </b>
            </th>
            <th>
              <b> Account </b>
            </th>
            <th>
              <b> Opportunity Owner </b>
            </th>
            <th>
              <b> Status </b>
            </th>
            <th>
              <b> Rate Start Date </b>
            </th>
            <th>
              <b> Rate End Date </b>
            </th>
            <th>
              <b> AnticipetedRevanue </b>
            </th>
          </tr>
        </thead>

        <tbody>
          {ImportedData &&
            ImportedData.map((item: any, idx) => {
              if (item.Isvalid === false) {
                return (
                  <tr key={idx}>
                    <td className="text-danger w-300 whiteSpaceNowrap">{item.Opportunityname}</td>
                    <td>{item.Source}</td>
                    <td>{item.Hotels}</td>
                    <td>{item.Account}</td>
                    <td>{item.OpportunityOwner}</td>
                    <td>{item.Status}</td>
                    <td>{getDateInFormatMMMMDYYYY(item.RateStartDate)}</td>
                    <td>{getDateInFormatMMMMDYYYY(item.RateEndDate)}</td>
                    <td>
                      {item.AnticipetedRevanue !== undefined && item.AnticipetedRevanue !== null
                        ? "$" + item.AnticipetedRevanue
                        : ""}
                    </td>
                  </tr>
                );
              }
            })}
        </tbody>
      </>
    );
  };

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="d-flex align-items-center justify-content-between w-100"
          >
            <div> Import (LNR Opportunity) </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col xs={12}>
              <h6> Imported Data</h6>
              <div>
                <TableView
                  columnsData={SuccessfullImportdHeader}
                  tableData={tempSuccessfullImportedData}
                  isData={false}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-5">
              <Row>
                <Col md={11}>
                  <h6> Invalid Data</h6>
                </Col>
                <Col md={1}>
                  <Button
                    className="mb-2"
                    variant="primary"
                    disabled={tempInvalidImportedData.length ? false : true}
                    onClick={downloadTable}
                  >
                    <span className="me-2">
                      <img src={WhiteSaveIcon} alt="" />
                    </span>
                    Export
                  </Button>
                </Col>
              </Row>

              <div>
                <TableView
                  columnsData={InvalidImportdHeader}
                  tableData={tempInvalidImportedData}
                  isData={false}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Table
        id={"tableRef"}
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <RFPErrTableExport />
      </Table>
    </>
  );
}
