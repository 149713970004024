import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Col, Container, Table } from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import moment from "moment";
import XLSX from "sheetjs-style";
import { LeadReportList } from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import PageLoader from "../../Component/Loader/PageLoader";
import {
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  getDateInDBFormatForExcell,
  getDateInFormat,
  getDateTimeInDBFormat,
  getSortDataByObjKey,
} from "../../Services/CommonServices";
import TableView from "../../Component/Table/Index";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import GreenLabel from "../../Component/Labels/GreenLabel/Index";
import BlueLabel from "../../Component/Labels/BlueLabel/Index";
import RedLabel from "../../Component/Labels/RedLabel/Index";
import { useDownloadExcel } from "react-export-table-to-excel";
import ReadMoreButton from "../../Component/SeeeMoreBtn/Readmore";

const LeadReportModal = ({ ReportInput, showReport, setShowReport }) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [data, setData] = useState<any>([]);

  const fetchLeadGridList = async () => {
    let input = {
      propertyIDs: ReportInput.propertyID,
      statusTerm: ReportInput.status,
      sourceTerm: ReportInput.sourceTerm,
      startDate: ReportInput.startDate,
      endDate: ReportInput.endDate,
      ownerIDs: ReportInput.userIDs,
    };

    setPageLoading(true);
    let res = await LeadReportList(input, Token);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstData !== undefined &&
            res.data.data.lstData &&
            res.data.data.lstData.length
          ) {
            setData(res.data.data.lstData);
            setPageLoading(false);
          } else {
            setData([]);

            setPageLoading(false);
          }
        } else {
          setPageLoading(false);
        }
      } else {
        setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      setLoading(false);
      ToasterError(res.data.message);
    }
  };

  const LeadGridColumns = React.useMemo(
    () => [
      {
        Header: "Property Name",
        accessor: "PropertyName",
      },
      {
        Header: "Company/ Group Name",
        accessor: "companyGroupName",
        className: "w-243",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Source",
        accessor: "source",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: " FullAddress",
        accessor: "FullAddress",
      },
      {
        Header: "Date Created",
        accessor: "dateCreated",
      },
      {
        Header: "Assigned to",
        accessor: "assignedTo",
      },
      {
        Header: "Last Activity",
        accessor: "lastActivity",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
      {
        Header: "Activity Count",
        accessor: "activityCount",
      },
      {
        Header: "Comment",
        accessor: "comment",
      },
      {
        Header: "Activity Objective",
        accessor: "ActivityObjective",
      },
      {
        Header: "Activity Type",
        accessor: "ActivityType",
      },
      {
        Header: "Activity Date",
        accessor: "ActivityDate",
      },
      {
        Header: " Status",
        accessor: "ActivityStatus_Term",
      },

      {
        Header: "Details",
        accessor: "ActivityDetails",
      },
    ],
    []
  );

  let LeadGridData: any = [];

  data.length &&
    data.map((item: any) => {
      LeadGridData.push({
        controlRender: {
          status:
            item.Lead_Status_Term !== undefined &&
            item.Lead_Status_Term !== null &&
            item.Lead_Status_Term !== "" &&
            (item.Lead_Status_Term === "Open" ? (
              <GreenLabel successLabel={item.Lead_Status_Term} />
            ) : (
              <RedLabel dangerLabel={item.Lead_Status_Term} />
            )),
          priority:
            item.Priority !== undefined &&
            item.Priority !== null &&
            item.Priority !== "" &&
            (item.Priority === "Low" ? (
              <GreenLabel successLabel={item.Priority} />
            ) : item.Priority === "Normal" ? (
              <BlueLabel blueLabel={item.Priority} />
            ) : (
              <RedLabel dangerLabel={item.Priority} />
            )),
          dateCreated: item.CreatedOn ? getDateInFormat(item.CreatedOn) : "-",
          lastActivity: getDateInFormat(item.LastActivityDate),
          ActivityDate: getDateInFormat(item.DateOfCreated),
          ActivityStatus_Term:
            item.ActivityStatus_Term !== "" &&
            (item.ActivityStatus_Term === "Complete" ? (
              <>
                <GreenLabel successLabel={item.ActivityStatus_Term} />
              </>
            ) : (
              <>
                <RedLabel dangerLabel={item.ActivityStatus_Term} />
              </>
            )),
          ActivityDetails: <ReadMoreButton text={item.ActivityDetails} maxLength={70} />,
        },
        className: {
          dateCreated: "rightAlignClass text-end",
          lastActivity: "rightAlignClass text-end",
          activityCount: "text-center",
          action: "table-action sticky-action",
          subscribed:
            item.Lead_Status_Term === "true"
              ? "subs-active-class-add font-0 text-center"
              : "subs-inactive-class-add font-0 text-center",
        },
        PropertyName: item.PropertyName,
        companyGroupName: item.CompanyName,
        name: item.FirstName + " " + item.LastName,
        email: <a href={"mailto:" + item.Email}>{item.Email}</a>,
        status: item.Lead_Status_Term,
        source: item.Lead_Source_Term,
        phoneNumber:
          item.TelephoneNo !== undefined && item.TelephoneNo && item.TelephoneNo ? (
            <a href={"tel:" + item.TelephoneNo}>{item.TelephoneNo}</a>
          ) : (
            "-"
          ),
        dateCreated: item.CreatedOn ? getDateTimeInDBFormat(item.CreatedOn) : "-",
        assignedTo: item.OwnerName,
        lastActivity: getDateTimeInDBFormat(item.LastActivityDate),
        priority: item.Priority,
        activityCount: item.ActivityCount,
        comment: item.Comment,

        ActivityObjective: item.ActivitySubject,
        ActivityType: item.ActivityType_Term,
        ActivityDate: getDateTimeInDBFormat(item.DateOfCreated),
        ActivityStatus_Term: item.ActivityStatus_Term,
        FullAddress:
          (item.Address && item.Address + ",") +
          (item.City && item.City + ",") +
          (item.State && item.State + ",") +
          (item.Country && item.Country + ",") +
          item.Zipcode,
        ActivityDetails: item.ActivityDetails,
      });
    });

  useEffect(() => {
    fetchLeadGridList();
  }, []);

  const handleClosePopup = () => {
    setShowReport(false);
  };

  // const tableRef = useRef(null);

  // const { onDownload } = useDownloadExcel({
  //   currentTableRef: tableRef.current,
  //   filename: "Lead Report" + ReportInput.startDate + ReportInput.endDate,
  //   sheet: "LeadReport",
  // });

  // let LeadTableExport: any = () => {
  //   return (
  //     <>
  //       <thead>
  //         <tr>
  //           <th
  //             colSpan={4}
  //             style={{ background: "#417FF9", color: "#ffffff", fontSize: "11", fontWeight: "600" }}
  //           >
  //             Lead Report Grid - ({ReportInput.startDate + " " + ReportInput.endDate})
  //             {ReportInput.status ? "- (" + ReportInput.status + ") " : ""}
  //             {ReportInput.sourceTerm ? "- (" + ReportInput.sourceTerm + ")" : ""}
  //           </th>
  //         </tr>
  //         <tr></tr>
  //         <tr>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Property Name
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Company/ Group Name
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Name
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Email
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Status
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Source
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Phone Number
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             FullAddress
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Date Created
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Assigned to
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Last Activity
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Priority
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Activity Count
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Comment
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Activity Objective
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Activity Type
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Activity Date
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Status
  //           </th>
  //           <th
  //             style={{
  //               background: "#0D83BB",
  //               color: "#ffffff",
  //               fontSize: "11",
  //               fontWeight: "600",
  //             }}
  //           >
  //             Details
  //           </th>
  //         </tr>
  //       </thead>

  //       <tbody>
  //         {data.map((item: any, idx) => {
  //           return (
  //             <tr key={idx}>
  //               <td>{item.PropertyName}</td>
  //               <td>{item.CompanyName}</td>
  //               <td>{item.FirstName + " " + item.LastName}</td>
  //               <td>{item.Email}</td>
  //               <td>{item.Lead_Status_Term}</td>
  //               <td>{item.Lead_Source_Term}</td>
  //               <td>{item.TelephoneNo}</td>
  //               <td>
  //                 {(item.Address && item.Address + ",") +
  //                   (item.City && item.City + ",") +
  //                   (item.State && item.State + ",") +
  //                   (item.Country && item.Country + ",") +
  //                   item.Zipcode}
  //               </td>
  //               <td style={{ textAlign: "right" }}>{getDateInFormat(item.CreatedOn)}</td>
  //               <td>{item.OwnerName}</td>
  //               <td style={{ textAlign: "right" }}>{getDateInFormat(item.LastActivityDate)}</td>
  //               <td>{item.Priority}</td>
  //               <td style={{ textAlign: "center" }}>{item.ActivityCount}</td>
  //               <td>{item.Comment}</td>
  //               <td>{item.ActivitySubject}</td>
  //               <td>{item.ActivityType_Term}</td>
  //               <td style={{ textAlign: "right" }}>{getDateInFormat(item.DateOfCreated)}</td>
  //               <td>{item.ActivityStatus_Term}</td>
  //               <td>
  //                 <div
  //                   className="mt-1"
  //                   dangerouslySetInnerHTML={{ __html: item.ActivityDetails }}
  //                 ></div>
  //               </td>
  //             </tr>
  //           );
  //         })}
  //       </tbody>
  //     </>
  //   );
  // };

  async function getFilteredExportData(newArray) {
    let localFilters = JSON.parse(localStorage.getItem("leadReportFilter"));
    let mainTableData: any = [];
    newArray !== undefined &&
      newArray !== null &&
      newArray.map((item: any) => {
        mainTableData.push({
          PropertyName: item.PropertyName,
          companyGroupName: item.CompanyGroupName,
          name: item.Name,
          email: item.Email,
          status: item.Status,
          source: item.Source,
          phoneNumber: item.PhoneNumber,
          FullAddress: item.FullAddress,
          dateCreated: item.DateCreated,
          assignedTo: item.AssignedTo,
          lastActivity: item.LastActivity,
          priority: item.Priority,
          activityCount: item.ActivityCount,
          comment: item.Comment,
          ActivityObjective: item.ActivityObjective,
          ActivityType: item.ActivityType,
          ActivityDate: item.ActivityDate,
          ActivityStatus_Term: item.ActivityStatus,
          ActivityDetails: item.Details,
        });
      });

    if (localFilters.isSort.length !== 0) {
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === false) {
        // asc
        let ascData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "asc");
        let ascDataBind: any = [];
        ascData.map((item: any) => {
          ascDataBind.push({
            PropertyName: item.PropertyName,
            CompanyGroupName: item.companyGroupName,
            Name: item.name,
            Email: item.email,
            Status: item.status,
            Source: item.source,
            PhoneNumber: item.phoneNumber,
            FullAddress: item.FullAddress,
            DateCreated: item.dateCreated,
            AssignedTo: item.assignedTo,
            LastActivity: item.lastActivity,
            Priority: item.priority,
            ActivityCount: item.activityCount,
            Comment: item.comment,
            ActivityObjective: item.ActivityObjective,
            ActivityType: item.ActivityType,
            ActivityDate: item.ActivityDate,
            ActivityStatus: item.ActivityStatus_Term,
            Details: item.ActivityDetails,
          });
        });

        return ascDataBind;
      }
      if (localFilters.isSort[0].id !== "" && localFilters.isSort[0].desc === true) {
        // desc
        let descData = await getSortDataByObjKey(localFilters.isSort[0].id, mainTableData, "desc");

        let descDataBind: any = [];
        descData.map((item: any) => {
          descDataBind.push({
            PropertyName: item.PropertyName,
            CompanyGroupName: item.companyGroupName,
            Name: item.name,
            Email: item.email,
            Status: item.status,
            Source: item.source,
            PhoneNumber: item.phoneNumber,
            FullAddress: item.FullAddress,
            DateCreated: item.dateCreated,
            AssignedTo: item.assignedTo,
            LastActivity: item.lastActivity,
            Priority: item.priority,
            ActivityCount: item.activityCount,
            Comment: item.comment,
            ActivityObjective: item.ActivityObjective,
            ActivityType: item.ActivityType,
            ActivityDate: item.ActivityDate,
            ActivityStatus: item.ActivityStatus_Term,
            Details: item.ActivityDetails,
          });
        });

        return descDataBind;
      }
      if (localFilters.isSort[0].id === "" && localFilters.isSort[0].desc === true) {
        // Normal
        let normalDataBind: any = [];
        mainTableData.map((item: any) => {
          normalDataBind.push({
            PropertyName: item.PropertyName,
            CompanyGroupName: item.companyGroupName,
            Name: item.name,
            Email: item.email,
            Status: item.status,
            Source: item.source,
            PhoneNumber: item.phoneNumber,
            FullAddress: item.FullAddress,
            DateCreated: item.dateCreated,
            AssignedTo: item.assignedTo,
            LastActivity: item.lastActivity,
            Priority: item.priority,
            ActivityCount: item.activityCount,
            Comment: item.comment,
            ActivityObjective: item.ActivityObjective,
            ActivityType: item.ActivityType,
            ActivityDate: item.ActivityDate,
            ActivityStatus: item.ActivityStatus_Term,
            Details: item.ActivityDetails,
          });
        });

        return normalDataBind;
      }
    } else {
      let normalInitDataBind: any = [];
      mainTableData.map((item: any) => {
        normalInitDataBind.push({
          PropertyName: item.PropertyName,
          CompanyGroupName: item.companyGroupName,
          Name: item.name,
          Email: item.email,
          Status: item.status,
          Source: item.source,
          PhoneNumber: item.phoneNumber,
          FullAddress: item.FullAddress,
          DateCreated: item.dateCreated,
          AssignedTo: item.assignedTo,
          LastActivity: item.lastActivity,
          Priority: item.priority,
          ActivityCount: item.activityCount,
          Comment: item.comment,
          ActivityObjective: item.ActivityObjective,
          ActivityType: item.ActivityType,
          ActivityDate: item.ActivityDate,
          ActivityStatus: item.ActivityStatus_Term,
          Details: item.ActivityDetails,
        });
      });
      return normalInitDataBind;
    }
  }

  const exportData = async () => {
    let headStyles = {
      font: {
        bold: true,
        sz: 11,
        name: "Arial",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "417FF9",
        },
        fgColor: {
          rgb: "417FF9",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let headStylesCell = {
      font: {
        bold: true,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "ffffff",
        },
      },
      bold: true,
      fill: {
        patternType: "solid",
        bgColor: {
          rgb: "0D83BB",
        },
        fgColor: {
          rgb: "0D83BB",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };
    let cellStyle = {
      font: {
        bold: false,
        sz: 11,
        name: "Calibri",
        color: {
          rgb: "000000",
        },
      },
      alignment: {
        vertical: "center",
        horizontal: "left",
        wrapText: false,
      },
      border: {
        top: { style: "thin", color: { auto: 1 } },
        bottom: { style: "thin", color: { auto: 1 } },
        left: { style: "thin", color: { auto: 1 } },
        right: { style: "thin", color: { auto: 1 } },
      },
    };

    // Top Main Header : ---------------------------
    let ws = XLSX.utils.json_to_sheet([], {
      header: [
        `Lead Grid  (${
          moment(ReportInput.startDate).format("MM/DD/YYYY") +
          " - " +
          moment(ReportInput.endDate).format("MM/DD/YYYY")
        })`,
      ],
    });

    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }];
    ws["!merges"] = merge;

    const rangeTotalAccounts: any = XLSX.utils.decode_range(ws["!ref"] ?? "");
    const rowCount = rangeTotalAccounts.e.r;
    const columnCount = rangeTotalAccounts.e.c;

    for (var R = rangeTotalAccounts.s.r; R <= rangeTotalAccounts.e.r; ++R) {
      for (var C = rangeTotalAccounts.s.c; C <= rangeTotalAccounts.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);

        if (typeof ws[cell_ref] !== "undefined") {
          if (C === 0 && R === 0) {
            ws[cell_ref].s = headStyles;
          } else if (R === 2) {
            ws[cell_ref].s = headStylesCell;
          } else {
            ws[cell_ref].s = {
              ...cellStyle,
              alignment: {
                ...cellStyle.alignment,
                horizontal: C === 1 || C === 2 ? "right" : "left",
              },
            };
          }
        }
      }
    }
    const newArray = data.map(({ AccountID, ...keepAttrs }) => keepAttrs);

    let newArrayJsonToExportInExcell: any = [];
    data.length &&
      data.map((item: any) => {
        newArrayJsonToExportInExcell.push({
          PropertyName: item.PropertyName,
          CompanyGroupName: item.CompanyName,
          Name: item.FirstName + " " + item.LastName,
          Email: item.Email,
          Status: item.Lead_Status_Term,
          Source: item.Lead_Source_Term,
          PhoneNumber: item.TelephoneNo,
          FullAddress:
            (item.Address && item.Address + ",") +
            (item.City && item.City + ",") +
            (item.State && item.State + ",") +
            (item.Country && item.Country + ",") +
            item.Zipcode,
          DateCreated: getDateInDBFormatForExcell(item.CreatedOn),
          AssignedTo: item.OwnerName,
          LastActivity: getDateInDBFormatForExcell(item.LastActivityDate),
          Priority: item.Priority,
          ActivityCount: item.ActivityCount,
          Comment: item.Comment,
          ActivityObjective: item.ActivitySubject,
          ActivityType: item.ActivityType_Term,
          ActivityDate: getDateInDBFormatForExcell(item.DateOfCreated),
          ActivityStatus: item.ActivityStatus_Term,
          Details: item.ActivityDetails,
        });
      });

    // ------------------- Sort Data By Filter :
    let newArraySortedData = await getFilteredExportData(newArrayJsonToExportInExcell);
    // ------------------- Sort Data By Filter End:

    XLSX.utils.sheet_add_aoa(ws, [Object.keys(newArraySortedData[0])], {
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 3),
    });
    XLSX.utils.sheet_add_json(ws, newArraySortedData, {
      header: Object.keys(newArraySortedData[0]),
      skipHeader: true,
      origin: "A" + (parseInt(rangeTotalAccounts.e.r) + 4),
    });

    const rowDataCount: any = XLSX.utils.decode_range(ws["!ref"] ?? "");

    for (var R = rowCount + 2; R <= rowDataCount.e.r; ++R) {
      for (var C = rowDataCount.s.c; C <= rowDataCount.e.c; ++C) {
        var cell_address = { c: C, r: R };
        var cell_ref = XLSX.utils.encode_cell(cell_address);
        if (typeof ws[cell_ref] !== "undefined") {
          if (rowCount + 2 === R) {
            ws[cell_ref].s = headStylesCell;
          }
          // else if(R ===  2){
          //   ws[cell_ref].s = headStylesCell
          // }
          else {
            let checkForRightArray = [8, 10, 16];
            let checkForCenterArray = [12];
            if (checkForRightArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "right",
                },
              };
            } else if (checkForCenterArray.includes(C)) {
              ws[cell_ref].s = {
                ...cellStyle,
                alignment: {
                  ...cellStyle.alignment,
                  horizontal: "center",
                },
              };
            }
          }
        }
      }
    }

    let cols: any = [];

    Object.keys(newArraySortedData[0]).forEach(() => {
      cols = [...cols, { wch: 22.07 }];
    });
    ws["!cols"] = cols;
    var wb = XLSX.utils.book_new();
    let filename = `LeadReport.xlsx`;

    XLSX.utils.book_append_sheet(wb, ws, filename);
    XLSX.writeFile(wb, filename);
  };

  return (
    <>
      <Modal
        show={showReport}
        onHide={() => {
          handleClosePopup();
        }}
        fullscreen
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Lead Grid Report - (
            {moment(ReportInput.startDate).format("MM/DD/YYYY") +
              " - " +
              moment(ReportInput.endDate).format("MM/DD/YYYY")}
            )
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {!pageLoading ? (
              <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
                <TableView
                  isData={loading}
                  columnsData={LeadGridColumns}
                  tableData={LeadGridData}
                />
              </Col>
            ) : (
              <PageLoader />
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={handleClosePopup}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            disabled={data.length && !loading ? false : true}
            onClick={(e) => {
              e.preventDefault();
              if (data.length && !loading) {
                exportData();
              }
            }}
            // onClick={onDownload}
            type="button"
            variant="primary"
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Table
        ref={tableRef}
        responsive
        bordered
        className="table-without-space mb-0"
        style={{ display: "none" }}
      >
        <LeadTableExport />
      </Table> */}
    </>
  );
};

export default LeadReportModal;
