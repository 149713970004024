import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  getWeeklyReportList,
  WeeklyReportSingleFileExportPDF,
} from "../../Services/ReportServices";

import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";

import { Button, Modal } from "react-bootstrap";
import WeeklyReportUserData from "./WeeklyReportUserData";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
import { AppConfigData, ToasterError } from "../../Services/CommonServices";
import moment from "moment";

function WeeklyReportsModal(props: any) {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [WeeklyReportAllData, setWeeklyReportAllData]: any = useState({
    IsData: true,
    data: [],
  });
  const [Downloading, setDownloading] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setData]: any = useState([]);

  React.useEffect(() => {
    if (props.StateValue.userIDs.includes(",")) {
      let splitedUser: any = props.StateValue.userIDs.split(",");

      if (props.StateValue !== undefined && props.StateValue !== null) {
        let tempObj: any = {
          endDate: props.StateValue.endDate,
          isIncludeActivity: props.StateValue.isIncludeActivity,
          propertyID: props.StateValue.propertyID,
          startDate: props.StateValue.startDate,
          userIDs: "",
        };
        splitedUser.length &&
          splitedUser.map((item) => {
            item;
            tempObj = {
              ...tempObj,
              userIDs: item,
            };
            getReportOnLoad(tempObj);
          });
      }
    } else {
      getReportOnLoad(props.StateValue);
    }
  }, []);

  async function getReportOnLoad(data: any) {
    let input = data;
    setWeeklyReportAllData({ IsData: true, data: [] });
    setloading(true);
    const res: any = await getWeeklyReportList(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          if (
            res.data.data.lstWeeklyReportData !== undefined &&
            res.data.data.lstWeeklyReportData !== null &&
            res.data.datalstWeeklyReportData !== ""
          ) {
            // setWeeklyReportAllData({ IsData: false, data: res.data.data.lstWeeklyReportData });
            setData((t: any) => [...t, ...res.data.data.lstWeeklyReportData]);
            setloading(false);
          }
        } else {
          setWeeklyReportAllData({ IsData: false, data: [] });
        }
      } else {
        setWeeklyReportAllData({ IsData: false, data: [] });
      }
    } else {
      setWeeklyReportAllData({ IsData: false, data: [] });
    }
  }

  async function exportPDF() {
    let inputData: any = {
      propertyID: props.StateValue.propertyID,
      startDate: props.StateValue.startDate,
      endDate: props.StateValue.endDate,
      userIDs: props.StateValue.userIDs,
      isIncludeActivity: props.StateValue.isIncludeActivity,
    };

    setDownloading(true);
    let res: any = await WeeklyReportSingleFileExportPDF(inputData, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloading(false);
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    } else {
      ToasterError(res.data.message);
      setDownloading(false);
    }
  }

  return (
    <Modal {...props} fullscreen>
      <Modal.Header closeButton>
        <Modal.Title>
          Weekly Report (
          {moment(props.StateValue.startDate).format("MM/DD/YYYY") +
            "-" +
            moment(props.StateValue.endDate).format("MM/DD/YYYY")}
          ){" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading ? (
          data.length ? (
            data.map((item: any) => {
              return (
                <>
                  <WeeklyReportUserData item={item} handleActiviityData={props.StateValue} />
                </>
              );
            })
          ) : (
            <>
              <div className="opportunity">
                <div className="noRecordFound">
                  <img src={noRecordFound} alt="" />
                </div>
              </div>
            </>
          )
        ) : (
          <>
            <PageLoader />
          </>
        )}

        {Downloading && (
          <div className="calender-loader-main">
            <PageLoader />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="pt-2">
        <Button variant="secondary" onClick={props.onHide}>
          <span className="me-2">
            <img src={BlackCancelIcon} alt="" />
          </span>
          Close
        </Button>
        {/* <Button variant="primary ms-0">
          <span className="me-2">
            <img src={PdfIcon} alt="" />
          </span>
          Generate PDF
        </Button> */}

        <Button
          variant="primary ms-0"
          disabled={data.length ? false : true}
          onClick={(e) => {
            e.preventDefault();
            exportPDF();
          }}
        >
          <span className="me-2">
            <img src={PdfIcon} alt="" />
          </span>
          Export PDF
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WeeklyReportsModal;
