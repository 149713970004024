import React, { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import {
  GroupPaceReportList,
  GroupPaceReportListExport,
  GroupPaceReportListExportPDF,
} from "../../Services/ReportServices";
import { useSelector } from "react-redux";
import { AppConfigData, ToasterError } from "../../Services/CommonServices";
import PageLoader from "../../Component/Loader/PageLoader";

const GroupPaceReportModal = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [GroupPaceReportData, setGroupPaceReportData]: any = useState([]);
  const [loading, setLoading]: any = useState(false);
  const [Downloading, setDownloading]: any = useState(false);
  const [DownloadingPDF, setDownloadingPDF]: any = useState(false);

  async function fetchGroupPaceReportData() {
    let input: any = {
      propertyID: props.groupPaceReportData.propertyID,
      yearID: props.groupPaceReportData.yearID,
    };

    setLoading(true);
    let res: any = await GroupPaceReportList(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          setGroupPaceReportData(res.data.data);
          setLoading(false);
        } else {
          ToasterError(res.data.message);
          setLoading(false);
        }
      } else {
        ToasterError(res.data.message);
        setLoading(false);
      }
    } else {
      ToasterError(res.data.message);
      setLoading(false);
    }
  }

  async function GenerateExcel() {
    let input: any = {
      propertyID: props.groupPaceReportData.propertyID,
      yearID: props.groupPaceReportData.yearID,
    };

    setDownloading(true);
    let res: any = await GroupPaceReportListExport(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloading(false);
        } else {
          ToasterError(res.data.message);
          setDownloading(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloading(false);
      }
    } else {
      ToasterError(res.data.message);
      setDownloading(false);
    }
  }

  async function exportPDF() {
    let input: any = {
      propertyID: props.groupPaceReportData.propertyID,
      yearID: props.groupPaceReportData.yearID,
    };

    setDownloadingPDF(true);
    let res: any = await GroupPaceReportListExportPDF(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null && res.data !== "") {
        if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
          window.open(AppConfigData.APIBasePath + res.data.data);
          setDownloadingPDF(false);
        } else {
          ToasterError(res.data.message);
          setDownloadingPDF(false);
        }
      } else {
        ToasterError(res.data.message);
        setDownloadingPDF(false);
      }
    } else {
      ToasterError(res.data.message);
      setDownloadingPDF(false);
    }
  }

  React.useEffect(() => {
    fetchGroupPaceReportData();
  }, []);

  //#region jan,Feb,Mar Variables

  // Definite
  let presentMonth1Room = 0;
  let presentMonth1ADR = 0;
  let presentMonth1REvenue = 0;

  let pastMonth1Room = 0;
  let pastMonth1ADR = 0;
  let pastMonth1REvenue = 0;

  let presentMonth2Room = 0;
  let presentMonth2ADR = 0;
  let presentMonth2REvenue = 0;

  let pastMonth2Room = 0;
  let pastMonth2ADR = 0;
  let pastMonth2REvenue = 0;

  let presentMonth3Room = 0;
  let presentMonth3ADR = 0;
  let presentMonth3REvenue = 0;

  let pastMonth3Room = 0;
  let pastMonth3ADR = 0;
  let pastMonth3REvenue = 0;

  // Tentative
  let presentTenMonth1Room = 0;
  let presentTenMonth1ADR = 0;
  let presentTenMonth1REvenue = 0;

  let presentTenMonth2Room = 0;
  let presentTenMonth2ADR = 0;
  let presentTenMonth2REvenue = 0;

  let presentTenMonth3Room = 0;
  let presentTenMonth3ADR = 0;
  let presentTenMonth3REvenue = 0;

  let pastTenMonth1Room = 0;
  let pastTenMonth1ADR = 0;
  let pastTenMonth1REvenue = 0;

  let pastTenMonth2Room = 0;
  let pastTenMonth2ADR = 0;
  let pastTenMonth2REvenue = 0;

  let pastTenMonth3Room = 0;
  let pastTenMonth3ADR = 0;
  let pastTenMonth3REvenue = 0;

  // Actual Pickups'

  let ActualPicMonth1Room = 0;
  let ActualPicMonth1ADR = 0;
  let ActualPicMonth1REvenue = 0;

  let ActualPicMonth2Room = 0;
  let ActualPicMonth2ADR = 0;
  let ActualPicMonth2REvenue = 0;

  let ActualPicMonth3Room = 0;
  let ActualPicMonth3ADR = 0;
  let ActualPicMonth3REvenue = 0;

  let ActualRoomMonth1Room = 0;
  let ActualRoomMonth1ADR = 0;
  let ActualRoomMonth1REvenue = 0;

  let ActualRoomMonth2Room = 0;
  let ActualRoomMonth2ADR = 0;
  let ActualRoomMonth2REvenue = 0;

  let ActualRoomMonth3Room = 0;
  let ActualRoomMonth3ADR = 0;
  let ActualRoomMonth3REvenue = 0;

  //#endregion Jan,Feb,Mar End

  //#region April,May,June Variables 1=4,2=5,3=6

  // Definite
  let presentMonth4Room = 0;
  let presentMonth4ADR = 0;
  let presentMonth4REvenue = 0;

  let pastMonth4Room = 0;
  let pastMonth4ADR = 0;
  let pastMonth4REvenue = 0;

  let presentMonth5Room = 0;
  let presentMonth5ADR = 0;
  let presentMonth5REvenue = 0;

  let pastMonth5Room = 0;
  let pastMonth5ADR = 0;
  let pastMonth5REvenue = 0;

  let presentMonth6Room = 0;
  let presentMonth6ADR = 0;
  let presentMonth6REvenue = 0;

  let pastMonth6Room = 0;
  let pastMonth6ADR = 0;
  let pastMonth6REvenue = 0;

  // Tentative
  let presentTenMonth4Room = 0;
  let presentTenMonth4ADR = 0;
  let presentTenMonth4REvenue = 0;

  let presentTenMonth5Room = 0;
  let presentTenMonth5ADR = 0;
  let presentTenMonth5REvenue = 0;

  let presentTenMonth6Room = 0;
  let presentTenMonth6ADR = 0;
  let presentTenMonth6REvenue = 0;

  let pastTenMonth4Room = 0;
  let pastTenMonth4ADR = 0;
  let pastTenMonth4REvenue = 0;

  let pastTenMonth5Room = 0;
  let pastTenMonth5ADR = 0;
  let pastTenMonth5REvenue = 0;

  let pastTenMonth6Room = 0;
  let pastTenMonth6ADR = 0;
  let pastTenMonth6REvenue = 0;

  // Actual Pickups'

  let ActualPicMonth4Room = 0;
  let ActualPicMonth4ADR = 0;
  let ActualPicMonth4REvenue = 0;

  let ActualPicMonth5Room = 0;
  let ActualPicMonth5ADR = 0;
  let ActualPicMonth5REvenue = 0;

  let ActualPicMonth6Room = 0;
  let ActualPicMonth6ADR = 0;
  let ActualPicMonth6REvenue = 0;

  let ActualRoomMonth4Room = 0;
  let ActualRoomMonth4ADR = 0;
  let ActualRoomMonth4REvenue = 0;

  let ActualRoomMonth5Room = 0;
  let ActualRoomMonth5ADR = 0;
  let ActualRoomMonth5REvenue = 0;

  let ActualRoomMonth6Room = 0;
  let ActualRoomMonth6ADR = 0;
  let ActualRoomMonth6REvenue = 0;

  //#endregion April,May,June End

  //#region July,August,Sept Variables 4=7,5=8,6=9

  // Definite
  let presentMonth7Room = 0;
  let presentMonth7ADR = 0;
  let presentMonth7REvenue = 0;

  let pastMonth7Room = 0;
  let pastMonth7ADR = 0;
  let pastMonth7REvenue = 0;

  let presentMonth8Room = 0;
  let presentMonth8ADR = 0;
  let presentMonth8REvenue = 0;

  let pastMonth8Room = 0;
  let pastMonth8ADR = 0;
  let pastMonth8REvenue = 0;

  let presentMonth9Room = 0;
  let presentMonth9ADR = 0;
  let presentMonth9REvenue = 0;

  let pastMonth9Room = 0;
  let pastMonth9ADR = 0;
  let pastMonth9REvenue = 0;

  // Tentative
  let presentTenMonth7Room = 0;
  let presentTenMonth7ADR = 0;
  let presentTenMonth7REvenue = 0;

  let presentTenMonth8Room = 0;
  let presentTenMonth8ADR = 0;
  let presentTenMonth8REvenue = 0;

  let presentTenMonth9Room = 0;
  let presentTenMonth9ADR = 0;
  let presentTenMonth9REvenue = 0;

  let pastTenMonth7Room = 0;
  let pastTenMonth7ADR = 0;
  let pastTenMonth7REvenue = 0;

  let pastTenMonth8Room = 0;
  let pastTenMonth8ADR = 0;
  let pastTenMonth8REvenue = 0;

  let pastTenMonth9Room = 0;
  let pastTenMonth9ADR = 0;
  let pastTenMonth9REvenue = 0;

  // Actual Pickups'

  let ActualPicMonth7Room = 0;
  let ActualPicMonth7ADR = 0;
  let ActualPicMonth7REvenue = 0;

  let ActualPicMonth8Room = 0;
  let ActualPicMonth8ADR = 0;
  let ActualPicMonth8REvenue = 0;

  let ActualPicMonth9Room = 0;
  let ActualPicMonth9ADR = 0;
  let ActualPicMonth9REvenue = 0;

  let ActualRoomMonth7Room = 0;
  let ActualRoomMonth7ADR = 0;
  let ActualRoomMonth7REvenue = 0;

  let ActualRoomMonth8Room = 0;
  let ActualRoomMonth8ADR = 0;
  let ActualRoomMonth8REvenue = 0;

  let ActualRoomMonth9Room = 0;
  let ActualRoomMonth9ADR = 0;
  let ActualRoomMonth9REvenue = 0;

  //#endregion July,August,Sept End

  //#region Oct,Nov,Dec Variables 7=10,8=11,9=12

  // Definite
  let presentMonth10Room = 0;
  let presentMonth10ADR = 0;
  let presentMonth10REvenue = 0;

  let pastMonth10Room = 0;
  let pastMonth10ADR = 0;
  let pastMonth10REvenue = 0;

  let presentMonth11Room = 0;
  let presentMonth11ADR = 0;
  let presentMonth11REvenue = 0;

  let pastMonth11Room = 0;
  let pastMonth11ADR = 0;
  let pastMonth11REvenue = 0;

  let presentMonth12Room = 0;
  let presentMonth12ADR = 0;
  let presentMonth12REvenue = 0;

  let pastMonth12Room = 0;
  let pastMonth12ADR = 0;
  let pastMonth12REvenue = 0;

  // Tentative
  let presentTenMonth10Room = 0;
  let presentTenMonth10ADR = 0;
  let presentTenMonth10REvenue = 0;

  let presentTenMonth11Room = 0;
  let presentTenMonth11ADR = 0;
  let presentTenMonth11REvenue = 0;

  let presentTenMonth12Room = 0;
  let presentTenMonth12ADR = 0;
  let presentTenMonth12REvenue = 0;

  let pastTenMonth10Room = 0;
  let pastTenMonth10ADR = 0;
  let pastTenMonth10REvenue = 0;

  let pastTenMonth11Room = 0;
  let pastTenMonth11ADR = 0;
  let pastTenMonth11REvenue = 0;

  let pastTenMonth12Room = 0;
  let pastTenMonth12ADR = 0;
  let pastTenMonth12REvenue = 0;

  // Actual Pickups'

  let ActualPicMonth10Room = 0;
  let ActualPicMonth10ADR = 0;
  let ActualPicMonth10REvenue = 0;

  let ActualPicMonth11Room = 0;
  let ActualPicMonth11ADR = 0;
  let ActualPicMonth11REvenue = 0;

  let ActualPicMonth12Room = 0;
  let ActualPicMonth12ADR = 0;
  let ActualPicMonth12REvenue = 0;

  let ActualRoomMonth10Room = 0;
  let ActualRoomMonth10ADR = 0;
  let ActualRoomMonth10REvenue = 0;

  let ActualRoomMonth11Room = 0;
  let ActualRoomMonth11ADR = 0;
  let ActualRoomMonth11REvenue = 0;

  let ActualRoomMonth12Room = 0;
  let ActualRoomMonth12ADR = 0;
  let ActualRoomMonth12REvenue = 0;

  //#endregion Oct,Nov,Dec End

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>
            Group Pace Report ({props.groupPaceReportData && props.groupPaceReportData.year})
          </Modal.Title>
        </Modal.Header>
        {!loading ? (
          <Modal.Body className="meeting-pace-report">
            {GroupPaceReportData.propertyData !== undefined &&
            GroupPaceReportData.propertyData &&
            GroupPaceReportData.propertyData.PropertyLogo ? (
              <div className="text-center mt-3">
                <img
                  src={GroupPaceReportData.propertyData.PropertyLogo}
                  style={{ width: "200px", maxHeight: "150px" }}
                  alt=""
                />
              </div>
            ) : (
              ""
            )}
            <div className="report-col mt-3 mb-3">
              <div className="report-title">
                <span>
                  {GroupPaceReportData.propertyData &&
                    GroupPaceReportData.propertyData.PropertyName}
                </span>
              </div>
            </div>

            {/* Table First */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center" colSpan={3}>
                    January
                  </th>
                  <th className="text-center" colSpan={3}>
                    Februry
                  </th>
                  <th className="text-center" colSpan={3}>
                    March
                  </th>
                  <th className="text-center" colSpan={3}>
                    Quarter 1
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: "550" }}>
                <tr>
                  <th></th>
                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}{" "}
                  </td>
                  {GroupPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstCurrentYearDefiniteData.map((defCur) => (
                      <>
                        {defCur.MonthNo === 1 && (
                          <>
                            <td className="text-center">
                              {(presentMonth1Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth1ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth1REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 2 && (
                          <>
                            <td className="text-center">
                              {(presentMonth2Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth2ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth2REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 3 && (
                          <>
                            <td className="text-center">
                              {(presentMonth3Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth3ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth3REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {presentMonth1Room + presentMonth2Room + presentMonth3Room}
                  </td>
                  <td className="text-end">
                    {presentMonth1ADR + presentMonth2ADR + presentMonth3ADR}
                  </td>
                  <td className="text-end">
                    {presentMonth1REvenue + presentMonth2REvenue + presentMonth3REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>

                  {GroupPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstPreviousYearDefiniteData.map((defPrev) => (
                      <>
                        {defPrev.MonthNo === 1 && (
                          <>
                            <td className="text-center">{(pastMonth1Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth1ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth1REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 2 && (
                          <>
                            <td className="text-center">{(pastMonth2Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth2ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth2REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 3 && (
                          <>
                            <td className="text-center">{(pastMonth3Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth3ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth3REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {pastMonth1Room + pastMonth2Room + pastMonth3Room}
                  </td>
                  <td className="text-end">
                    {parseFloat(pastMonth1ADR + pastMonth2ADR + pastMonth3ADR).toFixed(2)}
                  </td>
                  <td className="text-end">
                    {pastMonth1REvenue + pastMonth2REvenue + pastMonth3REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentMonth1Room - pastMonth1Room > 0
                        ? "font-green text-center"
                        : presentMonth1Room - pastMonth1Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth1Room - pastMonth1Room}
                  </td>
                  <td
                    className={
                      presentMonth1ADR - pastMonth1ADR > 0
                        ? "font-green text-end"
                        : presentMonth1ADR - pastMonth1ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth1ADR - pastMonth1ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentMonth1REvenue - pastMonth1REvenue > 0
                        ? "font-green text-end"
                        : presentMonth1REvenue - pastMonth1REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth1REvenue - pastMonth1REvenue}
                  </td>

                  <td
                    className={
                      presentMonth2Room - pastMonth2Room > 0
                        ? "font-green text-center"
                        : presentMonth2Room - pastMonth2Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth2Room - pastMonth2Room}
                  </td>
                  <td
                    className={
                      presentMonth2ADR - pastMonth2ADR > 0
                        ? "font-green text-end"
                        : presentMonth2ADR - pastMonth2ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth2ADR - pastMonth2ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth2REvenue - pastMonth2REvenue > 0
                        ? "font-green text-end"
                        : presentMonth2REvenue - pastMonth2REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth2REvenue - pastMonth2REvenue}
                  </td>

                  <td
                    className={
                      presentMonth3Room - pastMonth3Room > 0
                        ? "font-green text-center"
                        : presentMonth3Room - pastMonth3Room === 0
                        ? ""
                        : "font-red text-center"
                    }
                  >
                    {presentMonth3Room - pastMonth3Room}
                  </td>
                  <td
                    className={
                      presentMonth3ADR - pastMonth3ADR > 0
                        ? "font-green text-end"
                        : presentMonth3ADR - pastMonth3ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth3ADR - pastMonth3ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth3REvenue - pastMonth3REvenue > 0
                        ? "font-green text-end"
                        : presentMonth3REvenue - pastMonth3REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth3REvenue - pastMonth3REvenue}
                  </td>

                  <td
                    className={
                      presentMonth1Room +
                        presentMonth2Room +
                        presentMonth3Room -
                        (pastMonth1Room + pastMonth2Room + pastMonth3Room) >
                      0
                        ? "font-green text-center"
                        : presentMonth1Room +
                            presentMonth2Room +
                            presentMonth3Room -
                            (pastMonth1Room + pastMonth2Room + pastMonth3Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth1Room +
                      presentMonth2Room +
                      presentMonth3Room -
                      (pastMonth1Room + pastMonth2Room + pastMonth3Room)}
                  </td>
                  <td
                    className={
                      presentMonth1ADR +
                        presentMonth2ADR +
                        presentMonth3ADR -
                        (pastMonth1ADR + pastMonth2ADR + pastMonth3ADR) >
                      0
                        ? "font-green text-end"
                        : presentMonth1ADR +
                            presentMonth2ADR +
                            presentMonth3ADR -
                            (pastMonth1ADR + pastMonth2ADR + pastMonth3ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentMonth1ADR +
                        presentMonth2ADR +
                        presentMonth3ADR -
                        (pastMonth1ADR + pastMonth2ADR + pastMonth3ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth1REvenue +
                        presentMonth2REvenue +
                        presentMonth3REvenue -
                        (pastMonth1REvenue + pastMonth2REvenue + pastMonth3REvenue) >
                      0
                        ? "font-green text-end"
                        : presentMonth1REvenue +
                            presentMonth2REvenue +
                            presentMonth3REvenue -
                            (pastMonth1REvenue + pastMonth2REvenue + pastMonth3REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth1REvenue +
                      presentMonth2REvenue +
                      presentMonth3REvenue -
                      (pastMonth1REvenue + pastMonth2REvenue + pastMonth3REvenue)}
                  </td>
                </tr>

                {/* Space between Definite & Tantative */}

                <tr>
                  <td>
                    Tentatives -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}
                  </td>
                  {GroupPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    GroupPaceReportData.lstCurrentYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 1 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth1Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth1ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth1REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 2 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth2Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth2ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth2REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 3 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth3Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth3ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth3REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {presentTenMonth1Room + presentTenMonth2Room + presentTenMonth3Room}
                  </td>
                  <td className="text-end">
                    {presentTenMonth1ADR + presentTenMonth2ADR + presentTenMonth3ADR}
                  </td>
                  <td className="text-end">
                    {presentTenMonth1REvenue + presentTenMonth2REvenue + presentTenMonth3REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    GroupPaceReportData.lstPreviousYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 1 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth1Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth1ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth1REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 2 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth2Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth2ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth2REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 3 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth3Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth3ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth3REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {pastTenMonth1Room + pastTenMonth2Room + pastTenMonth3Room}
                  </td>
                  <td className="text-end">
                    {pastTenMonth1ADR + pastTenMonth2ADR + pastTenMonth3ADR}
                  </td>
                  <td className="text-end">
                    {pastTenMonth1REvenue + pastTenMonth2REvenue + pastTenMonth3REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentTenMonth1Room - pastTenMonth1Room > 0
                        ? "font-green text-center"
                        : presentTenMonth1Room - pastTenMonth1Room === 0
                        ? " text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth1Room - pastTenMonth1Room}
                  </td>
                  <td
                    className={
                      presentTenMonth1ADR - pastTenMonth1ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth1ADR - pastTenMonth1ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth1ADR - pastTenMonth1ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentTenMonth1REvenue - pastTenMonth1REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth1REvenue - pastTenMonth1REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth1REvenue - pastTenMonth1REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth2Room - pastTenMonth2Room > 0
                        ? "font-green text-center"
                        : presentTenMonth2Room - pastTenMonth2Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth2Room - pastTenMonth2Room}
                  </td>
                  <td
                    className={
                      presentTenMonth2ADR - pastTenMonth2ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth2ADR - pastTenMonth2ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth2ADR - pastTenMonth2ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth2REvenue - pastTenMonth2REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth2REvenue - pastTenMonth2REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth2REvenue - pastTenMonth2REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth3Room - pastTenMonth3Room > 0
                        ? "font-green text-center"
                        : presentTenMonth3Room - pastTenMonth3Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth3Room - pastTenMonth3Room}
                  </td>
                  <td
                    className={
                      presentTenMonth3ADR - pastTenMonth3ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth3ADR - pastTenMonth3ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth3ADR - pastTenMonth3ADR).toFixed(2)}
                  </td>
                  <td className="text-end">{presentTenMonth3REvenue - pastTenMonth3REvenue}</td>

                  <td
                    className={
                      presentTenMonth1Room +
                        presentTenMonth2Room +
                        presentTenMonth3Room -
                        (pastTenMonth1Room + pastTenMonth2Room + pastTenMonth3Room) >
                      0
                        ? "font-green text-center"
                        : presentTenMonth1Room +
                            presentTenMonth2Room +
                            presentTenMonth3Room -
                            (pastTenMonth1Room + pastTenMonth2Room + pastTenMonth3Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth1Room +
                      presentTenMonth2Room +
                      presentTenMonth3Room -
                      (pastTenMonth1Room + pastTenMonth2Room + pastTenMonth3Room)}
                  </td>
                  <td
                    className={
                      presentTenMonth1ADR +
                        presentTenMonth2ADR +
                        presentTenMonth3ADR -
                        (pastTenMonth1ADR + pastTenMonth2ADR + pastTenMonth3ADR) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth1ADR +
                            presentTenMonth2ADR +
                            presentTenMonth3ADR -
                            (pastTenMonth1ADR + pastTenMonth2ADR + pastTenMonth3ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth1ADR +
                        presentTenMonth2ADR +
                        presentTenMonth3ADR -
                        (pastTenMonth1ADR + pastTenMonth2ADR + pastTenMonth3ADR)
                    ).toFixed(2)}
                  </td>
                  <td className="text-end">
                    {parseFloat(
                      presentTenMonth1REvenue +
                        presentTenMonth2REvenue +
                        presentTenMonth3REvenue -
                        (pastTenMonth1REvenue + pastTenMonth2REvenue + pastTenMonth3REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>

                {/* Space between Tantative & Actual Pickupss */}

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualPickupData.map((ActPic) => (
                      <>
                        {ActPic.MonthNo === 1 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth1Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth1ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth1REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 2 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth2Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth2ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth2REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 3 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth3Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth3ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth3REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualPicMonth1Room + ActualPicMonth2Room + ActualPicMonth3Room}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth1ADR + ActualPicMonth2ADR + ActualPicMonth3ADR}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth1REvenue + ActualPicMonth2REvenue + ActualPicMonth3REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Room -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualData.map((ActRoom) => (
                      <>
                        {ActRoom.MonthNo === 1 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth1Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth1ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth1REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 2 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth2Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth2ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth2REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 3 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth3Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth3ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth3REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualRoomMonth1Room + ActualRoomMonth2Room + ActualRoomMonth3Room}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth1ADR + ActualRoomMonth2ADR + ActualRoomMonth3ADR}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth1REvenue + ActualRoomMonth2REvenue + ActualRoomMonth3REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      ActualPicMonth1Room - ActualRoomMonth1Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth1Room - ActualRoomMonth1Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth1Room - ActualRoomMonth1Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth1ADR - ActualRoomMonth1ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth1ADR - ActualRoomMonth1ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth1ADR - ActualRoomMonth1ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      ActualPicMonth1REvenue - ActualRoomMonth1REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth1REvenue - ActualRoomMonth1REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth1REvenue - ActualRoomMonth1REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth2Room - ActualRoomMonth2Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth2Room - ActualRoomMonth2Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth2Room - ActualRoomMonth2Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth2ADR - ActualRoomMonth2ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth2ADR - ActualRoomMonth2ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth2ADR - ActualRoomMonth2ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth2REvenue - ActualRoomMonth2REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth2REvenue - ActualRoomMonth2REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth2REvenue - ActualRoomMonth2REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth3Room - ActualRoomMonth3Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth3Room - ActualRoomMonth3Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth3Room - ActualRoomMonth3Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth3ADR - ActualRoomMonth3ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth3ADR - ActualRoomMonth3ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth3ADR - ActualRoomMonth3ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth3REvenue - ActualRoomMonth3REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth3REvenue - ActualRoomMonth3REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth3REvenue - ActualRoomMonth3REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth1Room +
                        ActualPicMonth2Room +
                        ActualPicMonth3Room -
                        (ActualRoomMonth1Room + ActualRoomMonth2Room + ActualRoomMonth3Room) >
                      0
                        ? "font-green text-center"
                        : ActualPicMonth1Room +
                            ActualPicMonth2Room +
                            ActualPicMonth3Room -
                            (ActualRoomMonth1Room + ActualRoomMonth2Room + ActualRoomMonth3Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth1Room +
                      ActualPicMonth2Room +
                      ActualPicMonth3Room -
                      (ActualRoomMonth1Room + ActualRoomMonth2Room + ActualRoomMonth3Room)}
                  </td>
                  <td
                    className={
                      ActualPicMonth1ADR +
                        ActualPicMonth2ADR +
                        ActualPicMonth3ADR -
                        (ActualRoomMonth1ADR + ActualRoomMonth2ADR + ActualRoomMonth3ADR) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth1ADR +
                            ActualPicMonth2ADR +
                            ActualPicMonth3ADR -
                            (ActualRoomMonth1ADR + ActualRoomMonth2ADR + ActualRoomMonth3ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth1ADR +
                        ActualPicMonth2ADR +
                        ActualPicMonth3ADR -
                        (ActualRoomMonth1ADR + ActualRoomMonth2ADR + ActualRoomMonth3ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth1REvenue +
                        ActualPicMonth2REvenue +
                        ActualPicMonth3REvenue -
                        (ActualRoomMonth1REvenue +
                          ActualRoomMonth2REvenue +
                          ActualRoomMonth3REvenue) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth1REvenue +
                            ActualPicMonth2REvenue +
                            ActualPicMonth3REvenue -
                            (ActualRoomMonth1REvenue +
                              ActualRoomMonth2REvenue +
                              ActualRoomMonth3REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth1REvenue +
                        ActualPicMonth2REvenue +
                        ActualPicMonth3REvenue -
                        (ActualRoomMonth1REvenue +
                          ActualRoomMonth2REvenue +
                          ActualRoomMonth3REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>

            {/* Table Second */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center" colSpan={3}>
                    April
                  </th>
                  <th className="text-center" colSpan={3}>
                    May
                  </th>
                  <th className="text-center" colSpan={3}>
                    June
                  </th>
                  <th className="text-center" colSpan={3}>
                    Quarter 2
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: "550" }}>
                <tr>
                  <th></th>
                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}{" "}
                  </td>
                  {GroupPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstCurrentYearDefiniteData.map((defCur) => (
                      <>
                        {defCur.MonthNo === 4 && (
                          <>
                            <td className="text-center">
                              {(presentMonth4Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth4ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth4REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 5 && (
                          <>
                            <td className="text-center">
                              {(presentMonth5Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth5ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth5REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 6 && (
                          <>
                            <td className="text-center">
                              {(presentMonth6Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth6ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth6REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {presentMonth4Room + presentMonth5Room + presentMonth6Room}
                  </td>
                  <td className="text-end">
                    {presentMonth4ADR + presentMonth5ADR + presentMonth6ADR}
                  </td>
                  <td className="text-end">
                    {presentMonth4REvenue + presentMonth5REvenue + presentMonth6REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>

                  {GroupPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstPreviousYearDefiniteData.map((defPrev) => (
                      <>
                        {defPrev.MonthNo === 4 && (
                          <>
                            <td className="text-center">{(pastMonth4Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth4ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth4REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 5 && (
                          <>
                            <td className="text-center">{(pastMonth5Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth5ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth5REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 6 && (
                          <>
                            <td className="text-center">{(pastMonth6Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth6ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth6REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {pastMonth4Room + pastMonth5Room + pastMonth6Room}
                  </td>
                  <td className="text-end">
                    {parseFloat(pastMonth4ADR + pastMonth5ADR + pastMonth6ADR).toFixed(2)}
                  </td>
                  <td className="text-end">
                    {pastMonth4REvenue + pastMonth5REvenue + pastMonth6REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentMonth4Room - pastMonth4Room > 0
                        ? "font-green text-center"
                        : presentMonth4Room - pastMonth4Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth4Room - pastMonth4Room}
                  </td>
                  <td
                    className={
                      presentMonth4ADR - pastMonth4ADR > 0
                        ? "font-green text-end"
                        : presentMonth4ADR - pastMonth4ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth4ADR - pastMonth4ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentMonth4REvenue - pastMonth4REvenue > 0
                        ? "font-green text-end"
                        : presentMonth4REvenue - pastMonth4REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth4REvenue - pastMonth4REvenue}
                  </td>

                  <td
                    className={
                      presentMonth5Room - pastMonth5Room > 0
                        ? "font-green text-center"
                        : presentMonth5Room - pastMonth5Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth5Room - pastMonth5Room}
                  </td>
                  <td
                    className={
                      presentMonth5ADR - pastMonth5ADR > 0
                        ? "font-green text-end"
                        : presentMonth5ADR - pastMonth5ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth5ADR - pastMonth5ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth5REvenue - pastMonth5REvenue > 0
                        ? "font-green text-end"
                        : presentMonth5REvenue - pastMonth5REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth5REvenue - pastMonth5REvenue}
                  </td>

                  <td
                    className={
                      presentMonth6Room - pastMonth6Room > 0
                        ? "font-green text-center"
                        : presentMonth6Room - pastMonth6Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth6Room - pastMonth6Room}
                  </td>
                  <td
                    className={
                      presentMonth6ADR - pastMonth6ADR > 0
                        ? "font-green text-end"
                        : presentMonth6ADR - pastMonth6ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth6ADR - pastMonth6ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth6REvenue - pastMonth6REvenue > 0
                        ? "font-green text-end"
                        : presentMonth6REvenue - pastMonth6REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth6REvenue - pastMonth6REvenue}
                  </td>

                  <td
                    className={
                      presentMonth4Room +
                        presentMonth5Room +
                        presentMonth6Room -
                        (pastMonth4Room + pastMonth5Room + pastMonth6Room) >
                      0
                        ? "font-green text-center"
                        : presentMonth4Room +
                            presentMonth5Room +
                            presentMonth6Room -
                            (pastMonth4Room + pastMonth5Room + pastMonth6Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth4Room +
                      presentMonth5Room +
                      presentMonth6Room -
                      (pastMonth4Room + pastMonth5Room + pastMonth6Room)}
                  </td>
                  <td
                    className={
                      presentMonth4ADR +
                        presentMonth5ADR +
                        presentMonth6ADR -
                        (pastMonth4ADR + pastMonth5ADR + pastMonth6ADR) >
                      0
                        ? "font-green text-end"
                        : presentMonth4ADR +
                            presentMonth5ADR +
                            presentMonth6ADR -
                            (pastMonth4ADR + pastMonth5ADR + pastMonth6ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentMonth4ADR +
                        presentMonth5ADR +
                        presentMonth6ADR -
                        (pastMonth4ADR + pastMonth5ADR + pastMonth6ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth4REvenue +
                        presentMonth5REvenue +
                        presentMonth6REvenue -
                        (pastMonth4REvenue + pastMonth5REvenue + pastMonth6REvenue) >
                      0
                        ? "font-green text-end"
                        : presentMonth4REvenue +
                            presentMonth5REvenue +
                            presentMonth6REvenue -
                            (pastMonth4REvenue + pastMonth5REvenue + pastMonth6REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentMonth4REvenue +
                        presentMonth5REvenue +
                        presentMonth6REvenue -
                        (pastMonth4REvenue + pastMonth5REvenue + pastMonth6REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>

                {/* Space between Definite & Tantative */}
                <tr>
                  <td>
                    Tentatives -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}
                  </td>
                  {GroupPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    GroupPaceReportData.lstCurrentYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 4 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth4Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth4ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth4REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 5 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth5Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth5ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth5REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 6 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth6Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth6ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth6REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {presentTenMonth4Room + presentTenMonth5Room + presentTenMonth6Room}
                  </td>
                  <td className="text-end">
                    {presentTenMonth4ADR + presentTenMonth5ADR + presentTenMonth6ADR}
                  </td>
                  <td className="text-end">
                    {presentTenMonth4REvenue + presentTenMonth5REvenue + presentTenMonth6REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    GroupPaceReportData.lstPreviousYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 4 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth4Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth4ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth4REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 5 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth5Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth5ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth5REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 6 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth6Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth6ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth6REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {pastTenMonth4Room + pastTenMonth5Room + pastTenMonth6Room}
                  </td>
                  <td className="text-end">
                    {pastTenMonth4ADR + pastTenMonth5ADR + pastTenMonth6ADR}
                  </td>
                  <td className="text-end">
                    {pastTenMonth4REvenue + pastTenMonth5REvenue + pastTenMonth6REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentTenMonth4Room - pastTenMonth4Room > 0
                        ? "font-green text-center"
                        : presentTenMonth4Room - pastTenMonth4Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth4Room - pastTenMonth4Room}
                  </td>
                  <td
                    className={
                      presentTenMonth4ADR - pastTenMonth4ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth4ADR - pastTenMonth4ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth4ADR - pastTenMonth4ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentTenMonth4REvenue - pastTenMonth4REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth4REvenue - pastTenMonth4REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth4REvenue - pastTenMonth4REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth5Room - pastTenMonth5Room > 0
                        ? "font-green text-center"
                        : presentTenMonth5Room - pastTenMonth5Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth5Room - pastTenMonth5Room}
                  </td>
                  <td
                    className={
                      presentTenMonth5ADR - pastTenMonth5ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth5ADR - pastTenMonth5ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth5ADR - pastTenMonth5ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth5REvenue - pastTenMonth5REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth5REvenue - pastTenMonth5REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth5REvenue - pastTenMonth5REvenue}
                  </td>

                  <td className="text-center">{presentTenMonth6Room - pastTenMonth6Room}</td>
                  <td
                    className={
                      presentTenMonth6ADR - pastTenMonth6ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth6ADR - pastTenMonth6ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth6ADR - pastTenMonth6ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth6REvenue - pastTenMonth6REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth6REvenue - pastTenMonth6REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth6REvenue - pastTenMonth6REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth4Room +
                        presentTenMonth5Room +
                        presentTenMonth6Room -
                        (pastTenMonth4Room + pastTenMonth5Room + pastTenMonth6Room) >
                      0
                        ? "font-green text-center"
                        : presentTenMonth4Room +
                            presentTenMonth5Room +
                            presentTenMonth6Room -
                            (pastTenMonth4Room + pastTenMonth5Room + pastTenMonth6Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth4Room +
                      presentTenMonth5Room +
                      presentTenMonth6Room -
                      (pastTenMonth4Room + pastTenMonth5Room + pastTenMonth6Room)}
                  </td>
                  <td
                    className={
                      presentTenMonth4ADR +
                        presentTenMonth5ADR +
                        presentTenMonth6ADR -
                        (pastTenMonth4ADR + pastTenMonth5ADR + pastTenMonth6ADR) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth4ADR +
                            presentTenMonth5ADR +
                            presentTenMonth6ADR -
                            (pastTenMonth4ADR + pastTenMonth5ADR + pastTenMonth6ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth4ADR +
                        presentTenMonth5ADR +
                        presentTenMonth6ADR -
                        (pastTenMonth4ADR + pastTenMonth5ADR + pastTenMonth6ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth4REvenue +
                        presentTenMonth5REvenue +
                        presentTenMonth6REvenue -
                        (pastTenMonth4REvenue + pastTenMonth5REvenue + pastTenMonth6REvenue) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth4REvenue +
                            presentTenMonth5REvenue +
                            presentTenMonth6REvenue -
                            (pastTenMonth4REvenue + pastTenMonth5REvenue + pastTenMonth6REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth4REvenue +
                        presentTenMonth5REvenue +
                        presentTenMonth6REvenue -
                        (pastTenMonth4REvenue + pastTenMonth5REvenue + pastTenMonth6REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>

                {/* Space between Tantative & Actual Pickupss */}

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualPickupData.map((ActPic) => (
                      <>
                        {ActPic.MonthNo === 4 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth4Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth4ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth4REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 5 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth5Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth5ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth5REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 6 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth6Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth6ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth6REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualPicMonth4Room + ActualPicMonth5Room + ActualPicMonth6Room}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth4ADR + ActualPicMonth5ADR + ActualPicMonth6ADR}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth4REvenue + ActualPicMonth5REvenue + ActualPicMonth6REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Room -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualData.map((ActRoom) => (
                      <>
                        {ActRoom.MonthNo === 4 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth4Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth4ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth4REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 5 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth5Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth5ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth5REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 6 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth6Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth6ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth6REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualRoomMonth4Room + ActualRoomMonth5Room + ActualRoomMonth6Room}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth4ADR + ActualRoomMonth5ADR + ActualRoomMonth6ADR}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth4REvenue + ActualRoomMonth5REvenue + ActualRoomMonth6REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      ActualPicMonth4Room - ActualRoomMonth4Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth4Room - ActualRoomMonth4Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth4Room - ActualRoomMonth4Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth4ADR - ActualRoomMonth4ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth4ADR - ActualRoomMonth4ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth4ADR - ActualRoomMonth4ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      ActualPicMonth4REvenue - ActualRoomMonth4REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth4REvenue - ActualRoomMonth4REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth4REvenue - ActualRoomMonth4REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth5Room - ActualRoomMonth5Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth5Room - ActualRoomMonth5Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth5Room - ActualRoomMonth5Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth5ADR - ActualRoomMonth5ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth5ADR - ActualRoomMonth5ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth5ADR - ActualRoomMonth5ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth5REvenue - ActualRoomMonth5REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth5REvenue - ActualRoomMonth5REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth5REvenue - ActualRoomMonth5REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth6Room - ActualRoomMonth6Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth6Room - ActualRoomMonth6Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth6Room - ActualRoomMonth6Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth6ADR - ActualRoomMonth6ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth6ADR - ActualRoomMonth6ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth6ADR - ActualRoomMonth6ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth6REvenue - ActualRoomMonth6REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth6REvenue - ActualRoomMonth6REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth6REvenue - ActualRoomMonth6REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth4Room +
                        ActualPicMonth5Room +
                        ActualPicMonth6Room -
                        (ActualRoomMonth4Room + ActualRoomMonth5Room + ActualRoomMonth6Room) >
                      0
                        ? "font-green text-center"
                        : ActualPicMonth4Room +
                            ActualPicMonth5Room +
                            ActualPicMonth6Room -
                            (ActualRoomMonth4Room + ActualRoomMonth5Room + ActualRoomMonth6Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth4Room +
                      ActualPicMonth5Room +
                      ActualPicMonth6Room -
                      (ActualRoomMonth4Room + ActualRoomMonth5Room + ActualRoomMonth6Room)}
                  </td>
                  <td
                    className={
                      ActualPicMonth4ADR +
                        ActualPicMonth5ADR +
                        ActualPicMonth6ADR -
                        (ActualRoomMonth4ADR + ActualRoomMonth5ADR + ActualRoomMonth6ADR) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth4ADR +
                            ActualPicMonth5ADR +
                            ActualPicMonth6ADR -
                            (ActualRoomMonth4ADR + ActualRoomMonth5ADR + ActualRoomMonth6ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth4ADR +
                        ActualPicMonth5ADR +
                        ActualPicMonth6ADR -
                        (ActualRoomMonth4ADR + ActualRoomMonth5ADR + ActualRoomMonth6ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth4REvenue +
                        ActualPicMonth5REvenue +
                        ActualPicMonth6REvenue -
                        (ActualRoomMonth4REvenue +
                          ActualRoomMonth5REvenue +
                          ActualRoomMonth6REvenue) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth4REvenue +
                            ActualPicMonth5REvenue +
                            ActualPicMonth6REvenue -
                            (ActualRoomMonth4REvenue +
                              ActualRoomMonth5REvenue +
                              ActualRoomMonth6REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth4REvenue +
                        ActualPicMonth5REvenue +
                        ActualPicMonth6REvenue -
                        (ActualRoomMonth4REvenue +
                          ActualRoomMonth5REvenue +
                          ActualRoomMonth6REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>

            {/* Table Third */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center" colSpan={3}>
                    July
                  </th>
                  <th className="text-center" colSpan={3}>
                    August
                  </th>
                  <th className="text-center" colSpan={3}>
                    September
                  </th>
                  <th className="text-center" colSpan={3}>
                    Quarter 3
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: "550" }}>
                <tr>
                  <th></th>
                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}{" "}
                  </td>
                  {GroupPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstCurrentYearDefiniteData.map((defCur) => (
                      <>
                        {defCur.MonthNo === 7 && (
                          <>
                            <td className="text-center">
                              {(presentMonth7Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth7ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth7REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 8 && (
                          <>
                            <td className="text-center">
                              {(presentMonth8Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth8ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth8REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 9 && (
                          <>
                            <td className="text-center">
                              {(presentMonth9Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth9ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth9REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {presentMonth7Room + presentMonth8Room + presentMonth9Room}
                  </td>
                  <td className="text-end">
                    {presentMonth7ADR + presentMonth8ADR + presentMonth9ADR}
                  </td>
                  <td className="text-end">
                    {presentMonth7REvenue + presentMonth8REvenue + presentMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>

                  {GroupPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstPreviousYearDefiniteData.map((defPrev) => (
                      <>
                        {defPrev.MonthNo === 7 && (
                          <>
                            <td className="text-center">{(pastMonth7Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth7ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth7REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 8 && (
                          <>
                            <td className="text-center">{(pastMonth8Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth8ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth8REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 9 && (
                          <>
                            <td className="text-center">{(pastMonth9Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth9ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth9REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {pastMonth7Room + pastMonth8Room + pastMonth9Room}
                  </td>
                  <td className="text-end">
                    {parseFloat(pastMonth7ADR + pastMonth8ADR + pastMonth9ADR).toFixed(2)}
                  </td>
                  <td className="text-end">
                    {pastMonth7REvenue + pastMonth8REvenue + pastMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentMonth7Room - pastMonth7Room > 0
                        ? "font-green text-center"
                        : presentMonth7Room - pastMonth7Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth7Room - pastMonth7Room}
                  </td>
                  <td
                    className={
                      presentMonth7ADR - pastMonth7ADR > 0
                        ? "font-green text-end"
                        : presentMonth7ADR - pastMonth7ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth7ADR - pastMonth7ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentMonth7REvenue - pastMonth7REvenue > 0
                        ? "font-green text-end"
                        : presentMonth7REvenue - pastMonth7REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth7REvenue - pastMonth7REvenue}
                  </td>

                  <td
                    className={
                      presentMonth8Room - pastMonth8Room > 0
                        ? "font-green text-center"
                        : presentMonth8Room - pastMonth8Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth8Room - pastMonth8Room}
                  </td>
                  <td
                    className={
                      presentMonth8ADR - pastMonth8ADR > 0
                        ? "font-green text-end"
                        : presentMonth8ADR - pastMonth8ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth8ADR - pastMonth8ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth8REvenue - pastMonth8REvenue > 0
                        ? "font-green text-end"
                        : presentMonth8REvenue - pastMonth8REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth8REvenue - pastMonth8REvenue}
                  </td>

                  <td
                    className={
                      presentMonth9Room - pastMonth9Room > 0
                        ? "font-green text-center"
                        : presentMonth9Room - pastMonth9Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth9Room - pastMonth9Room}
                  </td>
                  <td
                    className={
                      presentMonth9ADR - pastMonth9ADR > 0
                        ? "font-green text-end"
                        : presentMonth9ADR - pastMonth9ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth9ADR - pastMonth9ADR).toFixed(2)}
                  </td>
                  <td className="text-end">{presentMonth9REvenue - pastMonth9REvenue}</td>

                  <td
                    className={
                      presentMonth7Room +
                        presentMonth8Room +
                        presentMonth9Room -
                        (pastMonth7Room + pastMonth8Room + pastMonth9Room) >
                      0
                        ? "font-green text-center"
                        : presentMonth7Room +
                            presentMonth8Room +
                            presentMonth9Room -
                            (pastMonth7Room + pastMonth8Room + pastMonth9Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth7Room +
                      presentMonth8Room +
                      presentMonth9Room -
                      (pastMonth7Room + pastMonth8Room + pastMonth9Room)}
                  </td>
                  <td
                    className={
                      presentMonth7ADR +
                        presentMonth8ADR +
                        presentMonth9ADR -
                        (pastMonth7ADR + pastMonth8ADR + pastMonth9ADR) >
                      0
                        ? "font-green text-end"
                        : presentMonth7ADR +
                            presentMonth8ADR +
                            presentMonth9ADR -
                            (pastMonth7ADR + pastMonth8ADR + pastMonth9ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentMonth7ADR +
                        presentMonth8ADR +
                        presentMonth9ADR -
                        (pastMonth7ADR + pastMonth8ADR + pastMonth9ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth7REvenue +
                        presentMonth8REvenue +
                        presentMonth9REvenue -
                        (pastMonth7REvenue + pastMonth8REvenue + pastMonth9REvenue) >
                      0
                        ? "font-green text-end"
                        : presentMonth7REvenue +
                            presentMonth8REvenue +
                            presentMonth9REvenue -
                            (pastMonth7REvenue + pastMonth8REvenue + pastMonth9REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth7REvenue +
                      presentMonth8REvenue +
                      presentMonth9REvenue -
                      (pastMonth7REvenue + pastMonth8REvenue + pastMonth9REvenue)}
                  </td>
                </tr>

                {/* Space between Definite & Tantative */}

                <tr>
                  <td>
                    Tentatives -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}
                  </td>
                  {GroupPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    GroupPaceReportData.lstCurrentYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 7 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth7Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth7ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth7REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 8 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth8Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth8ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth8REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 9 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth9Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth9ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth9REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {presentTenMonth7Room + presentTenMonth8Room + presentTenMonth9Room}
                  </td>
                  <td className="text-end">
                    {presentTenMonth7ADR + presentTenMonth8ADR + presentTenMonth9ADR}
                  </td>
                  <td className="text-end">
                    {presentTenMonth7REvenue + presentTenMonth8REvenue + presentTenMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    GroupPaceReportData.lstPreviousYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 7 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth7Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth7ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth7REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 8 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth8Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth8ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth8REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 9 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth9Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth9ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth9REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {pastTenMonth7Room + pastTenMonth8Room + pastTenMonth9Room}
                  </td>
                  <td className="text-end">
                    {pastTenMonth7ADR + pastTenMonth8ADR + pastTenMonth9ADR}
                  </td>
                  <td className="text-end">
                    {pastTenMonth7REvenue + pastTenMonth8REvenue + pastTenMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentTenMonth7Room - pastTenMonth7Room > 0
                        ? "font-green text-center"
                        : presentTenMonth7Room - pastTenMonth7Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth7Room - pastTenMonth7Room}
                  </td>
                  <td
                    className={
                      presentTenMonth7ADR - pastTenMonth7ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth7ADR - pastTenMonth7ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth7ADR - pastTenMonth7ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentTenMonth7REvenue - pastTenMonth7REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth7REvenue - pastTenMonth7REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth7REvenue - pastTenMonth7REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth8Room - pastTenMonth8Room > 0
                        ? "font-green text-center"
                        : presentTenMonth8Room - pastTenMonth8Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth8Room - pastTenMonth8Room}
                  </td>
                  <td
                    className={
                      presentTenMonth8ADR - pastTenMonth8ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth8ADR - pastTenMonth8ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth8ADR - pastTenMonth8ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth8REvenue - pastTenMonth8REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth8REvenue - pastTenMonth8REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth8REvenue - pastTenMonth8REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth9Room - pastTenMonth9Room > 0
                        ? "font-green text-center"
                        : presentTenMonth9Room - pastTenMonth9Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth9Room - pastTenMonth9Room}
                  </td>
                  <td
                    className={
                      presentTenMonth9ADR - pastTenMonth9ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth9ADR - pastTenMonth9ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth9ADR - pastTenMonth9ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth9REvenue - pastTenMonth9REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth9REvenue - pastTenMonth9REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth9REvenue - pastTenMonth9REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth7Room +
                        presentTenMonth8Room +
                        presentTenMonth9Room -
                        (pastTenMonth7Room + pastTenMonth8Room + pastTenMonth9Room) >
                      0
                        ? "font-green text-center"
                        : presentTenMonth7Room +
                            presentTenMonth8Room +
                            presentTenMonth9Room -
                            (pastTenMonth7Room + pastTenMonth8Room + pastTenMonth9Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth7Room +
                      presentTenMonth8Room +
                      presentTenMonth9Room -
                      (pastTenMonth7Room + pastTenMonth8Room + pastTenMonth9Room)}
                  </td>
                  <td
                    className={
                      presentTenMonth7ADR +
                        presentTenMonth8ADR +
                        presentTenMonth9ADR -
                        (pastTenMonth7ADR + pastTenMonth8ADR + pastTenMonth9ADR) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth7ADR +
                            presentTenMonth8ADR +
                            presentTenMonth9ADR -
                            (pastTenMonth7ADR + pastTenMonth8ADR + pastTenMonth9ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth7ADR +
                        presentTenMonth8ADR +
                        presentTenMonth9ADR -
                        (pastTenMonth7ADR + pastTenMonth8ADR + pastTenMonth9ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth7REvenue +
                        presentTenMonth8REvenue +
                        presentTenMonth9REvenue -
                        (pastTenMonth7REvenue + pastTenMonth8REvenue + pastTenMonth9REvenue) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth7REvenue +
                            presentTenMonth8REvenue +
                            presentTenMonth9REvenue -
                            (pastTenMonth7REvenue + pastTenMonth8REvenue + pastTenMonth9REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth7REvenue +
                        presentTenMonth8REvenue +
                        presentTenMonth9REvenue -
                        (pastTenMonth7REvenue + pastTenMonth8REvenue + pastTenMonth9REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>

                {/* Space between Tantative & Actual Pickupss */}

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualPickupData.map((ActPic) => (
                      <>
                        {ActPic.MonthNo === 7 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth7Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth7ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth7REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 8 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth8Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth8ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth8REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 9 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth9Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth9ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth9REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualPicMonth7Room + ActualPicMonth8Room + ActualPicMonth9Room}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth7ADR + ActualPicMonth8ADR + ActualPicMonth9ADR}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth7REvenue + ActualPicMonth8REvenue + ActualPicMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Room -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualData.map((ActRoom) => (
                      <>
                        {ActRoom.MonthNo === 7 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth7Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth7ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth7REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 8 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth8Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth8ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth8REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 9 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth9Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth9ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth9REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualRoomMonth7Room + ActualRoomMonth8Room + ActualRoomMonth9Room}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth7ADR + ActualRoomMonth8ADR + ActualRoomMonth9ADR}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth7REvenue + ActualRoomMonth8REvenue + ActualRoomMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      ActualPicMonth7Room - ActualRoomMonth7Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth7Room - ActualRoomMonth7Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth7Room - ActualRoomMonth7Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth7ADR - ActualRoomMonth7ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth7ADR - ActualRoomMonth7ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth7ADR - ActualRoomMonth7ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      ActualPicMonth7REvenue - ActualRoomMonth7REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth7REvenue - ActualRoomMonth7REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth7REvenue - ActualRoomMonth7REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth8Room - ActualRoomMonth8Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth8Room - ActualRoomMonth8Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth8Room - ActualRoomMonth8Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth8ADR - ActualRoomMonth8ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth8ADR - ActualRoomMonth8ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth8ADR - ActualRoomMonth8ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth8REvenue - ActualRoomMonth8REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth8REvenue - ActualRoomMonth8REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth8REvenue - ActualRoomMonth8REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth9Room - ActualRoomMonth9Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth9Room - ActualRoomMonth9Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth9Room - ActualRoomMonth9Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth9ADR - ActualRoomMonth9ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth9ADR - ActualRoomMonth9ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth9ADR - ActualRoomMonth9ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth9REvenue - ActualRoomMonth9REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth9REvenue - ActualRoomMonth9REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth9REvenue - ActualRoomMonth9REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth7Room +
                        ActualPicMonth8Room +
                        ActualPicMonth9Room -
                        (ActualRoomMonth7Room + ActualRoomMonth8Room + ActualRoomMonth9Room) >
                      0
                        ? "font-green text-center"
                        : ActualPicMonth7Room +
                            ActualPicMonth8Room +
                            ActualPicMonth9Room -
                            (ActualRoomMonth7Room + ActualRoomMonth8Room + ActualRoomMonth9Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth7Room +
                      ActualPicMonth8Room +
                      ActualPicMonth9Room -
                      (ActualRoomMonth7Room + ActualRoomMonth8Room + ActualRoomMonth9Room)}
                  </td>
                  <td
                    className={
                      ActualPicMonth7ADR +
                        ActualPicMonth8ADR +
                        ActualPicMonth9ADR -
                        (ActualRoomMonth7ADR + ActualRoomMonth8ADR + ActualRoomMonth9ADR) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth7ADR +
                            ActualPicMonth8ADR +
                            ActualPicMonth9ADR -
                            (ActualRoomMonth7ADR + ActualRoomMonth8ADR + ActualRoomMonth9ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth7ADR +
                        ActualPicMonth8ADR +
                        ActualPicMonth9ADR -
                        (ActualRoomMonth7ADR + ActualRoomMonth8ADR + ActualRoomMonth9ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth7REvenue +
                        ActualPicMonth8REvenue +
                        ActualPicMonth9REvenue -
                        (ActualRoomMonth7REvenue +
                          ActualRoomMonth8REvenue +
                          ActualRoomMonth9REvenue) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth7REvenue +
                            ActualPicMonth8REvenue +
                            ActualPicMonth9REvenue -
                            (ActualRoomMonth7REvenue +
                              ActualRoomMonth8REvenue +
                              ActualRoomMonth9REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth7REvenue +
                        ActualPicMonth8REvenue +
                        ActualPicMonth9REvenue -
                        (ActualRoomMonth7REvenue +
                          ActualRoomMonth8REvenue +
                          ActualRoomMonth9REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>

            {/* Table Fourth */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center" colSpan={3}>
                    October
                  </th>
                  <th className="text-center" colSpan={3}>
                    November
                  </th>
                  <th className="text-center" colSpan={3}>
                    December
                  </th>
                  <th className="text-center" colSpan={3}>
                    Quarter 4
                  </th>
                </tr>
              </thead>
              <tbody style={{ fontWeight: "550" }}>
                <tr>
                  <th></th>
                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>

                  <th className="text-center">Rooms</th>
                  <th className="text-end">ADR($)</th>
                  <th className="text-end">Revenue($)</th>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}{" "}
                  </td>
                  {GroupPaceReportData.lstCurrentYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstCurrentYearDefiniteData.map((defCur) => (
                      <>
                        {defCur.MonthNo === 10 && (
                          <>
                            <td className="text-center">
                              {(presentMonth10Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth10ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth10REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 11 && (
                          <>
                            <td className="text-center">
                              {(presentMonth11Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth11ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth11REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defCur.MonthNo === 12 && (
                          <>
                            <td className="text-center">
                              {(presentMonth12Room = defCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentMonth12ADR = defCur.ADR)}</td>
                            <td className="text-end">
                              {(presentMonth12REvenue = defCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {presentMonth10Room + presentMonth11Room + presentMonth12Room}
                  </td>
                  <td className="text-end">
                    {presentMonth10ADR + presentMonth11ADR + presentMonth12ADR}
                  </td>
                  <td className="text-end">
                    {presentMonth10REvenue + presentMonth11REvenue + presentMonth12REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Definites -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>

                  {GroupPaceReportData.lstPreviousYearDefiniteData !== undefined &&
                    GroupPaceReportData.lstPreviousYearDefiniteData.map((defPrev) => (
                      <>
                        {defPrev.MonthNo === 10 && (
                          <>
                            <td className="text-center">{(pastMonth10Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth10ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth10REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 11 && (
                          <>
                            <td className="text-center">{(pastMonth11Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth11ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth11REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {defPrev.MonthNo === 12 && (
                          <>
                            <td className="text-center">{(pastMonth12Room = defPrev.NoOfRooms)}</td>
                            <td className="text-end">{(pastMonth9ADR = defPrev.ADR)}</td>
                            <td className="text-end">
                              {(pastMonth12REvenue = defPrev.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}
                  <td className="text-center">
                    {pastMonth10Room + pastMonth11Room + pastMonth12Room}
                  </td>
                  <td className="text-end">
                    {parseFloat(pastMonth10ADR + pastMonth11ADR + pastMonth9ADR).toFixed(2)}
                  </td>
                  <td className="text-end">
                    {pastMonth10REvenue + pastMonth11REvenue + pastMonth12REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentMonth10Room - pastMonth10Room > 0
                        ? "font-green text-center"
                        : presentMonth10Room - pastMonth10Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth10Room - pastMonth10Room}
                  </td>
                  <td
                    className={
                      presentMonth10ADR - pastMonth10ADR > 0
                        ? "font-green text-end"
                        : presentMonth10ADR - pastMonth10ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth10ADR - pastMonth10ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentMonth10REvenue - pastMonth10REvenue > 0
                        ? "font-green text-end"
                        : presentMonth10REvenue - pastMonth10REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth10REvenue - pastMonth10REvenue}
                  </td>

                  <td
                    className={
                      presentMonth11Room - pastMonth11Room > 0
                        ? "font-green text-center"
                        : presentMonth11Room - pastMonth11Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth11Room - pastMonth11Room}
                  </td>
                  <td
                    className={
                      presentMonth11ADR - pastMonth11ADR > 0
                        ? "font-green text-end"
                        : presentMonth11ADR - pastMonth11ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth11ADR - pastMonth11ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth11REvenue - pastMonth11REvenue > 0
                        ? "font-green text-end"
                        : presentMonth11REvenue - pastMonth11REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth11REvenue - pastMonth11REvenue}
                  </td>

                  <td
                    className={
                      presentMonth12Room - pastMonth12Room > 0
                        ? "font-green text-center"
                        : presentMonth12Room - pastMonth12Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth12Room - pastMonth12Room}
                  </td>
                  <td
                    className={
                      presentMonth12ADR - pastMonth9ADR > 0
                        ? "font-green text-end"
                        : presentMonth12ADR - pastMonth9ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentMonth12ADR - pastMonth9ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth12REvenue - pastMonth12REvenue > 0
                        ? "font-green text-end"
                        : presentMonth12REvenue - pastMonth12REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentMonth12REvenue - pastMonth12REvenue}
                  </td>

                  <td
                    className={
                      presentMonth10Room +
                        presentMonth11Room +
                        presentMonth12Room -
                        (pastMonth10Room + pastMonth11Room + pastMonth12Room) >
                      0
                        ? "font-green text-center"
                        : presentMonth10Room +
                            presentMonth11Room +
                            presentMonth12Room -
                            (pastMonth10Room + pastMonth11Room + pastMonth12Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentMonth10Room +
                      presentMonth11Room +
                      presentMonth12Room -
                      (pastMonth10Room + pastMonth11Room + pastMonth12Room)}
                  </td>
                  <td
                    className={
                      presentMonth10ADR +
                        presentMonth11ADR +
                        presentMonth12ADR -
                        (pastMonth10ADR + pastMonth11ADR + pastMonth9ADR) >
                      0
                        ? "font-green text-end"
                        : presentMonth10ADR +
                            presentMonth11ADR +
                            presentMonth12ADR -
                            (pastMonth10ADR + pastMonth11ADR + pastMonth9ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentMonth10ADR +
                        presentMonth11ADR +
                        presentMonth12ADR -
                        (pastMonth10ADR + pastMonth11ADR + pastMonth9ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentMonth10REvenue +
                        presentMonth11REvenue +
                        presentMonth12REvenue -
                        (pastMonth10REvenue + pastMonth11REvenue + pastMonth12REvenue) >
                      0
                        ? "font-green text-end"
                        : presentMonth10REvenue +
                            presentMonth11REvenue +
                            presentMonth12REvenue -
                            (pastMonth10REvenue + pastMonth11REvenue + pastMonth12REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentMonth10REvenue +
                        presentMonth11REvenue +
                        presentMonth12REvenue -
                        (pastMonth10REvenue + pastMonth11REvenue + pastMonth12REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>

                {/* Space between Definite & Tantative */}

                <tr>
                  <td>
                    Tentatives -{" "}
                    {GroupPaceReportData.propertyData && GroupPaceReportData.propertyData.Year}
                  </td>
                  {GroupPaceReportData.lstCurrentYearTentativeData !== undefined &&
                    GroupPaceReportData.lstCurrentYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 10 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth10Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth10ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth10REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 11 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth11Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth11ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth11REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 12 && (
                          <>
                            <td className="text-center">
                              {(presentTenMonth12Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(presentTenMonth12ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(presentTenMonth12REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {presentTenMonth10Room + presentTenMonth11Room + presentTenMonth12Room}
                  </td>
                  <td className="text-end">
                    {presentTenMonth10ADR + presentTenMonth11ADR + presentTenMonth12ADR}
                  </td>
                  <td className="text-end">
                    {presentTenMonth10REvenue + presentTenMonth11REvenue + presentTenMonth12REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Tentatives -
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearTentativeData !== undefined &&
                    GroupPaceReportData.lstPreviousYearTentativeData.map((tenCur) => (
                      <>
                        {tenCur.MonthNo === 10 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth10Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth10ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth10REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 11 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth11Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth11ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth11REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {tenCur.MonthNo === 12 && (
                          <>
                            <td className="text-center">
                              {(pastTenMonth12Room = tenCur.NoOfRooms)}
                            </td>
                            <td className="text-end">{(pastTenMonth12ADR = tenCur.ADR)}</td>
                            <td className="text-end">
                              {(pastTenMonth12REvenue = tenCur.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {pastTenMonth10Room + pastTenMonth11Room + pastTenMonth12Room}
                  </td>
                  <td className="text-end">
                    {pastTenMonth10ADR + pastTenMonth11ADR + pastTenMonth12ADR}
                  </td>
                  <td className="text-end">
                    {pastTenMonth10REvenue + pastTenMonth11REvenue + pastTenMonth12REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      presentTenMonth10Room - pastTenMonth10Room > 0
                        ? "font-green text-center"
                        : presentTenMonth10Room - pastTenMonth10Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth10Room - pastTenMonth10Room}
                  </td>
                  <td
                    className={
                      presentTenMonth10ADR - pastTenMonth10ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth10ADR - pastTenMonth10ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth10ADR - pastTenMonth10ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      presentTenMonth10REvenue - pastTenMonth10REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth10REvenue - pastTenMonth10REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth10REvenue - pastTenMonth10REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth11Room - pastTenMonth11Room > 0
                        ? "font-green text-center"
                        : presentTenMonth11Room - pastTenMonth11Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth11Room - pastTenMonth11Room}
                  </td>
                  <td
                    className={
                      presentTenMonth11ADR - pastTenMonth11ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth11ADR - pastTenMonth11ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth11ADR - pastTenMonth11ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth11REvenue - pastTenMonth11REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth11REvenue - pastTenMonth11REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth11REvenue - pastTenMonth11REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth12Room - pastTenMonth12Room > 0
                        ? "font-green text-center"
                        : presentTenMonth12Room - pastTenMonth12Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth12Room - pastTenMonth12Room}
                  </td>
                  <td
                    className={
                      presentTenMonth12ADR - pastTenMonth12ADR > 0
                        ? "font-green text-end"
                        : presentTenMonth12ADR - pastTenMonth12ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(presentTenMonth12ADR - pastTenMonth12ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth12REvenue - pastTenMonth12REvenue > 0
                        ? "font-green text-end"
                        : presentTenMonth12REvenue - pastTenMonth12REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {presentTenMonth12REvenue - pastTenMonth12REvenue}
                  </td>

                  <td
                    className={
                      presentTenMonth10Room +
                        presentTenMonth11Room +
                        presentTenMonth12Room -
                        (pastTenMonth10Room + pastTenMonth11Room + pastTenMonth12Room) >
                      0
                        ? "font-green text-center"
                        : presentTenMonth10Room +
                            presentTenMonth11Room +
                            presentTenMonth12Room -
                            (pastTenMonth10Room + pastTenMonth11Room + pastTenMonth12Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {presentTenMonth10Room +
                      presentTenMonth11Room +
                      presentTenMonth12Room -
                      (pastTenMonth10Room + pastTenMonth11Room + pastTenMonth12Room)}
                  </td>
                  <td
                    className={
                      presentTenMonth10ADR +
                        presentTenMonth11ADR +
                        presentTenMonth12ADR -
                        (pastTenMonth10ADR + pastTenMonth11ADR + pastTenMonth12ADR) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth10ADR +
                            presentTenMonth11ADR +
                            presentTenMonth12ADR -
                            (pastTenMonth10ADR + pastTenMonth11ADR + pastTenMonth12ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth10ADR +
                        presentTenMonth11ADR +
                        presentTenMonth12ADR -
                        (pastTenMonth10ADR + pastTenMonth11ADR + pastTenMonth12ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      presentTenMonth10REvenue +
                        presentTenMonth11REvenue +
                        presentTenMonth12REvenue -
                        (pastTenMonth10REvenue + pastTenMonth11REvenue + pastTenMonth12REvenue) >
                      0
                        ? "font-green text-end"
                        : presentTenMonth10REvenue +
                            presentTenMonth11REvenue +
                            presentTenMonth12REvenue -
                            (pastTenMonth10REvenue +
                              pastTenMonth11REvenue +
                              pastTenMonth12REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      presentTenMonth10REvenue +
                        presentTenMonth11REvenue +
                        presentTenMonth12REvenue -
                        (pastTenMonth10REvenue + pastTenMonth11REvenue + pastTenMonth12REvenue)
                    ).toFixed()}
                  </td>
                </tr>

                {/* Space between Tantative & Actual Pickupss */}

                <tr>
                  <td>
                    Actual Pickup -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualPickupData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualPickupData.map((ActPic) => (
                      <>
                        {ActPic.MonthNo === 10 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth10Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth10ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth7REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 11 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth11Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth11ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth11REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActPic.MonthNo === 12 && (
                          <>
                            <td className="text-center">
                              {(ActualPicMonth12Room = ActPic.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualPicMonth12ADR = ActPic.ADR)}</td>
                            <td className="text-end">
                              {(ActualPicMonth9REvenue = ActPic.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualPicMonth10Room + ActualPicMonth11Room + ActualPicMonth12Room}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth10ADR + ActualPicMonth11ADR + ActualPicMonth12ADR}
                  </td>
                  <td className="text-end">
                    {ActualPicMonth7REvenue + ActualPicMonth11REvenue + ActualPicMonth9REvenue}
                  </td>
                </tr>

                <tr>
                  <td>
                    Actual Room -{" "}
                    {GroupPaceReportData.propertyData &&
                      GroupPaceReportData.propertyData.PreviousYear}
                  </td>
                  {GroupPaceReportData.lstPreviousYearActualData !== undefined &&
                    GroupPaceReportData.lstPreviousYearActualData.map((ActRoom) => (
                      <>
                        {ActRoom.MonthNo === 10 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth10Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth10ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth10REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 11 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth11Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth11ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth11REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}

                        {ActRoom.MonthNo === 12 && (
                          <>
                            <td className="text-center">
                              {(ActualRoomMonth12Room = ActRoom.NoOfRooms)}
                            </td>
                            <td className="text-end">{(ActualRoomMonth12ADR = ActRoom.ADR)}</td>
                            <td className="text-end">
                              {(ActualRoomMonth12REvenue = ActRoom.AnticipatedRevenue)}
                            </td>
                          </>
                        )}
                      </>
                    ))}

                  <td className="text-center">
                    {ActualRoomMonth10Room + ActualRoomMonth11Room + ActualRoomMonth12Room}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth10ADR + ActualRoomMonth11ADR + ActualRoomMonth12ADR}
                  </td>
                  <td className="text-end">
                    {ActualRoomMonth10REvenue + ActualRoomMonth11REvenue + ActualRoomMonth12REvenue}
                  </td>
                </tr>

                <tr>
                  <td>Variance</td>
                  <td
                    className={
                      ActualPicMonth10Room - ActualRoomMonth10Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth10Room - ActualRoomMonth10Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth10Room - ActualRoomMonth10Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth10ADR - ActualRoomMonth10ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth10ADR - ActualRoomMonth10ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth10ADR - ActualRoomMonth10ADR).toFixed(2)}{" "}
                  </td>
                  <td
                    className={
                      ActualPicMonth7REvenue - ActualRoomMonth10REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth7REvenue - ActualRoomMonth10REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth7REvenue - ActualRoomMonth10REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth11Room - ActualRoomMonth11Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth11Room - ActualRoomMonth11Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth11Room - ActualRoomMonth11Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth11ADR - ActualRoomMonth11ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth11ADR - ActualRoomMonth11ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth11ADR - ActualRoomMonth11ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth11REvenue - ActualRoomMonth11REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth11REvenue - ActualRoomMonth11REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth11REvenue - ActualRoomMonth11REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth12Room - ActualRoomMonth12Room > 0
                        ? "font-green text-center"
                        : ActualPicMonth12Room - ActualRoomMonth12Room === 0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth12Room - ActualRoomMonth12Room}
                  </td>
                  <td
                    className={
                      ActualPicMonth12ADR - ActualRoomMonth12ADR > 0
                        ? "font-green text-end"
                        : ActualPicMonth12ADR - ActualRoomMonth12ADR === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(ActualPicMonth12ADR - ActualRoomMonth12ADR).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth9REvenue - ActualRoomMonth12REvenue > 0
                        ? "font-green text-end"
                        : ActualPicMonth9REvenue - ActualRoomMonth12REvenue === 0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {ActualPicMonth9REvenue - ActualRoomMonth12REvenue}
                  </td>

                  <td
                    className={
                      ActualPicMonth10Room +
                        ActualPicMonth11Room +
                        ActualPicMonth12Room -
                        (ActualRoomMonth10Room + ActualRoomMonth11Room + ActualRoomMonth12Room) >
                      0
                        ? "font-green text-center"
                        : ActualPicMonth10Room +
                            ActualPicMonth11Room +
                            ActualPicMonth12Room -
                            (ActualRoomMonth10Room +
                              ActualRoomMonth11Room +
                              ActualRoomMonth12Room) ===
                          0
                        ? "text-center"
                        : "font-red text-center"
                    }
                  >
                    {ActualPicMonth10Room +
                      ActualPicMonth11Room +
                      ActualPicMonth12Room -
                      (ActualRoomMonth10Room + ActualRoomMonth11Room + ActualRoomMonth12Room)}
                  </td>
                  <td
                    className={
                      ActualPicMonth10ADR +
                        ActualPicMonth11ADR +
                        ActualPicMonth12ADR -
                        (ActualRoomMonth10ADR + ActualRoomMonth11ADR + ActualRoomMonth12ADR) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth10ADR +
                            ActualPicMonth11ADR +
                            ActualPicMonth12ADR -
                            (ActualRoomMonth10ADR + ActualRoomMonth11ADR + ActualRoomMonth12ADR) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth10ADR +
                        ActualPicMonth11ADR +
                        ActualPicMonth12ADR -
                        (ActualRoomMonth10ADR + ActualRoomMonth11ADR + ActualRoomMonth12ADR)
                    ).toFixed(2)}
                  </td>
                  <td
                    className={
                      ActualPicMonth7REvenue +
                        ActualPicMonth11REvenue +
                        ActualPicMonth9REvenue -
                        (ActualRoomMonth10REvenue +
                          ActualRoomMonth11REvenue +
                          ActualRoomMonth12REvenue) >
                      0
                        ? "font-green text-end"
                        : ActualPicMonth7REvenue +
                            ActualPicMonth11REvenue +
                            ActualPicMonth9REvenue -
                            (ActualRoomMonth10REvenue +
                              ActualRoomMonth11REvenue +
                              ActualRoomMonth12REvenue) ===
                          0
                        ? "text-end"
                        : "font-red text-end"
                    }
                  >
                    {parseFloat(
                      ActualPicMonth7REvenue +
                        ActualPicMonth11REvenue +
                        ActualPicMonth9REvenue -
                        (ActualRoomMonth10REvenue +
                          ActualRoomMonth11REvenue +
                          ActualRoomMonth12REvenue)
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        ) : (
          <PageLoader />
        )}

        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary ms-0"
            onClick={GenerateExcel}
            disabled={Downloading === true ? true : false}
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
          <Button
            variant="primary ms-0"
            disabled={DownloadingPDF === true ? true : false}
            onClick={(e) => {
              e.preventDefault();
              exportPDF();
            }}
          >
            <span className="me-2">
              <img src={PdfIcon} alt="" />
            </span>
            Export PDF
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupPaceReportModal;
