import React from "react";
import { Accordion, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
import AddAccountRuleModal from "../../Model/AddAccountRuleModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";
import {
  DisableAccountRuleByID,
  EnabledAccountRuleByID,
  getAccountRules,
} from "../../Services/Setup&Config/AccountRulesService";

function AccountRules() {
  let page_rights_AccountRules = CommonService.getPageRight("AccountRules");

  const [EditableDataID, setEditableDataID] = React.useState("");
  const [AccountRules, setAccountRules] = React.useState({
    IsData: true,
    data: [],
  });
  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  const [modalShowaccounrule, setModalShowaccounrule] = React.useState(false);

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("accountRuleFilter"))) {
      HandleRemoveOtherFilters("accountRuleFilter");
      HandleFilters("accountRuleFilter", {
        pagePath: "/SetupAndConfigurations/accountRules",
        pageName: "accountRule",
        filters: [],
        isSort: [],
      });
    }
    getAccountRulesDate();
  }, []);
  async function getAccountRulesDate() {
    let input = {
      clientID: clientID,
    };
    setAccountRules({ IsData: true, data: [] });
    let res: any = await getAccountRules(input, Token);

    if (res.data.success) {
      if (res.data !== undefined) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setAccountRules({ IsData: false, data: res.data.data });
        } else {
          setAccountRules({ IsData: true, data: [] });
        }
      }
    }
  }

  function editRule(data) {
    setEditableDataID(data.AccountRulesConfigurationID);
    setModalShowaccounrule(true);
  }
  function disabled(data) {
    Swal.fire({
      title: "Are you sure want to disable this rule ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          KeyID: data.AccountRulesConfigurationID,
        };

        let res: any = await DisableAccountRuleByID(input, Token);

        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              ToasterSuccess("Accoumt Rule Disabled Successfully");

              getAccountRulesDate();
            } else {
              ToasterError("Something Went Wrong");
            }
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }
  function Enabled(data) {
    Swal.fire({
      title: "Are you sure want to Enabled ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input = {
          KeyID: data.AccountRulesConfigurationID,
        };

        let res: any = await EnabledAccountRuleByID(input, Token);

        if (res.data.success) {
          if (res.data !== undefined) {
            if (res.data.statusCode === 200) {
              ToasterSuccess("Accoumt Rule Enabled Successfully");

              getAccountRulesDate();
            } else {
              ToasterError("Something Went Wrong");
            }
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }

  return (
    <>
      <Container fluid={true} className="p-0 position-relative">
        <Row>
          <div className="col-md-12">
            <Accordion className="mycustomaccordian">
              {AccountRules.IsData ? (
                <Skeleton count={10} height={30} />
              ) : AccountRules.data.length !== 0 ? (
                AccountRules.data.map((rules: any, index: any) => (
                  <>
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        <div>{rules.AccountRuleName} :</div>
                      </Accordion.Header>

                      <Accordion.Body>
                        {rules.AccountRuleConfiguration !== null &&
                          rules.AccountRuleConfiguration.length !== 0 &&
                          rules.AccountRuleConfiguration.map((SubRules: any, index) => (
                            <>
                              <div className="d-flex justify-content-between">
                                <div className="rulelist">
                                  <ul>
                                    <li>
                                      <strong>
                                        {SubRules.AccountRulesName}{" "}
                                        <i className="fa-regular fa-right-long me-2 ms-2"></i>{" "}
                                      </strong>
                                      objective="{SubRules.Objective}", status="{SubRules.Status}",
                                      type ="{SubRules.Type}", start date={SubRules.StartDate}(
                                      {SubRules.StartDateRules}), due date={SubRules.EndDate} (
                                      {SubRules.EndDateRules})
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  <span className="btn-icon-grp-table justify-content-end">
                                    <button
                                      onClick={() => {
                                        if (page_rights_AccountRules.is_update) {
                                          editRule(SubRules);
                                        } else {
                                          CommonService.unauthorizedAction();
                                        }
                                      }}
                                      className="btn-icon button btn btn-primary editIcon acteditbtn"
                                    ></button>
                                    {SubRules.IsDisabled === false ||
                                    SubRules.IsDisabled === null ? (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>Disable Rule</Tooltip>}
                                      >
                                        <button
                                          type="button"
                                          onClick={() => disabled(SubRules)}
                                          className="btn-icon button btn fa-light fa-ban btn stopbtn disablestop"
                                        ></button>
                                      </OverlayTrigger>
                                    ) : (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip>Enable Rule</Tooltip>}
                                      >
                                        <button
                                          type="button"
                                          onClick={() => Enabled(SubRules)}
                                          className="btn-icon button btn fa-light fa-ban btn stopbtn disablestop disableBtn"
                                        ></button>
                                      </OverlayTrigger>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </>
                          ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ))
              ) : (
                <div className="noRecordFound">
                  <img src={noRecordFound} alt="" />
                </div>
              )}
            </Accordion>
          </div>
        </Row>
      </Container>

      {modalShowaccounrule === true && (
        <AddAccountRuleModal
          show={modalShowaccounrule}
          onHide={() => setModalShowaccounrule(false)}
          ruleID={EditableDataID}
          Token={Token}
          getAccountRulesDate={getAccountRulesDate}
        />
      )}
    </>
  );
}

export default AccountRules;
