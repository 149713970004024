import React, { useState } from "react";
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import BlackCancelIcon from "../Asset/Images/Icons/blackCancelIcon.svg";
import WhiteSaveIcon from "../Asset/Images/Icons/whiteSaveIcon.svg";
import {
  fnCheckValidationOfObject,
  ToasterError,
  ToasterSuccess,
} from "../Services/CommonServices";
import { getPortfolioDataByID, SaveOrUpdatePortfolio } from "../Services/PortFolioServices";
import { getProjectTermByCategory } from "../Services/Setup&Config/AdditionalSettingService";
import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import {
  CompSetHotelsGetByPrimaryKey,
  CompSetHotelsSave,
} from "../Services/Setup&Config/HotelService";

const AddEditHotelDetailsCompetitorModal = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;
  const [OnProcessHide, setOnProcessHide] = React.useState(false);

  const [CompetitorSave, setCompetitorSave]: any = useState({
    HotelID: props && props.property_id,
    CompHotelID: "00000000-0000-0000-0000-000000000000",
    HotelName: "",
    HotelCode: "",
    BrandName: "",
    Address: "",
    RateShopURL: "",
    WebsiteURL: "",
    HotelLogoURL: "",
    HotelLogo: "",
    OrderNo: 0,
    errors: {
      HotelName: "",
      // HotelCode: "",
      BrandName: "",
      // Address: "",
      RateShopURL: "",
      // WebsiteURL: "",
      ValidationRules: [
        {
          FieldName: "HotelName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        // {
        //   FieldName: "HotelCode",
        //   ValidationType: "required",
        //   ValidationMessage: "This field is required...!",
        // },
        {
          FieldName: "BrandName",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        // {
        //   FieldName: "Address",
        //   ValidationType: "required",
        //   ValidationMessage: "This field is required...!",
        // },
        {
          FieldName: "RateShopURL",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "RateShopURL",
          ValidationType: "invalidurl",
          ValidationMessage: "Invalid URL!",
        },
        // {
        //   FieldName: "WebsiteURL",
        //   ValidationType: "required",
        //   ValidationMessage: "This field is required...!",
        // },
      ],
    },
  });
  const [ChainItems, setChainItems] = useState([]);

  // Upload LOGO :
  const [Logo, setLogo]: any = useState({
    name: "",
    link: [],
    errors: "",
  });
  const [LogoUpload, setLogoUpload]: any = useState(null);

  function SelectLogo(e: any) {
    if (e.target.files.length !== 0) {
      setLogo({ ...Logo, ["link"]: e.target.files });
      setCompetitorSave({
        ...CompetitorSave,
        ["HotelLogo"]: e.target.files[0],
      });
      const fileLoaded: any = URL.createObjectURL(e.target.files[0]);
      setLogoUpload(fileLoaded);
    }
  }

  React.useEffect(() => {
    if (props && props.property_id) {
      getHotelBrandsByProjectTerm();
    }
    if (
      props &&
      props.competitorID !== undefined &&
      props.competitorID !== null &&
      props.competitorID !== ""
    ) {
      //   setCompetitorSave({
      //     ...CompetitorSave,
      //     ["HotelID"]: props.CompetitorEditData.HotelID,
      //     ["CompHotelID"]: props.CompetitorEditData.CompHotelID,
      //     ["HotelName"]: props.CompetitorEditData.HotelName,
      //     ["HotelCode"]: props.CompetitorEditData.HotelCode,
      //     ["BrandName"]: props.CompetitorEditData.BrandName,
      //     ["Address"]: props.CompetitorEditData.Address,
      //     ["RateShopURL"]: props.CompetitorEditData.RateShopURL,
      //     ["WebsiteURL"]: props.CompetitorEditData.WebsiteURL,
      //     ["HotelLogoURL"]: props.CompetitorEditData.HotelLogoURL,
      //     ["HotelLogo"]: props.CompetitorEditData.HotelLogo,
      //     ["OrderNo"]: props.CompetitorEditData.OrderNo,
      //   });
      //   if (props.CompetitorEditData.HotelLogo) {
      //     const fileLoaded: any = URL.createObjectURL(props.CompetitorEditData.HotelLogo);
      //     setLogoUpload(fileLoaded);
      //   }
      // setLogoUpload(props.competitorID.HotelLogo)
      getCompetitorDetails(props.competitorID);
    }
  }, []);

  async function getCompetitorDetails(competitorID) {
    let input: any = {
      keyID: competitorID,
    };
    setOnProcessHide(true);
    let res: any = await CompSetHotelsGetByPrimaryKey(input, Token);
    setOnProcessHide(false);

    if (res.data.success) {
      if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
        setLogoUpload(res.data.data.hotelLogoURL);

        setCompetitorSave({
          ...CompetitorSave,
          ["HotelID"]: res.data.data.hotelID,
          ["CompHotelID"]: res.data.data.compHotelID,
          ["HotelName"]: res.data.data.hotelName,
          ["HotelCode"]: res.data.data.hotelCode,
          ["BrandName"]: res.data.data.brandName,
          ["Address"]: res.data.data.address,
          ["RateShopURL"]: res.data.data.rateShopURL,
          ["WebsiteURL"]: res.data.data.websiteURL,
          ["HotelLogoURL"]: res.data.data.hotelLogoURL,
          // ["HotelLogo"]: res.data.data.HotelLogo,
          ["OrderNo"]: res.data.data.orderNo,
        });
      }
    }
  }

  async function getHotelBrandsByProjectTerm() {
    let input: any = {
      clientID: "00000000-0000-0000-0000-000000000000",
      category: "Hotel Brand",
    };
    let res: any = await getProjectTermByCategory(input, Token);

    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          let tempBrand =
            res.data.data.dataList1.length > 0 &&
            res.data.data.dataList1.filter((item) => item.Category === "Hotel Brand");
          setChainItems(tempBrand);
        }
      }
    }
  }

  async function SavePortFolio() {
    let obj = fnCheckValidationOfObject(CompetitorSave);
    setCompetitorSave({
      ...obj.obj,
    });
    if (obj.isValid) {
      //   props.setCompetitorData(CompetitorSave);
      //   // props.setCompetitorData([...props.CompetitorData, CompetitorSave])
      //   handleClose();
      //   props.onHide(false);

      let formData: any = new FormData();
      formData.append("HotelID", CompetitorSave.HotelID);
      formData.append("CompHotelID", CompetitorSave.CompHotelID);
      formData.append("HotelName", CompetitorSave.HotelName);
      formData.append("HotelCode", null);
      formData.append("BrandName", CompetitorSave.BrandName);
      formData.append("Address", CompetitorSave.Address);
      formData.append("RateShopURL", CompetitorSave.RateShopURL);
      formData.append("WebsiteURL", CompetitorSave.WebsiteURL);
      formData.append("HotelLogoURL", CompetitorSave.HotelLogoURL);
      // formData.append("HotelLogo", CompetitorSave.HotelLogo);
      formData.append("OrderNo", CompetitorSave.OrderNo);
      // formData.append("HotelLogo", Logo.link[0] !== undefined ? Logo.link[0] : null);
      formData.append("HotelLogo", null);

      setOnProcessHide(true);
      const res: any = await CompSetHotelsSave(formData, Token);
      setOnProcessHide(false);
      if (res.data !== undefined) {
        if (res.data.statusCode === 200) {
          props.fetchCompetitorListData();
          ToasterSuccess("Record saved Successfully");
          handleClose();
          props.onHide(false);
        }
      } else {
        ToasterError(res.message);
      }
    }
  }

  const handleClose = () => {
    setCompetitorSave({
      HotelID: null,
      CompHotelID: "00000000-0000-0000-0000-000000000000",
      HotelName: "",
      HotelCode: "",
      BrandName: "",
      Address: "",
      RateShopURL: "",
      WebsiteURL: "",
      HotelLogoURL: "",
      HotelLogo: "",
      OrderNo: 0,
      errors: {
        HotelName: "",
        // HotelCode: "",
        BrandName: "",
        Address: "",
        RateShopURL: "",
        // WebsiteURL: "",
        ValidationRules: [
          {
            FieldName: "HotelName",
            ValidationType: "required",
            ValidationMessage: "This field is required...!",
          },
          // {
          //   FieldName: "HotelCode",
          //   ValidationType: "required",
          //   ValidationMessage: "This field is required...!",
          // },
          {
            FieldName: "BrandName",
            ValidationType: "required",
            ValidationMessage: "This field is required...!",
          },
          // {
          //   FieldName: "Address",
          //   ValidationType: "required",
          //   ValidationMessage: "This field is required...!",
          // },
          {
            FieldName: "RateShopURL",
            ValidationType: "required",
            ValidationMessage: "This field is required...!",
          },
          {
            FieldName: "RateShopURL",
            ValidationType: "invalidurl",
            ValidationMessage: "Invalid URL!",
          },
          // {
          //   FieldName: "WebsiteURL",
          //   ValidationType: "required",
          //   ValidationMessage: "This field is required...!",
          // },
        ],
      },
    });
    props.onHide(false);
  };

  let items: any = [];
  ChainItems !== undefined &&
    ChainItems !== null &&
    ChainItems.length > 0 &&
    ChainItems.map((item: any) => {
      items.push({
        label: item.TermCode + " - " + item.Term,
        value: item.TermCode + " - " + item.Term,
      });
    });

  function handleOnSelect(event) {
    if (event !== null) {
      setCompetitorSave({
        ...CompetitorSave,
        ["BrandName"]: event.value,
      });
    } else {
      setCompetitorSave({
        ...CompetitorSave,
        ["BrandName"]: "",
      });
    }
  }

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-50w"
        className="quickAdd-popup"
        scrollable={true}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Competitor Hotel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Container fluid="true" className="p-0">
                <Row className="m-0 mb-2">
                  {/* <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Hotel Code">
                      <Form.Control
                        type="text"
                        // maxLength={364}
                        placeholder="Hotel Code"
                        value={CompetitorSave.HotelCode}
                        onChange={(e) =>
                          setCompetitorSave({
                            ...CompetitorSave,
                            ["HotelCode"]: e.target.value,
                          })
                        }
                        isInvalid={CompetitorSave.errors && CompetitorSave.errors.HotelCode}
                      />
                      {CompetitorSave.errors && CompetitorSave.errors.HotelCode && (
                        <Form.Control.Feedback type="invalid">
                          {CompetitorSave.errors && CompetitorSave.errors.HotelCode}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Col> */}
                  <Col xs={12} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Hotel Name">
                      <Form.Control
                        type="text"
                        // maxLength={364}
                        value={CompetitorSave.HotelName}
                        placeholder="Hotel Name"
                        onChange={(e) =>
                          setCompetitorSave({
                            ...CompetitorSave,
                            ["HotelName"]: e.target.value,
                          })
                        }
                        isInvalid={CompetitorSave.errors && CompetitorSave.errors.HotelName}
                      />
                      {CompetitorSave.errors.HotelName && (
                        <Form.Control.Feedback type="invalid">
                          {CompetitorSave.errors.HotelName}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="m-0 mb-2">
                  <Col xs={6} className="mb-3 fild-input">
                    <div
                      className={`${
                        CompetitorSave.errors &&
                        CompetitorSave.errors.BrandName &&
                        "red-border-masking"
                      }`}
                    >
                      <FloatingLabel controlId="floatingInput" label="">
                        {CompetitorSave.BrandName !== "" ? (
                          <CreatableSelect
                            isClearable
                            options={items}
                            onChange={(event: any) => {
                              handleOnSelect(event);
                            }}
                            placeholder="Chain*"
                            value={{
                              label: CompetitorSave.BrandName,
                              value: CompetitorSave.BrandName,
                            }}
                          />
                        ) : (
                          <CreatableSelect
                            isClearable
                            options={items}
                            onChange={(event: any) => {
                              handleOnSelect(event);
                            }}
                            placeholder="Chain*"
                          />
                        )}
                      </FloatingLabel>
                    </div>
                    <small style={{ color: "#dc3545" }}>
                      {CompetitorSave.errors && CompetitorSave.errors.BrandName}{" "}
                    </small>
                  </Col>

                  <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Address">
                      <Form.Control
                        type="text"
                        placeholder="Address"
                        value={CompetitorSave.Address}
                        onChange={(e) =>
                          setCompetitorSave({
                            ...CompetitorSave,
                            ["Address"]: e.target.value,
                          })
                        }
                        // isInvalid={CompetitorSave.errors && CompetitorSave.errors.Address}
                      />
                      {/* {CompetitorSave.errors.Address && (
                        <Form.Control.Feedback type="invalid">
                          {CompetitorSave.errors.Address}
                        </Form.Control.Feedback>
                      )} */}
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="m-0">
                  <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Rateshop URL">
                      <Form.Control
                        type="text"
                        // maxLength={16}
                        value={CompetitorSave.RateShopURL}
                        placeholder="Rateshop URL"
                        onChange={(e) =>
                          setCompetitorSave({
                            ...CompetitorSave,
                            ["RateShopURL"]: e.target.value,
                          })
                        }
                        isInvalid={CompetitorSave.errors && CompetitorSave.errors.RateShopURL}
                      />
                      {CompetitorSave.errors.RateShopURL && (
                        <Form.Control.Feedback type="invalid">
                          {CompetitorSave.errors.RateShopURL}
                        </Form.Control.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col xs={6} className="mb-3">
                    <FloatingLabel controlId="floatingInput" label="Website URL">
                      <Form.Control
                        type="text"
                        // maxLength={16}
                        // defaultValue={CompetitorSave.HPropertyCode}
                        value={CompetitorSave.WebsiteURL}
                        placeholder="Website URL"
                        onChange={(e) =>
                          setCompetitorSave({
                            ...CompetitorSave,
                            ["WebsiteURL"]: e.target.value,
                          })
                        }
                        // isInvalid={CompetitorSave.errors && CompetitorSave.errors.WebsiteURL}
                      />
                      {/* {CompetitorSave.errors.WebsiteURL && (
                        <Form.Control.Feedback type="invalid">
                          {CompetitorSave.errors.WebsiteURL}
                        </Form.Control.Feedback>
                      )} */}
                    </FloatingLabel>
                  </Col>
                  {/* <Col xs={4} className="mb-3">
                    <div className="select-logo-title">Logo</div>

                    <div className="custom-file comaninputfile">
                      {LogoUpload && (
                        <img
                          src={LogoUpload}
                          style={{
                            display: "flex",
                            border: "2px solid tomato",
                            maxWidth: "300px",
                            maxHeight: "300px",
                          }}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="comninputbtn">
                      <span>Select Logo</span>
                      <input
                        type="file"
                        accept="image/*"
                        id="inputGroupFile01"
                        className=""
                        onChange={SelectLogo}
                      />
                    </div>
                  </Col> */}
                </Row>
              </Container>
            </Row>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => SavePortFolio()}
            disabled={OnProcessHide === true ? true : false}
          >
            <span className="me-2">
              <img src={WhiteSaveIcon} alt="" />
            </span>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddEditHotelDetailsCompetitorModal;
