import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import BlackCancelIcon from "../../Asset/Images/Icons/blackCancelIcon.svg";
import PdfIcon from "../../Asset/Images/Icons/PdfIcon.svg";
import { CommonService, ToasterError } from "../../Services/CommonServices";
import { getGroupRoomCountReportList } from "../../Services/ReportServices";
import XLSX, { utils } from "sheetjs-style";
import PageLoader from "../../Component/Loader/PageLoader";
import noRecordFound from "../../Asset/Images/Other/no-record-found.svg";
import ExportIcon from "../../Asset/Images/Icons/exporticon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDownloadExcel } from "react-export-table-to-excel";

const GroupRoomCountModal = (props) => {
  const dataSelector: any = useSelector((state: any) => state.session);
  const Token = dataSelector.access_Token;

  const [loading, setloading] = useState(false);
  const [GroupRoomBookedData, setGroupRoomBookedData] = useState([]);
  const [GroupRoomBookedStatus, setGroupRoomBookedStatus] = useState([]);

  useEffect(() => {
    fetchMeetingRoomBookedData();
  }, []);

  async function fetchMeetingRoomBookedData() {
    let input: any = {
      propertyIDs: props.ReportData.propertyIDs,
      yearID: props.ReportData.yearID,
      monthID: props.ReportData.monthID,
    };
    setloading(true);
    let res = await getGroupRoomCountReportList(input, Token);
    setloading(false);
    if (res.status === 200) {
      if (res.data.success) {
        if (res.data.data !== undefined && res.data.data) {
          if (
            res.data.data.lstReportData !== undefined &&
            res.data.data.lstReportData &&
            res.data.data.lstReportData.length
          ) {
            let tempStatus: any = [];
            res.data.data.lstReportData.length &&
              res.data.data.lstReportData.map((item, index) => {
                if (
                  tempStatus.includes(item["Status"]) === false &&
                  item["Status"].includes("Total") === false
                ) {
                  tempStatus.push(item["Status"]);
                }
              });

            setGroupRoomBookedData(res.data.data.lstReportData);
            setGroupRoomBookedStatus(tempStatus);

            // setLoading(false);
          } else {
            // setData([])
            // setTotalRecord([])
            // setLoading(false);
          }
        } else {
          // setLoading(false);
        }
      } else {
        // setLoading(false);
        ToasterError(res.data.message);
      }
    } else {
      // setLoading(false);
      ToasterError(res.data.message);
    }
  }

  let page_rights_Account = CommonService.getPageRight("Account");
  let navigate = useNavigate();
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "GroupOpportunity" + props.ReportData.month + props.ReportData.year,
    sheet: "Group Opportunity Month View",
  });

  let GroupRoomCountTable: any = () => {
    return (
      <>
        {GroupRoomBookedStatus !== undefined &&
          GroupRoomBookedStatus &&
          GroupRoomBookedStatus.length &&
          GroupRoomBookedStatus.map((itm, idx) => {
            return (
              <>
                <thead>
                  <tr>
                    <th>{itm}</th>
                    {GroupRoomBookedData !== undefined &&
                      GroupRoomBookedData &&
                      GroupRoomBookedData.length && (
                        <>
                          <th>PropertyName</th>
                          <th>AccountName</th>
                          <th style={{ textAlign: "right" }}>Date</th>
                          {Object.keys(GroupRoomBookedData[0]).map((item, index) => {
                            if (index < Object.keys(GroupRoomBookedData[0]).length - 9) {
                              return <th key={index + 1}>{index + 1}</th>;
                            }
                          })}

                          <th style={{ textAlign: "right" }}>Revenue($)</th>
                          <th style={{ textAlign: "right" }}>NetRev.($)</th>
                          <th style={{ textAlign: "right" }}>ADR($)</th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {GroupRoomBookedData.filter(
                    (item) => item.Status === itm || item.Status === itm + " Total"
                  ).map((data, index1) => {
                    {
                      return (
                        <tr className={data.Status.includes(" Total") ? "total" : ""}>
                          <td><p className="forbreakline"> {data["OppoName"]} </p></td>
                          {GroupRoomBookedData !== undefined &&
                            GroupRoomBookedData &&
                            GroupRoomBookedData.length && (
                              <>
                                <td> <p className="forbreakline"> {data["PropertyName"]} </p></td>
                                <td>
                                <p className="forbreakline">
                                  <div
                                    className="report-content-one report-user-content"
                                    role="button"
                                    tabIndex={-1}
                                    style={{ cursor: "pointer", color: "blue" }}
                                    onClick={() => {
                                      if (page_rights_Account.is_view) {
                                        navigate("/accounts-view", {
                                          state: {
                                            AccountID: data["AccountID"],
                                            AccountName: data["AccountName"],
                                          },
                                        });
                                      } else {
                                        CommonService.unauthorizedAction();
                                      }
                                    }}
                                    onKeyDown={() => {
                                      if (page_rights_Account.is_view) {
                                        navigate("/accounts-view", {
                                          state: {
                                            AccountID: data["AccountID"],
                                            AccountName: data["AccountName"],
                                          },
                                        });
                                      } else {
                                        CommonService.unauthorizedAction();
                                      }
                                    }}
                                  >
                                    {data["AccountName"]}
                                  </div>
                                  </p>
                                </td>
                                <td> {data["Date"]}</td>
                                {Object.keys(GroupRoomBookedData[0]).map((item, index) => {
                                  if (index < Object.keys(GroupRoomBookedData[0]).length - 9) {
                                    return <td key={index + 1}>{data[index + 1]}</td>;
                                  }
                                })}

                                <td style={{ textAlign: "right" }}>{data["Revenue($)"]}</td>
                                <td style={{ textAlign: "right" }}>{data["NetRev.($)"]}</td>
                                <td style={{ textAlign: "right" }}>{data["ADR($)"]}</td>
                              </>
                            )}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </>
            );
          })}
      </>
    );
  };

  let GroupRoomCountTableExport: any = () => {
    return (
      <>
      <thead>
        <tr><th colSpan={2} 
        style={{background: "#417FF9", color: "#ffffff", fontSize:"11", fontWeight:"600" }}
        >Group Opportunity - ({props.ReportData.month +" "+ props.ReportData.year})</th></tr>
        <tr></tr>
      </thead>
        {GroupRoomBookedStatus !== undefined &&
          GroupRoomBookedStatus &&
          GroupRoomBookedStatus.length &&
          GroupRoomBookedStatus.map((itm, idx) => {
            return (
              <>
                <thead>
                  <tr>
                    <th style={{textAlign: "left"}}>{itm}</th>
                    {GroupRoomBookedData !== undefined &&
                      GroupRoomBookedData &&
                      GroupRoomBookedData.length && (
                        <>
                          <th style={{textAlign: "left"}}>PropertyName</th>
                          <th style={{textAlign: "left"}}>AccountName</th>
                          <th style={{ textAlign: "right" }}>Date</th>
                          {Object.keys(GroupRoomBookedData[0]).map((item, index) => {
                            if (index < Object.keys(GroupRoomBookedData[0]).length - 9) {
                              return <th key={index + 1}>{index + 1}</th>;
                            }
                          })}

                          <th style={{ textAlign: "right" }}>Revenue($)</th>
                          <th style={{ textAlign: "right" }}>NetRev.($)</th>
                          <th style={{ textAlign: "right" }}>ADR($)</th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {GroupRoomBookedData.filter(
                    (item) => item.Status === itm || item.Status === itm + " Total"
                  ).map((data, index1) => {
                    {
                      return (
                        <tr className={data.Status.includes(" Total") ? "total" : ""}>
                          <td>{data["OppoName"]}</td>
                          {GroupRoomBookedData !== undefined &&
                            GroupRoomBookedData &&
                            GroupRoomBookedData.length && (
                              <>
                                <td>{data["PropertyName"]}</td>
                                <td>
                                  <div
                                    className="report-content-one report-user-content"
                                    role="button"
                                    tabIndex={-1}
                                    style={{ cursor: "pointer", color: "blue" }}
                                    onClick={() => {
                                      if (page_rights_Account.is_view) {
                                        navigate("/accounts-view", {
                                          state: {
                                            AccountID: data["AccountID"],
                                            AccountName: data["AccountName"],
                                          },
                                        });
                                      } else {
                                        CommonService.unauthorizedAction();
                                      }
                                    }}
                                    onKeyDown={() => {
                                      if (page_rights_Account.is_view) {
                                        navigate("/accounts-view", {
                                          state: {
                                            AccountID: data["AccountID"],
                                            AccountName: data["AccountName"],
                                          },
                                        });
                                      } else {
                                        CommonService.unauthorizedAction();
                                      }
                                    }}
                                  >
                                    {data["AccountName"]}
                                  </div>
                                </td>
                                <td>{data["Date"]}</td>
                                {Object.keys(GroupRoomBookedData[0]).map((item, index) => {
                                  if (index < Object.keys(GroupRoomBookedData[0]).length - 9) {
                                    return <td key={index + 1}>{data[index + 1]}</td>;
                                  }
                                })}

                                <td style={{ textAlign: "right" }}>{data["Revenue($)"]}</td>
                                <td style={{ textAlign: "right" }}>{data["NetRev.($)"]}</td>
                                <td style={{ textAlign: "right" }}>{data["ADR($)"]}</td>
                              </>
                            )}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </>
            );
          })}
      </>
    );
  };

  return (
    <>
      <Modal {...props} fullscreen>
        <Modal.Header closeButton>
          <Modal.Title>
            Group Opportunity - ({props.ReportData.month && props.ReportData.month}{" "}
            {props.ReportData.year && props.ReportData.year})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-3">
            <Col xs={12} xl={12} className="mb-3 mb-xl-0">
              <div className="information-division">
                <h3 className="inner-header m-0">Group Opportunity </h3>
                <div className="tab-inner-box position-relative">
                  {/* <div className="customtablemetting"> */}
                  {!loading ? (
                    GroupRoomBookedData.length ? (
                      <>
                        <div className="">
                          <Table responsive bordered className="table-without-space mb-0">
                            <GroupRoomCountTable />
                          </Table>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="opportunity">
                          <div className="noRecordFound">
                            <img src={noRecordFound} alt="" />
                          </div>
                        </div>
                      </>
                    )
                  ) : (
                    <>
                      <PageLoader />
                    </>
                  )}
                  {/* </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer className="pt-2">
          <Button variant="secondary" onClick={props.onHide}>
            <span className="me-2">
              <img src={BlackCancelIcon} alt="" />
            </span>
            Close
          </Button>
          <Button
            variant="primary ms-0"
            disabled={GroupRoomBookedData.length ? false : true}
            onClick={onDownload}
          >
            <span className="me-2">
              <img src={ExportIcon} alt="" />
            </span>
            Export Excel
          </Button>
        </Modal.Footer>
      </Modal>
      <Table ref={tableRef} responsive bordered className="table-without-space mb-0" style={{display:"none"}}>
        <GroupRoomCountTableExport />
      </Table>
    </>
  );
};

export default GroupRoomCountModal;
