import { ENUMS, INITIALSTATES } from "../../Constants";

export default function sessionReducer(state = INITIALSTATES.sessionInitState, action) {
  switch (action.type) {
    case ENUMS.actions.UPDATE_USER:
      return action.payload;
    case ENUMS.actions.SET_IS_LOGGED:
      return {
        ...state,
        is_logged: action.payload,
      };
    case ENUMS.actions.SET_USER_DETAILS:
      return {
        ...state,
        auth_UserDetail: action.payload,
      };
    case ENUMS.actions.ACCESS_TOKEN:
      return {
        ...state,
        access_Token: action.payload,
      };
    case ENUMS.actions.UPDATE_AUTH_USER_DTLS:
      return {
        ...state,
        auth_UserDetail: action.payload,
      };
    case ENUMS.actions.UPDATE_LEAD_FETCH_COUNT:
      return {
        ...state,
        lead_data_reload_count: action.payload,
      };
    case ENUMS.actions.UPDATE_ACCOUNT_FETCH_COUNT:
      return {
        ...state,
        account_data_reload_count: action.payload,
      };
    case ENUMS.actions.UPDATE_CONTACT_FETCH_COUNT:
      return {
        ...state,
        contact_data_reload_count: action.payload,
      };
    case ENUMS.actions.UPDATE_ACTIVITY_FETCH_COUNT:
      return {
        ...state,
        activity_data_reload_count: action.payload,
      };

    default:
      return state;
  }
}
