import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { getProjectTermByCategory } from "../../Services/Setup&Config/AdditionalSettingService";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";
import { getAccountDropdownData } from "../../Services/DashboardServices";
import ActualReportModal from "../../Model/Reports/ActualReportModal";

function ActualReport() {
  let ActualReport_page_rights = CommonService.getPageRight("Actual Report");

  const dataSelector: any = useSelector((state: any) => state.session);
  const hotelsData: any = useSelector((state: any) => state.header.Hotels);

  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const [ReportData, setReportData]: any = React.useState({
    propertyID: "",
    propertyName: "",
    accountIDs: null,
    year: "",
    month: "",

    yearID: "",
    errors: {
      propertyID: "",
      yearID: "",
      accountIDs: null,
      ValidationRules: [
        {
          FieldName: "propertyID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "yearID",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
        {
          FieldName: "accountIDs",
          ValidationType: "required",
          ValidationMessage: "This field is required...!",
        },
      ],
    },
  });

  let currentYear = new Date().getFullYear();
  const [selectedYear, setselectedYear]: any = React.useState(currentYear);

  const [accountListData, setaccountListData]: any = useState([]);
  const [selAccount, setSelAccount]: any = useState([]);

  const [showModal, setshowModal] = React.useState(false);
  const Token = dataSelector.access_Token;

  const [yearList, setYearList] = useState([]);
  const [hotelChangeLoading, sethotelChangeLoading] = useState(false); // Hotel Selection Loader State.

  const [selHotels, setSelHotels]: any = useState([]);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;

  let [PropertiesListData, setPropertiesListData]: any = useState({
    IsData: true,
    data: [],
  });
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );

  let HotelsDropdown: any = [];
  PropertiesListData.data !== undefined &&
    PropertiesListData.data !== null &&
    PropertiesListData.data.length > 0 &&
    PropertiesListData.data.map((item: any) => {
      HotelsDropdown.push({
        label: item.HotelCode + " " + item.PropertyName,
        value: item.PropertyID,
      });
    });

  let optionsAccount: any = [{ label: "All Accounts", value: "" }];

  accountListData.length > 0 &&
    accountListData.map((item: any) => {
      optionsAccount.push({
        label: item.AccountName,
        value: item.AccountID,
      });
    });

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("actualReportFilter"))) {
      HandleRemoveOtherFilters("actualReportFilter");
      HandleFilters("actualReportFilter", {
        pagePath: "/ActualReport",
        pageName: "actualReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    fetchPrimaryDetails();
    getHotelsList();
  }, [SelectedPropertyName]);

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setPropertiesListData({ IsData: true, data: [] });
    let res: any = await GetHotelsListForReports(input, Token);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setPropertiesListData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setPropertiesListData({ IsData: false, data: [] });
        }
      } else {
        setPropertiesListData({ IsData: false, data: [] });
      }
    } else {
      setPropertiesListData({ IsData: false, data: [] });
    }
  }

  const fetchPrimaryDetails = async () => {
    let yearInput = {
      clientID: clientID,
      category: "year",
    };
    let resYear = await getProjectTermByCategory(yearInput, Token);

    if (resYear.status === 200) {
      if (resYear.data.success) {
        if (resYear.data.data !== undefined && resYear.data.data) {
          if (
            resYear.data.data.dataList1 !== undefined &&
            resYear.data.data.dataList1 &&
            resYear.data.data.dataList1.length
          ) {
            let yrArray: any = [];
            resYear.data.data.dataList1.forEach((itm) => {
              yrArray = [
                ...yrArray,
                {
                  value: itm.TermID,
                  label: itm.TermCode,
                },
              ];

              if (SelectedPropertyName) {
                if (!SelectedPropertyID.includes(",")) {
                  setSelAccount([]);
                  setSelHotels([
                    {
                      value: SelectedPropertyID,
                      label: SelectedPropertyName,
                    },
                  ]);
                  if (selectedYear.toString() === itm.TermCode) {
                    setReportData({
                      ...ReportData,
                      ["propertyID"]: SelectedPropertyID,
                      ["propertyName"]: SelectedPropertyName,
                      ["accountIDs"]: null,
                      ["year"]: itm.TermCode,
                      ["yearID"]: itm.TermID,
                    });
                    getAccountsDDTData(SelectedPropertyID);
                  }
                }
              } else {
                if (selectedYear.toString() === itm.TermCode) {
                  setReportData({
                    ...ReportData,
                    ["year"]: itm.TermCode,
                    ["yearID"]: itm.TermID,
                  });
                }
              }
            });
            setYearList(yrArray);
          }
        }
      }
    } else {
      ToasterError(resYear.message);
    }
  };

  async function getAccountsDDTData(selectedHotelId) {
    sethotelChangeLoading(true);
    if (selectedHotelId) {
      let input: any = {
        propertiesIDs: null,
      };
      if (selectedHotelId !== undefined && selectedHotelId !== null && selectedHotelId !== "") {
        input.propertiesIDs = selectedHotelId;
      }

      let res: any = await getAccountDropdownData(input, Token);

      if (res.data.success) {
        if (res.data !== undefined && res.data !== null && res.data !== "") {
          if (res.data.data !== undefined && res.data.data !== null && res.data.data !== "") {
            setaccountListData(res.data.data.dataList1);
          } else {
            setaccountListData([]);
          }
        } else {
          setaccountListData([]);
        }
      } else {
        setaccountListData([]);
      }
    }
    sethotelChangeLoading(false);
  }

  async function handleGenerateReport() {
    let obj = fnCheckValidationOfObject(ReportData);

    setReportData({
      ...obj.obj,
    });

    if (obj.isValid) {
      setReportData(ReportData);
      setshowModal(true);
    }
  }

  function SelectHotel(event: any) {
    if (event) {
      setSelAccount([]);
      setReportData({
        ...ReportData,
        propertyID: event.value,
        propertyName: event.label,
        accountIDs: null,
      });
      getAccountsDDTData(event.value);
    } else {
      setSelAccount([]);
      setReportData({
        ...ReportData,
        propertyID: "",
        accountIDs: null,
      });
      optionsAccount = [];
      setaccountListData([]);
    }
  }

  return (
    <>
      <div className="report-page">
        <Row>
          <Col xxl={3} xl={3} lg={3} md={4}>
            <ReportNavigations />
          </Col>
          <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
            {ActualReport_page_rights.is_view ? (
              PropertiesListData.IsData === false ? (
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={12} className="mb-4">
                      <div
                        className={`${
                          ReportData.errors && ReportData.errors.propertyID && "red-border-masking"
                        }`}
                      >
                        <FloatingLabel label="">
                          <Select
                            placeholder="Select Property"
                            options={HotelsDropdown}
                            // value={selHotels}
                            value={HotelsDropdown.filter(
                              (itm) =>
                                ReportData.propertyID && itm.value.includes(ReportData.propertyID)
                            )}
                            onChange={(e: any) => {
                              setSelHotels(e);
                              SelectHotel(e);
                            }}
                          ></Select>
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {ReportData.errors && ReportData.errors.propertyID}{" "}
                      </small>
                    </Col>
                    <Col xs={12} className="mb-3">
                      <Form.Group>
                        <div
                          className={`${
                            ReportData.errors &&
                            ReportData.errors.accountIDs &&
                            "red-border-masking"
                          }`}
                        >
                          <FloatingLabel controlId="floatingSelectGrid" label="">
                            <Select
                              styles={{
                                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                              }}
                              placeholder="Select Account"
                              options={optionsAccount}
                              isMulti
                              value={selAccount}
                              defaultValue={optionsAccount.filter(
                                (itm) =>
                                  ReportData.accountIDs && itm.value.includes(ReportData.accountIDs)
                              )}
                              onChange={(event) => {
                                // setSelAccount(event);
                                // let tempAccount: any = [];

                                if (event.length) {
                                  //   Array.isArray(event)
                                  //     ? event.map((x) => {
                                  //         tempAccount.push(x.value);
                                  //       })
                                  //     : [];

                                  //   setReportData({
                                  //     ...ReportData,
                                  //     accountIDs: tempAccount.toString(),
                                  //   });

                                  let data = event.filter((itm) => itm.label === "All Accounts");
                                  optionsAccount.splice(data, 1);
                                  let tempAccount: any = [];

                                  if (data.length) {
                                    Array.isArray(optionsAccount)
                                      ? optionsAccount.map((x) => {
                                          tempAccount.push(x.value);
                                        })
                                      : [];

                                    setReportData({
                                      ...ReportData,
                                      accountIDs: tempAccount.toString(),
                                    });
                                    setSelAccount(optionsAccount);
                                  } else {
                                    Array.isArray(event)
                                      ? event.map((x) => {
                                          tempAccount.push(x.value);
                                        })
                                      : [];

                                    setReportData({
                                      ...ReportData,
                                      accountIDs: tempAccount.toString(),
                                    });
                                    setSelAccount(event);
                                  }
                                } else {
                                  setReportData({
                                    ...ReportData,
                                    accountIDs: null,
                                  });
                                  setSelAccount([]);
                                }
                              }}
                            />
                          </FloatingLabel>
                        </div>
                        <small style={{ color: "#dc3545" }}>
                          {ReportData.errors && ReportData.errors.accountIDs}{" "}
                        </small>
                      </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-4">
                      <div
                        className={`${
                          ReportData.errors && ReportData.errors.yearID && "red-border-masking"
                        }`}
                      >
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            value={yearList.filter(
                              (obj: any) => obj.label === selectedYear.toString()
                            )}
                            options={yearList}
                            name="colors"
                            placeholder="Select Year"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            isDisabled={false}
                            onChange={(itm: any) => {
                              setselectedYear(itm.label);
                              setReportData({
                                ...ReportData,
                                ["year"]: itm.label,
                                ["yearID"]: itm.value,
                              });
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {ReportData.errors && ReportData.errors.yearID}{" "}
                      </small>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Modal.Footer className="border-0 justify-content-start">
                        <Button
                          variant="primary ms-0"
                          onClick={() => {
                            if (ActualReport_page_rights.is_view) {
                              handleGenerateReport();
                            } else {
                              CommonService.unauthorizedAction();
                            }
                          }}
                        >
                          <span className="me-2">
                            <img src={GenerateReport} alt="" />
                          </span>
                          Generate Report
                        </Button>
                      </Modal.Footer>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
                  <PageLoader />
                </Col>
              )
            ) : (
              <Unauthorized />
            )}
          </Col>
        </Row>
        {showModal && (
          <ActualReportModal
            ActualReportData={ReportData}
            show={showModal}
            onHide={() => {
              setshowModal(false);
            }}
          />
        )}
      </div>
      {hotelChangeLoading ? (
        <div className="calender-loader-main">
          <PageLoader />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default ActualReport;
