import React from "react";
import { Button, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import TableView from "../../Component/Table/Index";
import MarketSegmentModal from "../../Model/MarketSegmentModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  ToasterError,
  ToasterSuccess,
} from "../../Services/CommonServices";
import {
  DeleteMarketSegmentRecord,
  MarketSegmentList,
} from "../../Services/Setup&Config/MarketSegmentService";

function MarketSagmentTypes() {
  let page_rights_MarketSegment = CommonService.getPageRight("MarketSegmentType");

  const dataSelector: any = useSelector((state: any) => state.session);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;

  const [modalShow, setModalShow] = React.useState(false);
  const [marketSegmentData, setmarketSegmentData]: any = React.useState({ IsData: true, data: [] });

  const marketSagmentTableColumns = React.useMemo(
    () => [
      {
        Header: "Market Segment",
        accessor: "marketSegment",
      },

      {
        Header: "Property Name",
        accessor: "PropertyName",
        className: "w-400",
      },
      {
        Header: "Action",
        accessor: "action",
        className: "table-action sticky-action",
      },
    ],
    []
  );

  React.useEffect(() => {
    if (!JSON.parse(localStorage.getItem("marketSegmentFilter"))) {
      HandleRemoveOtherFilters("marketSegmentFilter");
      HandleFilters("marketSegmentFilter", {
        pagePath: "/SetupAndConfigurations/marketSegmentType",
        pageName: "marketSegment",
        filters: [],
        isSort: [],
      });
    }
    getMarketSegmentList();
  }, []);

  async function getMarketSegmentList() {
    let input: any = {
      clientID: clientID,
      marketSegmentName: "",
    };

    setmarketSegmentData({ IsData: true, data: [] });
    let res: any = await MarketSegmentList(input, Token);

    if (res.data.success) {
      if (res.data.statusCode === 200) {
        if (res.data.data !== undefined && res.data.data !== null) {
          setmarketSegmentData({ IsData: false, data: res.data.data.dataList1 });
        } else {
          setmarketSegmentData({ IsData: false, data: [] });
        }
      }
    }
  }

  let marketSagmentTableData: any = [];
  marketSegmentData.data &&
    marketSegmentData.data.map((item) => {
      marketSagmentTableData.push({
        className: {
          action: "table-action sticky-action",
        },
        marketSegment: item.MarketSegmentName,
        PropertyName: item.PropertyName !== undefined && item.PropertyName && item.PropertyName,
        action: (
          <span className="btn-icon-grp-table justify-content-end">
            <Button
              className="btn-icon button btn btn-primary editIcon acteditbtn"
              onClick={() => {
                if (page_rights_MarketSegment.is_update) {
                  EditMarketSegment(item.MarketSegmentID);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
            <Button
              className="btn-icon cancelIcon"
              onClick={() => {
                if (page_rights_MarketSegment.is_delete) {
                  DeleteMarketSegment(item.MarketSegmentID);
                } else {
                  CommonService.unauthorizedAction();
                }
              }}
            ></Button>
          </span>
        ),
      });
    });
  const [MarketSegmentID, setMarketSegmentID]: any = React.useState("");
  function EditMarketSegment(value) {
    setMarketSegmentID(value);
    setModalShow(true);
  }

  function DeleteMarketSegment(value: any) {
    Swal.fire({
      title: "Are you sure want to delete ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let input: any = {
          keyID: value,
        };
        let res: any = await DeleteMarketSegmentRecord(input, Token);
        if (res.data.success) {
          if (res.data.statusCode === 200) {
            ToasterSuccess("Email Setting Deleted Successfully");
            getMarketSegmentList();
          } else {
            ToasterError("Something Went Wrong");
          }
        }
      }
    });
  }

  return (
    <>
      <div className="btn-icon-grp adjust-height">
        <OverlayTrigger placement="bottom" overlay={<Tooltip>Add Market Segment Type</Tooltip>}>
          <Button
            onClick={() => {
              if (page_rights_MarketSegment.is_create) {
                setModalShow(true);
              } else {
                CommonService.unauthorizedAction();
              }
            }}
            className="btn-icon addFormIcon"
          ></Button>
        </OverlayTrigger>
      </div>
      <Container fluid={true} className="p-0 position-relative">
        <Row>
          <Col xs={12} className="portfolio-Table">
            <div className="lead-height-table">
              <TableView
                columnsData={marketSagmentTableColumns}
                tableData={marketSagmentTableData}
                isData={marketSegmentData.IsData}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {modalShow === true && (
        <MarketSegmentModal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setMarketSegmentID("");
          }}
          MarketSegmentID={MarketSegmentID}
          getMarketSegmentList={getMarketSegmentList}
        />
      )}
    </>
  );
}

export default MarketSagmentTypes;
