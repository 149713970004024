import moment from "moment";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import Select from "react-select";
import GenerateReport from "../../Asset/Images/Other/generate-report.png";
import PageLoader from "../../Component/Loader/PageLoader";
import MeetingAndCateringByDateModal from "../../Model/Reports/MeetingAndCateringByDateModal";
import {
  CommonService,
  HandleFilters,
  HandleRemoveOtherFilters,
  fnCheckValidationOfObject,
} from "../../Services/CommonServices";
import { GetHotelsListForReports } from "../../Services/Setup&Config/HotelService";
import Unauthorized from "../Authentication/Unauthorized";
import ReportNavigations from "./ReportNavigations";

function MeetingAndCateringByDate() {
  let MeetingandCateringbyDate_page_rights = CommonService.getPageRight("MeetingandCateringbyDate");

  let iniInputData = {
    propertyID: "",
    startDate: "",
    endDate: "",
    errors: {
      propertyID: "",
      startDate: "",
      endDate: "",
      ValidationRules: [
        {
          FieldName: "propertyID",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "startDate",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
        {
          FieldName: "endDate",
          ValidationType: "required",
          ValidationMessage: "This field is required.",
        },
      ],
    },
  };

  const dataSelector: any = useSelector((state: any) => state.session);
  const [loading, setLoading] = useState(false);
  const clientID: any = dataSelector.auth_UserDetail.C_ClientID;
  const Token = dataSelector.access_Token;
  const [HotelDropdown, setHotelDropdown]: any = useState([]);
  const [inputs, setInputs] = useState(iniInputData);
  const [totalRecord, setTotalRecord]: any = useState([]);
  const userId: any = dataSelector.auth_UserDetail.U_UserID;
  const userTypeID: any = dataSelector.auth_UserDetail.U_UserTypeID;
  const SelectedPropertyID: any = useSelector((state: any) => state.dashboard.SelectedPropertyID);
  const SelectedPropertyName: any = useSelector(
    (state: any) => state.dashboard.SelectedPropertyName
  );
  const [MeetingAndCateringByDateReport, setMeetingAndCateringByDateReport] = useState({});
  const [modalShow, setModalShow] = useState(false);

  let optionsHotel: any = [
    {
      value: null,
      label: "Select Hotel",
    },
  ];

  HotelDropdown.length > 0 &&
    HotelDropdown.map((item) => {
      optionsHotel.push({
        value: item.PropertyID,
        label: item.HotelCode + " " + item.PropertyName,
      });
    });

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("meetingCateringByDateReportFilter"))) {
      HandleRemoveOtherFilters("meetingCateringByDateReportFilter");
      HandleFilters("meetingCateringByDateReportFilter", {
        pagePath: "/MeetingAndCateringByDate",
        pageName: "meetingCateringByDateReport",
        filters: [],
        isSort: [],
      });
    }
  }, []);

  useEffect(() => {
    if (SelectedPropertyName) {
      if (!SelectedPropertyID.includes(",")) {
        setInputs({
          ...inputs,
          propertyID: SelectedPropertyID,
        });
      }
    }
    getHotelsList();
  }, [SelectedPropertyName]);

  async function getHotelsList() {
    let input: any = {
      clientID: clientID,
      propertyName: "",
      propertyCode: "",
      portfolio: "",
      primaryContactNo: "",
      primaryEmailID: "",
      propertyStatus_Term: "Active",
      hotelCode: "",
      city: "",
      userID: userId,
    };
    setLoading(true);
    let res: any = await GetHotelsListForReports(input, Token);
    setLoading(false);
    if (res.data.success) {
      if (res.data !== undefined && res.data !== null) {
        if (res.data.data !== undefined && res.data.data) {
          setHotelDropdown(res.data.data.dataList1);
        }
      }
    }
  }

  async function handleGenerateReport() {
    let obj = fnCheckValidationOfObject(inputs);
    setInputs({
      ...obj.obj,
    });

    if (obj.isValid) {
      let input: any = {
        propertyID: inputs.propertyID,
        startDate: inputs.startDate,
        endDate: inputs.endDate,
      };

      setMeetingAndCateringByDateReport(input);
      setModalShow(true);
    }
  }

  let MeetingCateringByDateReportTable: any = () => {
    return (
      <>
        <thead>
          <tr className="h-40">
            <th className="settitle text-center">Start Date</th>
            <th className="settitle text-center">End Date</th>
            <th className="settitle text-center">Day Of Week</th>
            <th className="settitle text-center">Account Name </th>
            <th className="settitle text-center">Opportunity Name </th>
            <th className="settitle text-center">Event Date Details </th>
            <th className="settitle text-center">Event Start Time </th>
            <th className="settitle text-center">Event End Time </th>
            <th className="settitle text-center">Meeting Room </th>
            <th className="settitle text-center">Setup </th>
            <th className="settitle text-center">Meeting Room Revenue</th>
            <th className="settitle text-center">No Of People </th>
            <th className="settitle text-center">Is Food(Y/N) </th>
            <th className="settitle text-center">Total Anticipated Food Revenue</th>
            <th className="settitle text-center">Is Beverage(Y/N) </th>
            <th className="settitle text-center">Total Anticipated Beverage Revenue </th>
            <th className="settitle text-center">Total Anticipated F&B Revenue </th>
            <th className="settitle text-center">Is AVEquipment(Y/N)</th>
            <th className="settitle text-center">Total Anticipated AV & MIS Revenue</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(totalRecord).length &&
            Object.keys(totalRecord).map((table_key, index) => {
              return (
                <tr key={index} className="h-40">
                  <td className="settitle text-center">
                    {totalRecord[table_key].StartDate ? totalRecord[table_key].StartDate : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].EndDate ? totalRecord[table_key].EndDate : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].DayOfWeek ? totalRecord[table_key].DayOfWeek : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].AccountName ? totalRecord[table_key].AccountName : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].OppoName ? totalRecord[table_key].OppoName : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].EventDateDetails
                      ? totalRecord[table_key].EventDateDetails
                      : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].StartTime ? totalRecord[table_key].StartTime : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].EndTime ? totalRecord[table_key].EndTime : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].MeetingRoom ? totalRecord[table_key].MeetingRoom : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].Setup ? totalRecord[table_key].Setup : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].MeetingRoomRevenue
                      ? totalRecord[table_key].MeetingRoomRevenue
                      : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].NumberOfPeople
                      ? totalRecord[table_key].NumberOfPeople
                      : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].IsFood ? totalRecord[table_key].IsFood : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].FoodRevenue ? totalRecord[table_key].FoodRevenue : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].IsBeverage ? totalRecord[table_key].IsBeverage : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].BeverageRevenue
                      ? totalRecord[table_key].BeverageRevenue
                      : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].FoodBeverageRevenue
                      ? totalRecord[table_key].FoodBeverageRevenue
                      : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].IsAVEquipment
                      ? totalRecord[table_key].IsAVEquipment
                      : "-"}
                  </td>
                  <td className="settitle text-center">
                    {totalRecord[table_key].AvEquipmentRevenue
                      ? totalRecord[table_key].AvEquipmentRevenue
                      : "-"}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  };

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  function handleDateRange(e) {
    let [startDate, endDate] = e;
    if (startDate !== null && endDate !== null) {
      let x = moment(startDate).format("MM-DD-YYYY");
      let y = moment(endDate).format("MM-DD-YYYY");

      setInputs({
        ...inputs,
        ["startDate"]: x,
        ["endDate"]: y,
      });
    } else {
      setInputs({
        ...inputs,
        ["startDate"]: "",
        ["endDate"]: "",
      });
    }
  }

  return (
    <div className="report-page">
      <Row>
        <Col xxl={3} xl={3} lg={3} md={4}>
          <ReportNavigations />
        </Col>
        <Col xxl={9} xl={9} lg={9} md={8} className="mt-4 mt-lg-0">
          {MeetingandCateringbyDate_page_rights.is_view ? (
            !loading ? (
              <>
                <div className="custom-profile-tabs-col">
                  <Row>
                    <Col lg={6} xl={6} className="mb-4">
                      <div className={`${inputs.errors.propertyID.length && "red-border-masking"}`}>
                        <FloatingLabel controlId="floatingSelectGrid" label="">
                          <Select
                            value={optionsHotel.filter(
                              (obj) => inputs.propertyID && inputs.propertyID.includes(obj.value)
                            )}
                            placeholder="Select Hotel"
                            options={optionsHotel}
                            onChange={(e) => {
                              setInputs({
                                ...inputs,
                                propertyID: e.value,
                              });
                              e.value;
                            }}
                          />
                        </FloatingLabel>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {" "}
                        {inputs.errors.propertyID && inputs.errors.propertyID}{" "}
                      </small>
                    </Col>

                    <Col lg={6} className="mb-4">
                      <div className={`${inputs.errors.startDate && "red-border-masking"}`}>
                        <div className="form-floating">
                          <DatePicker
                            className="form-control form-control-date datepicker-react"
                            placeholderText="Select Start & End Date"
                            minDate={new Date("2010/01/01")}
                            maxDate={new Date("9999/12/31")}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              handleDateRange(update);
                              setDateRange(update);
                            }}
                          />
                        </div>
                      </div>
                      <small style={{ color: "#dc3545" }}>
                        {inputs.errors.startDate && inputs.errors.startDate}{" "}
                      </small>{" "}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Modal.Footer className="border-0 justify-content-start">
                        <Button
                          variant="primary ms-0"
                          onClick={() => {
                            if (MeetingandCateringbyDate_page_rights.is_view) {
                              handleGenerateReport();
                            } else {
                              CommonService.unauthorizedAction();
                            }
                          }}
                        >
                          <span className="me-2">
                            <img src={GenerateReport} alt="" />
                          </span>
                          Generate Report
                        </Button>
                      </Modal.Footer>
                    </Col>
                  </Row>

                  {modalShow && (
                    <MeetingAndCateringByDateModal
                      MeetingAndCateringByDateReport={MeetingAndCateringByDateReport}
                      MeetingAndCateringTitle={inputs}
                      show={modalShow}
                      onHide={() => {
                        setModalShow(false);
                        setMeetingAndCateringByDateReport({});
                      }}
                    ></MeetingAndCateringByDateModal>
                  )}
                </div>
              </>
            ) : (
              <Col xxl={12} xl={12} lg={12} md={12} className="mt-4 mt-lg-0">
                <PageLoader />
              </Col>
            )
          ) : (
            <Unauthorized />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default MeetingAndCateringByDate;
